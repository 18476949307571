/* eslint-disable no-nested-ternary */
import { useContext, useEffect, useState } from 'react';
import { scrollSmoothToPosition } from '@utils/utils';
import { INextStepsType } from '@simulator/utils/simulatorTypes';
import { InstallerContext } from '@auth/utils/authContext';
import { ChevronIcon } from '@assets/svgComponents/svgComponents';
import { useLocation } from 'react-router-dom';
import { backgroundQuaternaryLightMode, dark1 } from '@assets/colors';
import { v4 } from 'uuid';
import { useMediaQuery } from 'react-responsive';
import NextStepsContent from './NextStepsContent';
import ButtonNextStep from './ButtonNextStep';

function NextSteps(): JSX.Element {
  const { colorPrimary, radiusPx, isThemeDark } = useContext(InstallerContext);
  const isMobile = useMediaQuery({ maxWidth: 1024 });

  const nextStepsDatas: INextStepsType[] = [
    {
      title: 'Résultat',
      content: (
        <div>
          Cette étape affiche les différentes primes auxquelles est éligible
          votre client. Ce résultat ne vous engage à rien et n&apos;est pas
          définitif. Si vous estimez devoir corriger des informations, vous êtes
          libre de recommencer une nouvelle simulation. Si le résultat vous
          convient et que vous souhaitez créer le dossier, vous pouvez
          poursuivre le parcours de création de dossier. À la prochaine étape,
          vous serez invité à nous communiquer les informations à propos de
          votre client.
        </div>
      ),
    },
    {
      title: 'Création du dossier',
      content: (
        <div>
          Cette étape vous permettra de renseigner différentes informations à
          propos de votre client. Il s’agit de la dernière étape avant que Drapo
          prenne le relai. Votre Référent de compte peut vous assister dans
          cette démarche.
        </div>
      ),
    },
    {
      title: 'Étude par Drapo',
      content: (
        <div>
          Drapo instruit votre dossier et vérifie la conformité des documents
          d&apos;avant travaux, comme le devis. D&apos;éventuelles demandes de
          compléments pourront vous être faites avant de passer à l&apos;étape
          suivante. Sans dossier MPR, vous pourrez démarrer les travaux.
          Autrement, il faudra attendre la notification d&apos;octroi de la
          prime MPR (voir étape suivante).
        </div>
      ),
    },
    {
      title: 'Instruction par MPR',
      content: (
        <div>
          Plusieurs semaines après le dépôt du dossier (en moyenne un mois et
          demi), MaPrimeRénov’ nous transmet la notification d’octroi sur
          laquelle figure la prime accordée.{' '}
          <b>C’est le vrai feu vert pour les travaux.</b> Nous vous prévenons
          vous et votre client par email de la décision et du montant exact.
        </div>
      ),
    },
    {
      title: 'Travaux',
      content: (
        <div>
          À la fin des travaux, vous déposerez la facture des travaux sur votre
          espace Drapo (sur laquelle figure la{' '}
          <b>date de début et de fin de travaux</b>). Votre client et vous
          recevront également une attestation sur l&apos;honneur pré-remplie à
          signer en ligne.
        </div>
      ),
    },
    {
      title: 'Paiement des primes',
      content: (
        <div>
          À la fin des travaux, nous instruisons votre dossier (15 jours) et
          organisons le contrôle COFRAC si nécessaire (14 jours). Une fois ces
          contrôles effectués et validés, Drapo vous verse vos primes CEE sous
          quelques jours.
        </div>
      ),
    },
  ];

  const [isToggle, setIsToggle] = useState(false);
  const [nextStepsContentDatas, setNextStepsContentDatas] = useState({
    title: '',
    content: <div />,
  });
  const [style, setStyle] = useState<string>('');
  const [delayNextStepsDatas, setDelayNextStepsDatas] = useState<
    INextStepsType[]
  >([]);
  const [isClick, setIsClick] = useState(false);
  const [isClosable, setIsClosable] = useState(false);

  useEffect(() => {
    if (isToggle) {
      setTimeout(() => setIsClosable(true), 500 * nextStepsDatas.length);
    }
  }, [isToggle]);

  const delay = () => {
    setDelayNextStepsDatas([]);
    if (!isToggle) {
      for (let i = 0; i < nextStepsDatas.length; i += 1) {
        setTimeout(
          () =>
            setDelayNextStepsDatas((previousDatas) => [
              ...previousDatas,
              nextStepsDatas[i],
            ]),
          500 * i
        );
      }
    } else {
      setDelayNextStepsDatas([]);
    }
  };

  const handleChangeStyle = (title: string, content: JSX.Element) => {
    if (style === '') {
      setStyle(nextStepsDatas[0].title);
      setNextStepsContentDatas({
        title: nextStepsDatas[0].title,
        content: nextStepsDatas[0].content,
      });
      setIsClick(false);
    } else {
      setStyle(title);
      setIsClick(isToggle);
      setTimeout(() => {
        setNextStepsContentDatas({
          title,
          content,
        });
        setIsClick(false);
      }, 100);
    }
  };

  const handleDelay = () => {
    if (!isToggle) {
      setIsToggle(!isToggle);
      delay();
      handleChangeStyle(nextStepsDatas[0].title, nextStepsDatas[0].content);
      setIsClosable(false);
    } else if (isClosable) {
      setIsToggle(!isToggle);
      delay();
      handleChangeStyle(nextStepsDatas[0].title, nextStepsDatas[0].content);
    }
  };

  const ScrollToBottom = () => {
    if (!isToggle) {
      scrollSmoothToPosition(document.body.scrollHeight);
    }
    return true;
  };

  const location = useLocation();
  return (
    <div className="w-full mt-interblock dark:text-white text-colorTextLightMode  ">
      <div
        style={{
          borderRadius: radiusPx,
          backgroundColor: isThemeDark
            ? dark1
            : location.search.includes('?integration=iframe')
            ? backgroundQuaternaryLightMode
            : 'white',
        }}
        className="text-[.875rem] dark:bg-dark-1 bg-white  w-full dark:text-white text-colorTextLightMode"
      >
        <button
          style={{
            borderRadius: radiusPx,
            backgroundColor: isThemeDark
              ? dark1
              : location.search.includes('?integration=iframe')
              ? backgroundQuaternaryLightMode
              : 'white',
          }}
          type="button"
          className="py-[2.5rem] px-[2.5rem] items-center justify-between flex dark:bg-dark-1 bg-white  w-full"
          onClick={() => {
            handleDelay();
            setTimeout(ScrollToBottom, 1000);
          }}
        >
          <div className="font-medium">Prochaines étapes</div>
          <div>
            <ChevronIcon
              width="1.5rem"
              height="1.5rem"
              fill={isThemeDark ? 'white' : '#191919'}
              className={`${
                isToggle ? 'rotate-minus-90' : 'rotate-90'
              } transition-transform duration-300 `}
            />
          </div>
        </button>
        {isToggle && (
          <div
            style={{
              borderRadius: radiusPx,
              backgroundColor: isThemeDark
                ? dark1
                : location.search.includes('?integration=iframe')
                ? backgroundQuaternaryLightMode
                : 'white',
            }}
            className={` dark:text-white text-colorTextLightMode pb-[3.125rem] md:flex md:justify-between dark:bg-dark-1 bg-white ${
              isToggle
                ? 'text-white dark:bg-dark-1 bg-white w-auto h-auto animation-dropDown'
                : 'animation-up'
            }`}
          >
            {!isMobile ? (
              <>
                <ul
                  style={{
                    zIndex: 1,
                    backgroundColor: isThemeDark
                      ? dark1
                      : location.search.includes('?integration=iframe')
                      ? backgroundQuaternaryLightMode
                      : 'white',
                  }}
                  className="md:w-7/12 pl-[2.5rem] pb-[1rem] dark:bg-dark-1 bg-white pr-[1rem] "
                >
                  {delayNextStepsDatas.map(
                    (data: INextStepsType, index: number) => (
                      <li className='className="w-full relative' key={v4()}>
                        <div
                          key={data.title}
                          className={`before:animate-line after:animate-line mb-0 before:content-[''] before:absolute before:ml-[-.1rem] before:left-[.938rem] before:border-l-[.5px] before:border-solid  dark:before:border-[#313030] before:border-borderLightMode before:h-full before:w-[.5px] before:top-[1.5rem] ${
                            index === 5 && 'before:top-0 after:animate-none h-0'
                          }`}
                        >
                          <button
                            type="button"
                            className="flex items-center justify-start h-[3.875rem] !w-full"
                            onClick={() => {
                              handleChangeStyle(data.title, data.content);
                            }}
                          >
                            <svg
                              style={{ zIndex: 99999999999 }}
                              width="1.875rem"
                              height="1.875rem"
                              viewBox="0 0 90 90"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <circle
                                cx="45"
                                cy="45"
                                r="45"
                                fill={colorPrimary}
                                fillOpacity={style === data.title ? '0.2' : '0'}
                                className="animation-disc"
                              />
                              <circle
                                cx="45.5"
                                cy="45.5"
                                r="17.5"
                                fill={colorPrimary}
                              />
                            </svg>
                            <ButtonNextStep data={data} style={style} />
                          </button>
                        </div>
                      </li>
                    )
                  )}
                </ul>
                <div
                  style={{ zIndex: 0 }}
                  className="h-auto md:w-9/12 pl-[1rem] pr-[2.5rem]"
                >
                  <NextStepsContent
                    title={nextStepsContentDatas.title}
                    content={nextStepsContentDatas.content}
                    isClick={isClick}
                  />
                </div>
              </>
            ) : (
              <div className="px-[2.5rem] flex flex-col">
                {delayNextStepsDatas.map((item) => (
                  <>
                    <div className="flex items-center space-x-[1rem] mb-[1rem]">
                      <svg
                        style={{ zIndex: 99999999999 }}
                        width="1.875rem"
                        height="1.875rem"
                        viewBox="0 0 90 90"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          cx="45"
                          cy="45"
                          r="45"
                          fill={colorPrimary}
                          fillOpacity={
                            nextStepsContentDatas.title === item.title
                              ? '0.2'
                              : '0'
                          }
                          className="animation-disc"
                        />
                        <circle
                          cx="45.5"
                          cy="45.5"
                          r="17.5"
                          fill={colorPrimary}
                        />
                      </svg>
                      <button
                        style={{ borderRadius: radiusPx }}
                        onClick={() =>
                          setNextStepsContentDatas(
                            item.title !== nextStepsContentDatas.title
                              ? {
                                  title: item.title,
                                  content: item.content,
                                }
                              : {
                                  title: '',
                                  content: <div />,
                                }
                          )
                        }
                        type="button"
                        className="text-left dark:bg-dark-2 bg-white w-full dark:text-white text-colorTextLightMode p-[.25rem] pl-[1rem]"
                      >
                        {item.title}
                      </button>
                    </div>
                    {item.title === nextStepsContentDatas.title && (
                      <div
                        style={{ borderRadius: radiusPx }}
                        className="mb-[1rem] dark:bg-dark-3 bg-lightMode-dark-3 p-[1rem] animation-content-dropDown-mobile dark:border-none border border"
                      >
                        {item.content}
                      </div>
                    )}
                  </>
                ))}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default NextSteps;
