import { useContext, useState, useEffect } from 'react';
import { InputCount, Button } from 'storybook-design-system-drapo';
import LoaderInputNumber from '@components/loaders/LoaderInputNumber';
import { InstallerContext } from '@auth/utils/authContext';
import { nextStepGeneral, nextStepOperation } from '@simulator/utils/functions';
import { SimulatorContext } from '@simulator/utils/simulatorContext';
import { dark1, white } from '@assets/colors';
import { useLocation } from 'react-router-dom';

interface InputConfiguration {
  label: string;
  min: number;
  max?: number;
  startValue: number;
}

function InputNumber(): JSX.Element {
  const location = useLocation();
  const {
    simulatorDatas,
    updateSimulatorDatas,
    graphGeneral,
    updateGraphGeneral,
    updateGraphList,
    updateStepNumber,
    updateGeneralComplete,
    generalComplete,
    updateGraphOperation,
    updateGraphListOperation,
    graphOperation,
    updateCurrentOperation,
    currentOperation,
    loaded,
    multipleOperations,
  } = useContext(SimulatorContext);
  const { colorPrimary, radiusPx, isThemeDark, colorTextButton } =
    useContext(InstallerContext);

  const graphActive = generalComplete ? graphOperation : graphGeneral;

  const [valueInputCount, setValueInputCount] = useState<number>(1);
  const [labelInput, setLabelInput] = useState<string>('');

  // Objet de configuration de l'input number: on défini, le label, la valeur de départ, le min et éventuellement le max
  const inputConfigurations: Record<string, InputConfiguration> = {
    default: { label: 'Personne', min: 1, max: 10, startValue: 1 },
    transmitterNumber: { label: 'Radiateur', min: 1, max: 10, startValue: 1 },
    windowNumber: { label: 'Fenêtre', min: 1, max: 10, startValue: 1 },
    numberApartmentConnected: {
      label: 'Appartement',
      min: 1,
      max: 10,
      startValue: 1,
    },
    volumeMaterial: { label: 'Litre', min: 1, startValue: 301 },
    efficiency: {
      label: 'Efficacité énergétique saisonnière',
      min: 1,
      startValue: 111,
    },
    // ajouter d'autres configurations si besoin
  };

  const onClickNext = () => {
    if (generalComplete) {
      nextStepOperation(
        simulatorDatas,
        currentOperation,
        updateStepNumber,
        updateCurrentOperation,
        updateGraphOperation,
        updateGraphListOperation,
        graphOperation,
        graphOperation.id,
        valueInputCount.toString()
      );
    } else {
      nextStepGeneral(
        simulatorDatas,
        multipleOperations,
        updateGeneralComplete,
        updateStepNumber,
        updateSimulatorDatas,
        updateGraphGeneral,
        updateGraphList,
        graphGeneral,
        graphGeneral.id,
        valueInputCount.toString()
      );
    }
  };

  const minusClick = () => {
    const config =
      inputConfigurations[graphActive.id] || inputConfigurations.default;
    const min = config?.min || 1;
    setValueInputCount((prevValue) => Math.max(min, prevValue - 1));
  };

  const plusClick = () => {
    const config =
      inputConfigurations[graphActive.id] || inputConfigurations.default;
    const max = config?.max || Infinity;
    setValueInputCount((prevValue) => Math.min(max, prevValue + 1));
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const config =
      inputConfigurations[graphActive.id] || inputConfigurations.default;
    const max = config.max ?? Infinity;
    const min = config.min;
    let value = parseInt(e.target.value, 10);

    if (Number.isNaN(value)) {
      value = min;
    } else {
      // si value < min, value = min et si value > max, value = max
      value = Math.max(min, Math.min(value, max));
    }

    setValueInputCount(value);
  };

  useEffect(() => {
    const config =
      inputConfigurations[graphActive.id] || inputConfigurations.default;
    setLabelInput(config.label);
    setValueInputCount(config.startValue);
  }, [graphActive.id]);

  return (
    <div>
      {!loaded ? (
        <LoaderInputNumber />
      ) : (
        <div className="animate__animated animate__fadeIn">
          <div
            className="mt-[1.5rem]"
            style={{
              borderRadius: radiusPx,
              border:
                location.search.includes('?integration=iframe') && !isThemeDark
                  ? '1px solid #d2d2d2'
                  : '',
            }}
          >
            <InputCount
              borderRadius={radiusPx}
              color={isThemeDark ? white : dark1}
              backgroundColor={isThemeDark ? dark1 : 'white'}
              onChange={handleChange}
              onClickMinus={minusClick}
              onClickPlus={plusClick}
              label={labelInput}
              valueInputCount={valueInputCount}
              dataTestId="inputCount"
            />
          </div>
          <div className="w-full mt-[1.5rem]">
            <Button
              borderRadius={radiusPx}
              disabled={!(valueInputCount > 0)}
              buttonArrowSmall
              label="Suivant"
              color={colorTextButton}
              backgroundColor={colorPrimary}
              arrow
              addClass="!w-full !px-[1rem]"
              onClick={onClickNext}
              dataTestId="next"
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default InputNumber;
