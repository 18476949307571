import { InstallerContext } from '@auth/utils/authContext';
import { Dispatch, SetStateAction, useContext } from 'react';
import InfosButton from '../../InfosButton';
import AgreeCheckCards from '../mprInstruction/AgreeCheckCards';

interface IndivisionProps {
  setIsIndivision: Dispatch<SetStateAction<boolean>>;
  isIndivision: boolean;
  setDivActive: Dispatch<SetStateAction<string | undefined>>;
}

function Indivision({
  isIndivision,
  setIsIndivision,
  setDivActive,
}: IndivisionProps): JSX.Element {
  const { radiusPx } = useContext(InstallerContext);

  return (
    <div
      style={{ borderRadius: radiusPx }}
      className="dark:bg-dark-2 bg-white p-[1.5rem] mt-[2rem] dark:text-white text-colorTextLightMode"
    >
      <div className="mb-[.625rem] flex items-center justify-between">
        <p className="sb-text-rem-body2 dark:text-white text-colorTextLightMode">
          Indivision
        </p>
        <InfosButton forcedDivActive="Indivision" setDivActive={setDivActive} />
      </div>
      <p className="text-[.75rem] font-light mb-[1.5rem] leading-[1.5rem]">
        Le logement est-il en indivision ?
      </p>
      <AgreeCheckCards
        updateAgree={setIsIndivision}
        typeAgree={isIndivision}
        isMpr={false}
      />
    </div>
  );
}

export default Indivision;
