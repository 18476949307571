import { InstallerContext } from '@auth/utils/authContext';
import { updatePassword } from '@dashboard/apiRequests/dashboardRequests';
import { MyProfileContext } from '@dashboard/utils/context/myProfileContext';
import { Dispatch, SetStateAction, useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { purple1 } from '@assets/colors';
import InputUpdate from '../InputUpdate';

interface IProps {
  setShowPasswordUpdate: Dispatch<SetStateAction<boolean>>;
}

function UpdatePassword({ setShowPasswordUpdate }: IProps): JSX.Element {
  const [error, setError] = useState('');
  const { handleSubmit, register } = useForm();
  const { installer, radiusPx } = useContext(InstallerContext);
  const { updateIsUpdateLoading, isUpdateLoading } =
    useContext(MyProfileContext);

  const onSubmit = (data: any) => {
    // TODO vérifier que l'on demande bien le nouveau mot de passe et la confirmation
    if (data.password === data.confirmPassword) {
      updatePassword(
        data.password,
        updateIsUpdateLoading,
        setError,
        setShowPasswordUpdate
      );
    } else {
      setError('Les mots de passe ne correspondent pas');
    }
  };

  const radius = radiusPx === '0px' ? '0px' : '8px';
  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      action="update Password"
      className="pb-[1.25rem] w-full"
    >
      <InputUpdate
        placeholder=""
        register={register}
        name="password"
        required
        id="password"
        type="password"
      />
      <InputUpdate
        placeholder=""
        register={register}
        name="confirmPassword"
        required
        id="confirmPassword"
        type="password"
      />
      <p className="text-sm mt-1 text-red-500"> {error}</p>
      <button
        disabled={isUpdateLoading}
        style={{
          borderRadius: radius,
          opacity: isUpdateLoading ? 0.5 : 1,
          backgroundColor:
            installer.nom_partenaire === 'DRAPO' ||
            installer.nom_partenaire === ''
              ? purple1
              : installer.custom.couleur_boutons,
        }}
        type="submit"
        className="mt-[1rem]  w-auto text-white py-[.625rem] px-[2.25rem] sb-text-rem-subtitle2"
      >
        Valider
      </button>
    </form>
  );
}

export default UpdatePassword;
