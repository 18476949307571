import { InstallerContext } from '@auth/utils/authContext';
import { MyProfileContext } from '@dashboard/utils/context/myProfileContext';
import { useContext, useState } from 'react';
import UpdateEmail from './UpdateEmail';
import UpdatePassword from './UpdatePassword';

interface IProps {
  registrationDate: string;
  mail: string;
}
function LoginInformation({ registrationDate, mail }: IProps): JSX.Element {
  const [showPasswordUpdate, setShowPasswordUpdate] = useState<boolean>(false);
  const [showEmailUpdate, setShowEmailUpdate] = useState<boolean>(false);

  const { radiusPx } = useContext(InstallerContext);
  const { isUpdateLoading } = useContext(MyProfileContext);

  return (
    <div
      style={{
        borderRadius: radiusPx,
      }}
      className="border dark:border-none dark:bg-dark-5 bg-white w-full mt-[1.875rem] py-[1.5rem] px-[1.5rem] dark:text-white text-dark-2"
    >
      <p className="text-[1.125rem] font-medium mb-[1.25rem]">
        Informations de connexion
      </p>
      <div className="mb-[1.25rem]">
        <p className="sb-text-rem-caption1 opacity-90">
          Date d&apos;inscription
        </p>
        <p className="sb-text-rem-subtitle">
          {new Date(registrationDate).toLocaleDateString('fr')}
        </p>
      </div>
      <div className="mb-[1.25rem] flex justify-between items-start w-full">
        <div>
          <p className="sb-text-rem-caption1 opacity-90">
            Adresse de connexion
          </p>
          {!showEmailUpdate && <p className="sb-text-rem-subtitle">{mail}</p>}
        </div>
        <button
          onClick={() => setShowEmailUpdate(!showEmailUpdate)}
          className="sb-text-rem-caption1 opacity-90"
          type="button"
          disabled={isUpdateLoading}
        >
          {!showEmailUpdate ? 'Modifier' : 'Fermer'}
        </button>
      </div>
      {showEmailUpdate && (
        <UpdateEmail dataMail={mail} setShowEmailUpdate={setShowEmailUpdate} />
      )}

      <div className="flex items-start justify-between w-full ">
        <div>
          <p className="sb-text-rem-caption1 opacity-90">
            Mot de passe de connexion
          </p>
          {!showPasswordUpdate && (
            <p className="sb-text-rem-subtitle">**********</p>
          )}
        </div>
        <button
          disabled={isUpdateLoading}
          onClick={() => setShowPasswordUpdate(!showPasswordUpdate)}
          className="sb-text-rem-caption1 opacity-90"
          type="button"
        >
          {!showPasswordUpdate ? 'Modifier' : 'Fermer'}
        </button>
      </div>
      {showPasswordUpdate && (
        <UpdatePassword setShowPasswordUpdate={setShowPasswordUpdate} />
      )}
    </div>
  );
}

export default LoginInformation;
