import { useState, useEffect, useContext, ReactNode } from 'react';
import LoaderDashboard from '@components/loaders/LoaderDashboard';
import {
  getFoldersDashboard,
  getFoldersInAirtableDashboard,
} from '@dashboard/apiRequests/dashboardRequests';
import { DashboardContext } from '@dashboard/utils/context/dashboardContext';

import { DASHBOARD_TABS } from '@dashboard/utils/tabs';
import { IListFolders } from '@dashboard/utils/dashboardTypes';
import ArrayFolderList from './ArrayFolderList';

function FoldersList(): JSX.Element {
  const [renderComponent, setRenderComponent] = useState<ReactNode>(<></>);
  const [foldersDataNoSendToAirtable, setFoldersDataNoSendToAirtable] =
    useState<IListFolders[]>([]);
  const [foldersDataSendToAirtable, setFoldersDataSendToAirtable] = useState<
    IListFolders[]
  >([]);

  const {
    ceeStatusFilter,
    mprStatusFilter,
    elementActive,
    updateCountsFolders,
    updateRowsSelectedFolders,
    rowsSelectedFolders,
    isLoadedFolders,
    updateIsLoadedFolders,
  } = useContext(DashboardContext);

  const [numberPageFolderSendToAirtable, setNumberPageFolderSendToAirtable] =
    useState<number>(1);

  const [
    numberPageFolderNoSendToAirtable,
    setNumberPageFolderNoSendToAirtable,
  ] = useState<number>(1);

  const emptyFolders = () => {
    let textReturn = '';
    if (numberPageFolderNoSendToAirtable === 1) {
      if (elementActive === DASHBOARD_TABS.FOLDERS_INSTRUCTION) {
        textReturn = "Aucun dossier en cours d'instruction";
      } else {
        textReturn = 'Aucun dossier en cours de simulation';
      }
    } else {
      textReturn = 'Fin des dossiers';
    }
    return (
      <div className="text-center mb-5 mt-[2rem] dark:text-white text-colorTextLightMode">
        {textReturn}
      </div>
    );
  };

  useEffect(() => {
    updateIsLoadedFolders(true);
    if (elementActive === DASHBOARD_TABS.FOLDERS_INSTRUCTION) {
      updateIsLoadedFolders(true);
      getFoldersInAirtableDashboard(
        setFoldersDataSendToAirtable,
        1,
        updateIsLoadedFolders,
        foldersDataSendToAirtable,
        ceeStatusFilter,
        mprStatusFilter,
        updateCountsFolders,
        updateRowsSelectedFolders,
        rowsSelectedFolders
      );
    } else {
      getFoldersDashboard(
        setFoldersDataNoSendToAirtable,
        numberPageFolderNoSendToAirtable,
        updateIsLoadedFolders,
        foldersDataNoSendToAirtable,
        updateCountsFolders,
        updateRowsSelectedFolders,
        rowsSelectedFolders
      );
    }
  }, [elementActive]);

  useEffect(() => {
    if (elementActive === DASHBOARD_TABS.FOLDERS_INSTRUCTION) {
      setRenderComponent(
        <div>
          {foldersDataSendToAirtable.length > 0 ? (
            <div className="mb-2 pt-2 ">
              <ArrayFolderList
                foldersDatas={foldersDataSendToAirtable}
                setFoldersDatas={setFoldersDataSendToAirtable}
                numberPage={numberPageFolderSendToAirtable}
                setNumberPage={setNumberPageFolderSendToAirtable}
              />
            </div>
          ) : (
            emptyFolders()
          )}
        </div>
      );
    } else {
      setRenderComponent(
        <div>
          {foldersDataNoSendToAirtable.length > 0 ? (
            <div className=" mb-2 pt-2 ">
              <ArrayFolderList
                foldersDatas={foldersDataNoSendToAirtable}
                setFoldersDatas={setFoldersDataNoSendToAirtable}
                numberPage={numberPageFolderNoSendToAirtable}
                setNumberPage={setNumberPageFolderNoSendToAirtable}
              />
            </div>
          ) : (
            emptyFolders()
          )}
        </div>
      );
    }
  }, [foldersDataSendToAirtable, foldersDataNoSendToAirtable]);

  return (
    <div className="h-full w-full flex flex-col justify-between">
      {isLoadedFolders ? <LoaderDashboard /> : <div>{renderComponent}</div>}
    </div>
  );
}

export { FoldersList };
