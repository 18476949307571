import { InstallerContext } from '@auth/utils/authContext';
import { Dispatch, SetStateAction, useContext } from 'react';
import { CloseIcon, WarningIcon } from '@assets/svgComponents/svgComponents';
import { red3, backgroundBodyLightMode } from '@assets/colors';

type ModalThermalInsulationTypes = {
  setModalThermalInsulationAlreadyClosed: Dispatch<SetStateAction<boolean>>;
  setShowModal: Dispatch<SetStateAction<boolean>>;
};

function ModalThermalInsulation({
  setModalThermalInsulationAlreadyClosed,
  setShowModal,
}: ModalThermalInsulationTypes): JSX.Element {
  const { radiusPx } = useContext(InstallerContext);

  const radius = radiusPx === '0px' ? '0px' : '8px';

  return (
    <div
      style={{ borderRadius: radius, backgroundColor: red3 }}
      className="text-[.875rem] leading-[1.5rem] p-[2rem] flex flex-col space-y-[1rem]"
    >
      <div className="w-full text-white flex justify-between items-center">
        <div className="flex items-center space-x-[1rem]">
          <WarningIcon fill="white" width="1.5rem" height="1.5rem" />
          <p className="font-[500]">
            J’atteste de faire les travaux de calorifugeage
          </p>
        </div>
        <button
          type="button"
          onClick={() => {
            setShowModal(false);
            setModalThermalInsulationAlreadyClosed(true);
          }}
        >
          <CloseIcon fill="white" width="1.5rem" height="1.5rem" />
        </button>
      </div>
      <div style={{ color: backgroundBodyLightMode }}>
        J’atteste calorifuger l’ensemble des réseaux hydrauliques (en volume non
        chauffé) et frigorifiques.
      </div>
    </div>
  );
}

export default ModalThermalInsulation;
