import { useEffect, useState } from 'react';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { AdminContext } from '@admin/utils/AdminContext';
import {
  IStepConditionType,
  IFieldType,
  IStepType,
} from '@admin/utils/AdminTypes';
import { getSteps } from '@admin/apiRequests/AdminRequests';
import Table from '@admin/components/Table';

export default function Admin(): JSX.Element {
  const [stepName, setStepName] = useState<string>('');
  const [stepFieldsSelect, setStepFieldsSelect] = useState<IFieldType[]>([]);
  const [stepLoading, setStepLoading] = useState<boolean>(false);
  const [steps, setSteps] = useState<IStepType[]>([]);
  const [conditions, setConditions] = useState<IStepConditionType[]>([]);

  const adminContextValue = {
    // steps
    stepName,
    updateStepName: setStepName,
    stepFieldsSelect,
    updateStepFieldsSelect: setStepFieldsSelect,
    stepLoading,
    updateStepLoading: setStepLoading,
    steps,
    updateSteps: setSteps,
    conditions,
    updateConditions: setConditions,
  };

  const handleDragEnd = (result: DropResult) => {
    const { draggableId, destination, source } = result;
    if (!destination) return;
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const draggedField = stepFieldsSelect.find(
      (field) => field.provider_id === draggableId
    ) as IFieldType;

    const newFields = [...stepFieldsSelect];
    newFields.splice(source.index + 3, 1);
    newFields.splice(destination.index + 3, 0, draggedField);

    setStepFieldsSelect(newFields);
  };

  useEffect(() => {
    getSteps(setSteps);
  }, []);

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <DragDropContext onDragEnd={handleDragEnd}>
      <AdminContext.Provider value={adminContextValue}>
        <Table
          steps={steps}
          updateSteps={setSteps}
          stepFieldsSelect={stepFieldsSelect}
          setStepFieldsSelect={setStepFieldsSelect}
          stepName={stepName}
          setStepName={setStepName}
        />
      </AdminContext.Provider>
    </DragDropContext>
  );
}
