import { InstallerContext } from '@auth/utils/authContext';
import React, { Dispatch, SetStateAction, useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { v4 } from 'uuid';
import { IListAddress } from '../../models/simulator/utils/simulatorTypes';

interface BackButtonTypes {
  data: google.maps.places.AutocompletePrediction[];
  setChoiceOk: Dispatch<React.SetStateAction<boolean>>;
  setValue: (val: string, shouldFetchData?: boolean | undefined) => void;
  setManualAdress: Dispatch<React.SetStateAction<boolean>>;
  activeSuggestionIndex: number;
  setActiveSuggestionIndex: Dispatch<SetStateAction<number>>;
  listAddress: IListAddress[] | undefined;
  setListAddress: Dispatch<SetStateAction<IListAddress[] | undefined>>;
  setAddressSelected: Dispatch<SetStateAction<boolean>>;
  setIsManual?: Dispatch<SetStateAction<boolean>>;
}

function ListAddressGoogle({
  data,
  setValue,
  setChoiceOk,
  setManualAdress,
  activeSuggestionIndex,
  setActiveSuggestionIndex,
  listAddress,
  setListAddress,
  setIsManual,
  setAddressSelected,
}: BackButtonTypes): JSX.Element {
  const tabIndexSelect = 0;

  const { radiusPx, isThemeDark } = useContext(InstallerContext);

  const location = useLocation();

  useEffect(() => {
    const tab: IListAddress[] = [
      { id: 'notFound', description: 'Mon adresse n’est pas reconnue ?' },
    ];
    for (let i = 0; i < data.length; i += 1) {
      if (data[i].description.includes('France')) {
        tab.unshift({ id: data[i].place_id, description: data[i].description });
      }
    }
    setListAddress(tab);
  }, [data]);

  return (
    <div
      style={{
        marginTop: '-1.875rem',
        borderBottomLeftRadius: radiusPx,
        borderBottomRightRadius: radiusPx,
        border:
          location.search.includes('?integration=iframe') && !isThemeDark
            ? '1px solid #d2d2d2'
            : '',
      }}
      className="dark:bg-dark-1 bg-white p-5 sb-text-rem-subtitle"
    >
      {listAddress && (
        <ul>
          {listAddress.map((address: IListAddress, index: number) => {
            return (
              <li key={v4()}>
                <div
                  className={`mt-3 dark:text-white text-black hover:opacity-100 ${
                    index === activeSuggestionIndex
                      ? 'opacity-100 '
                      : 'opacity-50'
                  }`}
                  tabIndex={tabIndexSelect + 1}
                  role="button"
                  onKeyDown={undefined}
                  onClick={() => {
                    if (address.id === 'notFound') {
                      setManualAdress(true);
                      if (setIsManual) {
                        setIsManual(true);
                      }
                    } else {
                      setAddressSelected(true);
                      setValue(address.description);
                    }
                    setChoiceOk(true);
                  }}
                  onMouseEnter={() => setActiveSuggestionIndex(-1)}
                  key={address.id}
                  data-test-id="address"
                >
                  {address.description}
                </div>
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
}

export default ListAddressGoogle;

ListAddressGoogle.defaultProps = {
  setIsManual: undefined,
};
