/* eslint-disable no-nested-ternary */
import { ProgressBar } from 'storybook-design-system-drapo';
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { v4 } from 'uuid';
import { useLocation, Link } from 'react-router-dom';
import { SimulatorContext } from '@simulator/utils/simulatorContext';
import {
  FolderContext,
  FoldersAndSimulatorContext,
} from '@folder/utils/FoldersContext';
import { AuthContext, InstallerContext } from '@auth/utils/authContext';
import Logo from '@components/Logo';
import { StepsNames } from '@simulator/utils/steps';
import {
  borderLightMode,
  dark1,
  purple1,
  white,
  dark4,
  darkVariant1,
  backgroundBodyLightMode,
} from '@assets/colors';
import ListOperationsFolder from './listOperations-progressBar/ListOperationsFolder';
import ListOperationsSimu from './listOperations-progressBar/ListOperationsSimu';

interface IProps {
  leftContainer: number | undefined;
  setHeaderContainerHeight: Dispatch<SetStateAction<number>>;
  hideProgressBar?: boolean;
}

function Header({
  leftContainer,
  hideProgressBar,
  setHeaderContainerHeight,
}: IProps): JSX.Element {
  const { stepNumber, stepName, multipleOperations } =
    useContext(SimulatorContext);
  const { whiteLabelCustom } = useContext(FoldersAndSimulatorContext);
  const { stepNumberFolder, hasDimensioningNote } = useContext(FolderContext);
  const { installer, installerIsLoaded, colorPrimary, isThemeDark, radiusPx } =
    useContext(InstallerContext);
  const { token } = useContext(AuthContext);
  const { displayModalContratMixteMpr } = useContext(InstallerContext);

  const location = useLocation();

  const leftContainerWidth = leftContainer ? leftContainer + 1 : undefined;
  const offLine = token === '' && !location.pathname.includes('wl');
  const paddingInfosHeader = offLine ? 'pt-[6rem]' : 'pt-[2rem]';

  const headerContainer = useRef<HTMLDivElement>(null);

  const darkModeLogoWhiteLabel = isThemeDark
    ? whiteLabelCustom.logo_mb_light
    : whiteLabelCustom.logo_mb_dark;

  const darkModeLogoInstaller = isThemeDark
    ? installer.custom.logo_mb_light
    : installer.custom.logo_mb_dark;

  const [listActive, setListActive] = useState<boolean>(false);
  const [openAuto, setOpenAuto] = useState<boolean>(false);

  const getSize = () => {
    setTimeout(() => {
      if (headerContainer && headerContainer.current) {
        setHeaderContainerHeight(headerContainer.current.clientHeight + 16);
      }
    }, 5);
  };

  useEffect(() => {
    getSize();
  }, [
    listActive,
    headerContainer,
    hideProgressBar,
    installer,
    installerIsLoaded,
    whiteLabelCustom,
  ]);

  useEffect(() => {
    window.addEventListener('resize', getSize);
  }, []);

  useEffect(() => {
    getSize();
    if (multipleOperations) {
      if (multipleOperations.length === 0) {
        setListActive(false);
        setOpenAuto(false);
      } else if (
        stepName === StepsNames['1'] &&
        !openAuto &&
        multipleOperations.length > 0
      ) {
        setListActive(true);
        setOpenAuto(true);
      }
    }
  }, [multipleOperations]);

  useEffect(() => {
    if (stepName === StepsNames['1']) {
      setOpenAuto(false);
    }
    if (openAuto) {
      setListActive(false);
    }
  }, [stepName]);
  return (
    <div
      style={{
        zIndex: '111',
        width: leftContainerWidth,
        backgroundColor: isThemeDark
          ? darkVariant1
          : location.search.includes('?integration=iframe')
          ? white
          : backgroundBodyLightMode,
      }}
      className={`top-[0rem] ${displayModalContratMixteMpr ? '' : 'fixed'} `}
      ref={headerContainer}
    >
      <div
        className={`flex flex-col space-y-[1.5rem] w-full ${paddingInfosHeader} justify-between dark:border-b-[2px] dark:border-backgroundBodyDarkMode`}
      >
        <div>
          {!location.pathname.includes('wl') && (
            <>
              {installer.nom_partenaire === 'DRAPO' ||
              installer.nom_partenaire === '' ? (
                <Logo />
              ) : (
                darkModeLogoInstaller.map((logo) => {
                  return (
                    <Link key={v4()} to="/">
                      <img
                        src={logo}
                        style={{ width: '8.125rem' }}
                        className="logo-drapo"
                        alt="logo"
                      />
                    </Link>
                  );
                })
              )}
            </>
          )}
          {location.pathname.includes('wl') &&
            !location.search.includes('?integration=iframe') && (
              <Link to="/">
                {darkModeLogoWhiteLabel.map((logo) => (
                  <img
                    key={v4()}
                    style={{ width: '8.125rem', height: '3rem' }}
                    className="logo-drapo"
                    src={logo}
                    alt="logo"
                  />
                ))}
              </Link>
            )}
        </div>

        {!hideProgressBar && (
          <div
            style={{
              borderRadius: radiusPx,
            }}
            className="w-full px-[1.5rem] py-[1rem] dark:bg-dark-1 bg-white mb-[.625rem] border dark:border-none border-borderLightMode2"
          >
            {location.pathname === '/demander-ma-prime' ||
            location.pathname.includes('wl') ? (
              <>
                <ProgressBar
                  backgroundColor={colorPrimary}
                  stepNumber={stepNumber}
                  numberSteps={4}
                  stepName={stepName}
                  color={isThemeDark ? white : dark1}
                  colorSecondary={isThemeDark ? dark4 : borderLightMode}
                  listActive={listActive}
                  setListActive={setListActive}
                  hideOperationList={
                    multipleOperations ? multipleOperations.length === 0 : false
                  }
                />
                {listActive && <ListOperationsSimu />}
              </>
            ) : (
              <>
                <ProgressBar
                  backgroundColor={installerIsLoaded ? colorPrimary : purple1}
                  stepNumber={stepNumberFolder}
                  stepName=""
                  color={isThemeDark ? white : dark1}
                  colorSecondary={isThemeDark ? dark4 : borderLightMode}
                  numberSteps={hasDimensioningNote ? 4 : 3}
                  listActive={listActive}
                  setListActive={setListActive}
                />
                {listActive && <ListOperationsFolder />}
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

Header.defaultProps = {
  hideProgressBar: false,
};

export default Header;
