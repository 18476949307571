import { Dispatch, SetStateAction, useContext } from 'react';
import { InstallerContext } from '@auth/utils/authContext';
import { LayoutDashboardContext } from '@dashboard/utils/context/LayoutDashboardContext';
import { convertHexToRGBA } from '@utils/utils';

type INavButtonDDCProps = {
  title: string;
  elementActive: string;
  setElementActive: Dispatch<SetStateAction<string>>;
  isRetractable: boolean | undefined;
  setIsRetractable: Dispatch<SetStateAction<boolean>> | undefined;
  nbNotifs: number;
};

function NavButtonDDC({
  title,
  elementActive,
  setElementActive,
  isRetractable,
  setIsRetractable,
  nbNotifs,
}: INavButtonDDCProps): JSX.Element {
  const { isRetractableDDC, updateIsRetractableDDC } = useContext(
    LayoutDashboardContext
  );
  const { radiusPx, colorPrimary, isThemeDark } = useContext(InstallerContext);

  const radius = radiusPx === '0px' ? '0px' : '4px';

  const isActive = elementActive === title;

  const handleClick = () => {
    if (isRetractable && setIsRetractable) {
      setIsRetractable(!isRetractable);
      updateIsRetractableDDC(!isRetractable);
    }
    setElementActive(title);
  };

  return (
    <button
      type="button"
      style={{
        borderColor: isActive ? colorPrimary : '',
        borderRadius: radius,
        // eslint-disable-next-line no-nested-ternary
        color: isActive ? (isThemeDark ? 'white' : colorPrimary) : '',
        backgroundColor: isActive ? convertHexToRGBA(colorPrimary, 0.1) : '',
      }}
      className={`dark:bg-transparent text-[.875rem] flex items-center border-[1px] border-borderLightMode px-[.5rem] dark:text-white text-colorTextLightMode whitespace-nowrap ${
        title !== elementActive && isThemeDark && 'opacity-[.6]'
      } ${
        isRetractableDDC
          ? 'justify-between w-full py-[.5rem] bg-white'
          : 'space-x-[.75rem] py-[.25rem] bg-backgroundSecondaryLightMode'
      }`}
      onClick={handleClick}
    >
      <div>{title}</div>
      <div>{nbNotifs}</div>
    </button>
  );
}

export default NavButtonDDC;
