import { InstallerContext } from '@auth/utils/authContext';
import { useContext } from 'react';

function LoaderSurface(): JSX.Element {
  const { radiusPx } = useContext(InstallerContext);

  return (
    <div className="w-full">
      <div
        style={{ borderRadius: radiusPx }}
        className="w-full animate__flash animate__animated animate__slower h-[18.75rem] relative bg-black flex-row mt-[1.5rem]  px-[1.25rem] py-[1.25rem]"
      >
        <div
          className={`flex h-[3.125rem] absolute bottom-[.75rem] w-11/12 ml-1 animate__flash animate__animated animate__slower items-center dark:bg-dark-1 bg-lightMode-dark-1 px-[.5rem] mb-[.75rem] py-[.5rem] w-full flex-row items-center `}
        />
      </div>
    </div>
  );
}

export default LoaderSurface;
