import { useContext } from 'react';
import {
  OperationsListContext,
  SimulatorContext,
} from '@simulator/utils/simulatorContext';
import { InstallerContext } from '@auth/utils/authContext';
import { ChevronIcon } from '@assets/svgComponents/svgComponents';

function BackButton(): JSX.Element {
  const {
    updateStepNumber,
    stepNumber,
    updateGraphOperation,
    graphListOperation,
    updateGraphGeneral,
    graphGeneral,
    graphList,
    updateGraphList,
    updateGeneralComplete,
    updateGraphListOperation,
    updateHeigthMap,
    updateIsBack,
    currentOperation,
    updateCurrentOperation,
    multipleOperations,
    updateOperationActiveId,
    updateOperationsIdRecoveredGraph,
    operationInEdit,
  } = useContext(SimulatorContext);

  const { operationList } = useContext(OperationsListContext);

  const { isThemeDark } = useContext(InstallerContext);

  const backStepSimulator = () => {
    if (stepNumber === 1) {
      updateStepNumber(4);
    } else if (
      stepNumber === 2 &&
      graphGeneral.name === 'Adresse de vos travaux'
    ) {
      updateStepNumber(1);
    } else if (stepNumber !== 3) {
      if (graphGeneral.name === "Type d'habitation ?") {
        updateHeigthMap('h-[90vh]');
      }
      updateGraphList(graphList.slice(0, -1));
      updateGraphGeneral(graphList[graphList.length - 1]);
    } else if (stepNumber === 3) {
      if (graphListOperation.length === 1) {
        if (operationList.length === 0) {
          updateStepNumber(2);
          updateGeneralComplete(false);
        } else if (operationInEdit) {
          updateStepNumber(4);
        } else {
          updateStepNumber(1);
        }
      } else {
        const newGraphListOperation = graphListOperation.slice(0, -1);
        const lastOperationId =
          newGraphListOperation[newGraphListOperation.length - 1].operationId;
        if (lastOperationId && lastOperationId !== currentOperation.id) {
          updateOperationActiveId(lastOperationId);
          const operationIndexInList: number = multipleOperations.findIndex(
            (operation) => operation.id === lastOperationId
          );
          updateCurrentOperation(multipleOperations[operationIndexInList]);
          updateOperationsIdRecoveredGraph((oldIds) => oldIds.slice(0, -1));
        }
        updateGraphListOperation(newGraphListOperation);
        updateGraphOperation(
          newGraphListOperation[newGraphListOperation.length - 1]
        );
      }
    }
    updateIsBack(true);
  };

  return (
    <button
      className="cursor-pointer flex sb-text-rem-subtitle2 pb-[2rem] dark:text-white text-dark-1 items-center"
      type="button"
      onClick={() => backStepSimulator()}
    >
      <ChevronIcon
        width="1.25rem"
        height="1.25rem"
        fill={isThemeDark ? 'white' : '#191919'}
        className="rotate-full"
      />

      {stepNumber !== 1 && !operationInEdit ? (
        <p>Retour à l&apos;étape précédente</p>
      ) : (
        <p>Retour à la page de résultat</p>
      )}
    </button>
  );
}

export default BackButton;
