import { Dispatch, SetStateAction, useContext, useState } from 'react';
import { Input, WarningNotification } from 'storybook-design-system-drapo';
import { FolderContext } from '@folder/utils/FoldersContext';
import { InstallerContext } from '@auth/utils/authContext';
import { GoogleMap, Marker, Polygon, Polyline } from '@react-google-maps/api';
import { marker, minus, plus } from '@assets/images/images';
import { getParcelMapFolder } from '@folder/apiRequests/folderRequests';
import Loader from '@components/loaders/Loader';
import {
  backgroundQuaternaryLightMode,
  dark1,
  darkVariant1,
  red3,
  white,
} from '@assets/colors';
import { useMediaQuery } from 'react-responsive';
import InfosButton from '../../InfosButton';

interface ICadastreWorksAddressInformationProps {
  errParcelPrefix: string;
  errParcelSection: string;
  errParcelNumber: string;
  setErrParcelPrefix: Dispatch<SetStateAction<string>>;
  setErrParcelSection: Dispatch<SetStateAction<string>>;
  setErrParcelNumber: Dispatch<SetStateAction<string>>;
  setDivActive: Dispatch<SetStateAction<string | undefined>>;
}

function CadastreWorksAddressInformation({
  errParcelPrefix,
  errParcelSection,
  errParcelNumber,
  setErrParcelPrefix,
  setErrParcelSection,
  setErrParcelNumber,
  setDivActive,
}: ICadastreWorksAddressInformationProps): JSX.Element {
  const mobileSize = useMediaQuery({ maxWidth: 1280 });

  const {
    parcelPrefix,
    workCoordinates,
    updateParcelPrefix,
    parcelSection,
    updateParcelSection,
    parcelNumber,
    updateParcelNumber,
    updateFocusCadastre,
    updateFocusCustomerInfo,
    updateFocusTax,
  } = useContext(FolderContext);

  const { colorPrimary, installer, radiusPx, isThemeDark } =
    useContext(InstallerContext);
  const [displayMarker, setDisplayMarker] = useState(true);
  const [state, setState] = useState({
    lat: Number(workCoordinates.latitude),
    lng: Number(workCoordinates.longitude),
  });
  const [coordinates, setCoordinates] = useState([
    {
      lat: Number(workCoordinates.latitude),
      lng: Number(workCoordinates.longitude),
    },
  ]);
  const [zoom, setZoom] = useState(16);
  const [isLoading, setIsLoading] = useState(false);
  const [isErrorParcel, setIsErrorParcel] = useState(false);

  // ** PERSONALISATION DU POLYGONE DE LA MAP **
  const lineSymbol = {
    path: 'M 0,-1 0,1',
    fillOpacity: 1,
    scale: 2,
    strokeOpacity: 1,
    strokeColor: colorPrimary,
  };

  const lineOptions = {
    strokeColor: 'black',
    strokeOpacity: 0,
    icons: [
      {
        icon: lineSymbol,
        offset: '0',
        repeat: '.875rem',
      },
    ],
    path: coordinates.map((ll) => {
      return { lat: ll.lng, lng: ll.lat };
    }),
  };

  const updateInfoRightPart = () => {
    updateFocusTax(false);
    updateFocusCustomerInfo(false);
    updateFocusCadastre(true);
  };

  const moveMarker = (event: any) => {
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();
    setDisplayMarker(false);
    setIsLoading(true);

    getParcelMapFolder(
      { lat, lng },
      setCoordinates,
      updateParcelPrefix,
      updateParcelSection,
      updateParcelNumber,
      setIsLoading,
      setIsErrorParcel
    );
    setState({ lat, lng });
  };

  const mapStyles = [
    {
      featureType: 'poi',
      elementType: 'labels',
      stylers: [{ visibility: 'off' }],
    },
    {
      featureType: 'transit.station',
      stylers: [{ visibility: 'on' }],
    },
  ];

  const getMapOptions = {
    streetViewControl: false,
    scaleControl: true,
    zoomControl: true,
    fullscreenControl: true,
    dragginCursor: 'pointer',
    styles: mapStyles,
    disableDoubleClickZoom: true,
    minZoom: 5,
    maxZoom: 25,
    mapTypeControl: false,
    mapTypeId: 'hybrid',
    clickableIcons: true,
  };
  return (
    <div
      style={{ borderRadius: radiusPx }}
      className="dark:bg-dark-2 bg-white  p-[1.5rem] mt-[2rem] dark:text-white text-colorTextLightMode"
    >
      <div className="flex items-center justify-between mb-[.625rem]">
        <p className="sb-text-rem-body2">Cadastre</p>
        <InfosButton
          forcedDivActive="CadastreWorksAddressInformation"
          setDivActive={setDivActive}
        />
      </div>
      <p className="text-[.75rem] font-light mb-[1.5rem] leading-[1.5rem]">
        Vous pouvez identifier la parcelle correspondant à l&apos;adresse des
        travaux. Pour cela cliquer sur votre habitation sur la map ci-dessous.
      </p>
      {isErrorParcel && (
        <WarningNotification
          borderRadius={radiusPx}
          warningText="ll n'y a pas de Parcelle cadastrale pour cette adresse."
          backgroundColor={
            installer.nom_partenaire === 'DRAPO' ||
            installer.nom_partenaire === ''
              ? red3
              : installer.custom.couleur_alerte
          }
          color="#ffffff"
          addClass="my-[1.25rem]"
        />
      )}
      <div
        style={{
          borderRadius: radiusPx,
          height: !mobileSize ? '25rem' : '35rem',
          width: !mobileSize ? '35rem' : '102rem',
        }}
        className="overflow-hidden mb-[1rem] m-auto relative"
      >
        <div
          style={{ zIndex: 9999 }}
          className="absolute bottom-2 right-2 flex flex-col space-y-[.625rem] items-center rounded-[6px] p-1 bg-white shadow-sm"
        >
          <button
            onClick={() => setZoom(zoom + 1)}
            className="h-[1.25rem] w-[1.25rem] pt-1"
            type="button"
          >
            <img
              className="w-[.875rem] m-auto hover:opacity-60 opacity-80"
              src={plus}
              alt="zoom"
            />
          </button>
          <button
            onClick={() => setZoom(zoom - 1)}
            className="h-[1.25rem] w-[1.25rem]"
            type="button"
          >
            <img
              className="w-[.875rem] m-auto hover:opacity-60 opacity-80"
              src={minus}
              alt="dézoom"
            />
          </button>
        </div>
        <GoogleMap
          options={getMapOptions}
          zoom={zoom}
          center={state}
          mapContainerStyle={{
            height: !mobileSize ? '25rem' : '35rem',
            width: !mobileSize ? '35rem' : '102rem',
          }}
          onClick={(event) => moveMarker(event)}
        >
          {displayMarker && <Marker position={state} icon={marker} />}
          <Polygon
            path={coordinates.map((ll) => {
              return { lat: ll.lng, lng: ll.lat };
            })}
            key={1}
            options={{
              fillColor: colorPrimary,
              strokeColor: 'transparent',
              fillOpacity: 0.2,
              strokeOpacity: 1,
              strokeWeight: 3,
            }}
          />
          <Polyline
            path={coordinates.map((ll) => {
              return { lat: ll.lng, lng: ll.lat };
            })}
            options={lineOptions}
          />
        </GoogleMap>
      </div>
      {isLoading && <Loader />}
      <Input
        borderRadius={radiusPx}
        onClick={() => updateInfoRightPart()}
        label="Préfixe de parcelle"
        color={isThemeDark ? white : dark1}
        backgroundColor={
          isThemeDark ? darkVariant1 : backgroundQuaternaryLightMode
        }
        inputType="text"
        isNumber
        onChange={(e) => {
          updateParcelPrefix(e.target.value);
          if (errParcelPrefix !== '') {
            setErrParcelPrefix('');
          }
        }}
        value={!isLoading ? parcelPrefix : ' '}
        addClass={
          errParcelPrefix !== '' ? 'border border-red-500' : 'mb-[.875rem]'
        }
      />
      {errParcelPrefix !== '' && (
        <p className="text-xs text-red-500 mt-[.25rem] mb-[.875rem]">
          {errParcelPrefix}
        </p>
      )}
      <Input
        borderRadius={radiusPx}
        onClick={() => updateInfoRightPart()}
        label="Section de Parcelle"
        color={isThemeDark ? white : dark1}
        backgroundColor={
          isThemeDark ? darkVariant1 : backgroundQuaternaryLightMode
        }
        inputType="text"
        onChange={(e) => {
          updateParcelSection(e.target.value);
          if (errParcelSection !== '') {
            setErrParcelSection('');
          }
        }}
        value={!isLoading ? parcelSection : ' '}
        addClass={
          errParcelSection !== '' ? 'border border-red-500' : 'mb-[.875rem]'
        }
      />
      {errParcelSection !== '' && (
        <p className="text-xs text-red-500 mt-[.25rem] mb-[.875rem]">
          {errParcelSection}
        </p>
      )}
      <Input
        borderRadius={radiusPx}
        onClick={() => updateInfoRightPart()}
        label="Numéro de Parcelle"
        color={isThemeDark ? white : dark1}
        backgroundColor={
          isThemeDark ? darkVariant1 : backgroundQuaternaryLightMode
        }
        inputType="text"
        isNumber
        onChange={(e) => {
          updateParcelNumber(e.target.value);
          if (errParcelNumber !== '') {
            setErrParcelNumber('');
          }
        }}
        value={!isLoading ? parcelNumber : ' '}
        addClass={`${errParcelNumber !== '' && 'border border-red-500'}`}
      />
      {errParcelNumber !== '' && (
        <p className="text-xs text-red-500 mt-[.25rem]">{errParcelNumber}</p>
      )}
    </div>
  );
}

export default CadastreWorksAddressInformation;
