/* eslint-disable no-nested-ternary */
import React, { useContext } from 'react';
import { ArrowIcon } from '@assets/svgComponents/svgComponents';

import { colorTextLightMode, dark2, white } from '@assets/colors';
import { convertHexToRGBA } from '@utils/utils';
import { INextStepsType } from '@simulator/utils/simulatorTypes';
import { InstallerContext } from '@auth/utils/authContext';

interface Iprops {
  data: INextStepsType;
  style: string;
}

function ButtonNextStep({ data, style }: Iprops): JSX.Element {
  const { radiusPx, isThemeDark, colorPrimary } = useContext(InstallerContext);
  return (
    <div
      style={{
        borderRadius: radiusPx,
        backgroundColor:
          style !== data.title
            ? 'transparent'
            : isThemeDark
            ? dark2
            : convertHexToRGBA(colorPrimary, 0.1),
      }}
      className="w-full flex items-center justify-between ml-[.2rem] px-[.5rem] py-[.6rem]"
    >
      <p className="text-left">{data.title}</p>
      {style === data.title && (
        <ArrowIcon
          fill={isThemeDark ? white : colorTextLightMode}
          width="1rem"
          height="1rem"
        />
      )}
    </div>
  );
}

export default ButtonNextStep;
