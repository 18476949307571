/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useContext, useEffect, useState } from 'react';
import { v4 } from 'uuid';
import { getListCustomViewById } from '@dashboard/apiRequests/dashboardRequests';
import { toast } from 'react-toastify';
import { DashboardContext } from '@dashboard/utils/context/dashboardContext';
import { purple1, white } from '@assets/colors';
import { InstallerContext } from '@auth/utils/authContext';
import TotalAmountRows from './TotalAmountRows';
import { LayoutDashboardContext } from '../../utils/context/LayoutDashboardContext';
import RowsInListViewArray from './RowsInListViewArray';

function ListViewArray(): JSX.Element {
  const [foldersIsLoaded, setFoldersIsLoaded] = useState<boolean>(false);
  const [divScrollable, setDivScrollable] = useState<HTMLElement | null>(null);
  const [isEndFolders, setIsEndFolders] = useState<boolean>(false);

  const {
    currentSchemaFolderCustomView,
    currentListCustomView,
    idCurrentListCustomView,
    updateIsLoadingCustomView,
    updateCurrentListCustomView,
    numberPageList,
    updateNumberPageList,
    updateFiltersOrPersonalizationModalCustomView,
    updateCountsCustomListView,
    rowsSelectedCustomView,
    updateRowsSelectedCustomView,
  } = useContext(DashboardContext);

  const { installer, isThemeDark } = useContext(InstallerContext);

  const { isRetractableDDC } = useContext(LayoutDashboardContext);

  useEffect(() => {
    if (numberPageList === 1) {
      setIsEndFolders(false);
    }
  }, [numberPageList]);

  useEffect(() => {
    setDivScrollable(document.getElementById('scrollDiv'));
  }, []);

  useEffect(() => {
    if (isEndFolders) {
      toast('Vous avez atteint la fin de la liste');
    }
  }, [isEndFolders]);

  const infiniteScroll = async () => {
    const scrollColumns = document.getElementById('scrollableFixed');

    if (currentListCustomView !== undefined)
      if (
        currentListCustomView.data.length > 14 &&
        divScrollable &&
        divScrollable?.offsetHeight + divScrollable?.scrollTop >=
          divScrollable?.scrollHeight &&
        !foldersIsLoaded &&
        !isEndFolders
      ) {
        setFoldersIsLoaded(true);
        updateNumberPageList(numberPageList + 1);
        getListCustomViewById(
          idCurrentListCustomView,
          updateIsLoadingCustomView,
          updateCurrentListCustomView,
          numberPageList + 1,
          updateCountsCustomListView,
          updateRowsSelectedCustomView,
          setFoldersIsLoaded,
          currentListCustomView,
          setIsEndFolders,
          rowsSelectedCustomView
        );
      }
    if (divScrollable?.scrollLeft !== 0 && scrollColumns) {
      const scrollWithTimeOut = () => {
        scrollColumns.style.transform = `translateX(-${divScrollable?.scrollLeft}px)`;
      };
      setTimeout(scrollWithTimeOut, 40);
    }
  };

  const widthRowsAndColumns = (content: any) => {
    if (
      content.provider_id === 'fldn5YY8AGTC2wtkT' ||
      content.provider_id === 'fldIpwfMhyimarFHj' ||
      content.provider_id === 'fldpRfFD5izm60E1Y'
    ) {
      return '9.375rem';
    }
    if (
      content.provider_id === 'fldwKj7lXvUpeS28m' ||
      content.provider_id === 'fldOrj98UzXywrEAE' ||
      content.provider_id === 'fldDkH4qoF0bJG8rd'
    ) {
      return '18.75rem';
    }
    if (
      content.provider_id === 'fld1Bh2wK1RlbIRwF' ||
      content.provider_id === 'fldfk4ePNV7Ca4kkL' ||
      content.provider_id === 'fldKpAqrEqIWyIjyE'
    ) {
      return '25rem';
    }
    return '12.5rem';
  };

  const scrollBarColor = isThemeDark ? '' : 'scroll_light_mode';

  return (
    <div>
      {currentListCustomView?.data.length === 0 ? (
        <div className="text-[.875rem] px-[1.5rem] pt-[.625rem]">
          <p>
            Il n&apos;y a pas d&apos;opérations correspondantes aux filtres
            séléctionnés.
          </p>
          <p>
            Vous pouvez modifier les filtres{' '}
            <button
              type="button"
              onClick={() =>
                updateFiltersOrPersonalizationModalCustomView('Filtrer')
              }
              className="underline"
            >
              ici
            </button>
            .
          </p>
        </div>
      ) : (
        <div className="relative overflow-hidden pt-[2.8rem]">
          <div
            id="scrollDiv"
            style={{ transition: 'all 1s' }}
            onScroll={!foldersIsLoaded ? infiniteScroll : undefined}
            className={`${scrollBarColor} overflow-scroll ${
              isRetractableDDC ? 'lg:max-w-[71vw]' : 'lg:max-w-[60vw]'
            } max-h-[72vh] h-[72vh] dark:bg-[#131313] bg-white text-colorTextLightMode dark:text-white z-10`}
          >
            <table>
              <tbody>
                <tr
                  id="scrollableFixed"
                  className="absolute pr-[.375rem] top-0 dark:bg-[#131313] bg-white"
                >
                  <th className="px-[1.5rem] w-auto text-left">
                    <button
                      className={`w-full flex items-center ${
                        rowsSelectedCustomView.length > 0
                          ? 'visible'
                          : 'invisible'
                      }`}
                      onClick={() => updateRowsSelectedCustomView([])}
                      type="button"
                    >
                      <svg
                        width="1.125rem"
                        height="1.125rem"
                        viewBox="0 0 17 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          x="0.0637207"
                          width="16.0424"
                          height="15.9958"
                          fill={
                            installer.nom_partenaire === 'DRAPO' ||
                            installer.nom_partenaire === ''
                              ? purple1
                              : installer.custom.couleur_boutons
                          }
                        />
                        <path d="M3.07153 8H8.0848H13.0981" stroke="white" />
                      </svg>
                    </button>
                  </th>
                  {currentSchemaFolderCustomView.schema.fields.map((column) => {
                    return (
                      column.provider_name !== 'Accéder dossier' && (
                        <th key={v4()}>
                          <p
                            style={{ width: widthRowsAndColumns(column) }}
                            className={` dark:text-[#C6C6C6] text-colorTextLightMode py-[.375rem] h-[2.5rem] flex items-center justify-start text-left text-[.75rem] font-normal`}
                          >
                            {column.provider_name}
                          </p>
                        </th>
                      )
                    );
                  })}
                </tr>
                {currentListCustomView !== undefined &&
                  currentListCustomView.data !== undefined &&
                  currentListCustomView.data.map((folder) => {
                    return <RowsInListViewArray data={folder} />;
                  })}
              </tbody>
            </table>
            {foldersIsLoaded && (
              <div className="loader opacity-40 h-2">
                <div
                  style={{
                    backgroundColor: white,
                  }}
                  className="loaderBar"
                />
              </div>
            )}
          </div>
          <TotalAmountRows rowsSelected={rowsSelectedCustomView} />
        </div>
      )}
    </div>
  );
}

export default ListViewArray;
