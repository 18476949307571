/* eslint-disable no-nested-ternary */
import { Link, useLocation } from 'react-router-dom';
import { logout } from '@auth/utils/function';
import { AuthContext, InstallerContext } from '@auth/utils/authContext';
import { useContext, useState } from 'react';
import { DASHBOARD_TABS } from '@dashboard/utils/tabs';
import { convertHexToRGBA } from '@utils/utils';
import { colorTextLightMode, dark5, white } from '@assets/colors';
import { DashboardContext } from '../../utils/context/dashboardContext';

interface HomeLeftMenuCardIconsProps {
  title: string;
  icon: JSX.Element;
  iconLightModeHover: JSX.Element;
  iconDarkModeHover: JSX.Element;
  link: string;
}

function LinkButton({
  title,
  icon,
  link,
  iconLightModeHover,
  iconDarkModeHover,
}: HomeLeftMenuCardIconsProps): JSX.Element {
  const location = useLocation();
  const page = location.pathname;

  const [isShown, setIsShown] = useState(false);

  const { updateToken } = useContext(AuthContext);
  const {
    updateInstaller,
    updateInstallerIsLoaded,
    updateThemeChangedManually,
    isThemeDark,
    colorPrimary,
    colorTextButton,
    radiusPx,
  } = useContext(InstallerContext);

  const {
    updateActiveCustomView,
    updateCurrentListCustomView,
    updateNewConditions,
    updateElementActive,
  } = useContext(DashboardContext);

  const gotToDashboard = () => {
    updateActiveCustomView(false);
    updateCurrentListCustomView(undefined);
    updateNewConditions([]);
    updateElementActive(DASHBOARD_TABS.SUMMARY);
  };

  const colorText = isThemeDark
    ? white
    : colorTextButton === 'white'
    ? colorPrimary
    : colorTextLightMode;
  return (
    <>
      {title === 'Aide' || title.includes('P4') ? (
        <div className=" px-[1rem] mt-[.375rem] ">
          <a href={link} target="_blank" data-test-id={title} rel="noreferrer">
            <div
              style={{
                borderRadius: radiusPx === '0px' ? '0px' : '8px',
                backgroundColor:
                  isShown && !isThemeDark
                    ? convertHexToRGBA(colorPrimary, 0.1)
                    : page === link && isThemeDark && title !== 'Déconnexion'
                    ? dark5
                    : '',
              }}
              onMouseEnter={() => setIsShown(true)}
              onMouseLeave={() => setIsShown(false)}
              className="flex items-center space-x-[1.5rem] px-[2.625rem] py-[.875rem] dark:hover:bg-dark-5  w-full text-[#5D5D5F]  dark:text-white"
            >
              {isShown && !isThemeDark
                ? iconLightModeHover
                : isShown && isThemeDark
                ? iconDarkModeHover
                : icon}
              <div
                style={{
                  color: isThemeDark
                    ? 'white'
                    : convertHexToRGBA(
                        (page === link || isShown) && title !== 'Déconnexion'
                          ? colorText
                          : colorTextLightMode,
                        1
                      ),
                }}
                className="sb-text-rem-body2 whitespace-nowrap"
              >
                {title}
              </div>
            </div>
          </a>
        </div>
      ) : (
        <div className=" px-[1rem] mt-[.375rem] ">
          <Link to={link} data-test-id={title} onClick={() => gotToDashboard()}>
            <button
              style={{
                borderRadius: radiusPx === '0px' ? '0px' : '8px',
                backgroundColor:
                  (page === link || isShown) &&
                  !isThemeDark &&
                  title !== 'Déconnexion'
                    ? convertHexToRGBA(colorPrimary, 0.1)
                    : page === link && isThemeDark && title !== 'Déconnexion'
                    ? dark5
                    : '',
              }}
              type="button"
              onMouseEnter={() => setIsShown(true)}
              onMouseLeave={() => setIsShown(false)}
              className="flex items-center space-x-[1.5rem] px-[2.625rem] lg:py-[1.125rem] py-[1rem] dark:hover:bg-dark-5 w-full dark:text-white text-[#5D5D5F]"
              onClick={() => {
                if (title === 'Déconnexion') {
                  logout(
                    updateToken,
                    updateInstaller,
                    updateInstallerIsLoaded,
                    updateThemeChangedManually
                  );
                }
              }}
            >
              {isShown && !isThemeDark
                ? iconLightModeHover
                : isShown && isThemeDark
                ? iconDarkModeHover
                : icon}
              <div
                style={{
                  color: isThemeDark
                    ? 'white'
                    : convertHexToRGBA(
                        (page === link || isShown) && title !== 'Déconnexion'
                          ? colorText
                          : isShown && title === 'Déconnexion'
                          ? colorText
                          : colorTextLightMode,
                        1
                      ),
                }}
                className="sb-text-rem-body2 whitespace-nowrap"
              >
                {title}
              </div>
            </button>
          </Link>
        </div>
      )}
    </>
  );
}

export default LinkButton;
