import {
  useContext,
  useEffect,
  useRef,
  useState,
  Dispatch,
  SetStateAction,
  useMemo,
} from 'react';
import { Button, WarningNotification } from 'storybook-design-system-drapo';
import { scrollSmoothToPosition, useIsInViewport } from '@utils/utils';
import Loader from '@components/loaders/Loader';
import { FolderContext } from '@folder/utils/FoldersContext';
import {
  addCadastreToFolder,
  addClientToFolder,
  addTaxHouseholdToFolder,
  addIndivisionToFolder,
} from '@folder/apiRequests/folderRequests';
import { InstallerContext } from '@auth/utils/authContext';
import { red2 } from '@assets/colors';
import { IAccountMPR } from '@folder/utils/folderTypes';
import CadastreWorksAddressInformation from './forms/customerInformations/CadastreWorksAddressInformation';
import FormCustomerInformation from './forms/customerInformations/FormCustomerInformation';
import TaxHouseholdCustomerInformation from './forms/customerInformations/taxHousehold/TaxHouseholdCustomerInformation';
import Indivision from './forms/customerInformations/Indivision';
import { FormAccountMpr } from './forms/customerInformations/FormAccountMpr';

type CustomerInformationTypes = {
  setDivActive: Dispatch<SetStateAction<string | undefined>>;
};

function CustomerInformation({
  setDivActive,
}: CustomerInformationTypes): JSX.Element {
  const {
    updateStepNumberFolder,
    householdsDatas,
    menageIndivision,
    updateMenageIndivision,
    hasCadastre,
    parcelSection,
    parcelPrefix,
    parcelNumber,
    updateIsBack,
    updateClientInfosAlreadySet,
    clientInfosAlreadySet,
    hasIndivision,
    folderDatas,
    updateFolderDatas,
  } = useContext(FolderContext);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [clientInfosOk, setClientInfosOk] = useState<boolean>(false);
  const [errMail, setErrMail] = useState<string>('');
  const [errPhone, setErrPhone] = useState<string>('');
  const [errorOwnerAddress, setErrorOwnerAddress] = useState<string>('');

  const [cadastreOk, setCadastreOk] = useState<boolean>(false);
  const [errParcelPrefix, setErrParcelPrefix] = useState<string>('');
  const [errParcelSection, setErrParcelSection] = useState<string>('');
  const [errParcelNumber, setErrParcelNumber] = useState<string>('');

  const [indivisionOk, setIndivisionOk] = useState<boolean>(false);

  const [taxHouseholdOk, setTaxHouseholdOk] = useState<boolean>(false);
  /* const [isErrorTax, setIsErrorTax] = useState<boolean>(false);
  const [errorsTaxReference, setErrorsTaxReference] = useState<string[]>(['']);
  const [errorsTaxNumber, setErrorsTaxNumber] = useState<string[]>(['']);
  const [taxReferenceYearNMinus2, setTaxReferenceYearNMinus2] = useState<
    boolean[]
  >([false]); */
  const [checkGrantNotification, setCheckGrantNotification] =
    useState<boolean>(false);
  const [errorQuota, setErrorQuota] = useState<string>('');
  const [errorOnClickNext, setErrorOnClickNext] = useState<string>('');

  // IF MPR ACCOUNT
  const [dateDeposite, setDateDeposite] = useState<string>(
    new Date().toLocaleDateString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    })
  );
  const [birthDate, setBirthDate] = useState<string>('');
  const [errBirthDate, setErrBirthDate] = useState<string>('');
  const [errorDateDeposite, setErrorDateDeposite] = useState<boolean>(false);
  const [filesUploaded, setFilesUploaded] = useState<any[]>([]);
  const [fileToSendDeposite, setFileToSendDeposite] = useState<File>();
  const [isCheckDeclareIdMpr, setIsCheckDeclareIdMpr] =
    useState<boolean>(false);
  const [isCheckFolderDeposite, setIsCheckFolderDeposite] =
    useState<boolean>(false);

  const [dataAccountMpr, setDataAccountMpr] = useState<IAccountMPR>({
    id: '',
    password: '',
  });

  const containerRef = useRef<HTMLDivElement>(null);
  const infosRef = useRef<HTMLDivElement>(null);
  const cadastreRef = useRef<HTMLDivElement>(null);
  const indivisionRef = useRef<HTMLDivElement>(null);
  const taxHouseRef = useRef<HTMLDivElement>(null);

  const { colorPrimary, radiusPx, installer, colorTextButton } =
    useContext(InstallerContext);

  const infosTop = infosRef.current ? infosRef.current.offsetTop : 0;
  const cadastreTop = cadastreRef.current ? cadastreRef.current.offsetTop : 0;
  // const taxHoldTop = taxHouseRef.current ? taxHouseRef.current.offsetTop : 0;

  if (hasCadastre) {
    useIsInViewport(
      cadastreRef,
      'CadastreWorksAddressInformation',
      setDivActive
    );
  }
  const hasFiscalStep = useMemo(
    () => folderDatas.has_fiscal_step,
    [folderDatas]
  );
  useIsInViewport(infosRef, 'FormCustomerInformation', setDivActive);
  if (hasIndivision) {
    useIsInViewport(indivisionRef, 'Indivision', setDivActive);
  }
  if (hasFiscalStep) {
    useIsInViewport(
      taxHouseRef,
      'TaxHouseholdCustomerInformation',
      setDivActive
    );
  }

  const minus18years = () => {
    const today = new Date();
    return new Date(today.setFullYear(today.getFullYear() - 18));
  };

  const convertToDate = (data: string) => {
    const d = data.split('/');
    return new Date(`${d[2]}/${d[1]}/${d[0]}`);
  };

  /* const date = new Date();
  const lastCharYear = date.getFullYear().toString().substring(2, 4); */

  const checkClientIsOk = () => {
    if (clientInfosAlreadySet) {
      return true;
    }
    let clientIsValid = true;

    // Vérification du mail
    if (
      !folderDatas.client.email.match(/[a-z0-9_\-.]+@[a-z0-9_\-.]+\.[a-z]+/i)
    ) {
      setErrMail("Votre adresse mail n'est pas valide.");
      clientIsValid = false;
    } else {
      setErrMail('');
    }

    // Vérification du numéro de téléphone
    if (
      !folderDatas.client.phone.match(
        /^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/i
      )
    ) {
      setErrPhone("Votre numéro de téléphone n'est pas valide.");
      clientIsValid = false;
    } else {
      setErrPhone('');
    }

    // Vérification de la date de naissance
    const birthDateConverted = new Date(convertToDate(birthDate));

    if (
      folderDatas.has_mpr_step &&
      birthDateConverted &&
      (birthDateConverted > minus18years() ||
        !birthDate.match(
          /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/
        ))
    ) {
      setErrBirthDate(
        "La date de naissance n'est pas valide (18 ans minimum)."
      );
      clientIsValid = false;
    } else {
      setErrBirthDate('');
    }

    return clientIsValid;
  };
  /* const checkHouseholdIsOk = () => {
    let houseHoldValid = true;
    let taxReferenceN2Valid = true;

    for (let i = 0; i < householdsDatas.length; i += 1) {
      if (
        householdsDatas[i].reference.length < 13 ||
        householdsDatas[i].reference.length > 14
      ) {
        setErrorsTaxReference((oldErrors) => {
          const newErrors = oldErrors;
          newErrors[i] = 'La référence doit contenir entre 13 et 14 caractères';
          return newErrors;
        });
      }
      if (householdsDatas[i].numero.length !== 13) {
        setErrorsTaxNumber((oldErrors) => {
          const newErrors = oldErrors;
          newErrors[i] = 'Le numéro fiscal doit contenir 13 caractères';
          return newErrors;
        });
        houseHoldValid = false;
      }
      if (
        Number(householdsDatas[i].reference.substring(0, 2)) ===
          Number(lastCharYear) - 2 &&
        !checkGrantNotification
      ) {
        setTaxReferenceYearNMinus2((oldValues) => {
          const newValues = [...oldValues];
          newValues[i] = true;
          return newValues;
        });
        taxReferenceN2Valid = false;
      } else if (
        Number(householdsDatas[i].reference.substring(0, 2)) !==
          Number(lastCharYear) &&
        Number(householdsDatas[i].reference.substring(0, 2)) !==
          Number(lastCharYear) - 1 &&
        Number(householdsDatas[i].reference.substring(0, 2)) !==
          Number(lastCharYear) - 2
      ) {
        setErrorsTaxReference((oldErrors) => {
          const newErrors = oldErrors;
          newErrors[i] =
            "La référence de l'avis d'imposition doit être celle de l'année N (année en cours) ou N-1";
          return newErrors;
        });
        houseHoldValid = false;
      }
      if (!houseHoldValid) {
        setIsErrorTax(true);
      }
    }
    return houseHoldValid && taxReferenceN2Valid;
  }; */

  const checkCadastreIsOk = () => {
    let cadastreValid = true;
    if (hasCadastre) {
      if (parcelPrefix.length !== 3) {
        setErrParcelPrefix(
          'Le prefixe de la parcelle doit contenir 3 chiffres.'
        );
        cadastreValid = false;
      } else {
        setErrParcelPrefix('');
      }
      if (parcelSection.length !== 2) {
        setErrParcelSection('La section de parcelle doit contenir 2 lettres.');
        cadastreValid = false;
      } else {
        setErrParcelSection('');
      }
      if (parcelNumber.length !== 4) {
        setErrParcelNumber('Le numéro de parcelle doit contenir 4 chiffres.');
        cadastreValid = false;
      } else {
        setErrParcelNumber('');
      }
    }
    return cadastreValid;
  };

  const onClickNext = async () => {
    let scrollY = 0;
    /* let householdValid = true;
    if (hasFiscalStep) {
      householdValid = await checkHouseholdIsOk();
      if (!householdValid || taxReferenceYearNMinus2.includes(true)) {
        scrollY = taxHoldTop;
      }
    } */
    const cadastreValid = await checkCadastreIsOk();
    if (!cadastreValid) {
      scrollY = cadastreTop;
    }
    const clientValid = await checkClientIsOk();
    if (!clientValid) {
      scrollY = infosTop;
    }

    if (/* householdValid && */ cadastreValid && clientValid) {
      setIsLoading(true);
      let clientRequestOk = true;
      if (!clientInfosAlreadySet) {
        clientRequestOk = await addClientToFolder(
          folderDatas,
          updateFolderDatas,
          setClientInfosOk,
          setIsLoading,
          setErrMail,
          setErrorQuota,
          updateClientInfosAlreadySet,
          setErrorOwnerAddress,
          dateDeposite,
          fileToSendDeposite,
          dataAccountMpr,
          isCheckDeclareIdMpr,
          isCheckFolderDeposite,
          birthDate
        );
      } else {
        setClientInfosOk(true);
      }
      if (clientRequestOk) {
        let cadastreRequestOk = true;
        if (hasCadastre) {
          cadastreRequestOk = await addCadastreToFolder(
            folderDatas.folder_reference,
            parcelPrefix,
            parcelSection,
            parcelNumber,
            setCadastreOk,
            setIsLoading,
            setErrorOnClickNext
          );
        } else {
          setCadastreOk(true);
        }
        if (cadastreRequestOk) {
          let indivisionRequestOk = true;
          if (hasIndivision) {
            indivisionRequestOk = await addIndivisionToFolder(
              folderDatas.folder_reference,
              menageIndivision,
              setIndivisionOk,
              setIsLoading,
              setErrorOnClickNext
            );
          } else {
            setIndivisionOk(true);
          }
          if (indivisionRequestOk) {
            let taxHouseholdRequestOk = true;
            if (hasFiscalStep) {
              taxHouseholdRequestOk = await addTaxHouseholdToFolder(
                folderDatas.client.lastname,
                folderDatas.client.firstname,
                folderDatas.folder_reference,
                householdsDatas,
                setTaxHouseholdOk,
                setIsLoading,
                setErrorOnClickNext
              );
            } else {
              setTaxHouseholdOk(true);
            }
            if (!taxHouseholdRequestOk) {
              scrollSmoothToPosition(scrollY);
            }
          } else {
            scrollSmoothToPosition(infosTop);
          }
        } else {
          scrollSmoothToPosition(scrollY);
        }
      } else {
        scrollSmoothToPosition(scrollY);
      }
    } else if (containerRef) {
      scrollSmoothToPosition(scrollY);
    }
    updateIsBack(false);
  };

  const checkIfDisabled = () => {
    let conditions = [
      folderDatas.client.lastname,
      folderDatas.client.firstname,
      folderDatas.client.email,
      folderDatas.client.phone,
      /* hasFiscalStep ? householdsDatas[0].numero : 'empty',
      hasFiscalStep ? householdsDatas[0].reference : 'empty', */
    ];

    if (hasCadastre) {
      conditions = conditions.concat([
        parcelPrefix,
        parcelSection,
        parcelNumber,
      ]);
    }
    if (isCheckDeclareIdMpr) {
      conditions = conditions.concat([
        dataAccountMpr.id,
        dataAccountMpr.password,
      ]);
    }
    if (isCheckFolderDeposite) {
      conditions = conditions.concat([dateDeposite]);
      if (fileToSendDeposite === undefined) {
        return true;
      }
    }
    if (
      hasFiscalStep &&
      householdsDatas.findIndex((h, index) => {
        if (index === 0) {
          return false;
        }
        return h.declarant === '';
      }) !== -1
    ) {
      return true;
    }
    /* if (
      hasFiscalStep &&
      taxReferenceYearNMinus2.includes(true) &&
      !checkGrantNotification
    ) {
      return true;
    } */

    for (let i = 0; i < conditions.length; i += 1) {
      if (conditions[i] === '' || isLoading || conditions[i] === undefined) {
        return true;
      }
    }
    return false;
  };

  const error =
    errPhone !== '' ||
    errMail !== '' ||
    errParcelPrefix !== '' ||
    errParcelSection !== '' ||
    errParcelNumber !== '' ||
    errorQuota !== '' ||
    errorOnClickNext !== '';

  useEffect(() => {
    if (clientInfosOk && cadastreOk && taxHouseholdOk && indivisionOk) {
      setIsLoading(false);
      updateStepNumberFolder(2);
    }
    setDivActive('');
  }, [clientInfosOk, cadastreOk, taxHouseholdOk, indivisionOk]);

  useEffect(() => {
    const todayDate = new Date();
    const date = new Date(convertToDate(dateDeposite));

    const plus5years = () => {
      const result = new Date(todayDate);
      result.setFullYear(result.getFullYear() + 5);
      return result;
    };
    const minus5years = () => {
      const result = new Date(todayDate);
      result.setFullYear(result.getFullYear() - 5);
      return result;
    };

    if (dateDeposite !== '' && date <= plus5years() && date >= minus5years()) {
      setErrorDateDeposite(false);
    } else {
      setErrorDateDeposite(true);
    }
  }, [dateDeposite]);

  return (
    <div ref={containerRef}>
      {/* <div
            ref={containerRef}
            className={`snap-y snap-mandatory w-full h-[68vh] ${overflowStyle} pr-5 scroll-smooth pt-12`}
          >
      */}
      {/* <div ref={infosRef} className="w-full snap-center sticky h-full"> */}
      <div ref={infosRef}>
        {error && (
          <WarningNotification
            borderRadius={radiusPx}
            addClass="mt-[1.25rem]"
            backgroundColor={
              installer.nom_partenaire === 'DRAPO' ||
              installer.nom_partenaire === ''
                ? red2
                : installer.custom.couleur_alerte
            }
            color="white"
            warningText={`${
              errorQuota ||
              errorOnClickNext ||
              'Ceraines réponses ne sont pas correctes.'
            }`}
          />
        )}
        <FormCustomerInformation
          errMail={errMail}
          errPhone={errPhone}
          setErrMail={setErrMail}
          setDivActive={setDivActive}
          errorOwnerAddress={errorOwnerAddress}
          setErrorOwnerAddress={setErrorOwnerAddress}
          birthDate={birthDate}
          setBirthDate={setBirthDate}
          errBirthDate={errBirthDate}
        />
      </div>
      {folderDatas.has_mpr_step && (
        <FormAccountMpr
          dateDeposite={dateDeposite}
          setDateDeposite={setDateDeposite}
          filesUploaded={filesUploaded}
          setFilesUploaded={setFilesUploaded}
          dataAccountMpr={dataAccountMpr}
          setDataAccountMpr={setDataAccountMpr}
          setFileToSendDeposite={setFileToSendDeposite}
          isCheckDeclareIdMpr={isCheckDeclareIdMpr}
          setIsCheckDeclareIdMpr={setIsCheckDeclareIdMpr}
          isCheckFolderDeposite={isCheckFolderDeposite}
          setIsCheckFolderDeposite={setIsCheckFolderDeposite}
          errorDateDeposite={errorDateDeposite}
          setErrorDateDeposite={setErrorDateDeposite}
        />
      )}
      <>
        {/* <div ref={cadastreRef} className="w-full snap-center sticky h-full"> */}
        {hasCadastre && (
          <div ref={cadastreRef}>
            <CadastreWorksAddressInformation
              errParcelPrefix={errParcelPrefix}
              errParcelSection={errParcelSection}
              errParcelNumber={errParcelNumber}
              setErrParcelPrefix={setErrParcelPrefix}
              setErrParcelSection={setErrParcelSection}
              setErrParcelNumber={setErrParcelNumber}
              setDivActive={setDivActive}
            />
          </div>
        )}
        {/* <div ref={indivisionRef} className="w-full snap-center sticky h-full"> */}
        {hasIndivision && (
          <div ref={indivisionRef}>
            <Indivision
              isIndivision={menageIndivision}
              setIsIndivision={updateMenageIndivision}
              setDivActive={setDivActive}
            />
          </div>
        )}
        {/* <div ref={taxHouseRef} className="w-full snap-center sticky h-full"> */}
        {/* BLOC INFOS FISCALES DESACTIVE */}
        {hasFiscalStep && (
          <div ref={taxHouseRef}>
            <TaxHouseholdCustomerInformation
              /* setErrorsTaxReference={setErrorsTaxReference}
              setErrorsTaxNumber={setErrorsTaxNumber}
              errorsTaxReference={errorsTaxReference}
              errorsTaxNumber={errorsTaxNumber}
              isErrorTax={isErrorTax}
              setIsErrorTax={setIsErrorTax}
              setTaxReferenceYearNMinus2={setTaxReferenceYearNMinus2}
              taxReferenceYearNMinus2={taxReferenceYearNMinus2} */
              setDivActive={setDivActive}
              setCheckGrantNotification={setCheckGrantNotification}
              checkGrantNotification={checkGrantNotification}
            />
          </div>
        )}
        <div className="w-full pb-[3rem]">
          <Button
            borderRadius={radiusPx}
            disabled={checkIfDisabled()}
            addClass="mt-[1.5rem] !w-full !px-[1rem]"
            buttonArrowSmall
            label="Suivant"
            color={colorTextButton}
            backgroundColor={colorPrimary}
            arrow
            onClick={() => onClickNext()}
          />
          {isLoading && (
            <div className="mt-[1.5rem]">
              <Loader />
            </div>
          )}
        </div>
      </>
    </div>
  );
}

export default CustomerInformation;
