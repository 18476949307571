import { useContext, useEffect, useRef, useState } from 'react';
import { Document, Page, pdfjs, PDFPageProxy } from 'react-pdf';
import { InstallerContext } from '@auth/utils/authContext';
import { upload_dark_folder, upload_folder } from '@assets/images/images';
import { PdfNavigator } from '@components/PdfNavigator';
import { RenderRightPart } from './RenderRightPart';
import LoaderFolderRight from '../../../../components/loaders/LoaderFolderRight';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

type RightPartUploadFilesTypes = {
  fileToShow: string | undefined;
  divRightWidth: number | undefined;
  typeImage: boolean;
  rightPartActive: boolean;
};

function RightPartUploadFiles({
  fileToShow,
  divRightWidth,
  typeImage,
  rightPartActive,
}: RightPartUploadFilesTypes): JSX.Element {
  const [numPages, setNumPages] = useState<number>();
  const [pageNumber, setPageNumber] = useState<number>(1);

  const [isRotate, setIsRotate] = useState<boolean>(false);

  const uploadFilesInfos: JSX.Element[] = [
    <>
      Déposer ci-contre votre devis signé ou non signé et daté par votre client
      ainsi que les éventuels documents complémentaires. Merci de vous assurer
      que votre devis est bien conforme avant de nous le transmettre. Pour cela,{' '}
      <a
        target="_blank"
        href="https://guide.drapo.com"
        className="text-purple-1 no-underline"
        rel="noreferrer"
      >
        cliquez ici
      </a>{' '}
      pour lire notre guide.
    </>,
  ];

  const container = useRef<HTMLDivElement>(null);
  const [containerHeight, setContainerHeight] = useState<string>('');
  const [pdfWidth, setPdfWidth] = useState<number>(0);

  const { radiusPx, isThemeDark } = useContext(InstallerContext);

  useEffect(() => {
    if (container.current) {
      const divHeight = container.current.clientHeight;
      setContainerHeight(`${divHeight}px`);
    }
  }, [container, containerHeight]);

  const onDocumentLoadSuccess = (pageNum: number) => {
    setNumPages(pageNum);
    setPageNumber(1);
    if (isRotate) {
      setIsRotate(false);
    }
  };

  const handleLoadSuccess = (page: PDFPageProxy) => {
    if (page.width > page.height || page.width >= 842) {
      setIsRotate(true);
      setPdfWidth(page.width);
    }
  };

  const getSize = () => {
    if (fileToShow) {
      const img = new Image();
      img.src = fileToShow;
      img.onload = () => {
        if (img.width > img.height) {
          setIsRotate(true);
        } else {
          setIsRotate(false);
        }
      };
    }
  };

  return (
    <div
      style={{
        width: divRightWidth,
        maxWidth: divRightWidth,
      }}
      className="max-h-[70vh] rounded-[20px] fixed h-[70vh] z-[22222] overflow-hidden"
      ref={container}
    >
      {!rightPartActive ? (
        <>
          {fileToShow ? (
            <>
              {typeImage ? (
                <img
                  src={fileToShow}
                  alt="avant Travaux"
                  onLoad={getSize}
                  className={`${
                    isRotate ? 'rotate-90' : 'max-h-[90vh] h-[90vh]'
                  } rounded-[20px] left-[-1.25rem]`}
                  style={
                    isRotate
                      ? {
                          marginLeft: '-11.5rem',
                          marginTop: '10.875rem',
                          height: divRightWidth,
                          maxHeight: divRightWidth,
                          width: containerHeight,
                          maxWidth: containerHeight,
                        }
                      : {
                          marginTop: '-1.25rem',
                          width: divRightWidth,
                          maxWidth: divRightWidth,
                        }
                  }
                />
              ) : (
                <div
                  style={{
                    marginTop: '-1.25rem',
                    width: divRightWidth,
                    maxWidth: divRightWidth,
                  }}
                  className="max-h-[90vh] rounded-[20px] fixed h-[90vh] z-[22222]"
                >
                  <Document
                    file={fileToShow}
                    onLoadSuccess={(pdf) => {
                      onDocumentLoadSuccess(pdf.numPages);
                    }}
                    onLoadError={() => {
                      setNumPages(undefined);
                      setPageNumber(1);
                    }}
                    className="w-full"
                  >
                    {isRotate ? (
                      <Page
                        renderAnnotationLayer={false}
                        pageNumber={pageNumber}
                        onRenderSuccess={(page) => handleLoadSuccess(page)}
                        rotate={pdfWidth < 842 ? 90 : 180}
                        className={`${
                          pdfWidth >= 842 &&
                          'overflow-auto h-[90vh] max-h-[90vh]'
                        }`}
                      />
                    ) : (
                      <Page
                        renderAnnotationLayer={false}
                        pageNumber={pageNumber}
                        onRenderSuccess={(page) => handleLoadSuccess(page)}
                      />
                    )}
                  </Document>
                  <PdfNavigator
                    numberOfPages={numPages}
                    pageActive={pageNumber}
                    setPageActive={setPageNumber}
                  />
                </div>
              )}
            </>
          ) : (
            <div
              style={{
                marginTop: '-1.25rem',
                width: divRightWidth,
                borderRadius: radiusPx,
              }}
              className="max-h-[90vh]  fixed h-[90vh] z-[22222]"
            >
              <LoaderFolderRight />
            </div>
          )}
        </>
      ) : (
        <div
          style={{
            marginTop: '-1.25rem',
            width: divRightWidth,
            borderRadius: radiusPx,
            backgroundImage: `${
              isThemeDark
                ? `url(${upload_dark_folder})`
                : `url(${upload_folder})`
            }`,
            backgroundSize: 'cover',
          }}
          className="max-h-[90vh] fixed h-[90vh] dark:bg-black bg-white z-[22222]"
        >
          <RenderRightPart infosRightPart={uploadFilesInfos} />
        </div>
      )}
    </div>
  );
}

export default RightPartUploadFiles;
