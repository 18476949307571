/* eslint-disable no-nested-ternary */
import { numFormatSpace } from '@utils/utils';
import { ReactComponent as Checkbox } from '@assets/images/checkbox.svg';
import { ReactComponent as NotChecked } from '@assets/images/not_checked_white.svg';
import { Dispatch, SetStateAction, useContext } from 'react';
import { InstallerContext } from '@auth/utils/authContext';
import { colorTextLightMode, dark3, dark4, white } from '@assets/colors';
import { convertHexToRGBA } from '../../../../utils/utils';

interface CheckAcceptFeedsProps {
  accepted: boolean;
  setAccepted: Dispatch<SetStateAction<boolean>>;
  fees: number;
}

function CheckAcceptFeeds({
  accepted,
  setAccepted,
  fees,
}: CheckAcceptFeedsProps): JSX.Element {
  const { radiusPx, isThemeDark, colorPrimary, colorTextButton } =
    useContext(InstallerContext);
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div
      style={{
        borderRadius: radiusPx,
        backgroundColor:
          !accepted && isThemeDark
            ? dark4
            : accepted && isThemeDark
            ? dark3
            : accepted && !isThemeDark
            ? colorPrimary
            : convertHexToRGBA(colorPrimary, 0.1),
      }}
      className="flex w-full items-center cursor-pointer mt-[1rem] py-[2rem] px-[1.5rem] dark:text-white text-colorTextLightMode"
      onClick={() => {
        setAccepted(!accepted);
      }}
    >
      {accepted ? (
        <Checkbox fill={colorTextButton} className="w-[1.5rem] h-[1.5rem]" />
      ) : (
        <NotChecked
          fill={isThemeDark ? white : colorTextLightMode}
          className="w-[1.5rem] h-[1.5rem]"
        />
      )}
      <div
        style={{
          color: isThemeDark
            ? white
            : accepted && !isThemeDark
            ? colorTextButton
            : !accepted && !isThemeDark
            ? colorTextLightMode
            : '',
        }}
        className="lg:ml-[1.5rem] ml-[.375rem]"
      >
        J&apos;accepte les frais de dossier de {numFormatSpace(fees)} € HT.
      </div>
    </div>
  );
}

export { CheckAcceptFeeds };
