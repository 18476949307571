import {
  getListCustomViewById,
  getOneSchemaCustomViewById,
} from '@dashboard/apiRequests/dashboardRequests';
import { DashboardContext } from '@dashboard/utils/context/dashboardContext';
import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import { ChevronIcon } from '@assets/svgComponents/svgComponents';
import { colorTextLightMode, white } from '@assets/colors';
import { InstallerContext } from '@auth/utils/authContext';
import { ICurrentSchemaCustomView } from '../utils/dashboardTypes';

interface IProps {
  datas: ICurrentSchemaCustomView;
  setIsList: Dispatch<SetStateAction<boolean>>;
}
function ListCustomViewHeader({ datas, setIsList }: IProps): JSX.Element {
  const [hoverMouse, setHoverMouse] = useState<boolean>(false);
  const {
    updateActiveCustomView,
    updateIdCurrentListCustomView,
    updateNameCurrentListCustomView,
    updateCurrentSchemaFolderCustomView,
    updateIsLoadingCustomView,
    updateCurrentListCustomView,
    updateFiltersOrPersonalizationModalCustomView,
    numberPageList,
    updateNumberPageList,
    updateCountsCustomListView,
    updateRowsSelectedCustomView,
  } = useContext(DashboardContext);

  const { isThemeDark } = useContext(InstallerContext);

  useEffect(() => {
    updateNumberPageList(1);
  }, []);

  const accessToCustomView = async () => {
    updateFiltersOrPersonalizationModalCustomView('');
    updateNameCurrentListCustomView(datas.name);
    updateIdCurrentListCustomView(datas.id);
    getOneSchemaCustomViewById(
      datas.id,
      updateCurrentSchemaFolderCustomView,
      updateIsLoadingCustomView
    );
    getListCustomViewById(
      datas.id,
      updateIsLoadingCustomView,
      updateCurrentListCustomView,
      numberPageList,
      updateCountsCustomListView,
      updateRowsSelectedCustomView
    );
    setIsList(true);
    updateActiveCustomView(true);
  };

  return (
    <button
      onMouseEnter={() => setHoverMouse(true)}
      onMouseLeave={() => setHoverMouse(false)}
      onClick={() => accessToCustomView()}
      className="text-left hover:opacity-80 text-[.875rem] px-[.5rem] flex items-center relative  hover:scale-[1.05]"
      type="button"
    >
      <div style={{ zIndex: 1 }}>
        <ChevronIcon
          width="1.5rem"
          height="1.5rem"
          fill={isThemeDark ? white : colorTextLightMode}
          className="mr-[.5rem] absolute top-[-.1rem]"
          style={{
            left: hoverMouse ? '-.1rem' : '0rem',
            transition: 'all 0.2s',
          }}
        />
      </div>

      <div
        style={{
          zIndex: 2,
          marginLeft: hoverMouse ? '1rem' : '0rem',
          transition: 'all 0.5s',
        }}
        className="dark:bg-[#272727] bg-white"
      >
        {' '}
        {datas.name}
      </div>
    </button>
  );
}

export default ListCustomViewHeader;
