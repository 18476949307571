/* eslint-disable no-nested-ternary */
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';

import { Button } from 'storybook-design-system-drapo';
import { AuthContext, InstallerContext } from '@auth/utils/authContext';
import { FoldersAndSimulatorContext } from '@folder/utils/FoldersContext';
import { createFolder } from '@folder/apiRequests/folderRequests';
import { numFormatSpace } from '@utils/utils';
import Loader from '@components/loaders/Loader';
import {
  IOperationDatasType,
  IPartnerBonusType,
  IRecapGeneralType,
} from '@simulator/utils/simulatorTypes';
import {
  OperationsListContext,
  SimulatorContext,
} from '@simulator/utils/simulatorContext';
import { CheckCardPrime } from '@simulator/components/summary/CheckCardPrime';
import { CheckAcceptFeeds } from '@simulator/components/summary/CheckAcceptFeeds';
import { ReactComponent as InfoIcon } from '@assets/images/info_white.svg';
import { ModalWhiteLabel } from './modalSendRecapMail/ModalWhiteLabel';

interface CardSummaryProps {
  operationsSelected: IOperationDatasType[];
  TVA: number;
  folderFeeMpr: number;
  setFolderFeeMpr: Dispatch<SetStateAction<number>>;
  folderFeeCee: number;
  setFolderFeeCee: Dispatch<SetStateAction<number>>;
  setError: Dispatch<SetStateAction<string>>;
  setErrorQuota: Dispatch<SetStateAction<string[]>>;
  mprIsSelect: boolean;
  setMprIsSelect: Dispatch<SetStateAction<boolean>>;
  ceeIsSelect: boolean;
  setCeeIsSelect: Dispatch<SetStateAction<boolean>>;
  totalFolderFees: number;
  setTotalFolderFees: Dispatch<SetStateAction<number>>;
  setDetailDropdownOpen: Dispatch<SetStateAction<boolean>>;
}

function CardSummary({
  operationsSelected,
  TVA,
  folderFeeMpr,
  setFolderFeeMpr,
  folderFeeCee,
  setFolderFeeCee,
  setError,
  setErrorQuota,
  mprIsSelect,
  setMprIsSelect,
  ceeIsSelect,
  setCeeIsSelect,
  totalFolderFees,
  setTotalFolderFees,
  setDetailDropdownOpen,
}: CardSummaryProps): JSX.Element {
  const { token } = useContext(AuthContext);
  const { installer, radiusPx, colorPrimary, colorTextButton, isThemeDark } =
    useContext(InstallerContext);
  const { simulatorDatas, cadastreDatas } = useContext(SimulatorContext);
  const { updateFolderReference, updateOwnerOrLandlord } = useContext(
    FoldersAndSimulatorContext
  );
  const { updateOperationList, operationList } = useContext(
    OperationsListContext
  );
  const router = useNavigate();
  const location = useLocation();

  const [totalPrimes, setTotalPrimes] = useState(0);
  const [totalMpr, setTotalMpr] = useState(0);
  const [totalCee, setTotalCee] = useState(0);
  const [bonusPartnerHT, setBonusPartnerHT] = useState(0);
  const [bonusPartnerTTC, setBonusPartnerTTC] = useState(0);

  const [isLoading, setIsLoading] = useState(false);
  const [mprDeselectForced, setMprDeselectForced] = useState(false);
  // const [ceeDeselectForced, setCeeDeselectForced] = useState(false);
  const [feesAccepted, setFeesAccepted] = useState(false);
  const [limitMprChecked, setLimitMprChecked] = useState(false);
  // const [cappedActive, setCappedActive] = useState(false);

  /* const cappedPresent =
    operationList.findIndex((op) => op.primes.ecretement) !== -1; */

  const [isSendEmailModal, setIsSendEmailModal] = useState(false);

  const isWhiteLabel = location.pathname.includes('wl');

  const noCee = () => {
    if (!installer.eligibility?.includes('CEE')) {
      return true;
    }
    return (
      operationsSelected.length > 0 &&
      !operationsSelected
        .map((operation) => operation.eligibility.includes('CEE'))
        .includes(true)
    );
  };

  const noMpr = () => {
    if (!installer.eligibility?.includes('MPR')) {
      return true;
    }
    return (
      operationsSelected.length > 0 &&
      !operationsSelected
        .map((operation) => operation.eligibility.includes('MPR'))
        .includes(true)
    );
  };

  const summaryCardDatas: Array<IRecapGeneralType> = [
    {
      title: 'Primes totales',
      content: `${numFormatSpace(totalPrimes)} €`,
    },
    {
      title: `Frais de dossier Hors Taxes`,
      content: `${numFormatSpace(totalFolderFees)} €`,
    },
    {
      title: 'Bonus Partenaire HT',
      content: `${numFormatSpace(bonusPartnerHT)} €`,
    },
    {
      title: 'TVA récupérable sur dossier',
      content: `${numFormatSpace(TVA)} €`,
    },
    {
      title: 'Vous recevrez',
      content: `${numFormatSpace(
        totalPrimes - totalFolderFees + bonusPartnerTTC
      )} € TTC`,
    },
  ];

  const validateSummary = () => {
    if (!isWhiteLabel) {
      setIsLoading(true);
      createFolder(
        simulatorDatas,
        operationsSelected,
        router,
        updateFolderReference,
        updateOwnerOrLandlord,
        setIsLoading,
        setError,
        setErrorQuota,
        installer.eligibility.includes('CEE') ? ceeIsSelect : false,
        mprIsSelect,
        cadastreDatas
      );
    } else {
      const html = document.getElementsByTagName('html')[0];
      html.classList.add('not-scroll');
      setIsSendEmailModal(true);
    }
  };

  /* const onCheckCeeCard = () => {
    setCeeDeselectForced(ceeIsSelect);
    setCeeIsSelect(!ceeIsSelect);
    if (cappedPresent) {
      updateOperationList((oldOperationList) => {
        const newOperationList = [...oldOperationList];
        for (let i = 0; i < newOperationList.length; i += 1) {
          // Association des primes MPR à la liste des opérations
          newOperationList[i].primes.mpr = !cappedActive
            ? newOperationList[i].primes.mpr_uncapped || 0
            : newOperationList[i].primes.calculated?.mpr || 0;
          // Association des frais de dossier MPR à la liste des opérations
          newOperationList[i].primes.fees.MPR.feesNoLimit = !cappedActive
            ? newOperationList[i].primes.feesMprNoLimit_uncapped || 0
            : newOperationList[i].primes.calculated?.feesMprNoLimit || 0;
          newOperationList[i].primes.fees.MPR.fees = !cappedActive
            ? newOperationList[i].primes.feesMpr_uncapped
            : newOperationList[i].primes.calculated?.feesMpr;

          // Association des bonus à la liste des opérations
          // Transformation des bonus pour retirer la première partie des clés
          const oldBonus = !cappedActive
            ? newOperationList[i].primes.bonus_uncapped
            : newOperationList[i].primes.calculated?.bonus;
          const bonusToPush: IBonusType | undefined =
            oldBonus &&
            (oldBonus.length > 0 || Object.keys(oldBonus).length > 0)
              ? {}
              : undefined;
          if (oldBonus && bonusToPush) {
            Object.keys(oldBonus).forEach((key) => {
              bonusToPush[`${key.split('-')[1]}`] = oldBonus[key];
            });
          }
          newOperationList[i].primes.bonus = bonusToPush;
        }
        return newOperationList;
      });
      setCappedActive(!cappedActive);
    }
  }; */

  useEffect(() => {
    if (operationsSelected.length > 0) {
      let cee = 0;
      let totalcee = 0;
      let mpr = 0;
      let totalmpr = 0;
      let total = 0;
      let fraisMprHt = 0;
      let totalFraisMprHt = 0;
      let lowerLimitMpr = 0;
      let upperLimitMpr = 0;
      let totalFraisCeeHt = 0;
      let partnerBonusHT = 0;
      let partnerBonusTTC = 0;
      const limitMpr = operationsSelected[0].primes.max_mpr || 99999;
      for (let i = 0; i < operationsSelected.length; i += 1) {
        const operation = operationsSelected[i];
        let mprBonus = 0;
        if (
          operation.primes.bonus &&
          Object.keys(operation.primes.bonus).length > 0
        ) {
          const bonusKeys = Object.keys(operation.primes.bonus);
          for (let j = 0; j < bonusKeys.length; j += 1) {
            const isBonusAudit =
              bonusKeys[j] === 'bar-se-1000' || bonusKeys[j] === 'bar-se-1001';
            if (bonusKeys[j] === 'partner') {
              const currentBonusPartner = operation.primes.bonus
                .partner as IPartnerBonusType;
              if (!noCee() && ceeIsSelect) {
                partnerBonusHT += currentBonusPartner.ht;
                partnerBonusTTC += currentBonusPartner.ttc;
              } else {
                partnerBonusHT = 0;
                partnerBonusTTC = 0;
              }
            } else if (isBonusAudit) {
              if (operationsSelected.length > 1) {
                mprBonus += operation.primes.bonus[bonusKeys[j]] as number;
              }
            } else {
              mprBonus += operation.primes.bonus[bonusKeys[j]] as number;
            }
          }
        }
        mpr = Math.round((operation.primes.mpr + mprBonus) * 100) / 100;
        fraisMprHt =
          operation.primes.fees.MPR.fees &&
          operation.eligibility.includes('MPR')
            ? operation.primes.fees.MPR.fees
            : !operation.eligibility.includes('MPR')
            ? 0
            : operation.primes.fees.MPR.feesNoLimit;
        lowerLimitMpr = operation.primes.fees.MPR.lowerLimit;
        upperLimitMpr = operation.primes.fees.MPR.upperLimit;
        if (fraisMprHt > upperLimitMpr) {
          totalFraisMprHt += upperLimitMpr;
        } else if (fraisMprHt < lowerLimitMpr && mpr !== 0) {
          totalFraisMprHt += lowerLimitMpr;
        } else {
          totalFraisMprHt += fraisMprHt;
        }

        totalmpr += mpr;

        const ceeUse =
          operation.primes.cdp === 0
            ? operation.primes.cee
            : operation.primes.cdp;
        cee = Math.round(ceeUse * 100) / 100;
        if (operation.primes.fees.CEE) {
          totalFraisCeeHt += operation.primes.fees.CEE.ht;
        }
        totalcee += cee;
      }
      if (totalmpr === 0) {
        setMprIsSelect(false);
      }
      if (totalmpr > 0 && !mprDeselectForced) {
        // if (totalmpr > 0) {
        setMprIsSelect(true);
      }
      // -------------EGALISATION DES PRIMES, BONUS ET FRAIS MPR QUAND LE PLAFOND EST ATTEINT---------------------------
      // SI le total des primes MPR est différent de la limite MPR (donc déjà calculé)
      // ET si il est supérieur à la limite
      // OU si on doit regarder le plafond MPR
      if (
        (Math.round(totalmpr) > limitMpr || limitMprChecked) &&
        Math.round(totalmpr) !== limitMpr
      ) {
        if (limitMprChecked) {
          totalmpr = 0;
          for (let i = 0; i < operationsSelected.length; i += 1) {
            const operation = operationsSelected[i];
            if (
              operation.primes.calculated &&
              operation.eligibility.includes('MPR')
            ) {
              let mprBonus = 0;
              if (
                operation.primes.calculated.bonus &&
                Object.keys(operation.primes.calculated.bonus).length > 0
              ) {
                const bonusKeys = Object.keys(
                  operation.primes.calculated.bonus
                );
                for (let j = 0; j < bonusKeys.length; j += 1) {
                  const isBonusAudit =
                    bonusKeys[j] === 'calc-bar-se-1000' ||
                    bonusKeys[j] === 'calc-bar-se-1001';
                  if (isBonusAudit) {
                    if (operationsSelected.length > 1) {
                      mprBonus += operation.primes.calculated.bonus[
                        bonusKeys[j]
                      ] as number;
                    }
                  } else if (bonusKeys[j] !== 'calc-partner') {
                    mprBonus += operation.primes.calculated.bonus[
                      bonusKeys[j]
                    ] as number;
                  }
                }
              }
              totalmpr +=
                Math.round((operation.primes.calculated.mpr + mprBonus) * 100) /
                100;
            }
          }
        } else if (totalmpr < limitMpr) {
          setLimitMprChecked(false);
        } else {
          setLimitMprChecked(true);
        }
        const operationListTransformed = [...operationList];
        const reductionLimitMpr = limitMpr / Math.round(totalmpr);
        for (let i = 0; i < operationListTransformed.length; i += 1) {
          const op = operationListTransformed[i];
          const calculated = op.primes.calculated
            ? JSON.parse(JSON.stringify(op.primes.calculated))
            : undefined;
          const isSelected =
            operationsSelected.findIndex(
              (operation) => operation.id === op.id
            ) !== -1;
          const operationOriginalFeesMpr = calculated?.feesMpr
            ? calculated?.feesMpr
            : calculated?.feesMprNoLimit;
          const operationOriginalMpr = calculated?.mpr;
          const operationOriginalBonus = calculated?.bonus;
          if (operationOriginalMpr && operationOriginalFeesMpr) {
            // Quand il n'y a pas de réduction
            if (reductionLimitMpr > 1) {
              // Primes MPR d'origine
              op.primes.mpr = operationOriginalMpr;
              // Frais de dossier MPR d'origine
              if (op.primes.fees.MPR.fees) {
                op.primes.fees.MPR.fees = operationOriginalFeesMpr;
              } else {
                op.primes.fees.MPR.feesNoLimit = operationOriginalFeesMpr;
              }
              setLimitMprChecked(false);
            } else {
              // Application de la réduction sur la prime MPR
              op.primes.mpr = isSelected
                ? operationOriginalMpr * reductionLimitMpr
                : operationOriginalMpr;
              // Application de la réduction sur les frais de dossier MPR
              if (op.primes.fees.MPR.fees) {
                op.primes.fees.MPR.fees = isSelected
                  ? operationOriginalFeesMpr * reductionLimitMpr
                  : operationOriginalFeesMpr;
              } else {
                op.primes.fees.MPR.feesNoLimit = isSelected
                  ? operationOriginalFeesMpr * reductionLimitMpr
                  : operationOriginalFeesMpr;
              }
            }
          }
          if (
            operationOriginalBonus &&
            Object.keys(operationOriginalBonus).length > 0 &&
            op.primes.bonus &&
            Object.keys(op.primes.bonus).length > 0
          ) {
            const bonusKeys = Object.keys(op.primes.bonus);
            for (let j = 0; j < bonusKeys.length; j += 1) {
              const isBonusAudit =
                bonusKeys[j] === 'bar-se-1000' ||
                bonusKeys[j] === 'bar-se-1001';
              const bonusOriginalValue = Number(
                operationOriginalBonus[`calc-${bonusKeys[j]}`]
              );
              if (reductionLimitMpr > 1) {
                op.primes.bonus[bonusKeys[j]] = bonusOriginalValue;
              } else if (isBonusAudit) {
                if (operationsSelected.length > 1) {
                  op.primes.bonus[bonusKeys[j]] = isSelected
                    ? bonusOriginalValue * reductionLimitMpr
                    : bonusOriginalValue;
                }
              } else if (bonusKeys[j] !== 'partner') {
                op.primes.bonus[bonusKeys[j]] = isSelected
                  ? bonusOriginalValue * reductionLimitMpr
                  : bonusOriginalValue;
              }
            }
          }
        }
        updateOperationList(operationListTransformed);
        totalmpr = limitMpr;
        setTotalMpr(limitMpr);
      }
      //---------------------------------------------------------------------------------------------------------------
      else if (limitMprChecked) {
        totalmpr = limitMpr;
        setTotalMpr(limitMpr);
      } else {
        setTotalMpr(!noMpr() ? Number(totalmpr.toFixed(2)) : 0);
      }
      if (totalcee === 0) {
        setCeeIsSelect(false);
      }
      // if (totalcee > 0 && !ceeDeselectForced) {
      if (totalcee > 0) {
        setCeeIsSelect(true);
      }
      setTotalCee(Number(totalcee.toFixed(2)));
      if (ceeIsSelect) {
        total += totalcee;
      }
      if (mprIsSelect) {
        total += totalmpr;
      }
      setTotalPrimes(total);
      setFolderFeeMpr(
        !installer.eligibility?.includes('MPR') ? 0 : totalFraisMprHt
      );
      setBonusPartnerHT(partnerBonusHT);
      setBonusPartnerTTC(partnerBonusTTC);
      setFolderFeeCee(
        !installer.eligibility?.includes('CEE') || totalFraisCeeHt < 0
          ? 0
          : totalFraisCeeHt
      );
      if (!ceeIsSelect) {
        totalFraisCeeHt = 0;
      }
      if (!mprIsSelect) {
        totalFraisMprHt = 0;
      }
      setTotalFolderFees(totalFraisMprHt + totalFraisCeeHt);
    }
  }, [operationsSelected, mprIsSelect, ceeIsSelect, totalMpr]);

  return (
    <div
      style={{
        borderRadius: radiusPx,
        border:
          location.search.includes('?integration=iframe') && !isThemeDark
            ? '1px solid #d2d2d2'
            : '',
      }}
      data-test-id="cardSummary"
      className="w-full dark:bg-dark-1 bg-white dark:text-white text-colorTextLightMode sb-text-rem-body2 lg:p-[2.5rem] p-[1rem]"
    >
      {isSendEmailModal && (
        <ModalWhiteLabel
          setisSendEmailModal={setIsSendEmailModal}
          operationsSelected={operationsSelected}
          ceeIsSelect={ceeIsSelect}
          mprIsSelect={mprIsSelect}
        />
      )}
      <div className="pb-[1.5rem]">Récapitulatif</div>
      <div className="flex items-center justify-center lg:space-x-[2rem] space-x-[.375rem]">
        <CheckCardPrime
          checked={noMpr() ? false : mprIsSelect}
          initialAmount={numFormatSpace(totalMpr)}
          label="Prime MPR"
          onClick={() => {
            setMprDeselectForced(mprIsSelect);
            setMprIsSelect(!mprIsSelect);
          }}
          notCheckable={noMpr() || totalMpr === 0}
          folderFee={
            totalMpr === 0 ? numFormatSpace(0) : numFormatSpace(folderFeeMpr)
          }
        />
        <CheckCardPrime
          checked={noCee() ? false : ceeIsSelect}
          initialAmount={numFormatSpace(totalCee)}
          label={`Prime CEE${
            !installer.eligibility?.includes('CEE')
              ? ` partenaire${operationsSelected.length > 1 ? 's' : ''}`
              : ''
          }`}
          /* onClick={onCheckCeeCard} */
          notCheckable={noCee() || totalCee === 0}
          folderFee={numFormatSpace(folderFeeCee)}
        />
      </div>
      <div className="w-full pt-[1.875rem]">
        <ul className="w-full">
          {summaryCardDatas.map((data: IRecapGeneralType, index: number) => {
            const isTvaLine = data.title.includes('TVA');
            if (isTvaLine && totalFolderFees === 0) {
              return null;
            }

            const isPartnerLine = data.title
              .toLowerCase()
              .includes('partenaire');
            if (isPartnerLine && bonusPartnerHT === 0) {
              return null;
            }

            return (
              <li
                key={data.title}
                className={`flex ${
                  index === 0 ? 'pb-[.625rem]' : 'py-[.625rem]'
                } animate__animated animate__fadeIn`}
              >
                <div className="flex w-7/12 text-left dark:text-[#C6C6C6] text-dark-1 font-medium items-center lg:space-x-[1rem] space-x-[.25rem]">
                  <div>{data.title}</div>
                  {isTvaLine || isPartnerLine ? (
                    <a
                      href="#detail-dropdown"
                      className={`${
                        isPartnerLine &&
                        'group relative inline-block duration-300'
                      }`}
                    >
                      <InfoIcon
                        className="cursor-pointer"
                        fill="#C6C6C6"
                        onClick={() => {
                          if (isTvaLine) {
                            setDetailDropdownOpen(true);
                          }
                        }}
                        width="1.5rem"
                        height="1.5rem"
                      />
                      {isPartnerLine && (
                        <span className="font-normal absolute hidden group-hover:flex -top-5 -right-3 translate-x-full w-[21rem] p-[.5rem] bg-lightMode-dark-4 dark:bg-dark-4 rounded-[.5rem] text-justify dark:text-white text-colorTextLightMode before:content-[''] before:absolute before:top-1/2  before:right-[100%] before:-translate-y-1/2 before:border-[.5rem] before:border-y-transparent before:border-l-transparent before:border-r-dark-4">
                          Sous réserve d&apos;envoi de la facture d&apos;achat
                          du matériel chez le partenaire.
                        </span>
                      )}
                    </a>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="w-5/12 text-right">{data.content}</div>
              </li>
            );
          })}
        </ul>
      </div>
      {totalFolderFees > 0 && !location.pathname.includes('wl') && (
        <CheckAcceptFeeds
          accepted={feesAccepted}
          setAccepted={setFeesAccepted}
          fees={totalFolderFees}
        />
      )}
      <div className="mt-[1.5rem]">
        <div className="w-full">
          <Button
            borderRadius={radiusPx}
            label={
              isWhiteLabel
                ? 'Recevoir le récapitulatif par email'
                : 'Valider la création de mon dossier'
            }
            color={colorTextButton}
            backgroundColor={colorPrimary}
            arrow
            addClass="mx-auto lg:mx-0 !text-[.75rem] !w-full"
            disabled={
              isLoading ||
              totalPrimes === 0 ||
              totalCee === 0 ||
              (!isWhiteLabel && !feesAccepted && totalFolderFees !== 0) ||
              (!isWhiteLabel && (token === '' || !token))
            }
            onClick={() => validateSummary()}
            dataTestId="validate"
          />
        </div>
      </div>
      <div />
      {isLoading && (
        <div className="mt-[1rem]">
          <Loader />
        </div>
      )}
    </div>
  );
}

export { CardSummary };
