import { useContext } from 'react';
import { FolderContext } from '@folder/utils/FoldersContext';
import { InstallerContext } from '@auth/utils/authContext';
import { ChevronIcon } from '@assets/svgComponents/svgComponents';

function BackStepButton(): JSX.Element {
  const { updateStepNumberFolder, stepNumberFolder, updateIsBack } =
    useContext(FolderContext);

  const { isThemeDark } = useContext(InstallerContext);

  const backStepFolder = () => {
    if (stepNumberFolder === 2) {
      updateIsBack(true);
    }
    updateStepNumberFolder(stepNumberFolder - 1);
  };

  return (
    <button
      className="cursor-pointer flex items-center sb-text-rem-subtitle2 pb-[2rem] dark:text-white text-dark-1"
      type="button"
      onClick={() => backStepFolder()}
    >
      <ChevronIcon
        width="1.25rem"
        height="1.25rem"
        fill={isThemeDark ? 'white' : '#191919'}
        transform="rotate(180)"
      />
      <p>Etape précédente</p>
    </button>
  );
}

export default BackStepButton;
