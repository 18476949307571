/* eslint-disable no-nested-ternary */
import { alert } from '@assets/images/images';
import { InstallerContext } from '@auth/utils/authContext';
import {
  getDetailFileById,
  getListFilesImported,
} from '@bulkImport/apiRequests/bulkImportRequest';
import { BulkImportContext } from '@bulkImport/utils/bulkImportContext';
import { convertHexToRGBA, transformLongString } from '@utils/utils';
import { useContext, useEffect } from 'react';
import {
  borderLightMode,
  dark4,
  red1,
  colorTextLightMode,
  white,
  dark1,
} from '@assets/colors';
import { ArrowIcon } from '@assets/svgComponents/svgComponents';

function ListImportedXls(): JSX.Element {
  const {
    listFilesImported,
    updateIsUploaded,
    updateFileDetail,
    fileDetail,
    updateListFilesImported,
    updateListFilesImportedIsClicked,
    updateErrorWindow,
    fileNameUploaded,
    isErrorXLS,
    fileIsLoading,
    fileUploaded,
    updateFolderList,
    uploadLoading,
    updateFileUploaded,
    updateFileNameUploaded,
    updateImportLoading,
    importLoading,
  } = useContext(BulkImportContext);
  const { colorPrimary, radiusPx, isThemeDark, colorTextButton } =
    useContext(InstallerContext);

  const animClass =
    fileIsLoading || importLoading !== undefined
      ? 'anim-loading-imported-file'
      : '';
  const fileName =
    fileNameUploaded.length === 1 ? fileNameUploaded[0] : fileNameUploaded;

  // RECUPERE LA LISTE DES FICHIERS DEJA IMPORTES
  useEffect(() => {
    getListFilesImported(
      updateListFilesImported,
      updateIsUploaded,
      updateFileUploaded,
      updateFileNameUploaded,
      updateImportLoading
    );
  }, []);

  const loadingWidth =
    // eslint-disable-next-line no-nested-ternary
    fileUploaded && fileUploaded.nb_folders
      ? importLoading
        ? (importLoading / fileUploaded?.nb_folders) * 100
        : (uploadLoading / fileUploaded.nb_folders) * 100
      : 0;

  const backgroundColorUploadedFile = () => {
    if (!fileDetail?.id) {
      if (isErrorXLS) {
        return red1;
      }
      return isThemeDark
        ? ''
        : animClass !== '' && !isThemeDark
        ? convertHexToRGBA(colorPrimary, 0.1)
        : '';
    }
    if (isErrorXLS) {
      return red1;
    }
    return isThemeDark ? dark4 : colorPrimary;
  };

  const radius = radiusPx === '0px' ? '0px' : '8px';

  return (
    <div className="sb-text-rem-body1">
      <p className="sb-text-rem-body2">XLS en cours</p>
      {fileNameUploaded !== '' &&
        fileUploaded?.status !== 'Initialisation' &&
        fileUploaded?.status !== 'Creation' && (
          <button
            type="button"
            onClick={() => {
              // INDIQUE QUE L'ON VEUT ACCEDER A LA PARTIE DROITE DU FICHIER ENCORE NON IMPORTE
              updateIsUploaded(true);
              updateFileDetail(undefined);
              updateFolderList([]);
            }}
            style={{
              backgroundColor: backgroundColorUploadedFile(),
              borderRadius: radius,
            }}
            className={` ${
              !fileDetail?.id
                ? 'border dark:border-white border-borderLightMode'
                : ''
            } w-11/12 overflow-hidden mt-[.5rem] relative items-center sb-text-rem-caption1 justify-between flex`}
          >
            {animClass === '' && !isErrorXLS && !fileDetail?.id && (
              <div className="absolute right-[1.25rem]">
                <ArrowIcon
                  fill={isThemeDark ? 'white' : '#191919'}
                  width="1.5rem"
                  height="1.5rem"
                />
              </div>
            )}
            {isErrorXLS && (
              <div className="absolute right-[1.25rem]">
                <img src={alert} alt="erreur" />
              </div>
            )}
            <div
              style={{
                width: `${loadingWidth}%`,
                backgroundColor: animClass !== '' ? colorPrimary : '',
              }}
              className="anim-loading-imported-file w-full flex justify-between whitespace-nowrap py-[.75rem]"
            >
              <p
                style={{
                  color: isErrorXLS
                    ? 'white'
                    : fileDetail !== undefined
                    ? colorTextButton
                    : !isThemeDark
                    ? colorTextLightMode
                    : 'white',
                }}
                className="ml-[1.875rem]"
              >
                {transformLongString(fileName)}
              </p>
            </div>

            {animClass !== '' && (
              <div
                style={{
                  borderTopColor: isThemeDark ? 'white' : colorPrimary,
                  borderRightColor: isThemeDark ? 'white' : colorPrimary,
                }}
                className="spinner absolute"
              />
            )}
          </button>
        )}
      <div className="flex justify-between items-center w-full mt-[1rem] pr-[1.5rem]">
        <p className="sb-text-rem-body2">XLS importés</p>
        <p>
          {
            listFilesImported?.filter(
              (file) => file.status === 'Waiting for documents'
            ).length
          }
        </p>
      </div>
      <div className="mt-[1rem]">
        {listFilesImported.map((file) => {
          const fileActif = fileDetail?.id === file.id;
          if (file.status === 'Waiting for documents') {
            return (
              <div key={file.id}>
                <button
                  onClick={() => {
                    // RECUPERE LE DETAIL DU FICHIER CLIQUER POUR METTRE A JOUR LES INFOS SUR LA DROITE EN TEMPS REEL
                    getDetailFileById(
                      file.id,
                      updateFileDetail,
                      updateIsUploaded,
                      updateListFilesImportedIsClicked,
                      updateFolderList
                    );
                    updateErrorWindow(false);
                  }}
                  type="button"
                  key={file.id}
                  style={{
                    borderRadius: radius,
                    border: fileActif
                      ? `1px solid ${isThemeDark ? 'white' : borderLightMode}`
                      : !isThemeDark
                      ? `1px dashed ${borderLightMode}`
                      : '',
                    backgroundColor: fileActif
                      ? isThemeDark
                        ? dark1
                        : colorPrimary
                      : isThemeDark
                      ? dark4
                      : white,
                  }}
                  className="w-full mt-2 px-[1.875rem] py-3 w-11/12 opacity-100 hover:opacity-80 relative cursor-pointer"
                >
                  {fileActif && (
                    <div className="absolute right-[1.25rem]">
                      <ArrowIcon
                        fill={isThemeDark ? 'white' : '#191919'}
                        width="1.5rem"
                        height="1.5rem"
                      />
                    </div>
                  )}
                  <p className="sb-text-rem-body2 flex justify-start">
                    {transformLongString(file.file_bulk_import[0])}
                  </p>
                </button>
              </div>
            );
          }
          return false;
        })}
      </div>
    </div>
  );
}

export default ListImportedXls;
