import { ICategoryFinancial } from '@dashboard/utils/dashboardTypes';
import { numFormatSpace } from '@utils/utils';

interface IProps {
  item: ICategoryFinancial;
}

function OneCardCategoryFinancial({ item }: IProps): JSX.Element {
  const colorText = item.color;

  return (
    <div className="flex items-center justify-between font-regular text-[.875rem] leading-[2.5rem]">
      <div
        className={`w-1/5 text-left ${
          colorText === '#EFEEEE' && 'dark:text-[#EFEEEE] text-dark-1'
        }`}
        style={{ color: `${colorText !== '#EFEEEE' && colorText}` }}
      >
        {item.title}
      </div>
      <div className="opacity-90 dark:text-white text-dark-1 w-2/5 text-right">
        {numFormatSpace(item.amount)} €
      </div>
      <div className="opacity-90 dark:text-white text-dark-1 w-2/5 text-right">
        {item.nb_folders} opération{item.nb_folders > 1 ? 's' : ''}
      </div>
    </div>
  );
}

export default OneCardCategoryFinancial;
