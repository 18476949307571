import { useContext } from 'react';
import { alert } from '@assets/images/images';
import { BulkImportContext } from '@bulkImport/utils/bulkImportContext';
import { CloseIcon } from '@assets/svgComponents/svgComponents';

interface ErrorWindowProps {
  onClose?: () => void;
}

function ErrorWindow({ onClose }: ErrorWindowProps): JSX.Element {
  const { updateErrorWindow, errorWindowHeader, errorWindowContent } =
    useContext(BulkImportContext);

  const handleClose = () => {
    updateErrorWindow(false);
    if (onClose) {
      onClose();
    }
  };
  return (
    <div className="absolute h-[0] bottom-[1rem] ml-[2rem]">
      <div className="bg-red-1 p-[2rem] rounded-default w-[45rem] translate-y-[-125%]">
        <button
          type="button"
          className="float-right opacity-40 hover:opacity-70"
          onClick={() => handleClose()}
        >
          <CloseIcon fill="white" width="1.3rem" height="1.3rem" />
        </button>
        <div className="mb-[1rem] flex items-center">
          <img className="mr-[.875rem]" src={alert} alt="alert icon" />
          {errorWindowHeader}
        </div>
        <div>{errorWindowContent}</div>
      </div>
    </div>
  );
}

ErrorWindow.defaultProps = {
  onClose: null,
};

export default ErrorWindow;
