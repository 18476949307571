import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import Loader from '@components/loaders/Loader';
import Pagination from './Pagination';
import PopupContainer from './popups/PopupContainer';
import TableHeader from './TableHeader';
import Tabs from './Tabs';
import { IFieldType, IStepOperationType, IStepType } from '../utils/AdminTypes';
import { getOperations } from '../apiRequests/AdminRequests';

type PropTypes = {
  steps: IStepType[];
  updateSteps: Dispatch<SetStateAction<IStepType[]>>;
  stepFieldsSelect: IFieldType[];
  setStepFieldsSelect: Dispatch<SetStateAction<IFieldType[]>>;
  stepName: string;
  setStepName: Dispatch<SetStateAction<string>>;
};

export default function Table({
  steps,
  updateSteps,
  stepFieldsSelect,
  setStepFieldsSelect,
  stepName,
  setStepName,
}: PropTypes): JSX.Element {
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [selectedStep, setSelectedStep] = useState<IStepType>(steps[0]);
  const [operations, setOperations] = useState<IStepOperationType[]>([]);
  const [pageCount, setPageCount] = useState<number>(1);
  const [selectedPage, setSelectedPage] = useState<number>(1);
  const [operationLoading, setOperationLoading] = useState<boolean>(true);
  const [showPopup, setShowPopup] = useState<string>('NONE');

  useEffect(() => {
    const currentTable = steps.find((step) => step.id === selectedTab);
    if (currentTable) setSelectedStep(currentTable);
    if (steps[0]) {
      setSelectedTab(steps[steps.length - 1].id);
      const selectTable: IStepType = steps.find(
        (table) => table.id === steps[steps.length - 1].id
      ) as IStepType;
      setSelectedStep(selectTable);
    }
  }, [steps]);

  useEffect(() => {
    if (selectedStep) {
      getOperations(selectedStep.id, setOperations, setOperationLoading);
      setStepFieldsSelect(selectedStep.schema.fields);
      setStepName(selectedStep.name);
    }
  }, [selectedStep]);

  useEffect(() => {
    setPageCount(Math.floor(operations.length / 10));
  }, [operations]);

  let rows: unknown[][] | string[][] = [];

  operations.forEach((obj) => {
    rows = [...rows, Object.values(obj)];
  });

  const tableBody = rows
    .slice(pageCount > 1 ? selectedPage * 10 : 0, (selectedPage + 1) * 10)
    .map((row) => (
      <tr
        key={Math.random()}
        className="border-b-2 border-[#707070] 
        hover:dark:bg-dark-5 bg-lightMode-dark-5 cursor-pointer"
      >
        {row.map(
          (elt) =>
            (typeof elt === 'string' && (
              <td
                key={Math.random()}
                className="text-sm  px-5 whitespace-nowrap"
              >
                {elt.includes('http') ? (
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href={
                      elt !== null ? elt.replace('{', '').replace('}', '') : ''
                    }
                  >
                    🔍
                  </a>
                ) : (
                  elt
                )}
              </td>
            )) ||
            (typeof elt === 'object' && elt !== null && (
              <td
                key={Math.random()}
                className="text-sm px-5 whitespace-nowrap"
              >
                {Object.values(elt).length > 1 ? (
                  <select className="bg-transparent text-sm">
                    {Object.values(elt).map((option) => (
                      <option key={Math.random()} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                ) : (
                  Object.values(elt)[0]
                )}
              </td>
            )) ||
            (elt === null && (
              <td key={Math.random()} className="text-sm  px-5">
                Non établi
              </td>
            ))
        )}
      </tr>
    ));

  return (
    // POPUPS
    <div className="mt-10">
      <PopupContainer
        step={selectedStep}
        updateSteps={updateSteps}
        setShowPopup={setShowPopup}
        setOperationLoading={setOperationLoading}
        stepFieldsSelect={stepFieldsSelect}
        showPopup={showPopup}
        setOperations={setOperations}
        stepName={stepName}
        setStepFieldsSelect={setStepFieldsSelect}
      />
      <Tabs
        setOperationLoading={setOperationLoading}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        steps={steps}
        setOperations={setOperations}
        updateSteps={updateSteps}
        setSelectedStep={setSelectedStep}
      />

      {selectedStep && (
        <>
          <div className="border" />
          {operationLoading ? (
            <Loader />
          ) : (
            <>
              <TableHeader
                step={selectedStep}
                operations={operations}
                rows={rows}
                updateSteps={updateSteps}
                setShowPopup={setShowPopup}
                setOperations={setOperations}
                setOperationLoading={setOperationLoading}
                stepFieldsSelect={stepFieldsSelect}
                stepName={stepName}
                setStepName={setStepName}
                steps={steps}
              />
              <div className="w-full py-3 px-5 rounded-md flex justify-between mb-5 bg-[#2B2C3D]">
                <button
                  type="button"
                  onClick={() => setShowPopup('SELECT_FIELDS')}
                >
                  👁️ &nbsp; Afficher/Masquer les colonnes
                </button>
                <button
                  type="button"
                  onClick={() => setShowPopup('SELECT_CONDITIONS')}
                >
                  🔍 &nbsp; Filtrer par
                </button>
              </div>

              <div className="overflow-x-auto">
                <table className="w-full h-[40vh] overflow-x-scroll">
                  <thead>
                    <tr className="border-b-2">
                      {selectedStep.schema.fields.map((field) => (
                        <td
                          className="px-5 whitespace-nowrap overflow-x auto pb-3"
                          key={`field-${field.provider_id + Math.random()}`}
                        >
                          {field.provider_name}
                        </td>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {operations[0] ? (
                      tableBody
                    ) : (
                      <tr>
                        <td colSpan={12} className="text-xl w-full text-center">
                          Aucune opération trouvée
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              {pageCount > 1 && (
                <Pagination
                  pageCount={pageCount}
                  setSelectedPage={setSelectedPage}
                />
              )}
            </>
          )}
        </>
      )}
    </div>
  );
}
