import { InstallerContext } from '@auth/utils/authContext';
import { useContext } from 'react';

function LoaderRecap(): JSX.Element {
  const { radiusPx } = useContext(InstallerContext);

  return (
    <div className="w-full lg:flex space-x-[2rem] items-start animate__infinite  animate__flash animate__animated animate__slower">
      <div className="flex lg:w-5/12 flex-row items-center justify-center animate__infinite  animate__flash animate__animated animate__slower">
        <div
          style={{ borderRadius: radiusPx }}
          className="flex flex-col p-[1.25rem] w-full pt-[2.5rem] dark:bg-dark-4 bg-lightMode-dark-4 space-y-[1.25rem] animate__infinite  animate__flash animate__animated animate__slower"
        >
          <div
            style={{ borderRadius: radiusPx }}
            className="w-7/12 mb-[1.25rem] bg-dark-light-1 h-[1.5rem] opacity-40 animate__infinite  animate__flash animate__animated animate__slower"
          />
          <div
            style={{ borderRadius: radiusPx }}
            className="w-full bg-dark-light-1 h-[13rem] opacity-40 animate__infinite  animate__flash animate__animated animate__slower"
          />
          <div
            style={{ borderRadius: radiusPx }}
            className={`w-full bg-dark-light-1 h-[13rem] opacity-40 animate__infinite  animate__flash animate__animated animate__slower `}
          />
        </div>
      </div>
      <div className="lg:w-7/12 animate__infinite  animate__flash animate__animated animate__slower">
        <div
          style={{ borderRadius: radiusPx }}
          className="dark:bg-dark-4 bg-lightMode-dark-4 px-[1.25rem] py-[2.5rem] w-full animate__infinite  animate__flash animate__animated animate__slower"
        >
          <div
            style={{ borderRadius: radiusPx }}
            className="ml-[1.5rem] w-4/12 bg-dark-light-1 h-[1.5rem] opacity-60 animate__infinite  animate__flash animate__animated animate__slower"
          />
          <div className="flex justify-between">
            <div
              style={{ borderRadius: radiusPx }}
              className="w-6/12 h-[4rem] bg-dark-light-1 h-[5.875rem] mr-[.875rem] ml-[1.5rem] opacity-60 mt-[1.5rem] animate__infinite  animate__flash animate__animated animate__slower"
            />
            <div
              style={{ borderRadius: radiusPx }}
              className="w-6/12 h-[4rem] bg-dark-light-1 h-[5.875rem] ml-[.875rem] mr-[1.5rem] opacity-60 mt-[1.5rem] animate__infinite  animate__flash animate__animated animate__slower"
            />
          </div>
          <div className="flex justify-between">
            <div
              style={{ borderRadius: radiusPx }}
              className="w-4/12 bg-dark-light-1 h-[1.5rem] mx-[1.5rem] opacity-60 mt-[1.5rem] animate__infinite  animate__flash animate__animated animate__slower"
            />
            <div
              style={{ borderRadius: radiusPx }}
              className="w-2/12 bg-dark-light-1 h-[1.5rem] mx-[1.5rem] opacity-60 mt-[1.5rem] animate__infinite  animate__flash animate__animated animate__slower"
            />
          </div>
          <div className="flex justify-between">
            <div
              style={{ borderRadius: radiusPx }}
              className="w-7/12 bg-dark-light-1 h-[1.5rem] mx-[1.5rem] opacity-60 mt-[1.5rem] animate__infinite  animate__flash animate__animated animate__slower"
            />
            <div
              style={{ borderRadius: radiusPx }}
              className="w-2/12 bg-dark-light-1 h-[1.5rem] mx-[1.5rem] opacity-60 mt-[1.5rem] animate__infinite  animate__flash animate__animated animate__slower"
            />
          </div>
          <div className="flex justify-between">
            <div
              style={{ borderRadius: radiusPx }}
              className="w-7/12 bg-dark-light-1 h-[1.5rem] mx-[1.5rem] opacity-60 mt-[1.5rem] animate__infinite  animate__flash animate__animated animate__slower"
            />
            <div
              style={{ borderRadius: radiusPx }}
              className="w-2/12 bg-dark-light-1 h-[1.5rem] mx-[1.5rem] opacity-60 mt-[1.5rem] animate__infinite  animate__flash animate__animated animate__slower"
            />
          </div>
          <div className="flex justify-between">
            <div
              style={{ borderRadius: radiusPx }}
              className="w-3/12 bg-dark-light-1 h-[1.5rem] mx-[1.5rem] opacity-60 mt-[1.5rem] animate__infinite  animate__flash animate__animated animate__slower"
            />
            <div
              style={{ borderRadius: radiusPx }}
              className="w-2/12 bg-dark-light-1 h-[1.5rem] mx-[1.5rem] opacity-60 mt-[1.5rem] animate__infinite  animate__flash animate__animated animate__slower"
            />
          </div>
          <div
            style={{ borderRadius: radiusPx }}
            className="w-11/12 h-[4rem] bg-dark-light-1 h-[5.875rem] ml-[1.5rem] opacity-60 mt-[1.5rem] animate__infinite  animate__flash animate__animated animate__slower"
          />
        </div>
        <div
          style={{ borderRadius: radiusPx }}
          className="dark:bg-dark-4 bg-lightMode-dark-4 px-[1.25rem] py-[2.5rem] w-full mt-interblock animate__infinite  animate__flash animate__animated animate__slower"
        >
          <div
            style={{ borderRadius: radiusPx }}
            className="ml-[1.5rem] w-4/12 bg-dark-light-1 h-[1.5rem] opacity-60 animate__infinite  animate__flash animate__animated animate__slower"
          />
        </div>
        <div
          style={{ borderRadius: radiusPx }}
          className="dark:bg-dark-4 bg-lightMode-dark-4 px-[1.25rem] py-[2.5rem] w-full mt-interblock animate__infinite  animate__flash animate__animated animate__slower"
        >
          <div
            style={{ borderRadius: radiusPx }}
            className="ml-[1.5rem] w-4/12 bg-dark-light-1 h-[1.5rem] opacity-60 animate__infinite  animate__flash animate__animated animate__slower"
          />
        </div>
      </div>
    </div>
  );
}

export default LoaderRecap;
