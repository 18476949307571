import { IDocumentList, IMyProfilDatas } from '@dashboard/utils/dashboardTypes';
import { Dispatch, SetStateAction, useContext } from 'react';
import { v4 } from 'uuid';
import { InstallerContext } from '@auth/utils/authContext';
import OneCard from './OneCard';

interface IProps {
  setDatasMyProfile: Dispatch<SetStateAction<IMyProfilDatas>>;
  datas: IMyProfilDatas;
}
function LeftPartDocuments({ setDatasMyProfile, datas }: IProps): JSX.Element {
  const { isThemeDark } = useContext(InstallerContext);
  const scrollBarColor = isThemeDark ? '' : 'scroll_light_mode';

  const documentsContrat = {
    label: 'Contrats',
    editable: false,
    values: [
      {
        label: datas.date_signature_contrat_mpr.value
          ? `Contrat MPR signé le ${new Date(
              datas.date_signature_contrat_mpr.value
            ).toLocaleDateString('fr')}`
          : '',
        value: datas.contrat_mpr,
      },
      {
        label: datas.date_signature_contrat_cee.value
          ? `Contrat CEE signé le ${new Date(
              datas.date_signature_contrat_cee.value
            ).toLocaleDateString('fr')}`
          : '',
        value: datas.contrat_cee,
      },
    ],
  };

  const arrayDocuments: IDocumentList[] = [
    {
      label: 'RGE',
      values: datas.rge,
      editable: true,
    },
    {
      label: 'Contrats sous-traitants',
      values: datas.contrats_sous_traitance,
      editable: true,
    },
  ];

  return (
    <div
      className={`${scrollBarColor} xl:w-5/12 mt-[1.875rem] pr-[1rem] mr-[1rem] overflow-y-scroll max-h-[75vh]`}
    >
      <OneCard
        documentsOther={null}
        editable={documentsContrat.editable}
        documentsContrat={documentsContrat.values}
        titleCard={documentsContrat.label}
        setDatasMyProfile={setDatasMyProfile}
      />
      {arrayDocuments.map((el: IDocumentList) => {
        return (
          <div
            key={v4()}
            className={`${
              el.label === 'Contrats sous-traitants' ? 'mt-[3.125rem]' : ''
            }`}
          >
            <OneCard
              editable={el.editable}
              documentsOther={el.values.value}
              titleCard={el.label}
              documentsContrat={null}
              setDatasMyProfile={setDatasMyProfile}
            />
          </div>
        );
      })}
    </div>
  );
}

export default LeftPartDocuments;
