import axios from 'axios';
import { Dispatch, SetStateAction } from 'react';
import { toast } from 'react-toastify';
import { IKeyValueStringType } from '../../simulator/utils/simulatorTypes';
import {
  ICoordinates,
  IFileImported,
  IListFilesImported,
  IFileCheckBeforeSend,
  Idocuments,
  IOperation,
  IFolder,
  IFileSentToAirtable,
} from '../utils/bulkImportTypes';

const apiUrl = process.env.REACT_APP_BACK_PRO_API_URL;

// RECUPERER LE DETAIL D'UN FICHIER LORSQUE L'ON CLIQUE DESSUS
export const getDetailFileInImportById = (
  idFile: number,
  updateIsUploaded: Dispatch<SetStateAction<boolean>>,
  updateFileUploaded: Dispatch<SetStateAction<IFileImported | undefined>>,
  updateFileNameUploaded: Dispatch<SetStateAction<string>>,
  updateImportLoading: Dispatch<SetStateAction<number | undefined>>
) => {
  axios.get(`${apiUrl}/bulkImport/${idFile}`).then((response) => {
    updateFileUploaded(response.data);
    updateIsUploaded(true);
    updateFileNameUploaded(response.data.file[0]);
    updateImportLoading(0);
  });
};

// RECUPERER LA LISTE DES FICHIERS IMPORTES
export const getListFilesImported = (
  updateListFilesImported: Dispatch<SetStateAction<IListFilesImported[]>>,
  updateIsUploaded?: Dispatch<SetStateAction<boolean>>,
  updateFileUploaded?: Dispatch<SetStateAction<IFileImported | undefined>>,
  updateFileNameUploaded?: Dispatch<SetStateAction<string>>,
  updateImportLoading?: Dispatch<SetStateAction<number | undefined>>
) => {
  axios.get(`${apiUrl}/bulkImport`).then((response) => {
    updateListFilesImported(response.data.list);
    if (
      updateIsUploaded &&
      updateFileUploaded &&
      updateFileNameUploaded &&
      updateImportLoading
    ) {
      const fileInImportIndex = response.data.list.findIndex(
        (file: IFileImported) => file.status === 'Import'
      );
      if (fileInImportIndex !== -1) {
        getDetailFileInImportById(
          response.data.list[fileInImportIndex].id,
          updateIsUploaded,
          updateFileUploaded,
          updateFileNameUploaded,
          updateImportLoading
        );
      }
    }
  });
};

// RECUPERER LE DETAIL D'UN FICHIER LORSQUE L'ON CLIQUE DESSUS
export const getDetailFileById = (
  idFile: number,
  updateFileDetail: Dispatch<SetStateAction<IFileCheckBeforeSend | undefined>>,
  updateIsUploaded: Dispatch<SetStateAction<boolean>>,
  updateListFilesImportedIsClicked: Dispatch<SetStateAction<boolean>>,
  updateFolderList: Dispatch<SetStateAction<IFolder[]>>
) => {
  axios.get(`${apiUrl}/bulkImport/${idFile}`).then((response) => {
    updateFileDetail(response.data);
    updateFolderList(response.data.folders);
    updateIsUploaded(false);
    updateListFilesImportedIsClicked(true);
  });
};

// RECUPERER LE DETAIL D'UN FICHIER LORSQUE L'ON CLIQUE DESSUS
export const getDetailFileProcessing = (
  idFile: number,
  updateFileDetail: Dispatch<SetStateAction<IFileCheckBeforeSend | undefined>>,
  updateIsUploaded: Dispatch<SetStateAction<boolean>>,
  updateFileNameUploaded: Dispatch<SetStateAction<string>>,
  updateFileUploaded: Dispatch<SetStateAction<IFileImported | undefined>>
) => {
  const config = {
    headers: {
      ContentType: 'multipart/form-data',
    },
  };
  axios.get(`${apiUrl}/bulkImport/${idFile}`, config).then((response) => {
    updateFileDetail(response.data);
    updateFileUploaded(response.data);
    updateFileNameUploaded(response.data.file[0]);
    updateIsUploaded(true);
  });
};

// RECUPERER LE DETAIL D'UN FICHIER LORSQUE L'ON MODIFIE LES DOCUMENTS
export const getDetailFileByIdOnUpload = async (
  idFile: number,
  updateFileDetail: Dispatch<SetStateAction<IFileCheckBeforeSend | undefined>>
) => {
  return axios.get(`${apiUrl}/bulkImport/${idFile}`).then((response) => {
    updateFileDetail(response.data);
  });
};

// UPLOAD UN FICHIER
export const uploadXLS = (
  file: File,
  updateFileNameUploaded: Dispatch<SetStateAction<string>>,
  setIsError: Dispatch<SetStateAction<boolean>>,
  updateFileUploaded: Dispatch<SetStateAction<IFileImported | undefined>>,
  updateIsUploaded: Dispatch<SetStateAction<boolean>>,
  updateFileIsLoading: Dispatch<SetStateAction<boolean>>,
  updateCoordinatesArrayForMapLoading: Dispatch<SetStateAction<ICoordinates[]>>,
  updateErrorWindow: Dispatch<SetStateAction<boolean>>,
  updateErrorWindowHeader: Dispatch<SetStateAction<string>>,
  updateErrorWindowContent: Dispatch<SetStateAction<JSX.Element | string>>,
  updateZoomMap: Dispatch<SetStateAction<number>>
) => {
  updateCoordinatesArrayForMapLoading([]);
  updateFileIsLoading(true);

  const config = {
    headers: {
      ContentType: 'multipart/form-data',
    },
  };
  const formData = new FormData();
  formData.append('file', file);

  setIsError(false);
  axios
    .post(`${apiUrl}/bulkImport`, formData, config)
    .then((response) => {
      updateFileUploaded((old) => {
        let newFileUploaded = old;
        if (newFileUploaded) {
          newFileUploaded.id = response.data.id;
          newFileUploaded.file = response.data.file;
          if (newFileUploaded.check !== undefined) {
            setTimeout(() => {
              updateZoomMap(5);
            }, 1000);
            setTimeout(() => {
              updateFileIsLoading(false);
              updateCoordinatesArrayForMapLoading([]);
            }, 5000);
          }
        } else {
          newFileUploaded = response.data;
        }
        return newFileUploaded;
      });
      updateFileNameUploaded(response.data.file[0]);
    })
    .catch((error) => {
      updateFileIsLoading(false);
      setIsError(true);
      updateFileUploaded(undefined);
      updateErrorWindow(true);
      updateErrorWindowHeader('Erreur sur le fichier');
      updateErrorWindowContent(
        "Le fichier que vous avez tenté d'ajouter n'est pas conforme. Corrigez le avant de l'importer à nouveau."
      );
      if (error.toJSON().status === 500) {
        if (error.response.data.error) {
          updateErrorWindowContent(error.response.data.error);
        }
      }
    });
};

// Importe un fichier
export const importXls = (id: number) => {
  axios.post(`${apiUrl}/bulkImport/${id}`, null).catch(() => {
    toast("⛔️ Erreur lors de l'importation du fichier.");
  });
};

// SUPPRIMER UN FICHIER
export const deleteFile = (
  idFile: number | undefined,
  updateFileNameUploaded: Dispatch<SetStateAction<string>>,
  updateFileUploaded: Dispatch<SetStateAction<IFileImported | undefined>>
) => {
  return axios.delete(`${apiUrl}/bulkImport/${idFile}`).then(() => {
    updateFileUploaded(undefined);
    updateFileNameUploaded('');
  });
};

// UPLOAD DOCUMENTS FOLDER
export const addUploadFile = (
  folderReference: string,
  file: File,
  fileType: string,
  id: number,
  updateFileDetail: Dispatch<SetStateAction<IFileCheckBeforeSend | undefined>>,
  setIsLoading: Dispatch<SetStateAction<boolean>>,
  updateOperations: Dispatch<SetStateAction<IOperation[] | undefined>>,
  updateDocumentsGlobals: Dispatch<SetStateAction<Idocuments[] | undefined>>,
  setErr: Dispatch<SetStateAction<boolean>>,
  setErrMessage: Dispatch<SetStateAction<string>>,
  operationReference?: string
) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('type', fileType);
  formData.append('full', 'true');

  if (operationReference) {
    formData.append('operation', operationReference);
  }

  const config = {
    headers: {
      ContentType: 'multipart/form-data',
    },
  };
  setIsLoading(true);

  axios
    .post(`${apiUrl}/dossier/${folderReference}/file`, formData, config)
    .then(async (response) => {
      toast('Le fichier a été ajouté avec succès', { type: 'success' });
      if (operationReference) {
        updateOperations(response.data.operations);
        updateDocumentsGlobals(response.data.files);
      } else {
        updateDocumentsGlobals(response.data.files);
      }
      if (response.data.completed) {
        await getDetailFileByIdOnUpload(id, updateFileDetail);
        toast('Le dossier est maintenant complet', { type: 'success' });
      }
      setIsLoading(false);
    })
    .catch((err) => {
      setIsLoading(false);
      setErr(true);
      setErrMessage(
        `Un problème est survenu lors du téléchargement de votre fichier ${file.name}. Veuillez réessayer.`
      );
      if (err.response.status === 413) {
        setErrMessage(`Le fichier ${file.name} est trop volumineux.`);
      } else {
        setErrMessage(`Impossible de télécharger le fichier ${file.name}`);
      }
    });
};

// DELETE DOCUMENTS FOLDER
export const deleteDocument = (
  folderReference: string,
  fileType: string,
  fileName: string,
  id: number,
  updateFileDetail: Dispatch<SetStateAction<IFileCheckBeforeSend | undefined>>,
  updateDocumentsGlobals: Dispatch<SetStateAction<Idocuments[] | undefined>>,
  updateOperations: Dispatch<React.SetStateAction<IOperation[] | undefined>>,
  setIsLoading: Dispatch<SetStateAction<boolean>>,
  operationReference?: string
) => {
  const dataFile: IKeyValueStringType = {
    type: fileType,
    file: fileName,
  };

  if (operationReference) {
    dataFile.operation = operationReference;
  }
  const config = {
    data: dataFile,
  };

  setIsLoading(true);

  axios
    .delete(`${apiUrl}/dossier/${folderReference}/file`, config)
    .then(async (response) => {
      await getDetailFileByIdOnUpload(id, updateFileDetail);
      setIsLoading(false);
      toast('Le fichier a été supprimé avec succès', { type: 'success' });
      if (operationReference) {
        updateOperations(response.data.operations);
        updateDocumentsGlobals(response.data.files);
      } else {
        updateDocumentsGlobals(response.data.files);
      }
    });
};

// SEND FOLDER TO AIRTABLE
export const sendFolderToAirtable = (
  idFile: number,
  updateFileIsPushingToAirtable: Dispatch<SetStateAction<boolean>>,
  setLoading: Dispatch<SetStateAction<boolean>> | undefined,
  updateFileDetail: Dispatch<SetStateAction<IFileCheckBeforeSend | undefined>>,
  updateWasSentToAirtable: Dispatch<SetStateAction<boolean>>,
  updateFileSentToAirtable: Dispatch<
    SetStateAction<IFileSentToAirtable | undefined>
  >
) => {
  if (setLoading) {
    setLoading(true);
  }
  axios.get(`${apiUrl}/bulkImport/push/${idFile}`).then((response) => {
    updateFileIsPushingToAirtable(true);
    setTimeout(() => {
      if (setLoading) {
        setLoading(false);
      }
      updateFileDetail(undefined);
      updateWasSentToAirtable(true);
      updateFileSentToAirtable(response.data);
      toast('Le dossier a été envoyé avec succès', { type: 'success' });
    }, 20000);
  });
};
