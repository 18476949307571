import { useContext, useEffect, useState } from 'react';
import { v4 } from 'uuid';
import { FileMultiUpload, ButtonSb } from 'storybook-design-system-drapo';
import Loader from '@components/loaders/Loader';
import { addInvoiceMPR } from '@folderMonitoring/apiRequest/folderMonitoringRequest';
import { InstallerContext } from '@auth/utils/authContext';
import { ICeeDocuments } from '@folderMonitoring/utils/folderMonitoringTypes';
import { toast } from 'react-toastify';
import { dark1, dark5, white } from '@assets/colors';
import { isVolumeExceed } from '@folderMonitoring/utils/functions';
import { convertHexToRGBA } from '@utils/utils';
import { FolderMonitoringContext } from '../../utils/context/folderMonitoringContext';
/*
 import InputInvoiceAmount from '@folderMonitoring/components/recapFolder/InputInvoiceAmount';
*/

interface IMprInvoicesProps {
  reference_operation: string;
  hasInvoice: boolean;
  /*
  mprAmountQuote: number;
  montant_facture_ttc_attestation_consentement_dev: number;
  */
}

function MprInvoices({
  reference_operation,
  hasInvoice,
}: /*
  mprAmountQuote,
  montant_facture_ttc_attestation_consentement_dev,
  */
IMprInvoicesProps): JSX.Element {
  /*
  const apiUrl = process.env.REACT_APP_BACK_PRO_API_URL;
  */
  const {
    folderDatas,
    updateFolderDatas,
    updateStepIsUpdate,
    folderActif,
    /*
    hasAttestation,
    */
  } = useContext(FolderMonitoringContext);

  const { radiusPx, colorPrimary, isThemeDark, colorTextButton, installer } =
    useContext(InstallerContext);

  const radius = radiusPx === '0px' ? '0px' : '8px';

  /*
  const [invoiceAmountToCompare, setInvoiceAmountToCompare] = useState<string>(
    montant_facture_ttc_attestation_consentement_dev !== null
      ? montant_facture_ttc_attestation_consentement_dev.toString()
      : '0'
  );
  const [isMatchingAmount, setIsMatchingAmount] = useState<boolean>(
    montant_facture_ttc_attestation_consentement_dev !== null
      ? invoiceAmountToCompare === mprAmountQuote.toString()
      : false
  );
  const [isCompare, setIsCompare] = useState<boolean>(
    montant_facture_ttc_attestation_consentement_dev !== null || hasAttestation
  );
  */

  const [isFileUploading, setIsFileUploading] = useState<boolean>(false);
  const [fileName, setFileName] = useState<string>('');
  const [fileToSend, setFileToSend] = useState<ICeeDocuments[]>([]);

  const [isOk, setIsOk] = useState<boolean>(false);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [isSend, setIsSend] = useState<boolean>(false);
  /*
  const multipleFilesRequired: boolean =
    !hasInvoice && isCompare && !hasAttestation;
  const oneFilesRequired: boolean =
    (hasInvoice && isCompare) || (!hasInvoice && isCompare && hasAttestation);
  */
  const noFileRequired: boolean = hasInvoice; /* && hasAttestation */
  const [requiredType, setRequiredType] = useState<string[]>([]);
  const [isRequired, setIsRequired] = useState<boolean>(false);

  const [isSameFile, setIsSameFile] = useState<boolean>(false);

  const filesList = [
    { title: `${!hasInvoice ? 'Facture' : ''}`, required: true },
    /*
    {
      title: `${hasAttestation ? '' : 'Attestation de consentement'}`,
      required: true,
    },
    */
  ];

  const whichFile = (index: number) => {
    const tab = fileToSend;
    const file: ICeeDocuments[] = [];

    for (let i = 0; i < tab.length; i += 1) {
      if (tab[i].index === index) {
        file.push(tab[i]);
      }
    }
    return file;
  };

  const onUploadFile = (
    file: File,
    index: number,
    name: string,
    required: boolean
  ) => {
    setIsFileUploading(false);
    setFileName(file.name);
    setFileToSend((prev: ICeeDocuments[]) => [
      ...prev,
      {
        name,
        path: URL.createObjectURL(file),
        index,
        file,
      },
    ]);
    if (required && requiredType.indexOf(name) === -1) {
      setRequiredType((prev) => [...prev, name]);
    }
  };

  const onDeleteFileUpload = (
    index: number,
    required: boolean,
    name: string
  ) => {
    const tab = fileToSend;
    const tab2 = requiredType;
    const changeRequired: string[] = [];

    for (let i = 0; i < fileToSend.length; i += 1) {
      if (fileToSend[i].index === index) {
        tab.splice(i, 1);
      }
    }

    for (let i = 0; i < tab.length; i += 1) {
      if (tab[i].name === name) {
        changeRequired.push(name);
      }
    }

    if (required && changeRequired.length === 0) {
      tab2.splice(tab2.indexOf(name), 1);
      setIsRequired(false);
    }

    setFileToSend(tab);
    setRequiredType(tab2);
  };

  const handleClick = () => {
    setIsLoading(true);
    setIsSend(false);
    setError('');
    if (fileToSend) {
      if (isVolumeExceed(fileToSend)) {
        setError('Le volume maximum des fichiers dépasse les 3 Mo');
        setIsLoading(false);
      } else {
        addInvoiceMPR(
          updateStepIsUpdate,
          setIsLoading,
          setIsSend,
          setError,
          folderActif[0].référence_dossier,
          fileToSend,
          reference_operation,
          folderDatas,
          updateFolderDatas,
          hasInvoice
        );
      }
    }
  };

  useEffect(() => {
    /*
    if (multipleFilesRequired) {
      setIsRequired(requiredType.length === 2);
    } else if (oneFilesRequired) {
      */
    setIsRequired(requiredType.length === 1);
    /*
    }
    */
  }, [requiredType /* , multipleFilesRequired, oneFilesRequired */]);

  useEffect(() => {
    setIsOk(isRequired);
  }, [isRequired]);

  useEffect(() => {
    if (isSameFile) {
      toast(
        '⛔️ Un fichier avec le même nom a déjà été renseigné pour ce document'
      );
    }
  }, [isSameFile]);
  console.log('file', fileToSend);

  return (
    <div className="relative pt-[.75rem] flex flex-col space-y-[.75rem] px-[1rem]">
      {/*
      {!hasAttestation && (
        <InputInvoiceAmount
          reference_operation={reference_operation}
          mprAmountQuote={mprAmountQuote}
          invoiceAmountToCompare={invoiceAmountToCompare}
          isMatchingAmount={isMatchingAmount}
          setIsMatchingAmount={setIsMatchingAmount}
          isCompare={isCompare}
          setIsCompare={setIsCompare}
          setInvoiceAmountToCompare={setInvoiceAmountToCompare}
          isMpr
        />
      )}
      {isCompare && (
        <>
      */}
      {filesList
        .filter((el) => el.title !== '')
        .map((file, index: number) => (
          <FileMultiUpload
            key={v4()}
            fileName={file.title}
            fileNameUpload={fileName}
            required={file.required}
            isFileUploading={isFileUploading}
            borderRadiusContainer={radiusPx}
            borderRadius={radiusPx}
            backgroundColorFileLoaded={colorPrimary}
            backgroundUploadedColor={colorPrimary}
            containerBackgroundColor={
              isThemeDark ? dark1 : convertHexToRGBA(colorPrimary, 0.1)
            }
            color={isThemeDark ? white : dark1}
            backgroundColorAddFile={isThemeDark ? dark5 : white}
            backgroundColorComponent={
              isThemeDark ? dark1 : convertHexToRGBA(colorPrimary, 0.1)
            }
            onDelete={() =>
              onDeleteFileUpload(index, file.required, file.title)
            }
            onChangeFileUpload={(files) =>
              onUploadFile(files, index, file.title, file.required)
            }
            filesUploaded={whichFile(index)}
            setActiveToast={setIsSameFile}
          />
        ))}
      {/*
        </>
      )}
      {!hasAttestation && isMatchingAmount && isCompare && (
        <a
          href={`${apiUrl}/document/attestation-consentement/${reference_operation}`}
          download
          target="_blank"
          rel="noreferrer"
          style={{ borderRadius: radius, backgroundColor: colorPrimary }}
          className="text-center p-[.5rem] opacity-[.92] hover:opacity-100"
        >
          Cliquez ici pour recevoir l’Attestation de consentement à faire signer
          par votre client.
        </a>
      )}
      */}
      {!noFileRequired /* && isCompare */ && (
        <ButtonSb
          disabled={!isOk || installer.desactiver_boutons}
          onClick={() => {
            handleClick();
          }}
          label="Soumettre"
          color={colorTextButton}
          backgroundColor={colorPrimary}
          dataTestId="soumettre"
          addClass="!h-[2.5rem]"
          borderRadius={radius}
        />
      )}
      {isLoading && (
        <div className="mt-[.875rem]">
          <Loader />
        </div>
      )}
      {!isLoading && isSend && error && (
        <div className="mt-[.875rem] text-red-1 text-[.875rem]">{error}</div>
      )}
    </div>
  );
}

export default MprInvoices;
