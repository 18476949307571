import { Dispatch, SetStateAction } from 'react';
import ReactPaginate from 'react-paginate';

type PropTypes = {
  pageCount: number;
  setSelectedPage: Dispatch<SetStateAction<number>>;
};

type PageData = {
  selected: number;
};

export default function Pagination({
  pageCount,
  setSelectedPage,
}: PropTypes): JSX.Element {
  const handlePageChange = (data: PageData) => {
    setSelectedPage(data.selected + 1);
  };

  const pageClass =
    'text-white relative inline-flex items-center px-4 py-2 text-sm font-medium';

  return (
    <div className="my-10 flex z-0">
      <ReactPaginate
        previousLabel="Précedent"
        nextLabel="Suivant"
        pageCount={pageCount}
        breakLabel="..."
        onPageChange={handlePageChange}
        pageRangeDisplayed={2}
        marginPagesDisplayed={3}
        pageLinkClassName={pageClass}
        breakLinkClassName={pageClass}
        containerClassName="flex w-full justify-center"
        previousLinkClassName={pageClass}
        nextLinkClassName={pageClass}
        activeLinkClassName="z-0 border-t-purple-1 text-purple-1 relative inline-flex items-center px-4 py-2 text-sm font-bold border-t-2"
      />
    </div>
  );
}
