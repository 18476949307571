import { useContext } from 'react';
import { InstallerContext } from '@auth/utils/authContext';
import { operationDatasToPush } from '@simulator/utils/initialsState';
import { SimulatorContext } from '@simulator/utils/simulatorContext';
import { white } from '@assets/colors';
import { ArrowIcon } from '@assets/svgComponents/svgComponents';

function BonusSuggestion(): JSX.Element {
  const { colorPrimary, isThemeDark, colorTextButton } =
    useContext(InstallerContext);
  const {
    updateCurrentOperation,
    updateStepNumber,
    updateMultipleOperations,
    updateAuditFromSummary,
  } = useContext(SimulatorContext);

  const auditSlug = 'bar-se-1002';
  const auditName = 'Audit énergétique';
  const auditEligibility = ['MPR'];

  const addAudit = () => {
    updateCurrentOperation(
      operationDatasToPush(auditSlug, auditName, auditEligibility)
    );
    updateStepNumber(3);
    updateMultipleOperations([
      operationDatasToPush(auditSlug, auditName, auditEligibility),
    ]);
    updateAuditFromSummary(true);
  };
  return (
    <div
      style={{ backgroundColor: colorPrimary }}
      className="w-full p-[2rem] flex justify-between rounded-default mb-[2rem]"
    >
      <div
        style={{
          color: isThemeDark ? white : colorTextButton,
        }}
        className="leading-[1.5rem] text-[.875rem]"
      >
        <p>Vous êtes peut-être éligible à un bonus</p>
        <p className="font-light">
          Nous pensons que les travaux que vous êtes en train de simuler peuvent
          bénéficier d’une bonification supplémentaire (jusqu’à 1500€ en
          fonction du type de ménage de votre client) si vous réalisez une
          opération d&apos;Audit énergétique.
        </p>
      </div>
      <div className="flex items-end pl-[2rem]">
        <button
          type="button"
          className="cursor-pointer bg-white rounded-default px-[1.5rem] py-[1rem] text-[.75rem] font-light whitespace-nowrap h-min flex items-center"
          style={{ color: colorPrimary }}
          onClick={() => addAudit()}
        >
          Ajouter un Audit énergétique{' '}
          <ArrowIcon
            width="1.5rem"
            height="1.5rem"
            className="ml-[1.5rem]"
            fill={colorPrimary}
          />
        </button>
      </div>
    </div>
  );
}

export { BonusSuggestion };
