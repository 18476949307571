import { useState, useEffect } from 'react';
import HeaderMyProfile from '@dashboard/components/myProfile/HeaderMyProfil';
import GeneralContainer from '@dashboard/components/myProfile/general/GeneralContainer';
import DocumentsContainer from '@dashboard/components/myProfile/documents/DocumentsContainer';
import { IMyProfilDatas } from '@dashboard/utils/dashboardTypes';
import { getAllParameters } from '@dashboard/apiRequests/dashboardRequests';
import { MyProfileContext } from '@dashboard/utils/context/myProfileContext';
import Loader from '@components/loaders/Loader';
import { transformDocumentUrl } from '@dashboard/utils/functions';
import { initialDatasMyProfil } from '../models/dashboard/utils/initialState';
import LayoutDashboard from './LayoutDashboard';

function MyProfile(): JSX.Element {
  const [datasMyProfil, setDatasMyProfil] =
    useState<IMyProfilDatas>(initialDatasMyProfil);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isUpdateLoading, setIsUpdateLoading] = useState<boolean>(false);
  const [elementNavActive, setElementNavActive] = useState<string>('Général');
  const [documentUrlActive, setdocumentUrlActive] = useState<string>(
    transformDocumentUrl(
      datasMyProfil.contrat_mpr.value || datasMyProfil.contrat_cee.value
    )
  );
  const [pageNumberViewDocument, setPageNumberViewDocument] =
    useState<number>(1);

  useEffect(() => {
    getAllParameters(setDatasMyProfil, undefined, undefined, setIsLoading);
  }, []);

  useEffect(() => {
    if (documentUrlActive === '') {
      setdocumentUrlActive(
        transformDocumentUrl(
          datasMyProfil.contrat_mpr.value || datasMyProfil.contrat_cee.value
        )
      );
    }
  }, [datasMyProfil]);

  const myProfileContextValue = {
    documentUrlActive,
    updateDocumentUrlActive: setdocumentUrlActive,
    isUpdateLoading,
    updateIsUpdateLoading: setIsUpdateLoading,
    pageNumberViewDocument,
    updatePageNumberViewDocument: setPageNumberViewDocument,
  };

  return (
    <MyProfileContext.Provider value={myProfileContextValue}>
      <LayoutDashboard>
        <>
          <HeaderMyProfile
            elementNavActive={elementNavActive}
            setElementNavActive={setElementNavActive}
          />

          {isUpdateLoading && <Loader />}
          <div className="w-full dark:bg-dark-1 bg-backgroundSecondaryLightMode pb-[1.5rem] lg:h-[82vh] rounded-bl-[16px]">
            {elementNavActive === 'Général' ? (
              <GeneralContainer
                datas={datasMyProfil}
                setDatasMyProfil={setDatasMyProfil}
                isLoading={isLoading}
              />
            ) : (
              <DocumentsContainer
                datas={datasMyProfil}
                setDatasMyProfile={setDatasMyProfil}
              />
            )}
          </div>
        </>
      </LayoutDashboard>
    </MyProfileContext.Provider>
  );
}

export default MyProfile;
