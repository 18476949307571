import { useContext, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { v4 } from 'uuid';
import { InstallerContext } from '@auth/utils/authContext';
import { DASHBOARD_TABS } from '@dashboard/utils/tabs';
import { PERMISSIONS } from '@utils/utils';
import { HomeLeftMenuBugCard } from '@dashboard/components/homeLeftMenu/HomeLeftMenuBugCard';
import DashboardButton from '@dashboard/components/homeLeftMenu/dashboardButtonDropdown/DashboardButton';
import { ThemeCheckbox } from '@components/ThemeCheckbox';
import {
  PublishIcon,
  CreateNewFolderIcon,
  SupportIcon,
  InventoryIcon,
  LogoDrapoIcon,
  LogoutIcon,
  AccountCircleIcon,
} from '@assets/svgComponents/svgComponents';
import { white } from '@assets/colors';
import LinkButton from './LinkButton';
import HomeLeftMenuCard from './HomeLeftMenuCard';
import { DashboardContext } from '../../utils/context/dashboardContext';
import { convertHexToRGBA } from '../../../../utils/utils';

function HomeLeftMenu(): JSX.Element {
  const location = useLocation();
  const page = location.pathname;

  const [icons, setIcons] = useState({
    createFolder: (
      <CreateNewFolderIcon fill="white" width="2rem" height="2rem" />
    ),
    bulkmport: <PublishIcon fill="white" width="2rem" height="2rem" />,
    faq: <SupportIcon fill="white" width="2rem" height="2rem" />,
    myProfile: <AccountCircleIcon fill="white" width="2rem" height="2rem" />,
    inventory: <InventoryIcon fill="white" width="2rem" height="2rem" />,
    logout: <LogoutIcon fill="white" width="2rem" height="2rem" />,
  });
  /*
  const [iconToolbox, setIconToolbox] = useState(toolBox);
  */

  const { installer, radiusPx, isThemeDark, colorPrimary } =
    useContext(InstallerContext);
  const {
    updateElementActive,
    updateActiveCustomView,
    updateCurrentListCustomView,
    updateNewConditions,
  } = useContext(DashboardContext);

  const session = {
    title: 'Session Active',
    name: installer.raison_sociale_de_lentreprise,
  };

  useEffect(() => {
    if (!isThemeDark) {
      setIcons({
        ...icons,
        createFolder: (
          <CreateNewFolderIcon fill="#BEBFBE" width="2rem" height="2rem" />
        ),
        bulkmport: (
          <PublishIcon
            fill={convertHexToRGBA(
              page === '/import-dossiers' ? colorPrimary : '#BEBFBE',
              1
            )}
            width="2rem"
            height="2rem"
          />
        ),
        faq: <SupportIcon fill="#BEBFBE" width="2rem" height="2rem" />,
        myProfile: (
          <AccountCircleIcon
            fill={convertHexToRGBA(
              page === '/mon-profil' ? colorPrimary : '#BEBFBE',
              1
            )}
            width="2rem"
            height="2rem"
          />
        ),
        inventory: <InventoryIcon fill="#BEBFBE" width="2rem" height="2rem" />,
        logout: <LogoutIcon fill="#BEBFBE" width="2rem" height="2rem" />,
      });
    } else {
      setIcons({
        createFolder: (
          <CreateNewFolderIcon
            fill={convertHexToRGBA(
              '#ffffff',
              page === '/demander-ma-prime' ? 1 : 0.3
            )}
            width="2rem"
            height="2rem"
          />
        ),
        bulkmport: (
          <PublishIcon
            fill={convertHexToRGBA(
              '#ffffff',
              page === '/import-dossiers' ? 1 : 0.3
            )}
            width="2rem"
            height="2rem"
          />
        ),
        faq: (
          <SupportIcon
            fill={convertHexToRGBA('#ffffff', 0.3)}
            width="2rem"
            height="2rem"
          />
        ),
        myProfile: (
          <AccountCircleIcon
            fill={convertHexToRGBA('#ffffff', page === '/mon-profil' ? 1 : 0.3)}
            width="2rem"
            height="2rem"
          />
        ),
        inventory: (
          <InventoryIcon
            fill={convertHexToRGBA('#ffffff', 0.3)}
            width="2rem"
            height="2rem"
          />
        ),
        logout: (
          <LogoutIcon
            fill={convertHexToRGBA('#ffffff', 0.3)}
            width="2rem"
            height="2rem"
          />
        ),
      });
    }
  }, [isThemeDark]);

  const nav = [
    {
      icon: icons.createFolder,
      iconLightModeHover: (
        <CreateNewFolderIcon fill={colorPrimary} width="2rem" height="2rem" />
      ),
      iconDarkModeHover: (
        <CreateNewFolderIcon fill={white} width="2rem" height="2rem" />
      ),
      name: 'Nouveau dossier',
      link: '/demander-ma-prime',
      permission: PERMISSIONS.ALL,
    },
    {
      icon: icons.bulkmport,
      iconLightModeHover: (
        <PublishIcon fill={colorPrimary} width="2rem" height="2rem" />
      ),
      iconDarkModeHover: (
        <PublishIcon fill={white} width="2rem" height="2rem" />
      ),
      name: 'Import dossiers',
      link: '/import-dossiers',
      permission: PERMISSIONS.BULK_IMPORT,
    },
    {
      icon: icons.faq,
      iconLightModeHover: (
        <SupportIcon fill={colorPrimary} width="2rem" height="2rem" />
      ),
      iconDarkModeHover: (
        <SupportIcon fill={white} width="2rem" height="2rem" />
      ),
      name: 'Aide',
      link: 'https://support.drapo.com/',
      permission: PERMISSIONS.ALL,
    },
    {
      icon: icons.myProfile,
      iconLightModeHover: (
        <AccountCircleIcon fill={colorPrimary} width="2rem" height="2rem" />
      ),
      iconDarkModeHover: (
        <AccountCircleIcon fill={white} width="2rem" height="2rem" />
      ),
      name: 'Mon profil',
      link: '/mon-profil',
      permission: PERMISSIONS.ALL,
    },
    {
      icon: icons.inventory,
      iconLightModeHover: (
        <InventoryIcon fill={colorPrimary} width="2rem" height="2rem" />
      ),
      iconDarkModeHover: (
        <InventoryIcon fill={white} width="2rem" height="2rem" />
      ),
      name: 'Voir dossiers P4',
      link: installer.url_2021 ? installer.url_2021 : '',
      permission: PERMISSIONS.ALL,
    },
    {
      icon: icons.logout,
      iconLightModeHover: (
        <LogoutIcon fill={colorPrimary} width="2rem" height="2rem" />
      ),
      iconDarkModeHover: <LogoutIcon fill={white} width="2rem" height="2rem" />,
      name: 'Déconnexion',
      link: '/',
      permission: PERMISSIONS.ALL,
    },
    /*
    {
      icon: iconToolbox,
      name: 'Boites à outils',
      link: '/boite-a-outils',
      permission: PERMISSIONS.ALL,
    },
    */
  ];

  const navAfterPermissions = nav.filter((item) => {
    if (item.permission === PERMISSIONS.ALL) {
      return item;
    }
    if (localStorage.getItem('permission')?.indexOf(item.permission) !== -1) {
      return item;
    }

    return false;
  });

  const gotToDashboard = () => {
    updateActiveCustomView(false);
    updateCurrentListCustomView(undefined);
    updateNewConditions([]);
    updateElementActive(DASHBOARD_TABS.SUMMARY);
  };

  return (
    <div className="w-full">
      <div
        style={{ borderRadius: radiusPx }}
        className="dark:bg-dark-2 bg-white w-full bg-backgroundSecondaryLightMode"
      >
        <div
          style={{
            borderTopLeftRadius: radiusPx,
            borderTopRightRadius: radiusPx,
          }}
          className="w-full  px-[1.5rem] relative  h-home-header dark:bg-dark-5 flex dark:border-none border-b border-b-borderLightMode border-b-[1px]"
        >
          <div className="w-full">
            {installer.nom_partenaire === 'DRAPO' ||
            installer.nom_partenaire === '' ? (
              <div className="w-full flex items-center justify-between pt-[2.5rem]">
                <Link
                  to="/"
                  onClick={() => {
                    gotToDashboard();
                  }}
                >
                  <LogoDrapoIcon
                    fill={isThemeDark ? white : 'black'}
                    width="7.5rem"
                    height="3rem"
                  />
                </Link>
                {installer.raison_sociale_de_lentreprise === 'DÉMO' && (
                  <ThemeCheckbox />
                )}
              </div>
            ) : (
              <Link
                to="/"
                onClick={() => {
                  gotToDashboard();
                }}
              >
                <img
                  style={{ width: '9.25rem' }}
                  className="pt-[1.875rem]"
                  src={
                    !isThemeDark
                      ? installer.custom.logo_mb_dark[0]
                      : installer.custom.logo_mb_light[0]
                  }
                  alt={`logo ${installer.nom_partenaire}`}
                />
              </Link>
            )}
          </div>
        </div>
        <div
          style={{
            borderBottomLeftRadius: radiusPx,
            borderBottomRightRadius: radiusPx,
          }}
          className={`w-full flex flex-col justify-between pt-[.875rem] bg-backgroundSecondaryLightMode dark:bg-dark-2 ${
            installer.url_2021 ? '' : 'pb-[.5rem]'
          } h-[82vh]`}
        >
          <div>
            <HomeLeftMenuCard title={session.title} name={session.name} />
            <div className="w-full text-center mt-4">
              <DashboardButton />
              {navAfterPermissions.map((item) => {
                if (item.name.includes('P4') && !installer.url_2021) {
                  return <div key={v4()} />;
                }
                // If à supprimer si on veut rendre à nouveau disponible le bouton Aide
                //----------------------------
                if (item.name === 'Aide') {
                  return false;
                }
                //----------------------------
                return (
                  <div key={v4()} className="w-full text-center">
                    <LinkButton
                      title={item.name}
                      icon={item.icon}
                      link={item.link}
                      iconLightModeHover={item.iconLightModeHover}
                      iconDarkModeHover={item.iconDarkModeHover}
                    />
                  </div>
                );
              })}
            </div>
          </div>
          <div>
            <HomeLeftMenuBugCard />
            {installer.rc && (
              <HomeLeftMenuCard
                title="Référent de compte"
                name={installer.rc}
                email={installer.email_rc}
                tel={installer.téléphone_rc}
                photo={installer.photo_rc}
              />
            )}
            {!installer.rc && (
              <HomeLeftMenuCard
                title="Nous contacter"
                name="Drapo"
                email="contact@drapo.com"
                tel="01 76 40 37 75"
              />
            )}
            {/* {installer.référent_technique && (
              <HomeLeftMenuCard
                title="Référent Technique"
                name={installer.référent_technique}
                email={installer.email_référent_technique}
                tel={installer.téléphone_référent_technique}
                photo={installer.photo_rt}
              />
            )} */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeLeftMenu;
