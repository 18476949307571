function LoaderRequestForSupplementsDashboard(): JSX.Element {
  const oneCard = (
    <div className="w-full p-[.75rem] h-[5.625rem] flex animate__infinite animate__flash animate__animated animate__slower justify-between dark:bg-dark-5 bg-lightMode-dark-5 rounded-[8px] mb-[.75rem]">
      <div>
        <p className="text-[.875rem] font-medium mb-[.5rem]" />
        <p className="font-normal text-[.75rem]" />
      </div>
      <div className="flex flex-col justify-between items-end text-[.5rem] text-medium">
        <p className="opacity-[.40]" />
        <div className="text-right" />
      </div>
    </div>
  );
  return (
    <div className="mr-[1rem]">
      {oneCard}
      {oneCard}
      {oneCard}
      {oneCard}
      {oneCard}
    </div>
  );
}

export default LoaderRequestForSupplementsDashboard;
