import { IOperationFolderType } from '@folder/utils/folderTypes';
import { FolderContext } from '@folder/utils/FoldersContext';
import { useContext } from 'react';
import { InstallerContext } from '@auth/utils/authContext';
import { v4 } from 'uuid';

function ListOperationsFolder(): JSX.Element {
  const { colorPrimary } = useContext(InstallerContext);
  const { folderDatas } = useContext(FolderContext);

  const CSS = `div::before {border-color: ${colorPrimary}}`;

  return (
    <div className="relative mt-[1.375rem] mb-[.5rem] flex flex-col space-y-[1.5rem] items-start">
      {folderDatas.operations.map(
        (operation: IOperationFolderType, index: number) => (
          <div key={v4()} className="flex items-center sb-text-rem-body2">
            <div
              className={`relative m-0 before:border-[${colorPrimary.toString()}] before:content-[''] before:absolute before:left-[.938rem] before:border-l-[.6px] before:border-solid  before:h-[2.5rem] before:w-[1px] before:top-[.75rem] before:left-[.813rem] ${
                index === folderDatas.operations.length - 1 &&
                'before:h-[0rem] before:w-0'
              }`}
              style={{ zIndex: 99999999999 }}
            >
              <style>{CSS}</style>
              <svg
                width="1.125rem"
                height="1.125rem"
                viewBox="0 0 90 90"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="ml-[.25rem]"
              >
                <circle cx="45" cy="45" r="45" fill={colorPrimary} />
              </svg>
              <svg
                width="1.5rem"
                height="1.5rem"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="absolute top-[50%] left-[50%] transform -translate-x-[12%] -translate-y-[18%]"
              >
                <path
                  d="M3.49999 6.08517L1.41499 4.00017L0.704987 4.70517L3.49999 7.50017L9.49999 1.50017L8.79499 0.795166L3.49999 6.08517Z"
                  fill="white"
                />
              </svg>
            </div>
            <div className="ml-[1.5rem] dark:text-white text-dark-1">
              {operation.operation_name}
            </div>
          </div>
        )
      )}
    </div>
  );
}

export default ListOperationsFolder;
