import { InstallerContext } from '@auth/utils/authContext';
import { useContext, useEffect } from 'react';
import Draggable from 'react-draggable';
import { purple1 } from '@assets/colors';
import { DashboardContext } from '@dashboard/utils/context/dashboardContext';
import {
  createNewListCustomView,
  updateOneCustomViewById,
} from '@dashboard/apiRequests/dashboardRequests';
import { CloseIcon } from '@assets/svgComponents/svgComponents';
import ListColumns from './ListColumns';
import DuplicateOrDeleteList from './DuplicateOrDeleteList';
import { initialSchemaCustomView } from '../../../utils/initialState';

function ModalPersonalization() {
  const {
    listVisibleColumnsCustomView,
    listInvisibleColumnsCustomView,
    isCreateNewListCustomView,
    idCurrentListCustomView,
    nameCurrentListCustomView,
    schemaFoldersCustomView,
    updateIsLoadingCustomView,
    updateCurrentListCustomView,
    updateFiltersOrPersonalizationModalCustomView,
    updateIsCreateNewListCustomView,
    updateIdCurrentListCustomView,
    filtersOrPersonalizationModalCustomView,
    updateListVisibleColumnsCustomView,
    updateListInvisibleColumnsCustomView,
    currentSchemaFolderCustomView,
    numberPageList,
    updateNumberPageList,
    updateCountsCustomListView,
    updateRowsSelectedCustomView,
  } = useContext(DashboardContext);

  const { radiusPx, installer, isThemeDark, colorTextButton } =
    useContext(InstallerContext);

  const radius = radiusPx === '0px' ? '0px' : '8px';

  const scrollBarColor = isThemeDark ? '' : 'scroll_light_mode';

  const createOrUpdateView = () => {
    if (isCreateNewListCustomView) {
      createNewListCustomView(
        listVisibleColumnsCustomView.fields,
        nameCurrentListCustomView,
        updateIsLoadingCustomView,
        updateCurrentListCustomView,
        updateIsCreateNewListCustomView,
        updateFiltersOrPersonalizationModalCustomView,
        updateIdCurrentListCustomView,
        numberPageList,
        updateCountsCustomListView,
        updateRowsSelectedCustomView
      );
    } else {
      updateOneCustomViewById(
        nameCurrentListCustomView,
        idCurrentListCustomView,
        listVisibleColumnsCustomView.fields,
        updateIsLoadingCustomView,
        updateCurrentListCustomView,
        updateFiltersOrPersonalizationModalCustomView,
        currentSchemaFolderCustomView,
        numberPageList,
        updateCountsCustomListView,
        updateRowsSelectedCustomView
      );
    }
  };
  useEffect(() => {
    updateNumberPageList(1);
    if (
      !isCreateNewListCustomView &&
      currentSchemaFolderCustomView !== undefined
    ) {
      const filtreInvisibleColumns = schemaFoldersCustomView.fields.filter(
        (field) => {
          return !currentSchemaFolderCustomView.schema.fields.some(
            (fieldVisible) => fieldVisible.provider_id === field.provider_id
          );
        }
      );
      updateListVisibleColumnsCustomView({
        name: currentSchemaFolderCustomView.name,
        fields: currentSchemaFolderCustomView.schema.fields,
        provider_id: currentSchemaFolderCustomView.schema.provider_id,
        provider_name: currentSchemaFolderCustomView.schema.provider_name,
      });
      updateListInvisibleColumnsCustomView({
        name: currentSchemaFolderCustomView.name,
        fields: filtreInvisibleColumns,
        provider_id: currentSchemaFolderCustomView.schema.provider_id,
        provider_name: currentSchemaFolderCustomView.schema.provider_name,
      });
    } else {
      updateListInvisibleColumnsCustomView(initialSchemaCustomView);
      updateListVisibleColumnsCustomView(schemaFoldersCustomView);
    }
  }, [filtersOrPersonalizationModalCustomView, schemaFoldersCustomView]);

  return (
    <>
      <Draggable handle=".modal" bounds="main">
        <div
          style={{ borderRadius: radius, zIndex: 99999999 }}
          className="absolute overflow-hidden max-w-[18.125rem] text-colorTextLightMode dark:text-white border border-borderLightMode border dark:border-[#2D2D2D] "
        >
          <div
            style={{
              backgroundColor:
                installer.nom_partenaire === 'DRAPO' ||
                installer.nom_partenaire === ''
                  ? purple1
                  : installer.custom.couleur_boutons,
            }}
            className="w-full text-[.75rem] flex justify-between items-center px-[1.5rem] py-[.625rem] modal "
          >
            <p style={{ color: colorTextButton }} className="font-medium">
              {' '}
              {isCreateNewListCustomView
                ? 'Personnaliser'
                : 'Personnaliser'}{' '}
            </p>
            <button
              type="button"
              onClick={() => updateFiltersOrPersonalizationModalCustomView('')}
            >
              <CloseIcon fill={colorTextButton} width="1rem" height="1rem" />
            </button>
          </div>
          {idCurrentListCustomView !== '' && <DuplicateOrDeleteList />}
          <div
            className={`flex flex-col ${scrollBarColor}  space-y-[1.25rem] pt-[1.25rem] border-t dark:border-t-[#3F3F3F] border-t-borderLightMode max-h-[37.5rem] overflow-y-scroll dark:bg-[#1C1E1F] bg-white pb-[1.25rem]`}
          >
            <ListColumns
              hideColumn={false}
              datas={listVisibleColumnsCustomView.fields}
            />
            <ListColumns
              hideColumn
              datas={listInvisibleColumnsCustomView.fields}
            />
          </div>
          <div className="dark:bg-[#1C1E1F] bg-white pb-[3.375rem] ">
            <button
              onClick={() => createOrUpdateView()}
              style={{
                color: colorTextButton,
                borderRadius: radius,
                backgroundColor:
                  installer.nom_partenaire === 'DRAPO' ||
                  installer.nom_partenaire === ''
                    ? purple1
                    : installer.custom.couleur_boutons,
              }}
              className="px-[1.5rem] py-[.625rem] text-[.75rem] font-medium w-1/2 float-right mr-[1.5rem] mt-[.5rem]"
              type="button"
            >
              Valider
            </button>
          </div>
        </div>
      </Draggable>
    </>
  );
}

export default ModalPersonalization;
