import { convertHexToRGBA, numFormatSpace } from '@utils/utils';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { IRecapFolderContentGeneralType } from '@dashboard/utils/dashboardTypes';
import { v4 } from 'uuid';
import { IOperationsMonitoringDatas } from '@folderMonitoring/utils/folderMonitoringTypes';
import { darkLight1 } from '@assets/colors';

interface IRecapFolderContentGeneralProps {
  operation: IOperationsMonitoringDatas;
  totalStepsCEE: number;
  totalStepsMPR: number;
  backgroundCEE: string;
  backgroundMPR: string;
  bgWidthCEE: string;
  bgWidthMPR: string;
  setDetailsActive: Dispatch<SetStateAction<boolean>>;
}
function RecapFolderContentGeneral({
  operation,
  totalStepsCEE,
  totalStepsMPR,
  backgroundCEE,
  backgroundMPR,
  bgWidthCEE,
  bgWidthMPR,
  setDetailsActive,
}: IRecapFolderContentGeneralProps): JSX.Element {
  const [isMpr, setIsMpr] = useState<boolean>(false);
  const [isCee, setIsCee] = useState<boolean>(false);

  const transformStatut = (str: string) => {
    const result = str !== null ? str.split('.') : [''];
    return result[1];
  };

  const infosGeneral: IRecapFolderContentGeneralType[] = [
    {
      nameStep: `CEE : ${
        operation.statut_cee ? transformStatut(operation.statut_cee) : ''
      }`,
      namePrime: 'Prime CEE',
      totalPrime: `${numFormatSpace(operation.prime_cee_bénéficiaire)} € TTC`,
      paymentDate: operation.status_payment_cee.replace(/-/g, '/'),
      numberStep: operation.step_cee,
      totalStep: totalStepsCEE,
      background: backgroundCEE,
      bgWidth: bgWidthCEE,
      isActive: isCee,
    },
    {
      nameStep: `MPR : ${
        operation.statut_mpr ? transformStatut(operation.statut_mpr) : ''
      }`,
      namePrime: 'Prime MPR',
      totalPrime: `${numFormatSpace(operation.montant_mpr)} € TTC`,
      paymentDate: operation.status_payment_mpr,
      numberStep: operation.step_mpr,
      totalStep: totalStepsMPR,
      background: backgroundMPR,
      bgWidth: bgWidthMPR,
      isActive: isMpr,
    },
  ];

  useEffect(() => {
    if (
      operation.type_dossier.includes('MPR') &&
      operation.statut_mpr !== 'Pas de MPR'
    ) {
      setIsMpr(true);
    }
    if (
      operation.type_dossier.includes('CEE') &&
      operation.statut_cee !== 'Pas de CEE'
    ) {
      setIsCee(true);
    }
  }, [operation.type_dossier, operation.statut_cee, operation.statut_mpr]);

  return (
    <div
      role="button"
      tabIndex={0}
      onKeyDown={undefined}
      onClick={() => setDetailsActive(true)}
    >
      {infosGeneral.map((info: IRecapFolderContentGeneralType) => {
        const nameStepArray =
          info.nameStep !== null ? info.nameStep.split(' : ') : [''];
        if (info.isActive) {
          return (
            <div
              key={v4()}
              className={`w-full px-[2rem] pt-[1.5rem] text-[.875rem] flex flex-col space-y-[.625rem] ${
                operation.type_dossier === 'CEE' ||
                operation.type_dossier === 'MPR'
                  ? 'pb-[1.25rem]'
                  : 'pb-[1rem]'
              }`}
            >
              <div className="flex items-start justify-between opacity-[.92] whitespace-nowrap">
                <div className="font-[500] flex">
                  <div>{nameStepArray[0]} :&nbsp;</div>
                  <div className="whitespace-normal">{nameStepArray[1]}</div>
                </div>
                <div className="flex items-center space-x-[3.375rem]">
                  <div className="flex items-center space-x-[2rem]">
                    <div>
                      <span className="font-[500] text-dark-light-1">
                        {info.namePrime}
                      </span>
                      <span className="pl-[.625rem]">{info.totalPrime}</span>
                    </div>
                    <div>
                      <span className="font-[500] text-dark-light-1">
                        Date de paiement estimé
                      </span>
                      <span className="pl-[.625rem]">{info.paymentDate}</span>
                    </div>
                  </div>
                  <div>{`${info.numberStep} sur ${info.totalStep}`}</div>
                </div>
              </div>
              <div
                style={{
                  backgroundColor: convertHexToRGBA(darkLight1, 0.2),
                }}
                className="h-[.15rem] w-full max-h-[.15rem]"
              >
                <div
                  style={{
                    width: `${info.bgWidth}`,
                    backgroundColor: info.background,
                    height: '100%',
                    maxHeight: '100%',
                  }}
                />
              </div>
            </div>
          );
        }
        return <div key={v4()} />;
      })}
    </div>
  );
}

export default RecapFolderContentGeneral;
