import LoaderFolderMonitoring from '@components/loaders/LoaderFolderMonitoring';
import { IListFolders } from '@dashboard/utils/dashboardTypes';
import {
  getFolder,
  getFolderMonitoringDatasMPRCEE,
} from '@folderMonitoring/apiRequest/folderMonitoringRequest';
import DeleteFolder from '@folderMonitoring/components/DeleteFolder';
import DocumentsFolder from '@folderMonitoring/components/documentsFolder/DocumentsFolder';
import HeaderFolderMonitoring from '@folderMonitoring/components/HeaderFolderMonitoring';
import RecapFolder from '@folderMonitoring/components/recapFolder/RecapFolder';
import RelativesInfos from '@folderMonitoring/components/RelativeInfos';
import { InstallerContext } from '@auth/utils/authContext';
import { FolderMonitoringContext } from '@folderMonitoring/utils/context/folderMonitoringContext';
import {
  IAllDocumentType,
  IFolderMonitoringDatas,
  IOperationsMonitoringDatas,
} from '@folderMonitoring/utils/folderMonitoringTypes';
import {
  initialAllDocuments,
  initialFolderData,
  initialOperationMonitoringData,
} from '@folderMonitoring/utils/initialStates';
import { FOLDER_MONITORING_TABS } from '@folderMonitoring/utils/tabs';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { borderLightMode } from '@assets/colors';
import { initialListFolders } from '@dashboard/utils/initialState';
import LayoutDashboard from './LayoutDashboard';

function FolderMonitoring(): JSX.Element {
  const { radiusPx, installer, installerIsLoaded, isThemeDark, colorPrimary } =
    useContext(InstallerContext);

  const { folderReference } = useParams();
  const router = useNavigate();

  const statutBackgroundColor = {
    error: '#A60028',
    done: '#2ECC71',
    inprogress: colorPrimary,
  };

  const [folderDatas, setFolderDatas] =
    useState<IFolderMonitoringDatas>(initialFolderData);
  const [detailsActive, setDetailsActive] = useState<boolean>(false);
  const [tab, setTab] = useState<string>('Suivi du dossier');
  const [renderComponent, setRenderComponent] = useState<JSX.Element>();
  const [isModalDeleteFolder, setIsModalDeleteFolder] =
    useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [deleteOperationOrFolder, setDeleteOperationOrFolder] =
    useState<string>('folder');
  const [operationActive, setOperationActive] =
    useState<IOperationsMonitoringDatas>(initialOperationMonitoringData);

  const [stepIsUpdate, setStepIsUpdate] = useState<boolean>(false);
  const [folderActif, setFolderActif] = useState<IListFolders[]>([
    initialListFolders,
  ]);
  const [allDocuments, setAllDocuments] =
    useState<IAllDocumentType>(initialAllDocuments);
  const [hasAttestation, setHasAttestation] = useState<boolean>(false);

  const radius = radiusPx === '0px' ? '0px' : '16px';

  useEffect(() => {
    setTab(FOLDER_MONITORING_TABS.RECAP);
    setIsLoading(true);
    if (installerIsLoaded) {
      getFolderMonitoringDatasMPRCEE(
        folderReference || '',
        setFolderDatas,
        setIsLoading,
        router
      );
      getFolder(folderReference || '', setFolderActif);
    }
  }, [installer, folderReference]);

  useEffect(() => {
    switch (tab) {
      case FOLDER_MONITORING_TABS.RECAP:
        setRenderComponent(<RecapFolder />);
        break;
      case FOLDER_MONITORING_TABS.INFOS:
        setRenderComponent(<RelativesInfos />);
        break;
      case FOLDER_MONITORING_TABS.DOCS:
        setRenderComponent(<DocumentsFolder />);
        break;
      default:
        setRenderComponent(<div />);
    }
  }, [tab]);

  const folderMonitoringContextValue = {
    folderDatas,
    updateFolderDatas: setFolderDatas,
    detailsActive,
    updateDetailsActive: setDetailsActive,
    tab,
    updateTab: setTab,
    isModalDeleteFolder,
    updateIsModalDeleteFolder: setIsModalDeleteFolder,
    isLoading,
    updateIsLoading: setIsLoading,
    stepIsUpdate,
    updateStepIsUpdate: setStepIsUpdate,
    allDocuments,
    updateAllDocuments: setAllDocuments,
    folderActif,
    updateFolderActif: setFolderActif,
    deleteOperationOrFolder,
    updateDeleteOperationOrFolder: setDeleteOperationOrFolder,
    operationActive,
    updateOperationActive: setOperationActive,
    statutBackgroundColor,
    hasAttestation,
    updateHasAttestation: setHasAttestation,
  };

  return (
    <FolderMonitoringContext.Provider value={folderMonitoringContextValue}>
      <LayoutDashboard>
        <div
          style={{
            borderRight: isThemeDark
              ? `1px solid #3C3B3B`
              : `1px solid ${borderLightMode}`,
          }}
          className=" w-full lg:border-r lg:border-r-[#3C3B3B]"
        >
          <HeaderFolderMonitoring />
          <div
            className="w-full dark:bg-dark-1 bg-backgroundSecondaryLightMode pb-[1.5rem] lg:h-[82vh]"
            style={{
              borderBottomLeftRadius: radius,
            }}
          >
            <div
              className={`w-full h-full ${
                tab === FOLDER_MONITORING_TABS.RECAP && !isLoading
                  ? 'pt-[1.5rem]'
                  : 'pt-[2rem]'
              } ${isLoading ? 'px-[2rem]' : 'px-[.625rem]'}`}
            >
              {isLoading ? <LoaderFolderMonitoring /> : renderComponent}
              {isModalDeleteFolder && <DeleteFolder />}
            </div>
          </div>
        </div>
      </LayoutDashboard>
    </FolderMonitoringContext.Provider>
  );
}

export default FolderMonitoring;
