import { Dispatch, SetStateAction } from 'react';
import Delete from './Delete';
import SelectConditions from '../forms/SelectConditions';
import SelectFields from '../forms/SelectFields';
import {
  IFieldType,
  IStepOperationType,
  IStepType,
} from '../../utils/AdminTypes';

type PropTypes = {
  step: IStepType;
  updateSteps: Dispatch<SetStateAction<IStepType[]>>;
  setShowPopup: Dispatch<SetStateAction<string>>;
  showPopup: string;
  setOperationLoading: Dispatch<SetStateAction<boolean>>;
  setOperations: Dispatch<SetStateAction<IStepOperationType[]>>;
  stepFieldsSelect: IFieldType[];
  setStepFieldsSelect: Dispatch<SetStateAction<IFieldType[]>>;
  stepName: string;
};

export default function PopupContainer({
  step,
  updateSteps,
  setShowPopup,
  setOperations,
  setOperationLoading,
  showPopup,
  stepFieldsSelect,
  setStepFieldsSelect,
  stepName,
}: PropTypes): JSX.Element {
  return (
    <div>
      {showPopup === 'DELETE' && (
        <Delete
          step={step}
          updateSteps={updateSteps}
          setShowPopup={setShowPopup}
          setOperationLoading={setOperationLoading}
        />
      )}
      {(showPopup === 'SELECT_CONDITIONS' ||
        showPopup === 'SAVE_CONDITIONS') && (
        <SelectConditions
          stepFieldsSelect={stepFieldsSelect}
          showPopup={showPopup}
          setShowPopup={setShowPopup}
          currentStep={step}
          updateSteps={updateSteps}
          setOperations={setOperations}
          setOperationLoading={setOperationLoading}
          stepName={stepName}
        />
      )}
      {showPopup === 'SELECT_FIELDS' && (
        <SelectFields
          stepFieldsSelect={stepFieldsSelect}
          setStepFieldsSelect={setStepFieldsSelect}
          currentStep={step}
          updateSteps={updateSteps}
          setShowPopup={setShowPopup}
          setOperations={setOperations}
          setOperationLoading={setOperationLoading}
          stepName={stepName}
        />
      )}
    </div>
  );
}
