/* eslint-disable react/jsx-props-no-spreading */
import { InstallerContext } from '@auth/utils/authContext';
import { uploadXLS } from '@bulkImport/apiRequests/bulkImportRequest';
import { BulkImportContext } from '@bulkImport/utils/bulkImportContext';
import { useContext } from 'react';
import Dropzone from 'react-dropzone';
import {
  borderLightMode2,
  dark1,
  lightModeDarkVariant5,
  purple1,
  white,
  colorTextLightMode,
} from '@assets/colors';
import { DownloadIcon, PublishIcon } from '@assets/svgComponents/svgComponents';

function UploadXls(): JSX.Element {
  const {
    updateFileNameUploaded,
    updateFileUploaded,
    updateIsErrorXLS,
    updateIsUploaded,
    updateFileDetail,
    updateFileIsLoading,
    updateCoordinatesArrayForMapLoading,
    updateErrorWindow,
    updateErrorWindowHeader,
    updateErrorWindowContent,
    updateZoomMap,
    fileUploaded,
    fileNameUploaded,
    updateFolderList,
    updateFileSentToAirtable,
    updateWasSentToAirtable,
    errorWindow,
  } = useContext(BulkImportContext);
  const { installer, radiusPx, isThemeDark, colorTextButton } =
    useContext(InstallerContext);
  const checkIfUploadDisabled = () => {
    return (
      fileUploaded &&
      fileNameUploaded !== '' &&
      fileUploaded?.status !== 'Error' &&
      fileUploaded?.status !== 'Scanning' &&
      fileUploaded?.status !== 'Creation'
    );
  };
  const backgroundColorUpload = isThemeDark ? dark1 : 'white';

  const radius = radiusPx === '0px' ? '0px' : '8px';

  return (
    <div
      style={{ borderRadius: radiusPx }}
      className="dark:bg-dark-5 bg-lightMode-dark-5 mt-[1.5rem] p-[1.5rem] w-11/12 dark:border-none border border-borderLightMode"
    >
      <p className="sb-text-rem-body2 dark:text-white text-dark-1">
        Téléchargez le fichier type à compléter puis ajoutez-le ci-dessous pour
        importer vos dossiers.
      </p>
      <a
        href={`${process.env.REACT_APP_BACK_PRO_URL}/format_import_ok.xlsx`}
        download
        target="_blank"
        rel="noreferrer"
      >
        <button
          style={{
            backgroundColor:
              installer.nom_partenaire === 'DRAPO' ||
              installer.nom_partenaire === ''
                ? purple1
                : installer.custom.couleur_boutons,
            borderRadius: radius,
            color: colorTextButton,
          }}
          type="button"
          className={`flex sb-text-rem-caption1 dark:text-white text-colorTextLightMode justify-between items-center w-full mt-[1rem] py-[.75rem] px-[1rem] `}
        >
          Télécharger le fichier type
          <DownloadIcon fill={colorTextButton} width="1.8rem" height="1.8rem" />
        </button>
      </a>

      <Dropzone
        disabled={checkIfUploadDisabled()}
        accept={{
          'application/xlsx': ['.xlsx', '.xlsm'],
        }}
        onDrop={(acceptedFiles) => {
          if (errorWindow) {
            updateErrorWindow(false);
          }
          updateFileSentToAirtable(undefined);
          updateWasSentToAirtable(false);
          updateFileIsLoading(true);
          updateFileUploaded(undefined);
          updateIsUploaded(true);
          // MET A JOUR LE NOM DU FICHIER EN COURS D'IMPORTATION (LeftPartBulkImport.tsx)
          updateFileNameUploaded(acceptedFiles[0].name);
          // REQUETE POUR TELECHARGER LE FICHIER
          uploadXLS(
            acceptedFiles[0],
            updateFileNameUploaded,
            updateIsErrorXLS,
            updateFileUploaded,
            updateIsUploaded,
            updateFileIsLoading,
            updateCoordinatesArrayForMapLoading,
            updateErrorWindow,
            updateErrorWindowHeader,
            updateErrorWindowContent,
            updateZoomMap
          );
          // INDIQUE QUE L'ON NE NAVIGUE PLUS ENTRE LES FICHIERS
          updateFileDetail(undefined);
          updateFolderList([]);
        }}
      >
        {({ getRootProps, getInputProps }) => (
          <section>
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <button
                style={{
                  borderRadius: radius,
                  border: `1px dashed ${
                    isThemeDark ? '#C4C4C4' : borderLightMode2
                  }`,
                  backgroundColor: checkIfUploadDisabled()
                    ? lightModeDarkVariant5
                    : backgroundColorUpload,
                  cursor: checkIfUploadDisabled() ? 'not-allowed' : 'pointer',
                }}
                type="button"
                className="flex sb-text-rem-caption1 justify-between items-center w-full mt-[1rem] py-[.75rem] px-[1rem]  dark:bg-dark-1 bg-lightMode-dark-1"
              >
                <p className="sb-text-rem-caption1 dark:text-white text-dark-1">
                  Ajouter un fichier
                </p>
                <PublishIcon
                  fill={isThemeDark ? white : colorTextLightMode}
                  width="1.8rem"
                  height="1.8rem"
                />
              </button>
            </div>
          </section>
        )}
      </Dropzone>
    </div>
  );
}

export default UploadXls;
