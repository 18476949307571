import { Dispatch, SetStateAction, useContext } from 'react';
import { IStatus } from '@dashboard/utils/dashboardTypes';
import { numFormatSpace } from '@utils/utils';
import { InstallerContext } from '@auth/utils/authContext';
import { dark3, white } from '@assets/colors';
import StepsList from './StepsList';

interface FoldersStatusProps {
  datas: IStatus;
  title: string;
  updateStatus: Dispatch<SetStateAction<string[]>>;
}

function NewOperationsStatus({
  datas,
  title,
  updateStatus,
}: FoldersStatusProps): JSX.Element {
  const { installer, radiusPx, isThemeDark } = useContext(InstallerContext);

  const notEligible = () => {
    if (title.includes('CEE') && !installer.eligibility.includes('CEE')) {
      return true;
    }
    return title.includes('MPR') && !installer.eligibility.includes('MPR');
  };

  return (
    <div className="w-full px-[2rem] ">
      <div className="flex items-center justify-between pb-[1rem]">
        <div className="text-[.875rem] font-[500] dark:text-white text-colorTextLightMode">
          {title}
        </div>
        <div className="text-[.75rem] dark:text-white text-colorTextLightMode">{`${numFormatSpace(
          datas.total
        )} opération${datas.total > 1 ? 's' : ''}`}</div>
      </div>
      <div
        style={{
          borderRadius: radiusPx,
          backgroundColor: isThemeDark ? dark3 : white,
        }}
        className="flex flex-col space-y-[.5rem] p-[.7rem] dark:border-borderDarkMode border border-borderLightMode"
      >
        {!notEligible() ? (
          <>
            <StepsList
              datas={datas.datas}
              updateStatus={updateStatus}
              primeType={title.includes('CEE') ? 'CEE' : 'MPR'}
            />
          </>
        ) : (
          <p className="text-[.875rem] m-[1.5rem] dark:text-white text-dark-1">
            Vous ne bénéficiez pas de ce dispositif. Si vous voulez en disposer,
            veuillez contacter votre référent de compte.
          </p>
        )}
      </div>
    </div>
  );
}

export default NewOperationsStatus;
