import React, { Dispatch, SetStateAction } from 'react';
import {
  IGraphType,
  ILatLng,
  IOperationDatasType,
  ISimulatorDatasType,
} from '@simulator/utils/simulatorTypes';
import { ICadastre } from './simulatorTypes';

interface SimulatorContextState {
  title: string;
  updateTitle: Dispatch<SetStateAction<string>>;
  subtitle: string;
  updateSubtitle: Dispatch<SetStateAction<string>>;
  stepNumber: number;
  updateStepNumber: Dispatch<SetStateAction<number>>;
  stepName: string;
  updateStepName: Dispatch<SetStateAction<string>>;
  urlImg: string;
  updateUrlImg: Dispatch<SetStateAction<string>>;
  simulatorDatas: ISimulatorDatasType;
  updateSimulatorDatas: Dispatch<SetStateAction<ISimulatorDatasType>>;
  graphGeneral: IGraphType;
  updateGraphGeneral: Dispatch<SetStateAction<IGraphType>>;
  graphList: IGraphType[];
  updateGraphList: Dispatch<SetStateAction<IGraphType[]>>;
  graphOperation: IGraphType;
  updateGraphOperation: Dispatch<SetStateAction<IGraphType>>;
  graphListOperation: IGraphType[];
  updateGraphListOperation: Dispatch<SetStateAction<IGraphType[]>>;
  updateOperationsIdRecoveredGraph: Dispatch<SetStateAction<string[]>>;
  latLngData: ILatLng;
  updateLatLngData: Dispatch<SetStateAction<ILatLng>>;
  coordinates: ILatLng[];
  updateCoordinates: Dispatch<SetStateAction<ILatLng[]>>;
  markerMap: boolean;
  updateMarkerMap: Dispatch<SetStateAction<boolean>>;
  currentOperation: IOperationDatasType;
  updateCurrentOperation: Dispatch<SetStateAction<IOperationDatasType>>;
  operationActiveId: string;
  updateOperationActiveId: Dispatch<SetStateAction<string>>;
  multipleOperations: IOperationDatasType[];
  updateMultipleOperations: Dispatch<SetStateAction<IOperationDatasType[]>>;
  generalComplete: boolean;
  updateGeneralComplete: Dispatch<SetStateAction<boolean>>;
  loaded: boolean;
  updateHeigthMap: Dispatch<SetStateAction<string>>;
  heightMap: string;
  updateLoaded: Dispatch<SetStateAction<boolean>>;
  incompatibles: string[][];
  updateIncompatibles: Dispatch<SetStateAction<string[][]>>;
  updateSurfaceIsolate: Dispatch<SetStateAction<number>>;
  surfaceIsolate: number;
  totalCostAmountMin: number;
  updateTotalCostAmountMin: Dispatch<SetStateAction<number>>;
  isBack: boolean;
  updateIsBack: Dispatch<SetStateAction<boolean>>;
  auditFromSummary: boolean;
  updateAuditFromSummary: Dispatch<SetStateAction<boolean>>;
  cadastreDatas: ICadastre;
  updateCadastreDatas: Dispatch<SetStateAction<ICadastre>>;
  divLeftWidth: number | undefined;
  errorCalculationPrimes: string | undefined;
  updateErrorCalculationPrimes: Dispatch<SetStateAction<string | undefined>>;
  operationInEdit: boolean;
  updateOperationInEdit: Dispatch<SetStateAction<boolean>>;
  hasEcsIncompatibility: boolean;
  updateHasEcsIncompatibility: Dispatch<SetStateAction<boolean>>;
}

export const SimulatorContext = React.createContext(
  {} as SimulatorContextState
);

interface OperationsList {
  operationList: IOperationDatasType[];
  updateOperationList: Dispatch<SetStateAction<IOperationDatasType[]>>;
}

export const OperationsListContext = React.createContext({} as OperationsList);
