import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import {
  Button,
  Input,
  WarningNotification,
} from 'storybook-design-system-drapo';
import Loader from '@components/loaders/Loader';
import { purple1 } from '@assets/colors';
import { ChevronIcon } from '@assets/svgComponents/svgComponents';
import { resetPassword } from '@admin/apiRequests/AdminRequests';

function ResetPassword(): JSX.Element {
  const router = useNavigate();
  const queryParams = new URLSearchParams(window.location.search);
  const token = queryParams.get('token');

  useEffect(() => {
    if (!token) {
      const path = `/`;
      router(path);
    }
  }, [token]);

  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');

  const [errMessage, setErrMessage] = useState<string>('');
  const [errBorderPassword, setErrBorderPassword] = useState<string>('');
  const [errBorderMail, setErrBorderMail] = useState<string>('');

  const [loading, setLoading] = useState<boolean>(false);
  const [isSend, setIsSend] = useState<boolean>(false);

  const success =
    'Le nouveau mot de passe a bien été enregistré, veuillez vous reconnecter.';

  const cleanErrBorders = () => {
    setErrBorderPassword('');
    setErrBorderMail('');
    setErrMessage('');
  };

  const handleSubmit = () => {
    if (password !== confirmPassword) {
      setErrBorderPassword('border border-red-500');
      setErrMessage('Les mots de passe ne sont pas identiques.');
    } else {
      setLoading(true);
      resetPassword(
        email,
        token,
        password,
        setIsSend,
        setLoading,
        setErrMessage,
        setErrBorderMail,
        setErrBorderPassword
      );
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent<EventTarget>) => {
    if (e.key === 'Enter' && !loading) {
      handleSubmit();
    }
  };

  return (
    <div>
      <div
        style={{ transform: 'translate(-50%, -50%)', left: '50%', top: '50%' }}
        className="flex dark:bg-dark-2 bg-lightMode-dark-2 rounded-default absolute lg:w-8/12 w-11/12 lg:pt-0 pt-[1.25rem]"
      >
        <div className="flex w-full flex-col self-center space-y-[1rem] p-[.5rem] lg:p-[3rem] xl:w-3/6">
          <h2 className="text-[2.25rem] lg:text-left text-center mb-[3.5rem] 3xl:text-center">
            Réinitialiser mot de passe oublié
          </h2>
          {!isSend ? (
            <>
              <Input
                addClassInput="dark-mode-input"
                inputType="email"
                label="Email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  cleanErrBorders();
                }}
                backgroundColor="#191919"
                color="#FFFFFF"
                addClass={`mb-[1rem] ${errBorderMail}`}
                dataTestId="email"
                onKeyPress={(e) =>
                  handleKeyPress(e as React.KeyboardEvent<HTMLInputElement>)
                }
              />
              <Input
                addClassInput="dark-mode-input"
                inputType="password"
                label="Nouveau mot de passe"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                  cleanErrBorders();
                }}
                backgroundColor="#191919"
                color="#FFFFFF"
                addClass={errBorderPassword}
                dataTestId="password"
                onKeyPress={(e) =>
                  handleKeyPress(e as React.KeyboardEvent<HTMLInputElement>)
                }
              />
              <Input
                addClassInput="dark-mode-input"
                inputType="password"
                label="Confirmer le mot de passe"
                value={confirmPassword}
                onChange={(e) => {
                  setConfirmPassword(e.target.value);
                  cleanErrBorders();
                }}
                backgroundColor="#191919"
                color="#FFFFFF"
                addClass={errBorderPassword}
                dataTestId="confirm-password"
                onKeyPress={(e) =>
                  handleKeyPress(e as React.KeyboardEvent<HTMLInputElement>)
                }
              />
              {errMessage !== '' && (
                <p
                  id="error-message"
                  className="text-red-500 text-[.75rem] mt-[.5rem] ml-[.5rem] opacity-60"
                >
                  {errMessage}
                </p>
              )}
              <div className="w-full mb-[1rem] 3xl:w-3/5">
                <Button
                  disabled={!!loading}
                  label="Réactualiser mot de passe"
                  color="#FFFFFF"
                  backgroundColor={purple1}
                  arrow
                  addClass="btn-login lg:mr-[.75rem]"
                  onClick={() => handleSubmit()}
                  dataTestId="reset-password"
                />
              </div>
              {loading && <Loader />}
            </>
          ) : (
            <>
              <WarningNotification
                warningText={success}
                backgroundColor={purple1}
                color="#ffffff"
              />
              <Link to="/">
                <div className="flex items-center space-x-[.625rem] mt-[1rem] opacity-80 hover:opacity-100">
                  <ChevronIcon
                    width="1.2rem"
                    height="1.2rem"
                    fill="white"
                    transform="rotate(180)"
                  />
                  <div>Aller à la page de connexion</div>
                </div>
              </Link>
            </>
          )}
        </div>
        <div className="hidden xl:block xl:w-3/6">
          <div className="h-full w-full rounded-r-default bg-login-img bg-center bg-cover" />
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;
