import {
  IWhiteLabelCustomType,
  IGraphType,
  IOperationDatasType,
  ISimulatorDatasType,
} from '@simulator/utils/simulatorTypes';

export const initialGraph: IGraphType = {
  name: '',
  id: '',
  description: '',
  type: '',
  options: [],
  condition: [],
  childrens: [],
};

export const initialSimulatorDatas: ISimulatorDatasType = {
  age: '',
  habitation: '',
  persons: '',
  owner: '',
  surface: '',
  energy: '',
  works: {
    worksType: { activite: 'residentiel' },
    worksStreet: '',
    worksStreetNumber: '',
    worksZip: '',
    worksCity: '',
  },
  income: {
    title: '',
    label: '',
    value: '',
    legend: '',
    menage: '',
    color: '',
  },
};

export const initialOperationDatas: IOperationDatasType = {
  id: '',
  name: '',
  totalCost: '',
  eligibility: [''],
  primes: {
    mpr: 0,
    cdp: 0,
    cee: 0,
    limitCee: 0,
    fees: {
      MPR: {
        feesNoLimit: 0,
        lowerLimit: 100,
        percent: 0.15,
        upperLimit: 700,
      },
    },
    price: {
      CEE: {
        id: '',
        amount: '',
        date: '',
      },
      MPR: {
        id: '',
        percent: '',
      },
    },
  },
};

export const operationDatasToPush = (
  slug: string,
  name: string,
  eligibility: string[]
): IOperationDatasType => ({
  id: slug,
  name,
  eligibility,
  totalCost: '',
  primes: {
    mpr: 0,
    cdp: 0,
    cee: 0,
    limitCee: 0,
    fees: {
      MPR: {
        feesNoLimit: 0,
        lowerLimit: 100,
        percent: 0.15,
        upperLimit: 700,
      },
    },
    price: {
      CEE: {
        id: '',
        amount: '',
        date: '',
      },
      MPR: {
        id: '',
        percent: '',
      },
    },
  },
});

export const intialWhiteLabelCustom: IWhiteLabelCustomType = {
  couleur_boutons: '',
  logo_mb_dark: [''],
  logo_mb_light: [''],
  sans_radius: '',
  couleur_alerte: '',
  image_illustration_mb: '',
  url_inscription: '',
  message_connexion: '',
  favicon: '',
};

export const initialNbNotifs = {
  cee: 0,
  mpr: 0,
  toutes: 0,
};

export const energyLabels = [
  {
    label: 'Classe A',
    value: 'A',
  },
  {
    label: 'Classe B',
    value: 'B',
  },
  {
    label: 'Classe C',
    value: 'C',
  },
  {
    label: 'Classe D',
    value: 'D',
  },
  {
    label: 'Classe E',
    value: 'E',
  },
  {
    label: 'Classe F',
    value: 'F',
  },
  {
    label: 'Classe G',
    value: 'G',
  },
];
