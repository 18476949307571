import { InstallerContext } from '@auth/utils/authContext';
import { sendFolderToAirtable } from '@bulkImport/apiRequests/bulkImportRequest';
import { BulkImportContext } from '@bulkImport/utils/bulkImportContext';
import { Dispatch, SetStateAction, useContext } from 'react';
import { Button } from 'storybook-design-system-drapo';
import { numFormatSpace } from '@utils/utils';
import { borderLightMode } from '@assets/colors';

interface IProps {
  setLoading?: Dispatch<SetStateAction<boolean>>;
  isLoading?: boolean;
}

function HeaderRightPart({ setLoading, isLoading }: IProps): JSX.Element {
  const {
    fileDetail,
    foldersIsCompleted,
    updateFileIsPushingToAirtable,
    updateFileDetail,
    updateWasSentToAirtable,
    wasSentToAirtable,
    updateFileSentToAirtable,
    fileSentToAirtable,
  } = useContext(BulkImportContext);
  const { colorPrimary, radiusPx, colorTextButton, isThemeDark } =
    useContext(InstallerContext);
  const fileToDisplay = wasSentToAirtable ? fileSentToAirtable : fileDetail;

  return (
    <div
      style={{
        borderTopLeftRadius: radiusPx,
        borderTopRightRadius: radiusPx,
        borderBottom: isThemeDark ? '' : `1px solid ${borderLightMode}`,
      }}
      className="lg:flex justify-between dark:bg-dark-4 bg-lightMode-dark-4 w-full  px-[1.5rem] items-center h-[6.25rem] dark:text-white text-colorTextLightMode"
    >
      <div className="lg:w-3/4 pr-[1rem]">
        <div className="lg:flex justify-start items-center space-x-[1rem]">
          <p className="font-medium sb-text-rem-body2 text-center lg:text-left">
            {fileToDisplay?.file}
          </p>
          <p className="sb-text-rem-caption1 text-center lg:text-left">
            {fileToDisplay?.nb_operations}
            {fileToDisplay?.nb_operations && fileToDisplay?.nb_operations > 1
              ? ' Opérations'
              : ' Opération'}
          </p>
          <p className="sb-text-rem-caption1 text-center lg:text-left">
            {fileToDisplay?.nb_folders}
            {fileToDisplay?.nb_folders && fileToDisplay?.nb_folders > 1
              ? ' Dossiers'
              : ' Dossier'}
          </p>
          <p className="sb-text-rem-caption1 text-center lg:text-left">
            {fileToDisplay ? numFormatSpace(fileToDisplay?.nb_mpr, true) : '0'}{' '}
            € MPR
          </p>
          <p className="sb-text-rem-caption1 text-center lg:text-left">
            {fileToDisplay ? numFormatSpace(fileToDisplay?.nb_cee, true) : '0'}{' '}
            € CEE
          </p>
          <p className="sb-text-rem-caption1 text-center lg:text-left">
            {fileToDisplay
              ? numFormatSpace(fileToDisplay?.nb_cee_partner, true)
              : '0'}{' '}
            € CEE Partenaire
          </p>
        </div>
      </div>
      {fileToDisplay?.status !== 'Done' && !wasSentToAirtable && (
        <div className="h-[3.5rem] lg:mt-0 mt-[1rem]">
          <Button
            borderRadius={radiusPx}
            disabled={!foldersIsCompleted || isLoading}
            label="Valider"
            arrow
            color={colorTextButton}
            backgroundColor={colorPrimary}
            onClick={() => {
              if (fileToDisplay !== undefined)
                sendFolderToAirtable(
                  fileToDisplay.id,
                  updateFileIsPushingToAirtable,
                  setLoading,
                  updateFileDetail,
                  updateWasSentToAirtable,
                  updateFileSentToAirtable
                );
            }}
          />
        </div>
      )}
    </div>
  );
}

export default HeaderRightPart;

HeaderRightPart.defaultProps = {
  setLoading: () => {
    return false;
  },
  isLoading: false,
};
