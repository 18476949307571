import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { getSchemaChoices } from '../../apiRequests/AdminRequests';
import {
  IFieldType,
  ISchemaChoiceType,
  IStepConditionType,
} from '../../utils/AdminTypes';

type PropTypes = {
  condition: IStepConditionType;
  stepFieldsSelect: IFieldType[];
  conditions: IStepConditionType[];
  updateConditions: Dispatch<SetStateAction<IStepConditionType[]>>;
  conditionsFieldsIds: (string | undefined)[];
  setConditionsFieldsIds: Dispatch<SetStateAction<(string | undefined)[]>>;
};

type OperatorType = {
  name: string;
  value: string;
};

type NewValues = {
  value?: string;
  provider_name?: string;
  provider_type?: string;
  provider_id?: string;
  condition?: string;
};

export default function Condition({
  condition,
  stepFieldsSelect,
  updateConditions,
  conditions,
  conditionsFieldsIds,
  setConditionsFieldsIds,
}: PropTypes): JSX.Element {
  const [schemaChoices, setSchemaChoices] = useState<ISchemaChoiceType[]>([]);
  const [conditionLoading, setConditionLoading] = useState<boolean>(false);
  const [fieldInputValue, setFieldInputValue] = useState<string>('');
  const [choices, setChoices] = useState<string[]>(
    condition.value !== null ? condition.value.split('||') : ['']
  );

  const initialOperators = [
    { name: 'contient', value: 'ILIKE' },
    { name: 'ne contient pas', value: 'NOT ILIKE' },
    { name: 'est égal à', value: '=' },
    { name: 'est différent de', value: '<>' },
  ];

  const operators: OperatorType[] =
    condition.provider_type !== 'singleSelect'
      ? initialOperators
      : [
          { name: 'est parmis', value: 'IN' },
          { name: "n'est pas parmis", value: 'NOT IN' },
          ...initialOperators,
        ];

  const setConditions = (newValues: NewValues) => {
    let newConditions: IStepConditionType[] = [];
    conditions.forEach((elt: IStepConditionType) => {
      if (elt.id === condition.id) {
        newConditions = [...newConditions, { ...elt, ...newValues }];
      } else {
        newConditions = [...newConditions, elt];
      }
    });

    updateConditions(newConditions);
  };

  const handleOnSelectField = (provider: string) => {
    const splitProvider = provider !== null ? provider.split('split') : [''];
    const defaultCondition = splitProvider[2] === 'singleSelect' ? 'IN' : '=';
    setConditions({
      provider_name: splitProvider[0],
      provider_id: splitProvider[1],
      provider_type: splitProvider[2],
      condition: defaultCondition,
      value: '',
    });
    setFieldInputValue('');
  };

  const handleOnSelectOperator = (operator: string) => {
    setConditions({ condition: operator });
  };

  const handleDeleteCondition = () => {
    const newConditions = conditions.filter(
      (conditionValue) => conditionValue.id !== condition.id
    );

    updateConditions(newConditions);
  };

  useEffect(() => {
    setConditions({ value: fieldInputValue });
  }, [fieldInputValue]);

  useEffect(() => {
    if (condition.provider_id)
      if (condition.provider_type !== 'singleSelect') {
        setSchemaChoices([]);
      } else {
        setConditionLoading(true);

        getSchemaChoices(
          condition.provider_id,
          setSchemaChoices,
          setConditionLoading
        );
      }
    setConditionsFieldsIds(conditions.map((field) => field.provider_id));
  }, [condition]);

  const handleChangeValue = (value: string) => {
    if (!choices.includes(value)) {
      let updatedChoices: string[] = [];
      if (choices[0]) {
        updatedChoices = [...choices, value];
      } else {
        updatedChoices = [value];
      }
      setChoices(updatedChoices);
    }
  };

  const handleDeleteChoice = (value: string) => {
    const newChoices = choices.filter((choice) => choice !== value);
    setChoices(newChoices);
  };

  return (
    <>
      <div className="bg-black w-3/4 p-5 rounded-md">
        <div className="flex items-center justify-between ">
          <select
            onChange={(e) => handleOnSelectField(e.currentTarget.value)}
            className="bg-transparent w-1/3"
            value={`${condition.provider_name}split${condition.provider_id}split${condition.provider_type}`}
          >
            {stepFieldsSelect.map((field: IFieldType) => (
              <option
                key={field.provider_id}
                className="text-white p-2 text-sm"
                value={`${field.provider_name}split${field.provider_id}split${field.provider_type}`}
                disabled={conditionsFieldsIds.includes(field.provider_id)}
              >
                {field.provider_name.slice(0, 35)}
              </option>
            ))}
          </select>
          <select
            onChange={(e) => handleOnSelectOperator(e.currentTarget.value)}
            className="bg-transparent w-1/6"
            value={condition.condition}
          >
            {operators.map((operatorValue: OperatorType) => (
              <option
                key={operatorValue.value}
                className="text-white p-2 text-sm"
                value={operatorValue.value}
              >
                {operatorValue.name}
              </option>
            ))}
          </select>
          {!conditionLoading && !schemaChoices[0] ? (
            <input
              className=" dark:bg-dark-3 bg-lightMode-dark-3 rounded-[8px] h-[50px] p-3 w-1/3"
              placeholder="Entrer une valeur"
              type="text"
              value={condition.value}
              onChange={(e) => setFieldInputValue(e.target.value)}
            />
          ) : (
            <select
              onChange={(e) => handleChangeValue(e.target.value)}
              className="text-white p-2 text-sm w-1/3 overflow-x-hidden bg-transparent"
            >
              <option>Ajouter un {condition.provider_name} </option>
              {schemaChoices
                .filter((choice) => !choices.includes(choice.value))
                .map((choice) => (
                  <option
                    key={choice.id}
                    value={choice.value}
                    className="text-white p-2 text-sm overflow-x-hidden"
                    disabled={choices.includes(choice.value)}
                  >
                    {choice.value}
                  </option>
                ))}
            </select>
          )}
          <button
            type="button"
            className="absolute right-10 text-[40px]  
              bg-purple-1 text-[white] transition duration-300 h-[45px] flex items-center px-3 rounded-md"
            onClick={handleDeleteCondition}
          >
            <span className="relative left-[1px] top-[1px] ">-</span>
          </button>
        </div>
      </div>
      {condition.provider_type === 'singleSelect' && choices[0] && (
        <div className="bg-[#CECECE] w-3/4 p-2 rounded-md overflow-x-auto flex items-center h-[70px] gap-2 overflow-y-hidden">
          {choices.map((choice) => (
            <div
              className="text-[12px] bg-purple-1 p-2 rounded-sm h-[50px] whitespace-nowrap	flex items-center gap-5"
              key={Math.random()}
            >
              {choice}
              <button
                type="button"
                className="rotate-45 text-[25px]"
                onClick={() => handleDeleteChoice(choice)}
              >
                +
              </button>
            </div>
          ))}
        </div>
      )}
    </>
  );
}
