/* eslint-disable react/jsx-props-no-spreading */
import { InstallerContext } from '@auth/utils/authContext';
import { useContext } from 'react';
import { FieldValues, UseFormRegister } from 'react-hook-form';

interface IProps {
  placeholder: string;
  register: UseFormRegister<FieldValues>;
  name: string;
  required: boolean;
  id: string;
  type: string;
}

function InputUpdate({
  placeholder,
  register,
  name,
  required,
  id,
  type,
}: IProps): JSX.Element {
  const { radiusPx } = useContext(InstallerContext);
  const radius = radiusPx === '0px' ? '0px' : '8px';
  return (
    <label htmlFor={id} className="flex w-full text-sm mt-[.5rem]">
      <input
        style={{
          borderRadius: radius,
        }}
        id={id}
        type={type}
        placeholder={placeholder}
        {...register(name, { required })}
        className={` bg-transparent w-full  border rounded-md focus:outline-none border-[#878787] py-[.625rem] px-[1rem]`}
      />
    </label>
  );
}

export default InputUpdate;
