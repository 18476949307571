/* eslint-disable react/jsx-props-no-spreading */
import { Dispatch, SetStateAction, useContext } from 'react';
import { InstallerContext } from '@auth/utils/authContext';
import { editPencilBlack } from '@assets/images/images';
import { v4 } from 'uuid';
import Dropzone from 'react-dropzone';
import { updateFileMyProfile } from '@dashboard/apiRequests/dashboardRequests';
import { IMyProfilDatas } from '@dashboard/utils/dashboardTypes';
import { MyProfileContext } from '../../../../utils/context/myProfileContext';

interface IProps {
  logo: string[];
  setDatasMyProfil: Dispatch<SetStateAction<IMyProfilDatas>>;
}
function LogoCompany({ logo, setDatasMyProfil }: IProps): JSX.Element {
  const { radiusPx, installer } = useContext(InstallerContext);
  const { updateIsUpdateLoading } = useContext(MyProfileContext);

  return (
    <div
      style={{
        borderRadius: radiusPx,
      }}
      className={` dark:bg-[#E9E9E9] bg-white min-h-[18.75rem] w-full relative overflow-hidden dark:border-none border border-borderLightMode border-[1px]`}
    >
      <Dropzone
        accept={{
          'image/*': ['.jpg', '.png'],
        }}
        onDrop={(acceptedFiles) => {
          updateFileMyProfile(
            'logo',
            acceptedFiles[0],
            setDatasMyProfil,
            updateIsUpdateLoading
          );
        }}
      >
        {({ getRootProps, getInputProps }) => (
          <section>
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <button type="button">
                <img
                  className="absolute right-[1.25rem]"
                  src={editPencilBlack}
                  alt="editer logo"
                />
              </button>
            </div>
          </section>
        )}
      </Dropzone>
      <div className="items-center h-full">
        <img
          key={v4()}
          src={logo[0]}
          alt="logo"
          className="w-3/6 m-auto mt-[5rem]"
        />
      </div>
      <div className="text-center !text-black font-medium text-[1.125rem] pb-[2.5rem] mt-[.625rem]">
        {installer.raison_sociale_de_lentreprise}
      </div>
    </div>
  );
}

export default LogoCompany;
