/* eslint-disable no-nested-ternary */
import { Dispatch, SetStateAction, useContext } from 'react';
import { IDatasStatus } from '@dashboard/utils/dashboardTypes';
import { v4 } from 'uuid';
import { convertHexToRGBA, numFormatSpace } from '@utils/utils';
import { DASHBOARD_TABS } from '@dashboard/utils/tabs';
import { DashboardContext } from '@dashboard/utils/context/dashboardContext';
import { InstallerContext } from '@auth/utils/authContext';
import { ChevronIcon } from '@assets/svgComponents/svgComponents';
import { colorTextLightMode, dark1, darkVariant2, white } from '@assets/colors';

interface StepsListProps {
  datas: IDatasStatus[];
  updateStatus: Dispatch<SetStateAction<string[]>>;
  primeType: 'CEE' | 'MPR';
}

function StepsList({
  datas,
  updateStatus,
  primeType,
}: StepsListProps): JSX.Element {
  const { radiusPx, isThemeDark, colorPrimary, colorTextButton } =
    useContext(InstallerContext);

  const radius = radiusPx === '0px' ? '0px' : '8px';

  const { updateElementActive } = useContext(DashboardContext);

  return (
    <div className="flex flex-col space-y-[.5rem] w-full">
      {datas.map((item: IDatasStatus) => {
        const stepNameSplitted = item.step.split('.');
        const stepName = stepNameSplitted[1] || stepNameSplitted[0];
        return (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
          <div
            key={v4()}
            className={`flex w-full text-[.75rem] ${
              item.nb_folders !== 0 ? 'cursor-pointer' : ''
            }`}
            onClick={() => {
              if (item.nb_folders !== 0) {
                const filterToSet =
                  primeType === 'MPR'
                    ? item.filter.mpr_status
                    : item.filter.cee_status;
                updateStatus(filterToSet);
                updateElementActive(DASHBOARD_TABS.FOLDERS_INSTRUCTION);
              }
            }}
          >
            <div
              className="w-2/12 flex items-center"
              style={{
                backgroundColor: isThemeDark
                  ? darkVariant2
                  : convertHexToRGBA(colorPrimary, 0.22),
                borderTopLeftRadius: radius,
                borderBottomLeftRadius: radius,
                color: isThemeDark
                  ? white
                  : colorTextButton === 'white'
                  ? colorPrimary
                  : colorTextLightMode,
              }}
            >
              <div className="text-center w-full">{`ÉTAPE ${item.step_number}`}</div>
            </div>
            <div
              className="p-[.9rem] dark:text-white text-dark-1 flex w-10/12 justify-between items-center"
              style={{
                backgroundColor: isThemeDark
                  ? dark1
                  : convertHexToRGBA(colorPrimary, 0.06),
                borderTopRightRadius: radius,
                borderBottomRightRadius: radius,
              }}
            >
              <div className="font-[500] w-7/12 flex items-center">
                {stepName}
              </div>
              {!stepName.includes('Contrôle contact') && (
                <>
                  <div className="opacity-70 w-2/12 flex justify-end items-center whitespace-nowrap">
                    {item.amount !== 0
                      ? `${numFormatSpace(item.amount)} €`
                      : '-'}
                  </div>

                  <div className="font-[500] w-2/12 flex justify-end items-center whitespace-nowrap">
                    {`${numFormatSpace(item.nb_folders)} opération${
                      item.nb_folders > 1 ? 's' : ''
                    }`}
                  </div>

                  <div className="w-1/12 flex justify-end items-center">
                    {item.nb_folders !== 0 && (
                      <ChevronIcon
                        fill={isThemeDark ? 'white' : 'black'}
                        width="1rem"
                        height="1rem"
                      />
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default StepsList;
