import { InstallerContext } from '@auth/utils/authContext';
import { useContext } from 'react';

function LoaderSimulator(): JSX.Element {
  const { radiusPx } = useContext(InstallerContext);

  const array = [1, 2];
  return (
    <div
      style={{ borderRadius: radiusPx }}
      className="animate__flash animate__animated animate__slower p-[.625rem] w-full dark:bg-dark-4 bg-lightMode-dark-4 mt-[1.5rem] flex flex-col space-y-[1.5rem] items-start"
    >
      <div className="w-full dark:bg-dark-4 bg-lightMode-dark-4 flex flex-col space-y-[.625rem] items-start">
        <div className="bg-dark-light-1 h-[.75rem] opacity-80 w-full rounded-[6px] animate__flash animate__animated animate__slower" />
        <div className="bg-dark-light-1 h-[.75rem] opacity-80 w-full rounded-[6px] animate__flash animate__animated animate__slower" />
        <div className="bg-dark-light-1 h-[.75rem] opacity-80 w-full rounded-[6px] animate__flash animate__animated animate__slower" />
        <div className="bg-dark-light-1 h-[.75rem] opacity-80 w-full rounded-[6px] animate__flash animate__animated animate__slower" />
        <div className="bg-dark-light-1 h-[.75rem] opacity-80 w-full rounded-[6px] animate__flash animate__animated animate__slower" />
        <div className="bg-dark-light-1 h-[.75rem] opacity-80 w-full rounded-[6px] animate__flash animate__animated animate__slower" />
        <div className="bg-dark-light-1 h-[.75rem] opacity-80 w-6/12 rounded-[6px] animate__flash animate__animated animate__slower" />
      </div>
      {array.map((arr) => {
        return (
          <div
            style={{ borderRadius: radiusPx }}
            className="w-full h-[5rem] bg-dark-light-1 animate__flash animate__animated animate__slower"
            key={arr}
          />
        );
      })}
    </div>
  );
}

export default LoaderSimulator;
