import { InstallerContext } from '@auth/utils/authContext';
import React, {
  Dispatch,
  useContext,
  SetStateAction,
  useState,
  useEffect,
} from 'react';
import { v4 } from 'uuid';
import { IChoices, IConditions } from '@dashboard/utils/dashboardTypes';
import { arrayChoices } from '@dashboard/utils/functions';
import { ChevronIcon } from '@assets/svgComponents/svgComponents';
import { DashboardContext } from '../../../utils/context/dashboardContext';

interface IProps {
  valueValue: string | number;
  setValueValue: Dispatch<SetStateAction<string | number>>;
  datas: IConditions;
}

function InputSingleSelect({
  valueValue,
  setValueValue,
  datas,
}: IProps): JSX.Element {
  const { schemaFoldersCustomView } = useContext(DashboardContext);

  const [isSelectValueSingleSelect, setIsSelectValueSingleSelect] =
    useState(false);
  const [valueVisible, setValueVisible] = useState(
    arrayChoices(datas, schemaFoldersCustomView).filter((el: any) => {
      return el.provider_id === valueValue;
    })[0]?.provider_name
  );
  const { radiusPx, isThemeDark } = useContext(InstallerContext);

  const radius = radiusPx === '0px' ? '0px' : '8px';

  useEffect(() => {
    if (valueVisible === undefined) {
      setValueValue('');
    }
  }, [valueVisible]);

  return (
    <div className="relative text-[.75rem] font-medium">
      <button
        style={{ borderRadius: radius }}
        className="text-[.75rem] font-medium border dark:border-white dark:border-opacity-[15%] flex items-center justify-between py-[.375rem] px-[.625rem]  w-[30rem]"
        type="button"
        onClick={() => {
          setIsSelectValueSingleSelect(!isSelectValueSingleSelect);
        }}
      >
        {valueVisible || 'Choisir une valeur'}
        <ChevronIcon
          width="1.5rem"
          height="1.5rem"
          fill={isThemeDark ? 'white' : '#191919'}
          transform={isSelectValueSingleSelect ? 'rotate(-90)' : 'rotate(90)'}
          className="transition-transform duration-300"
        />
      </button>
      {isSelectValueSingleSelect && (
        <div
          style={{
            zIndex: 9999999999,
            borderBottomLeftRadius: radius,
            borderBottomRightRadius: radius,
          }}
          className="text-[.75rem] font-medium absolute w-[30rem] mt-[-.375rem] p-[.625rem] border dark:border-white dark:border-opacity-[15%] dark:border-t-transparent dark:bg-[#1C1E1F] bg-white "
        >
          {arrayChoices(datas, schemaFoldersCustomView)?.map((el: IChoices) => {
            return (
              <div key={v4()} className="flex flex-col s">
                <button
                  className="text-[.75rem] font-medium text-left hover:opacity-90 hover:scale-105 hover:ml-[.375rem]"
                  type="button"
                  onClick={() => {
                    setValueValue(el.provider_id);
                    setValueVisible(el.provider_name);
                    setIsSelectValueSingleSelect(false);
                  }}
                >
                  {el.provider_name}
                </button>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}

export default InputSingleSelect;
