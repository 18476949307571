import { useContext, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';

import {
  Button,
  Input,
  WarningNotification,
} from 'storybook-design-system-drapo';
import Loader from '@components/loaders/Loader';
import {
  borderLightMode2,
  colorTextLightMode,
  purple1,
  white,
} from '@assets/colors';
import { ChevronIcon } from '@assets/svgComponents/svgComponents';
import { getContrastYIQ } from '@utils/utils';
import { InstallerContext } from '@auth/utils/authContext';
import { sendEmailPasswordForgotten } from '../models/admin/apiRequests/AdminRequests';

function PasswordForgotten(): JSX.Element {
  const [email, setEmail] = useState<string>('');
  const [errMessage, setErrMessage] = useState<string>('');

  const [loading, setLoading] = useState<boolean>(false);
  const [isSend, setIsSend] = useState<boolean>(false);

  const { colorPrimary, radiusPx } = useContext(InstallerContext);

  const location = useLocation();
  const isWhiteLabel = location.pathname.includes('wl');
  const whiteLabel: string = useParams().marque || '';

  const success =
    'Votre demande a bien été enregistrée, vous allez bientôt recevoir un email avec les instructions pour le modifier.';

  const handleSubmit = () => {
    setLoading(true);
    sendEmailPasswordForgotten(email, setIsSend, setLoading, setErrMessage);
  };

  const handleKeyPress = (e: React.KeyboardEvent<EventTarget>) => {
    if (e.key === 'Enter' && !loading) {
      handleSubmit();
    }
  };

  return (
    <div>
      <div
        style={{
          borderRadius: radiusPx,
          transform: 'translate(-50%, -50%)',
          left: '50%',
          top: '50%',
          color: isWhiteLabel ? colorTextLightMode : white,
        }}
        className="flex dark:bg-dark-2 bg-white  absolute lg:w-8/12 w-11/12 lg:pt-0 pt-[1.25rem]"
      >
        <div className="flex w-full flex-col self-center justify-center p-[.5rem] lg:p-[3rem] xl:w-3/6">
          <h2 className="text-[2.25rem] lg:text-left text-center mb-[3.5rem] 3xl:text-center">
            Mot de passe oublié
          </h2>
          {!isSend ? (
            <>
              <Input
                borderRadius={radiusPx}
                addClassInput={
                  isWhiteLabel ? 'light-mode-input-white' : 'dark-mode-input'
                }
                backgroundColor={isWhiteLabel ? '#FFFFFF' : '#191919'}
                color={isWhiteLabel ? colorTextLightMode : white}
                border={
                  isWhiteLabel ? `1px solid ${borderLightMode2}` : '#191919'
                }
                inputType="email"
                label="Email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                addClass="mb-[1rem]"
                dataTestId="email"
                onKeyPress={(e) =>
                  handleKeyPress(e as React.KeyboardEvent<HTMLInputElement>)
                }
              />
              <div className="my-[1rem] w-full mb-[1rem] 3xl:w-3/5">
                <Button
                  borderRadius={radiusPx}
                  disabled={!!loading}
                  label="Réactualiser mot de passe"
                  color={getContrastYIQ(colorPrimary.replace('#', ''))}
                  backgroundColor={colorPrimary}
                  arrow
                  addClass="btn-login lg:mr-[.75rem]"
                  onClick={() => handleSubmit()}
                  dataTestId="send"
                />
              </div>
              {loading && <Loader />}
            </>
          ) : (
            <>
              {errMessage !== '' ? (
                <WarningNotification
                  warningText={errMessage}
                  backgroundColor="#991B2C"
                  color="#ffffff"
                />
              ) : (
                <WarningNotification
                  warningText={success}
                  backgroundColor={purple1}
                  color="#ffffff"
                />
              )}
            </>
          )}
          <Link
            to={isWhiteLabel ? `/wl/${whiteLabel}/connexion` : '/connexion'}
          >
            <div className="flex items-center space-x-[.625rem] mt-[1rem] opacity-80 hover:opacity-100">
              <ChevronIcon
                width="1.5rem"
                height="1.5rem"
                fill={isWhiteLabel ? colorTextLightMode : white}
                transform="rotate(180)"
              />
              <div>Retour à la page de connexion</div>
            </div>
          </Link>
        </div>
        <div className="hidden xl:block xl:w-3/6">
          <div
            style={{
              borderTopRightRadius: radiusPx,
              borderBottomRightRadius: radiusPx,
            }}
            className="h-full w-full bg-login-img bg-center bg-cover"
          />
        </div>
      </div>
    </div>
  );
}

export default PasswordForgotten;
