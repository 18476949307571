/* eslint-disable jsx-a11y/label-has-associated-control */
import { useContext } from 'react';
import { v4 } from 'uuid';
import { InstallerContext } from '@auth/utils/authContext';
import {
  DarkModeIcon,
  LightModeIcon,
} from '@assets/svgComponents/svgComponents';
import { SimulatorContext } from '@simulator/utils/simulatorContext';

function ThemeCheckbox(): JSX.Element {
  const { isThemeDark, updateIsThemeDark, updateThemeChangedManually } =
    useContext(InstallerContext);
  const { stepName } = useContext(SimulatorContext);

  return (
    <>
      <label
        key={v4()}
        className={`${
          stepName === 'Résultat' ? 'ml-[2rem] ' : ''
        }check-box dark:text-white text-colorTextLightMode flex text-[.875rem] leading-[1.5rem] gap-x-[.5rem] items-center`}
      >
        <LightModeIcon
          fill={isThemeDark ? 'white' : 'black'}
          width="1.5rem"
          height="1.5rem"
        />
        <input
          checked={isThemeDark}
          onChange={() => {
            updateIsThemeDark(!isThemeDark);
            updateThemeChangedManually(true);
          }}
          type="checkbox"
          className="check-box__switcher sb-text-rem-body2 appearance-none h-[1rem] cursor-pointer w-[2.25rem] rounded-[16px]"
        />
        <DarkModeIcon
          fill={isThemeDark ? 'white' : 'black'}
          width="1.5rem"
          height="1.5rem"
        />
      </label>
    </>
  );
}

export { ThemeCheckbox };
