import { IMyProfilDatas } from '@dashboard/utils/dashboardTypes';
import { Dispatch, SetStateAction, useContext } from 'react';
import { InstallerContext } from '@auth/utils/authContext';
import LeftPartDocuments from './leftPart/LeftPartDocuments';
import RightPartDocuments from './rightPart/RightPartDocuments';

interface IProps {
  datas: IMyProfilDatas;
  setDatasMyProfile: Dispatch<SetStateAction<IMyProfilDatas>>;
}
function DocumentsContainer({ datas, setDatasMyProfile }: IProps): JSX.Element {
  const { isThemeDark } = useContext(InstallerContext);

  const scrollBarColor = isThemeDark ? '' : 'scroll_light_mode';

  return (
    <div
      className={`${scrollBarColor} w-full pl-[1.875rem] pr-[1.5rem] xl:flex justify-between xl:overflow-y-hidden lg:overflow-y-scroll lg:max-h-[78vh]`}
    >
      <LeftPartDocuments setDatasMyProfile={setDatasMyProfile} datas={datas} />
      <RightPartDocuments />
    </div>
  );
}

export default DocumentsContainer;
