import { purple1 } from '@assets/colors';
import { add, add_black } from '@assets/images/images';
import { InstallerContext } from '@auth/utils/authContext';
import { updateOneCustomViewById } from '@dashboard/apiRequests/dashboardRequests';
import { DashboardContext } from '@dashboard/utils/context/dashboardContext';
import { v4 } from 'uuid';
import React, { useContext, useEffect, useState } from 'react';
import Draggable from 'react-draggable';
import { ChevronIcon, CloseIcon } from '@assets/svgComponents/svgComponents';
import OneCondition from './OneCondition';

function ModalFilters(): JSX.Element {
  const [isSelectOrOrAnd, setIsSelectOrOrAnd] = useState(false);

  const { installer, radiusPx, isThemeDark, colorTextButton } =
    useContext(InstallerContext);
  const { newConditions, updateNewConditions, updateOrOrAndName, orOrAndName } =
    useContext(DashboardContext);

  const {
    updateFiltersOrPersonalizationModalCustomView,
    currentSchemaFolderCustomView,
    nameCurrentListCustomView,
    idCurrentListCustomView,
    updateIsLoadingCustomView,
    updateCurrentListCustomView,
    updateNumberPageList,
    updateCountsCustomListView,
    updateRowsSelectedCustomView,
    isDisabledFiltersCustomView,
  } = useContext(DashboardContext);
  const radius = radiusPx === '0px' ? '0px' : '8px';

  useEffect(() => {
    if (
      currentSchemaFolderCustomView.schema.whereClauses.conditions.length !== 0
    ) {
      updateNewConditions(
        currentSchemaFolderCustomView.schema.whereClauses.conditions
      );
    }
  }, []);

  const updateConditions = () => {
    if (newConditions.length > 0) {
      updateNumberPageList(1);
      updateOneCustomViewById(
        nameCurrentListCustomView,
        idCurrentListCustomView,
        currentSchemaFolderCustomView.schema.fields,
        updateIsLoadingCustomView,
        updateCurrentListCustomView,
        updateFiltersOrPersonalizationModalCustomView,
        currentSchemaFolderCustomView,
        1,
        updateCountsCustomListView,
        updateRowsSelectedCustomView,
        orOrAndName ===
          'Afficher les dossiers qui contiennent au moins une  des conditions ci-dessous'
          ? 'OR'
          : 'AND',
        newConditions
      );
    } else {
      updateOneCustomViewById(
        nameCurrentListCustomView,
        idCurrentListCustomView,
        currentSchemaFolderCustomView.schema.fields,
        updateIsLoadingCustomView,
        updateCurrentListCustomView,
        updateFiltersOrPersonalizationModalCustomView,
        currentSchemaFolderCustomView,
        1,
        updateCountsCustomListView,
        updateRowsSelectedCustomView,
        orOrAndName ===
          'Afficher les dossiers qui contiennent au moins une  des conditions ci-dessous'
          ? 'OR'
          : 'AND',
        []
      );
    }
  };

  const themeDark = isThemeDark;
  return (
    <Draggable handle=".modal" bounds="main">
      <div
        style={{ zIndex: 99999999, borderRadius: radius }}
        className="absolute text-colorTextLightMode dark:text-white min-w-[40.625rem] border border-borderLightMode dark:border-[#2D2D2D] bg-white dark:bg-[#1F1F1F] shadow-[0px 4px 12px rgba(0, 0, 0, 0.1)]"
      >
        <div
          style={{
            borderTopLeftRadius: radius,
            borderTopRightRadius: radius,
            backgroundColor:
              installer.nom_partenaire === 'DRAPO' ||
              installer.nom_partenaire === ''
                ? purple1
                : installer.custom.couleur_boutons,
          }}
          className="w-full text-[.75rem] flex justify-between items-center  py-[.625rem]  modal px-[1.5rem] modal"
        >
          <p style={{ color: colorTextButton }} className="font-medium">
            {' '}
            Filtrer
          </p>
          <button
            type="button"
            onClick={() => updateFiltersOrPersonalizationModalCustomView('')}
          >
            <CloseIcon fill={colorTextButton} width="1rem" height="1rem" />
          </button>
        </div>
        <div
          style={{
            borderBottomLeftRadius: radius,
            borderBottomRightRadius: radius,
          }}
          className="dark:bg-[#1C1E1F] bg-white px-[1.5rem] py-[.625rem] pb-[1.5rem] "
        >
          {newConditions.length > 0 && (
            <button
              style={{ borderRadius: radius }}
              className="text-[.75rem] font-medium border dark:border-white dark:border-opacity-[15%] flex items-center justify-between py-[.375rem] px-[.625rem] w-[34.5rem] mb-[1.25rem]"
              type="button"
              onClick={() => setIsSelectOrOrAnd(!isSelectOrOrAnd)}
            >
              {orOrAndName}
              <ChevronIcon
                width="1.5rem"
                height="1.5rem"
                fill={isThemeDark ? 'white' : '#191919'}
                transform={isSelectOrOrAnd ? 'rotate(-90)' : 'rotate(90)'}
                className="transition-transform duration-300"
              />
            </button>
          )}
          {newConditions.length > 0 && isSelectOrOrAnd && (
            <div
              style={{
                zIndex: 9999999999,
                borderBottomLeftRadius: radius,
                borderBottomRightRadius: radius,
              }}
              className="text-[.75rem] font-medium absolute w-[34.5rem] mt-[-1.5rem]  p-[.625rem] border dark:border-white dark:border-opacity-[15%] dark:border-t-transparent dark:bg-[#1C1E1F] bg-white"
            >
              <div>
                <div key={v4()} className="flex flex-col s">
                  <button
                    className="text-[.75rem] font-medium text-left hover:opacity-90 hover:scale-105 hover:ml-[.625rem]"
                    type="button"
                    onClick={() => {
                      updateOrOrAndName(
                        orOrAndName ===
                          'Afficher les dossiers qui contiennent au moins une  des conditions ci-dessous'
                          ? 'Afficher les dossiers qui contiennent toutes les conditions ci-dessous'
                          : 'Afficher les dossiers qui contiennent au moins une  des conditions ci-dessous'
                      );
                      setIsSelectOrOrAnd(false);
                    }}
                  >
                    {orOrAndName ===
                    'Afficher les dossiers qui contiennent au moins une  des conditions ci-dessous'
                      ? 'Afficher les dossiers qui contiennent toutes les conditions ci-dessous'
                      : 'Afficher les dossiers qui contiennent au moins une  des conditions ci-dessous'}
                  </button>
                </div>
              </div>
            </div>
          )}

          {newConditions.map((el, index) => {
            return (
              <div key={v4()}>
                <OneCondition index={index} datas={el} />
              </div>
            );
          })}
          <button
            type="button"
            onClick={() => {
              updateNewConditions([
                ...newConditions,
                {
                  condition: '',
                  value: '',
                  provider_name: '',
                  provider_id: '',
                },
              ]);
            }}
            className="flex items-center"
          >
            <img
              className="w-[1rem] mr-[.625rem]"
              src={themeDark ? add : add_black}
              alt="ajouter une condition"
            />
            <p className="text-[.75rem] font-medium">Ajouter une condition</p>
          </button>
          <div className="flex space-x-[1.5rem] justify-end	items-center  dark:bg-[#1C1E1F] bg-white w-full ">
            <button
              className="text-[.75rem] font-medium"
              type="button"
              onClick={() => {
                updateNewConditions([]);
              }}
            >
              Réinitialiser
            </button>
            <button
              disabled={newConditions.length > 0 && isDisabledFiltersCustomView}
              style={{
                color: colorTextButton,
                opacity:
                  newConditions.length > 0 && isDisabledFiltersCustomView
                    ? 0.5
                    : 1,
                borderRadius: radius,
                backgroundColor:
                  installer.nom_partenaire === 'DRAPO' ||
                  installer.nom_partenaire === ''
                    ? purple1
                    : installer.custom.couleur_boutons,
              }}
              className="px-[1.5rem] py-[.375rem] text-[.75rem] font-medium "
              type="button"
              onClick={() => updateConditions()}
            >
              Valider
            </button>
          </div>
        </div>
      </div>
    </Draggable>
  );
}

export default ModalFilters;
