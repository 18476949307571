import { IListFolders } from '@dashboard/utils/dashboardTypes';
import React, { Dispatch, SetStateAction } from 'react';
import {
  IAllDocumentType,
  IFolderMonitoringDatas,
  IOperationsMonitoringDatas,
} from '../folderMonitoringTypes';

interface IProps {
  tab: string;
  updateTab: Dispatch<SetStateAction<string>>;
  folderDatas: IFolderMonitoringDatas;
  updateFolderDatas: Dispatch<SetStateAction<IFolderMonitoringDatas>>;
  detailsActive: boolean;
  updateDetailsActive: Dispatch<SetStateAction<boolean>>;
  isLoading: boolean;
  updateIsLoading: Dispatch<SetStateAction<boolean>>;
  isModalDeleteFolder: boolean;
  updateIsModalDeleteFolder: Dispatch<SetStateAction<boolean>>;
  stepIsUpdate: boolean;
  updateStepIsUpdate: Dispatch<SetStateAction<boolean>>;
  allDocuments: IAllDocumentType;
  updateAllDocuments: Dispatch<SetStateAction<IAllDocumentType>>;
  folderActif: IListFolders[];
  updateFolderActif: Dispatch<SetStateAction<IListFolders[]>>;
  operationActive: IOperationsMonitoringDatas;
  updateOperationActive: Dispatch<SetStateAction<IOperationsMonitoringDatas>>;
  deleteOperationOrFolder: string;
  updateDeleteOperationOrFolder: Dispatch<SetStateAction<string>>;
  statutBackgroundColor: { [key: string]: string };
  hasAttestation: boolean;
  updateHasAttestation: Dispatch<SetStateAction<boolean>>;
}

export const FolderMonitoringContext = React.createContext({} as IProps);
