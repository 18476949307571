import HomeLeftMenu from '@dashboard/components/homeLeftMenu/HomeLeftMenu';
import { LayoutDashboardContext } from '@dashboard/utils/context/LayoutDashboardContext';
import React, { useState } from 'react';
import { IListFolders } from '@dashboard/utils/dashboardTypes';
import { initialListFolders } from '@dashboard/utils/initialState';
import RequestForSupplements from '../models/dashboard/components/summary/requestForSupplements/RequestForSupplements';

interface IProps {
  children: JSX.Element;
  withoutRequestForSupplements?: boolean;
}

function LayoutDashboard({
  children,
  withoutRequestForSupplements,
}: IProps): JSX.Element {
  const [isRetractableDDC, setIsRetractableDDC] = useState(false);
  const [elementActiveDDC, setElementActiveDDC] = useState<string>('');
  const [folderActif, setFolderActif] =
    useState<IListFolders>(initialListFolders);
  const [isLookingFor, setIsLookingFor] = useState<boolean>(false);

  const layoutDashboardContextValue = {
    isRetractableDDC,
    updateIsRetractableDDC: setIsRetractableDDC,
    elementActiveDDC,
    updateElementActiveDDC: setElementActiveDDC,
    folderActif,
    updateFolderActif: setFolderActif,
    isLookingFor,
    updateIsLookingFor: setIsLookingFor,
  };

  return (
    <LayoutDashboardContext.Provider value={layoutDashboardContextValue}>
      {isLookingFor && (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
        <div
          className="absolute left-0 top-0 w-full h-full z-[9999] bg-black/50"
          onClick={() => setIsLookingFor(false)}
        />
      )}
      <div
        className={`lg:flex w-full justify-center mt-[2rem] ${
          window.location.href.includes('.staging')
            ? 'lg:mt-[2.5vh]'
            : 'lg:mt-0'
        } `}
      >
        <div className="w-[18%] lg:block hidden lg:pr-[1.5rem]">
          <HomeLeftMenu />
        </div>
        <div className="lg:flex w-[82%] lg:mt-0 mt-[2rem] ">
          <>
            {!withoutRequestForSupplements ? (
              <div
                style={{
                  transition: !isRetractableDDC ? 'width 1s' : '',
                }}
                className={`${
                  isRetractableDDC ? 'lg:w-11/12' : 'lg:w-9/12'
                } w-full lg:border-r dark:border-r-[#3C3B3B]`}
              >
                {children}
              </div>
            ) : (
              <>{children}</>
            )}
            {!withoutRequestForSupplements && <RequestForSupplements />}
          </>
        </div>
      </div>
    </LayoutDashboardContext.Provider>
  );
}

export default LayoutDashboard;

LayoutDashboard.defaultProps = {
  withoutRequestForSupplements: false,
};
