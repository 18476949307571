import { Dispatch, SetStateAction, useContext } from 'react';
import { NavButton } from '@components/NavButton';
import { v4 } from 'uuid';
import { InstallerContext } from '../../../auth/utils/authContext';

interface IProps {
  elementNavActive: string;
  setElementNavActive: Dispatch<SetStateAction<string>>;
}
function HeaderMyProfile({
  elementNavActive,
  setElementNavActive,
}: IProps): JSX.Element {
  const { radiusPx } = useContext(InstallerContext);
  const navElements = [
    {
      title: 'Général',
    },
    {
      title: 'Documents',
    },
  ];

  return (
    <div
      style={{
        borderTopLeftRadius: radiusPx,
      }}
      className={`rounded-tl-default pt-[2.25rem] w-full dark:bg-dark-5 bg-white px-[2rem] w-full h-home-header relative lg:rounded-tr-[0px] dark:border-none border-b border-b-borderLightMode border-b-[1px] `}
    >
      <div className="flex items-center justify-between w-full mb-[.875rem]">
        <div className="xl:text-[1.875rem] text-[1.5rem] pb-[.625rem] dark:text-white text-dark-2 ">
          Mon profil
        </div>
        {/* <div className="pr-[2rem]">
          <InputSearch
            onSearch={handleSearch}
            placeholder="Rechercher quelque chose"
            addClass="!h-[3.75rem] inputSearch"
          />
        </div> */}
      </div>
      <div className="flex space-x-[1.5rem] text-[.75rem] absolute bottom-0">
        {navElements.map((el) => (
          <NavButton
            key={v4()}
            title={el.title}
            elementActive={elementNavActive}
            updateElementActive={setElementNavActive}
          />
        ))}
      </div>
    </div>
  );
}

export default HeaderMyProfile;
