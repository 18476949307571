import { useContext, useEffect, useState } from 'react';
import { SimulatorContext } from '@simulator/utils/simulatorContext';
import { IOperationDatasType } from '@simulator/utils/simulatorTypes';
import { InstallerContext } from '@auth/utils/authContext';

function ListOperationsSimu(): JSX.Element {
  const { colorPrimary } = useContext(InstallerContext);
  const { currentOperation, multipleOperations, stepNumber } =
    useContext(SimulatorContext);

  const CSS = `div::before {border-color: ${colorPrimary}}`;

  const [listOperations, setListOperations] =
    useState<IOperationDatasType[]>(multipleOperations);
  const currentIndex: number =
    listOperations.length > 1
      ? listOperations.findIndex((el) => el.name === currentOperation.name)
      : 0;

  const isValidate = (operation: IOperationDatasType) => {
    return (
      operation.totalCost !== '' &&
      currentOperation.id !== operation.id &&
      stepNumber > 2
    );
  };

  useEffect(() => {
    setListOperations(multipleOperations);
  }, [multipleOperations]);

  useEffect(() => {
    const tab = listOperations;
    for (let i = 0; i < tab.length; i += 1) {
      if (tab[i].totalCost !== '') {
        if (i > currentIndex) {
          tab[i].totalCost = '';
        }
      }
    }

    setListOperations(tab);
  }, [listOperations]);

  return (
    <div className="mt-[1.375rem] mb-[.5rem] animate__animated animate__fadeIn">
      {listOperations.length > 0 &&
        currentOperation &&
        listOperations.map((operation: IOperationDatasType, index: number) => (
          <div
            key={operation.name}
            className={`flex items-center sb-text-rem-body2 animate__animated animate__fadeInDown ${
              index !== listOperations.length - 1 && 'mb-[1.5rem]'
            } `}
          >
            <div
              className={`relative m-0 ${
                !isValidate(operation) && 'before:border-[#3F3F3F]'
              } ${
                index === multipleOperations.length - 1 &&
                'before:h-[0rem] before:w-[0rem]'
              } before:transition-all before:duration-[1s] before:content-[''] before:absolute before:left-[.938rem] before:border-l-[.6px] before:border-solid  before:h-[2.5rem] before:w-[1px] before:top-[.75rem] before:left-[.625rem]`}
              style={{
                zIndex: 99999999999,
              }}
            >
              <style>{CSS}</style>
              <svg
                width={`${isValidate(operation) ? '1.125rem' : '0.75rem'}`}
                height={`${isValidate(operation) ? '1.125rem' : '0.75rem'}`}
                viewBox="0 0 90 90"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={`${
                  operation.totalCost !== '' &&
                  currentOperation.id !== operation.id &&
                  stepNumber > 2
                    ? 'ml-[.125rem]'
                    : 'ml-[.25rem]'
                }`}
              >
                <circle
                  cx="45"
                  cy="45"
                  r="45"
                  fill={`${
                    (operation.totalCost !== '' &&
                      currentOperation.id !== operation.id) ||
                    (operation.name === currentOperation.name && stepNumber > 2)
                      ? colorPrimary
                      : '#3F3F3F'
                  }`}
                />
              </svg>
              {isValidate(operation) && (
                <svg
                  width="1.5rem"
                  height="1.5rem"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="absolute top-[50%] left-[50%] transform -translate-x-[18%] -translate-y-[18%]"
                >
                  <path
                    d="M3.49999 6.08517L1.41499 4.00017L0.704987 4.70517L3.49999 7.50017L9.49999 1.50017L8.79499 0.795166L3.49999 6.08517Z"
                    fill="white"
                  />
                </svg>
              )}
            </div>
            <div
              className={`${
                isValidate(operation) ? 'ml-[1.25rem]' : 'ml-[1.5rem]'
              } dark:text-white text-dark-1 truncate`}
            >
              {operation.name}
            </div>
          </div>
        ))}
    </div>
  );
}

export default ListOperationsSimu;
