/* eslint-disable jsx-a11y/no-autofocus */
import InputSingleSelect from '@dashboard/components/customView/modalFilters/InputSingleSelect';
import InputMultipleSelect from '@dashboard/components/customView/modalFilters/InputMultipleSelect';
import React from 'react';
import {
  IConditions,
  IFilteredMaterialToolbox,
  IinitialOperator,
  IMaterialToolbox,
  ISchemaFolder,
} from './dashboardTypes';

export const initFilteredDatasToolBox = (datas: IMaterialToolbox[]) => {
  const filteredList: IFilteredMaterialToolbox[] = [];
  for (let i = 0; i < datas.length; i += 1) {
    filteredList.push({
      value: datas[i].id,
      label: datas[i].title,
      marque: datas[i].marque,
      référence: datas[i].référence,
      links: datas[i].fiche_technique,
    });
  }
  return filteredList;
};

export const arrayChoices = (
  datas: IConditions,
  schemaFoldersCustomView: ISchemaFolder
) => {
  switch (datas.provider_name) {
    case 'Statut MPR':
      return schemaFoldersCustomView?.fields?.filter((el) => {
        return el.provider_name === 'Statut MPR';
      })[0]?.provider_choices;
    case 'Statut CEE':
      return schemaFoldersCustomView?.fields?.filter((el) => {
        return el.provider_name === 'Statut CEE';
      })[0]?.provider_choices;
    case 'Sous-opération':
      return schemaFoldersCustomView?.fields?.filter((el) => {
        return el.provider_name === 'Sous-opération';
      })[0]?.provider_choices;
    case 'Statut paiement CEE':
      return schemaFoldersCustomView?.fields
        ?.filter((el) => {
          return el.provider_name === 'Statut paiement CEE';
        })[0]
        ?.provider_choices.filter(
          (el: any) =>
            el.provider_name !== 'En attente du remboursement' &&
            el.provider_name !== 'Remboursé'
        );
    case 'Statut Risque MPR':
      return schemaFoldersCustomView?.fields?.filter((el) => {
        return el.provider_name === 'Statut Risque MPR';
      })[0]?.provider_choices;
    case 'Statut Retour instructeur MPR':
      return schemaFoldersCustomView?.fields?.filter((el) => {
        return el.provider_name === 'Statut Retour instructeur MPR';
      })[0]?.provider_choices;
    case 'Statut paiement MPR':
      return schemaFoldersCustomView?.fields?.filter((el) => {
        return el.provider_name === 'Statut paiement MPR';
      })[0]?.provider_choices;
    default:
      return [];
  }
};

export const arrayChoicesMultiple = (
  datas: IConditions,
  schemaFoldersCustomView: ISchemaFolder
) => {
  switch (datas.provider_name) {
    case 'Type information préalable/retrait':
      return schemaFoldersCustomView?.fields?.filter((el) => {
        return el.provider_name === 'Type information préalable/retrait';
      })[0]?.provider_choices;
    case 'Type retour instructeur MPR':
      return schemaFoldersCustomView?.fields?.filter((el) => {
        return el.provider_name === 'Statut Retour instructeur MPR';
      })[0]?.provider_choices;
    case 'Libellé opération':
      return schemaFoldersCustomView?.fields?.filter((el) => {
        return el.provider_name === 'Libellé opération';
      })[0]?.provider_choices;
    default:
      return [];
  }
};

export const changeConditionsCustomViewFilters = (
  valueName: string,
  nameCondition: string,
  setNameCondition: React.Dispatch<React.SetStateAction<string>>,
  setIntitialOperators: React.Dispatch<
    React.SetStateAction<IinitialOperator[]>
  >,
  setRenderInput: React.Dispatch<React.SetStateAction<JSX.Element>>,
  radius: string,
  valueValue: string | number,
  setValueValue: React.Dispatch<React.SetStateAction<string | number>>,
  valueCondition: string,
  valueDateBetweenValue: string,
  setValueDateBetweenValue: React.Dispatch<React.SetStateAction<string>>,
  valueDateBetweenValueEnd: string | number,
  setValueDateBetweenValueEnd: React.Dispatch<
    React.SetStateAction<string | number>
  >,
  setValueCondition: React.Dispatch<React.SetStateAction<string>>,
  datas: IConditions,
  arrayChoicesMultiples: any,
  arrayChoicesSingle: any
) => {
  // *** TYPE SINGLE TEXT ***
  if (
    valueName === 'Référence dossier' ||
    valueName === 'Prénom client' ||
    valueName === 'Nom client' ||
    valueName === 'Demandeur CEE' ||
    valueName === 'Adresse travaux'
  ) {
    if (
      nameCondition === '' ||
      nameCondition === 'est supérieure ou égale à' ||
      nameCondition === '>=' ||
      nameCondition === '=' ||
      nameCondition === '<=' ||
      nameCondition === '<' ||
      nameCondition === 'est strictement supérieure à' ||
      nameCondition === 'est inférieure ou égale à' ||
      nameCondition === 'est strictement inférieure à' ||
      nameCondition === 'est compris entre' ||
      nameCondition === 'est avant' ||
      nameCondition === 'est après' ||
      nameCondition === 'est égale à'
    ) {
      setNameCondition('contient');
      setValueCondition('ILIKE');
    }
    const val =
      typeof valueValue === 'string' && valueValue !== null
        ? valueValue.split('||')
        : [''];
    const newArrayMultipleChoices = arrayChoicesMultiples.map(
      (el: any) => el.provider_id
    );
    const newArraySingleChoices = arrayChoicesSingle.map(
      (el: any) => el.provider_id
    );
    const matchingMultiple = val.filter((vale) =>
      newArrayMultipleChoices.includes(vale)
    );
    const matchingSingle = val.filter((vale) =>
      newArraySingleChoices.includes(vale)
    );
    const conditionMultiple = matchingMultiple.length === val.length;
    const conditionSingle = matchingSingle.length === val.length;

    if (conditionMultiple || conditionSingle) {
      setValueValue('');
    }
    setIntitialOperators([
      { name: 'contient', value: 'ILIKE' },
      { name: 'ne contient pas', value: 'NOT ILIKE' },
      { name: 'est', value: '=' },
      { name: 'est différent de', value: '<>' },
      { name: 'est vide', value: 'EMPTY' },
      { name: "n'est pas vide", value: 'NOT EMPTY' },
    ]);
    if (valueCondition === 'EMPTY' || valueCondition === 'NOT EMPTY') {
      setRenderInput(<div />);
    } else {
      setRenderInput(
        <input
          autoFocus
          placeholder="Donnée"
          style={{ borderRadius: radius }}
          className="text-[.75rem] bg-transparent font-medium border dark:border-white  dark:border-opacity-[15%] flex items-center justify-between py-[.375rem] px-[.625rem] w-[30rem]"
          type="text"
          value={valueValue}
          onChange={(e) => setValueValue(e.target.value)}
        />
      );
    }
  }

  // *** TYPE CURRENCY ***
  if (
    valueName === 'Montant TTC devis' ||
    valueName === 'Prime CEE bénéficiaire' ||
    valueName === 'Prime CEE installateur' ||
    valueName === 'Montant MPR géré par Drapo' ||
    valueName === 'Virement MPR pro'
  ) {
    if (
      nameCondition === 'contient' ||
      nameCondition === 'est compris entre' ||
      nameCondition === 'est avant' ||
      nameCondition === 'est après' ||
      nameCondition === 'est égale à' ||
      nameCondition === 'est'
    ) {
      setNameCondition('=');
      setValueCondition('=');
    }
    setIntitialOperators([
      { name: '=', value: '=' },
      { name: '>=', value: '>=' },
      { name: '>', value: '>' },
      { name: '<=', value: '<=' },
      { name: '<', value: '<' },
      { name: 'est différent de', value: '<>' },
      { name: 'est vide', value: 'IS NULL' },
      { name: "n'est pas vide", value: 'IS NOT NULL' },
    ]);
    if (valueCondition === 'IS NULL' || valueCondition === 'IS NOT NULL') {
      setRenderInput(<div />);
    } else {
      setRenderInput(
        <input
          autoFocus
          placeholder="Donnée"
          style={{ borderRadius: radius }}
          className="text-[.75rem] bg-transparent font-medium border dark:border-white  dark:border-opacity-[15%] flex items-center justify-between py-[.375rem] px-[.625rem] w-[30rem]"
          type="number"
          value={valueValue}
          onChange={(e) => setValueValue(Number(e.target.value))}
        />
      );
    }
  }

  // *** TYPE DATE ***
  if (
    valueName === 'Date demande subvention' ||
    valueName === 'Date engagement opération' ||
    valueName === 'Date demande solde'
  ) {
    if (
      nameCondition === '>=' ||
      nameCondition === '=' ||
      nameCondition === '<=' ||
      nameCondition === '<' ||
      nameCondition === 'contient' ||
      nameCondition === 'est supérieure ou égal à' ||
      nameCondition === 'est strictement supérieure à' ||
      nameCondition === 'est inférieure ou égale à' ||
      nameCondition === 'est strictement inférieure à' ||
      nameCondition === 'est'
    ) {
      setNameCondition('est égale à');
      setValueCondition('=');
    }
    setIntitialOperators([
      { name: 'est égale à', value: '=' },
      { name: 'est avant', value: '<' },
      { name: 'est après', value: '>' },
      { name: 'est compris entre', value: 'BETWEEN' },
      { name: 'est vide', value: 'IS NULL' },
      { name: "n'est pas vide", value: 'IS NOT NULL' },
    ]);
    if (valueCondition === 'EMPTY' || valueCondition === 'NOT EMPTY') {
      setRenderInput(<div />);
    } else if (valueCondition === 'BETWEEN') {
      setRenderInput(
        <div className="flex items-center">
          <input
            style={{ borderRadius: radius }}
            className="text-[.75rem] bg-transparent font-medium border dark:border-white  dark:border-opacity-[15%] flex items-center justify-between py-[.375rem] px-[.625rem] w-[14rem]"
            type="date"
            value={valueDateBetweenValue}
            onChange={(e) => setValueDateBetweenValue(e.target.value)}
          />
          <p className="text-[.875rem] ml-2 mr-2">et</p>
          <input
            style={{ borderRadius: radius }}
            className="text-[.75rem] bg-transparent font-medium border dark:border-white  dark:border-opacity-[15%] flex items-center justify-between py-[.375rem] px-[.625rem] w-[14rem]"
            type="date"
            value={valueDateBetweenValueEnd}
            onChange={(e) => setValueDateBetweenValueEnd(e.target.value)}
          />
        </div>
      );
    } else {
      setRenderInput(
        <input
          placeholder="Donnée"
          style={{ borderRadius: radius }}
          className="text-[.75rem] bg-transparent font-medium border dark:border-white  dark:border-opacity-[15%] flex items-center justify-between py-[.375rem] px-[.625rem]  w-[30rem]"
          type="date"
          value={valueValue}
          onChange={(e) => setValueValue(e.target.value)}
        />
      );
    }
  }

  // *** TYPE SINGLE SELECT ***
  if (
    valueName === 'Sous-opération' ||
    valueName === 'Statut MPR' ||
    valueName === 'Statut paiement CEE' ||
    valueName === 'Statut Risque MPR' ||
    valueName === 'Statut Retour instructeur MPR' ||
    valueName === 'Statut paiement MPR' ||
    valueName === 'Statut CEE'
  ) {
    if (
      nameCondition === '>=' ||
      nameCondition === '=' ||
      nameCondition === '<=' ||
      nameCondition === '<' ||
      nameCondition === 'contient' ||
      nameCondition === 'est supérieure ou égale à' ||
      nameCondition === 'est strictement supérieure à' ||
      nameCondition === 'est inférieure ou égale à' ||
      nameCondition === 'est strictement inférieure à' ||
      nameCondition === 'est compris entre' ||
      nameCondition === 'est avant' ||
      nameCondition === 'est après' ||
      nameCondition === 'est'
    ) {
      setNameCondition('est égale à');
    }
    setIntitialOperators([
      { name: 'est égale à', value: 'IN' },
      { name: 'est différent de', value: 'NOT IN' },
      { name: 'est vide', value: 'EMPTY' },
      { name: "n'est pas vide", value: 'NOT EMPTY' },
    ]);
    if (valueCondition === 'EMPTY' || valueCondition === 'NOT EMPTY') {
      setRenderInput(<div />);
    } else {
      setRenderInput(
        <InputSingleSelect
          valueValue={valueValue}
          setValueValue={setValueValue}
          datas={datas}
        />
      );
    }
  }

  // *** TYPE MULTIPLE SELECT ***
  if (
    valueName === 'Type information préalable/retrait' ||
    valueName === 'Type retour instructeur MPR' ||
    valueName === 'Libellé opération'
  ) {
    if (
      nameCondition === '>=' ||
      nameCondition === '=' ||
      nameCondition === '<=' ||
      nameCondition === '<' ||
      nameCondition === 'contient' ||
      nameCondition === 'est supérieure ou égale à' ||
      nameCondition === 'est strictement supérieure à' ||
      nameCondition === 'est inférieure ou égale à' ||
      nameCondition === 'est strictement inférieure à' ||
      nameCondition === 'est compris entre' ||
      nameCondition === 'est avant' ||
      nameCondition === 'est après' ||
      nameCondition === 'est'
    ) {
      setNameCondition('est égale à');
    }
    setIntitialOperators([
      { name: 'est égale à', value: 'IN' },
      { name: 'est différent de', value: 'NOT IN' },
      { name: 'est vide', value: 'EMPTY' },
      { name: "n'est pas vide", value: 'NOT EMPTY' },
    ]);
    if (valueCondition === 'EMPTY' || valueCondition === 'NOT EMPTY') {
      setRenderInput(<div />);
    } else {
      setRenderInput(
        <InputMultipleSelect
          valueValue={valueValue}
          setValueValue={setValueValue}
          datas={datas}
        />
      );
    }
  }
};

export const transformDocumentUrl = (url: any) => {
  if (typeof url === 'string') {
    return url.replace('{', '').replace('}', '');
  }
  return url.value.value !== null
    ? url.value.value.replace('{', '').replace('}', '')
    : '';
};

export const numberOfDemands = (tab: any) => {
  let result = 0;
  for (let i = 0; i < tab.length; i += 1) {
    result += tab[i].error;
  }
  return result;
};
