import {
  Input,
  Button,
  WarningNotification,
} from 'storybook-design-system-drapo';
import {
  Dispatch,
  SetStateAction,
  useState,
  useEffect,
  useContext,
} from 'react';
import { pencil } from '@assets/images/images';
import { ISubcontractorDataType } from '@folder/utils/folderTypes';
import { InstallerContext } from '@auth/utils/authContext';
import {
  colorTextLightMode,
  dark1,
  darkVariant1,
  red2,
  white,
} from '@assets/colors';
import { CloseIcon } from '@assets/svgComponents/svgComponents';

interface NewSubcontractorFormProps {
  subcontractorsData: ISubcontractorDataType[];
  setSubcontractorsData: Dispatch<SetStateAction<ISubcontractorDataType[]>>;
  index: number;
  applyNewSubcontractor: (datas: ISubcontractorDataType) => void;
}

function NewSubcontractorForm({
  subcontractorsData,
  setSubcontractorsData,
  index,
  applyNewSubcontractor,
}: NewSubcontractorFormProps): JSX.Element {
  const { colorPrimary, radiusPx, installer, isThemeDark, colorTextButton } =
    useContext(InstallerContext);

  const [edited, setEdited] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [name, setName] = useState<string>(
    subcontractorsData[index].subcontractor?.nom || ''
  );
  const [firstName, setFirstName] = useState<string>(
    subcontractorsData[index].subcontractor?.prenom || ''
  );
  const [companyName, setCompanyName] = useState<string>(
    subcontractorsData[index].subcontractor?.raison_sociale || ''
  );
  const [siret, setSiret] = useState<string>(
    subcontractorsData[index].subcontractor?.siret || ''
  );
  const [email, setEmail] = useState<string>(
    subcontractorsData[index].subcontractor?.email || ''
  );
  const [isValid, setIsValid] = useState(false);
  const [errBorderMail, setErrBorderMail] = useState<string>('');
  const [errSiret, setErrSiret] = useState<string>('');

  useEffect(() => {
    if (edited) {
      setIsValid(false);
    }
  }, [edited]);

  useEffect(() => {
    if (subcontractorsData[index].subcontractor) {
      setIsValid(true);
    }
  }, []);

  const onValid = () => {
    if (edited) {
      setEdited(false);
    }
    if (
      !email.match(/[a-z0-9_\-.]+@[a-z0-9_\-.]+\.[a-z]+/i) ||
      siret.length !== 14
    ) {
      if (!email.match(/[a-z0-9_\-.]+@[a-z0-9_\-.]+\.[a-z]+/i)) {
        setErrBorderMail('border border-red-500');
      }
      if (siret.length !== 14) {
        setErrSiret('border border-red-500');
      }
      setError(true);
    } else {
      const newSubcontractor = subcontractorsData[index];
      newSubcontractor.action = 'subcontractor';
      newSubcontractor.subcontractor = {
        nom: name,
        prenom: firstName,
        raison_sociale: companyName,
        siret,
        email,
      };
      setSubcontractorsData((prevState) => {
        const newSubcontractorsData = [...prevState];
        newSubcontractorsData[index] = newSubcontractor;
        return newSubcontractorsData;
      });
      setIsValid(true);
      setError(false);
      applyNewSubcontractor(newSubcontractor);
    }
  };

  const onCancel = () => {
    const newSubcontractor: ISubcontractorDataType = {
      operation: subcontractorsData[index].operation,
      action: 'subcontractorId',
      label: '',
    };
    setSubcontractorsData((prevState) => {
      const newSubcontractorsData = [...prevState];
      newSubcontractorsData[index] = newSubcontractor;
      return newSubcontractorsData;
    });
    setIsValid(false);
    setError(false);
  };

  const giveUp = () => {
    if (subcontractorsData[index].subcontractor === undefined) {
      onCancel();
    } else {
      setIsValid(true);
      setEdited(false);
    }
  };

  return (
    <>
      {isValid ? (
        <>
          <div className="h-[3.5rem] md:h-[5.125rem] mt-[.875rem] flex items-center rounded-[10px] md:rounded-[20px] md:px-[2.5rem] w-full p-[1rem] dark:bg-dark-1 bg-backgroundQuaternaryLightMode sb-text-rem-caption1 justify-between">
            <div className="sb-caption1 sb-text-white">{companyName}</div>
            <div>
              <button type="button" onClick={() => setEdited(true)}>
                <img
                  className="cursor-pointer w-[1.25rem] mr-[1rem] hover:opacity-100 opacity-80"
                  src={pencil}
                  alt="modifier"
                />
              </button>
              <button
                type="button"
                className="opacity-50 hover:opacity-70"
                onClick={onCancel}
              >
                <CloseIcon
                  fill={isThemeDark ? white : colorTextLightMode}
                  width="1rem"
                  height="1rem"
                />
              </button>
            </div>
          </div>
        </>
      ) : (
        <div
          style={{ borderRadius: radiusPx }}
          className={`mt-[.875rem] dark:bg-dark-1 bg-backgroundQuaternaryLightMode p-[.75rem] `}
        >
          <div className="flex justify-between px-[.5rem] mb-[.5rem]">
            <p className="sb-text-rem-body2 mb-[.75rem] dark:text-white text-colorTextLightMode">
              Nouveau sous-traitant
            </p>
            <button
              type="button"
              className="opacity-50 hover:opacity-70"
              onClick={() => {
                giveUp();
              }}
            >
              <CloseIcon
                fill={isThemeDark ? white : colorTextLightMode}
                width="1.5rem"
                height="1.5rem"
              />
            </button>
          </div>
          {error && (
            <WarningNotification
              borderRadius={radiusPx}
              addClass="my-[.5rem]"
              backgroundColor={
                installer.nom_partenaire === 'DRAPO' ||
                installer.nom_partenaire === ''
                  ? red2
                  : installer.custom.couleur_alerte
              }
              color="white"
              warningText="Certaines réponses ne sont pas valides"
            />
          )}
          <Input
            borderRadius={radiusPx}
            color={isThemeDark ? white : dark1}
            backgroundColor={isThemeDark ? darkVariant1 : white}
            inputType="text"
            label="Raison sociale"
            value={companyName}
            onChange={(e) => setCompanyName(e.target.value)}
          />
          <Input
            borderRadius={radiusPx}
            addClass={`mt-[.75rem] ${errSiret}`}
            color={isThemeDark ? white : dark1}
            backgroundColor={isThemeDark ? darkVariant1 : white}
            inputType="text"
            isNumber
            label="SIRET"
            value={siret}
            onChange={(e) => {
              setSiret(e.target.value);
              setErrSiret('');
            }}
          />
          {errSiret !== '' && (
            <p className="text-[.75rem] text-red-500 mb-[1rem] ml-[.5rem] mt-[.5rem]">
              Votre SIRET doit contenir 14 chiffres.
            </p>
          )}
          <div className="flex mt-[.75rem]">
            <Input
              borderRadius={radiusPx}
              addClass="w-1/2	mr-[.5rem]"
              color={isThemeDark ? white : dark1}
              backgroundColor={isThemeDark ? darkVariant1 : white}
              inputType="text"
              label="Nom"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <Input
              borderRadius={radiusPx}
              addClass="w-1/2 ml-[.5rem]"
              color={isThemeDark ? white : dark1}
              backgroundColor={isThemeDark ? darkVariant1 : white}
              inputType="text"
              label="Prénom"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </div>
          <Input
            borderRadius={radiusPx}
            addClass={`mt-[.75rem] ${errBorderMail}`}
            color={isThemeDark ? white : dark1}
            backgroundColor={isThemeDark ? darkVariant1 : white}
            inputType="email"
            label="Email"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              setErrBorderMail('');
            }}
          />
          {errBorderMail !== '' && (
            <p className="text-[.75rem] text-red-500 mb-[1rem] ml-[.5rem] mt-[.5rem]">
              Merci de saisir une adresse email valide.
            </p>
          )}
          <div className="w-full">
            <Button
              borderRadius={radiusPx}
              label="Confirmer"
              color={colorTextButton}
              backgroundColor={colorPrimary}
              onClick={onValid}
              disabled={
                !!(
                  name === '' ||
                  firstName === '' ||
                  companyName === '' ||
                  email === '' ||
                  siret === ''
                )
              }
              addClass="mt-[.75rem] btn-sb-w-full"
            />
          </div>
        </div>
      )}
    </>
  );
}

export default NewSubcontractorForm;
