import {
  getFoldersDashboard,
  getFoldersInAirtableDashboard,
} from '@dashboard/apiRequests/dashboardRequests';
import { DashboardContext } from '@dashboard/utils/context/dashboardContext';
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import { v4 } from 'uuid';
import { InstallerContext } from '@auth/utils/authContext';
import { toast } from 'react-toastify';
import { IListFolders } from '@dashboard/utils/dashboardTypes';
import { DASHBOARD_TABS } from '@dashboard/utils/tabs';
import { LayoutDashboardContext } from '@dashboard/utils/context/LayoutDashboardContext';
import { purple1, white } from '@assets/colors';
import ModalPersonalization from './personalizationColumns/ModalPersonalization';
import TotalAmounts from './TotalAmountsAndDelete';
import RowsInFolderListArray from './RowsInFolderListArray';

interface IProps {
  foldersDatas: IListFolders[];
  setFoldersDatas: Dispatch<SetStateAction<IListFolders[]>>;
  numberPage: number;
  setNumberPage: Dispatch<SetStateAction<number>>;
}

function ArrayFolderList({
  foldersDatas,
  setFoldersDatas,
  numberPage,
  setNumberPage,
}: IProps): JSX.Element {
  const [foldersIsLoaded, setFoldersIsLoaded] = useState<boolean>(false);
  const [divScrollable, setDivScrollable] = useState<HTMLElement | null>(null);
  const [isEndFolders, setIsEndFolders] = useState<boolean>(false);
  const [columnsArrays, setColumnsArray] = useState<string[]>(['']);

  const { radiusPx, installer, isThemeDark } = useContext(InstallerContext);

  const {
    ceeStatusFilter,
    mprStatusFilter,
    elementActive,
    isModalPersonalizationFolders,
    isDeleteFolderLoading,
    isModalDeleteFolder,
    updateRowsSelectedFolders,
    rowsSelectedFolders,
    updateCountsFolders,
  } = useContext(DashboardContext);

  const { isRetractableDDC } = useContext(LayoutDashboardContext);

  useEffect(() => {
    setDivScrollable(document.getElementById('scrollDiv'));
    if (
      rowsSelectedFolders[0] !== undefined &&
      rowsSelectedFolders[0].folder_reference !== undefined &&
      rowsSelectedFolders[0].folder_reference === ''
    ) {
      updateRowsSelectedFolders([]);
    }
  }, []);

  useEffect(() => {
    if (isEndFolders) {
      toast('Vous avez atteint la fin de la liste.');
    }
  }, [isEndFolders]);

  useEffect(() => {
    if (elementActive === DASHBOARD_TABS.FOLDERS_INSTRUCTION) {
      setColumnsArray([
        'Nom bénéficiaire',
        'Prénom bénéficiaire',
        'Adresse des travaux',
        'Référence du dossier',
        'Opération',
        'Montant MaPrimeRénov',
        'Montant prime CEE',
        "Gestion MaPrimeRénov' par Drapo",
        'Frais dossier MPR TTC',
        'Frais dossier CEE TTC',
      ]);
    } else {
      setColumnsArray([
        'Nom du client',
        'Prénom du client',
        'Adresse des travaux',
        'Référence du dossier',
        'Date de simulation',
        'Opération',
        'Statut Dossier',
        'Type de ménage',
        'Téléphone du client',
        'Adresse mail du client',
        'Montant MaPrimeRénov',
        'Montant prime CEE',
        "Gestion MaPrimeRénov' par Drapo",
        'Frais dossier MPR TTC',
        'Frais dossier CEE TTC',
      ]);
    }
  }, [elementActive]);

  const infiniteScroll = () => {
    const scrollColumns = document.getElementById('scrollableFixed');

    if (
      foldersDatas.length > 14 &&
      divScrollable &&
      divScrollable?.offsetHeight + divScrollable?.scrollTop >=
        divScrollable?.scrollHeight &&
      !isEndFolders
    ) {
      if (!foldersIsLoaded) {
        setNumberPage(numberPage + 1);
        if (elementActive === DASHBOARD_TABS.FOLDERS_INSTRUCTION) {
          getFoldersInAirtableDashboard(
            setFoldersDatas,
            numberPage + 1,
            setFoldersIsLoaded,
            foldersDatas,
            ceeStatusFilter,
            mprStatusFilter,
            updateCountsFolders,
            updateRowsSelectedFolders,
            rowsSelectedFolders,
            setIsEndFolders
          );
        } else {
          getFoldersDashboard(
            setFoldersDatas,
            numberPage + 1,
            setFoldersIsLoaded,
            foldersDatas,
            updateCountsFolders,
            updateRowsSelectedFolders,
            rowsSelectedFolders,
            setIsEndFolders
          );
        }
      }
    }
    if (divScrollable?.scrollLeft !== 0 && scrollColumns) {
      const scrollWithTimeOut = () => {
        scrollColumns.style.transform = `translateX(-${divScrollable?.scrollLeft}px)`;
      };
      setTimeout(scrollWithTimeOut, 40);
    }
  };

  const scrollBarColor = isThemeDark ? '' : 'scroll_light_mode';
  const radius = radiusPx === '0px' ? '0px' : '12px';

  return (
    <div
      style={{ opacity: isDeleteFolderLoading ? 0.5 : 1 }}
      className="relative"
    >
      <div
        style={{ borderRadius: radius, transition: 'all 1s' }}
        id="scrollDiv"
        onScroll={!foldersIsLoaded ? infiniteScroll : undefined}
        className={`${scrollBarColor} ${
          isRetractableDDC ? 'lg:max-w-[74vw] ' : 'lg:max-w-[63vw]'
        } overflow-scroll max-h-[75vh] h-[75vh] pt-[3.125rem]`}
      >
        {isModalPersonalizationFolders && (
          <ModalPersonalization
            columnsArray={columnsArrays}
            setColumnArray={setColumnsArray}
            updateNumberPageList={setNumberPage}
          />
        )}

        <table>
          <tbody>
            <tr
              id="scrollableFixed"
              className="absolute pr-[.375rem] top-0 dark:bg-dark-1 bg-white"
            >
              <th className="w-auto px-[1.5rem] text-left">
                <button
                  className={`w-full flex items-center ${
                    rowsSelectedFolders.length > 0 ? 'visible' : 'invisible'
                  }`}
                  disabled={isDeleteFolderLoading || isModalDeleteFolder}
                  onClick={() => updateRowsSelectedFolders([])}
                  type="button"
                >
                  <svg
                    width="1.125rem"
                    height="1.125rem"
                    viewBox="0 0 17 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      x="0.0637207"
                      width="16.0424"
                      height="15.9958"
                      fill={
                        installer.nom_partenaire === 'DRAPO' ||
                        installer.nom_partenaire === ''
                          ? purple1
                          : installer.custom.couleur_boutons
                      }
                    />
                    <path d="M3.07153 8H8.0848H13.0981" stroke="white" />
                  </svg>
                </button>
              </th>
              {columnsArrays.map((column) => {
                return (
                  <th key={v4()}>
                    <p
                      style={{
                        width:
                          column === 'Opération' ||
                          column === 'Opération' ||
                          column === 'Adresse des travaux' ||
                          column === 'Statut Dossier' ||
                          column === 'Référence du dossier' ||
                          column === 'Référence'
                            ? '25rem'
                            : '12.5rem',
                      }}
                      className="column text-left dark:text-[#C6C6C6] text-colorTextLightMode py-[.25rem] h-[2.5rem] flex items-center justify-start text-[.75rem] font-normal"
                    >
                      {column}
                    </p>
                  </th>
                );
              })}
            </tr>
            {foldersDatas.map((folder) => {
              return (
                <RowsInFolderListArray
                  data={folder}
                  columnsArrays={columnsArrays}
                />
              );
            })}
          </tbody>
        </table>
        {foldersIsLoaded && (
          <div className="loader opacity-40 h-2">
            <div
              style={{
                backgroundColor: white,
              }}
              className="loaderBar"
            />
          </div>
        )}
      </div>
      <TotalAmounts
        setFoldersDatas={setFoldersDatas}
        folderDatas={foldersDatas}
      />
    </div>
  );
}

export default ArrayFolderList;
