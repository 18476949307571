/* eslint-disable no-nested-ternary */
import { useContext, useEffect, useState } from 'react';
import { DashboardContext } from '@dashboard/utils/context/dashboardContext';
import {
  arrowLeft_black,
  arrowLeft,
  editPencilWhite,
  editPencilBlack,
} from '@assets/images/images';
import { DASHBOARD_TABS } from '@dashboard/utils/tabs';
import { downloadCustomList } from '@dashboard/apiRequests/dashboardRequests';
import { InstallerContext } from '@auth/utils/authContext';
import SearchFolder from '@dashboard/components/searchFolder/SearchFolder';
import { colorTextLightMode, white } from '@assets/colors';
import { DownloadIcon } from '@assets/svgComponents/svgComponents';
import ButtonsHeader from './ButtonsHeader';
import UpdateListName from './UpdateListName';

function CustomViewHeader(): JSX.Element {
  const [isEditName, setIsEditName] = useState(false);
  const [urlCsv, setUrlCsv] = useState('');
  const [isDownload, setIsDownload] = useState(false);
  const [webSocketConnected, setWebSocketConnected] = useState<boolean>(false);

  const {
    updateElementActive,
    nameCurrentListCustomView,
    updateActiveCustomView,
    isCreateNewListCustomView,
    currentListCustomView,
    updateCurrentListCustomView,
    updateNewConditions,
    idCurrentListCustomView,
    currentSchemaFolderCustomView,
    countsCustomListView,
  } = useContext(DashboardContext);

  const { radiusPx, pusher, isThemeDark } = useContext(InstallerContext);

  const handleClick = () => {
    updateElementActive(DASHBOARD_TABS.SUMMARY);
    updateActiveCustomView(false);
    updateCurrentListCustomView(undefined);
    updateNewConditions([]);
  };

  useEffect(() => {
    if (urlCsv !== '') {
      window.open(urlCsv);
    }
  }, [urlCsv]);

  const downloadCsv = () => {
    setIsDownload(true);
    downloadCustomList(idCurrentListCustomView);

    if (!webSocketConnected) {
      const channel = pusher?.subscribe(`export.${idCurrentListCustomView}`);
      // eslint-disable-next-line no-unused-expressions
      channel?.bind('export:csv', (data: any) => {
        setUrlCsv(data.url);
        setIsDownload(false);
      });
      setWebSocketConnected(true);
    }
  };
  const radiusTop = radiusPx === '0px' ? `0px` : '16px';

  useEffect(() => {
    if (
      !webSocketConnected &&
      pusher?.channel(`export.${idCurrentListCustomView}`) === undefined
    ) {
      // eslint-disable-next-line no-unused-expressions
      pusher?.unsubscribe(`export.${idCurrentListCustomView}`);
    }
  }, [webSocketConnected]);

  return (
    <div
      style={{
        borderTopLeftRadius: radiusTop,
      }}
      className={`pt-[2.25rem] w-full dark:bg-dark-5  bg-white dark:text-white text-dark-2 px-[2rem] w-full h-home-header relative dark:border-none border-b border-b-borderLightMode border-b-[1px] `}
    >
      <div className="flex items-center space-x-[.875rem] w-full mb-[1rem] relative">
        <div className="absolute right-0 top-[-1rem] w-5/12 z-[99999]">
          <SearchFolder />
        </div>
        <div
          className="cursor-pointer"
          onClick={handleClick}
          onKeyDown={undefined}
          role="button"
          tabIndex={0}
        >
          <img
            className="tall:w-full w-[.625rem]"
            src={isThemeDark ? arrowLeft : arrowLeft_black}
            alt="arrow-left"
          />
        </div>
        {!isEditName ? (
          <div className="tall:text-[1.875rem] text-[1.5rem] font-[500]">
            {nameCurrentListCustomView}
          </div>
        ) : (
          <UpdateListName setIsEditName={setIsEditName} />
        )}

        {!isEditName && (
          <button type="button" onClick={() => setIsEditName(true)}>
            <img
              className="w-[1rem]"
              src={isThemeDark ? editPencilWhite : editPencilBlack}
              alt="modifier nom"
            />
          </button>
        )}
        {!isDownload ? (
          <button type="button" onClick={() => downloadCsv()}>
            <DownloadIcon
              fill={isThemeDark ? white : colorTextLightMode}
              width="1.25rem"
              height="1.25rem"
            />
          </button>
        ) : (
          <div className="spinner" />
        )}
      </div>
      <div className="flex justify-between w-full items-center">
        <ButtonsHeader />
        {!isCreateNewListCustomView &&
          currentSchemaFolderCustomView !== undefined &&
          currentSchemaFolderCustomView.schema !== undefined &&
          currentSchemaFolderCustomView.schema.fields !== undefined &&
          currentSchemaFolderCustomView.schema.fields.length > 0 &&
          currentListCustomView !== undefined &&
          currentListCustomView.data !== undefined && (
            <p className="text-[.75rem]">
              Il y a {countsCustomListView.nb_folders} opération$
              {countsCustomListView.nb_folders > 1 ? 's' : ''} dans la vue
            </p>
          )}
      </div>
    </div>
  );
}

export default CustomViewHeader;
