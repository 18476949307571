/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { InstallerContext } from '@auth/utils/authContext';
import { useContext, useEffect, useState } from 'react';
import { NavButton } from '@components/NavButton';
import { DASHBOARD_TABS } from '@dashboard/utils/tabs';
import { v4 } from 'uuid';
import { getListSchemasCustomView } from '@dashboard/apiRequests/dashboardRequests';
import {
  ChevronIcon,
  AddCircleIcon,
} from '@assets/svgComponents/svgComponents';
import { colorTextLightMode, white } from '@assets/colors';
import { DashboardContext } from '../utils/context/dashboardContext';
import ListCustomViewHeader from './ListCustomViewHeader';
import SearchFolder from './searchFolder/SearchFolder';
import DemoCheckboxMprCee from './DemoCheckboxMprCee';
import DemoCheckboxPrefilled from './DemoCheckboxPrefilled';

function DashboardHeader(): JSX.Element {
  const [isList, setIsList] = useState(false);

  const {
    updateElementActive,
    elementActive,
    updateActiveCustomView,
    updateIdCurrentListCustomView,
    updateFiltersOrPersonalizationModalCustomView,
    updateIsCreateNewListCustomView,
    updateNameCurrentListCustomView,
    listSchemasCustomView,
    updateListSchemasCustomView,
    updateIsLoadingCustomView,
    updateCurrentListCustomView,
  } = useContext(DashboardContext);
  const { installer, radiusPx, isThemeDark, colorPrimary, colorTextButton } =
    useContext(InstallerContext);

  useEffect(() => {
    updateCurrentListCustomView(undefined);
    getListSchemasCustomView(
      updateListSchemasCustomView,
      updateIsLoadingCustomView
    );
  }, []);

  const listSchemasCustomViewSort = listSchemasCustomView.sort((a, b) =>
    a.name.localeCompare(b.name)
  );

  return (
    <div
      style={{
        borderTopLeftRadius: radiusPx,
      }}
      className="pt-[2.25rem] w-full dark:bg-dark-5 bg-white px-[2rem] w-full h-home-header relative dark:border-none border-b border-b-borderLightMode border-b-[1px] "
    >
      <div className="flex items-center justify-between w-full mb-[.875rem]">
        <div className="lg:text-[1.875rem] pb-[.625rem] dark:text-white text-dark-1">
          Tableau de bord
          {installer.raison_sociale_de_lentreprise === 'DÉMO' && (
            <div className="flex">
              <DemoCheckboxMprCee />
              <DemoCheckboxPrefilled />
            </div>
          )}
        </div>
        <div className="right-7 absolute top-[2rem] w-5/12 z-[99999]">
          <SearchFolder />
        </div>
      </div>

      <div className="text-[.75rem]">
        <div className="flex space-x-[1.5rem] absolute bottom-0">
          {Object.keys(DASHBOARD_TABS).map((el) => (
            <NavButton
              key={v4()}
              title={DASHBOARD_TABS[el]}
              elementActive={elementActive}
              updateElementActive={updateElementActive}
              isDashboard
            />
          ))}
          {listSchemasCustomView.length > 0 && (
            <div className="pb-[.5rem] relative flex items-center">
              <button
                type="button"
                onClick={() => setIsList(!isList)}
                className="flex items-center space-x-[.375rem]"
              >
                <p className="text-[.875rem] dark:text-white text-colorTextLightMode">
                  Listes
                </p>
                <ChevronIcon
                  width="1.5rem"
                  height="1.5rem"
                  fill={isThemeDark ? white : colorTextLightMode}
                  className={`ml-auto transition-transform ${
                    !isList ? 'rotate-[90deg]' : 'rotate-[-90deg]'
                  }`}
                />
                {isList && (
                  <div
                    style={{
                      zIndex: 9999999999999,
                      borderRadius: radiusPx ? '8px' : 'Opx',
                    }}
                    className="absolute top-[1.25rem] left-[-1rem] !ml-[-.5rem] dark:bg-[#272727] dark:text-white text-colorTextLightMode w-[10rem] bg-white p-[1rem] flex flex-col space-y-[.375rem]"
                  >
                    {listSchemasCustomViewSort.map((el) => {
                      return (
                        <div key={v4()}>
                          <ListCustomViewHeader
                            datas={el}
                            setIsList={setIsList}
                          />
                        </div>
                      );
                    })}
                  </div>
                )}
              </button>
            </div>
          )}
        </div>

        <div className="absolute bottom-0 right-0 pr-[2rem]">
          <button
            onClick={() => {
              updateActiveCustomView(true);
              updateElementActive('');
              updateFiltersOrPersonalizationModalCustomView('Personnaliser');
              updateIsCreateNewListCustomView(true);
              updateIdCurrentListCustomView('');
              updateNameCurrentListCustomView(
                `Liste ${listSchemasCustomView.length + 1}`
              );
            }}
            type="button"
            className="pb-[.5rem] flex space-x-[.5rem] items-center"
          >
            <div
              style={{
                color: isThemeDark
                  ? white
                  : colorTextButton === 'white'
                  ? colorPrimary
                  : colorTextLightMode,
              }}
              className=" text-[.875rem]  leading-[1.5rem] font-medium"
            >
              Créer une liste
            </div>
            <AddCircleIcon
              width="1rem"
              height="1rem"
              fill={
                isThemeDark
                  ? white
                  : colorTextButton === 'white'
                  ? colorPrimary
                  : colorTextLightMode
              }
            />
          </button>
        </div>
      </div>
    </div>
  );
}

export default DashboardHeader;
