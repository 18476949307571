/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import { useContext, useEffect, useState } from 'react';
import { v4 } from 'uuid';
import { numFormatSpace } from '@utils/utils';
import { InstallerContext } from '@auth/utils/authContext';
import { FolderMonitoringContext } from '@folderMonitoring/utils/context/folderMonitoringContext';
import { IOperationsMonitoringDatas } from '@folderMonitoring/utils/folderMonitoringTypes';
import { InfoCircleIcon } from '@assets/svgComponents/svgComponents';
import { dark2 } from '@assets/colors';
import RecapFolderContent from './RecapFolderContent';

function RecapFolder(): JSX.Element {
  const { folderDatas, folderActif, updateHasAttestation } = useContext(
    FolderMonitoringContext
  );
  const { radiusPx, isThemeDark } = useContext(InstallerContext);

  const radius = radiusPx === '0px' ? '0px' : '8px';

  const totalStepsCEE = Object.keys(folderDatas.cee_steps).length + 1;
  const totalStepsMPR = Object.keys(folderDatas.mpr_steps).length;
  const [ceeTotalFormat, setCeeTotalFormat] = useState<string>('');
  const [mprTotalFormat, setMprTotalFormat] = useState<string>('');
  const [isDemandeur, setIsDemandeur] = useState<boolean>(false);
  const [isVisiblePartner, setIsVisiblePartner] = useState<boolean>(false);
  const [namePartner, setNamePartner] = useState<string>('');

  const alreadyHasAttestation = () => {
    if (
      folderDatas.operations.filter(
        (el: IOperationsMonitoringDatas) =>
          el.prime_cee_bénéficiaire !== '0' && el.montant_mpr !== '0'
      ).length > 0
    ) {
      updateHasAttestation(
        folderDatas.operations.filter(
          (el: IOperationsMonitoringDatas) => parseFloat(el.step_mpr) > 1
        ).length > 0
          ? folderDatas.operations.findIndex(
              (el: IOperationsMonitoringDatas) => parseFloat(el.step_mpr) > 5
            ) !== -1 ||
              folderDatas.operations.findIndex(
                (el: IOperationsMonitoringDatas) => parseFloat(el.step_cee) > 2
              ) !== -1
          : true
      );
    } else if (
      folderDatas.operations.filter(
        (el: IOperationsMonitoringDatas) =>
          el.prime_cee_bénéficiaire === '0' && el.montant_mpr !== '0'
      ).length > 0
    ) {
      updateHasAttestation(
        folderDatas.operations.findIndex(
          (el: IOperationsMonitoringDatas) => parseFloat(el.step_mpr) > 5
        ) !== -1
      );
    }
  };

  useEffect(() => {
    let totalCee = folderDatas.cee_total.toString();
    let totalMpr = folderDatas.mpr_total.toString();
    let haveDemandeur = false;
    let partnerName = '';

    for (let i = 0; i < folderDatas.operations.length; i += 1) {
      if (folderDatas.operations[i].statut_cee === 'Pas de CEE') {
        totalCee = '0';
        if (folderDatas.operations[i].demandeur_cee !== null) {
          haveDemandeur = true;
          partnerName = folderDatas.operations[i].demandeur_cee;
        }
      }
      if (folderDatas.operations[i].statut_mpr === 'Pas de MPR') {
        totalMpr = '0';
      }
    }

    if (haveDemandeur) {
      setNamePartner(partnerName);
    }
    setIsDemandeur(haveDemandeur);
    setCeeTotalFormat(numFormatSpace(totalCee));
    setMprTotalFormat(numFormatSpace(totalMpr));

    alreadyHasAttestation();
  }, [folderDatas]);

  const scrollbarColor = isThemeDark ? '' : 'scroll_light_mode';

  return (
    <div
      className={` ${scrollbarColor} w-full h-full flex flex-col space-y-[1.5rem] px-[1.375rem] overflow-y-scroll text-dark-1 dark:text-white`}
    >
      <div
        style={{ borderRadius: radius }}
        className="dark:bg-dark-5 bg-white py-[1rem] dark:border-borderDarkMode border border-lightModeBorder px-[2rem] flex items-center space-x-[3rem] w-full cursor-default opacity-[.92]"
      >
        <div className="font-medium text-[1rem]">Récapitulatif</div>
        <div className="w-full flex justify-between items-center">
          <div className="text-[.75rem]">
            {folderActif[0].référence_dossier}
          </div>
          <div className="text-[.75rem]">
            {folderDatas.operations[0] !== undefined
              ? folderDatas.operations[0].adresse_travaux
              : ''}
          </div>
          {ceeTotalFormat !== '0' && (
            <div className="text-[.75rem] flex items-center space-x-[.375rem]">
              <div>CEE {ceeTotalFormat} €</div>
              {isDemandeur && (
                <div
                  onMouseOver={() => setIsVisiblePartner(true)}
                  onMouseOut={() => setIsVisiblePartner(false)}
                  className="relative"
                >
                  <InfoCircleIcon
                    width="1.25rem"
                    fill={isThemeDark ? 'white' : 'black'}
                  />
                  {isVisiblePartner && (
                    <div className="absolute text-[.75rem] py-[.5rem] px-[.75rem] top-[.25rem] left-[-100%] ">
                      <div
                        style={{
                          borderWidth: '4px',
                          borderStyle: 'solid',
                          borderColor: `transparent transparent ${
                            isThemeDark ? dark2 : 'white'
                          } transparent`,
                        }}
                        className="ml-[.375rem] w-0"
                      />
                      <div className="dark:bg-[#131313] bg-white text-[.75rem] py-[.5rem] px-[.75rem] rounded-[4px] dark:text-white text-colorTextLightMode">
                        {namePartner}
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          )}

          {mprTotalFormat !== '0' && (
            <div className="text-[.75rem]">MPR {mprTotalFormat} €</div>
          )}

          <div className="text-[.75rem]">
            {folderDatas.nb_operations} opération
            {folderDatas.nb_operations > 1 ? 's' : ''}
          </div>
        </div>
      </div>
      {folderDatas.operations.map((operation: IOperationsMonitoringDatas) => (
        <div
          key={v4()}
          style={{ borderRadius: radius }}
          className="dark:border-borderDarkMode border border-lightModeBorder"
        >
          <RecapFolderContent
            operation={operation}
            totalStepsCEE={totalStepsCEE}
            totalStepsMPR={totalStepsMPR}
          />
        </div>
      ))}
    </div>
  );
}

export default RecapFolder;
