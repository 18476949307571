import { useContext } from 'react';
import { v4 } from 'uuid';
import { BulkImportContext } from '@bulkImport/utils/bulkImportContext';
import {
  columnsKeys,
  getObjectKeyByValue,
} from '@bulkImport/utils/columnsKeys';
import { checkCircle } from '@assets/images/images';
import { darkVariant1 } from '@assets/colors';

function ArrayFileSent(): JSX.Element {
  // A REMPLACER PAR fileSentToAirtable
  const { fileSentToAirtable } = useContext(BulkImportContext);

  // GERE LES BACKGROUND COLOR DE CHAQUES LIGNES DU TABLEAU
  const backgroundRowArray = (rowNumber: number) => {
    let backgroundColor = '';
    if (rowNumber % 2 === 0) {
      backgroundColor = '#262626';
    } else {
      backgroundColor = darkVariant1;
    }
    return backgroundColor;
  };

  return (
    <>
      {fileSentToAirtable?.columns && (
        <>
          <table>
            <tbody>
              <tr className="dark:bg-dark-4 bg-lightMode-dark-4">
                <th key={v4()}>
                  <p className="sb-text-rem-body2 w-[6.25rem] py-[.25rem] h-[2.5rem] ">
                    Status
                  </p>
                </th>
                {fileSentToAirtable?.columns.map((column) => {
                  return (
                    <th key={v4()}>
                      <p className="sb-text-rem-body2 w-[15.5rem] py-[.25rem] h-[2.5rem] ">
                        {column}
                      </p>
                    </th>
                  );
                })}
              </tr>

              {fileSentToAirtable?.rows?.map((row, index) => {
                return (
                  <tr key={v4()}>
                    <td
                      key={v4()}
                      style={{ backgroundColor: backgroundRowArray(index) }}
                      className="sb-text-rem-caption1"
                    >
                      <p
                        style={{ border: '0px solid white' }}
                        className=" w-[6.25rem] flex justify-center items-center lg:h-[2.5rem] text-center h-[1.5rem] overflow-hidden"
                      >
                        <img src={checkCircle} alt="Is validate icon" />
                      </p>
                    </td>
                    {fileSentToAirtable?.columns?.map((column) => {
                      const el = getObjectKeyByValue(columnsKeys, column);
                      return (
                        <td
                          key={v4()}
                          style={{ backgroundColor: backgroundRowArray(index) }}
                          className="sb-text-rem-caption1"
                        >
                          <p
                            style={{ border: '0px solid white' }}
                            className=" w-[15.5rem] py-[.25rem] h-[2.5rem] text-center h-[1.5rem] overflow-hidden"
                          >
                            {row.row[el]}
                          </p>
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </>
      )}
    </>
  );
}

export { ArrayFileSent };
