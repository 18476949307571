import { v4 } from 'uuid';
import LoaderRequestForSupplementsDashboard from '@components/loaders/LoaderRequestForSupplementsDashboard';
import { IInstallerNotificationCeeMprOrAll } from '@dashboard/utils/dashboardTypes';
import { useContext } from 'react';
import { InstallerContext } from '@auth/utils/authContext';
import { convertHexToRGBA } from '@utils/utils';
import { dark5 } from '@assets/colors';
import OneCardFolder from './OneCardFolder';

interface IProps {
  data: IInstallerNotificationCeeMprOrAll[] | undefined;
}

function ListCardsFolders({ data }: IProps): JSX.Element {
  const { radiusPx, isThemeDark, colorPrimary } = useContext(InstallerContext);

  const radius = radiusPx === '0px' ? '0px' : '8px';

  return (
    <div
      style={{ borderBottomRightRadius: radiusPx }}
      className={`py-[2rem] pl-[2rem] pr-[1rem] h-[82vh] dark:bg-dark-1 bg-backgroundSecondaryLightMode `}
    >
      {data !== undefined ? (
        <div className="h-full overflow-y-auto pr-[1.125rem] whitespace-nowrap">
          {data.length > 0 ? (
            data.map((el: IInstallerNotificationCeeMprOrAll) => {
              return (
                <div key={v4()}>
                  <OneCardFolder
                    amountCee={el.prime_cee_bénéficiaire}
                    amountMpr={el.montant_mpr}
                    nameClient={el.nom_client}
                    firstNameClient={el.prénom_client}
                    addressClient={el.adresse_travaux}
                    refFolder={el.référence_dossier}
                    error={el.error}
                  />
                </div>
              );
            })
          ) : (
            <div
              style={{
                borderRadius: radius,
                backgroundColor: !isThemeDark
                  ? convertHexToRGBA(colorPrimary, 0.1)
                  : dark5,
              }}
              className="w-full p-[.75rem] text-[.875rem] flex flex-col space-y-[1rem] dark:text-white text-dark-2 mb-[.75rem] border-[1px] border-borderLightMode dark:border-transparent"
            >
              <p className="font-[500] text-[1rem] mb-[.5rem]">
                Vous êtes à jour !
              </p>
              Vous n’avez aucune demande de <br /> compléments
            </div>
          )}
        </div>
      ) : (
        <LoaderRequestForSupplementsDashboard />
      )}
    </div>
  );
}

export default ListCardsFolders;
