import { InstallerContext } from '@auth/utils/authContext';
import { IDataCurrentCustomView } from '@dashboard/utils/dashboardTypes';
import { v4 } from 'uuid';
import React, { useContext } from 'react';
import { numFormatSpace } from '@utils/utils';
import { purple1 } from '@assets/colors';
import { DashboardContext } from '../../utils/context/dashboardContext';

interface IProps {
  rowsSelected: IDataCurrentCustomView[];
}
function TotalAmountRows({ rowsSelected }: IProps): JSX.Element {
  const {
    currentSchemaFolderCustomView,
    updateFiltersOrPersonalizationModalCustomView,
    countsCustomListView,
  } = useContext(DashboardContext);

  const { installer, radiusPx } = useContext(InstallerContext);
  const radius = radiusPx === '0px' ? '0px' : '8px';

  const totalMpr = () => {
    if (rowsSelected.length === 0) {
      return countsCustomListView['Montant MPR géré par Drapo'];
    }
    if (rowsSelected.length > 0) {
      return rowsSelected.reduce((acc, curr) => {
        return acc + curr?.['Montant MPR géré par Drapo'];
      }, 0);
    }
    return 0;
  };

  const totalCee = () => {
    if (rowsSelected.length === 0) {
      return countsCustomListView['Prime CEE bénéficiaire'];
    }
    if (rowsSelected.length > 0) {
      return rowsSelected.reduce((acc, curr) => {
        return acc + curr?.['Prime CEE bénéficiaire'];
      }, 0);
    }
    return 0;
  };

  const totaux = [
    {
      label: 'Total MPR',
      value: totalMpr(),
    },
    {
      label: 'Total CEE',
      value: totalCee(),
    },
    {
      label: 'Total global',
      value: totalMpr() + totalCee(),
    },
  ];
  const displayAmount = () => {
    return (
      currentSchemaFolderCustomView.schema !== undefined &&
      currentSchemaFolderCustomView.schema.fields !== undefined &&
      currentSchemaFolderCustomView.schema.fields.filter(
        (field) => field.provider_name === 'Prime CEE bénéficiaire'
      ).length === 1 &&
      currentSchemaFolderCustomView.schema.fields.filter(
        (field) => field.provider_name === 'Montant MPR géré par Drapo'
      ).length === 1
    );
  };

  return (
    <div
      style={{
        borderBottomLeftRadius: radius,
        backgroundColor:
          installer.nom_partenaire === 'DRAPO' ||
          installer.nom_partenaire === ''
            ? purple1
            : installer.custom.couleur_boutons,
      }}
      className="flex text-white pt-[.625rem] px-[1.5rem] w-full pb-[5.825rem]"
    >
      {displayAmount() && (
        <p className=" w-2/6 px-[1.5rem] text-[1rem] font-normal">
          {rowsSelected.length} Sélectionné
          {rowsSelected.length > 1 ? 's' : ''}
        </p>
      )}

      <div className="w-full">
        {displayAmount() ? (
          <div className="w-4/6 flex space-x-[3.125rem]">
            {totaux.map((el) => {
              return (
                <div key={v4()}>
                  <p>{el.label}</p>
                  <p>{numFormatSpace(el.value, false)} €</p>
                </div>
              );
            })}
          </div>
        ) : (
          <p className="text-[.875rem]">
            Pour afficher les montants totaux, veuillez afficher les colonnes{' '}
            <strong>Prime CEE bénéficiaire</strong> et{' '}
            <strong>Montant MPR géré par Drapo</strong> dans la vue
            personnalisée. <br /> Vous pouvez les ajouter en cliquant{' '}
            <button
              className="font-medium underline"
              type="button"
              onClick={() =>
                updateFiltersOrPersonalizationModalCustomView('Personnaliser')
              }
            >
              ici.
            </button>
          </p>
        )}
      </div>
    </div>
  );
}

export default TotalAmountRows;
