import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Button } from 'storybook-design-system-drapo';
import { purple1 } from '@assets/colors';
import {
  IStepConditionType,
  IFieldType,
  IStepOperationType,
  IStepType,
} from '../../utils/AdminTypes';
import { updateStep } from '../../apiRequests/AdminRequests';
import Condition from './Condition';
import Save from '../popups/Save';

type CleanConditionType = {
  condition: string;
  value: string | string[];
  provider_name?: string;
  provider_id?: string;
  provider_type?: string;
};

type PropTypes = {
  showPopup: string;
  setShowPopup: Dispatch<SetStateAction<string>>;
  stepFieldsSelect: IFieldType[];
  currentStep: IStepType;
  updateSteps: Dispatch<SetStateAction<IStepType[]>>;
  setOperations: Dispatch<SetStateAction<IStepOperationType[]>>;
  setOperationLoading: Dispatch<SetStateAction<boolean>>;
  stepName: string;
};

export default function SelectConditions({
  stepFieldsSelect,
  showPopup,
  setShowPopup,
  currentStep,
  updateSteps,
  setOperations,
  setOperationLoading,
  stepName,
}: PropTypes): JSX.Element {
  const [conditions, setConditions] = useState<IStepConditionType[]>(
    currentStep.schema.whereClauses.conditions
  );
  const [conditionsFieldsIds, setConditionsFieldsIds] = useState<
    (string | undefined)[]
  >([]);
  const [emptyConditionValue, setEmptyConditionValue] = useState<number>(0);
  useEffect(() => {
    setConditions(currentStep.schema.whereClauses.conditions);
  }, []);

  const [message, setMessage] = useState<string>('');

  useEffect(() => {
    if (!conditions[0]) setMessage('');
    setConditionsFieldsIds(conditions.map((field) => field.provider_id));
    const emptyConditions = conditions.filter((elt) => elt.value.length === 0);
    setEmptyConditionValue(emptyConditions.length);
  }, [conditions]);

  const handleSubmitConditions = () => {
    if (emptyConditionValue === 0) {
      let cleanConditions: CleanConditionType[] = [];
      conditions.forEach((elt) => {
        const cleanCondition = {
          provider_id: elt.provider_id,
          provider_name: elt.provider_name,
          condition: elt.condition,
          value: elt.value,
        };
        cleanConditions = [...cleanConditions, cleanCondition];
      });

      setOperationLoading(true);

      updateStep(
        currentStep,
        updateSteps,
        setShowPopup,
        setOperations,
        setOperationLoading,
        conditions,
        stepFieldsSelect,
        stepName
      );
    } else {
      setMessage('Veuillez ajouter une valeur à chaque condition.');
    }
  };

  const availableFields = stepFieldsSelect.filter(
    (field) => !conditionsFieldsIds.includes(field.provider_id)
  );

  const handleAddCondition = () => {
    if (conditions.length < stepFieldsSelect.length && availableFields[0]) {
      const initialCondition: IStepConditionType = {
        id: `condition${Math.random().toString()}`,
        condition: 'ILIKE',
        value: '',
        provider_name: availableFields[0].provider_name,
        provider_id: availableFields[0].provider_id,
        provider_type: availableFields[0].provider_type,
      };
      setConditions([...conditions, initialCondition]);
    }
  };

  const handleClosePopUp = () => {
    if (currentStep.schema.whereClauses.conditions === conditions) {
      setShowPopup('NONE');
    } else {
      setShowPopup('SAVE_CONDITIONS');
    }
  };

  return (
    <div
      className="fixed top-0 left-0 w-full h-full bg-[#000000] z-10"
      onClick={handleClosePopUp}
      onKeyPress={handleClosePopUp}
      tabIndex={0}
      role="button"
    >
      <div
        className="dark:bg-dark-3 bg-lightMode-dark-3 relative left-1/2 top-1/2 w-2/3 h-[85%]
        cursor-default rounded-md p-5 flex flex-col"
        style={{ transform: 'translate(-50%, -50%)' }}
        onClick={(e) => e.stopPropagation()}
        onKeyPress={(e) => e.stopPropagation()}
        tabIndex={0}
        role="button"
      >
        {showPopup === 'SAVE_CONDITIONS' && (
          <Save setShowPopup={setShowPopup} />
        )}
        <button
          type="button"
          className="rotate-45 absolute top-0 right-4 text-[40px]"
          onClick={handleClosePopUp}
        >
          +
        </button>
        <div>
          <h2 className="text-xl text-center mb-10">Ajouter des conditions</h2>
        </div>
        <div className="w-full flex justify-center">
          <Button
            label="Ajouter une condition +"
            backgroundColor={purple1}
            color="white"
            addClass="w-[250px]"
            onClick={handleAddCondition}
          />
        </div>
        <div className="w-full overflow-y-auto h-[480px] flex flex-col items-center gap-5 mt-10">
          {conditions[0] && (
            <p className="text-center text-sm text-red-1">{message}</p>
          )}
          {conditions.map((condition) => (
            <Condition
              key={condition.id}
              conditions={conditions}
              condition={condition}
              stepFieldsSelect={stepFieldsSelect}
              updateConditions={setConditions}
              conditionsFieldsIds={conditionsFieldsIds}
              setConditionsFieldsIds={setConditionsFieldsIds}
            />
          ))}
        </div>
        {(conditions[0] ||
          currentStep.schema.whereClauses.conditions !== conditions) && (
          <Button
            label="valider"
            backgroundColor={purple1}
            color="white"
            addClass="w-1/4 self-center absolute bottom-5"
            onClick={handleSubmitConditions}
          />
        )}
      </div>
    </div>
  );
}
