import { useState, useEffect, useContext } from 'react';
import DashboardHeader from '@dashboard/components/DashboardHeader';
import Summary from '@dashboard/components/summary/Summary';
import 'react-toastify/dist/ReactToastify.css';
import { DashboardContext } from '@dashboard/utils/context/dashboardContext';
import {
  IFinancial,
  IStatus,
  ICurrentCustomView,
  ICurrentSchemaCustomView,
  IConditions,
  IQuotaCee,
  ICountsCurrentCustomView,
  IDataCurrentCustomView,
  IListFolders,
} from '@dashboard/utils/dashboardTypes';
import {
  initialCountsCustomView,
  initialCurrentSchemaCustomView,
  initialFinancials,
  initialListFolders,
  initialSchemaCustomView,
  initialStatus,
} from '@dashboard/utils/initialState';
import { DASHBOARD_TABS } from '@dashboard/utils/tabs';
import CustomView from '@dashboard/components/customView/CustomView';
import CustomViewHeader from '@dashboard/components/customView/header/CustomViewHeader';
import FoldersHeader from '@dashboard/components/folders/HeaderFolders';
import { FoldersList } from '@dashboard/components/folders/FoldersList';
import { useLocation } from 'react-router-dom';
import ModalNewProfessionnalSpace from '@components/ModalNewProfessionnalSpace';
import { InstallerContext } from '@auth/utils/authContext';
import { useMediaQuery } from 'react-responsive';
import { ISchemaFolder } from '../models/dashboard/utils/dashboardTypes';
import LayoutDashboard from './LayoutDashboard';

type LocationState = { prevPath: string };

function Dashboard(): JSX.Element {
  const { radiusPx, installer } = useContext(InstallerContext);
  const isMobile = useMediaQuery({ maxWidth: 1024 });

  const [elementActive, setElementActive] = useState<string>(
    DASHBOARD_TABS.SUMMARY
  );
  const [isNewProfessionnalSpace, setIsNewProfessionnalSpace] =
    useState<boolean>(localStorage.getItem('isNewProfessionnalSpace') !== 'ok');
  const [financialsIsLoaded, setFinancialsIsLoaded] = useState<boolean>(false);
  const [statusIsLoaded, setStatusIsLoaded] = useState<boolean>(false);
  const [isLoadedFolders, setIsLoadedFolders] = useState<boolean>(true);

  const [renderComponent, setRenderComponent] = useState<JSX.Element>(
    <Summary />
  );
  const [renderHeader, setRenderHeader] = useState<JSX.Element>(
    <DashboardHeader />
  );
  const [financialsCee, setFinancialsCee] =
    useState<IFinancial>(initialFinancials);
  const [financialsMpr, setFinancialsMpr] =
    useState<IFinancial>(initialFinancials);
  const [statusCee, setStatusCee] = useState<IStatus>(initialStatus);
  const [statusMpr, setStatusMpr] = useState<IStatus>(initialStatus);
  const [mprStatusFilter, setMprStatusFilter] = useState<string[]>(['']);
  const [ceeStatusFilter, setCeeStatusFilter] = useState<string[]>(['']);
  const [isModalPersonalizationFolders, setIsModalPersonalizationFolders] =
    useState<boolean>(false);
  const [countsFolders, setCountsFolders] = useState({
    mpr: '0',
    cee: '0',
    total: 0,
    nb_folders: 0,
  });
  const [isDeleteFolderLoading, setIsDeleteFolderLoading] =
    useState<boolean>(false);
  const [isInAirtable, setIsInAirtable] = useState<boolean>(false);
  const [isModalDeleteFolder, setIsModalDeleteFolder] = useState(false);
  const [previousPage, setPreviousPage] = useState<string>('');
  const [activeCustomView, setActiveCustomView] = useState<boolean>(false);
  const [isLoadingCustomView, setIsLoadingCustomView] =
    useState<boolean>(false);
  const [rowsSelectedFolders, setRowsSelectedFolders] = useState<
    IListFolders[]
  >([initialListFolders]);
  const [rowsSelectedCustomView, setRowsSelectedCustomView] = useState<
    IDataCurrentCustomView[]
  >([
    {
      'Accéder dossier': null,
      'Adresse travaux': null,
      'Commentaires risques MPR': null,
      'Date demande soldes': null,
      'Date demande subvention': null,
      'Date engagement opération': null,
      'Demandeur CEE': null,
      'Libellé opération': null,
      'Montant MPR géré par Drapo': 0,
      'Montant TTC devis': 0,
      'Nom client': null,
      'Prime CEE bénéficiaire': 0,
      'Prime CEE installateur': null,
      'Prénom client': null,
      'Référence dossier': null,
      'Sous-opération': null,
      'Statut MPR': null,
      'Statut Retour instructeur': null,
      'Statut Risque MPR': null,
      'Statut paiement': null,
      'Type information préalable/retrait': null,
      'Type retour instructeur MPR': null,
      'Virement MPR pro': null,
      'Référence du dossier': null,
    },
  ]);
  const [isCreateNewListCustomView, setIsCreateNewListCustomView] =
    useState<boolean>(false);
  const [nameCurrentListCustomView, setNameCurrentListCustomView] =
    useState<string>('Liste personnalisable');
  const [idCurrentListCustomView, setIdCurrentListCustomView] =
    useState<string>('');
  const [listVisibleColumnsCustomView, setListVisibleColumnsCustomView] =
    useState<ISchemaFolder>(initialSchemaCustomView);
  const [listInvisibleColumnsCustomView, setListInvisibleColumnsCustomView] =
    useState<ISchemaFolder>(initialSchemaCustomView);
  const [
    filtersOrPersonalizationModalCustomView,
    setFiltersOrPersonalizationModalCustomView,
  ] = useState<string>('');
  const [schemaFoldersCustomView, setSchemaFoldersCustomView] =
    useState<ISchemaFolder>(initialSchemaCustomView);
  const [listSchemasCustomView, setListSchemasCustomView] = useState<
    ICurrentSchemaCustomView[]
  >([initialCurrentSchemaCustomView]);
  const [currentSchemaFolderCustomView, setCurrentSchemaFolderCustomView] =
    useState<ICurrentSchemaCustomView>(initialCurrentSchemaCustomView);
  const [currentListCustomView, setCurrentListCustomView] = useState<
    ICurrentCustomView | undefined
  >();
  const [numberPageList, setNumberPageList] = useState<number>(1);
  const [countsCustomListView, setCountsCustomListView] =
    useState<ICountsCurrentCustomView>(initialCountsCustomView);

  const [newConditions, setNewConditions] = useState<IConditions[]>([]);
  const [orOrAndName, setIsOrOrAndName] = useState(
    'Afficher les dossiers qui contiennent au moins une  des conditions ci-dessous'
  );
  const [isDisabledFiltersCustomView, setIsDisabledFiltersCustomView] =
    useState<boolean>(true);

  const [quotaCee, setQuotaCee] = useState<IQuotaCee | undefined>();

  const location = useLocation();

  useEffect(() => {
    const html = document.getElementsByTagName('html')[0];

    if (isMobile) {
      html.classList.add('not-scroll');
    } else {
      html.classList.remove('not-scroll');
    }
  }, [isMobile]);

  useEffect(() => {
    if (activeCustomView) {
      setRenderComponent(<CustomView />);
      setRenderHeader(<CustomViewHeader />);
    } else if (
      elementActive === DASHBOARD_TABS.FOLDERS_INSTRUCTION ||
      elementActive === DASHBOARD_TABS.FOLDERS_SIMULATION
    ) {
      setRenderHeader(<FoldersHeader />);
      setRenderComponent(<FoldersList />);
    } else {
      setRenderComponent(<Summary />);
      setRenderHeader(<DashboardHeader />);
    }
  }, [elementActive, activeCustomView]);

  useEffect(() => {
    if (location.state !== null) {
      const { prevPath } = location.state as LocationState;

      if (prevPath !== undefined) {
        if (
          prevPath.includes('suivi-de-dossier') ||
          prevPath.includes(DASHBOARD_TABS.FOLDERS_INSTRUCTION)
        ) {
          setElementActive(DASHBOARD_TABS.FOLDERS_INSTRUCTION);
        } else if (prevPath.includes(DASHBOARD_TABS.FOLDERS_SIMULATION)) {
          setElementActive(DASHBOARD_TABS.FOLDERS_SIMULATION);
        }
      }
    }
  }, [location]);

  const dashboardContextValue = {
    elementActive,
    updateElementActive: setElementActive,
    financialsIsLoaded,
    updateFinancialsIsLoaded: setFinancialsIsLoaded,
    financialsCee,
    updateFinancialsCee: setFinancialsCee,
    financialsMpr,
    updateFinancialsMpr: setFinancialsMpr,
    statusCee,
    updateStatusCee: setStatusCee,
    statusMpr,
    updateStatusMpr: setStatusMpr,
    statusIsLoaded,
    updateStatusIsLoaded: setStatusIsLoaded,
    isLoadedFolders,
    updateIsLoadedFolders: setIsLoadedFolders,
    mprStatusFilter,
    updateMprStatusFilter: setMprStatusFilter,
    ceeStatusFilter,
    updateCeeStatusFilter: setCeeStatusFilter,
    isModalPersonalizationFolders,
    updateIsModalPersonalizationFolders: setIsModalPersonalizationFolders,
    countsFolders,
    updateCountsFolders: setCountsFolders,
    rowsSelectedFolders,
    updateRowsSelectedFolders: setRowsSelectedFolders,
    isDeleteFolderLoading,
    updateIsDeleteFolderLoading: setIsDeleteFolderLoading,
    isInAirtable,
    updateIsInairtable: setIsInAirtable,
    isModalDeleteFolder,
    updateIsModalDeleteFolder: setIsModalDeleteFolder,
    previousPage,
    updatePreviousPage: setPreviousPage,
    activeCustomView,
    updateActiveCustomView: setActiveCustomView,
    nameCurrentListCustomView,
    updateNameCurrentListCustomView: setNameCurrentListCustomView,
    idCurrentListCustomView,
    updateIdCurrentListCustomView: setIdCurrentListCustomView,
    filtersOrPersonalizationModalCustomView,
    updateFiltersOrPersonalizationModalCustomView:
      setFiltersOrPersonalizationModalCustomView,
    schemaFoldersCustomView,
    updateSchemaFoldersCustomView: setSchemaFoldersCustomView,
    listSchemasCustomView,
    updateListSchemasCustomView: setListSchemasCustomView,
    listVisibleColumnsCustomView,
    updateListVisibleColumnsCustomView: setListVisibleColumnsCustomView,
    listInvisibleColumnsCustomView,
    updateListInvisibleColumnsCustomView: setListInvisibleColumnsCustomView,
    isCreateNewListCustomView,
    updateIsCreateNewListCustomView: setIsCreateNewListCustomView,
    isLoadingCustomView,
    updateIsLoadingCustomView: setIsLoadingCustomView,
    rowsSelectedCustomView,
    updateRowsSelectedCustomView: setRowsSelectedCustomView,
    currentListCustomView,
    updateCurrentListCustomView: setCurrentListCustomView,
    currentSchemaFolderCustomView,
    updateCurrentSchemaFolderCustomView: setCurrentSchemaFolderCustomView,
    numberPageList,
    updateNumberPageList: setNumberPageList,
    newConditions,
    updateNewConditions: setNewConditions,
    orOrAndName,
    updateOrOrAndName: setIsOrOrAndName,
    isDisabledFiltersCustomView,
    updateIsDisabledFiltersCustomView: setIsDisabledFiltersCustomView,
    countsCustomListView,
    updateCountsCustomListView: setCountsCustomListView,
    quotaCee,
    updateQuotaCee: setQuotaCee,
  };

  useEffect(() => {
    setIsModalDeleteFolder(false);
  }, [elementActive]);

  return (
    <DashboardContext.Provider value={dashboardContextValue}>
      {isMobile && isNewProfessionnalSpace ? (
        <ModalNewProfessionnalSpace
          setIsNewProfessionnalSpace={setIsNewProfessionnalSpace}
        />
      ) : (
        <LayoutDashboard>
          <>
            {renderHeader}
            <div
              style={{ borderBottomLeftRadius: radiusPx }}
              className={`w-full dark:bg-dark-1 bg-backgroundSecondaryLightMode lg:h-[82vh] ${
                installer.raison_sociale_de_lentreprise === 'DÉMO'
                  ? 'overflow-y-scroll overflow-x-hidden'
                  : 'overflow-hidden'
              }`}
            >
              {renderComponent}
            </div>
          </>
        </LayoutDashboard>
      )}

      {isNewProfessionnalSpace && !isMobile && (
        <ModalNewProfessionnalSpace
          setIsNewProfessionnalSpace={setIsNewProfessionnalSpace}
        />
      )}
    </DashboardContext.Provider>
  );
}

export default Dashboard;
