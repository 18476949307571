import { MapView } from 'storybook-design-system-drapo';
import { useContext } from 'react';
import marker from '@assets/images/marker.svg';
import { SimulatorContext } from '@simulator/utils/simulatorContext';
import { LabelsByValue } from '@simulator/utils/labelsByValue';
import { ILatLng, IRecapGeneralType } from '@simulator/utils/simulatorTypes';
import { InstallerContext } from '@auth/utils/authContext';
import { convertHexToRGBA } from '@utils/utils';

interface MapProps {
  coordinates: { lat: number; lng: number }[];
  latLngData: ILatLng;
  markerMap: boolean;
  divRightWidth: number | undefined;
}

function Map({
  coordinates,
  latLngData,
  markerMap,
  divRightWidth,
}: MapProps): JSX.Element {
  const { simulatorDatas, heightMap, title } = useContext(SimulatorContext);
  const { colorPrimary, radiusPx, isThemeDark } = useContext(InstallerContext);

  const recapGeneralDatas: Array<IRecapGeneralType> = [
    {
      title: 'Âge du logement',
      content: LabelsByValue[simulatorDatas.age],
    },
    {
      title: "Type d'habitation",
      content: LabelsByValue[simulatorDatas.habitation],
    },
    {
      title: 'Chauffage central',
      content: LabelsByValue[simulatorDatas.energy],
    },
    { title: 'Superficie', content: `${simulatorDatas.surface}m2` },

    {
      title: 'Personne(s) dans le ménage',
      content: `${simulatorDatas.persons} Personne${
        simulatorDatas.persons !== '1' ? 's' : ''
      }`,
    },
    {
      title: 'Revenus du ménage',
      content: `${simulatorDatas.income.legend} (${simulatorDatas.income.menage})`,
    },
    { title: 'Typologie client', content: LabelsByValue[simulatorDatas.owner] },
  ];

  return (
    <div
      style={{ borderRadius: radiusPx }}
      className={`max-h-[90vh] fixed h-[90vh] w-full z-[22222] `}
    >
      <div
        style={{ width: divRightWidth, borderRadius: radiusPx }}
        className={
          heightMap === 'h-[90vh]'
            ? ` dark:bg-dark-1 bg-white py-0 h-[90vh] relative `
            : `dark:bg-dark-1 bg-white py-[1.25rem] h-[90vh] relative overflow-y-auto`
        }
      >
        <div
          style={{ transition: '2s', borderRadius: radiusPx }}
          className={
            heightMap === 'h-[90vh]'
              ? `${heightMap} mx-auto w-12/12 dark:border-none border border-borderLightMode2 `
              : `${heightMap} mx-auto w-11/12 dark:border-none border border-borderLightMode2 `
          }
        >
          <MapView
            colorPolygon={colorPrimary}
            marker={markerMap}
            iconMarker={marker}
            zoomLevel={coordinates.length > 1 ? 18 : 6}
            addressCenter={latLngData}
            coordinates={coordinates}
            whiteMode={!isThemeDark}
          />
        </div>
        <ul className="w-full px-[1.25rem] sb-text-rem-body2 mt-[1.5rem] dark:text-white text-colorTextLightMode">
          {recapGeneralDatas.map((data: IRecapGeneralType, index: number) => (
            <li
              style={{
                borderBottom: isThemeDark
                  ? `2px solid ${convertHexToRGBA('#707070', 0.5)}`
                  : `2px solid ${convertHexToRGBA('#202020', 0.5)}`,
                transition: '1s ease-in-out',
                opacity:
                  data.content === ' ()' ||
                  data.content === 'm2' ||
                  data.content === ' Personnes' ||
                  data.content === '' ||
                  data.content === undefined
                    ? 0
                    : 1,
                visibility:
                  data.content === ' ()' ||
                  data.content === 'm2' ||
                  data.content === ' Personnes' ||
                  data.content === '' ||
                  data.content === undefined ||
                  title === 'Adresse de vos travaux' ||
                  title === 'Age du logement'
                    ? 'hidden'
                    : 'visible',
              }}
              key={data.title}
              className={`flex ${index === 0 ? 'pt-0' : 'pt-[.5rem]'} ${
                index === recapGeneralDatas.length - 1
                  ? 'pb-[.625rem]'
                  : 'pb-[.5rem]'
              }`}
            >
              <div className="w-7/12 text-left">{data.title}</div>
              <div className="w-5/12 text-right">{data.content}</div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default Map;
