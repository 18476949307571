import { purple1 } from '@assets/colors';
import { Dispatch, SetStateAction, useContext } from 'react';
import { InstallerContext } from '@auth/utils/authContext';
import { DashboardContext } from '@dashboard/utils/context/dashboardContext';

type INavButtonProps = {
  title: string;
  elementActive: string;
  updateElementActive: Dispatch<SetStateAction<string>>;
  isDashboard?: boolean;
};

function NavButton({
  title,
  elementActive,
  updateElementActive,
  isDashboard,
}: INavButtonProps): JSX.Element {
  const { installer } = useContext(InstallerContext);
  const {
    updateCeeStatusFilter,
    updateMprStatusFilter,
    updateRowsSelectedFolders,
  } = useContext(DashboardContext);

  const handleClick = () => {
    updateElementActive(title);
    if (isDashboard) {
      updateCeeStatusFilter(['']);
      updateMprStatusFilter(['']);
      updateRowsSelectedFolders([]);
    }
  };

  return (
    <button
      type="button"
      style={{
        borderBottomColor:
          installer.nom_partenaire === 'DRAPO' ||
          installer.nom_partenaire === ''
            ? purple1
            : installer.custom.couleur_boutons,
      }}
      className={`text-[.875rem] leading-[1.5rem] flex items-center space-x-[.5rem] pb-[.5rem] dark:text-white text-colorTextLightMode whitespace-nowrap ${
        title === elementActive
          ? `text-white border-b-[1px]`
          : 'opacity-[.40] border-none'
      }`}
      onClick={handleClick}
    >
      <div>{title}</div>
    </button>
  );
}

export { NavButton };

NavButton.defaultProps = {
  isDashboard: false,
};
