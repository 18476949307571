/* eslint-disable dot-notation */
import { useContext, useEffect } from 'react';
import { v4 } from 'uuid';
import { BulkImportContext } from '@bulkImport/utils/bulkImportContext';
import { IErrorWs } from '@bulkImport/utils/bulkImportTypes';
import {
  columnsKeys,
  getObjectKeyByValue,
} from '@bulkImport/utils/columnsKeys';
import { convertHexToRGBA, numFormatSpace } from '@utils/utils';
import { darkVariant1, red1 } from '@assets/colors';
import { InstallerContext } from '@auth/utils/authContext';
import { SearchIcon } from '@assets/svgComponents/svgComponents';

function ArrayFileXls(): JSX.Element {
  const {
    fileUploaded,
    updateErrorWindow,
    updateErrorWindowHeader,
    updateErrorWindowContent,
  } = useContext(BulkImportContext);

  const { isThemeDark, colorPrimary } = useContext(InstallerContext);

  // GERE LES BACKGROUND COLOR DE CHAQUES LIGNES DU TABLEAU AVEC ERREUR
  const backgroundRowErrorArray = (rowNumber: number) => {
    let backgroundColor = '';
    if (rowNumber % 2 === 0) {
      backgroundColor = red1;
    } else {
      backgroundColor = '#820e28';
    }
    return backgroundColor;
  };

  // GERE LES BACKGROUND COLOR DE CHAQUES LIGNES DU TABLEAU
  const backgroundRowArray = (rowNumber: number) => {
    let backgroundColor = '';
    if (rowNumber % 2 === 0) {
      backgroundColor = isThemeDark
        ? '#262626'
        : convertHexToRGBA(colorPrimary, 0.1);
    } else {
      backgroundColor = isThemeDark ? darkVariant1 : '';
    }
    return backgroundColor;
  };

  const handleRowError = (rowErrors: IErrorWs[] | undefined) => {
    if (rowErrors) {
      updateErrorWindow(true);
      updateErrorWindowHeader(
        `${rowErrors.length} erreur${rowErrors.length > 1 ? 's' : ''}`
      );
      const errorContent: string[] = [];
      for (let i = 0; i < rowErrors.length; i += 1) {
        const row = rowErrors[i];
        errorContent.push(`- ${row.column} : ${row.message}`);
      }
      updateErrorWindowContent(
        <>
          {errorContent.map((error) => {
            return (
              <div key={v4()}>
                {error} <br />
              </div>
            );
          })}
        </>
      );
    }
  };

  useEffect(() => {
    if (fileUploaded?.nb_errors && fileUploaded?.nb_errors > 0) {
      updateErrorWindow(true);
      updateErrorWindowHeader(
        `${fileUploaded?.nb_errors} erreur${
          fileUploaded?.nb_errors > 1 ? 's' : ''
        }`
      );
      updateErrorWindowContent(
        <div key={v4()}>
          Cliquez sur la ligne d’un dossier contenant une ou des erreur(s) pour
          la ou les consulter.
        </div>
      );
    }
  }, []);

  return (
    <>
      {fileUploaded?.columns && (
        <>
          <table>
            <tbody>
              <tr className="dark:bg-dark-4 bg-lightMode-dark-4 dark:text-white text-dark-1 ">
                {fileUploaded?.nb_errors ? (
                  <th>
                    <p className="sb-text-rem-body2 w-[6.25rem] py-[.25rem] h-[2.5rem] flex justify-center dark:text-white text-dark-1">
                      Erreur(s)
                    </p>
                  </th>
                ) : (
                  <></>
                )}
                {fileUploaded?.columns.map((column) => {
                  return (
                    <th key={v4()} className="">
                      <p className="sb-text-rem-body2 w-[15.5rem] py-[.25rem] h-[2.5rem] dark:text-white text-dark-1 ">
                        {column}
                      </p>
                    </th>
                  );
                })}
              </tr>

              {fileUploaded?.rows?.map((row, index) => {
                const rowError = fileUploaded?.errors
                  ? fileUploaded?.errors[index]
                  : undefined;
                return (
                  <tr
                    key={v4()}
                    onClick={() => {
                      if (rowError) handleRowError(rowError);
                    }}
                    className={`${rowError ? 'cursor-pointer' : ''}`}
                  >
                    {fileUploaded?.nb_errors ? (
                      <td
                        style={
                          !row?.valid
                            ? {
                                backgroundColor: backgroundRowErrorArray(index),
                              }
                            : { backgroundColor: backgroundRowArray(index) }
                        }
                        className="sb-text-rem-caption1"
                      >
                        {!row?.valid && (
                          <p className="w-[6.25rem] py-[.25rem] lg:h-[2.5rem] flex justify-center items-center h-[1.5rem] overflow-hidden ">
                            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
                            <SearchIcon
                              fill="white"
                              width="1.5rem"
                              height="1.5rem"
                            />
                          </p>
                        )}
                      </td>
                    ) : (
                      <></>
                    )}
                    {fileUploaded?.columns?.map((column) => {
                      const el = getObjectKeyByValue(columnsKeys, column);
                      return (
                        <td
                          key={v4()}
                          style={
                            !row?.valid
                              ? {
                                  backgroundColor:
                                    backgroundRowErrorArray(index),
                                }
                              : { backgroundColor: backgroundRowArray(index) }
                          }
                          className="sb-text-rem-caption1"
                        >
                          <p
                            style={
                              row.row[el] === 'Donnée invalide' ||
                              row.row[el] === 'Donnée manquante' ||
                              row.row[el] === ' Donnée invalide' ||
                              row.row[el] ===
                                'CEE et MPR : Droits insuffisants' ||
                              row.row[el] === 'CEE seul : Droits insuffisants'
                                ? { border: '1px solid white' }
                                : { border: '0px solid white' }
                            }
                            className=" w-[15.5rem] py-[.25rem] lg:h-[2.5rem] flex justify-center items-center h-[1.5rem] overflow-hidden dark:text-white text-dark-1 "
                          >
                            {(el === 'montant_cee' && row.row[el]) ||
                            (el === 'montant_des_travaux_eur' &&
                              row.row[el]) ? (
                              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                              <>{numFormatSpace(row.row[el]!, true)}</>
                            ) : (
                              <> {row.row[el]}</>
                            )}
                          </p>
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </>
      )}
    </>
  );
}

export default ArrayFileXls;
