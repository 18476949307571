import { useContext } from 'react';

import { BulkImportContext } from '@bulkImport/utils/bulkImportContext';
import { WasSentToAirtable } from '@bulkImport/components/rightPart/wasSentToAirtable/WasSentToAirtableRightPart';
import ImportedXlsRightPart from './checkFileXlsIsOk/ImportedXlsRightPart';
import UploadDocumentsFolderRightPart from './uploadDocumentsFolder/UploadDocumentsFolderRightPart';
import ErrorWindow from './ErrorWindow';

function RightPartBulkImport(): JSX.Element {
  const {
    fileDetail,
    isUploaded,
    errorWindow,
    updateFileNameUploaded,
    updateIsUploaded,
    fileUploaded,
    updateIsErrorXLS,
    fileIsLoading,
    wasSentToAirtable,
  } = useContext(BulkImportContext);
  const handleCloseError = () => {
    updateIsUploaded(false);
    updateFileNameUploaded('');
    updateIsErrorXLS(false);
  };
  return (
    <div className="block lg:w-9/12 w-full pt-[2rem] h-full pb-[1.5rem]">
      <>
        {fileIsLoading && !isUploaded && fileUploaded === undefined && (
          <ImportedXlsRightPart />
        )}
        {/* SI FICHIER EN COURS D'IMPORTATION */}
        {isUploaded ? (
          <>{fileUploaded && <ImportedXlsRightPart />}</>
        ) : (
          <>
            {/* SI FICHIER IMPORTE */}
            {fileDetail !== undefined && <UploadDocumentsFolderRightPart />}
            {/* SI FICHIER ENVOYE A AIRTABLE */}
            {wasSentToAirtable && <WasSentToAirtable />}
          </>
        )}
      </>
      {errorWindow && !fileUploaded && (
        <ErrorWindow onClose={handleCloseError} />
      )}
      {errorWindow && fileUploaded && <ErrorWindow />}
    </div>
  );
}

export default RightPartBulkImport;
