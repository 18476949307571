/* eslint-disable no-restricted-globals */
import { Button, WarningNotification } from 'storybook-design-system-drapo';
import React, { useContext, useState, useEffect } from 'react';
import usePlacesAutocomplete from 'use-places-autocomplete';
import { InstallerContext } from '@auth/utils/authContext';
import { SimulatorContext } from '@simulator/utils/simulatorContext';

import { IWorksType } from '@simulator/utils/simulatorTypes';
import { nextStepGeneral } from '@simulator/utils/functions';
import AddressForm from '../../../../components/addressForm/AddressForm';

function WorksAddress(): JSX.Element {
  const {
    simulatorDatas,
    updateGraphGeneral,
    updateSimulatorDatas,
    updateLatLngData,
    updateCoordinates,
    updateMarkerMap,
    updateGraphList,
    graphGeneral,
    updateStepNumber,
    updateGeneralComplete,
    multipleOperations,
    isBack,
    updateIsBack,
  } = useContext(SimulatorContext);
  const { colorPrimary, radiusPx, colorAlertWhiteLabel, colorTextButton } =
    useContext(InstallerContext);

  const [choiceOk, setChoiceOk] = useState(false);
  const [worksStreet, setWorksStreet] = useState(
    isBack ? simulatorDatas.works.worksStreet : ''
  );
  const [worksStreetNumber, setWorksStreetNumber] = useState(
    isBack ? simulatorDatas.works.worksStreetNumber : ''
  );
  const [worksCity, setWorksCity] = useState(
    isBack ? simulatorDatas.works.worksCity : ''
  );
  const [worksZip, setWorksZip] = useState(
    isBack ? simulatorDatas.works.worksZip : ''
  );
  const [errorZip, setErrorZip] = useState(false);
  const [isManual, setIsManual] = useState<boolean>(false);

  const {
    value,
    suggestions: { data },
    setValue,
  } = usePlacesAutocomplete();

  useEffect(() => {
    const addressToPut = `${simulatorDatas.works.worksStreet}, ${simulatorDatas.works.worksCity}, France`;
    if (simulatorDatas.works.worksStreet && isBack) {
      setValue(addressToPut);
      setChoiceOk(true);
    }
  }, []);

  const onClickNext = () => {
    const works: IWorksType = {
      worksType: { activite: 'residentiel' },
      worksStreet,
      worksStreetNumber,
      worksZip,
      worksCity,
    };

    updateIsBack(false);

    if (worksZip.length !== 5) {
      setErrorZip(true);
    } else {
      nextStepGeneral(
        simulatorDatas,
        multipleOperations,
        updateGeneralComplete,
        updateStepNumber,
        updateSimulatorDatas,
        updateGraphGeneral,
        updateGraphList,
        graphGeneral,
        'works',
        works
      );
    }
  };

  return (
    <div>
      <div className="mt-[1.5rem] adress-container">
        {errorZip && !isManual && (
          <WarningNotification
            addClass="mt-[1.25rem]"
            backgroundColor={colorAlertWhiteLabel}
            color="white"
            warningText="Le code postal doit contenir 5 chiffres."
          />
        )}
        <AddressForm
          data={data}
          value={value}
          setValue={setValue}
          setCity={setWorksCity}
          setStreet={setWorksStreet}
          setWorksStreetNumber={setWorksStreetNumber}
          setZip={setWorksZip}
          updateIsBack={updateIsBack}
          updateCoordinates={updateCoordinates}
          updateLatLngData={updateLatLngData}
          updateMarkerMap={updateMarkerMap}
          choiceOk={choiceOk}
          setChoiceOk={setChoiceOk}
          isWorkAddress
          street={worksStreet}
          zip={worksZip}
          city={worksCity}
          setNumberStreet={setWorksStreetNumber}
          setIsManual={setIsManual}
        />
      </div>
      {!isManual && (
        <div className="w-full mt-[1.5rem]">
          <Button
            borderRadius={radiusPx}
            buttonArrowSmall
            disabled={!choiceOk}
            label="Suivant"
            color={colorTextButton}
            backgroundColor={colorPrimary}
            arrow
            addClass="!w-full !px-[1rem]"
            onClick={onClickNext}
            dataTestId="next"
          />
        </div>
      )}
    </div>
  );
}

export default WorksAddress;
