import { trash, trash_black } from '@assets/images/images';
import { DashboardContext } from '@dashboard/utils/context/dashboardContext';
import { useContext, useEffect, useState } from 'react';
import { v4 } from 'uuid';
import {
  arrayChoices,
  changeConditionsCustomViewFilters,
} from '@dashboard/utils/functions';
import { ChevronIcon } from '@assets/svgComponents/svgComponents';
import { IConditions, IinitialOperator } from '../../../utils/dashboardTypes';
import { InstallerContext } from '../../../../auth/utils/authContext';
import { arrayChoicesMultiple } from '../../../utils/functions';

interface IProps {
  datas: IConditions;
  index: number;
}

function OneCondition({ datas, index }: IProps): JSX.Element {
  const providerTypeDate = !!(
    datas.provider_name === 'Date demande subvention' ||
    datas.provider_name === 'Date engagement opération' ||
    datas.provider_name === 'Date demande solde'
  );

  const providerTypeCurrency = !!(
    datas.provider_name === 'Montant TTC devis' ||
    datas.provider_name === 'Prime CEE bénéficiaire' ||
    datas.provider_name === 'Prime CEE installateur' ||
    datas.provider_name === 'Montant MPR géré par Drapo' ||
    datas.provider_name === 'Virement MPR pro'
  );

  const providerTypeSingleText = !!(
    datas.provider_name === 'Référence dossier' ||
    datas.provider_name === 'Nom client' ||
    datas.provider_name === 'Prénom client' ||
    datas.provider_name === 'Adresse travaux' ||
    datas.provider_name === 'Commentaires risques MPR'
  );

  const conditionName = (data: string) => {
    switch (data) {
      case '=':
        if (providerTypeCurrency) {
          return '=';
        }
        if (providerTypeSingleText) {
          return 'est';
        }
        return 'est égale à';
      case '<>':
        return 'est différent de';
      case '>=':
        if (providerTypeCurrency) {
          return '>=';
        }
        return 'est supérieure ou égale à';
      case '>':
        if (providerTypeDate) {
          return 'est après';
        }
        if (providerTypeCurrency) {
          return '>';
        }
        return 'est strictement supérieure à';
      case '<=':
        if (providerTypeCurrency) {
          return '<=';
        }
        return 'est inférieure ou égale à';
      case '<':
        if (providerTypeDate) {
          return 'est avant';
        }
        if (providerTypeCurrency) {
          return '<';
        }
        return 'est strictement inférieure à';
      case 'ILIKE':
        return 'contient';
      case 'NOT ILIKE':
        return 'ne contient pas';
      case 'EMPTY':
        return 'est vide';
      case 'NOT EMPTY':
        return "n'est pas vide";
      case 'BETWEEN':
        return 'compris entre';
      case 'IN':
        return 'est égale à';
      case 'NOT IN':
        return 'est différent de';
      case 'IS NULL':
        return 'est vide';
      case 'IS NOT NULL':
        return "n'est pas vide";
      default:
        return '';
    }
  };

  const [valueName, setValueName] = useState(
    datas.provider_name !== '' ? datas.provider_name : 'Choisir une colonne'
  );
  const [nameCondition, setNameCondition] = useState(
    datas.condition !== '' ? conditionName(datas.condition) : ''
  );
  const [valueCondition, setValueCondition] = useState(
    datas.condition !== '' ? datas.condition : ''
  );
  const [valueValue, setValueValue] = useState<string | number>(
    datas.value !== 0 ? datas.value : ''
  );
  const [valueDateBetweenValue, setValueDateBetweenValue] = useState<string>(
    datas.value !== 0 && typeof datas.value === 'string'
      ? datas.value.substring(0, 10)
      : ''
  );

  const [valueDateBetweenValueEnd, setValueDateBetweenValueEnd] = useState<
    string | number
  >(
    datas.value !== 0 && typeof datas.value === 'string'
      ? datas.value.substring(12, 22)
      : ''
  );
  const [valueId, setValueId] = useState(datas.provider_id);

  const [isSelectColumn, setIsSelectColumn] = useState(false);
  const [isSelectCondition, setIsSelectCondition] = useState(false);
  const [renderInput, setRenderInput] = useState(<div />);

  const [initialOperators, setIntitialOperators] = useState<IinitialOperator[]>(
    []
  );

  const {
    currentSchemaFolderCustomView,
    schemaFoldersCustomView,
    updateNewConditions,
    newConditions,
    updateIsDisabledFiltersCustomView,
  } = useContext(DashboardContext);

  const { radiusPx, isThemeDark } = useContext(InstallerContext);

  const radius = radiusPx === '0px' ? '0px' : '8px';
  useEffect(() => {
    changeConditionsCustomViewFilters(
      valueName,
      nameCondition,
      setNameCondition,
      setIntitialOperators,
      setRenderInput,
      radius,
      valueValue,
      setValueValue,
      valueCondition,
      valueDateBetweenValue,
      setValueDateBetweenValue,
      valueDateBetweenValueEnd,
      setValueDateBetweenValueEnd,
      setValueCondition,
      datas,
      arrayChoicesMultiple(datas, schemaFoldersCustomView),
      arrayChoices(datas, schemaFoldersCustomView)
    );

    if (
      valueName !== 'Type information préalable/retrait' &&
      valueName !== 'Type retour instructeur MPR' &&
      valueName !== 'Libellé opération'
    ) {
      for (let i = 0; i < newConditions.length; i += 1) {
        if (
          valueName === '' ||
          valueName === 'Choisir une colonne' ||
          valueCondition === '' ||
          valueValue === ''
        ) {
          if (
            valueCondition === 'IS NULL' ||
            valueCondition === 'IS NOT NULL' ||
            valueCondition === 'EMPTY' ||
            valueCondition === 'NOT EMPTY'
          ) {
            if (valueName === '' || valueName === 'Choisir une colonne') {
              updateIsDisabledFiltersCustomView(true);
            } else {
              updateIsDisabledFiltersCustomView(false);
            }
          } else {
            updateIsDisabledFiltersCustomView(true);
          }
        } else {
          updateIsDisabledFiltersCustomView(false);
        }
      }
    }
    const newDatas = newConditions;
    newDatas[index].provider_name = valueName;
    newDatas[index].condition = valueCondition;
    if (providerTypeCurrency) {
      newDatas[index].value = Number(valueValue);
    } else if (valueCondition === 'BETWEEN') {
      newDatas[
        index
      ].value = `${valueDateBetweenValue}||${valueDateBetweenValueEnd}`;
    } else {
      newDatas[index].value = valueValue;
    }
    newDatas[index].provider_id = valueId;
    updateNewConditions(newDatas);
  }, [
    valueValue,
    valueName,
    valueCondition,
    valueDateBetweenValueEnd,
    valueDateBetweenValue,
  ]);

  return (
    <div className="flex items-center justify-between">
      <div className="flex items-center space-x-[1rem] pb-[1rem]">
        <p className="text-[.875rem] font-normal">La colonne</p>
        <div className="relative">
          <button
            style={{ borderRadius: radius }}
            className="text-[.75rem] font-medium border dark:border-white dark:border-opacity-[15%]  flex items-center justify-between py-[.375rem] px-[.625rem] w-[20rem]"
            type="button"
            onClick={() => {
              setIsSelectColumn(!isSelectColumn);
            }}
          >
            {valueName}
            <ChevronIcon
              width="1.5rem"
              height="1.5rem"
              fill={isThemeDark ? 'white' : '#191919'}
              transform={isSelectColumn ? 'rotate(-90)' : 'rotate(90)'}
              className="transition-transform duration-300"
            />
          </button>

          {isSelectColumn && (
            <div
              style={{
                zIndex: 9999999999,
                borderBottomLeftRadius: radius,
                borderBottomRightRadius: radius,
              }}
              className="text-[.75rem] font-medium absolute w-[20rem] mt-[-.375rem] p-[.625rem] border border-white border-opacity-[15%] border-t-transparent dark:bg-[#1C1E1F] bg-white"
            >
              {currentSchemaFolderCustomView.schema.fields
                .filter((el) => el.provider_name !== 'Accéder dossier')
                .map((el: any) => {
                  return (
                    <div
                      key={v4()}
                      className="flex flex-col dark:bg-[#1C1E1F] bg-white hover:opacity-90 hover:scale-105 hover:ml-[.375rem]"
                    >
                      <button
                        className="text-left"
                        type="button"
                        onClick={() => {
                          setValueName(el.provider_name);
                          setValueId(el.provider_id);
                          setIsSelectColumn(false);
                        }}
                      >
                        {el.provider_name}
                      </button>
                    </div>
                  );
                })}
            </div>
          )}
        </div>
        <div className="relative text-[.75rem] font-medium">
          <button
            style={{ borderRadius: radius }}
            className="text-[.75rem] font-medium border dark:border-white dark:border-opacity-[15%] flex items-center justify-between py-[.375rem] px-[.625rem] w-[16rem]"
            type="button"
            onClick={() => setIsSelectCondition(!isSelectCondition)}
          >
            {nameCondition || 'Choisir une condition'}

            <ChevronIcon
              width="1.5rem"
              height="1.5rem"
              fill={isThemeDark ? 'white' : '#191919'}
              transform={isSelectCondition ? 'rotate(-90)' : 'rotate(90)'}
              className="transition-transform duration-300"
            />
          </button>
          {isSelectCondition && (
            <div
              style={{
                zIndex: 9999999999,
                borderBottomLeftRadius: radius,
                borderBottomRightRadius: radius,
              }}
              className="text-[.75rem] font-medium absolute w-[16rem] mt-[-.375rem] p-[.625rem] border dark:border-white dark:border-opacity-[15%] dark:border-t-transparent dark:bg-[#1C1E1F] bg-white"
            >
              {initialOperators.map((el) => {
                return (
                  <div key={v4()} className="flex flex-col">
                    <button
                      className="text-[.75rem] font-medium text-left hover:opacity-90 hover:scale-105 hover:ml-[.375rem]"
                      type="button"
                      onClick={() => {
                        setNameCondition(el.name);
                        setValueCondition(el.value);
                        setIsSelectCondition(false);
                      }}
                    >
                      {el.name}
                    </button>
                  </div>
                );
              })}
            </div>
          )}
        </div>
        {renderInput}
      </div>
      <button
        type="button"
        onClick={() =>
          newConditions.length > 1
            ? updateNewConditions(newConditions.filter((el, i) => i !== index))
            : null
        }
      >
        {newConditions.length > 1 && (
          <img
            className="w-[1.5rem] mt-[-1rem] ml-[.8rem]"
            src={isThemeDark ? trash : trash_black}
            alt="supprimer"
          />
        )}
      </button>
    </div>
  );
}

export default OneCondition;
