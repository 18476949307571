import { useContext, useEffect, useRef, useState } from 'react';
import { Title } from 'storybook-design-system-drapo';
import { useNavigate, useParams } from 'react-router-dom';
import Header from '@components/menu/Header';
import { FolderContext } from '@folder/utils/FoldersContext';
import {
  IFileFolderType,
  ITaxHousehold,
  IFolderDatasType,
} from '@folder/utils/folderTypes';
import { intialFolderDatas } from '@folder/utils/initialsState';
import { getFolder } from '@folder/apiRequests/folderRequests';
import RenderStepFolder from '@folder/components/RenderStepFolder';
import RightPartCustomerInformation from '@folder/components/rightPart/RightPartCustomerInformation';
import BackStepButton from '@folder/components/BackStepButton';
import NextStepButton from '@folder/components/NextStepButton';
import LoaderFolderLeft from '@components/loaders/LoaderFolderLeft';
import LoaderFolderRight from '@components/loaders/LoaderFolderRight';
import RightPartUploadFiles from '@folder/components/rightPart/RightPartUploadFiles';
import RightPartInfosFolder from '@folder/components/rightPart/RightPartInfosFolder';
import RightPartDimensioningNote from '@folder/components/rightPart/RightPartDimensioningNote';
import { IKeyValueStringType } from '@simulator/utils/simulatorTypes';
import { InstallerContext } from '@auth/utils/authContext';
import { useMediaQuery } from 'react-responsive';
import { colorTextLightMode, dark4, white } from '@assets/colors';

function Folder(): JSX.Element {
  const folderReference: string = useParams().folderReference || '';
  const router = useNavigate();

  const littlePaddingAppContainer = useMediaQuery({ maxWidth: 1280 });

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [stepNumberFolder, setStepNumberFolder] = useState(1);
  const [title, setTitle] = useState('');
  const [subtitle, setSubtitle] = useState('');
  const [housingOwner, setHousingOwner] = useState('');

  const [parcelPrefix, setParcelPrefix] = useState('');
  const [parcelSection, setParcelSection] = useState('');
  const [parcelNumber, setParcelNumber] = useState('');
  const [hasCadastre, setHasCadastre] = useState<boolean>(false);
  const [hasIndivision, setHasIndivision] = useState<boolean>(false);
  const [hasDimensioningNote, setHasDimensioningNote] =
    useState<boolean>(false);

  const [menageIndivision, setMenageIndivision] = useState(false);

  const [householdsDatas, setHouseholdsDatas] = useState<ITaxHousehold[]>([
    {
      /* numero: '',
      reference: '', */
      declarant: '',
    },
  ]);
  const [folderDatas, setFolderDatas] =
    useState<IFolderDatasType>(intialFolderDatas);

  const [genderClient, setGenderClient] = useState('Monsieur');
  const [ownerStreet, setOwnerStreet] = useState('');
  const [ownerZipcode, setOwnerZipcode] = useState('');
  const [ownerCity, setOwnerCity] = useState('');

  const [mprAgree, setMprAgree] = useState(true);
  const [subcontractorAgree, setSubcontractorAgree] = useState(true);
  const [globalFiles, setGlobalFiles] = useState<[] | IFileFolderType[]>([]);

  const [focusCustomerInfo, setFocusCustomerInfo] = useState(true);
  const [focusCadastre, setFocusCadastre] = useState(false);
  const [focusTax, setFocusTax] = useState(false);

  const [workAddress, setWorkAddress] = useState('');
  const [workCoordinates, setWorkCoordinates] = useState<IKeyValueStringType>({
    latitude: '0',
    longitude: '0',
  });
  const [numberHousehold, setNumberHousehold] = useState(1);

  const [roomsHeight, setRoomsHeight] = useState<string>('2.5');
  const [stopTempHeatPump, setStopTempHeatPump] = useState<string>('');
  const [tempPowerHeatPump, setTempPowerHeatPump] = useState<string>('');
  const [heatingElementPower, setHeatingElementPower] = useState<string>('');
  const [otherHeatingSystems, setOtherHeatingSystems] = useState<string>('');
  const [dimensioningNote, setDimensioningNote] = useState<string>('');
  const [showDimensioningNote, setShowDimensioningNote] =
    useState<boolean>(false);
  const [housingValue, setHousingValue] = useState<IKeyValueStringType[]>([
    {
      title: 'altitude',
      value: '',
    },
    {
      title: 'temperature_mode',
      value: '',
    },
    {
      title: 'emitter_type',
      value: '',
    },
    {
      title: 'construction_type',
      value: '',
    },
  ]);
  const [hasDimensioningNotePro, setHasDimensioningNotePro] =
    useState<boolean>(false);
  const [dimensioningNotePro, setDimensioningNotePro] = useState<
    File | undefined
  >();

  const [thermalInsulationIsCheck, setThermalInsulationIsCheck] =
    useState<boolean>(false);
  const [
    modalThermalInsulationAlreadyClosed,
    setModalThermalInsulationAlreadyClosed,
  ] = useState<boolean>(false);

  const [folderFinded, setFolderFinded] = useState<boolean>(false);
  const [redirection, setRedirection] = useState<boolean>(false);
  const [isBack, setIsBack] = useState<boolean>(false);
  const [clientInfosAlreadySet, setClientInfosAlreadySet] =
    useState<boolean>(false);

  const [folderInCreation, setFolderInCreation] = useState<boolean>(false);

  const folderContextValue = {
    folderDatas,
    updateFolderDatas: setFolderDatas,
    folderReference,
    housingOwner,
    stepNumberFolder,
    updateStepNumberFolder: setStepNumberFolder,
    title,
    subtitle,
    updateTitle: setTitle,
    updateSubtitle: setSubtitle,
    parcelPrefix,
    updateParcelPrefix: setParcelPrefix,
    parcelSection,
    updateParcelSection: setParcelSection,
    parcelNumber,
    updateParcelNumber: setParcelNumber,
    genderClient,
    updateGenderClient: setGenderClient,
    ownerStreet,
    updateOwnerStreet: setOwnerStreet,
    ownerZipcode,
    updateOwnerZipcode: setOwnerZipcode,
    ownerCity,
    updateOwnerCity: setOwnerCity,
    householdsDatas,
    updateHouseholdsDatas: setHouseholdsDatas,
    mprAgree,
    updateMprAgree: setMprAgree,
    subcontractorAgree,
    updateSubcontractorAgree: setSubcontractorAgree,
    globalFiles,
    updateGlobalFiles: setGlobalFiles,
    updateHousingOwner: setHousingOwner,
    focusCustomerInfo,
    updateFocusCustomerInfo: setFocusCustomerInfo,
    focusCadastre,
    updateFocusCadastre: setFocusCadastre,
    focusTax,
    updateFocusTax: setFocusTax,
    menageIndivision,
    updateMenageIndivision: setMenageIndivision,
    hasCadastre,
    updateHasCadastre: setHasCadastre,
    hasIndivision,
    updateHasIndivision: setHasIndivision,
    workAddress,
    workCoordinates,
    isBack,
    updateIsBack: setIsBack,
    numberHousehold,
    updateNumberHousehold: setNumberHousehold,
    clientInfosAlreadySet,
    updateClientInfosAlreadySet: setClientInfosAlreadySet,
    folderInCreation,
    updateFolderInCreation: setFolderInCreation,
    hasDimensioningNote,
    updateHasDimensioningNote: setHasDimensioningNote,
    roomsHeight,
    updateRoomsHeight: setRoomsHeight,
    stopTempHeatPump,
    updateStopTempHeatPump: setStopTempHeatPump,
    tempPowerHeatPump,
    updateTempPowerHeatPump: setTempPowerHeatPump,
    heatingElementPower,
    updateHeatingElementPower: setHeatingElementPower,
    otherHeatingSystems,
    updateOtherHeatingSystems: setOtherHeatingSystems,
    dimensioningNote,
    updateDimensioningNote: setDimensioningNote,
    showDimensioningNote,
    updateShowDimensioningNote: setShowDimensioningNote,
    housingValue,
    updateHousingValue: setHousingValue,
    hasDimensioningNotePro,
    updateHasDimensioningNotePro: setHasDimensioningNotePro,
    dimensioningNotePro,
    updateDimensioningNotePro: setDimensioningNotePro,
    thermalInsulationIsCheck,
    updateThermalInsulationIsCheck: setThermalInsulationIsCheck,
    modalThermalInsulationAlreadyClosed,
    updateModalThermalInsulationAlreadyClosed:
      setModalThermalInsulationAlreadyClosed,
  };

  const leftContainer = useRef<HTMLInputElement>(null);
  const rightContainer = useRef<HTMLInputElement>(null);
  const titleHeight = useRef<HTMLDivElement>(null);

  const [divLeftWidth, setDivLeftWidth] = useState<number>();
  const [divRightWidth, setDivRightWidth] = useState<number>();
  const [headerContainerHeight, setHeaderContainerHeight] = useState<number>(0);

  const [fileToShow, setFileToShow] = useState<string | undefined>();
  const [typeImage, setTypeImage] = useState<boolean>(false);

  const [divActive, setDivActive] = useState<string | undefined>();
  const [rightPartActive, setRightPartActive] = useState<boolean>(true);

  const [hasInfosMaterials, setHasInfosMaterial] = useState<boolean>(true);

  const { radiusPx, isThemeDark } = useContext(InstallerContext);

  const rightContent = () => {
    let block: JSX.Element = <></>;
    if (stepNumberFolder === 1) {
      block = (
        <RightPartCustomerInformation
          divActive={divActive}
          divRightWidth={divRightWidth}
        />
      );
    } else if (stepNumberFolder === 2) {
      block = (
        <RightPartInfosFolder
          divRightWidth={divRightWidth}
          divActive={divActive}
          hasInfosMaterials={hasInfosMaterials}
        />
      );
    } else if (
      (stepNumberFolder === 3 && !hasDimensioningNote) ||
      stepNumberFolder === 4
    ) {
      block = (
        <RightPartUploadFiles
          fileToShow={fileToShow}
          divRightWidth={divRightWidth}
          typeImage={typeImage}
          rightPartActive={rightPartActive}
        />
      );
    } else {
      block = <RightPartDimensioningNote divRightWidth={divRightWidth} />;
    }
    return block;
  };

  const getSize = () => {
    if (leftContainer && leftContainer.current) {
      const newWidth = leftContainer.current.clientWidth;

      setDivLeftWidth(newWidth);
    }
    if (rightContainer && rightContainer.current) {
      const newWidth = rightContainer.current.clientWidth;

      setDivRightWidth(newWidth);
    }
  };

  const promptGetFolder = async () => {
    await getFolder(
      folderReference,
      setFolderDatas,
      setHousingOwner,
      setStepNumberFolder,
      setGlobalFiles,
      setParcelPrefix,
      setParcelSection,
      setParcelNumber,
      setHouseholdsDatas,
      setFolderFinded,
      setRedirection,
      setMenageIndivision,
      setHasCadastre,
      setWorkAddress,
      setWorkCoordinates,
      setClientInfosAlreadySet,
      setHasIndivision,
      setHasDimensioningNote,
      setRoomsHeight,
      setStopTempHeatPump,
      setTempPowerHeatPump,
      setHeatingElementPower,
      setOtherHeatingSystems,
      setDimensioningNote,
      setShowDimensioningNote,
      setHousingValue
    );
  };

  useEffect(() => {
    window.addEventListener('resize', getSize);
    window.scrollTo(0, 0);

    getSize();
    promptGetFolder();
  }, []);

  useEffect(() => {
    if (folderFinded) {
      setIsLoading(false);
    }
  }, [folderFinded]);

  useEffect(() => {
    if (redirection) {
      router('/', { replace: true });
    }
  }, [redirection]);

  const checkIfDisplayNextButton = () => {
    if (
      (stepNumberFolder === 3 && !hasDimensioningNote) ||
      (stepNumberFolder === 3 &&
        hasDimensioningNote &&
        folderDatas.status === 'Note de dimensionnement') ||
      stepNumberFolder === 4
    ) {
      return false;
    }
    switch (folderDatas.status) {
      case 'Bénéficiaire':
        return false;
      case 'Dépose documents avant travaux':
        return true;
      case 'Indivision':
        return true;
      case 'Note de dimensionnement':
        return true;
      default:
        return stepNumberFolder === 1;
    }
  };

  return (
    <FolderContext.Provider value={folderContextValue}>
      <div className="lg:flex justify-between lg:space-x-[5rem]">
        <div className="lg:w-6/12 mx-auto pb-[2.5]">
          <Header
            leftContainer={divLeftWidth}
            hideProgressBar={isLoading}
            setHeaderContainerHeight={setHeaderContainerHeight}
          />
          <div
            ref={leftContainer}
            style={{
              marginTop: `calc(${headerContainerHeight}px - ${
                littlePaddingAppContainer ? '2vh' : '5vh'
              })`,
            }}
          >
            {isLoading ? (
              <LoaderFolderLeft />
            ) : (
              <>
                <div
                  className={`flex w-full ${
                    stepNumberFolder === 1 ? 'justify-end' : 'justify-between'
                  }`}
                >
                  {stepNumberFolder > 1 && !folderInCreation && (
                    <BackStepButton />
                  )}
                  {checkIfDisplayNextButton() && <NextStepButton />}
                </div>
                <div ref={titleHeight}>
                  <Title
                    borderRadius={radiusPx}
                    color={isThemeDark ? white : colorTextLightMode}
                    colorSubtitle={isThemeDark ? '#C6C6C6' : colorTextLightMode}
                    backgroundColor={isThemeDark ? dark4 : white}
                    title={title}
                    width="70%"
                    subtitle={subtitle}
                    labelButton=""
                  />
                </div>
                <div>
                  <RenderStepFolder
                    setFileToShow={setFileToShow}
                    setDivActive={setDivActive}
                    setTypeImage={setTypeImage}
                    setRightPartActive={setRightPartActive}
                    setHasInfosMaterials={setHasInfosMaterial}
                  />
                </div>
              </>
            )}
          </div>
        </div>
        <div
          style={{ borderRadius: radiusPx }}
          ref={rightContainer}
          className="lg:block mx-auto hidden lg:w-6/12 static h-90-vh z-[22222] pt-[1.25rem]"
        >
          {isLoading ? <LoaderFolderRight /> : rightContent()}
        </div>
      </div>
    </FolderContext.Provider>
  );
}

export default Folder;
