import React, { Dispatch, SetStateAction } from 'react';
import {
  IDataCurrentCustomView,
  ICountsCurrentCustomView,
  IConditions,
  IFinancial,
  IStatus,
  ISchemaFolder,
  ICurrentCustomView,
  ICurrentSchemaCustomView,
  IQuotaCee,
  ICountsFolders,
  IListFolders,
} from '../dashboardTypes';

interface IProps {
  elementActive: string;
  updateElementActive: Dispatch<SetStateAction<string>>;
  financialsIsLoaded: boolean;
  updateFinancialsIsLoaded: Dispatch<SetStateAction<boolean>>;
  financialsCee: IFinancial;
  updateFinancialsCee: Dispatch<SetStateAction<IFinancial>>;
  financialsMpr: IFinancial;
  updateFinancialsMpr: Dispatch<SetStateAction<IFinancial>>;
  statusIsLoaded: boolean;
  updateStatusIsLoaded: Dispatch<SetStateAction<boolean>>;
  isLoadedFolders: boolean;
  updateIsLoadedFolders: Dispatch<SetStateAction<boolean>>;
  statusCee: IStatus;
  updateStatusCee: Dispatch<SetStateAction<IStatus>>;
  statusMpr: IStatus;
  updateStatusMpr: Dispatch<SetStateAction<IStatus>>;
  mprStatusFilter: string[];
  updateMprStatusFilter: Dispatch<SetStateAction<string[]>>;
  ceeStatusFilter: string[];
  updateCeeStatusFilter: Dispatch<SetStateAction<string[]>>;
  isModalPersonalizationFolders: boolean;
  updateIsModalPersonalizationFolders: Dispatch<SetStateAction<boolean>>;
  countsFolders: ICountsFolders;
  updateCountsFolders: Dispatch<SetStateAction<ICountsFolders>>;
  rowsSelectedFolders: IListFolders[];
  updateRowsSelectedFolders: Dispatch<SetStateAction<IListFolders[]>>;
  isDeleteFolderLoading: boolean;
  updateIsDeleteFolderLoading: Dispatch<SetStateAction<boolean>>;
  isInAirtable: boolean;
  updateIsInairtable: Dispatch<SetStateAction<boolean>>;
  isModalDeleteFolder: boolean;
  updateIsModalDeleteFolder: Dispatch<SetStateAction<boolean>>;
  previousPage: string;
  updatePreviousPage: Dispatch<SetStateAction<string>>;
  activeCustomView: boolean;
  updateActiveCustomView: Dispatch<SetStateAction<boolean>>;
  nameCurrentListCustomView: string;
  updateNameCurrentListCustomView: Dispatch<SetStateAction<string>>;
  idCurrentListCustomView: string;
  updateIdCurrentListCustomView: Dispatch<SetStateAction<string>>;
  rowsSelectedCustomView: IDataCurrentCustomView[];
  updateRowsSelectedCustomView: Dispatch<
    SetStateAction<IDataCurrentCustomView[]>
  >;
  filtersOrPersonalizationModalCustomView: string;
  updateFiltersOrPersonalizationModalCustomView: Dispatch<
    SetStateAction<string>
  >;
  isCreateNewListCustomView: boolean;
  updateIsCreateNewListCustomView: Dispatch<SetStateAction<boolean>>;
  schemaFoldersCustomView: ISchemaFolder;
  updateSchemaFoldersCustomView: Dispatch<SetStateAction<ISchemaFolder>>;
  listSchemasCustomView: ICurrentSchemaCustomView[];
  updateListSchemasCustomView: Dispatch<
    SetStateAction<ICurrentSchemaCustomView[]>
  >;
  listVisibleColumnsCustomView: ISchemaFolder;
  updateListVisibleColumnsCustomView: Dispatch<SetStateAction<ISchemaFolder>>;
  listInvisibleColumnsCustomView: ISchemaFolder;
  updateListInvisibleColumnsCustomView: Dispatch<SetStateAction<ISchemaFolder>>;
  isLoadingCustomView: boolean;
  updateIsLoadingCustomView: Dispatch<SetStateAction<boolean>>;
  currentListCustomView: ICurrentCustomView | undefined;
  updateCurrentListCustomView: Dispatch<
    SetStateAction<ICurrentCustomView | undefined>
  >;
  currentSchemaFolderCustomView: ICurrentSchemaCustomView;
  updateCurrentSchemaFolderCustomView: Dispatch<
    SetStateAction<ICurrentSchemaCustomView>
  >;
  numberPageList: number;
  updateNumberPageList: Dispatch<SetStateAction<number>>;
  newConditions: IConditions[];
  updateNewConditions: Dispatch<SetStateAction<IConditions[]>>;
  orOrAndName: string;
  updateOrOrAndName: Dispatch<SetStateAction<string>>;
  isDisabledFiltersCustomView: boolean;
  updateIsDisabledFiltersCustomView: Dispatch<SetStateAction<boolean>>;
  countsCustomListView: ICountsCurrentCustomView;
  updateCountsCustomListView: Dispatch<
    SetStateAction<ICountsCurrentCustomView>
  >;
  quotaCee: IQuotaCee | undefined;
  updateQuotaCee: Dispatch<SetStateAction<IQuotaCee | undefined>>;
}

export const DashboardContext = React.createContext({} as IProps);
