import { Dispatch, SetStateAction, useContext } from 'react';
import { Input } from 'storybook-design-system-drapo';
import { FolderContext } from '@folder/utils/FoldersContext';
import { ITaxHousehold } from '@folder/utils/folderTypes';
import InfosButton from '@folder/components/InfosButton';
import { InstallerContext } from '@auth/utils/authContext';
import {
  backgroundQuaternaryLightMode,
  dark1,
  darkVariant1,
  white,
} from '@assets/colors';

interface HouseholdCardProps {
  item: ITaxHousehold;
  setHouseholdsDatas: Dispatch<SetStateAction<ITaxHousehold[]>>;
  index: number;
  firstNameClient: string;
  lastNameClient: string;
  /* setErrorsTaxReference: Dispatch<SetStateAction<string[]>>;
  setErrorsTaxNumber: Dispatch<SetStateAction<string[]>>;
  errorsTaxReference: string[];
  errorsTaxNumber: string[];
  setIsErrorTax: Dispatch<SetStateAction<boolean>>;
  setTaxReferenceYearNMinus2: Dispatch<SetStateAction<boolean[]>>; */
  setDivActive: Dispatch<SetStateAction<string | undefined>>;
  setCheckGrantNotification: Dispatch<SetStateAction<boolean>>;
  checkGrantNotification: boolean;
}
function HouseholdCard({
  item,
  setHouseholdsDatas,
  index,
  firstNameClient,
  lastNameClient,
  /* setErrorsTaxReference,
  setErrorsTaxNumber,
  errorsTaxReference,
  errorsTaxNumber,
  setIsErrorTax,
  setTaxReferenceYearNMinus2, */
  setDivActive,
  setCheckGrantNotification,
  checkGrantNotification,
}: HouseholdCardProps): JSX.Element {
  const { updateFocusCustomerInfo, updateFocusTax, updateFocusCadastre } =
    useContext(FolderContext);

  const { radiusPx, isThemeDark } = useContext(InstallerContext);

  /* useEffect(() => {
    if (isBack) {
      setErrorsTaxNumber((oldErrors) => {
        const newErrors = oldErrors;
        newErrors[index] = '';
        return newErrors;
      });
      setErrorsTaxReference((oldErrors) => {
        const newErrors = oldErrors;
        newErrors[index] = '';
        return newErrors;
      });
    }
  }, []); */

  const onChangeDatas = (fieldName: string, value: string) => {
    /* setIsErrorTax(false);
    switch (fieldName) {
      default:
      case 'numero':
        setErrorsTaxNumber((oldErrors) => {
          const newErrors = oldErrors;
          newErrors[index] = '';
          return newErrors;
        });
        break;
      case 'reference':
        setErrorsTaxReference((oldErrors) => {
          const newErrors = oldErrors;
          newErrors[index] = '';
          return newErrors;
        });
        setTaxReferenceYearNMinus2((oldValues) => {
          const newValues = oldValues;
          newValues[index] = false;
          return newValues;
        });
        break;
    } */
    setHouseholdsDatas((oldHouseholdDatas) => {
      const newHouseholdDatas: ITaxHousehold[] = [...oldHouseholdDatas];
      newHouseholdDatas[index][fieldName] = value;
      return newHouseholdDatas;
    });
  };

  const updateInfoRightPart = () => {
    updateFocusTax(true);
    updateFocusCustomerInfo(false);
    updateFocusCadastre(false);
  };

  return (
    <>
      <div
        style={{ borderRadius: radiusPx }}
        className="dark:bg-dark-2 bg-[#ffffff] p-[1.5rem]  mt-[2rem]"
      >
        <div className="flex items-center justify-between mb-[1.5rem]">
          <p className="sb-text-rem-body2 dark:text-white text-colorTextLightMode">
            Foyer fiscal N° {index + 1}
          </p>
          <InfosButton
            forcedDivActive="TaxHouseholdCustomerInformation"
            setDivActive={setDivActive}
          />
        </div>
        <Input
          borderRadius={radiusPx}
          onClick={() => updateInfoRightPart()}
          label="Nom de la personne déclarante"
          color={isThemeDark ? white : dark1}
          backgroundColor={
            isThemeDark ? darkVariant1 : backgroundQuaternaryLightMode
          }
          inputType="text"
          onChange={(e) => {
            onChangeDatas('declarant', e.target.value);
            if (checkGrantNotification) {
              setCheckGrantNotification(false);
            }
          }}
          value={
            index === 0
              ? `${firstNameClient} ${lastNameClient}`
              : item.declarant
          }
          readOnly={index === 0}
          addClass="mb-[.875rem]"
        />
        {/* <Input
          borderRadius={radiusPx}
          onClick={() => updateInfoRightPart()}
          label="Numéro fiscal (13 caractères)"
          color={isThemeDark ? white : dark1}
          backgroundColor={
            isThemeDark ? darkVariant1 : backgroundQuaternaryLightMode
          }
          inputType="text"
          isNumber
          onChange={(e) => {
            onChangeDatas('numero', e.target.value);
            if (checkGrantNotification) {
              setCheckGrantNotification(false);
            }
          }}
          value={item.numero}
          addClass={
            errorsTaxNumber[index] !== ''
              ? 'border border-red-500'
              : 'mb-[.875rem]'
          }
        />
        {errorsTaxNumber[index] !== '' && (
          <p className="text-[.75rem] text-red-500 mt-[.25rem] mb-[.875rem] ml-[.5rem]">
            {errorsTaxNumber[index]}
          </p>
        )}
        <Input
          borderRadius={radiusPx}
          onClick={() => updateInfoRightPart()}
          label="Référence de l'avis (13 ou 14 caractères)"
          color={isThemeDark ? white : dark1}
          backgroundColor={
            isThemeDark ? darkVariant1 : backgroundQuaternaryLightMode
          }
          inputType="text"
          onChange={(e) => {
            onChangeDatas('reference', e.target.value);
            if (checkGrantNotification) {
              setCheckGrantNotification(false);
            }
          }}
          value={item.reference}
          addClass={
            errorsTaxReference[index] !== '' ? 'border border-red-500' : ''
          }
        />
        {errorsTaxReference[index] !== '' && (
          <p className="text-[.75rem] text-red-500 mt-[.25rem] ml-[.5rem]">
            {errorsTaxReference[index]}
          </p>
        )} */}
      </div>
    </>
  );
}

export default HouseholdCard;
