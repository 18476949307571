/* eslint-disable no-nested-ternary */
/* eslint-disable no-restricted-globals */
import { Input } from 'storybook-design-system-drapo';
import {
  ChangeEvent,
  useState,
  useEffect,
  Dispatch,
  SetStateAction,
  useContext,
} from 'react';
import { getGeocode, getLatLng } from 'use-places-autocomplete';
import { InstallerContext } from '@auth/utils/authContext';
import { ILatLng, IListAddress } from '@simulator/utils/simulatorTypes';
import { getParcelMap } from '@simulator/apiRequests/SimulatorRequests';
import { dark1, white } from '@assets/colors';
import ListAddressGoogle from './ListAddressGoogle';
import { SimulatorContext } from '../../models/simulator/utils/simulatorContext';

interface AdressInputSearchProps {
  data: any;
  setManualAdress: Dispatch<SetStateAction<boolean>>;
  value: string;
  setValue: (val: string, shouldFetchData?: boolean | undefined) => void;
  setCity: Dispatch<SetStateAction<string>>;
  setStreet: Dispatch<SetStateAction<string>>;
  setWorksStreetNumber?: Dispatch<SetStateAction<string>>;
  setZip: Dispatch<SetStateAction<string>>;
  updateLatLngData?: Dispatch<SetStateAction<ILatLng>>;
  updateCoordinates?: any;
  updateMarkerMap?: Dispatch<SetStateAction<boolean>>;
  updateIsBack?: Dispatch<SetStateAction<boolean>>;
  choiceOk: boolean;
  setChoiceOk: Dispatch<SetStateAction<boolean>>;
  isWorkAddress?: boolean;
  setIsManual?: Dispatch<SetStateAction<boolean>>;
  errorOwnerAddress?: string;
  setErrorOwnerAddress?: Dispatch<SetStateAction<string>>;
}

function AddressInputSearch({
  data,
  setManualAdress,
  value,
  setValue,
  setCity,
  setStreet,
  setWorksStreetNumber,
  setZip,
  updateLatLngData,
  updateCoordinates,
  updateMarkerMap,
  updateIsBack,
  choiceOk,
  setChoiceOk,
  isWorkAddress,
  setIsManual,
  errorOwnerAddress,
  setErrorOwnerAddress,
}: AdressInputSearchProps): JSX.Element {
  const [activeSuggestionIndex, setActiveSuggestionIndex] =
    useState<number>(-1);
  const [listAddress, setListAddress] = useState<IListAddress[]>();
  const [errorInfo, setErrorInfo] = useState<boolean>(false);
  const [addressSelected, setAddressSelected] = useState<boolean>(false);

  const { radiusPx, isThemeDark } = useContext(InstallerContext);
  const { updateCadastreDatas } = useContext(SimulatorContext);

  const parameter = {
    address: value,
  };

  const geoCodeResultProcessing = (
    addressFind: google.maps.GeocoderAddressComponent[]
  ) => {
    const numberStreetObj = addressFind.find((item) =>
      item.types.includes('street_number')
    );
    const numberStreet = numberStreetObj ? `${numberStreetObj.long_name} ` : '';
    if (setWorksStreetNumber) {
      setWorksStreetNumber(numberStreet);
    }

    const streetObj = addressFind.find(
      (item) =>
        item.types.includes('route') || item.types.includes('establishment')
    );
    const street = streetObj ? streetObj.long_name : '';
    setStreet(`${numberStreet}${street}`);

    const cityObj = addressFind.find((item) => item.types.includes('locality'));
    const city = cityObj ? cityObj.long_name : '';
    setCity(city);

    const postalCodeObj = addressFind.find((item) =>
      item.types.includes('postal_code')
    );
    const postalCode = postalCodeObj ? postalCodeObj.long_name : '';
    setZip(postalCode);

    setAddressSelected(false);
  };

  useEffect(() => {
    if (addressSelected) {
      getGeocode(parameter)
        .then((results) => {
          if (
            results[0].types.includes('street_address') ||
            results[0].types.includes('route') ||
            results[0].types.includes('premise') ||
            results[0].types.includes('subpremise') ||
            results[0].types.includes('establishment')
          ) {
            setErrorInfo(false);
            geoCodeResultProcessing(results[0].address_components);
          } else setErrorInfo(true);
          return getLatLng(results[0]);
        })
        .then((latLng) => {
          if (choiceOk && updateLatLngData && updateMarkerMap) {
            updateLatLngData(latLng);
            getParcelMap(
              latLng,
              updateCoordinates,
              updateMarkerMap,
              updateCadastreDatas
            );
          }
        })
        .catch((error) => {
          return <p>{error}</p>;
        });
    }
    if (value === '' && setErrorInfo) {
      setErrorInfo(false);
    }
  }, [value, addressSelected]);

  const handleInput = (e: ChangeEvent<HTMLInputElement>): void => {
    if (updateIsBack) {
      updateIsBack(false);
    }
    if (errorOwnerAddress !== '' && setErrorOwnerAddress) {
      setErrorOwnerAddress('');
    }
    setValue(e.target.value);
    setChoiceOk(false);
  };

  const onKeyDown = (e: any) => {
    if (listAddress) {
      if (e.keyCode === 13) {
        if (activeSuggestionIndex === listAddress.length - 1) {
          setManualAdress(true);
          setActiveSuggestionIndex(0);
          if (setIsManual) {
            setIsManual(true);
          }
        } else {
          setValue(listAddress[activeSuggestionIndex].description, false);
          setAddressSelected(true);
          setActiveSuggestionIndex(0);
          setChoiceOk(true);
          if (setIsManual) {
            setIsManual(false);
          }
        }
      } else if (e.keyCode === 38) {
        if (activeSuggestionIndex === 0) {
          return;
        }
        setActiveSuggestionIndex(activeSuggestionIndex - 1);
      } else if (e.keyCode === 40) {
        if (activeSuggestionIndex - 1 === data.length) {
          return;
        }
        setActiveSuggestionIndex(activeSuggestionIndex + 1);
      }
    }
  };

  return (
    <div className="mt-[1.5rem]">
      <Input
        borderRadius={radiusPx}
        color={isThemeDark ? white : dark1}
        border={
          errorInfo || errorOwnerAddress
            ? '1px solid red'
            : location.search.includes('?integration=iframe')
            ? '1px solid #d2d2d2'
            : ''
        }
        backgroundColor={isThemeDark ? dark1 : 'white'}
        inputType="text"
        label="Adresse"
        onChange={handleInput}
        value={value}
        dataTestId="input-address"
        onKeyDown={onKeyDown}
      />

      {value !== '' && !choiceOk && (
        <ListAddressGoogle
          data={data}
          setChoiceOk={setChoiceOk}
          setManualAdress={setManualAdress}
          setIsManual={setIsManual}
          setValue={setValue}
          activeSuggestionIndex={activeSuggestionIndex}
          setActiveSuggestionIndex={setActiveSuggestionIndex}
          listAddress={listAddress}
          setListAddress={setListAddress}
          setAddressSelected={setAddressSelected}
        />
      )}
      {errorOwnerAddress !== '' && (
        <p className="text-xs text-red-500 mt-[.25rem] mb-[.875rem] ml-[.5rem]">
          {errorOwnerAddress}
        </p>
      )}
      {errorInfo && isWorkAddress && (
        <p className="text-[.75rem] text-red-500 mt-[.25rem] mb-[1rem] ml-[.5rem]">
          Il manque des informations dans l&apos;adresse saisie, veuillez
          chercher à nouveau ou cliquer{' '}
          <button
            type="button"
            className="underline"
            onClick={() => {
              setChoiceOk(true);
              setManualAdress(true);
              if (setIsManual) {
                setIsManual(true);
              }
            }}
          >
            ICI
          </button>{' '}
          pour saisir une adresse manuellement..
        </p>
      )}
    </div>
  );
}

export default AddressInputSearch;

AddressInputSearch.defaultProps = {
  setWorksStreetNumber: undefined,
  updateLatLngData: undefined,
  updateCoordinates: undefined,
  updateMarkerMap: undefined,
  updateIsBack: undefined,
  isWorkAddress: false,
  setIsManual: undefined,
  errorOwnerAddress: undefined,
  setErrorOwnerAddress: undefined,
};
