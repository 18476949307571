import { InstallerContext } from '@auth/utils/authContext';
import { useContext } from 'react';

function LoaderFolderRight(): JSX.Element {
  const { radiusPx } = useContext(InstallerContext);

  return (
    <div
      style={{ borderRadius: radiusPx }}
      className="w-full dark:bg-dark-4 bg-lightMode-dark-4 pt-[1.5rem] h-[95%] animate__infinite animate__flash animate__animated animate__slower"
    >
      <div className="ml-[1.5rem] mt-[1.5rem] w-6/12 bg-dark-light-1 h-[1.5rem] rounded-[8px] opacity-60 animate__infinite animate__flash animate__animated animate__slower " />
      <div className="w-11/12 h-3/4 bg-dark-light-1 rounded-[8px] mx-[1.5rem] opacity-60 mt-[1.5rem] animate__infinite  animate__flash animate__animated animate__slower" />
      <div className="flex w-full">
        <div className="w-7/12 bg-dark-light-1 h-[2rem] rounded-[8px] mx-[1.5rem] opacity-60 mt-[1.5rem] animate__infinite  animate__flash animate__animated animate__slower" />
        <div className="w-5/12 bg-dark-light-1 h-[2rem] rounded-[8px] mx-[1.5rem] opacity-60 mt-[1.5rem] animate__infinite  animate__flash animate__animated animate__slower" />
      </div>
    </div>
  );
}

export default LoaderFolderRight;
