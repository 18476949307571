import { InstallerContext } from '@auth/utils/authContext';
import { useContext } from 'react';

function LoaderSummaryDashboard(): JSX.Element {
  const { radiusPx } = useContext(InstallerContext);

  return (
    <>
      <div
        style={{ borderRadius: radiusPx }}
        className="lg:w-1/3 mx-[1.25rem] h-full h-[75vh] dark:bg-dark-5 bg-lightMode-dark-5 animate__infinite  animate__flash animate__animated animate__slower"
      />
      <div className="lg:w-2/3 h-full h-[75vh]">
        <div
          style={{ borderRadius: radiusPx }}
          className="lg:w-11/12 mb-[1.25rem] mx-[1.25rem] h-[45vh] dark:bg-dark-5 bg-lightMode-dark-5 animate__infinite  animate__flash animate__animated animate__slower"
        />
        <div
          style={{ borderRadius: radiusPx }}
          className="lg:w-11/12 mx-[1.25rem] h-[30vh] dark:bg-dark-5 bg-lightMode-dark-5 animate__infinite  animate__flash animate__animated animate__slower"
        />
      </div>
    </>
  );
}

export default LoaderSummaryDashboard;
