import { useContext } from 'react';
import { FolderContext } from '@folder/utils/FoldersContext';
import { ChevronIcon } from '@assets/svgComponents/svgComponents';
import { InstallerContext } from '@auth/utils/authContext';

function NextStepButton(): JSX.Element {
  const { updateStepNumberFolder, stepNumberFolder } =
    useContext(FolderContext);

  const { isThemeDark } = useContext(InstallerContext);

  const nextStepFolder = () => {
    updateStepNumberFolder(stepNumberFolder + 1);
  };

  return (
    <button
      className="cursor-pointer flex items-center sb-text-rem-subtitle2 pb-[2rem]"
      type="button"
      onClick={() => nextStepFolder()}
      data-test-id="next"
    >
      <p className="dark:text-white text-colorTextLightMode">Etape suivante</p>
      <ChevronIcon
        width="1.25rem"
        height="1.25rem"
        fill={isThemeDark ? 'white' : '#191919'}
      />
    </button>
  );
}

export default NextStepButton;
