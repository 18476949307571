import { convertHexToRGBA, numFormatSpace } from '@utils/utils';
import { IBonusType } from '@simulator/utils/simulatorTypes';
import { v4 } from 'uuid';
import { dark4 } from '@assets/colors';
import { useContext } from 'react';
import { InstallerContext } from '@auth/utils/authContext';

interface BonusCardProps {
  bonusObj: IBonusType;
}

function BonusCard({ bonusObj }: BonusCardProps): JSX.Element {
  const { isThemeDark, colorPrimary } = useContext(InstallerContext);

  return (
    <div
      style={{
        backgroundColor: isThemeDark
          ? dark4
          : convertHexToRGBA(colorPrimary, 0.1),
      }}
      className="w-full p-[1.5rem] rounded-default mb-[1rem] leading-[1.5rem] text-[.875rem]"
    >
      <p className="mb-[1.25rem]">Bonus</p>
      {Object.keys(bonusObj).map((bonusKey) => {
        let bonusLabel: string;
        switch (bonusKey) {
          case 'bar-se-1000':
            bonusLabel = 'Bonus sortie de passoire';
            break;
          case 'bar-se-1001':
            bonusLabel = 'Bonus Bâtiment Basse Consommation';
            break;
          case 'bar-se-1002':
            bonusLabel = 'Audit énergétique';
            break;
          case 'partner':
            return <></>;
          default:
            bonusLabel = 'Autre bonus';
            break;
        }
        const bonusValue: number = bonusObj[bonusKey] as number;
        if (bonusObj[bonusKey] > 0) {
          return (
            <div key={v4()} className="flex items-center justify-between">
              <p>{bonusLabel}</p>
              <p className="whitespace-nowrap">{`${numFormatSpace(
                bonusValue
              )} € TTC`}</p>
            </div>
          );
        }
        return null;
      })}
    </div>
  );
}

export { BonusCard };
