import { Dispatch, SetStateAction, useContext } from 'react';
import { InstallerContext } from '@auth/utils/authContext';
import { IFilesList } from '@dashboard/utils/dashboardTypes';
import { v4 } from 'uuid';
import { colorTextLightMode, dark2 } from '@assets/colors';
import { DownloadIcon } from '@assets/svgComponents/svgComponents';

interface IDocumentsListProps {
  title: string;
  filesList: IFilesList[];
  fileToShow: string;
  setPdfIsLoaded: Dispatch<SetStateAction<boolean>>;
  setIsTypeImage: Dispatch<SetStateAction<boolean>>;
  setFileToShow: Dispatch<SetStateAction<string>>;
}

function DocumentsList({
  title,
  filesList,
  fileToShow,
  setPdfIsLoaded,
  setIsTypeImage,
  setFileToShow,
}: IDocumentsListProps): JSX.Element {
  const { radiusPx, isThemeDark, colorPrimary, colorTextButton } =
    useContext(InstallerContext);

  const radius = radiusPx === '0px' ? '0px' : '16px';
  const radiusSmall = radiusPx === '0px' ? '0px' : '8px';

  // Cherche AU MOINS UNE liste de fichiers n'est pas vide
  const noFileToDisplay =
    filesList.findIndex((file) => file.link.length > 0) !== -1;

  const handleClick = (link: string, isImage: boolean) => {
    if (link !== fileToShow) {
      setPdfIsLoaded(false);
      setIsTypeImage(isImage);
      setFileToShow(link);
    }
  };

  return (
    <>
      <div
        className="dark:bg-dark-5 bg-white p-[1rem] w-full"
        style={{ borderRadius: radius }}
      >
        <div className="text-[.875rem] font-[500] pb-[.5rem]">{title}</div>
        {noFileToDisplay ? (
          <div className="flex flex-col">
            {filesList
              .filter((file) => file.link.length !== 0)
              .map((file: IFilesList) => (
                <div key={v4()}>
                  {file.link.map((link: string, index: number) => {
                    const isFileActive = link === fileToShow;
                    return (
                      <button
                        key={v4()}
                        type="button"
                        style={{
                          borderRadius: radiusSmall,
                          backgroundColor: `${
                            isFileActive
                              ? colorPrimary
                              : `${!isThemeDark ? 'white' : dark2}`
                          }`,
                          color: isFileActive
                            ? colorTextButton
                            : `${isThemeDark ? 'white' : colorTextLightMode}`,
                        }}
                        className="w-full mt-[.5rem] dark:border-none border border-borderLightMode flex items-center justify-between py-[.75rem] px-[1rem] text-[.75rem] font-[500]"
                        onClick={() => {
                          handleClick(link, file.isImage);
                        }}
                      >
                        <div>
                          {file.link.length > 1
                            ? `${file.name} ${index + 1}`
                            : file.name}
                        </div>
                        <a
                          href={link}
                          download
                          target="_blank"
                          className="opacity-80 hover:opacity-100"
                          rel="noreferrer"
                        >
                          <DownloadIcon
                            width="1.5rem"
                            height="1.5rem"
                            fill={
                              isFileActive
                                ? colorTextButton
                                : `${
                                    isThemeDark ? 'white' : colorTextLightMode
                                  }`
                            }
                          />
                        </a>
                      </button>
                    );
                  })}
                </div>
              ))}
          </div>
        ) : (
          <div className="text-[.75rem] font-light">
            Auncun fichier présent.
          </div>
        )}
      </div>
    </>
  );
}

export { DocumentsList };
