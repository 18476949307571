import { useContext, useState } from 'react';
import { Button, CheckCardSquare } from 'storybook-design-system-drapo';
import { InstallerContext } from '@auth/utils/authContext';
import { nextStepGeneral, nextStepOperation } from '@simulator/utils/functions';
import { SimulatorContext } from '@simulator/utils/simulatorContext';

import { IOptionType } from '@simulator/utils/simulatorTypes';
import { dark1, white } from '@assets/colors';
import { convertHexToRGBA } from '@utils/utils';
import { useLocation } from 'react-router-dom';

function CardSquareForm(): JSX.Element {
  const location = useLocation();
  const {
    simulatorDatas,
    updateSimulatorDatas,
    graphGeneral,
    updateGraphGeneral,
    updateGraphList,
    updateStepNumber,
    updateGeneralComplete,
    generalComplete,
    updateGraphOperation,
    updateGraphListOperation,
    graphOperation,
    updateCurrentOperation,
    currentOperation,
    multipleOperations,
  } = useContext(SimulatorContext);
  const { colorPrimary, radiusPx, isThemeDark, colorTextButton } =
    useContext(InstallerContext);
  const [dataOption, setDataOption] = useState('');
  const [appartmentChecked, setAppartmentChecked] = useState(false);
  const [houseChecked, setHouseChecked] = useState(false);

  const onClickChange = (option: string) => {
    setDataOption(option);
    setAppartmentChecked(option !== 'house');
    setHouseChecked(option !== 'apartment');
  };
  const nextButton = () => {
    if (generalComplete) {
      nextStepOperation(
        simulatorDatas,
        currentOperation,
        updateStepNumber,
        updateCurrentOperation,
        updateGraphOperation,
        updateGraphListOperation,
        graphOperation,
        graphOperation.id,
        dataOption
      );
    } else {
      nextStepGeneral(
        simulatorDatas,
        multipleOperations,
        updateGeneralComplete,
        updateStepNumber,
        updateSimulatorDatas,
        updateGraphGeneral,
        updateGraphList,
        graphGeneral,
        graphGeneral.id,
        dataOption
      );
    }
  };

  const renderImg = (img: string | undefined) => {
    if (img === undefined) return undefined;
    try {
      // eslint-disable-next-line global-require,import/no-dynamic-require
      return require(`@assets/images/simulator/${img}`);
    } catch (error) {
      return undefined;
    }
  };

  const graphActive = generalComplete ? graphOperation : graphGeneral;

  return (
    <div className="grow">
      <div
        style={{
          borderRadius: radiusPx,
          border:
            location.search.includes('?integration=iframe') && !isThemeDark
              ? '1px solid #d2d2d2'
              : '',
        }}
        className="dark:bg-dark-2 bg-white  animate__animated animate__fadeIn p-[1.5rem] mt-[1.5rem] flex space-x-[1rem]"
      >
        {graphActive.options.map((item: IOptionType) => {
          const checkedState =
            item.value === 'house' ? houseChecked : appartmentChecked;
          return (
            <div key={item.value} className="w-1/2 h-[18rem]">
              <CheckCardSquare
                // eslint-disable-next-line global-require, import/no-dynamic-require
                urlImg={renderImg(item.img)}
                borderRadius={radiusPx}
                textColorChecked={colorTextButton}
                textColorNotChecked={isThemeDark ? white : dark1}
                backgroundColorNotChecked={
                  isThemeDark ? dark1 : convertHexToRGBA(colorPrimary, 0.1)
                }
                backgroundColorChecked={colorPrimary}
                checked={checkedState}
                label={item.label}
                onClick={() => onClickChange(item.value.toString())}
                dataTestId={`card-${graphActive.id}`}
              />
            </div>
          );
        })}
      </div>
      <Button
        buttonArrowSmall
        borderRadius={radiusPx}
        disabled={dataOption === ''}
        onClick={() => nextButton()}
        addClass="mt-[1.5rem] !w-full !px-[1rem]"
        label="Valider"
        arrow
        color={colorTextButton}
        backgroundColor={colorPrimary}
        dataTestId="next"
      />
    </div>
  );
}

export default CardSquareForm;
