import { useContext, useEffect, useState } from 'react';
import {
  ProgressBar,
  WarningNotification,
} from 'storybook-design-system-drapo';

import Logo from '@components/Logo';
import LoaderRecap from '@components/loaders/LoaderRecap';
import { FoldersAndSimulatorContext } from '@folder/utils/FoldersContext';
import { InstallerContext } from '@auth/utils/authContext';
import {
  OperationsListContext,
  SimulatorContext,
} from '@simulator/utils/simulatorContext';
import { ILatLng, IOperationDatasType } from '@simulator/utils/simulatorTypes';
import { v4 } from 'uuid';
import { useLocation, Link, useParams } from 'react-router-dom';
import { BonusSuggestion } from '@simulator/components/summary/BonusSuggestion';
import { ErrorCalculationPrimes } from '@simulator/components/summary/ErrorCalculationPrimes';
import { dark1, white } from '@assets/colors';
import { calculatePrimesUncapped } from '@simulator/apiRequests/SimulatorRequests';
import OperationListSummary from './OperationListSummary';
import GeneralSummary from './GeneralSummary';
import { CardSummary } from './CardSummary';

import DetailDropdown from './DetailDropdown';
import NextSteps from './nextStepPart/NextSteps';

interface SummaryProps {
  latLng: ILatLng;
  coordinates: ILatLng[];
  markerMap: boolean;
}

function Summary({ latLng, coordinates, markerMap }: SummaryProps) {
  const location = useLocation();

  const {
    stepName,
    stepNumber,
    loaded,
    errorCalculationPrimes,
    updateOperationInEdit,
    updateLoaded,
    updateErrorCalculationPrimes,
    simulatorDatas,
  } = useContext(SimulatorContext);
  const { whiteLabelCustom } = useContext(FoldersAndSimulatorContext);
  const { operationList, updateOperationList } = useContext(
    OperationsListContext
  );
  const {
    installer,
    colorPrimary,
    installerIsLoaded,
    radiusPx,
    colorAlertWhiteLabel,
    isThemeDark,
  } = useContext(InstallerContext);

  const steps = 4;

  const [error, setError] = useState('');
  const [errorQuota, setErrorQuota] = useState<string[]>(['']);
  const [bonusPossible, setBonusPossible] = useState(false);

  const [folderFeeMpr, setFolderFeeMpr] = useState(0);
  const [folderFeeCee, setFolderFeeCee] = useState(0);
  const [totalFolderFees, setTotalFolderFees] = useState(0);
  const TVA = (totalFolderFees * 1.2 * 100) / 100 - totalFolderFees;

  const [operationsSelected, setOperationsSelected] =
    useState<IOperationDatasType[]>(operationList);
  const [isInit, setIsInit] = useState(false);

  const numberOfOperationsForPromptBonus = 3;

  const noCee = !installer.eligibility?.includes('CEE');
  const noMpr = !installer.eligibility?.includes('MPR');

  const [mprIsSelect, setMprIsSelect] = useState(!noMpr);
  const [ceeIsSelect, setCeeIsSelect] = useState(!noCee);
  const [detailDropdownOpen, setDetailDropdownOpen] = useState(false);
  const [uncappedRequestOk, setUncappedRequestOk] = useState(false);
  const cappedPresent =
    operationList.findIndex((op) => op.primes.ecretement) !== -1;
  const whiteLabel: string = useParams().marque || '';

  useEffect(() => {
    if (cappedPresent && !uncappedRequestOk && loaded) {
      updateLoaded(false);
      setUncappedRequestOk(true);
      calculatePrimesUncapped(
        simulatorDatas,
        operationList,
        updateOperationList,
        updateLoaded,
        location,
        whiteLabel,
        updateErrorCalculationPrimes
      );
    } else if (!isInit && operationList.length > 0 && loaded) {
      setOperationsSelected(operationList);
      if (
        operationList.length >= numberOfOperationsForPromptBonus &&
        operationList.findIndex((op) => op.id === 'bar-se-1002') === -1
      ) {
        setBonusPossible(true);
      } else {
        setBonusPossible(false);
      }
      setIsInit(true);
      updateOperationInEdit(false);
    } else if (operationList !== operationsSelected) {
      setOperationsSelected((prev) =>
        prev.map((op) => operationList.find((o) => o.id === op.id) || op)
      );
    }
  }, [operationList, loaded]);

  const darkModeLogoInstaller = isThemeDark
    ? installer.custom.logo_mb_light
    : installer.custom.logo_mb_dark;
  const darkModeLogoWhiteLabel = isThemeDark
    ? whiteLabelCustom.logo_mb_light
    : whiteLabelCustom.logo_mb_dark;

  return (
    <>
      <div className="pb-[4rem] pt-[1.25rem]">
        <div className="flex w-full justify-between mb-[2rem]">
          <div>
            {installerIsLoaded && !location.pathname.includes('wl') && (
              <>
                {installer.nom_partenaire === 'DRAPO' ||
                installer.nom_partenaire === '' ? (
                  <Logo />
                ) : (
                  darkModeLogoInstaller.map((logo) => {
                    return (
                      <Link key={v4()} to="/">
                        <img
                          src={logo}
                          style={{ width: '8.125rem' }}
                          className="logo-drapo"
                          alt="logo"
                        />
                      </Link>
                    );
                  })
                )}
              </>
            )}

            <Link to="/">
              {location.pathname.includes('wl') &&
                !location.search.includes('?integration=iframe') &&
                whiteLabelCustom.couleur_boutons !== '' &&
                darkModeLogoWhiteLabel.map((logo) => (
                  <img
                    key={v4()}
                    style={{ width: '8.125rem' }}
                    className="logo-drapo"
                    src={logo}
                    alt="logo"
                  />
                ))}
            </Link>
          </div>

          <div
            style={{
              width: !location.search.includes('?integration=iframe')
                ? '12rem'
                : '100%',
            }}
            className="xl:ml-[2.5rem] xl:w-[12rem] w-1/2"
          >
            <ProgressBar
              colorSecondary={isThemeDark ? '#818181' : white}
              backgroundColor={colorPrimary}
              stepNumber={stepNumber}
              stepName={stepName}
              numberSteps={steps}
              color={isThemeDark ? 'white' : dark1}
              isSummary
            />
          </div>
        </div>
        <GeneralSummary
          latLng={latLng}
          coordinates={coordinates}
          markerMap={markerMap}
        />

        {loaded ? (
          <>
            {errorCalculationPrimes ? (
              <ErrorCalculationPrimes />
            ) : (
              <>
                {(error !== '' || errorQuota.length > 1) && (
                  <WarningNotification
                    borderRadius={radiusPx}
                    addClass="mb-[2rem]"
                    backgroundColor={colorAlertWhiteLabel}
                    color="white"
                    warningText={error !== '' ? error : errorQuota}
                  />
                )}
                {bonusPossible && <BonusSuggestion />}
                <div className="w-full lg:flex lg:space-x-[2rem] ">
                  <div className="lg:w-5/12">
                    <OperationListSummary
                      setOperationSelected={setOperationsSelected}
                      mprIsSelect={mprIsSelect}
                      ceeIsSelect={ceeIsSelect}
                    />
                  </div>
                  <div className="lg:w-7/12 lg:mt-0 mt-interblock pb-[6.25rem] ">
                    <CardSummary
                      operationsSelected={operationsSelected}
                      setFolderFeeMpr={setFolderFeeMpr}
                      setFolderFeeCee={setFolderFeeCee}
                      folderFeeMpr={folderFeeMpr}
                      folderFeeCee={folderFeeCee}
                      TVA={TVA}
                      setError={setError}
                      setErrorQuota={setErrorQuota}
                      mprIsSelect={mprIsSelect}
                      setMprIsSelect={setMprIsSelect}
                      ceeIsSelect={ceeIsSelect}
                      setCeeIsSelect={setCeeIsSelect}
                      totalFolderFees={totalFolderFees}
                      setTotalFolderFees={setTotalFolderFees}
                      setDetailDropdownOpen={setDetailDropdownOpen}
                    />
                    {totalFolderFees > 0 && (
                      <DetailDropdown
                        TVA={TVA}
                        detailDropdownOpen={detailDropdownOpen}
                        setDetailDropdownOpen={setDetailDropdownOpen}
                        totalFolderFees={totalFolderFees}
                      />
                    )}
                    <NextSteps />
                  </div>
                </div>
              </>
            )}
          </>
        ) : (
          <LoaderRecap />
        )}
      </div>
    </>
  );
}

export default Summary;
