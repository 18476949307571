/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-shadow */
import { drop } from '@assets/images/images';
import { Dispatch, SetStateAction } from 'react';

import { Droppable, Draggable, DragDropContext } from 'react-beautiful-dnd';

interface IProps {
  datas: string[];
  setColumnArray: Dispatch<SetStateAction<string[]>>;
}

function ListColumns({ datas, setColumnArray }: IProps): JSX.Element {
  const onDragEnd = (result: any) => {
    const { destination, source } = result;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const newItems = [...datas];
    const [removed] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, removed);
    setColumnArray(newItems);
  };

  return (
    <div className=" px-[1.5rem] w-full">
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="1">
          {(provided) => (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <div {...provided.droppableProps} ref={provided.innerRef}>
              <div className="holder holder--tint- w-full">
                <div className="holder__content flex w-full justify-between items-center">
                  <ul className="list mt-[.625rem] w-full">
                    {datas.map((el, index) => (
                      <Draggable key={el} draggableId={el} index={index}>
                        {(provided, snapshot) => (
                          <div
                            style={snapshot.isDragging ? { opacity: 0.5 } : {}}
                            className="text-[.875rem] font-medium pb-[.625rem]  w-full"
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            ref={provided.innerRef}
                          >
                            <div className="flex items-center space-x-[.625rem]">
                              <img src={drop} alt="déplacer" />
                              <p>{el}</p>
                            </div>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </ul>
                </div>
              </div>
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
}

export default ListColumns;
