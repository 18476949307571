import { useContext, useState } from 'react';
import {
  AnimateCadastreInputCount,
  Button,
} from 'storybook-design-system-drapo';
import LoaderSurface from '@components/loaders/LoaderSurface';
import { InstallerContext } from '@auth/utils/authContext';
import { nextStepGeneral, nextStepOperation } from '@simulator/utils/functions';
import { SimulatorContext } from '@simulator/utils/simulatorContext';
import { useLocation } from 'react-router-dom';
import {
  backgroundQuaternaryLightMode,
  dark1,
  dark4,
  darkVariant1,
  purple1Opaque,
  white,
} from '@assets/colors';

function InputSurface(): JSX.Element {
  const location = useLocation();
  const [surface, setSurface] = useState<number>(40);

  const {
    simulatorDatas,
    updateSimulatorDatas,
    graphGeneral,
    updateGraphGeneral,
    updateGraphList,
    updateStepNumber,
    updateGeneralComplete,
    generalComplete,
    updateGraphOperation,
    updateGraphListOperation,
    graphOperation,
    updateCurrentOperation,
    currentOperation,
    loaded,
    updateSurfaceIsolate,
    multipleOperations,
  } = useContext(SimulatorContext);
  const { installer, radiusPx, colorPrimary, isThemeDark, colorTextButton } =
    useContext(InstallerContext);

  const onClickNext = () => {
    if (currentOperation.id.includes('-en-')) {
      updateSurfaceIsolate(surface);
    }
    if (generalComplete) {
      nextStepOperation(
        simulatorDatas,
        currentOperation,
        updateStepNumber,
        updateCurrentOperation,
        updateGraphOperation,
        updateGraphListOperation,
        graphOperation,
        graphOperation.id,
        surface.toString()
      );
    } else {
      nextStepGeneral(
        simulatorDatas,
        multipleOperations,
        updateGeneralComplete,
        updateStepNumber,
        updateSimulatorDatas,
        updateGraphGeneral,
        updateGraphList,
        graphGeneral,
        graphGeneral.id,
        surface.toString()
      );
    }
  };

  const handleChange = (value: number) => {
    setSurface(value);
  };

  const backgroundColor = isThemeDark ? dark4 : backgroundQuaternaryLightMode;

  return (
    <div>
      {!loaded ? (
        <LoaderSurface />
      ) : (
        <div className="animate__animated animate__fadeIn">
          <div
            className="mt-[1.5rem]"
            style={{
              borderRadius: radiusPx,
              border:
                location.search.includes('?integration=iframe') && !isThemeDark
                  ? '1px solid #d2d2d2'
                  : '',
            }}
          >
            <AnimateCadastreInputCount
              borderRadius={radiusPx}
              colorInputCount={isThemeDark ? white : dark1}
              backgroundColorInputCount={
                isThemeDark ? darkVariant1 : backgroundQuaternaryLightMode
              }
              backgroundColorAnimateInput={isThemeDark ? dark1 : 'white'}
              addClass=" !text-black"
              backgroundColor={
                (installer.nom_partenaire === 'DRAPO' ||
                  installer.nom_partenaire === '') &&
                !location.pathname.includes('wl')
                  ? purple1Opaque
                  : backgroundColor
              }
              borderColor={colorPrimary}
              onChange={(value) => handleChange(value)}
            />
          </div>
          <div className="w-full mt-[1.5rem]">
            <Button
              borderRadius={radiusPx}
              disabled={!(surface > 1)}
              buttonArrowSmall
              label="Suivant"
              color={colorTextButton}
              backgroundColor={colorPrimary}
              arrow
              addClass="!w-full !px-[1rem]"
              onClick={onClickNext}
              dataTestId="next"
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default InputSurface;
