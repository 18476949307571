import React, { Dispatch, SetStateAction } from 'react';

interface IProps {
  documentUrlActive: string;
  updateDocumentUrlActive: Dispatch<SetStateAction<string>>;
  isUpdateLoading: boolean;
  updateIsUpdateLoading: Dispatch<SetStateAction<boolean>>;
  pageNumberViewDocument: number;
  updatePageNumberViewDocument: Dispatch<SetStateAction<number>>;
}

export const MyProfileContext = React.createContext({} as IProps);
