/* eslint-disable no-nested-ternary */
import { colorTextLightMode, dark1, dark5, white } from '@assets/colors';
import { backgroundCheckbox } from '@assets/images/images';
import { InstallerContext } from '@auth/utils/authContext';
import { sendMailIfNoMprWithDrapo } from '@dashboard/apiRequests/dashboardRequests';
import { convertHexToRGBA } from '@utils/utils';
import { useContext, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Button } from 'storybook-design-system-drapo';
import Loader from '@components/loaders/Loader';

function ModalContratMprMixte() {
  const {
    colorPrimary,
    radiusPx,
    isThemeDark,
    colorTextButton,
    updateDisplayModalContratMixteMpr,
  } = useContext(InstallerContext);
  const isMobile = useMediaQuery({ maxWidth: 1024 });
  const [choiceSelected, setChoiceSelected] = useState('OUI');
  const [validationCheckbox, setValidationCheckbox] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const choices = ['OUI', 'NON'];

  const onConfirmChoice = async () => {
    localStorage.setItem('isMprWithDrapo', 'true');

    await sendMailIfNoMprWithDrapo(
      updateDisplayModalContratMixteMpr,
      setIsLoading,
      choiceSelected === 'OUI' ? 1 : 0
    );
  };

  return (
    <div
      style={{ zIndex: 999999 }}
      className="w-screen bg-[#EFEFEF] lg:fixed lg:inset-0 z-50 h-full lg:flex lg:items-center lg:justify-center bg-opacity-30 "
    >
      <div
        style={{
          borderRadius: isMobile ? '' : radiusPx,
          color: isThemeDark ? white : dark1,
          minHeight: isMobile ? '100vh' : 'auto',
        }}
        className="dark:bg-black bg-white lg:shadow-2xl overflow-hidden h-auto lg:w-1/2 p-[2rem]"
      >
        <div className="h-full">
          <p className="lg:text-[2rem] text-[1.5rem] lg:py-0 lg:py-[3rem] lg:px-0 lg:px-[2.25rem]">
            Arrêt du mandat mixte MaPrimeRénov&apos; Drapo et remplacement par
            un mandat administratif
          </p>
          <div className=" lg:px-0 lg:px-[2.25rem] mt-[1rem]">
            <p className="lg:text-[1rem] leading-[1.5rem] text-[.875rem]">
              Comme nous vous l&apos;avions annoncé dans une newsletter, fin
              janvier dernier, dans le cadre de vos demandes de subvention MPR
              Drapo ne vous accompagne plus en tant que Mandataire Mixte, mais
              uniquement en tant que Mandataire administratif.
            </p>
            <p className="lg:text-[1rem] leading-[1.5rem] text-[.875rem] mt-[.5rem]">
              Aussi dans cette démarche, merci de nous indiquer si vous
              souhaitez poursuivre le partenariat avec Drapo pour la gestion
              administrative de MaPrimeRénov&apos;.
            </p>
          </div>
        </div>
        <div className="w-full flex justify-center">
          <div
            style={{
              borderRadius: radiusPx,
              backgroundColor: isThemeDark
                ? dark5
                : convertHexToRGBA(colorPrimary, 0.1),
            }}
            className="sb-text-typo text-white sb-text-rem-subtitle2 flex w-7/12 my-[2rem]"
          >
            {choices.map((choice, index) => {
              return (
                <div
                  tabIndex={index}
                  onKeyDown={undefined}
                  role="button"
                  key={choice}
                  style={{
                    width: `${100 / choices.length}%`,
                    backgroundColor:
                      choice === choiceSelected ? colorPrimary : '',
                    borderRadius: radiusPx === '16px' ? '16px' : '',
                    color: isThemeDark
                      ? white
                      : choice === choiceSelected && !isThemeDark
                      ? colorTextButton
                      : choice !== choiceSelected && !isThemeDark
                      ? colorTextLightMode
                      : '',
                  }}
                  className={`dark:text-white text-colorTextLightMode sb-text-rem-subtitle2 sb-choice flex justify-center items-center choice-${
                    choice === choiceSelected
                      ? 'sb-selected'
                      : 'sb-not-selected'
                  }`}
                  onClick={() => {
                    setChoiceSelected(choices[index]);
                    setValidationCheckbox(false);
                  }}
                >
                  {choice}
                </div>
              );
            })}
          </div>
        </div>

        <div className="flex items-center">
          <div
            style={{
              backgroundImage: validationCheckbox
                ? `url(${backgroundCheckbox})`
                : '',
              borderRadius: radiusPx === '16px' ? '2px' : '',
              backgroundSize: 'center',
              backgroundPositionX: '60%',
              backgroundPositionY: 'center',
              backgroundRepeat: 'no-repeat',
              border: validationCheckbox ? '' : '1px solid #C6C6C6',
              backgroundColor: validationCheckbox ? colorPrimary : '',
            }}
            className="w-[1.25rem] h-[1.25rem] mr-[.5rem]"
          >
            <input
              className="input-checked"
              checked={validationCheckbox}
              onChange={() => setValidationCheckbox(!validationCheckbox)}
              type="checkbox"
            />
          </div>
          <p>
            {choiceSelected === 'OUI'
              ? 'En cochant cette case :'
              : 'Je refuse de poursuivre le partenariat avec DRAPO pour la gestion administrative de MaPrimeRénov’.'}
          </p>
        </div>
        {choiceSelected === 'OUI' && (
          <ul className="list-disc ml-12 mt-[1rem]">
            <li>
              Je reconnais avoir pris connaissance qu’il ne sera plus possible
              d’engager un dossier MaPrimeRénov’ dont DRAPO serait le mandataire
              mixte.
            </li>
            <li>
              J&apos;accepte de poursuivre le partenariat avec DRAPO en tant que
              mandataire administratif.
            </li>
            <li>
              J&apos;affirme avoir pris connaissance et accepte sans réserve que
              DRAPO ne financera plus les dossiers MaPrimeRénov’.
            </li>
            <li>
              J&apos;accepte le prix forfaitaire de 150 €HT par opération
              lorsque je confie à DRAPO la gestion administrative de
              MaPrimeRénov&apos;.
            </li>
          </ul>
        )}
        {isLoading && (
          <div className="my-[1rem]">
            <Loader />
          </div>
        )}
        <Button
          arrow
          disabled={!validationCheckbox || isLoading}
          borderRadius={radiusPx === '16px' ? '8px' : '0px'}
          label="Valider"
          color={!isThemeDark ? dark1 : white}
          backgroundColor={colorPrimary}
          onClick={onConfirmChoice}
          addClass="float-right  mt-8"
        />
      </div>
    </div>
  );
}

export { ModalContratMprMixte };
