import { dark1, white } from '@assets/colors';
import { CloseIcon } from '@assets/svgComponents/svgComponents';
import { InstallerContext } from '@auth/utils/authContext';
import { Dispatch, SetStateAction, useContext } from 'react';
import { Input } from 'storybook-design-system-drapo';

interface IProps {
  setArrayMail: Dispatch<SetStateAction<string[]>>;
  arrayMail: string[];
  index: number;
  mail: string;
}

function OneNewMail({
  arrayMail,
  setArrayMail,
  index,
  mail,
}: IProps): JSX.Element {
  const { radiusPx, isThemeDark } = useContext(InstallerContext);
  return (
    <li
      // eslint-disable-next-line react/no-array-index-key
      key={index}
      className="w-full"
    >
      <div className="flex items-center space-x-[1rem] w-full relative">
        <Input
          addClass="!w-full"
          borderRadius={radiusPx}
          color={isThemeDark ? white : dark1}
          backgroundColor={isThemeDark ? dark1 : white}
          inputType="mail"
          label="Adresse email"
          onChange={(e) => {
            const odlArray = [...arrayMail];
            odlArray[index] = e.target.value;
            setArrayMail(odlArray);
          }}
          value={mail}
        />
        {index !== 0 && (
          <button
            type="button"
            onClick={() =>
              setArrayMail(arrayMail.filter((el, indexEl) => indexEl !== index))
            }
            style={{
              borderRadius: radiusPx,
            }}
            className="p-[1.5rem] cursor-pointer absolute right-0 top-0"
          >
            <CloseIcon
              width="1.5rem"
              height="1.5rem"
              fill={isThemeDark ? white : dark1}
            />
          </button>
        )}
      </div>
    </li>
  );
}

export { OneNewMail };
