import { FoldersAndSimulatorContext } from '@folder/utils/FoldersContext';
import { useContext } from 'react';

function DemoCheckboxPrefilled(): JSX.Element {
  const { updateIsPrefilled, isPrefilled } = useContext(
    FoldersAndSimulatorContext
  );

  const changePrefilled = () => {
    updateIsPrefilled(!isPrefilled);
  };

  return (
    <div className="flex">
      <label
        htmlFor="checkbox"
        className="check-box dark:text-white text-[#191919] flex text-[.875rem] leading-[1.5rem] mr-[.625rem] gap-x-[.3rem] items-center"
      >
        <input
          readOnly
          checked={isPrefilled}
          onClick={() => changePrefilled()}
          type="checkbox"
          id="checkbox"
          className="check-box__switcher sb-text-rem-body2 appearance-none h-[1rem] cursor-pointer w-[2.25rem] rounded-[16px]"
        />
        Pré-rempli
      </label>
    </div>
  );
}

export default DemoCheckboxPrefilled;
