import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
  useRef,
} from 'react';
import {
  Button,
  CheckCard,
  Input,
  InputSearchInList,
  WarningNotification,
} from 'storybook-design-system-drapo';
import Loader from '@components/loaders/Loader';
import { useIsInViewport } from '@utils/utils';
import { FolderContext } from '@folder/utils/FoldersContext';
import {
  initialMaterialsData,
  initialSubcontractorsData,
  checkIfMaterialNeeded,
} from '@folder/utils/initialsState';
import {
  IMaterialDataTransformedType,
  IMaterialDataType,
  ISubcontractorDataType,
} from '@folder/utils/folderTypes';
import {
  addFinancialAgentDesignation,
  addOperationsMaterial,
  addOperationsSubcontractor,
  getInstallerSubcontractors,
} from '@folder/apiRequests/folderRequests';

import { InstallerContext } from '@auth/utils/authContext';
import { IKeyValueStringType } from '@simulator/utils/simulatorTypes';
import { initFilteredDatas } from '@simulator/utils/functions';

import {
  backgroundQuaternaryLightMode,
  dark1,
  dark4,
  red3,
  white,
  colorTextLightMode,
} from '@assets/colors';
import { FinancialAgentDesignation } from '@folder/components/forms/mprInstruction/FinancialAgentDesignation';
import NewSubcontractorForm from './forms/mprInstruction/NewSubcontractorForm';
import MaterialOperation from './forms/mprInstruction/MaterialOperation';

type MprInstructionProps = {
  setDivActive: Dispatch<SetStateAction<string | undefined>>;
  setHasInfosMaterials: Dispatch<SetStateAction<boolean>>;
};

function InformationsAboutFolder({
  setDivActive,
  setHasInfosMaterials,
}: MprInstructionProps): JSX.Element {
  const {
    mprAgree,
    folderDatas,
    folderReference,
    updateGlobalFiles,
    updateStepNumberFolder,
    updateFolderDatas,
  } = useContext(FolderContext);

  const { radiusPx, colorPrimary, installer, isThemeDark, colorTextButton } =
    useContext(InstallerContext);

  const [subcontractorsData, setSubcontractorsData] = useState<
    ISubcontractorDataType[]
  >(initialSubcontractorsData(folderDatas.operations));

  const [installerSubcontractors, setInstallerSubcontractors] = useState<
    IKeyValueStringType[]
  >(
    initFilteredDatas(
      [
        {
          id: '',
          raison_sociale: '',
        },
      ],
      'id',
      'raison_sociale'
    )
  );

  const [filteredSubcontractors, setFilteredSubcontractors] = useState<
    IKeyValueStringType[]
  >([]);

  const [isLoading, setIsLoading] = useState(false);

  const [materialsData, setMaterialsData] = useState<IMaterialDataType[]>(
    initialMaterialsData(folderDatas.operations)
  );
  const [agreeMprOK, setAgreeMprOK] = useState(false);
  const [operationsSubcontractorOk, setOperationsSubcontractorOk] =
    useState(false);
  const [operationsMaterialOk, setOperationsMaterialOk] = useState(false);
  const [materialNotCorrect, setMaterialNotCorrect] = useState<boolean>(false);
  const [noMaterialSelected, setNoMaterialSelected] = useState<boolean>(false);
  const hasMaterial: boolean = checkIfMaterialNeeded(folderDatas.operations);

  const [inputIsActive, setInputIsActive] = useState<boolean>(false);

  const [error, setError] = useState<string>('');
  /* const feesTotal = getFeesMpr(operations);

  const partenariatRef = useRef<HTMLDivElement>(null); */
  const subAndMaterialRef = useRef<HTMLDivElement>(null);
  const [isExecutive, setIsExecutive] = useState<boolean>(
    folderDatas.is_executive ? folderDatas.is_executive : true
  );
  const [executiveCode, setExecutiveCode] = useState<string>(
    folderDatas.executive_code ? folderDatas.executive_code : ''
  );

  useIsInViewport(
    subAndMaterialRef,
    'SubAndMaterial',
    setDivActive,
    folderDatas.has_mpr_step
  );

  useEffect(() => {
    if (agreeMprOK && operationsSubcontractorOk && operationsMaterialOk) {
      updateStepNumberFolder(3);
      setIsLoading(false);
    }
  }, [agreeMprOK, operationsSubcontractorOk, operationsMaterialOk]);

  useEffect(() => {
    getInstallerSubcontractors(setInstallerSubcontractors);
    setHasInfosMaterials(hasMaterial);
  }, []);

  useEffect(() => {
    setFilteredSubcontractors(
      initFilteredDatas(installerSubcontractors, 'id', 'raison_sociale')
    );
  }, [installerSubcontractors]);

  useEffect(() => {
    if (inputIsActive) {
      setDivActive('SubAndMaterial');
    }
  }, [inputIsActive]);

  const barth137 =
    'Raccordement d’un bâtiment résidentiel à un réseau de chaleur';

  const emailFormatOk = (emailToCheck: string) =>
    emailToCheck.match(/[a-z0-9_\-.]+@[a-z0-9_\-.]+\.[a-z]+/i) ||
    emailToCheck === '';

  const handleSearch = (
    searchText: string | ISubcontractorDataType,
    setFilteredArray: Dispatch<SetStateAction<IKeyValueStringType[]>>,
    datas: IKeyValueStringType[]
  ) => {
    if (typeof searchText === 'string') {
      if (searchText !== '') {
        const filteredBlocks = datas.filter((data) => {
          return data.label.toLowerCase().includes(searchText.toLowerCase());
        });
        setFilteredArray(filteredBlocks);
      } else {
        setFilteredArray(datas);
      }
    } else {
      const filteredBlocks = datas.filter((data) => {
        return data.label
          .toLowerCase()
          .includes(searchText.label.toLowerCase());
      });
      setFilteredArray(filteredBlocks);
    }
  };

  const handleSelectSubcontractor = (
    subcontractor: IKeyValueStringType,
    index: number
  ) => {
    const needEmail =
      installerSubcontractors.find((sub) => sub.id === subcontractor.value)
        ?.email === null;
    setSubcontractorsData((prevState) => {
      const newState = [...prevState];
      newState[index] = {
        ...newState[index],
        subcontractorId: subcontractor.value,
        subcontractor: needEmail
          ? {
              ...newState[index].subcontractor,
              email: '',
            }
          : undefined,
      };
      return newState;
    });
    setFilteredSubcontractors(
      initFilteredDatas(installerSubcontractors, 'id', 'raison_sociale')
    );
  };

  const checkIfMaterialEmpty = (listToCheck: IMaterialDataType[]): boolean => {
    for (let i = 0; i < listToCheck.length; i += 1) {
      if (
        folderDatas.operations.findIndex(
          (operation) =>
            listToCheck[i].operation === operation.operation_reference &&
            operation.hasMaterialStep &&
            listToCheck[i].referenceMark === ''
        ) !== -1
      ) {
        return true;
      }
    }
    return false;
  };

  const onClickNext = async () => {
    const isMaterialEmpty = checkIfMaterialEmpty(materialsData);
    const materialsDataTransformed: IMaterialDataTransformedType[] =
      materialsData;
    for (let i = 0; i < materialsDataTransformed.length; i += 1) {
      if (materialsDataTransformed[i].noStep) {
        materialsDataTransformed.splice(i, 1);
      } else if (materialsDataTransformed[i].referenceMark === '') {
        materialsDataTransformed[i].haveId = false;
        delete materialsDataTransformed[i].referenceMark;
      }
    }
    if (isMaterialEmpty && !noMaterialSelected && hasMaterial) {
      setNoMaterialSelected(true);
    } else {
      setNoMaterialSelected(false);
      setIsLoading(true);
      const dataPost = {
        operations: materialsDataTransformed,
      };
      const dataPostExecutive: {
        is_executive: boolean;
        executive_code?: string;
      } = {
        is_executive: isExecutive,
        executive_code: executiveCode,
      };

      if (folderDatas.has_mpr_step && !isExecutive) {
        delete dataPostExecutive.executive_code;
      }

      let materialRequestOk = !hasMaterial;
      let financialAgentDesignationRequestOk = true;
      if (folderDatas.has_mpr_step) {
        financialAgentDesignationRequestOk = await addFinancialAgentDesignation(
          folderReference,
          dataPostExecutive,
          setError,
          setIsLoading
        );
      }
      if (
        dataPost.operations.length > 0 &&
        hasMaterial &&
        financialAgentDesignationRequestOk
      ) {
        materialRequestOk = await addOperationsMaterial(
          folderReference,
          dataPost,
          setOperationsMaterialOk,
          setError,
          setIsLoading
        );
      } else {
        setOperationsMaterialOk(true);
        materialRequestOk = true;
      }
      if (materialRequestOk) {
        const subcontractorRequestOk = await addOperationsSubcontractor(
          folderReference,
          subcontractorsData,
          setOperationsSubcontractorOk,
          setError,
          setIsLoading,
          updateGlobalFiles,
          folderDatas,
          updateFolderDatas
        );
        if (subcontractorRequestOk) {
          setIsLoading(false);
          setAgreeMprOK(true);
        }
      }
    }
  };

  const checkHaveSubcontractor = (index: number) => {
    const newSubcontractor = subcontractorsData[index];
    if (newSubcontractor.action === 'no') {
      newSubcontractor.action = 'subcontractorId';
      if (folderDatas.operations[index].subcontractor) {
        newSubcontractor.label =
          folderDatas.operations[index].subcontractor
            ?.subcontractor_social_reason || '';
        newSubcontractor.subcontractorId =
          folderDatas.operations[index].subcontractor?.subcontractor_id;
      }
    } else {
      newSubcontractor.action = 'no';
      if (newSubcontractor.subcontractor) {
        delete newSubcontractor.subcontractor;
      }
      if (newSubcontractor.subcontractorId) {
        delete newSubcontractor.subcontractorId;
      }
    }
    setSubcontractorsData((prevState) => {
      const newSubcontractorsData = [...prevState];
      newSubcontractorsData[index] = newSubcontractor;
      return newSubcontractorsData;
    });
  };

  const createNewSubcontractor = (index: number) => {
    const newSubcontractor = subcontractorsData[index];
    newSubcontractor.action = 'subcontractor';
    setSubcontractorsData((prevState) => {
      const newSubcontractorsData = [...prevState];
      newSubcontractorsData[index] = newSubcontractor;
      return newSubcontractorsData;
    });
  };

  const cleanSubcontractor = (index: number) => {
    const newSubcontractor = subcontractorsData[index];
    newSubcontractor.action = 'subcontractorId';
    if (newSubcontractor.subcontractor) {
      delete newSubcontractor.subcontractor;
    }
    if (newSubcontractor.subcontractorId) {
      delete newSubcontractor.subcontractorId;
    }
    setSubcontractorsData((prevState) => {
      const newSubcontractorsData = [...prevState];
      newSubcontractorsData[index] = newSubcontractor;
      return newSubcontractorsData;
    });
  };

  const checkIfAllMandatoriesOk = () => {
    if (folderDatas.has_mpr_step && isExecutive && executiveCode === '') {
      return true;
    }
    for (let i = 0; i < subcontractorsData.length; i += 1) {
      if (
        (subcontractorsData[i].subcontractorId === undefined &&
          subcontractorsData[i].subcontractor === undefined &&
          subcontractorsData[i].action !== 'no') ||
        (subcontractorsData[i].subcontractor &&
          (subcontractorsData[i].subcontractor?.email === '' ||
            !emailFormatOk(
              subcontractorsData[i].subcontractor?.email as string
            )))
      ) {
        return true;
      }
    }
    if (materialNotCorrect) {
      return true;
    }
    if (isLoading) {
      return true;
    }

    if (!isLoading) {
      return false;
    }
    return false;
  };

  const applyNewSubcontractor = (datas: ISubcontractorDataType) => {
    const newSubcontractorsData: ISubcontractorDataType[] = [];
    for (let i = 0; i < subcontractorsData.length; i += 1) {
      const newSubcontractorData: ISubcontractorDataType =
        subcontractorsData[i];
      if (
        !subcontractorsData[i].subcontractor &&
        !subcontractorsData[i].subcontractorId
      ) {
        newSubcontractorData.action = 'subcontractor';
        newSubcontractorData.subcontractor = datas.subcontractor;
      }
      newSubcontractorsData.push(newSubcontractorData);
    }
    setSubcontractorsData(newSubcontractorsData);
  };
  return (
    <>
      {folderDatas.has_mpr_step && (
        <FinancialAgentDesignation
          isExecutive={isExecutive}
          setIsExecutive={setIsExecutive}
          executiveCode={executiveCode}
          setExecutiveCode={setExecutiveCode}
        />
      )}
      <div ref={subAndMaterialRef}>
        {mprAgree ? (
          <div className="pb-6 rounded-default">
            {subcontractorsData.map(
              (subcontractorData: ISubcontractorDataType, index) => {
                const operation = folderDatas.operations[index];
                const errorMail =
                  subcontractorData.subcontractor &&
                  !emailFormatOk(subcontractorData.subcontractor.email);
                return (
                  <div
                    style={{ borderRadius: radiusPx }}
                    key={subcontractorData.operation}
                    className="dark:bg-dark-2 bg-white p-[1.5rem] mt-[2rem] relative dark:text-white text-colorTextLightMode"
                  >
                    <div className="flex items-center justify-between mb-[1rem] dark:text-white text-colorTextLightMode">
                      <p className="sb-text-rem-body2">
                        Opération {index + 1} :{' '}
                        {folderDatas.operations[index].operation_name}
                      </p>
                    </div>
                    <p className="sb-text-rem-body2 mb-[.875rem]">
                      Sous-traitement:{' '}
                    </p>
                    <CheckCard
                      borderRadius={radiusPx}
                      colorHover={colorTextButton}
                      colorChecked={colorTextButton}
                      colorNotChecked={isThemeDark ? white : dark1}
                      backgroundColorNotChecked={
                        isThemeDark ? dark4 : backgroundQuaternaryLightMode
                      }
                      backgroundColorChecked={colorPrimary}
                      label="Réalisé par un sous-traitant"
                      onClick={() => checkHaveSubcontractor(index)}
                      addClass="px-[2.5rem!important] py-[1.75rem!important]"
                      checked={subcontractorData.action !== 'no'}
                    />
                    {subcontractorData.action !== 'no' && (
                      <>
                        {subcontractorData.action === 'subcontractorId' ? (
                          <>
                            <InputSearchInList
                              addClassScroll={
                                isThemeDark
                                  ? ''
                                  : 'scroll_light_mode_search_in_list'
                              }
                              color={isThemeDark ? white : dark1}
                              backgroundColorIconSearch={
                                isThemeDark ? dark4 : white
                              }
                              colorNotFoundText={
                                isThemeDark ? white : colorTextLightMode
                              }
                              backgroundColor={
                                isThemeDark
                                  ? dark1
                                  : backgroundQuaternaryLightMode
                              }
                              borderRadius={radiusPx}
                              onSearch={(searchValue) =>
                                handleSearch(
                                  searchValue,
                                  setFilteredSubcontractors,
                                  filteredSubcontractors
                                )
                              }
                              filteredList={filteredSubcontractors}
                              onSelectInList={(subcontractor) =>
                                handleSelectSubcontractor(subcontractor, index)
                              }
                              placeholder="Nom du sous-traitant"
                              addClass="mt-[.875rem]"
                              notFoundText="Ajouter un nouveau sous-traitant"
                              notFoundAction={() =>
                                createNewSubcontractor(index)
                              }
                              defaultSelectedItem={
                                operation.subcontractor
                                  ? {
                                      value:
                                        subcontractorsData[index]
                                          .subcontractorId,
                                      label: subcontractorsData[index].label,
                                    }
                                  : null
                              }
                              onClear={() => cleanSubcontractor(index)}
                              setIsActive={setInputIsActive}
                            />
                            {subcontractorData.subcontractor &&
                              (subcontractorData.subcontractor.email ||
                                subcontractorData.subcontractor.email ===
                                  '') && (
                                <>
                                  <Input
                                    inputType="email"
                                    label="Email du sous-taitant"
                                    value={
                                      subcontractorData.subcontractor.email
                                    }
                                    onChange={(e) => {
                                      setSubcontractorsData((prevState) => {
                                        const newState = [...prevState];
                                        newState[index] = {
                                          ...newState[index],
                                          subcontractor: {
                                            ...newState[index].subcontractor,
                                            email: e.target.value,
                                          },
                                        };
                                        return newState;
                                      });
                                    }}
                                    borderRadius={radiusPx}
                                    color={isThemeDark ? white : dark1}
                                    backgroundColor={
                                      isThemeDark
                                        ? dark1
                                        : backgroundQuaternaryLightMode
                                    }
                                    addClass={`mt-[.875rem] !pl-[2.25rem] ${
                                      errorMail ? 'border border-red-500' : ''
                                    }`}
                                  />
                                  {errorMail && (
                                    <p className="text-[.75rem] text-red-500 my-[1rem] ml-[.5rem]">
                                      Le format de l&apos;adresse email
                                      n&apos;est pas correct
                                    </p>
                                  )}
                                </>
                              )}
                          </>
                        ) : (
                          <NewSubcontractorForm
                            subcontractorsData={subcontractorsData}
                            setSubcontractorsData={setSubcontractorsData}
                            index={index}
                            applyNewSubcontractor={(
                              datas: ISubcontractorDataType
                            ) => applyNewSubcontractor(datas)}
                          />
                        )}
                      </>
                    )}
                    {operation.hasMaterialStep && (
                      <div>
                        <p className="sb-text-rem-body2 my-[.875rem]">
                          Sélection du matériel:{' '}
                        </p>
                        {operation.operation_name !== barth137 && (
                          <MaterialOperation
                            operation={operation}
                            setMaterialsData={setMaterialsData}
                            materialsData={materialsData}
                            operationIndex={index}
                            setMaterialNotCorrect={setMaterialNotCorrect}
                            setNoMaterialSelected={setNoMaterialSelected}
                            setInputIsActive={setInputIsActive}
                          />
                        )}
                      </div>
                    )}
                  </div>
                );
              }
            )}
            {isLoading && (
              <div className="mt-[.875rem]">
                <Loader />
              </div>
            )}
            {noMaterialSelected && (
              <WarningNotification
                borderRadius={radiusPx}
                warningText="Vous n’avez pas renseigné de matériel, il est conseillé de le faire pour un meilleur traitement de votre dossier. Pour continuer malgré tout, cliquez à nouveau sur Suivant."
                backgroundColor={
                  installer.nom_partenaire === 'DRAPO' ||
                  installer.nom_partenaire === ''
                    ? red3
                    : installer.custom.couleur_alerte
                }
                color="#ffffff"
                addClass="mt-[.875rem]"
              />
            )}
            {error !== '' && (
              <WarningNotification
                borderRadius={radiusPx}
                warningText={error}
                backgroundColor={
                  installer.nom_partenaire === 'DRAPO' ||
                  installer.nom_partenaire === ''
                    ? red3
                    : installer.custom.couleur_alerte
                }
                color="#ffffff"
                addClass="mt-[.875rem]"
              />
            )}
            <Button
              borderRadius={radiusPx}
              disabled={checkIfAllMandatoriesOk()}
              addClass="mt-[2rem] !w-full !px-[1rem]"
              buttonArrowSmall
              label="Suivant"
              color="white"
              backgroundColor={colorPrimary}
              arrow
              onClick={() => onClickNext()}
            />
          </div>
        ) : (
          <WarningNotification
            borderRadius={radiusPx}
            warningText="Vous ne pouvez pas continuer à compléter votre dossier si vous ne confiez pas la gestion de l’aide MaPrimeRénov’ à Drapo."
            backgroundColor={
              installer.nom_partenaire === 'DRAPO' ||
              installer.nom_partenaire === ''
                ? red3
                : installer.custom.couleur_alerte
            }
            color="#ffffff"
            addClass="mt-[.875rem]"
          />
        )}
      </div>
    </>
  );
}

export default InformationsAboutFolder;
