type ILabelsByValueType = {
  [key: string]: string;
};

export const LabelsByValue: ILabelsByValueType = {
  house: 'Maison',
  apartment: 'Appartement',
  owner: 'Propriétaire occupant en résidence principale',
  landlord: 'Propriétaire bailleur',
  other: 'Autre',
  '0': 'Moins de 2 ans',
  '3': 'Plus de 2 ans',
  '16': 'Plus de 15 ans',
  electricity: 'Electricité',
  combustible: 'Combustible',
};
