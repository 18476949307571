import { Dispatch, SetStateAction, useContext, useState } from 'react';
import { IKeyValueStringType } from '@simulator/utils/simulatorTypes';
import { Input, InputSearchInList } from 'storybook-design-system-drapo';
import { energyLabels } from '@simulator/utils/initialsState';
import { InstallerContext } from '@auth/utils/authContext';
import {
  backgroundQuaternaryLightMode,
  colorTextLightMode,
  dark1,
  dark4,
  white,
} from '@assets/colors';

interface EnergyConsumptionLabelHouseProps {
  energyConsumptionValue: string;
  setEnergyConsumptionValue: Dispatch<SetStateAction<string>>;
  energyLabel: string;
  setEnergyLabel: Dispatch<SetStateAction<string>>;
  isCurrent: boolean;
}

function EnergyConsumptionLabelHouse({
  energyConsumptionValue,
  setEnergyConsumptionValue,
  energyLabel,
  setEnergyLabel,
  isCurrent,
}: EnergyConsumptionLabelHouseProps): JSX.Element {
  const { radiusPx, isThemeDark } = useContext(InstallerContext);

  const [filteredEnergyLabels, setFilteredEnergyLabels] =
    useState<IKeyValueStringType[]>(energyLabels);

  const handleSearch = (
    searchText: string | IKeyValueStringType,
    setFilteredArray: Dispatch<SetStateAction<IKeyValueStringType[]>>,
    datas: IKeyValueStringType[]
  ) => {
    if (typeof searchText === 'string') {
      if (searchText !== '') {
        const filteredBlocks = datas.filter((data) => {
          return data.label.toLowerCase().includes(searchText.toLowerCase());
        });
        setFilteredArray(filteredBlocks);
      } else {
        setFilteredArray(datas);
      }
    } else {
      const filteredBlocks = datas.filter((data) => {
        return data.label
          .toLowerCase()
          .includes(searchText.label.toLowerCase());
      });
      setFilteredArray(filteredBlocks);
    }
  };

  const handleSelectEnergyLabel = (energyLabelSelect: IKeyValueStringType) => {
    setEnergyLabel(energyLabelSelect.value);
    setFilteredEnergyLabels(energyLabels);
  };

  const cleanEnergyLabel = () => {
    setEnergyLabel('');
  };

  return (
    <div
      style={{ borderRadius: radiusPx }}
      className="dark:bg-dark-2 bg-white animate__animated animate__fadeIn p-[1.5rem] mt-[1.5rem] leading-[2rem]"
    >
      <p className="mb-[1rem] text-[.875rem] dark:text-white text-dark-1">
        {`Consommation et étiquette énergétique ${
          isCurrent ? 'actuelle' : 'projetée'
        } du logement`}
      </p>
      <Input
        borderRadius={radiusPx}
        inputType="text"
        isNumber
        label={`Consommation énergétique ${
          isCurrent ? 'actuelle' : 'projetée'
        } du logement`}
        value={energyConsumptionValue}
        onClick={() => setEnergyConsumptionValue('')}
        onChange={(e) =>
          setEnergyConsumptionValue(e.target.value.replace(',', '.'))
        }
        symbol="kWh"
        color={isThemeDark ? white : dark1}
        backgroundColor={isThemeDark ? dark1 : backgroundQuaternaryLightMode}
        dataTestId="currentCecAndValue"
        addClass="mt-[1.5rem]"
      />
      <InputSearchInList
        onSearch={(searchValue) =>
          handleSearch(searchValue, setFilteredEnergyLabels, energyLabels)
        }
        filteredList={filteredEnergyLabels}
        onSelectInList={(energyLabelSelect) =>
          handleSelectEnergyLabel(energyLabelSelect)
        }
        placeholder={`Étiquette énergétique ${
          isCurrent ? 'actuelle' : 'projetée'
        } du logement`}
        onClear={cleanEnergyLabel}
        defaultSelectedItem={
          energyLabel !== ''
            ? {
                label: `Classe ${energyLabel}`,
                value: energyLabel,
              }
            : undefined
        }
        colorNotFoundText={isThemeDark ? dark1 : colorTextLightMode}
        backgroundColorIconSearch={isThemeDark ? dark4 : white}
        backgroundColor={isThemeDark ? dark1 : backgroundQuaternaryLightMode}
        addClassScroll={isThemeDark ? '' : 'scroll_light_mode_search_in_list'}
        borderRadius={radiusPx}
        color={isThemeDark ? white : dark1}
        addClass="mt-[1.5rem]"
      />
    </div>
  );
}

export { EnergyConsumptionLabelHouse };
