import { Dispatch, SetStateAction, useContext } from 'react';
import AgreeCheckCards from '@folder/components/forms/mprInstruction/AgreeCheckCards';
import { InstallerContext } from '@auth/utils/authContext';
import { Input } from 'storybook-design-system-drapo';
import { backgroundQuaternaryLightMode, dark1, white } from '@assets/colors';

interface FinancialAgentDesignationProps {
  isExecutive: boolean;
  setIsExecutive: Dispatch<SetStateAction<boolean>>;
  executiveCode: string;
  setExecutiveCode: Dispatch<SetStateAction<string>>;
}

function FinancialAgentDesignation({
  isExecutive,
  setIsExecutive,
  executiveCode,
  setExecutiveCode,
}: FinancialAgentDesignationProps) {
  const { radiusPx, isThemeDark } = useContext(InstallerContext);
  return (
    <div
      style={{ borderRadius: radiusPx }}
      className="dark:bg-dark-2 bg-lightMode-dark-2 px-[24px] pt-[24px] pb-[33px] mt-[32px]"
    >
      <p className="mb-[1rem] text-[.875rem] dark:text-white text-dark-1">
        La prime MPR sera perçue par :
      </p>
      <AgreeCheckCards
        typeAgree={isExecutive}
        updateAgree={setIsExecutive}
        isMpr
        trueLabel="Un mandataire financier"
        falseLabel="Le bénéficiaire des travaux"
      />
      {isExecutive && (
        <Input
          inputType="text"
          label="Code du mandataire financier"
          value={executiveCode}
          onChange={(e) => setExecutiveCode(e.target.value)}
          borderRadius={radiusPx}
          color={isThemeDark ? white : dark1}
          backgroundColor={isThemeDark ? dark1 : backgroundQuaternaryLightMode}
          dataTestId="executiveCodeInput"
          addClass="mt-[1.5rem]"
        />
      )}
    </div>
  );
}

export { FinancialAgentDesignation };
