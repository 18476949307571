import { useContext, useEffect, useState } from 'react';
import { CardLink } from 'storybook-design-system-drapo';
import { v4 } from 'uuid';
import LoaderCardlink from '@components/loaders/LoaderCardLink';
import { InstallerContext } from '@auth/utils/authContext';
import { SimulatorContext } from '@simulator/utils/simulatorContext';
import { getIncomes } from '@simulator/apiRequests/SimulatorRequests';
import { IIncomeType } from '@simulator/utils/simulatorTypes';
import { nextStepGeneral } from '@simulator/utils/functions';
import { colorTextLightMode, dark1, white } from '@assets/colors';
import { convertHexToRGBA } from '@utils/utils';
import { FoldersAndSimulatorContext } from '@folder/utils/FoldersContext';
import { useLocation } from 'react-router-dom';

function IncomesForm(): JSX.Element {
  const location = useLocation();

  const {
    updateSimulatorDatas,
    graphGeneral,
    updateGraphGeneral,
    updateGraphList,
    simulatorDatas,
    updateStepNumber,
    updateGeneralComplete,
    multipleOperations,
    updateLoaded,
    loaded,
  } = useContext(SimulatorContext);
  const { colorPrimary, radiusPx, isThemeDark, colorTextButton } =
    useContext(InstallerContext);

  const { isPrefilled } = useContext(FoldersAndSimulatorContext);

  const [incomesContent, setIncomesContent] = useState<IIncomeType[] | null>(
    null
  );

  useEffect(() => {
    if (isPrefilled) {
      const income: IIncomeType = {
        color: 'blue',
        label: '21 123 €',
        legend: 'Très modeste',
        menage: 'Ménage bleu',
        title: 'Inférieur à',
        value: '21122',
      };
      nextStepGeneral(
        simulatorDatas,
        multipleOperations,
        updateGeneralComplete,
        updateStepNumber,
        updateSimulatorDatas,
        updateGraphGeneral,
        updateGraphList,
        graphGeneral,
        graphGeneral.id,
        income
      );
    }
  }, [isPrefilled]);

  useEffect(() => {
    updateLoaded(false);
    getIncomes(simulatorDatas, setIncomesContent, updateLoaded);
  }, []);

  const onClickChange = (income: IIncomeType) => {
    const incomeWithoutImg = income;
    delete incomeWithoutImg.img;
    nextStepGeneral(
      simulatorDatas,
      multipleOperations,
      updateGeneralComplete,
      updateStepNumber,
      updateSimulatorDatas,
      updateGraphGeneral,
      updateGraphList,
      graphGeneral,
      graphGeneral.id,
      incomeWithoutImg
    );
  };

  return (
    <div className="grow pb-[2rem]">
      {!loaded && <LoaderCardlink />}
      {incomesContent && (
        <div
          style={{
            borderRadius: radiusPx,
            border:
              location.search.includes('?integration=iframe') && !isThemeDark
                ? '1px solid #d2d2d2'
                : '',
          }}
          className={`dark:bg-dark-2 bg-white  animate__animated animate__fadeIn p-[1.5rem] mt-[1.5rem] `}
        >
          {incomesContent.map((income: IIncomeType, index: number) => {
            return (
              <CardLink
                key={v4()}
                borderRadius={radiusPx}
                colorHover={colorTextButton}
                color={isThemeDark ? white : colorTextLightMode}
                backgroundColor={
                  isThemeDark ? dark1 : convertHexToRGBA(colorPrimary, 0.1)
                }
                backgroundColorHover={colorPrimary}
                addClass={`${
                  index === incomesContent.length - 1 ? 'mb-0' : 'mb-[1rem]'
                }`}
                title={`${income.legend} - ${income.menage}`}
                subtitle={income.title}
                price={income.label}
                onClick={() => onClickChange(income)}
                icon="arrow"
                dataTestId="card-incomes"
                urlImg={income.img ? income.img : undefined}
              />
            );
          })}
        </div>
      )}
    </div>
  );
}

export default IncomesForm;
