function LoaderDashboard(): JSX.Element {
  return (
    <div className="load load-dashboard rounded-[16px]">
      <div className="load-one" />
      <div className="load-two" />
      <div className="load-three" />
    </div>
  );
}

export default LoaderDashboard;
