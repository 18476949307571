import { useContext, useEffect, useState } from 'react';
import {
  custominfo_dark_folder,
  custominfo_folder,
} from '@assets/images/images';
import { RenderRightPart } from './RenderRightPart';
import { subAndMaterial, sub } from './ContentRightPart';
import { InstallerContext } from '../../../auth/utils/authContext';

interface RightPartMprInstructionProps {
  divRightWidth: number | undefined;
  divActive: string | undefined;
  hasInfosMaterials: boolean;
}

function RightPartInfosFolder({
  divRightWidth,
  divActive,
  hasInfosMaterials,
}: RightPartMprInstructionProps): JSX.Element {
  const [infosRightPart, setInfosRightPart] = useState<JSX.Element[]>();

  const { radiusPx, isThemeDark } = useContext(InstallerContext);

  useEffect(() => {
    if (hasInfosMaterials) {
      setInfosRightPart(subAndMaterial);
    } else {
      setInfosRightPart(sub);
    }
  }, [divActive]);

  return (
    <div
      style={{
        marginTop: '-1.25rem',
        width: divRightWidth,
        borderRadius: radiusPx,
        backgroundImage: `${
          isThemeDark
            ? `url(${custominfo_dark_folder})`
            : `url(${custominfo_folder})`
        }`,
        backgroundSize: 'cover',
      }}
      className="max-h-[90vh] fixed h-[90vh] z-[22222] dark:bg-black bg-white"
    >
      <RenderRightPart infosRightPart={infosRightPart} />
    </div>
  );
}

export default RightPartInfosFolder;
