import { IInstaller } from './authType';

export const initialInstaller: IInstaller = {
  contrat_cee: '',
  contrat_mpr: '',
  custom: {
    couleur_boutons: '',
    logo_mb_dark: [''],
    logo_mb_light: [''],
    sans_radius: '',
    couleur_alerte: '',
    image_illustration_mb: '',
    favicon: '',
  },
  eligibility: ['MPR', 'CEE'],
  email_rc: '',
  email_référent_technique: '',
  id: '',
  nom_partenaire: '',
  raison_sociale_de_lentreprise: '',
  adresse_email_de_lentreprise: '',
  téléphone_de_lentreprise: '',
  rge: null,
  rc: '',
  référent_technique: '',
  type_de_gestion_mpr: '',
  adresse_email_du_service_comptabilité: '',
  rue_du_siège_social: '',
  code_postal_du_siège_social: '',
  ville_du_siège_social: '',
  téléphone_référent_technique: '',
  téléphone_rc: '',
  photo_rc: '',
  photo_rt: '',
  desactiver_boutons: false,
  popup_mpr: false,
  trust_nd: false,
  has_photo_equilibrage: true,
};
