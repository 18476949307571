import React, {
  useContext,
  useState,
  useEffect,
  Dispatch,
  SetStateAction,
} from 'react';
import usePlacesAutocomplete from 'use-places-autocomplete';
import { FolderContext } from '@folder/utils/FoldersContext';
import { InstallerContext } from '@auth/utils/authContext';
import AddressForm from '../../../../../components/addressForm/AddressForm';

interface IAdressCustomerInformationProps {
  errorOwnerAddress: string;
  setErrorOwnerAddress: Dispatch<SetStateAction<string>>;
}

function AdressCustomerInformation({
  errorOwnerAddress,
  setErrorOwnerAddress,
}: IAdressCustomerInformationProps): JSX.Element {
  const [choiceOk, setChoiceOk] = useState(false);
  const [city, setCity] = useState('');
  const [street, setStreet] = useState('');
  const [zipCode, setZipCode] = useState('');

  const { radiusPx } = useContext(InstallerContext);

  const { isBack, folderDatas, updateFolderDatas } = useContext(FolderContext);

  useEffect(() => {
    updateFolderDatas({
      ...folderDatas,
      client: {
        ...folderDatas.client,
        owner_city: city,
        owner_street: street,
        owner_zipcode: zipCode,
      },
    });
  }, [city, street, zipCode]);

  const {
    value,
    suggestions: { data },
    setValue,
  } = usePlacesAutocomplete();

  useEffect(() => {
    const addressToPut = `${folderDatas.client.owner_street}, ${folderDatas.client.owner_city}, France`;
    if (folderDatas.client.owner_street && isBack) {
      setValue(addressToPut);
      setChoiceOk(true);
    }
  }, []);

  return (
    <div style={{ borderRadius: radiusPx }} className={` w-full`}>
      <AddressForm
        data={data}
        value={value}
        setValue={setValue}
        setCity={setCity}
        setStreet={setStreet}
        setZip={setZipCode}
        choiceOk={choiceOk}
        setChoiceOk={setChoiceOk}
        street={street}
        zip={zipCode}
        city={city}
        errorOwnerAddress={errorOwnerAddress}
        setErrorOwnerAddress={setErrorOwnerAddress}
      />
    </div>
  );
}

export default AdressCustomerInformation;
