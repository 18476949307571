import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { InstallerContext } from '@auth/utils/authContext';
import { ThemeCheckbox } from '@components/ThemeCheckbox';
import { LogoDrapoIcon } from '@assets/svgComponents/svgComponents';
import { white } from '@assets/colors';
import { useMediaQuery } from 'react-responsive';

function Logo(): JSX.Element {
  const { installer, isThemeDark } = useContext(InstallerContext);
  const isMobile = useMediaQuery({ maxWidth: 1024 });

  return (
    <div className="flex items-center justify-between">
      <Link to="/">
        <LogoDrapoIcon
          fill={isThemeDark ? white : 'black'}
          width="8.5rem"
          height="2.4rem"
        />
      </Link>
      {installer.raison_sociale_de_lentreprise === 'DÉMO' && !isMobile && (
        <ThemeCheckbox />
      )}
    </div>
  );
}

export default Logo;
