import { InstallerContext } from '@auth/utils/authContext';
import { useContext } from 'react';
import { v4 } from 'uuid';

function LoaderMyProfileGeneral(): JSX.Element {
  const { radiusPx } = useContext(InstallerContext);
  const array1 = [1, 2, 3, 4, 5, 6];
  const array2 = [1, 2, 3];

  const radius = radiusPx === '0px' ? '0px' : '8px';
  return (
    <>
      <div className="lg:w-5/12 pr-[1.875rem] lg:border-r dark:border-r-[#3C3B3B] pt-[1.875rem]">
        <div
          style={{ borderRadius: radiusPx }}
          className="h-[12.5rem] w-full dark:bg-[#272727] bg-[#E9E9E9] animate__infinite  animate__flash animate__animated animate__slower"
        />
        <div
          style={{ borderRadius: radiusPx }}
          className="h-[15.625rem] mt-[2.5rem] w-full dark:bg-[#272727] bg-[#E9E9E9] animate__infinite  animate__flash animate__animated animate__slower"
        />
      </div>
      <div className="lg:w-7/12 pl-[1.875rem] pt-[1.875rem]">
        <div className="h-[70vh] animate__infinite  animate__flash animate__animated animate__slower">
          <div
            style={{ borderRadius: radius }}
            className="dark:bg-[#272727] bg-[#E9E9E9] py-2 mb-5"
          />
          {array1.map(() => {
            return (
              <div
                style={{ borderRadius: radius }}
                key={v4()}
                className={`dark:bg-[#272727] bg-[#E9E9E9] py-6 my-2 `}
              />
            );
          })}
          <div
            style={{ borderRadius: radius }}
            className={`dark:bg-[#272727] bg-[#E9E9E9] py-2 mb-5 mt-5 `}
          />
          {array2.map(() => {
            return (
              <div
                style={{ borderRadius: radius }}
                key={v4()}
                className={`dark:bg-[#272727] py-6 my-2 `}
              />
            );
          })}
        </div>
      </div>
    </>
  );
}

export default LoaderMyProfileGeneral;
