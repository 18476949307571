import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import { v4 } from 'uuid';
import { numFormatSpace, convertHexToRGBA } from '@utils/utils';
import { colorTextLightMode, dark2, green1, red1, white } from '@assets/colors';
import { error, error_black } from '@assets/images/images';
import { IFolderSteps } from '@dashboard/utils/dashboardTypes';
import { IOperationsMonitoringDatas } from '@folderMonitoring/utils/folderMonitoringTypes';
import { InstallerContext } from '@auth/utils/authContext';

import {
  CheckCircleIcon,
  AutorenewIcon,
} from '@assets/svgComponents/svgComponents';
import MprCreateFolder from './MprCreateFolder';
import MprElectronicSignature from './MprElectronicSignature';
import MprInvoices from './MprInvoices';
import ErrorGeneral from './ErrorGeneral';
import CeeDocuments from './CeeDocuments';
import CeeAH from './CeeAH';
import { FolderMonitoringContext } from '../../utils/context/folderMonitoringContext';

interface IRecapFolderContentDetailsProps {
  operation: IOperationsMonitoringDatas;
  totalStepsCEE: number;
  totalStepsMPR: number;
  backgroundCEE: string;
  backgroundMPR: string;
  convertDDC: any[];
  setDetailsActive: Dispatch<SetStateAction<boolean>>;
  noCeeNoMpr: boolean;
}
function RecapFolderContentDetails({
  operation,
  totalStepsCEE,
  totalStepsMPR,
  backgroundCEE,
  backgroundMPR,
  convertDDC,
  setDetailsActive,
  noCeeNoMpr,
}: IRecapFolderContentDetailsProps): JSX.Element {
  const { folderDatas, stepIsUpdate, updateFolderDatas, hasAttestation } =
    useContext(FolderMonitoringContext);
  const { radiusPx, colorPrimary, isThemeDark, colorTextButton } =
    useContext(InstallerContext);

  const radius = radiusPx === '0px' ? '0px' : '16px';

  const [lastModifCEE, setLastModifCEE] = useState<string>('');
  const [lastModifMPR, setLastModifMPR] = useState<string>('');

  const [stepsMpr, setStepsMpr] = useState<IFolderSteps[]>([]);
  const [stepsCee, setStepsCee] = useState<IFolderSteps[]>([]);
  const [textMpr, setTextMpr] = useState<string[]>([]);
  const [textCee, setTextCee] = useState<string[]>([]);
  const [stepActiveCee, setStepActiveCee] = useState<string>('');
  const [stepActiveMpr, setStepActiveMpr] = useState<string>('');

  const [isAlone, setIsAlone] = useState<boolean>(false);
  const [hasInvoice, setHasInvoice] = useState<boolean>(false);

  const infosMpr = [
    {
      title: 'Primes',
      content: `${numFormatSpace(operation.montant_mpr)} € TTC`,
    },
    {
      title: 'Mise à jour le',
      content: lastModifMPR,
    },
    {
      title: 'Date de paiement estimé',
      content: operation.status_payment_mpr
        .replace('-', '/')
        .replace('-', '/')
        .replace('-', '/'),
    },
  ];

  const infosCee = [
    {
      title: 'Primes',
      content: `${numFormatSpace(operation.prime_cee_bénéficiaire)} € TTC`,
    },
    {
      title: 'Mise à jour le',
      content: lastModifCEE,
    },
    {
      title: 'Date de paiement estimé',
      content: operation.status_payment_cee
        .replace('-', '/')
        .replace('-', '/')
        .replace('-', '/'),
    },
  ];

  const infosStep4CEE: JSX.Element = (
    <>
      <div>
        Montant de prime CEE:{' '}
        <span className="text-dark-light-1">
          {operation.prime_cee_bénéficiaire &&
            `${operation.prime_cee_bénéficiaire}€`}
        </span>
      </div>
      <div>
        Frais Drapo:{' '}
        <span className="text-dark-light-1">
          {`${
            operation.frais_dossier_cee_ht
              ? operation.frais_dossier_cee_ht
              : '0'
          }`}
          € HT
        </span>
      </div>
      <div>
        Date estimative de votre virement:{' '}
        <span className="text-dark-light-1">
          {operation.date_estimée_virement_cee &&
            operation.date_estimée_virement_cee.replace(/-/g, '/')}
        </span>
      </div>
    </>
  );

  const allSteps = (steps: any, type: string) => {
    const tab: IFolderSteps[] = [];
    const convert = Object.keys(steps).map((index) => {
      return [steps[index]];
    });

    for (let i = 0; i < convert.length; i += 1) {
      const result = convert[i][0] !== null ? convert[i][0].split('.') : [''];
      tab.push({ stepNumber: result[0], stepName: result[1].replace(' ', '') });
    }

    if (type === 'mpr') {
      tab.pop();
    }

    tab.push({
      stepNumber: (tab.length + 1).toString(),
      stepName: 'Contrôle contact',
    });

    if (type === 'mpr') {
      setStepsMpr(tab);
    } else {
      setStepsCee(tab);
    }
  };

  const transformText = (mpr?: boolean) => {
    if (mpr) {
      const str =
        operation.text_mpr !== null ? operation.text_mpr.split('\n') : [''];
      setTextMpr(str);
    } else {
      const str =
        operation.text_cee !== null ? operation.text_cee.split('\n') : [''];
      setTextCee(str);
    }
  };

  useEffect(() => {
    if (operation.last_cee) {
      const date = new Date(operation.last_cee);
      setLastModifCEE(date.toLocaleDateString('fr'));
    } else {
      setLastModifCEE('N/C');
    }

    if (operation.last_mpr) {
      const date = new Date(operation.last_mpr);
      setLastModifMPR(date.toLocaleDateString('fr'));
    } else {
      setLastModifMPR('N/C');
    }

    transformText();
    transformText(true);

    allSteps(folderDatas.mpr_steps, 'mpr');
    allSteps(folderDatas.cee_steps, 'cee');
  }, []);

  useEffect(() => {
    if (stepIsUpdate) {
      updateFolderDatas(folderDatas);

      transformText();
      transformText(true);

      allSteps(folderDatas.mpr_steps, 'mpr');
      allSteps(folderDatas.cee_steps, 'cee');
    }
  }, [stepIsUpdate]);

  useEffect(() => {
    if (
      operation.type_dossier === 'MPR et CEE' &&
      operation.statut_cee !== 'Pas de CEE' &&
      operation.statut_mpr !== 'Pas de MPR'
    ) {
      setIsAlone(true);
    }
  }, [
    operation.type_dossier,
    operation.statut_cee,
    operation.status_payment_mpr,
  ]);

  useEffect(() => {
    if (operation.facture_travaux !== '{}') {
      setHasInvoice(true);
    }
  }, [operation.facture_travaux]);

  useEffect(() => {
    setStepActiveCee(
      operation.step_cee === '5' && operation.cofrac ? '6' : operation.step_cee
    );

    setStepActiveMpr(
      operation.step_mpr === '8' && operation.cofrac ? '9' : operation.step_mpr
    );
  }, [operation.cofrac, operation.step_cee, operation.step_mpr]);

  return (
    <div className="flex items-start text-dark-1 dark:text-white ">
      {noCeeNoMpr && (
        <div className="p-[2rem] w-full text-center font-[400]">
          Aucun suivi de disponible pour ce dossier
        </div>
      )}
      <div
        className={`${
          (operation.type_dossier === 'MPR et CEE' &&
            operation.statut_cee !== 'Pas de CEE') ||
          (operation.type_dossier === 'CEE' &&
            operation.statut_cee !== 'Pas de CEE')
            ? 'block flex flex-col space-y-[2rem] px-[2rem] pt-[.75rem] pb-[2rem]'
            : 'hidden'
        } ${isAlone ? 'w-1/2' : 'w-full'}`}
      >
        <div
          className={`flex ${
            isAlone ? 'items-start' : 'items-center'
          } justify-between`}
        >
          <div
            className={`flex ${
              isAlone ? 'items-start' : 'items-center'
            } space-x-[1.25rem] text-[.875rem] `}
          >
            <div className="font-[500]">CEE</div>
            <div
              className={`${
                isAlone
                  ? 'flex flex-col space-y-[.25rem]'
                  : 'flex items-center space-x-[1rem]'
              }`}
            >
              {infosCee.map((info) => (
                <div key={v4()}>
                  <span className="text-dark-light-1/[.92]"> {info.title}</span>{' '}
                  <span className="pl-[1rem]">{info.content}</span>
                </div>
              ))}
            </div>
          </div>
          <div className="text-[.875rem] font-[500]">
            {`${stepActiveCee}/${totalStepsCEE}`}
          </div>
        </div>
        <div>
          {stepsCee.map((step: IFolderSteps, index: number) => (
            <div key={v4()}>
              <div
                style={{
                  backgroundColor: `${
                    (parseFloat(step.stepNumber) ===
                      parseFloat(stepActiveCee) &&
                      !operation.cofrac) ||
                    (step.stepNumber === '6' && operation.cofrac)
                      ? backgroundCEE
                      : `${
                          !isThemeDark
                            ? convertHexToRGBA(colorPrimary, 0.1)
                            : dark2
                        }`
                  }`,
                  color: `${
                    (parseFloat(step.stepNumber) ===
                      parseFloat(stepActiveCee) &&
                      !operation.cofrac) ||
                    (step.stepNumber === '6' && operation.cofrac)
                      ? colorTextButton
                      : `${!isThemeDark ? colorTextLightMode : 'white'}`
                  }`,
                  borderTopLeftRadius: `${index === 0 && radius}`,
                  borderTopRightRadius: `${index === 0 && radius}`,
                  borderBottomLeftRadius: `${
                    index === stepsCee.length - 1 &&
                    parseFloat(stepActiveCee) !== stepsCee.length &&
                    radius
                  }`,
                  borderBottomRightRadius: `${
                    index === stepsCee.length - 1 &&
                    parseFloat(stepActiveCee) !== stepsCee.length &&
                    radius
                  }`,
                }}
                className={`py-[.75rem] px-[1rem] text-[.875rem] font-[500] flex items-center justify-between ${
                  index !== stepsCee.length - 1 &&
                  'border-b dark:border-b-dark-5 border-b-borderLightMode'
                }`}
              >
                <div className="flex pr-[1rem]">
                  <div className="whitespace-nowrap">
                    Étape {step.stepNumber} :&nbsp;
                  </div>
                  <div>{step.stepName}</div>
                </div>
                {parseFloat(step.stepNumber) <=
                  parseFloat(operation.step_cee) ||
                (stepActiveCee === '6' && operation.cofrac) ? (
                  <>
                    {parseFloat(step.stepNumber) < parseFloat(stepActiveCee) ||
                    backgroundCEE === green1 ? (
                      <CheckCircleIcon
                        width="1.5rem"
                        height="1.5rem"
                        fill={isThemeDark ? white : colorTextLightMode}
                      />
                    ) : (
                      <>
                        {backgroundCEE === red1 ? (
                          <img
                            className="w-[1.5rem]"
                            src={isThemeDark ? error : error_black}
                            alt="error"
                          />
                        ) : (
                          <AutorenewIcon
                            width="1.7rem"
                            height="1.7rem"
                            fill={colorTextButton}
                          />
                        )}
                      </>
                    )}
                  </>
                ) : (
                  <div className="text-[.75rem] opacity-[.92] whitespace-nowrap">
                    À venir
                  </div>
                )}
              </div>
              {parseFloat(step.stepNumber) === parseFloat(stepActiveCee) && (
                <div
                  style={{
                    borderBottomLeftRadius: `${
                      index === stepsCee.length - 1 && radius
                    } `,
                    borderBottomRightRadius: `${
                      index === stepsCee.length - 1 && radius
                    } `,
                  }}
                  className={`dark:bg-dark-2 bg-lightMode-dark-2  pt-[.75rem] pb-[1rem] text-[.75rem] text-justify dark:border-x-dark-2 border-x border-x-borderLightMode ${
                    index !== stepsCee.length - 1 &&
                    'border-b dark:border-b-dark-5 border-b-borderLightMode'
                  }`}
                >
                  <div className="px-[1rem] leading-[1.5rem]">
                    {stepActiveCee !== '5' ? (
                      <>
                        {stepActiveCee === '6' && operation.cofrac ? (
                          <>
                            <div>{operation.text_cofrac}</div>
                            {backgroundCEE === green1 && <>{infosStep4CEE}</>}
                          </>
                        ) : (
                          <>
                            {textCee.map((text) => (
                              <div key={v4()}>{text}</div>
                            ))}
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        {textCee.map((text) => (
                          <div key={v4()}>{text}</div>
                        ))}
                        {infosStep4CEE}
                      </>
                    )}
                  </div>
                  {backgroundCEE === red1 && (
                    <>
                      {stepActiveCee === '4' ? (
                        <>
                          {operation.statut_signature_ah ===
                          'Signature annulée' ? (
                            <CeeAH
                              reference_operation={
                                operation.référence_opération
                              }
                              canceledInAirtable
                            />
                          ) : (
                            <ErrorGeneral
                              convertDDC={convertDDC}
                              isMpr={false}
                              commentaries={
                                operation.commentaires_référents_dossier
                              }
                            />
                          )}
                        </>
                      ) : (
                        <>
                          {stepActiveCee !== '5' && (
                            <ErrorGeneral
                              convertDDC={convertDDC}
                              isMpr={false}
                              commentaries={
                                operation.commentaires_référents_dossier
                              }
                            />
                          )}
                        </>
                      )}
                    </>
                  )}
                  {stepActiveCee === '2' && (
                    <CeeDocuments
                      reference_operation={operation.référence_opération}
                      email={operation.adresse_email_client}
                      tel={operation.téléphone_client}
                      setDetailsActive={setDetailsActive}
                      operation={operation}
                      hasInvoice={hasInvoice}
                      /*
                      montant_facture_ttc_attestation_consentement_dev={
                        operation.montant_facture_ttc_attestation_consentement_dev
                      }
                      mprAmountQuote={folderDatas.mpr_amount_quote}
                      */
                    />
                  )}
                  {stepActiveCee === '4' && backgroundCEE === colorPrimary && (
                    <>
                      {operation.statut_signature_ah !==
                        'Documents de fin de chantier reçus' && (
                        <CeeAH
                          reference_operation={operation.référence_opération}
                        />
                      )}
                    </>
                  )}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
      <div
        className={`${
          (operation.type_dossier === 'MPR et CEE' &&
            operation.statut_mpr !== 'Pas de MPR') ||
          (operation.type_dossier === 'MPR' &&
            operation.statut_mpr !== 'Pas de MPR')
            ? 'block flex flex-col space-y-[2rem] px-[2rem] pt-[.75rem] pb-[2rem]'
            : 'hidden'
        } ${
          isAlone
            ? 'w-1/2 border-l dark:border-l-borderDarkMode border-l-borderLightMode'
            : 'w-full'
        }`}
      >
        <div
          className={`flex ${
            isAlone ? 'items-start' : 'items-center'
          } justify-between`}
        >
          <div
            className={`flex ${
              isAlone ? 'items-start' : 'items-center'
            } space-x-[1.25rem] text-[.875rem]`}
          >
            <div className="font-[500]">MPR</div>
            <div
              className={`${
                isAlone
                  ? 'flex flex-col space-y-[.25rem]'
                  : 'flex items-center space-x-[1rem]'
              }`}
            >
              {infosMpr.map((info) => (
                <div key={v4()}>
                  <span className="text-dark-light-1/[.92]"> {info.title}</span>{' '}
                  <span className="pl-[1rem]">{info.content}</span>
                </div>
              ))}
            </div>
          </div>
          <div className="text-[.875rem] font-[500]">
            {`${operation.step_mpr}/${totalStepsMPR}`}
          </div>
        </div>
        <div>
          {stepsMpr.map((step: IFolderSteps, index: number) => {
            const stepActive = stepActiveMpr.includes(step.stepNumber);
            return (
              <div key={v4()}>
                <div
                  style={{
                    backgroundColor: `${
                      stepActive
                        ? backgroundMPR
                        : `${
                            isThemeDark
                              ? dark2
                              : convertHexToRGBA(colorPrimary, 0.1)
                          }`
                    }`,
                    color: `${
                      stepActive
                        ? colorTextButton
                        : `${!isThemeDark ? colorTextLightMode : 'white'}`
                    }`,
                    borderTopLeftRadius: `${index === 0 && radius}`,
                    borderTopRightRadius: `${index === 0 && radius}`,
                    borderBottomLeftRadius: `${
                      index === stepsMpr.length - 1 && !stepActive && radius
                    }`,
                    borderBottomRightRadius: `${
                      index === stepsMpr.length - 1 && !stepActive && radius
                    }`,
                  }}
                  className={`py-[.75rem] px-[1rem] text-[.875rem] font-medium flex items-center justify-between ${
                    index !== stepsMpr.length - 1 &&
                    'border-b dark:border-b-dark-5 border-b-borderLightMode'
                  }`}
                >
                  <div className="flex pr-[1rem]">
                    <div className="whitespace-nowrap">
                      Étape {step.stepNumber} :&nbsp;
                    </div>
                    <div>{step.stepName}</div>
                  </div>
                  {index + 1 < parseFloat(stepActiveMpr) ||
                  (backgroundMPR === green1 && stepActive) ? (
                    <CheckCircleIcon
                      width="1.5rem"
                      height="1.5rem"
                      fill={isThemeDark ? white : colorTextLightMode}
                    />
                  ) : (
                    <>
                      {stepActive ? (
                        <>
                          {backgroundMPR === red1 ? (
                            <img
                              className="w-[1.5rem]"
                              src={isThemeDark ? error : error_black}
                              alt="error"
                            />
                          ) : (
                            <AutorenewIcon
                              width="1.7rem"
                              height="1.7rem"
                              fill={colorTextButton}
                            />
                          )}
                        </>
                      ) : (
                        <div className="text-[.75rem] opacity-[.92] whitespace-nowrap">
                          À venir
                        </div>
                      )}
                    </>
                  )}
                </div>
                {stepActive && (
                  <div
                    style={{
                      borderBottomLeftRadius: `${
                        index === stepsMpr.length - 1 && radius
                      }`,
                      borderBottomRightRadius: `${
                        index === stepsMpr.length - 1 && radius
                      }`,
                    }}
                    className={`dark:bg-dark-2 bg-lightMode-dark-2 pt-[.75rem] pb-[1rem] text-[.75rem] text-justify dark:border-none border border-borderLightMode border-t border-t-white dark:border-t-dark-2 ${
                      index !== stepsMpr.length - 1 &&
                      'border-b dark:border-b-dark-5 border-b-borderLightMode '
                    }`}
                  >
                    <div className="px-[1rem] leading-[1.5rem]">
                      {operation.step_mpr === '5' &&
                      hasAttestation &&
                      hasInvoice ? (
                        <div>
                          Drapo est en train d’étudier les différentes pièces du
                          dossier.
                        </div>
                      ) : (
                        <>
                          {textMpr.map((text: string, i: number) => {
                            if (
                              operation.statut_mpr &&
                              operation.statut_mpr.includes(
                                'En attente NO demande de subvention effectuée'
                              )
                            ) {
                              if (
                                i === textMpr.length - 1 ||
                                i === textMpr.length - 2
                              ) {
                                const j = textMpr[i].indexOf(':') + 1;
                                const bold = textMpr[i].substring(0, j);
                                const normal = textMpr[i].substring(j);

                                return (
                                  <div key={v4()}>
                                    <b>{bold}</b> {normal}
                                  </div>
                                );
                              }
                            }
                            if (
                              operation.statut_mpr &&
                              operation.statut_mpr.includes(
                                'Demande de solde effectuée'
                              ) &&
                              stepActiveMpr === '8'
                            ) {
                              return (
                                <>
                                  <div key={v4()}>{text}</div>
                                  <div>
                                    Montant ma PrimeRenov&apos;:{' '}
                                    <span className="text-dark-light-1">
                                      {operation.montant_mpr}€
                                    </span>
                                  </div>
                                  <div>
                                    Frais Drapo:{' '}
                                    <span className="text-dark-light-1">
                                      {operation.frais_dossier_mpr_ht} € HT (
                                      {operation.frais_dossier_mpr_ttc} € TTC)
                                    </span>
                                  </div>
                                  <div>
                                    Montant de votre virement:{' '}
                                    <span className="text-dark-light-1">
                                      {operation.virement_mpr_pro} €
                                    </span>
                                  </div>
                                  <div>
                                    Date estimative de votre virement:{' '}
                                    <span className="text-dark-light-1">
                                      {operation.date_estimée_virement_mpr &&
                                        operation.date_estimée_virement_mpr.replace(
                                          /-/g,
                                          '/'
                                        )}
                                    </span>
                                  </div>
                                </>
                              );
                            }
                            if (stepActiveMpr === '9' && operation.cofrac) {
                              return <div>{operation.text_cofrac}</div>;
                            }
                            return <div key={v4()}>{text}</div>;
                          })}
                        </>
                      )}
                    </div>
                    {backgroundMPR === red1 && (
                      <>
                        {operation.step_mpr === '2C' ? (
                          <MprElectronicSignature
                            email={operation.adresse_email_client}
                            tel={operation.téléphone_client}
                            reference_operation={operation.référence_opération}
                          />
                        ) : (
                          <ErrorGeneral
                            convertDDC={convertDDC}
                            isMpr
                            commentaries={
                              operation.commentaires_référents_dossier
                            }
                          />
                        )}
                      </>
                    )}
                    {operation.step_mpr === '1' && (
                      <MprCreateFolder setDetailsActive={setDetailsActive} />
                    )}
                    {operation.step_mpr === '5' && (
                      <MprInvoices
                        reference_operation={operation.référence_opération}
                        hasInvoice={hasInvoice}
                        /*
                        mprAmountQuote={folderDatas.mpr_amount_quote}
                        montant_facture_ttc_attestation_consentement_dev={
                          operation.montant_facture_ttc_attestation_consentement_dev
                        }
                        */
                      />
                    )}
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default RecapFolderContentDetails;
