import { useContext, useEffect, useState } from 'react';
import { numFormatSpace } from '@utils/utils';
import { trash, trash_black } from '@assets/images/images';
import { InstallerContext } from '@auth/utils/authContext';
import { FolderMonitoringContext } from '@folderMonitoring/utils/context/folderMonitoringContext';
import {
  IDDCConvert,
  IOperationsMonitoringDatas,
} from '@folderMonitoring/utils/folderMonitoringTypes';
import { ChevronIcon } from '@assets/svgComponents/svgComponents';
import RecapFolderContentDetails from './RecapFolderContentDetails';
import RecapFolderContentGeneral from './RecapFolderContentGeneral';

interface IRecapFolderContentProps {
  operation: IOperationsMonitoringDatas;
  totalStepsCEE: number;
  totalStepsMPR: number;
}

function RecapFolderContent({
  operation,
  totalStepsCEE,
  totalStepsMPR,
}: IRecapFolderContentProps): JSX.Element {
  const { radiusPx, isThemeDark } = useContext(InstallerContext);

  const {
    folderActif,
    updateDeleteOperationOrFolder,
    updateIsModalDeleteFolder,
    updateOperationActive,
    statutBackgroundColor,
  } = useContext(FolderMonitoringContext);

  const withoutCee = operation.statut_cee === 'Pas de CEE';
  const withoutMpr = operation.statut_mpr === 'Pas de MPR';

  const radius = radiusPx === '0px' ? '0px' : '8px';

  const [lastModif, setLastModif] = useState<string>('');
  const [backgroundCEE, setBackgroundCEE] = useState<string>('');
  const [bgWidthCEE, setBgWidthCEE] = useState<string>('');
  const [backgroundMPR, setBackgroundMPR] = useState<string>('');
  const [bgWidthMPR, setBgWidthMPR] = useState<string>('');
  const convertDDC: IDDCConvert[] =
    operation.ddc !== null
      ? Object.keys(operation.ddc).map((index) => {
          return {
            name: index,
            tab: operation.ddc !== null ? operation.ddc[index] : [],
            file: null,
            path: null,
          };
        })
      : [];
  const [operationName, setOperationName] = useState<string>('');
  const [detailsActive, setDetailsActive] = useState<boolean>(false);
  const [noCeeNoMpr, setNoCeeNoMpr] = useState<boolean>(false);

  const getBg = (stepNumber: string, totalStep: number, isCee?: boolean) => {
    // Si l'étape est une sous étape (2C par exemple), il y a forcément une action requise => on met le background en rouge
    if (stepNumber.length > 1) {
      return statutBackgroundColor.error;
    }
    if (totalStep === parseFloat(stepNumber)) {
      if (operation.cofrac) {
        switch (operation.step_cofrac) {
          default:
          case 'empty':
          case 'to_add':
          case 'in_progress':
            return statutBackgroundColor.inprogress;
          case 'unsatisfactory':
            return statutBackgroundColor.error;
          case 'not_checked':
          case 'not_cofrac':
            return statutBackgroundColor.done;
        }
      } else {
        return statutBackgroundColor.done;
      }
    }
    if (stepNumber === '4' && isCee) {
      if (operation.statut_signature_ah === 'Signature annulée') {
        return statutBackgroundColor.error;
      }
    }
    return statutBackgroundColor.inprogress;
  };

  const getWidthAndBg = () => {
    const percentCEE = (100 / totalStepsCEE) * parseFloat(operation.step_cee);
    const percentMPR = (100 / totalStepsMPR) * parseFloat(operation.step_mpr);

    setBgWidthMPR(`${Math.round(percentMPR)}%`);
    setBgWidthCEE(`${Math.round(percentCEE)}%`);

    setBackgroundMPR(getBg(operation.step_mpr, totalStepsMPR));
    setBackgroundCEE(getBg(operation.step_cee, totalStepsCEE, true));
  };

  // Si le dossier actif ne contient pas une opération supprimable et qu'elle n'a aucun bonus
  const showButtonDelete =
    folderActif.findIndex(
      (folder) =>
        folder.référence_opération === operation.référence_opération &&
        folder.deletable === 1 &&
        !folder.bonus
    ) !== -1;

  const dateToDisplay = () => {
    const dateCee = operation.last_cee ? new Date(operation.last_cee) : '';
    const dateMpr = operation.last_mpr ? new Date(operation.last_mpr) : '';

    // Si le type de dossier de l'opération est MPR & CEE et qu'elle est éligible MPR & CEE
    if (operation.type_dossier === 'MPR et CEE' && !withoutMpr && !withoutCee) {
      if (dateCee !== '' && dateMpr !== '') {
        if (dateCee > dateMpr) {
          setLastModif(dateCee.toLocaleDateString('fr'));
        } else {
          setLastModif(dateMpr.toLocaleDateString('fr'));
        }
      } else {
        setLastModif('N/C');
      }
      // Si le type de dossier de l'opération est uniquement MPR ou qu'elle n'est pas éligible CEE
    } else if (operation.type_dossier === 'MPR' || withoutCee) {
      setLastModif(dateMpr !== '' ? dateMpr.toLocaleDateString('fr') : 'N/C');
      // Si le type de dossier de l'opération est uniquement CEE ou qu'elle n'est pas éligible MPR
    } else if (operation.type_dossier === 'CEE' || withoutMpr) {
      setLastModif(dateCee !== '' ? dateCee.toLocaleDateString('fr') : 'N/C');
    }
  };

  useEffect(() => {
    const libelle =
      operation.libellé_opération !== null
        ? operation.libellé_opération.replace(/["{}]/g, '').split('- BAR')
        : [''];
    setOperationName(libelle[0]);
    dateToDisplay();
    setNoCeeNoMpr(withoutCee && withoutMpr);

    getWidthAndBg();
  }, [operation]);

  return (
    <>
      <div
        style={{
          borderTopLeftRadius: radius,
          borderTopRightRadius: radius,
          borderBottomLeftRadius: `${
            noCeeNoMpr && !detailsActive ? radius : ''
          }`,
          borderBottomRightRadius: `${
            noCeeNoMpr && !detailsActive ? radius : ''
          }`,
        }}
        className={`${
          detailsActive
            ? 'border-b dark:border-b-borderDarkMode border-b-borderLightMode'
            : 'dark:border-none border-b border-b-borderLightMode'
        } dark:bg-dark-3 bg-white pt-[1.5rem] px-[2rem] text-dark-1 dark:text-white`}
      >
        <div
          className={`flex items-center justify-between ${
            detailsActive ? 'pb-[1.5rem]' : 'pb-[1rem]'
          }`}
        >
          <div className="font-medium text-[1.25rem]">{operationName}</div>
          <div className="flex items-center">
            {showButtonDelete && (
              <button
                type="button"
                onClick={() => {
                  updateDeleteOperationOrFolder('operation');
                  updateIsModalDeleteFolder(true);
                  updateOperationActive(operation);
                }}
              >
                <img
                  className="mr-[1rem] w-[1.5rem]"
                  alt="supprimer"
                  src={isThemeDark ? trash : trash_black}
                />
              </button>
            )}
            <ChevronIcon
              fill={isThemeDark ? 'white' : '#191919'}
              width="2.5rem"
              height="2.5rem"
              className="transition-transform duration-300 cursor-pointer"
              onClick={() => setDetailsActive(!detailsActive)}
              transform={detailsActive ? 'rotate(-90)' : 'rotate(90)'}
            />
          </div>
        </div>
        {!detailsActive && (
          <div className="flex items-center space-x-[1rem]">
            <div className="text-[.875rem] pb-[1.25rem]">
              <span className="text-dark-light-1 opacity-[.92]">
                Mise à jour le
              </span>{' '}
              <span className="opacity-[.92]">
                {lastModif !== '' ? lastModif : 'N/C'}
              </span>
            </div>
            <div className="text-[.875rem] pb-[1.25rem]">
              <span className="text-dark-light-1 opacity-[.92]">
                Montant TTC des travaux :
              </span>{' '}
              <span className="opacity-[.92]">{`${
                operation.montant_ttc_devis
                  ? `${numFormatSpace(operation.montant_ttc_devis)} €`
                  : 'N/C'
              }`}</span>
            </div>
          </div>
        )}
      </div>
      <div
        style={{
          borderBottomLeftRadius: radius,
          borderBottomRightRadius: radius,
        }}
        className="dark:bg-dark-5 bg-white w-full"
      >
        {detailsActive ? (
          <RecapFolderContentDetails
            operation={operation}
            totalStepsCEE={totalStepsCEE}
            totalStepsMPR={totalStepsMPR}
            backgroundCEE={backgroundCEE}
            backgroundMPR={backgroundMPR}
            convertDDC={convertDDC}
            setDetailsActive={setDetailsActive}
            noCeeNoMpr={noCeeNoMpr}
          />
        ) : (
          <RecapFolderContentGeneral
            operation={operation}
            totalStepsCEE={totalStepsCEE}
            totalStepsMPR={totalStepsMPR}
            backgroundCEE={backgroundCEE}
            backgroundMPR={backgroundMPR}
            bgWidthCEE={bgWidthCEE}
            bgWidthMPR={bgWidthMPR}
            setDetailsActive={setDetailsActive}
          />
        )}
      </div>
    </>
  );
}

export default RecapFolderContent;
