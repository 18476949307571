import {
  editPencilWhite,
  editPencilBlack,
  validate,
} from '@assets/images/images';

import { InstallerContext } from '@auth/utils/authContext';
import { updateInformationMyProfile } from '@dashboard/apiRequests/dashboardRequests';
import { MyProfileContext } from '@dashboard/utils/context/myProfileContext';
import { Dispatch, SetStateAction, useContext, useState } from 'react';
import { useForm } from 'react-hook-form';

import { v4 } from 'uuid';
import { numFormatSpace } from '@utils/utils';

import {
  ICardInformation,
  IMyProfilDatas,
} from '@dashboard/utils/dashboardTypes';
import { colorTextLightMode, purple1, white } from '@assets/colors';
import { CloseIcon } from '@assets/svgComponents/svgComponents';
import InputUpdate from '../InputUpdate';

interface IProps {
  dataArray: ICardInformation[];
  title: string;
  setDatasMyProfil: Dispatch<SetStateAction<IMyProfilDatas>>;
}
function CardInformation({
  dataArray,
  title,
  setDatasMyProfil,
}: IProps): JSX.Element {
  const { handleSubmit, register } = useForm();
  const [isEditable, setIsEditable] = useState(['']);
  const [errorBic, setErrorBic] = useState('');
  const [errorIban, setErrorIban] = useState('');
  const [errorTelephone, setErrorTelephone] = useState('');
  const [errorUrl, setErrorUrl] = useState('');

  const { installer, radiusPx, isThemeDark } = useContext(InstallerContext);
  const { updateIsUpdateLoading, isUpdateLoading } =
    useContext(MyProfileContext);

  const radius = radiusPx === '0px' ? '0px' : '8px';

  const editAction = (value: string | null) => {
    if (value !== null) {
      if (isEditable.includes(value)) {
        setIsEditable(isEditable.filter((el: string) => el !== value));
      } else {
        setIsEditable([...isEditable, value]);
      }
    }
  };

  const onSubmit = (data: any, value: any, field: any) => {
    const patternUrl = new RegExp(
      '^((http|https)://){1}(www[.])?([a-zA-Z0-9]|-)+([.][a-zA-Z0-9(-|/|=|?)?]+)+$'
    );
    if (field === 'site_web_de_lentreprise') {
      if (data.site_web_de_lentreprise.match(patternUrl) === null) {
        setErrorUrl(" L'URL saisie n'est pas valide.");
      } else {
        updateIsUpdateLoading(true);
        updateInformationMyProfile(
          field,
          data[field],
          setDatasMyProfil,
          updateIsUpdateLoading,
          setIsEditable,
          isEditable
        );
        setErrorUrl('');
      }
    } else if (field === 'iban') {
      if (
        data.iban.length < 23 ||
        data.iban.length > 34 ||
        data.iban.includes(' ')
      ) {
        setErrorIban(
          " L'IBAN saisi n'est pas valide, il doit comporter entre 23 à 34 caractères."
        );
      } else {
        updateIsUpdateLoading(true);
        updateInformationMyProfile(
          field,
          data[field],
          setDatasMyProfil,
          updateIsUpdateLoading,
          setIsEditable,
          isEditable
        );
        setErrorIban('');
      }
    } else if (field === 'bic') {
      if (
        data.bic.length < 8 ||
        data.bic.length > 11 ||
        data.bic.includes(' ')
      ) {
        setErrorBic(
          "Le BIC saisi n'est pas valide, il doit comporter entre 8 à 11 caractères."
        );
      } else {
        updateIsUpdateLoading(true);
        updateInformationMyProfile(
          field,
          data[field],
          setDatasMyProfil,
          updateIsUpdateLoading,
          setIsEditable,
          isEditable
        );
        setErrorBic('');
      }
    } else if (field === 'téléphone_de_lentreprise') {
      if (
        data.téléphone_de_lentreprise.length < 10 ||
        data.téléphone_de_lentreprise.length > 10 ||
        data.téléphone_de_lentreprise.substr(0, 1) !== '0'
      ) {
        setErrorTelephone("Le numéro de téléphone saisi n'est pas valide.");
      } else {
        updateIsUpdateLoading(true);
        updateInformationMyProfile(
          field,
          data[field],
          setDatasMyProfil,
          updateIsUpdateLoading,
          setIsEditable,
          isEditable
        );
        setErrorTelephone('');
      }
    } else {
      updateIsUpdateLoading(true);
      updateInformationMyProfile(
        field,
        data[field],
        setDatasMyProfil,
        updateIsUpdateLoading,
        setIsEditable,
        isEditable
      );
    }
  };

  const typeInput = (label: string) => {
    let type = '';
    if (
      label === 'Téléphone de l’entreprise' ||
      label === 'Nombre de salariés' ||
      label === 'Chiffre d’affaires'
    ) {
      type = 'number';
    } else {
      type = 'text';
    }
    return type;
  };
  return (
    <div className="w-full ">
      <p className="font-medium text-[1.125rem] dark:text-white text-dark-2">
        {title}
      </p>
      <div className="mt-[.375rem]">
        {dataArray.map((el: ICardInformation) => (
          <div key={v4()} className="flex ">
            <div
              style={{
                borderBottom:
                  el.label !== 'Adresse email de la comptabilité' &&
                  el.label !== 'Capital Social'
                    ? '2px solid rgba(112, 112, 112, 0.2)'
                    : '',
              }}
              className={`w-11/12 ${
                isEditable &&
                el.value.value !== null &&
                !isEditable.includes(el.value.value)
                  ? 'flex justify-between'
                  : ''
              }  py-[.5rem] dark:text-white text-dark-2`}
            >
              <p className="desktop:text-[.875rem] text-[.75rem] font-medium">
                {el.label}
              </p>
              {el.value.value !== null &&
                !isEditable.includes(el.value.value) && (
                  <p className="desktop:text-[1rem] text-[.875rem]">
                    {el.label === 'Capital Social' ||
                    el.label === 'Chiffre d’affaires' ? (
                      <>{`${numFormatSpace(el.value.value)} €`}</>
                    ) : (
                      <>{el.value.value}</>
                    )}
                  </p>
                )}
              {isEditable.includes(
                el.value.value !== null ? el.value.value : '$$$'
              ) && (
                <form
                  onSubmit={handleSubmit((data) =>
                    onSubmit(data, el.value.value, el.value.field)
                  )}
                  action="update Password"
                  className="pb-[1.25rem]"
                >
                  <div className="w-full flex justify-between items-center relative">
                    <button
                      disabled={isUpdateLoading}
                      style={{
                        opacity: isUpdateLoading ? 0.5 : 1,
                      }}
                      className="absolute right-[4.375rem] bottom-4 hover:opacity-80"
                      type="button"
                      onClick={() =>
                        setIsEditable(
                          isEditable.filter(
                            (item: string) => item !== el.value.value
                          )
                        )
                      }
                    >
                      <CloseIcon
                        fill={isThemeDark ? white : colorTextLightMode}
                        width="1rem"
                        height="1rem"
                      />
                    </button>
                    <div
                      style={{
                        opacity: isUpdateLoading ? 0.5 : 1,
                        pointerEvents: isUpdateLoading ? 'none' : 'auto',
                      }}
                      className="w-full"
                    >
                      <InputUpdate
                        placeholder={
                          el.value.value !== null ? el.value.value : '$$$'
                        }
                        register={register}
                        name={el.value.field}
                        id={el.value.field}
                        required={false}
                        type={typeInput(el.label)}
                      />
                    </div>
                    <button
                      disabled={isUpdateLoading}
                      style={{
                        borderRadius: radius,
                        backgroundColor:
                          installer.nom_partenaire === 'DRAPO' ||
                          installer.nom_partenaire === ''
                            ? purple1
                            : installer.custom.couleur_boutons,
                        opacity: isUpdateLoading ? 0.5 : 1,
                      }}
                      className="p-[1rem] ml-[.625rem] mt-[.375rem]"
                      type="submit"
                    >
                      <img src={validate} alt="valider" />
                    </button>
                  </div>
                  <p className="text-sm mt-1 text-red-500">
                    {el.value.field === 'iban' && errorIban}
                  </p>
                  <p className="text-sm mt-1 text-red-500">
                    {el.value.field === 'bic' && errorBic}
                  </p>
                  <p className="text-sm mt-1 text-red-500">
                    {el.value.field === 'téléphone_de_lentreprise' &&
                      errorTelephone}
                  </p>
                  <p className="text-sm mt-1 text-red-500">
                    {el.value.field === 'site_web_de_lentreprise' && errorUrl}
                  </p>
                </form>
              )}
            </div>

            {el.value.editable === true &&
              el.value.value !== null &&
              !isEditable.includes(el.value.value) && (
                <button
                  onClick={() => editAction(el.value.value)}
                  type="button"
                >
                  <img
                    className="ml-[1.25rem]"
                    src={isThemeDark ? editPencilWhite : editPencilBlack}
                    alt="modifier"
                  />
                </button>
              )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default CardInformation;
