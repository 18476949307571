import axios from 'axios';
import { Dispatch, SetStateAction } from 'react';
import { NavigateFunction } from 'react-router-dom';
import { IWhiteLabelCustomType } from '@simulator/utils/simulatorTypes';
import { IInstaller } from '../utils/authType';

const apiUrl = process.env.REACT_APP_BACK_PRO_API_URL;

export const authRequests = (
  email: string,
  password: string,
  updateToken: React.Dispatch<React.SetStateAction<string>>,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setErrMessage: React.Dispatch<React.SetStateAction<string>>,
  setErrBorderMail: React.Dispatch<React.SetStateAction<string>>,
  setErrBorderPassword: React.Dispatch<React.SetStateAction<string>>,
  router: NavigateFunction,
  isMobile: boolean,
  updateTokenConnected?: Dispatch<SetStateAction<boolean>>
) => {
  axios
    .post(`${apiUrl}/installer/auth`, {
      email,
      password,
    })
    .then((res) => {
      localStorage.setItem('permission', JSON.stringify(res.data.permissions));
      localStorage.setItem(
        'token_expires_at',
        JSON.stringify(res.data.expires_at)
      );
      updateToken(res.data.token);
      if (!isMobile) {
        router(-1);
      } else {
        router('/demander-ma-prime');
      }
      if (updateTokenConnected) {
        updateTokenConnected(true);
      }
    })
    .catch((error) => {
      setLoading(false);
      if (!error.response) {
        setErrMessage('Le serveur ne répond pas');
      } else if (error.response.data.error) {
        setErrMessage(error.response.data.error);
        setErrBorderMail('border border-red-500');
        setErrBorderPassword('border border-red-500');
      } else {
        setErrMessage(
          'Nous rencontrons des problèmes pour vous connecter, veuillez réessayer plus tard'
        );
      }
    });
};

export const getInstaller = (
  setInstaller: Dispatch<SetStateAction<IInstaller>>,
  updateInstallerIsLoaded: Dispatch<SetStateAction<boolean>>,
  eligibilityInstallerDemo: string[],
  demoActive: boolean,
  setIsPrefilled?: Dispatch<SetStateAction<boolean>>
) => {
  axios.get(`${apiUrl}/installer`).then((res) => {
    if (
      res.data.installer.raison_sociale_de_lentreprise === 'DÉMO' &&
      demoActive
    ) {
      setInstaller({
        ...res.data.installer,
        eligibility: eligibilityInstallerDemo,
      });
    } else {
      setInstaller(res.data.installer);
    }

    if (
      res.data.installer.raison_sociale_de_lentreprise === 'DÉMO' &&
      setIsPrefilled !== undefined &&
      process.env.REACT_APP_SWITCH_PREFILLED === 'true' &&
      localStorage.getItem('isPrefilled') === null
    ) {
      setIsPrefilled(true);
    }

    updateInstallerIsLoaded(true);
  });
};

export const autoLoginRequest = (
  urlToken: string,
  updateToken: Dispatch<SetStateAction<string>>,
  router: NavigateFunction,
  updateTokenConnected?: Dispatch<SetStateAction<boolean>>
) => {
  axios
    .get(`${apiUrl}/installer/autologin/${urlToken}`)
    .then((res) => {
      localStorage.setItem('permission', JSON.stringify(res.data.permissions));
      localStorage.setItem(
        'token_expires_at',
        JSON.stringify(res.data.expires_at)
      );
      updateToken(res.data.token);
      router('/');
      if (updateTokenConnected) {
        updateTokenConnected(true);
      }
    })
    .catch(() => {
      router('/connexion', {
        state: { prevPath: 'autologin' },
        replace: true,
      });
    });
};

export const getWhiteLabelLogin = (
  whiteLabel: string,
  setWhiteLabelCustom: Dispatch<SetStateAction<IWhiteLabelCustomType>>,
  router: NavigateFunction,
  setIsLoading: Dispatch<SetStateAction<boolean>>
) => {
  axios
    .get(`${apiUrl}/wl/${whiteLabel}`)
    .then((response) => {
      setIsLoading(false);
      if (response.data === undefined) {
        router('/connexion', { replace: true });
      } else {
        setWhiteLabelCustom(response.data);
      }
    })
    .catch(() => {
      router('/connexion', { replace: true });
      setIsLoading(false);
    });
};
