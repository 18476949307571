import axios from 'axios';
import { Dispatch, SetStateAction } from 'react';
import { NavigateFunction } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  arrayOfOperationsData,
  generalDatasFunction,
  initFilteredDatas,
} from '@simulator/utils/functions';

import {
  IKeyValueStringType,
  ILatLng,
  IOperationDatasType,
  IPrimesType,
  ISimulatorDatasType,
  ICadastre,
} from '@simulator/utils/simulatorTypes';
import { convertToYYYYMMDD } from '@utils/utils';
import {
  IAccountMPR,
  ITaxHousehold,
  ISubcontractorDataType,
  IFileFolderType,
  IFileDatasType,
  IMaterialDataTransformedType,
  IFolderDatasType,
  IDimensioningNoteType,
  IDimensioningNoteMaterial,
} from '../utils/folderTypes';

const apiUrl = process.env.REACT_APP_BACK_PRO_API_URL;

// requètes folders
export const createFolder = (
  datas: ISimulatorDatasType,
  operations: IOperationDatasType[],
  router: NavigateFunction,
  updateFolderReference: Dispatch<SetStateAction<string>>,
  updateOwnerOrLandlord: Dispatch<SetStateAction<string>>,
  setLoaded: Dispatch<SetStateAction<boolean>>,
  setError: Dispatch<SetStateAction<string>>,
  setErrorQuota: Dispatch<SetStateAction<string[]>>,
  ceeIsSelect: boolean,
  mprIsSelect: boolean,
  cadastresData: ICadastre
) => {
  const primesToPush: IPrimesType[] = [];
  const operationsToPush: IOperationDatasType[] = [];
  for (let i = 0; i < operations.length; i += 1) {
    const primeToPush = operations[i].primes;
    if (!ceeIsSelect) {
      primeToPush.cee = 0;
      primeToPush.cdp = 0;
    }
    if (!mprIsSelect) {
      primeToPush.mpr = 0;
    }
    if (
      primeToPush.cee !== 0 ||
      primeToPush.cdp !== 0 ||
      primeToPush.mpr !== 0
    ) {
      primesToPush.push(primeToPush);
      operationsToPush.push(operations[i]);
    }
  }

  const noCadastre =
    Object.values(cadastresData).includes(null) ||
    Object.values(cadastresData).includes(0) ||
    Object.values(cadastresData).includes('');

  let dataPost = {};

  if (!noCadastre) {
    dataPost = {
      general: generalDatasFunction(datas),
      operations: arrayOfOperationsData(operationsToPush),
      primes: primesToPush,
      cadastre: cadastresData,
      mpr: mprIsSelect ? 1 : 0,
      cee: ceeIsSelect ? 1 : 0,
    };
  } else {
    dataPost = {
      general: generalDatasFunction(datas),
      operations: arrayOfOperationsData(operationsToPush),
      primes: primesToPush,
      mpr: mprIsSelect ? 1 : 0,
      cee: ceeIsSelect ? 1 : 0,
    };
  }

  axios
    .put(`${apiUrl}/dossier/create`, dataPost)
    .then((response) => {
      setLoaded(false);
      updateFolderReference(response.data.folder_reference);
      updateOwnerOrLandlord(datas.owner);
      router(
        `/informations-complementaires/${response.data.folder_reference}`,
        { replace: true }
      );
    })
    .catch((error) => {
      setLoaded(false);

      if (error.response.status === 422) {
        setError(
          "Un problème dans l'envoi des données pour la création du dossier est survenu et le dossier n'a pas pu être créé. Merci de contacter votre Référent de compte."
        );
      } else if (error.response.data?.error?.toLowerCase().includes('quota')) {
        const tab =
          error.response.data.error !== null
            ? error.response.data.error.split(',')
            : [''];
        tab.unshift();
        let classique = [''];
        let precaire = [''];

        for (let i = 0; i < tab.length; i += 1) {
          if (tab[i].includes('Gisement')) {
            if (tab[i].includes('classique')) {
              classique = [
                tab[i + 1].replace(/[^0-9/.]+/g, ''),
                tab[i + 2].replace(/[^0-9/.]+/g, ''),
              ];
            } else {
              precaire = [
                tab[i + 1].replace(/[^0-9/.]+/g, ''),
                tab[i + 2].replace(/[^0-9/.]+/g, ''),
              ];
            }
          }
        }
        setErrorQuota([
          'Vous avez atteint le quota autorisé pour valoriser des CEE. Merci de contacter votre Référent de compte.',
          'Pour rappel :',
          `- Votre quota actuel de Gisement précaire est de ${precaire[1]} sur ${precaire[0]} GWh cumac.`,
          `- Votre quota actuel de Gisement classique est de ${classique[1]} sur ${classique[0]} GWh cumac.`,
        ]);
      } else {
        setError("Un problème est survenu, le dossier n'a pas pu être créé.");
      }
    });
};

// RECUPERE LES INFOS D'UN DOSSIER
export const getFolder = (
  folderReference: string,
  setFolderData: Dispatch<SetStateAction<IFolderDatasType>>,
  setHousingOwner: Dispatch<SetStateAction<string>>,
  setStepNumberFolder: Dispatch<SetStateAction<number>>,
  setGlobalFiles: Dispatch<SetStateAction<IFileFolderType[] | []>>,
  setParcelPrefix: Dispatch<SetStateAction<string>>,
  setParcelSection: Dispatch<SetStateAction<string>>,
  setParcelNumber: Dispatch<SetStateAction<string>>,
  setHouseholdsDatas: Dispatch<SetStateAction<ITaxHousehold[]>>,
  setFolderFinded: Dispatch<SetStateAction<boolean>>,
  setRedirection: Dispatch<SetStateAction<boolean>>,
  setMenageIndivision: Dispatch<SetStateAction<boolean>>,
  setHasCadastre: Dispatch<SetStateAction<boolean>>,
  setWorkAddress: Dispatch<SetStateAction<string>>,
  setWorkCoordinates: Dispatch<SetStateAction<IKeyValueStringType>>,
  setClientInfosAlreadySet: Dispatch<SetStateAction<boolean>>,
  setHasIndivision: Dispatch<SetStateAction<boolean>>,
  updateHasDimensioningNote: Dispatch<SetStateAction<boolean>>,
  setRoomsHeight: Dispatch<SetStateAction<string>>,
  setStopTempHeatPump: Dispatch<SetStateAction<string>>,
  setTempPowerHeatPump: Dispatch<SetStateAction<string>>,
  setHeatingElementPower: Dispatch<SetStateAction<string>>,
  setOtherHeatingSystems: Dispatch<SetStateAction<string>>,
  setDimensioningNote: Dispatch<SetStateAction<string>>,
  setShowDimensioningNote: Dispatch<SetStateAction<boolean>>,
  setHousingValue: Dispatch<SetStateAction<IKeyValueStringType[]>>
) => {
  return axios
    .get(`${apiUrl}/dossier/${folderReference}`)
    .then((response) => {
      const folderDataToSet = response.data;
      if (response.data.status === 'pushing_airtable') {
        setRedirection(true);
      }
      setFolderFinded(true);
      setGlobalFiles(response.data.files);
      updateHasDimensioningNote(response.data.has_note_step);
      if (response.data.has_note_step) {
        for (let i = 0; i < response.data.operations.length; i += 1) {
          if (response.data.operations[i].file_dimension_note) {
            setShowDimensioningNote(true);
            setDimensioningNote(
              response.data.operations[i].file_dimension_note[0]
            );
            setRoomsHeight(response.data.operations[i].height_rooms);
            setStopTempHeatPump(
              response.data.operations[i].stop_temperature_heat_pump
            );
            setTempPowerHeatPump(
              response.data.operations[i].temperature_power_heat_pump
            );
            setHeatingElementPower(
              response.data.operations[i].heating_element_power
            );
            setOtherHeatingSystems(
              response.data.operations[i].other_heating_systems
            );
          }

          const generalValue: IKeyValueStringType[] = [
            {
              title: 'altitude',
              value: response.data.operations[i].altitude,
            },
            {
              title: 'temperature_mode',
              value: response.data.operations[i].temperature_mode,
            },
            {
              title: 'emitter_type',
              value: response.data.operations[i].emitter_type,
            },
            {
              title: 'construction_type',
              value: response.data.operations[i].construction_type,
            },
          ];
          setHousingValue(generalValue);
        }
      }

      if (response.data.has_client) {
        setClientInfosAlreadySet(true);
      } else if (response.data.client) {
        folderDataToSet.client = response.data.client;
      } else {
        folderDataToSet.client = {
          gender: 'Monsieur',
        };
      }
      setFolderData(folderDataToSet);
      if (response.data.work_street && response.data.work_city) {
        setWorkAddress(
          `${response.data.work_street}, ${response.data.work_city}`
        );
      }
      if (response.data.has_cadastre_step) {
        setHasCadastre(response.data.has_cadastre_step);
      }
      if (response.data.has_indivision_step) {
        setHasIndivision(response.data.has_indivision_step);
      }
      if (response.data.housing_owner) {
        setHousingOwner(response.data.housing_owner);
      }
      if (response.data.in_airtable === true) {
        setRedirection(true);
      } else if (response.data.front_status === 'step3') {
        setStepNumberFolder(3);
      } else if (
        response.data.front_status === 'step2' ||
        response.data.status === 'Mandataire financier'
      ) {
        setStepNumberFolder(2);
      } else if (response.data.front_status === 'step4') {
        setStepNumberFolder(4);
      }
      if (response.data.work_land_register) {
        const workLand =
          response.data.work_land_register !== null
            ? response.data.work_land_register.split('-')
            : [''];
        setParcelPrefix(workLand[0]);
        setParcelSection(workLand[1]);
        setParcelNumber(workLand[2]);
      }
      if (response.data.work_latitude && response.data.work_longitude) {
        setWorkCoordinates({
          latitude: response.data.work_latitude,
          longitude: response.data.work_longitude,
        });
      }
      if (response.data.nb_fiscal) {
        const householdDatas = [];
        for (let i = 1; i <= response.data.nb_fiscal; i += 1) {
          householdDatas.push({
            numero: response.data[`number_fiscal_client_${i}`],
            reference: response.data[`number_avis_fiscal_client_${i}`],
            declarant: response.data[`name_declarant_${i}`],
          });
        }
        if (response.data.menage_joint_ownership) {
          setMenageIndivision(response.data.menage_joint_ownership);
        }
        setHouseholdsDatas(householdDatas);
      }
    })
    .catch(() => {
      setRedirection(true);
    });
};

// LIER LE CLIENT AU DOSSIER
export const addClientToFolder = (
  folderDatas: IFolderDatasType,
  updateFolderDatas: Dispatch<SetStateAction<IFolderDatasType>>,
  setClientInfosOk: Dispatch<SetStateAction<boolean>>,
  setIsLoading: Dispatch<SetStateAction<boolean>>,
  setErrMail: Dispatch<SetStateAction<string>>,
  setErrorQuota: Dispatch<SetStateAction<string>>,
  updateClientInfosAlreadySet: Dispatch<SetStateAction<boolean>>,
  setErrorOwnerAddress: Dispatch<SetStateAction<string>>,
  dateDeposite: string,
  file: File | undefined,
  dataAccountMpr: IAccountMPR,
  isCheckDeclareIdMpr: boolean,
  isCheckFolderDeposite: boolean,
  birthDate: string
) => {
  const formData = new FormData();
  formData.append('client_firstname', folderDatas.client.firstname);
  formData.append('client_lastname', folderDatas.client.lastname);
  formData.append('client_email', folderDatas.client.email);
  formData.append('client_phone', folderDatas.client.phone);
  formData.append('client_gender', folderDatas.client.gender);
  formData.append('client_owner_street', folderDatas.client.owner_street);
  formData.append('client_owner_zipcode', folderDatas.client.owner_zipcode);
  formData.append('client_owner_city', folderDatas.client.owner_city);
  if (folderDatas.has_mpr_step) {
    formData.append('client_date_of_birth', convertToYYYYMMDD(birthDate));
  }
  if (isCheckDeclareIdMpr) {
    formData.append('mpr_id', dataAccountMpr.id);
    formData.append('mpr_password', dataAccountMpr.password);
    if (isCheckFolderDeposite) {
      formData.append('file_award_notification', file || '');
      formData.append('mpr_date', dateDeposite);
      formData.append('mpr_attest', '1');
    }
  }
  return axios
    .post(
      `${apiUrl}/dossier/${folderDatas.folder_reference}/client/add`,
      formData
    )
    .then((response) => {
      setClientInfosOk(true);
      updateFolderDatas({ ...folderDatas, status: response.data.status });
      updateClientInfosAlreadySet(true);
      return true;
    })
    .catch((error) => {
      console.log(error);
      setIsLoading(false);
      if (error.response.data.error.toLowerCase().includes('quota')) {
        setErrorQuota(
          'Vous avez atteint le quota autorisé pour valoriser des CEE. Merci de contacter votre Référent de compte.'
        );
      }
      if (error.response.status === 403) {
        if (error.response.data.error.includes('mail')) {
          setErrMail(error.response.data.error);
        }
        if (error.response.data.error.includes('owner informations needed')) {
          setErrorOwnerAddress(
            'Lors de la simulation, vous avez indiqué que votre client était un Propriétaire Bailleur. Veuillez indiquer l’adresse de résidence de votre client.'
          );
        }
      }
      return false;
    });
};

// LIER LE CADASTRE AU DOSSIER
export const addCadastreToFolder = (
  folderReference: string,
  parcelPrefix: string,
  parcelSection: string,
  parcelNumber: string,
  setCadastreOk: Dispatch<SetStateAction<boolean>>,
  setIsLoading: Dispatch<SetStateAction<boolean>>,
  setErrorOnClickNext: Dispatch<SetStateAction<string>>
) => {
  const dataPost = {
    cadastre: {
      prefix: parcelPrefix,
      section: parcelSection,
      number: parcelNumber,
    },
  };

  return axios
    .put(`${apiUrl}/dossier/${folderReference}/cadastre`, dataPost)
    .then(() => {
      setCadastreOk(true);
      return true;
    })
    .catch((error) => {
      console.log(error);
      setIsLoading(false);
      setErrorOnClickNext(error.response.data.error);
      return false;
    });
};

// ADD INDIVISION
export const addIndivisionToFolder = (
  folderReference: string,
  isIndivision: boolean,
  setIndivisionOk: Dispatch<SetStateAction<boolean>>,
  setIsLoading: Dispatch<SetStateAction<boolean>>,
  setErrorOnClickNext: Dispatch<SetStateAction<string>>
) => {
  const data = {
    indivision: isIndivision,
    folder: folderReference,
  };

  return axios
    .put(`${apiUrl}/dossier/${folderReference}/indivision`, data)
    .then(() => {
      setIndivisionOk(true);
      return true;
    })
    .catch((error) => {
      console.log(error);
      setIsLoading(false);
      setErrorOnClickNext(error.response.data.error);
      return false;
    });
};

// LIER LES INFORMATIONS FISCALES AU DOSSIER
export const addTaxHouseholdToFolder = (
  nameClient: string,
  firstNameClient: string,
  folderReference: string,
  householdsDatas: ITaxHousehold[],
  setTaxHouseholdOk: Dispatch<SetStateAction<boolean>>,
  setIsLoading: Dispatch<SetStateAction<boolean>>,
  setErrorOnClickNext: Dispatch<SetStateAction<string>>
) => {
  // eslint-disable-next-line no-param-reassign
  householdsDatas[0].declarant = `${firstNameClient} ${nameClient}`;
  const dataPost = { fiscal: householdsDatas, full: true };

  return axios
    .put(`${apiUrl}/dossier/${folderReference}/fiscal`, dataPost)
    .then(() => {
      setTaxHouseholdOk(true);
      setIsLoading(false);
      return true;
    })
    .catch((error) => {
      console.log(error);
      setIsLoading(false);
      setErrorOnClickNext(error.response.data.error);
      return false;
    });
};

// RECUPERE LES SOUS TRAITANTS D'UN INSTALLATEUR
export const getInstallerSubcontractors = (
  setInstallerSubcontractors: Dispatch<SetStateAction<IKeyValueStringType[]>>
) => {
  axios.get(`${apiUrl}/installer/subcontractors/search`).then((response) => {
    setInstallerSubcontractors(response.data);
  });
};

// RECUPERE LA LISTE DU MATERIEL
export const getMaterials = (
  setMaterials: Dispatch<SetStateAction<IKeyValueStringType[]>>,
  operationSlug: string
) => {
  axios.get(`${apiUrl}/material/search/${operationSlug}`).then((response) => {
    setMaterials(initFilteredDatas(response.data, 'id', 'title'));
  });
};

// ADD SUBCONTRACTORS
export const addOperationsSubcontractor = (
  folderReference: string,
  subcontractorsData: ISubcontractorDataType[],
  setIsOk: Dispatch<SetStateAction<boolean>>,
  setError: Dispatch<SetStateAction<string>>,
  setIsLoading: Dispatch<SetStateAction<boolean>>,
  updateGlobalFiles: Dispatch<SetStateAction<IFileFolderType[]>>,
  folderDatas: IFolderDatasType,
  updateFolderDatas: Dispatch<SetStateAction<IFolderDatasType>>
) => {
  const dataPost = {
    operations: subcontractorsData,
    full: true,
  };

  return axios
    .put(`${apiUrl}/dossier/${folderReference}/subcontractor`, dataPost)
    .then((response) => {
      setIsOk(true);
      updateGlobalFiles(response.data.files);
      updateFolderDatas({
        ...folderDatas,
        operations: response.data.operations,
      });
      return true;
    })
    .catch((error) => {
      console.log(error);
      setError(
        "Nous avons rencontré un problème lors de l'envoi concernant les sous-taitants."
      );
      setIsLoading(false);
      return false;
    });
};

// ADD MATERIALS
export const addOperationsMaterial = (
  folderReference: string,
  dataPost: { operations: IMaterialDataTransformedType[] },
  setIsOk: Dispatch<SetStateAction<boolean>>,
  setError: Dispatch<SetStateAction<string>>,
  setIsLoading: Dispatch<SetStateAction<boolean>>
) => {
  return axios
    .put(`${apiUrl}/dossier/${folderReference}/material`, dataPost)
    .then(() => {
      setIsOk(true);
      return true;
    })
    .catch((error) => {
      console.log(error);
      setError(
        "Nous avons rencontré un problème lors de l'envoi concernant le matériel."
      );
      setIsLoading(false);
      return false;
    });
};

export const addFinancialAgentDesignation = (
  folderReference: string,
  dataPost: { is_executive: boolean; executive_code?: string },
  setError: Dispatch<SetStateAction<string>>,
  setIsLoading: Dispatch<SetStateAction<boolean>>
) => {
  return axios
    .put(`${apiUrl}/dossier/${folderReference}/executive`, dataPost)
    .then(() => {
      return true;
    })
    .catch((error) => {
      console.log(error);
      setError(
        "Nous avons rencontré un problème lors de l'envoi des données concernant la désignation du mandataire financier."
      );
      setIsLoading(false);
      return false;
    });
};

// RECUPERE LISTES DEROULANTES DIMENSIONING NOTE
export const getDimensioningNoteDatas = (
  refFolder: string,
  setHousingInfos: Dispatch<SetStateAction<IDimensioningNoteType[]>>,
  setMaterial: Dispatch<SetStateAction<IDimensioningNoteMaterial | undefined>>
) => {
  axios
    .get(`${apiUrl}/dossier/${refFolder}/note-dimensionnement/list`)
    .then((response) => {
      const generalInfos: IDimensioningNoteType[] = [
        {
          title: 'Altitude (m)',
          values: response.data.altitude,
          slug: 'altitude',
        },
        {
          title: 'Mode de température',
          values: response.data.temperature_mode,
          slug: 'temperature_mode',
        },
        {
          title: 'Type d’émetteur',
          values: response.data.emitter_type,
          slug: 'emitter_type',
        },
        {
          title: 'Type de construction',
          values: response.data.construction_type,
          slug: 'construction_type',
        },
      ];
      setHousingInfos(generalInfos);
      setMaterial(response.data.material);
    });
};

// ENVOIS LES INFOS POUR LA NOTE DE DIMENSIONEMENT
export const sendDimensioningNoteDatas = (
  folderReference: string,
  setIsLoading: Dispatch<SetStateAction<boolean>>,
  housingInfos: IDimensioningNoteType[],
  roomsHeight: string,
  stopTempHeatPump: string,
  tempPowerHeatPump: string,
  heatingElementPower: string,
  otherHeatingSystems: string,
  housingValue: IKeyValueStringType[],
  updateHousingValue: Dispatch<SetStateAction<IKeyValueStringType[]>>,
  updateShowDimensioningNote: Dispatch<SetStateAction<boolean>>,
  updateDimensioningNote: Dispatch<SetStateAction<string>>,
  hasDimensioningNotePro: boolean,
  fileToSend: File | undefined,
  updateStepNumberFolder: Dispatch<SetStateAction<number>>,
  updateDimensioningNotePro: Dispatch<SetStateAction<File | undefined>>,
  installerTrustNd: boolean | undefined
) => {
  const config = {
    headers: {
      ContentType: 'multipart/form-data',
    },
  };

  const tab = housingValue;
  for (let i = 0; i < tab.length; i += 1) {
    if (tab[i].value === undefined) {
      tab[i].value =
        housingInfos[
          housingInfos.findIndex(
            (el: IDimensioningNoteType) => el.slug === tab[i].title
          )
        ].values[0];
    }
  }
  updateHousingValue(tab);

  const formData = new FormData();
  formData.append('stop_temperature_heat_pump', stopTempHeatPump);
  formData.append('temperature_power_heat_pump', tempPowerHeatPump);
  formData.append('heating_element_power', heatingElementPower);
  formData.append('other_heating_systems', otherHeatingSystems);
  formData.append(
    'altitude',
    housingValue[housingValue.findIndex((el) => el.title === 'altitude')].value
  );
  formData.append(
    'temperature_mode',
    housingValue[
      housingValue.findIndex((el) => el.title === 'temperature_mode')
    ].value
  );
  formData.append(
    'emitter_type',
    housingValue[housingValue.findIndex((el) => el.title === 'emitter_type')]
      .value
  );
  formData.append(
    'construction_type',
    housingValue[
      housingValue.findIndex((el) => el.title === 'construction_type')
    ].value
  );
  formData.append('height_rooms', roomsHeight);
  formData.append('has_nd', !hasDimensioningNotePro ? '0' : '1');
  formData.append('trust_nd', installerTrustNd ? '1' : '0');

  if (hasDimensioningNotePro && fileToSend !== undefined) {
    formData.append('file_dimension_note_pro', fileToSend);
    if (installerTrustNd) {
      formData.delete('stop_temperature_heat_pump');
      formData.delete('temperature_power_heat_pump');
      formData.delete('heating_element_power');
      formData.delete('other_heating_systems');
    }
    updateDimensioningNotePro(fileToSend);
  }

  axios
    .post(
      `${apiUrl}/dossier/${folderReference}/note-dimensionnement`,
      formData,
      config
    )
    .then((response) => {
      const date = new Date();
      let dimensioningNote = '';
      for (let i = 0; i < response.data.operations.length; i += 1) {
        if (response.data.operations[i].file_dimension_note) {
          dimensioningNote = `${response.data.operations[i].file_dimension_note[0]}?${date}`;
        }
      }
      setIsLoading(false);
      updateDimensioningNote(dimensioningNote);
      updateShowDimensioningNote(true);
      if (hasDimensioningNotePro) {
        updateStepNumberFolder(4);
      }
    })
    .catch((error) => {
      setIsLoading(false);
      console.log(error);
    });
};

// ADD UPLOAD FILES
export const addUploadFile = (
  folderReference: string,
  file: File,
  fileExpected: IFileFolderType,
  updateGlobalFiles: Dispatch<SetStateAction<IFileFolderType[]>>,
  updateFolderDatas: Dispatch<SetStateAction<IFolderDatasType>>,
  folderDatas: IFolderDatasType,
  setErr: Dispatch<SetStateAction<boolean>>,
  setErrMessage: Dispatch<SetStateAction<string>>,
  setRightPartActive: Dispatch<SetStateAction<boolean>>,
  setCategoryToDelete: Dispatch<SetStateAction<string>>,
  setFileUploadName: Dispatch<SetStateAction<string>>,
  setFileToShow: Dispatch<SetStateAction<string | undefined>>,
  operationRerence?: string
) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('type', fileExpected.type);
  formData.append('full', 'true');

  if (operationRerence) {
    formData.append('operation', operationRerence);
  }

  const config = {
    headers: {
      ContentType: 'multipart/form-data',
    },
  };
  axios
    .post(`${apiUrl}/dossier/${folderReference}/file`, formData, config)
    .then((response) => {
      if (operationRerence) {
        updateFolderDatas({
          ...folderDatas,
          operations: response.data.operations,
        });
      } else {
        updateGlobalFiles(response.data.files);
      }
      setFileToShow(URL.createObjectURL(file));
    })
    .catch((error) => {
      setErr(true);
      setRightPartActive(true);
      setCategoryToDelete(fileExpected.title);
      setFileUploadName(file.name);
      setErrMessage(
        `Un problème est survenu lors du téléchargement de votre fichier ${file.name}. Veuillez réessayer.`
      );
      console.log(error);
      if (error.response.status === 413) {
        setErrMessage(`Le fichier ${file.name} est trop volumineux.`);
      } else {
        setErrMessage(`Impossible de télécharger le fichier ${file.name}`);
      }
    });
};

// DELETE UPLOADED FILES
export const deleteUploadedFile = (
  setIsAwait: Dispatch<SetStateAction<boolean>>,
  fileExpected: IFileFolderType,
  folderReference: string,
  fileName: string,
  updateGlobalFiles: Dispatch<SetStateAction<IFileFolderType[]>>,
  updateFolderData: Dispatch<SetStateAction<IFolderDatasType>>,
  folderDatas: IFolderDatasType,
  operationRef?: string
) => {
  const fileDatas: IFileDatasType = {
    type: fileExpected.type,
    file: fileName,
    full: true,
  };

  if (operationRef) {
    fileDatas.operation = operationRef;
  }

  const config = {
    data: fileDatas,
  };
  axios
    .delete(`${apiUrl}/dossier/${folderReference}/file`, config)
    .then((response) => {
      if (
        !fileExpected.mandatory ||
        (fileExpected.path !== null && fileExpected.path.length !== 0)
      ) {
        setIsAwait(false);
      }
      if (operationRef) {
        updateFolderData({
          ...folderDatas,
          operations: response.data.operations,
        });
      } else {
        updateGlobalFiles(response.data.files);
      }
    })
    .catch((error) => {
      console.log(error);
    });
};

// SEND FOLDER TO AIRTABLE
export const sendFolderToAirtable = (
  folderReference: string,
  setIsLoading: Dispatch<SetStateAction<boolean>>,
  setFolderInCreation: Dispatch<SetStateAction<boolean>>,
  setErr: Dispatch<SetStateAction<boolean>>,
  setErrMessage: Dispatch<SetStateAction<string>>
) => {
  const config = {
    headers: {
      ContentType: 'multipart/form-data',
    },
  };
  axios
    .get(`${apiUrl}/dossier/${folderReference}/send`, config)
    .then(() => {
      setFolderInCreation(true);
      toast('⌛ Dossier en cours de création.');
    })
    .catch((error) => {
      setFolderInCreation(false);
      setIsLoading(false);
      setErrMessage(
        'Un problème est survenu lors de la création de votre dossier.'
      );
      setErr(true);
      console.log(error);
    });
};

export const getParcelMapFolder = (
  LatLng: ILatLng,
  setCoordinate: React.Dispatch<React.SetStateAction<ILatLng[]>>,
  updateParcelPrefix: React.Dispatch<React.SetStateAction<string>>,
  updateParcelSection: React.Dispatch<React.SetStateAction<string>>,
  updateParcelNumber: React.Dispatch<React.SetStateAction<string>>,
  setLoadParcel: React.Dispatch<React.SetStateAction<boolean>>,
  setParcelError: React.Dispatch<React.SetStateAction<boolean>>
) => {
  axios
    .get(
      `https://apicarto.ign.fr/api/cadastre/parcelle?geom=%7B%22type%22%3A%20%22Point%22%2C%22coordinates%22%3A%5B${LatLng.lng}%2C${LatLng.lat}%5D%7D`
    )
    .then((response) => {
      const results = response.data.features[0].geometry.coordinates[0][0];
      updateParcelPrefix(response.data.features[0].properties.code_arr);
      updateParcelSection(response.data.features[0].properties.section);
      updateParcelNumber(response.data.features[0].properties.numero);
      const coordinatesCadastre = results.map((item: string) => {
        return { lat: item[0], lng: item[1] };
      });
      setCoordinate(coordinatesCadastre);
      setLoadParcel(false);
      setParcelError(false);
    })
    .catch((err) => {
      console.log(err);
      setLoadParcel(false);
      setParcelError(true);
    });
};
