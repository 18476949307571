/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-shadow */
import { purple1 } from '@assets/colors';
import {
  drop,
  invisibleColumns,
  invisibleColumns_black,
  visibleColumns,
  visibleColumns_black,
} from '@assets/images/images';
import { InstallerContext } from '@auth/utils/authContext';
import { IProvider } from '@dashboard/utils/dashboardTypes';
import React, { useContext } from 'react';
import { Droppable, Draggable, DragDropContext } from 'react-beautiful-dnd';
import { DashboardContext } from '../../../utils/context/dashboardContext';
import { IProviderWithChoices } from '../../../utils/dashboardTypes';

interface IProps {
  hideColumn: boolean;
  datas: IProvider[];
}

function ListColumns({ hideColumn, datas }: IProps): JSX.Element {
  const { installer, isThemeDark } = useContext(InstallerContext);
  const {
    schemaFoldersCustomView,
    updateListInvisibleColumnsCustomView,
    listInvisibleColumnsCustomView,
    updateListVisibleColumnsCustomView,
    listVisibleColumnsCustomView,
  } = useContext(DashboardContext);

  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }
    const newItems = [...datas];
    const [removed] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, removed);
    if (hideColumn) {
      updateListInvisibleColumnsCustomView({
        ...listInvisibleColumnsCustomView,
        fields: newItems,
      });
    } else {
      updateListVisibleColumnsCustomView({
        ...listVisibleColumnsCustomView,
        fields: newItems,
      });
    }
  };

  const hideAllOrShowAll = () => {
    if (hideColumn) {
      updateListInvisibleColumnsCustomView({
        ...listInvisibleColumnsCustomView,
        fields: [],
      });
      updateListVisibleColumnsCustomView({
        ...listVisibleColumnsCustomView,
        fields: schemaFoldersCustomView.fields,
      });
    } else {
      updateListInvisibleColumnsCustomView({
        ...listInvisibleColumnsCustomView,
        fields: schemaFoldersCustomView.fields,
      });
      updateListVisibleColumnsCustomView({
        ...listVisibleColumnsCustomView,
        fields: [],
      });
    }
  };

  const addColumnIntoNewList = (el: IProviderWithChoices | IProvider) => {
    if (!hideColumn) {
      const newItems = [...listInvisibleColumnsCustomView.fields];
      newItems.push(el);
      updateListInvisibleColumnsCustomView({
        ...listInvisibleColumnsCustomView,
        fields: newItems,
      });
      updateListVisibleColumnsCustomView({
        ...listVisibleColumnsCustomView,
        fields: listVisibleColumnsCustomView.fields.filter(
          (item: IProvider) => item.provider_name !== el.provider_name
        ),
      });
    } else {
      const newItems = [...listVisibleColumnsCustomView.fields];
      newItems.push(el);
      updateListVisibleColumnsCustomView({
        ...listVisibleColumnsCustomView,
        fields: newItems,
      });
      updateListInvisibleColumnsCustomView({
        ...listInvisibleColumnsCustomView,
        fields: listInvisibleColumnsCustomView.fields.filter(
          (item: IProvider) => item.provider_name !== el.provider_name
        ),
      });
    }
  };
  const iconHideColumn = isThemeDark
    ? invisibleColumns
    : invisibleColumns_black;

  const iconDisplayColumn = isThemeDark ? visibleColumns : visibleColumns_black;

  return (
    <div className="border-b dark:border-b-[#3F3F3F] border-b-borderLightMode pb-[1.25rem] px-[1.5rem] w-full">
      <div className="w-full flex justify-between items-center w-full ">
        <p className="text-[.75rem] font-medium dark:text-[#C6C6C6] text-colorTextLightMode ">
          {!hideColumn ? 'Colonnes visibles' : 'Colonnes invisibles'}
        </p>
        <button
          type="button"
          onClick={() => hideAllOrShowAll()}
          className="text-[.75rem] font-regular"
          style={{
            color:
              installer.nom_partenaire === 'DRAPO' ||
              installer.nom_partenaire === ''
                ? purple1
                : installer.custom.couleur_boutons,
          }}
        >
          {!hideColumn ? 'Tout masquer' : 'Tout afficher'}
        </button>
      </div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="1">
          {(provided) => (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <div {...provided.droppableProps} ref={provided.innerRef}>
              <div className="holder holder--tint- w-full">
                <div className="holder__content flex w-full justify-between items-center">
                  <ul className="list mt-[.625rem] w-full">
                    {datas.map(
                      (el, index) =>
                        el.provider_name !== 'Accéder dossier' && (
                          <li
                            className="list__item flex w-full justify-between items-center "
                            key={el.provider_id}
                          >
                            <Draggable
                              key={el.provider_id}
                              draggableId={el.provider_id}
                              index={index}
                            >
                              {(provided) => (
                                <div
                                  className="text-[.875rem] font-medium pb-[.625rem] w-full"
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  ref={provided.innerRef}
                                >
                                  <div className="flex items-center w-full justify-between">
                                    <div className="flex items-center space-x-[.625rem]">
                                      {datas.length > 0 && (
                                        <img src={drop} alt="déplacer" />
                                      )}
                                      <p> {el.provider_name}</p>
                                    </div>
                                    <button
                                      className="mt-[-.375rem]"
                                      onClick={() => addColumnIntoNewList(el)}
                                      type="button"
                                    >
                                      <img
                                        src={
                                          hideColumn
                                            ? iconDisplayColumn
                                            : iconHideColumn
                                        }
                                        alt=""
                                      />
                                    </button>
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          </li>
                        )
                    )}
                    {provided.placeholder}
                  </ul>
                </div>
              </div>
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
}

export default ListColumns;
