/* eslint-disable react/jsx-props-no-spreading */
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { IFieldType } from '../../utils/AdminTypes';

type PropTypes = {
  fields: IFieldType[];
  searchValue: string;
  stepFieldsId: string[];
  handleCheckCard: (field: IFieldType) => void;
  showFields: string;
};

export default function Fields({
  fields,
  searchValue,
  stepFieldsId,
  handleCheckCard,
  showFields,
}: PropTypes): JSX.Element {
  return (
    <div>
      {showFields === 'SELECTED' ? (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <Droppable droppableId="fieldsSelect">
          {(provider) => (
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            <div
              className="h-[500px] overflow-y-auto overflow-x-hidden my-5"
              data-rbd-droppable-context-id="1"
              data-rbd-droppable-id="allFields"
              ref={provider.innerRef}
            >
              {fields.map((field, index) => (
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                <Draggable
                  key={field.provider_id}
                  draggableId={field.provider_id}
                  index={index}
                >
                  {(dragProvider) => (
                    <div
                      {...dragProvider.draggableProps}
                      {...dragProvider.dragHandleProps}
                      ref={dragProvider.innerRef}
                    >
                      <div
                        key={`field-${field.provider_id + Math.random()}`}
                        className={
                          field.provider_name
                            .toLowerCase()
                            .includes(searchValue.toLowerCase())
                            ? 'relative block'
                            : 'relative hidden'
                        }
                      >
                        <div className="relative">
                          <div
                            className={` ${
                              stepFieldsId.includes(field.provider_id)
                                ? `bg-purple-1 hover:bg-[#E3E6F8];`
                                : `bg-black`
                            } bg-black w-9/12 mx-auto rounded-[8px] my-3 p-5 flex justify-between`}
                          >
                            {field.provider_name}
                          </div>

                          <button
                            type="button"
                            className="rotate-45 absolute right-3 text-[40px] top-[50%] translate-y-[-50%] "
                            onClick={() => handleCheckCard(field)}
                          >
                            +
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {provider.placeholder}
            </div>
          )}
        </Droppable>
      ) : (
        <div className="h-[500px] overflow-y-auto overflow-x-hidden my-5">
          {fields.map((field) => (
            <div
              key={`field-${field.provider_id + Math.random()}`}
              className={
                field.provider_name
                  .toLowerCase()
                  .includes(searchValue.toLowerCase())
                  ? 'relative block'
                  : 'relative hidden'
              }
            >
              <div className="relative">
                <div
                  className={` ${
                    stepFieldsId.includes(field.provider_id)
                      ? `bg-purple-1`
                      : `bg-black`
                  } bg-black w-9/12 mx-auto rounded-[8px] my-3 p-5`}
                  onClick={() => handleCheckCard(field)}
                  onKeyPress={() => handleCheckCard(field)}
                  tabIndex={0}
                  role="button"
                >
                  {field.provider_name}
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
