import React, { Dispatch, SetStateAction } from 'react';
import {
  IFolder,
  IOperation,
  Idocuments,
  IFileCheckBeforeSend,
  IFileImported,
  IListFilesImported,
  ICoordinates,
  IFileSentToAirtable,
} from './bulkImportTypes';

interface IProps {
  // Liste des fichiers xls importés
  listFilesImported: IListFilesImported[];
  updateListFilesImported: Dispatch<SetStateAction<IListFilesImported[]>>;
  // Indique si un fichier xls importé est sélectionné
  listFilesImportedIsClicked: boolean;
  updateListFilesImportedIsClicked: Dispatch<SetStateAction<boolean>>;
  // Nom du fichier xls importé sélectionné
  fileNameUploaded: string;
  updateFileNameUploaded: Dispatch<SetStateAction<string>>;
  // Fichier xls importé sélectionné
  fileUploaded: IFileImported | undefined;
  updateFileUploaded: Dispatch<SetStateAction<IFileImported | undefined>>;
  // Indique si le fichier xls est en cours de téléchargement
  fileIsLoading: boolean;
  updateFileIsLoading: Dispatch<SetStateAction<boolean>>;
  // Indique si le fichier xls en cours d'import présente une erreur
  isErrorXLS: boolean;
  updateIsErrorXLS: Dispatch<SetStateAction<boolean>>;
  // Indique si le fichier xls en cours d'import est téléchargé
  isUploaded: boolean;
  updateIsUploaded: Dispatch<SetStateAction<boolean>>;
  // Détails du fichier xls importé
  fileDetail: IFileCheckBeforeSend | undefined;
  updateFileDetail: Dispatch<SetStateAction<IFileCheckBeforeSend | undefined>>;
  // Liste des dossiers du fichier xls sélectionné
  folderList: IFolder[];
  updateFolderList: Dispatch<SetStateAction<IFolder[]>>;
  // Opérations du dossier séléctionné parmis ceux du fichier importé
  operations: IOperation[] | undefined;
  updateOperations: Dispatch<SetStateAction<IOperation[] | undefined>>;
  // Documents globaux du dossier séléctionné parmis ceux du fichier importé
  documentsGlobals: Idocuments[] | undefined;
  updateDocumentsGlobals: Dispatch<SetStateAction<Idocuments[] | undefined>>;
  // Référence du dossier séléctionné parmis ceux du fichier importé
  folderActiveReference: string;
  updateFolderActiveReference: Dispatch<SetStateAction<string>>;
  // Indique si tout les documents obligatoires du dossier séléctionné parmis ceux du fichier importé sont présents
  foldersIsCompleted: boolean;
  updateFolderIsCompleted: Dispatch<SetStateAction<boolean>>;
  // Dossier séléctionné parmis ceux du fichier importé
  folderActive: IFolder | undefined;
  updateFolderActive: Dispatch<SetStateAction<IFolder | undefined>>;
  // Indique si le fichier xls a été envoyé sur Airtable
  fileIsPushingToAirtable: boolean;
  updateFileIsPushingToAirtable: Dispatch<SetStateAction<boolean>>;
  // Coordonnées des travaux du fichier en cours d'import
  coordinatesArrayForMapLoading: ICoordinates[];
  updateCoordinatesArrayForMapLoading: Dispatch<SetStateAction<ICoordinates[]>>;
  // Coordonnées sur lesquelles zoomer sur la map Google
  centerMap: ICoordinates;
  updateCenterMap: Dispatch<SetStateAction<ICoordinates>>;
  // Niveau de zoom de la map Google
  zoomMap: number;
  updateZoomMap: Dispatch<SetStateAction<number>>;
  // Fenetre d'erreur lors de l'import du fichier xls
  errorWindow: boolean;
  updateErrorWindow: Dispatch<SetStateAction<boolean>>;
  // Titre de l'erreur de la fenetre d'erreur lors de l'import du fichier xls
  errorWindowHeader: string;
  updateErrorWindowHeader: Dispatch<SetStateAction<string>>;
  // Contenu de l'erreur de la fenetre d'erreur lors de l'import du fichier xls
  errorWindowContent: JSX.Element | string;
  updateErrorWindowContent: Dispatch<SetStateAction<JSX.Element | string>>;
  // Indique au fur et à mesure le nombre de dossiers importés lors du traitement du fichier xls
  importLoading: number | undefined;
  updateImportLoading: Dispatch<SetStateAction<number | undefined>>;
  // Indique au fur et à mesure le nombre de dossiers importés lors de l'upload du fichier xls
  uploadLoading: number;
  updateUploadLoading: Dispatch<SetStateAction<number>>;
  // Indique si l'import du fichier xls est achevé
  importSuccess: boolean;
  updateImportSuccess: Dispatch<SetStateAction<boolean>>;
  updateWebSocketConnected: Dispatch<SetStateAction<boolean>>;
  wasSentToAirtable: boolean;
  updateWasSentToAirtable: Dispatch<SetStateAction<boolean>>;
  fileSentToAirtable: IFileSentToAirtable | undefined;
  updateFileSentToAirtable: Dispatch<
    SetStateAction<IFileSentToAirtable | undefined>
  >;
}

export const BulkImportContext = React.createContext({} as IProps);
