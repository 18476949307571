import { validate } from '@assets/images/images';
import { InstallerContext } from '@auth/utils/authContext';
import { updateNameOneCustomViewById } from '@dashboard/apiRequests/dashboardRequests';
import React, { Dispatch, SetStateAction, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { colorTextLightMode, purple1, white } from '@assets/colors';
import { CloseIcon } from '@assets/svgComponents/svgComponents';
import { DashboardContext } from '../../../utils/context/dashboardContext';

interface IProps {
  setIsEditName: Dispatch<SetStateAction<boolean>>;
}
function UpdateListName({ setIsEditName }: IProps): JSX.Element {
  const { handleSubmit } = useForm();

  const {
    nameCurrentListCustomView,
    idCurrentListCustomView,
    updateNameCurrentListCustomView,
    currentSchemaFolderCustomView,
  } = useContext(DashboardContext);

  const { radiusPx, installer, isThemeDark } = useContext(InstallerContext);
  const radius = radiusPx === '0px' ? '0px' : '8px';

  const onSubmit = () => {
    setIsEditName(false);
    updateNameOneCustomViewById(
      nameCurrentListCustomView,
      idCurrentListCustomView,
      currentSchemaFolderCustomView
    );
  };
  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        action="update name custom list"
        className="flex items-center relative w-1/2"
      >
        <button
          type="button"
          onClick={() => setIsEditName(false)}
          className="absolute right-[4.375rem] bottom-4 hover:opacity-80"
        >
          <CloseIcon
            fill={isThemeDark ? white : colorTextLightMode}
            width="1rem"
            height="1rem"
          />
        </button>
        <label htmlFor="name" className="flex w-full text-sm ">
          <input
            style={{ borderRadius: radius }}
            value={nameCurrentListCustomView}
            onChange={(e) => updateNameCurrentListCustomView(e.target.value)}
            id="name"
            type="text"
            placeholder={nameCurrentListCustomView}
            name="name"
            className="bg-transparent w-full  border dark:text-white text-colorTextLightMode rounded-md focus:outline-none border-[#878787] py-[.625rem] px-[1rem]"
          />
        </label>
        <button
          style={{
            borderRadius: radius,
            backgroundColor:
              installer.nom_partenaire === 'DRAPO' ||
              installer.nom_partenaire === ''
                ? purple1
                : installer.custom.couleur_boutons,
          }}
          className="p-[1rem] ml-[.625rem]"
          type="submit"
        >
          <img src={validate} alt="valider" />
        </button>
      </form>
    </>
  );
}

export default UpdateListName;
