import { Button } from 'storybook-design-system-drapo';
import { Link } from 'react-router-dom';
import { useContext } from 'react';
import { InstallerContext } from '@auth/utils/authContext';
import {
  OperationsListContext,
  SimulatorContext,
} from '@simulator/utils/simulatorContext';
import { initialOperationDatas } from '@simulator/utils/initialsState';
import { white } from '@assets/colors';

function NoAid(): JSX.Element {
  const onChoice = () => {
    window.location.reload();
  };

  const { updateStepNumber, updateCurrentOperation } =
    useContext(SimulatorContext);
  const { operationList } = useContext(OperationsListContext);
  const { colorPrimary, radiusPx, colorTextButton } =
    useContext(InstallerContext);
  const addOperation = () => {
    updateCurrentOperation(initialOperationDatas);
    updateStepNumber(1);
  };

  const backResult = () => {
    updateStepNumber(4);
  };

  return (
    <div>
      {operationList.length === 0 ? (
        <div className="float-right mt-[1.5rem]">
          <Link to="/demander-ma-prime">
            <Button
              borderRadius={radiusPx}
              buttonArrowSmall
              label="Nouvelle simulation"
              color={colorTextButton}
              backgroundColor="#5D6AD2"
              arrow
              onClick={onChoice}
            />
          </Link>
        </div>
      ) : (
        <div className="flex justify-between space-x-[1rem] mt-[1.5rem] btnSbContainer">
          <Button
            borderRadius={radiusPx}
            buttonArrowSmall
            label="Changer d'opération"
            color={colorTextButton}
            backgroundColor={colorPrimary}
            arrow
            onClick={() => addOperation()}
          />
          <Button
            borderRadius={radiusPx}
            buttonArrowSmall
            label="Retour au résultat"
            color={white}
            backgroundColor={colorPrimary}
            arrow
            onClick={() => backResult()}
          />
        </div>
      )}
    </div>
  );
}

export default NoAid;
