import { InstallerContext } from '@auth/utils/authContext';
import { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { dark1, dark2, backgroundBodyLightMode } from '@assets/colors';

function Loader(): JSX.Element {
  const { colorPrimary, isThemeDark } = useContext(InstallerContext);
  const location = useLocation();

  const background = () => {
    if (
      location.pathname.includes('connexion') &&
      !location.pathname.includes('wl')
    ) {
      return dark2;
    }
    if (isThemeDark) {
      return dark1;
    }
    if (
      location.pathname.includes('connexion') &&
      location.pathname.includes('wl')
    ) {
      return backgroundBodyLightMode;
    }
    return '';
  };
  return (
    <div
      style={{
        backgroundColor: background(),
      }}
    >
      <div
        style={{
          backgroundColor: colorPrimary,
        }}
        className="loaderBar"
      />
    </div>
  );
}

export default Loader;
