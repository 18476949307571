import { white } from '@assets/colors';
import { LogoDrapoIcon, LogoutIcon } from '@assets/svgComponents/svgComponents';
import { logout } from '@auth/utils/function';
import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'storybook-design-system-drapo';
import {
  InstallerContext,
  AuthContext,
} from '../models/auth/utils/authContext';

interface IProps {
  label: string;
}

function MobileScreen({ label }: IProps) {
  const router = useNavigate();
  const {
    radiusPx,
    colorPrimary,
    colorTextButton,
    updateInstaller,
    updateInstallerIsLoaded,
    updateThemeChangedManually,
  } = useContext(InstallerContext);
  const { updateToken } = useContext(AuthContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div
      style={{
        zIndex: 9999999,
      }}
      className="w-screen h-screen absolute text-center dark:bg-dark-5 bg-white"
    >
      <div className="absolute right-0 p-[1rem]">
        <LogoutIcon
          onClick={() =>
            logout(
              updateToken,
              updateInstaller,
              updateInstallerIsLoaded,
              updateThemeChangedManually
            )
          }
          fill={colorTextButton}
        />
      </div>
      <div
        style={{
          transform: 'translate(-50%, -50%)',
        }}
        className="absolute top-[50%] left-[50%] w-8/12"
      >
        <LogoDrapoIcon fill={white} width="8rem" className="mb-[2rem] m-auto" />
        <p className="text-[1rem] ml-[.5rem] dark:text-white text-colorTextLightMode">
          {label}
        </p>
        <Button
          borderRadius={radiusPx}
          label="Simulation en version mobile"
          addClass="!p-[1rem] !mt-[2rem] !m-auto"
          backgroundColor={colorPrimary}
          color={colorTextButton}
          onClick={() => router('/demander-ma-prime')}
        />
      </div>
    </div>
  );
}

export { MobileScreen };
