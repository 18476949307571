/* eslint-disable no-nested-ternary */
import { Provider } from '@rollbar/react';
import { useMediaQuery } from 'react-responsive';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { ToastContainer } from 'react-toastify';
import { IInstallerNotification } from '@dashboard/utils/dashboardTypes';
import {
  backgroundBodyLightMode,
  darkVariant1,
  purple1,
  red2,
  white,
} from '@assets/colors';
import { AuthContext, InstallerContext } from '@auth/utils/authContext';
import { FoldersAndSimulatorContext } from '@folder/utils/FoldersContext';
import { getInstaller } from '@auth/apiRequests/AuthRequests';
import { logout } from '@auth/utils/function';

import {
  changeMobileScreen,
  changeThemeToDark,
  changeThemeToLight,
  checkCaching,
  eligibilityDemoFromLocalStorage,
  // fontSizeHtml,
  getContrastYIQ,
  pagesWithSidebarLeft,
  rollbarConfig,
} from '@utils/utils';
import { IInstaller } from '@auth/utils/authType';
import { initialInstaller } from '@auth/utils/initialState';
import { IWhiteLabelCustomType } from '@simulator/utils/simulatorTypes';
import { intialWhiteLabelCustom } from '@simulator/utils/initialsState';
import { imgTemp, imgTempLight } from '@assets/images/images';
import Pusher from 'pusher-js';
import { MobileScreen } from '@components/MobileScreen';
import { InfosBanner } from '@components/InfosBanner';
import { ModalContratMprMixte } from '@components/ModalContratMprMixte';
import { Helmet } from 'react-helmet';
import MenuMobile from './components/menu/MenuMobile';
import InfoHeader from './components/InfoHeader';
import Router from './Router';

function App(): JSX.Element {
  const location = useLocation();
  const defaultFavIcon = '../favicon_drapo.svg';
  const defaultSiteTitle = 'Espace pro Drapo';

  // Contexte d'authentification
  //-------------------------------------------------------------------------------------------------------------------
  const [token, setToken] = useState<string>(
    localStorage.getItem('token') || ''
  );

  const [tokenConnected, setTokenConnected] = useState<boolean>(true);
  const authContextValue = {
    token,
    updateToken: setToken,
    updateTokenConnected: setTokenConnected,
  };
  //-------------------------------------------------------------------------------------------------------------------
  // Contexte Installateur
  //-------------------------------------------------------------------------------------------------------------------
  // Changer l'eligibility dans initialInstaller pour régler CEE et/ou MPR lors d'une simulation hors connexion
  const [installer, setInstaller] = useState<IInstaller>(initialInstaller);
  const [installerIsLoaded, setInstallerIsLoaded] = useState<boolean>(false);
  const [installerNotification, setInstallerNotification] =
    useState<IInstallerNotification | null>(null);
  const [eligibilityInstallerDemo, setEligibilityInstallerDemo] = useState<
    string[]
  >(eligibilityDemoFromLocalStorage());
  const [pusher, setPusher] = useState<Pusher>();
  const [radiusPx, setRadiusPx] = useState<string>('0px');
  const [colorPrimary, setColorPrimary] = useState<string>(purple1);
  const [colorTextButton, setColorTextButton] = useState<string>('white');
  const [colorAlertWhiteLabel, setColorAlertWhiteLabel] =
    useState<string>(purple1);
  const [isThemeDark, setIsThemeDark] = useState<boolean>(
    localStorage.getItem('theme') === 'dark'
  );
  const [imgTemplate, setImgTemplate] = useState<string>(
    isThemeDark ? imgTemp : imgTempLight
  );
  const [themeChangedManually, setThemeChangedManually] =
    useState<boolean>(false);
  const [displayModalContratMixteMpr, setDisplayModalContratMixteMpr] =
    useState<boolean>(false);

  const installerContextValue = {
    installer,
    updateInstaller: setInstaller,
    installerIsLoaded,
    updateInstallerIsLoaded: setInstallerIsLoaded,
    installerNotification,
    updateInstallerNotification: setInstallerNotification,
    eligibilityInstallerDemo,
    updateEligibilityInstallerDemo: setEligibilityInstallerDemo,
    pusher,
    radiusPx,
    colorPrimary,
    colorTextButton,
    colorAlertWhiteLabel,
    imgTemplate,
    isThemeDark,
    updateIsThemeDark: setIsThemeDark,
    updateThemeChangedManually: setThemeChangedManually,
    displayModalContratMixteMpr,
    updateDisplayModalContratMixteMpr: setDisplayModalContratMixteMpr,
  };
  //-------------------------------------------------------------------------------------------------------------------

  // Contexte de simulation et de création de dossier
  //-------------------------------------------------------------------------------------------------------------------
  const [folderReference, setFolderReference] = useState<string>('');
  const [ownerOrLandlord, setOwnerOrLandlor] = useState<string>('');
  const [whiteLabelCustom, setWhiteLabelCustom] =
    useState<IWhiteLabelCustomType>(intialWhiteLabelCustom);
  const [isPrefilled, setIsPrefilled] = useState<boolean>(
    localStorage.getItem('isPrefilled') === 'true'
  );

  const folderAndSimulatorContextValue = {
    folderReference,
    updateFolderReference: setFolderReference,
    ownerOrLandlord,
    updateOwnerOrLandlord: setOwnerOrLandlor,
    whiteLabelCustom,
    updateWhiteLabelCustom: setWhiteLabelCustom,
    isPrefilled,
    updateIsPrefilled: setIsPrefilled,
  };
  //-------------------------------------------------------------------------------------------------------------------

  const [favicon, setFavicon] = useState(defaultFavIcon);
  const [titleSite, setTitleSite] = useState(defaultSiteTitle);

  const whiteLabel: string = useParams().marque || '';
  const navigate = useNavigate();

  const isMobile = useMediaQuery({ maxWidth: 1024 });

  const displayMobileScreen =
    isMobile &&
    !location.pathname.includes('test-iframe') &&
    // Décommenter si on veut activer la version mobile pour la simulation depuis une session connectée
    // location.pathname !== '/demander-ma-prime' &&
    // location.pathname !== '/connexion' &&
    !location.pathname.includes('demander-ma-prime') &&
    !location.pathname.includes('informations-complementaires') &&
    !location.pathname.includes('connexion');

  const hour: number = 60 * 60 * 1000;

  const checkExpireDate = (tokenExpiresAt: string) => {
    if (new Date() <= new Date(tokenExpiresAt.replace(/"/g, ''))) {
      setTokenConnected(true);
    } else {
      setTokenConnected(false);
      logout(
        setToken,
        setInstaller,
        setInstallerIsLoaded,
        setThemeChangedManually
      );
    }
  };

  const appContainerClass =
    (pagesWithSidebarLeft.includes(location.pathname) &&
      !displayModalContratMixteMpr) ||
    (location.pathname.includes('suivi-de-dossier') &&
      !displayModalContratMixteMpr)
      ? 'app-container-with-sidebar'
      : displayModalContratMixteMpr
      ? ''
      : 'app-container';

  const demoActive =
    eligibilityInstallerDemo.includes('CEE') ||
    eligibilityInstallerDemo.includes('MPR');

  // set bearer token in axios
  axios.defaults.headers.common.Authorization = token ? `Bearer ${token}` : '';
  if (demoActive) {
    axios.defaults.params = {
      demo_cee: eligibilityInstallerDemo.includes('CEE'),
      demo_mpr: eligibilityInstallerDemo.includes('MPR'),
      force_fill: isPrefilled,
    };
  } else {
    axios.defaults.params = {};
  }

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response && error.response.status === 401) {
        logout(
          setToken,
          setInstaller,
          setInstallerIsLoaded,
          setThemeChangedManually
        );
        navigate('/login', { replace: true });
      }
      return Promise.reject(error);
    }
  );

  axios.interceptors.request.use((config) => {
    const newConfig = config;
    if (config && config.url && config.url[config.url.length - 1] === '/') {
      newConfig.url = config.url.slice(0, -1);
    }
    return newConfig;
  });

  useEffect(() => {
    checkCaching();
  }, []);

  useEffect(() => {
    if (token) {
      if (token !== localStorage.getItem('token')) {
        localStorage.setItem('token', token);
      }
      if (!pusher) {
        const initPusher = new Pusher(
          process.env.REACT_APP_PUSHER_APP_KEY || '',
          {
            wsHost: process.env.REACT_APP_PUSHER_IP,
            cluster: 'eu',
            enabledTransports: ['ws', 'wss'],
          }
        );
        setPusher(initPusher);
      }

      if (installer.id === '') {
        getInstaller(
          setInstaller,
          setInstallerIsLoaded,
          eligibilityInstallerDemo,
          demoActive,
          setIsPrefilled
        );
      }

      const tokenExpiresAt = localStorage.getItem('token_expires_at');
      if (tokenExpiresAt !== null) {
        checkExpireDate(tokenExpiresAt);
        setInterval(() => {
          checkExpireDate(tokenExpiresAt);
        }, hour);
      }
    } else {
      // suppression du localStorage au logout
      localStorage.removeItem('token');
      localStorage.removeItem('theme');
      localStorage.removeItem('permission');
      localStorage.removeItem('token_expires_at');
      localStorage.removeItem('isPrefilled');
      localStorage.removeItem('eligibilityDemoCee');
      localStorage.removeItem('eligibilityDemoMpr');
      setEligibilityInstallerDemo(['']);
    }
  }, [token]);

  useEffect(() => {
    if (isThemeDark) {
      changeThemeToDark();
    } else {
      changeThemeToLight(location.pathname, location.search);
    }
  }, [isThemeDark]);

  useEffect(() => {
    changeMobileScreen(displayMobileScreen);
  }, [isMobile]);

  useEffect(() => {
    // fontSizeHtml(location.pathname);
    if (location.pathname.includes('wl')) {
      setColorPrimary(
        whiteLabelCustom.couleur_boutons
          ? whiteLabelCustom.couleur_boutons
          : purple1
      );
      setColorTextButton(
        getContrastYIQ(
          whiteLabelCustom.couleur_boutons
            ? whiteLabelCustom.couleur_boutons.replace('#', '')
            : purple1
        )
      );
      setColorAlertWhiteLabel(whiteLabelCustom.couleur_alerte);
      if (whiteLabelCustom.image_illustration_mb !== undefined) {
        setImgTemplate(whiteLabelCustom.image_illustration_mb);
      } else {
        setImgTemplate(isThemeDark ? imgTemp : imgTempLight);
      }
    } else if (
      installer.nom_partenaire === 'DRAPO' ||
      installer.nom_partenaire === ''
    ) {
      setColorAlertWhiteLabel(red2);
      setColorPrimary(purple1);
      setColorTextButton(getContrastYIQ(purple1));
      setImgTemplate(isThemeDark ? imgTemp : imgTempLight);
    } else {
      setColorAlertWhiteLabel(installer.custom.couleur_alerte);
      setColorPrimary(installer.custom.couleur_boutons);
      getContrastYIQ(
        installer.custom.couleur_boutons
          ? installer.custom.couleur_boutons.replace('#', '')
          : purple1
      );
      if (installer.custom.image_illustration_mb !== undefined) {
        setImgTemplate(installer.custom.image_illustration_mb);
      } else {
        setImgTemplate(isThemeDark ? imgTemp : imgTempLight);
      }
    }
    if (
      installer.custom.sans_radius === 'oui' ||
      (location.pathname.includes('wl') &&
        whiteLabelCustom.sans_radius === 'oui')
    ) {
      setRadiusPx('0px');
    } else {
      setRadiusPx('16px');
    }
    if (!themeChangedManually) {
      if (location.pathname !== '/connexion') {
        if (location.pathname.includes('wl')) {
          setIsThemeDark(false);
        } else if (
          installer.nom_partenaire === '' ||
          installer.nom_partenaire === 'DRAPO'
        ) {
          setIsThemeDark(true);
        } else {
          setIsThemeDark(false);
        }
      } else {
        setIsThemeDark(true);
      }
    }
    if (
      (installer.nom_partenaire !== 'DRAPO' &&
        localStorage.getItem('whiteLabelName')) ||
      location.pathname.includes('wl')
    ) {
      if (location.pathname.includes('wl')) {
        setFavicon(
          whiteLabelCustom.favicon && whiteLabelCustom.favicon !== ''
            ? whiteLabelCustom.favicon
            : defaultFavIcon
        );
        console.log(
          'whiteLabelCustom',
          whiteLabelCustom.favicon && whiteLabelCustom.favicon !== ''
            ? whiteLabelCustom.favicon
            : defaultFavIcon
        );
        const str = location.pathname
          .replace(/^\/wl\/|\/demander-ma-prime$/g, '')
          .replace(/^\/wl\/|\/connexion$/g, '')
          .replace('%20', ' ');
        const arr = str.split(' ');
        for (let i = 0; i < arr.length; i += 1) {
          arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
        }
        const strUppercase = arr.join(' ');
        setTitleSite(`Espace pro ${strUppercase}` || defaultSiteTitle);
      } else {
        setFavicon(installer.custom.favicon || defaultFavIcon);
        setTitleSite(installer.nom_partenaire || defaultSiteTitle);
      }
    } else {
      setFavicon(defaultFavIcon);
      setTitleSite(defaultSiteTitle);
    }
  }, [
    installer.nom_partenaire,
    location.pathname,
    whiteLabelCustom,
    isThemeDark,
    themeChangedManually,
  ]);

  useEffect(() => {
    if (installer.raison_sociale_de_lentreprise === 'DÉMO') {
      if (isPrefilled) {
        localStorage.setItem('isPrefilled', 'true');
        if (!demoActive) {
          setEligibilityInstallerDemo(installer.eligibility);
        }
      } else {
        localStorage.setItem('isPrefilled', 'false');
      }
    }
  }, [isPrefilled]);

  useEffect(() => {
    if (
      installer.id !== '' &&
      installer.raison_sociale_de_lentreprise === 'DÉMO' &&
      demoActive
    ) {
      localStorage.setItem(
        'eligibilityDemoCee',
        eligibilityInstallerDemo.includes('CEE') ? 'true' : 'false'
      );
      localStorage.setItem(
        'eligibilityDemoMpr',
        eligibilityInstallerDemo.includes('MPR') ? 'true' : 'false'
      );
      setInstaller((prevState) => ({
        ...prevState,
        eligibility: eligibilityInstallerDemo,
      }));
    }
  }, [eligibilityInstallerDemo]);

  useEffect(() => {
    if (
      installer.popup_mpr &&
      localStorage.getItem('isMprWithDrapo') === null
    ) {
      setDisplayModalContratMixteMpr(true);
    } else {
      setDisplayModalContratMixteMpr(false);
    }
  }, [installer]);

  return (
    <main
      style={{
        // eslint-disable-next-line no-nested-ternary
        backgroundColor: isThemeDark
          ? darkVariant1
          : location.search.includes('?integration=iframe')
          ? white
          : backgroundBodyLightMode,
      }}
    >
      <Helmet>
        <title>{titleSite}</title>
        <link rel="icon" type="image/svg+xml" href={favicon} />
      </Helmet>
      <Provider config={rollbarConfig}>
        <AuthContext.Provider value={authContextValue}>
          <InstallerContext.Provider value={installerContextValue}>
            <FoldersAndSimulatorContext.Provider
              value={folderAndSimulatorContextValue}
            >
              <ToastContainer
                position={!isMobile ? 'bottom-right' : 'top-right'}
                autoClose={9000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme={isThemeDark ? 'dark' : 'light'}
                style={{ zIndex: 999999 }}
              />
              {displayModalContratMixteMpr &&
                location.pathname !== '/connexion' && <ModalContratMprMixte />}
              {location.pathname !== '/connexion' &&
                !location.pathname.includes('autologin') &&
                !location.pathname.includes('wl') &&
                !token && (
                  <>
                    {location.pathname !== '/password-forgotten' && (
                      <InfoHeader />
                    )}
                  </>
                )}
              {!tokenConnected && (
                <InfosBanner info="Votre session a expiré ! Veuillez vous reconnecter." />
              )}
              {displayMobileScreen && (
                <MobileScreen
                  label={
                    location.pathname.includes('suivi-de-dossier')
                      ? 'Votre dossier a bien été créé, pour continuer dans vos démarches connectez vous sur un ecran adapté'
                      : "La résolution de l'écran ne semble pas adaptée à l'affichage de votre Espace Pro"
                  }
                />
              )}
              {window.location.href.includes('.staging') && (
                <InfosBanner info="Attention vous êtes sur STAGING et non sur pro.drapo.com" />
              )}
              <div className={`${appContainerClass} max-h-screen`}>
                {location.pathname !== '/connexion' &&
                  location.pathname !== `/wl/${whiteLabel}/demander-ma-prime` &&
                  !location.pathname.includes('/demander-ma-prime') &&
                  location.pathname.substring(0, 29) !==
                    '/informations-complementaires' &&
                  isMobile && <MenuMobile />}
                <Router />
              </div>
            </FoldersAndSimulatorContext.Provider>
          </InstallerContext.Provider>
        </AuthContext.Provider>
      </Provider>
    </main>
  );
}

export default App;
