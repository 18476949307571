import { dark1, white } from '@assets/colors';
import { InstallerContext } from '@auth/utils/authContext';
import { IAdditionalInformationSendMailRecap } from '@simulator/utils/simulatorTypes';
import { Dispatch, SetStateAction, useContext } from 'react';
import { Input } from 'storybook-design-system-drapo';

interface IProps {
  setAdditionalInformation: Dispatch<
    SetStateAction<IAdditionalInformationSendMailRecap>
  >;
  additionalInformation: IAdditionalInformationSendMailRecap;
}

function AddAdditionalInformation({
  setAdditionalInformation,
  additionalInformation,
}: IProps): JSX.Element {
  const { isThemeDark, radiusPx } = useContext(InstallerContext);
  return (
    <div className="mt-[1rem]">
      <div className="flex w-full space-x-[.5rem]">
        <Input
          addClass="!w-[50%]"
          borderRadius={radiusPx}
          color={isThemeDark ? white : dark1}
          backgroundColor={isThemeDark ? dark1 : white}
          inputType="text"
          label="Prénom"
          onChange={(e) => {
            setAdditionalInformation({
              ...additionalInformation,
              firstname: e.target.value,
            });
          }}
          value={additionalInformation.firstname}
        />
        <Input
          addClass="!w-[50%]"
          borderRadius={radiusPx}
          color={isThemeDark ? white : dark1}
          backgroundColor={isThemeDark ? dark1 : white}
          inputType="text"
          label="Nom"
          onChange={(e) => {
            setAdditionalInformation({
              ...additionalInformation,
              lastname: e.target.value,
            });
          }}
          value={additionalInformation.lastname}
        />
      </div>
      <Input
        addClass="mt-[1rem]"
        borderRadius={radiusPx}
        color={isThemeDark ? white : dark1}
        backgroundColor={isThemeDark ? dark1 : white}
        inputType="number"
        label="Téléphone"
        onChange={(e) => {
          setAdditionalInformation({
            ...additionalInformation,
            phone: e.target.value,
          });
        }}
        value={additionalInformation.phone}
      />
    </div>
  );
}

export { AddAdditionalInformation };
