/* eslint-disable jsx-a11y/label-has-associated-control */
import { useContext, useState, useEffect } from 'react';
import { v4 } from 'uuid';
import { InstallerContext } from '../../auth/utils/authContext';

function DemoCheckboxMprCee(): JSX.Element {
  const {
    updateEligibilityInstallerDemo,
    eligibilityInstallerDemo,
    installer,
  } = useContext(InstallerContext);

  const [isCee, setIsCee] = useState(
    eligibilityInstallerDemo !== undefined
      ? installer.eligibility.includes('CEE')
      : false
  );
  const [isMpr, setIsMpr] = useState(
    eligibilityInstallerDemo !== undefined
      ? installer.eligibility.includes('MPR')
      : false
  );

  const [changedManually, setChangedManually] = useState<boolean>(false);

  const array = ['CEE', 'MPR'];

  useEffect(() => {
    if (changedManually) {
      if (isCee && isMpr) {
        updateEligibilityInstallerDemo(['CEE', 'MPR']);
      } else if (isCee) {
        updateEligibilityInstallerDemo(['CEE']);
      } else if (isMpr) {
        updateEligibilityInstallerDemo(['MPR']);
      }
    }
  }, [isCee, isMpr, updateEligibilityInstallerDemo]);

  const updateInstallerEligibility = (item: string) => {
    setChangedManually(true);
    if (item === 'CEE') {
      if (!isCee) {
        setIsCee(true);
      } else {
        setIsCee(false);
      }
    } else if (!isMpr) {
      setIsMpr(true);
    } else {
      setIsMpr(false);
    }
  };
  return (
    <div className="flex">
      {array.map((item) => (
        <label
          key={v4()}
          className="check-box dark:text-white text-colorTextLightMode flex text-[.875rem] leading-[1.5rem] mr-[.625rem] gap-x-[.3rem] items-center"
        >
          <input
            checked={item === 'CEE' ? isCee : isMpr}
            onChange={() => updateInstallerEligibility(item)}
            type="checkbox"
            className="check-box__switcher sb-text-rem-body2 appearance-none h-[1rem] cursor-pointer w-[2.25rem] rounded-[16px]"
          />
          {item}
        </label>
      ))}
    </div>
  );
}

export default DemoCheckboxMprCee;
