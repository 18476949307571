/* eslint-disable no-nested-ternary */
import { useContext } from 'react';
import { InstallerContext } from '@auth/utils/authContext';
import {
  backgroundQuaternaryLightMode,
  colorTextLightMode,
  white,
} from '@assets/colors';
import { ReactComponent as Checkbox } from '@assets/images/checkbox.svg';
import { ReactComponent as NotChecked } from '@assets/images/not_checked_white.svg';

interface CheckCardPrimeTypes {
  label: string;
  onClick?: () => void;
  folderFee: string;
  checked: boolean;
  initialAmount?: string;
  notCheckable?: boolean;
}

function CheckCardPrime({
  label,
  initialAmount,
  checked,
  onClick,
  notCheckable,
  folderFee,
}: CheckCardPrimeTypes) {
  const { radiusPx, colorPrimary, isThemeDark, colorTextButton } =
    useContext(InstallerContext);

  const checkedColor = () => {
    if (notCheckable || !checked) {
      return isThemeDark ? '#707070' : backgroundQuaternaryLightMode;
    }
    return colorPrimary;
  };

  const onCheck = () => {
    if (!notCheckable) {
      if (onClick) {
        onClick();
      }
    }
  };

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div
      style={{
        backgroundColor: checkedColor(),
        borderRadius: radiusPx,
      }}
      className={`flex items-center justify-between w-full py-[1rem] px-[1.5rem] relative ${
        label.includes('MPR') ? 'cursor-pointer' : ''
      }`}
      onClick={() => onCheck()}
      data-test-id="check-card-prime"
    >
      <div
        style={{
          color: isThemeDark
            ? white
            : checked &&
              !isThemeDark &&
              checkedColor() !== backgroundQuaternaryLightMode
            ? colorTextButton
            : !checked && !isThemeDark
            ? colorTextLightMode
            : checkedColor() === backgroundQuaternaryLightMode
            ? colorTextLightMode
            : '',
        }}
      >
        <p className="text-[.875rem] leading-[1.125rem]">{label}</p>
        <p className="text-[.875rem] font-medium leading-[1.5rem]">
          {initialAmount} €
        </p>
        <p className="text-[.625rem] font-medium leading-[1.5rem]">
          <span
            style={{
              color: isThemeDark
                ? white
                : checked &&
                  !isThemeDark &&
                  checkedColor() !== backgroundQuaternaryLightMode
                ? colorTextButton
                : !checked && !isThemeDark
                ? colorTextLightMode
                : checkedColor() === backgroundQuaternaryLightMode
                ? colorTextLightMode
                : '',
            }}
            className="dark:text-[#C6C6C6] text-dark-1"
          >
            Frais dossier
          </span>{' '}
          {folderFee} € HT
        </p>
      </div>
      {!notCheckable && !label.includes('CEE') && (
        <div className="absolute right-[1rem] top-[1rem]">
          {checked ? (
            <Checkbox
              fill={colorTextButton}
              className="w-[1.5rem] h-[1.5rem]"
            />
          ) : (
            <NotChecked
              fill={
                isThemeDark
                  ? white
                  : checked && !isThemeDark
                  ? colorTextButton
                  : !checked && !isThemeDark
                  ? colorTextLightMode
                  : ''
              }
              className="w-[1.5rem] h-[1.5rem]"
            />
          )}
        </div>
      )}
    </div>
  );
}

export { CheckCardPrime };

CheckCardPrime.defaultProps = {
  notCheckable: false,
  initialAmount: '',
  onClick: () => {
    return null;
  },
};
