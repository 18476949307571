/* eslint-disable no-param-reassign */
import { Dispatch, SetStateAction } from 'react';
import {
  IGeneralDatasPostType,
  IGraphType,
  IIncomeType,
  IKeyValueStringType,
  IOperationDatasType,
  ISimulatorDatasType,
  IWorksType,
} from '@simulator/utils/simulatorTypes';
import { numFormatSpace } from '@utils/utils';
import {
  householdBlue,
  householdPink,
  householdPurple,
  householdYellow,
} from '@assets/images/images';

const aboutOperation = (array: string[]) => {
  for (let i = 0; i < array.length; i += 1) {
    if (array[i].includes('bar')) {
      return true;
    }
  }
  return false;
};

const conditionWithExclusion = (array: string[]) => {
  for (let i = 0; i < array.length; i += 1) {
    if (array[i].includes('!')) {
      return true;
    }
  }
  return false;
};

export const checkChildrensConditions = (
  childrens: IGraphType[],
  simulatorDatas: ISimulatorDatasType,
  operationId: string | string[],
  value?: string | number,
  material?: string
) => {
  let childWithoutCondition: IGraphType = childrens[0];
  for (let i = 0; i < childrens.length; i += 1) {
    const child = childrens[i];
    const { condition } = child;
    if (condition instanceof Array && condition.length === 0) {
      childWithoutCondition = child;
    }
    if (!(condition instanceof Array)) {
      if (
        material &&
        condition.operationMaterial &&
        condition.operationMaterial.includes(material)
      ) {
        return child;
      }
      if (condition.generalSimulator) {
        if (!(condition.generalSimulator instanceof Array)) {
          // graphe operation avec condition step material
          if (condition.generalSimulator.answer === material) {
            return child;
          }
          if (
            simulatorDatas[condition.generalSimulator.step] ===
            condition.generalSimulator.answer
          ) {
            return child;
          }
        } else {
          let counter = 0;
          for (let o = 0; o < condition.generalSimulator.length; o += 1) {
            const generalSimulatorCondition = condition.generalSimulator[o];
            if (
              simulatorDatas[generalSimulatorCondition.step] ===
              generalSimulatorCondition.answer
            ) {
              counter += 1;
            }
          }
          if (counter === condition.generalSimulator.length) {
            return child;
          }
        }
      }
    } else if (
      value &&
      condition.findIndex((e) => e.includes('>') || e.includes('<')) !== -1
    ) {
      const conditionIsSuperior =
        condition.findIndex((e) => e.includes('>')) !== -1;
      const conditionIsSuperiorOrEqual =
        condition.findIndex((e) => e.includes('>=')) !== -1;
      const conditionIsInferior =
        condition.findIndex((e) => e.includes('<')) !== -1;
      const conditionIsInferiorOrEqual =
        condition.findIndex((e) => e.includes('<=')) !== -1;
      if (conditionIsSuperiorOrEqual) {
        if (Number(value) >= Number(condition[0].replace('>=', ''))) {
          return child;
        }
      } else if (conditionIsSuperior) {
        if (Number(value) > Number(condition[0].replace('>', ''))) {
          return child;
        }
      } else if (conditionIsInferiorOrEqual) {
        if (Number(value) <= Number(condition[0].replace('<=', ''))) {
          return child;
        }
      } else if (conditionIsInferior) {
        if (Number(value) < Number(condition[0].replace('<', ''))) {
          return child;
        }
      }
    } else if (value && condition.includes(value.toString())) {
      return child;
    } else if (aboutOperation(condition)) {
      if (operationId instanceof Array) {
        // Au moins une opération est exclue (son id précédé d'un ! est contenu dans le tableau condition)
        let operationIsExclude = false;
        for (let o = 0; o < operationId.length; o += 1) {
          // on vérfie si l'id de l'opération est dans le tableau sans le ! devant (array.includes check si l'élément EXACTE dans le tableau)
          if (condition.includes(operationId[o])) {
            return child;
          }
          // on vérifie si une exclusion de l'opération est dans le tableau
          if (condition.includes(`!${operationId[o]}`)) {
            operationIsExclude = true;
          }
        }
        // Si aucune opération n'est exclue, on retourne le child
        if (!operationIsExclude && conditionWithExclusion(condition)) {
          return child;
        }
      } else {
        // on vérfie si l'id de l'opération est dans le tableau sans le ! devant (array.includes check si l'élément EXACTE dans le tableau)
        if (condition.includes(operationId)) {
          return child;
        }
        // Si l'opération n'est pas exclue, on retourne le child
        if (
          !condition.includes(`!${operationId}`) &&
          conditionWithExclusion(condition)
        ) {
          return child;
        }
      }
    }
  }
  return childWithoutCondition;
};

export const checkInitialChildrensConditions = (
  childrens: IGraphType[],
  simulatorDatas: ISimulatorDatasType,
  operationId: string,
  value?: string | number,
  material?: string
) => {
  let childWithoutCondition: IGraphType = childrens[0];
  for (let i = 0; i < childrens.length; i += 1) {
    const child = childrens[i];
    const { condition } = child;
    if (condition instanceof Array && condition.length === 0) {
      childWithoutCondition = child;
    }
    if (!(condition instanceof Array)) {
      if (
        material &&
        condition.operationMaterial &&
        condition.operationMaterial.includes(material)
      ) {
        return child;
      }
      if (condition.generalSimulator) {
        if (!(condition.generalSimulator instanceof Array)) {
          if (
            simulatorDatas[condition.generalSimulator.step] ===
            condition.generalSimulator.answer
          ) {
            return child;
          }
        } else {
          let counter = 0;
          for (let o = 0; o < condition.generalSimulator.length; o += 1) {
            const generalSimulatorCondition = condition.generalSimulator[o];
            if (
              simulatorDatas[generalSimulatorCondition.step] ===
              generalSimulatorCondition.answer
            ) {
              counter += 1;
            }
          }
          if (counter === condition.generalSimulator.length) {
            return child;
          }
        }
      }
    } else if (value && condition.includes(value.toString())) {
      return child;
    } else if (aboutOperation(condition)) {
      // on vérfie si l'id de l'opération est dans le tableau sans le ! devant (array.includes check si l'élément EXACTE dans le tableau)
      if (condition.includes(operationId)) {
        return child;
      }
      // Si l'opération n'est pas exclue, on retourne le child
      if (
        !condition.includes(`!${operationId}`) &&
        conditionWithExclusion(condition)
      ) {
        return child;
      }
    }
  }
  return childWithoutCondition;
};

export const nextStepGeneral = (
  simulatorDatas: ISimulatorDatasType,
  operationsDatas: IOperationDatasType[],
  updateGeneralComplete: Dispatch<SetStateAction<boolean>>,
  updateStepNumber: Dispatch<SetStateAction<number>>,
  updateSimulatorDatas: Dispatch<SetStateAction<ISimulatorDatasType>>,
  updateGraphGeneral: Dispatch<SetStateAction<IGraphType>>,
  updateGraphList: Dispatch<SetStateAction<IGraphType[]>>,
  graphGeneral: IGraphType,
  step: string,
  value: string | IWorksType | IIncomeType | number
) => {
  updateSimulatorDatas((oldSimulatorDatas) => {
    const newSimulatorDatas = { ...oldSimulatorDatas };
    newSimulatorDatas[step] = value;
    return newSimulatorDatas;
  });

  updateGraphList((oldGraphList) => {
    const newGraphList: IGraphType[] = [...oldGraphList, graphGeneral];
    return newGraphList;
  });

  if (graphGeneral.childrens.length === 0) {
    updateStepNumber(3);
    updateGeneralComplete(true);
  } else {
    const valueCheck = typeof value === 'string' ? value : undefined;
    const operationIds: string[] = operationsDatas.map(
      (operation) => operation.id
    );
    updateGraphGeneral((oldGraphGeneral) => {
      let newGraphGeneral: IGraphType = { ...oldGraphGeneral };
      newGraphGeneral = checkChildrensConditions(
        newGraphGeneral.childrens,
        simulatorDatas,
        operationIds,
        valueCheck
      );
      return newGraphGeneral;
    });
  }
};

export const nextStepOperation = (
  simulatorDatas: ISimulatorDatasType,
  operationDatas: IOperationDatasType,
  updateStepNumber: Dispatch<SetStateAction<number>>,
  updateCurrentOperation: Dispatch<SetStateAction<IOperationDatasType>>,
  updateGraphOperation: Dispatch<SetStateAction<IGraphType>>,
  updateGraphListOperation: Dispatch<SetStateAction<IGraphType[]>>,
  graphOperation: IGraphType,
  step: string,
  value: string | number
) => {
  updateCurrentOperation((oldCurrentOperation) => {
    const newCurrentOperation = { ...oldCurrentOperation };
    newCurrentOperation[step] = value;
    return newCurrentOperation;
  });

  const newGraphOperation = checkChildrensConditions(
    graphOperation.childrens,
    simulatorDatas,
    operationDatas.id,
    value,
    operationDatas.material ? (operationDatas.material as string) : undefined
  );

  updateGraphListOperation((oldGraphList) => {
    let newGraphList: IGraphType[] = [];
    const graphOperationToPush = newGraphOperation;
    graphOperationToPush.operationId = operationDatas.id;
    newGraphList = [...oldGraphList, graphOperationToPush];
    return newGraphList;
  });

  updateGraphOperation(newGraphOperation);
};

export const nextStepAudit = (
  updateStepNumber: Dispatch<SetStateAction<number>>,
  updateCurrentOperation: Dispatch<SetStateAction<IOperationDatasType>>,
  updateGraphListOperation: Dispatch<SetStateAction<IGraphType[]>>,
  updateGraphOperation: Dispatch<SetStateAction<IGraphType>>,
  graphOperation: IGraphType,
  currentCec: string,
  currentValue: string,
  projectedCec: string,
  projectedValue: string,
  dataOption: string,
  currentOperationId: string
) => {
  updateCurrentOperation((oldCurrentOperation) => {
    const newCurrentOperation = { ...oldCurrentOperation };
    newCurrentOperation.bonus = dataOption;
    if (dataOption === 'oui') {
      newCurrentOperation.currentCec = currentCec;
      newCurrentOperation.currentValue = currentValue;
      newCurrentOperation.projectedCec = projectedCec;
      newCurrentOperation.projectedValue = projectedValue;
    }
    return newCurrentOperation;
  });

  updateGraphListOperation((oldGraphList) => {
    let newGraphList: IGraphType[] = [];
    const graphOperationToPush = graphOperation.childrens[1];
    graphOperationToPush.operationId = currentOperationId;
    newGraphList = [...oldGraphList, graphOperationToPush];
    return newGraphList;
  });

  updateGraphOperation(graphOperation.childrens[1]);
};

export const constructIncomesContentArray = (incomes: number[]) => [
  {
    title: 'Inférieur à',
    label: `${numFormatSpace(incomes[0])} €`,
    value: `${incomes[0] - 1}`,
    legend: 'Très modeste',
    menage: 'Ménage bleu',
    color: 'blue',
    img: householdBlue,
  },
  {
    title: 'Compris entre',
    label: `${numFormatSpace(incomes[0])} € et ${numFormatSpace(incomes[1])} €`,
    value: `${incomes[0] + 1}`,
    legend: 'Modeste',
    menage: 'Ménage jaune',
    color: 'yellow',
    img: householdYellow,
  },
  {
    title: 'Compris entre',
    label: `${numFormatSpace(incomes[1])} € et ${numFormatSpace(incomes[2])} €`,
    value: `${incomes[1] + 1}`,
    legend: 'Intermédiaire',
    menage: 'Ménage violet',
    color: 'purple',
    img: householdPurple,
  },
  {
    title: 'Supérieur à',
    label: `${numFormatSpace(incomes[2])} €`,
    value: `${incomes[2] + 1}`,
    legend: 'Classique',
    menage: 'Ménage rose',
    color: 'pink',
    img: householdPink,
  },
];

// GET OPERATIONS DATA
export const operationsDataFunction = (operation: IOperationDatasType) => {
  const operationId =
    operation.id === 'bar-en-102-int' ? 'bar-en-102' : operation.id;

  const operationDatas: IGeneralDatasPostType[] = [];
  operationDatas.push({
    step: 'operation',
    answer: {
      id: operationId,
      name: operation.name,
    },
  });
  for (let i = 0; i < Object.keys(operation).length; i += 1) {
    if (
      Object.keys(operation)[i] !== 'id' &&
      Object.keys(operation)[i] !== 'name' &&
      Object.keys(operation)[i] !== 'primes'
    ) {
      operationDatas.push({
        step: Object.keys(operation)[i],
        answer: operation[Object.keys(operation)[i]],
      });
    }
  }
  return operationDatas;
};

export const arrayOfOperationsData = (operations: IOperationDatasType[]) => {
  const operationsDatas = [];
  for (let i = 0; i < operations.length; i += 1) {
    operationsDatas.push(operationsDataFunction(operations[i]));
  }
  return operationsDatas;
};

// GET GENERAL DATA
export const generalDatasFunction = (datas: ISimulatorDatasType) => {
  const generalDatas: IGeneralDatasPostType[] = [];
  for (let i = 0; i < Object.keys(datas).length; i += 1) {
    const dataAnswer = datas[Object.keys(datas)[i]];
    if (Object.keys(datas)[i] === 'surface' && typeof dataAnswer === 'string') {
      generalDatas.push({
        step: Object.keys(datas)[i],
        answer: [dataAnswer],
      });
    } else {
      generalDatas.push({
        step: Object.keys(datas)[i],
        answer: dataAnswer,
      });
    }
  }
  return generalDatas;
};

export const initFilteredDatas = (
  datas: IKeyValueStringType[],
  valueField: string,
  labelField: string
) => {
  const filteredList = [];
  for (let i = 0; i < datas.length; i += 1) {
    filteredList.push({
      value: datas[i][valueField],
      label: datas[i][labelField],
    });
  }
  return filteredList as IKeyValueStringType[];
};

export const initFilteredUnitsDatas = (datas: IKeyValueStringType[]) => {
  const filteredList = [];
  for (let i = 0; i < datas.length; i += 1) {
    filteredList.push({
      value: datas[i].id,
      label: datas[i].title,
      scop: datas[i].scop,
      power: datas[i].puissance_nominale_calorifique,
    });
  }
  return filteredList as IKeyValueStringType[];
};

// Séparation des milliers par un espace
export const numStr = (a: string | number, ttc: boolean) => {
  let str = `${typeof a === 'number' ? a.toString() : a}`;
  let c = '';
  let d = 0;
  while (str.match(/^0[0-9]/)) {
    str = str.substring(1);
  }
  if (!ttc) {
    for (let i = str.length - 1; i >= 0; i -= 1) {
      c = d !== 0 && d % 8 === 0 ? `${str[i]} ${c}` : str[i] + c;
      d += 1;
    }
  } else {
    for (let i = str.length - 1; i >= 0; i -= 1) {
      c = d !== 0 && d % 6 === 0 ? `${str[i]} ${c}` : str[i] + c;
      d += 1;
    }
  }

  return c;
};

export const numStr2 = (a: string, b: string) => {
  a = `${a}`;
  b = b || ' ';
  let c = '';
  let d = 0;
  while (a.match(/^0[0-9]/)) {
    a = a.substring(1);
  }
  for (let i = a.length - 1; i >= 0; i -= 1) {
    c = d !== 0 && d % 3 === 0 ? a[i] + b + c : a[i] + c;
    d += 1;
  }
  return c;
};

// Enlever les accents
export const sansAccent = (inputValue: string) => {
  const accent = [
    /[\300-\306]/g, // A, a
    /[\340-\346]/g,
    /[\310-\313]/g, // E, e
    /[\350-\353]/g,
    /[\314-\317]/g, // I, i
    /[\354-\357]/g,
    /[\322-\330]/g, // O, o
    /[\362-\370]/g,
    /[\331-\334]/g, // U, u
    /[\371-\374]/g,
    /[\321]/g, // N, n
    /[\361]/g,
    /[\307]/g, // C, c
    /[\347]/g,
  ];
  const noaccent = [
    'A',
    'a',
    'E',
    'e',
    'I',
    'i',
    'O',
    'o',
    'U',
    'u',
    'N',
    'n',
    'C',
    'c',
  ];

  let str = inputValue;
  for (let i = 0; i < accent.length; i += 1) {
    str = str.replace(accent[i], noaccent[i]);
  }

  return str;
};

const processChildren = (
  children: IGraphType[],
  stepToRemove: string[]
): IGraphType[] => {
  return children.reduce<IGraphType[]>((acc, child) => {
    if (stepToRemove.includes(child.id)) {
      // Si le nœud doit être supprimé, ajouter ses enfants à la place
      return acc.concat(processChildren(child.childrens, stepToRemove));
    }
    // Sinon, traiter ses enfants et l'ajouter au résultat
    const newChild = { ...child };
    newChild.childrens = processChildren(child.childrens, stepToRemove);
    acc.push(newChild);
    return acc;
  }, []);
};

export const removeStep = (
  graph: IGraphType,
  stepToRemove: string[]
): IGraphType => {
  // Cloner le graph pour ne pas modifier l'original
  const newGraph = {
    ...graph,
    childrens: processChildren(graph.childrens, stepToRemove),
  };
  return newGraph;
};
