import { useContext, useEffect } from 'react';
import { Button } from 'storybook-design-system-drapo';

import LoaderBulkimport from '@components/loaders/LoaderBulkimport';
import { InstallerContext } from '@auth/utils/authContext';
import { BulkImportContext } from '@bulkImport/utils/bulkImportContext';
import {
  deleteFile,
  getDetailFileById,
  getListFilesImported,
  importXls,
} from '@bulkImport/apiRequests/bulkImportRequest';
import MapLoading from '../../MapLoading';
import ArrayFileXls from './ArrayFileXls';

function ImportedXlsRightPart(): JSX.Element {
  const { colorPrimary, radiusPx, isThemeDark, colorTextButton } =
    useContext(InstallerContext);

  const {
    fileUploaded,
    isErrorXLS,
    updateFileUploaded,
    updateFileNameUploaded,
    updateListFilesImported,
    updateIsUploaded,
    fileNameUploaded,
    fileIsLoading,
    importLoading,
    updateImportLoading,
    updateFileDetail,
    updateListFilesImportedIsClicked,
    importSuccess,
    updateFolderList,
    updateWebSocketConnected,
    errorWindow,
    updateErrorWindow,
  } = useContext(BulkImportContext);

  useEffect(() => {
    if (importSuccess) {
      // RECUPERE LA LISTE DES FICHIERS IMPORTES ET METS A JOUR CETTE LISTE DANS LE CONTEXT
      getListFilesImported(updateListFilesImported);
      // MET A JOUR LE NOM DU FICHIER EN COURS DE TELECHARGEMENT (LeftPartBulkImport.tsx)
      updateFileNameUploaded('');
      // MET A JOUR LA PARTIE DROITE (N'AFFICHE PLUS LE TABLEAU AVEC LE FICHIER)
      updateIsUploaded(false);
      if (fileUploaded) {
        // RECUPERE LE DETAIL DU FICHIER CLIQUER POUR METTRE A JOUR LES INFOS SUR LA DROITE EN TEMPS REEL
        getDetailFileById(
          fileUploaded.id as number,
          updateFileDetail,
          updateIsUploaded,
          updateListFilesImportedIsClicked,
          updateFolderList
        );
      }
    }
  }, [importSuccess]);

  const handleDelete = async () => {
    await deleteFile(
      fileUploaded?.id,
      updateFileNameUploaded,
      updateFileUploaded
    );
  };
  const scrollBarColor = isThemeDark ? '' : 'scroll_light_mode';

  return (
    <>
      {!fileIsLoading ? (
        <>
          {fileNameUploaded !== '' && (
            <>
              {(importLoading || importLoading === 0) && fileUploaded ? (
                <div className="w-full h-full flex justify-center items-center ">
                  <div className="text-[1.875rem] text-white text-center dark:text-white text-dark-1 ">
                    Importation du fichier en cours <br />
                    {importLoading === 0 ? (
                      <LoaderBulkimport />
                    ) : (
                      <div className="animate__animated animate__fadeIn">
                        Dossier {importLoading}/{fileUploaded.nb_folders}
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <>
                  <div
                    style={{
                      borderTopLeftRadius: radiusPx,
                      borderTopRightRadius: radiusPx,
                    }}
                    className="lg:flex bg-white justify-between pb-[.5rem] dark:bg-dark-4 bg-lightMode-dark-4 w-full ml-[1rem] pt-[1rem] px-[1.5rem] items-center h-[6.25rem] dark:text-white text-dark-1"
                  >
                    <div className="lg:w-3/4 pr-[1rem] ">
                      <div className="lg:flex justify-start space-x-[1rem]">
                        <div className="font-medium sb-text-rem-caption1 text-center lg:text-left">
                          {fileNameUploaded}
                        </div>
                        <>
                          {fileUploaded?.nb_errors ? (
                            <div className="sb-text-rem-caption2 text-red-1">
                              {fileUploaded?.nb_errors}
                              {fileUploaded?.nb_errors > 1
                                ? ' Erreurs'
                                : ' Erreur'}
                            </div>
                          ) : (
                            <></>
                          )}
                          <div className="sb-text-rem-caption2">
                            {fileUploaded?.nb_operations}
                            {fileUploaded?.nb_operations &&
                            fileUploaded?.nb_operations > 1
                              ? ' Opérations'
                              : ' Opération'}
                          </div>
                          <div className="sb-text-rem-caption2">
                            {fileUploaded?.nb_folders}
                            {fileUploaded?.nb_folders &&
                            fileUploaded?.nb_folders > 1
                              ? ' Dossiers'
                              : ' Dossier'}
                          </div>
                        </>
                      </div>
                    </div>
                    <div className="flex lg:mt-0 mt-[.5rem]">
                      <div className="h-[2.5rem] lg:w-2/3 lg:mr-0 mr-[1rem]">
                        <Button
                          borderRadius={radiusPx}
                          addClass="btn-bulkImport"
                          onClick={() =>
                            handleDelete().then(() => {
                              getListFilesImported(updateListFilesImported);
                              updateWebSocketConnected(false);
                              if (errorWindow) {
                                updateErrorWindow(false);
                              }
                            })
                          }
                          label="Supprimer"
                          color="white"
                          backgroundColor="#a60e28"
                        />
                      </div>
                      <div className="h-[3.5rem] w-[12.5rem]">
                        <Button
                          borderRadius={radiusPx}
                          addClass="btn-bulkImport"
                          onClick={() => {
                            updateImportLoading(0);
                            // ENVOIE LE FICHIER POUR TRAITEMENT
                            importXls(fileUploaded?.id as number);
                          }}
                          disabled={isErrorXLS}
                          label="Importer"
                          arrow
                          color={colorTextButton}
                          backgroundColor={colorPrimary}
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className={`${scrollBarColor} overflow-x-scroll max-h-[calc(100%-6.25rem)] ml-[1rem] w-full dark:bg-transparent bg-white`}
                  >
                    {fileNameUploaded !== '' && <ArrayFileXls />}
                  </div>
                </>
              )}
            </>
          )}
        </>
      ) : (
        <MapLoading />
      )}
    </>
  );
}

export default ImportedXlsRightPart;
