import { Dispatch, SetStateAction } from 'react';

interface IPdfNavigatorProps {
  numberOfPages: number | undefined;
  pageActive: number;
  setPageActive: Dispatch<SetStateAction<number>>;
}

function PdfNavigator({
  numberOfPages,
  pageActive,
  setPageActive,
}: IPdfNavigatorProps): JSX.Element {
  return (
    <>
      {numberOfPages && numberOfPages > 1 && (
        <>
          <p className="flex w-full justify-center text-[.625rem] mt-[1rem]">
            Page {pageActive} sur {numberOfPages}
          </p>
          <div
            className={`flex ${
              pageActive === 1 ? 'justify-end' : 'justify-between'
            } items-center text-[.75rem] p-[.625rem]`}
          >
            {pageActive !== 1 && (
              <button
                type="button"
                disabled={pageActive <= 1}
                onClick={() =>
                  setPageActive((prevPageActive) => prevPageActive - 1)
                }
              >
                Précédent
              </button>
            )}
            {pageActive < numberOfPages && (
              <button
                type="button"
                disabled={pageActive !== null && pageActive >= numberOfPages}
                onClick={() =>
                  setPageActive((prevPageActive) => prevPageActive + 1)
                }
              >
                Suivant
              </button>
            )}
          </div>
        </>
      )}
    </>
  );
}

export { PdfNavigator };
