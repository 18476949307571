/* RIGHT PART CUSTOMER INFORMATIONS */
export const formCustomerInformation = (housingOwner: string) => {
  const form: JSX.Element = (
    <>
      Cette étape vous permet de valider les informations du client avant
      l&apos;envoi de son récapitulatif. Ce récapitulatif lui sera envoyé sous
      la forme d&apos;un email dont vous serez l&apos;expéditeur. <br /> <br />
      Il listera le montant des aides auxquelles il peut prétendre, ainsi que le
      reste à charge qu&apos;il lui restera à vous régler. <br /> <br />
      Le cadre de contribution sera joint à l&apos;email, vous n&apos;aurez donc
      pas à le lui communiquer vous-même. <br /> <br />
      En validant ces informations, vous pourrez visualiser un aperçu de ce mail
      récapitulatif.
    </>
  );
  const housing: JSX.Element = (
    <>
      {' '}
      *Vous avez précédemment renseigné l&apos;adresse postale sur laquelle les
      travaux seront réalisé, nous avons également besoin de l&apos;adresse
      postale de vie courante de votre client pour compléter votre dossier.
    </>
  );

  let content: JSX.Element[] = [form];

  if (housingOwner !== '') {
    content = [form, housing];
  }

  return content;
};

export const indivision: JSX.Element[] = [
  <>
    {' '}
    Nous avons remarqué lors de la vérification des dossiers de nos partenaires
    installateurs qu’un grand nombre d’inconformités vient de l’absence d’une
    attestation d’indivision du logement.
    <br />
    <br />
    L’indivision est la situation juridique dans laquelle plusieurs personnes
    sont propriétaires d’un même bien immobilier.
    <br />
    <br />
    Pour savoir si le bien de vos clients est en indivision, il suffit de
    consulter leur taxe foncière sur lequel vous pourrez voir la mention
    PROP/INDIVIS dans le champ Débiteurs Légaux.
  </>,
];

export const taxHouseholdCustomerInformation: JSX.Element[] = [
  <>
    {' '}
    Nous allons vérifier que le ménage est bien éligible aux aides que vous avez
    précédemment calculées. <br /> <br />
    Pour cela, renseignez ci-contre, chaque foyer fiscal constituant le ménage.
    Pour chaque foyer fiscal, il y a un avis d&apos;imposition. Un couple marié
    est sur le même avis d&apos;imposition, et représente donc un seul foyer
    fiscal. <br /> <br />
    Un couple en concubinage aura deux avis d&apos;imposition, dans ce cas ce
    ménage sera constitué de deux foyers fiscaux. Vous devez renseigner le
    numéro fiscal de l&apos;avis d’imposition de l’année n ou n-1 (c&apos;est à
    dire l&apos;avis des revenus n-1 ou n-2). Attention, pour un dossier
    MaPrimeRénov&apos;, seul l&apos;avis n sera accepté (c&apos;est à dire les
    revenus n-1).
  </>,
];

export const cadastreWorksAddressInformation = (workAddress: string) => {
  const result: JSX.Element[] = [
    <>
      Lors de la saisie de l’adresse des travaux, nous n’avons pas pu identifier
      exactement les références cadastrales du logement concernées par les
      travaux. <br /> <br />
      Afin de compléter votre dossier, nous avons besoin de ces informations.
      Vous pouvez cliquer sur ce lien afin trouver ces informations et les
      renseigner. <br /> <br />
      <a href="/" className="text-purple-1 no-underline">
        En cliquant sur ce lien{' '}
      </a>
      , vous pouvez identifier la parcelle correspondant à l&apos;adresse des
      travaux : <span className="text-purple-1">{workAddress}</span>
    </>,
  ];
  return result;
};

/* RIGHT PART MPR */
export const subAndMaterial: JSX.Element[] = [
  <>
    Si vous faites appel à un sous-traitant, vous devez renseigner ci-contre les
    informations à propos de ce sous-traitant.
    <br />
    Pour cela, cochez ‘Réalisé par un sous-traitant’ pour l’opération concernée,
    puis remplissez les champs.
    <br />
    Pensez-bien également à renseigner ces mêmes informations sur le devis
    client et la facture des travaux, accompagnés du numéro de qualification
    RGE.
  </>,
  <>
    Si les travaux que vous entreprenez impliquent l&apos;installation d&apos;un
    matériel, nous vous recommandons de l&apos;indiquer. Pour cela, vous pouvez
    utiliser notre moteur de recherche pour trouver la marque et la référence à
    installer.
    <br />
    Cette étape est recommandée, mais pas obligatoire. Si vous ne trouvez pas la
    marque et la référence du matériel, vous pouvez nous l&apos;indiquer à
    l&apos;adresse{' '}
    <a
      href="mailto:contact@drapo.com"
      target="_blank"
      rel="noreferrer"
      className="text-purple-1 no-underline"
    >
      contact@drapo.com
    </a>
    .
    <br />
    La marque et la référence du matériel à installer doit figurer sur votre
    devis et votre facture.
  </>,
];

export const sub: JSX.Element[] = [
  <>
    Si vous faites appel à un sous-traitant, vous devez renseigner ci-contre les
    informations à propos de ce sous-traitant.
    <br />
    Pour cela, cochez ‘Réalisé par un sous-traitant’ pour l’opération concernée,
    puis remplissez les champs.
    <br />
    Pensez-bien également à renseigner ces mêmes informations sur le devis
    client et la facture des travaux, accompagnés du numéro de qualification
    RGE.
  </>,
];

/* RIGHT PART UPLOADFILES */

export const uploadFilesInfos: JSX.Element[] = [
  <>
    Déposer ci-contre votre devis signé ou non signé et daté par votre client
    ainsi que les éventuels documents complémentaires. Merci de vous assurer que
    votre devis est bien conforme avant de nous le transmettre. Pour cela,{' '}
    <a
      href="https://guide.drapo.com"
      target="_blank"
      rel="noreferrer"
      className="text-purple-1 no-underline"
    >
      cliquez ici
    </a>{' '}
    pour lire notre guide.
  </>,
];
