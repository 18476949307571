import { InstallerContext } from '@auth/utils/authContext';
import { INbNotifs } from '@dashboard/utils/dashboardTypes';
import { Dispatch, SetStateAction, useContext } from 'react';
import { NavRequestForSupplements } from './NavRequestForSupplements';
import ButtonDDC from './ButtonDDC';

interface IProps {
  elementActive: string;
  setElementActive: Dispatch<SetStateAction<string>>;
  nbNotifs: INbNotifs;
  isRetractable: boolean;
  setIsRetractable: Dispatch<SetStateAction<boolean>>;
}

function HeaderRequestForSupplements({
  elementActive,
  setElementActive,
  nbNotifs,
  isRetractable,
  setIsRetractable,
}: IProps): JSX.Element {
  const { radiusPx } = useContext(InstallerContext);

  return (
    <div
      style={{ borderTopRightRadius: radiusPx }}
      className={`${
        isRetractable
          ? 'flex flex-col justify-center items-center'
          : 'pt-[1rem] px-[2rem]'
      } lg:dark:bg-dark-5 bg-white h-home-header relative dark:text-white text-dark-1 dark:border-none border-b border-b-borderLightMode border-b-[1px]`}
    >
      <div className={`${!isRetractable && 'flex justify-between'}`}>
        {!isRetractable && (
          <div
            style={{
              transition: 'all 1s ease',
              opacity: isRetractable ? 0 : 1,
            }}
            className="text-[1.875rem] lg:text-left text-center leading-[2.25rem] whitespace-nowrap"
          >
            Demande
            <br />
            de compléments
          </div>
        )}
        <ButtonDDC
          isRetractable={isRetractable}
          setIsRetractable={setIsRetractable}
        />
      </div>
      {!isRetractable && (
        <NavRequestForSupplements
          elementActive={elementActive}
          setElementActive={setElementActive}
          nbNotifs={nbNotifs}
        />
      )}
    </div>
  );
}

export { HeaderRequestForSupplements };
