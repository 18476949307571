import { imgNewProfessionnalSpace } from '@assets/images/images';
import { InstallerContext } from '@auth/utils/authContext';
import { Dispatch, SetStateAction, useContext } from 'react';
import { Button } from 'storybook-design-system-drapo';
import { dark1, white } from '@assets/colors';
import { useMediaQuery } from 'react-responsive';

interface IProps {
  setIsNewProfessionnalSpace: Dispatch<SetStateAction<boolean>>;
}
function ModalNewProfessionnalSpace({
  setIsNewProfessionnalSpace,
}: IProps): JSX.Element {
  const { colorPrimary, radiusPx, isThemeDark } = useContext(InstallerContext);
  const isMobile = useMediaQuery({ maxWidth: 1024 });

  return (
    <div
      style={{ zIndex: 61 }}
      className="w-screen bg-[#EFEFEF] lg:fixed inset-0 z-50 h-full flex items-center justify-center  bg-opacity-30 mt-[-5rem] ml-[-.625rem] lg:mt-0 lg:ml-0"
    >
      <div
        style={{ borderRadius: isMobile ? '' : radiusPx }}
        className={` bg-black shadow-2xl  overflow-hidden lg:h-[55%] h-full lg:w-9/12 lg:flex `}
      >
        <div className="lg:w-6/12 lg:flex flex-col lg:justify-between h-full lg:p-[4.25rem]">
          <p className="lg:text-[2.188rem] text-[1.8rem] lg:py-0 py-[8.25rem] lg:px-0 px-[2.25rem]">
            Votre nouvel espace <br /> professionnel
          </p>
          {isMobile && (
            <div className="lg:w-6/12">
              <img
                className="h-full w-full object-cover"
                src={imgNewProfessionnalSpace}
                alt="nouvel espace pro"
              />
            </div>
          )}
          <div className="mt-[-3rem] lg:mt-0 lg:px-0 px-[2.25rem]">
            <p className="text-[1rem] leading-[1.5rem]">
              Nous avons le plaisir de vous présenter votre nouvel espace pro.
              Tout a été repensé pour améliorer votre expérience et faciliter
              vos actions quotidiennes.
            </p>
            <p className="text-[1rem] leading-[1.5rem] mt-[.5rem]">
              Nous savons que tout changement peut être déroutant, alors nous
              avons préparé un guide pour vous aider dans la découverte de ce
              nouvel espace.
            </p>
            <p className="text-[1rem] leading-[1.5rem] mt-[.5rem]">
              N’hésitez pas à contacter votre Référent de compte pour plus de
              détail et nous vous remercions pour votre confiance.
            </p>
          </div>

          <div className="lg:flex items-center lg:mt-0 mt-[2rem] p-[2rem] lg:p-0">
            <div>
              <Button
                arrow
                borderRadius={radiusPx === '16px' ? '8px' : '0px'}
                label="Accéder"
                color={!isThemeDark ? dark1 : white}
                backgroundColor={colorPrimary}
                onClick={() => {
                  setIsNewProfessionnalSpace(false);
                  localStorage.setItem('isNewProfessionnalSpace', 'ok');
                }}
              />
            </div>
            <button
              style={{
                borderRadius: radiusPx === '16px' ? '8px' : '0px',
                transition: 'all 0.3s ease',
              }}
              className="lg:ml-[1.5rem] lg:mt-0 mt-[1rem] w-full lg:w-auto border lg:py-[1.1rem] py-[.9rem] px-[1.5rem] hover:border-opacity-60 hover:border-white "
              type="button"
            >
              <a
                target="_blank"
                href="https://support.drapo.com"
                rel="noreferrer"
              >
                {' '}
                Voir le guide
              </a>
            </button>
          </div>
        </div>
        {!isMobile && (
          <div className="lg:w-6/12">
            <img
              className="h-full w-full object-cover"
              src={imgNewProfessionnalSpace}
              alt="nouvel espace pro"
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default ModalNewProfessionnalSpace;
