import { useContext } from 'react';
import { FOLDER_MONITORING_TABS } from '@folderMonitoring/utils/tabs';
import { NavButton } from '@components/NavButton';
import { v4 } from 'uuid';
import { arrowLeft_black, arrowLeft } from '@assets/images/images';
import Loader from '@components/loaders/Loader';
import { FolderMonitoringContext } from '@folderMonitoring/utils/context/folderMonitoringContext';
import { Link, useLocation } from 'react-router-dom';
import { InstallerContext } from '@auth/utils/authContext';
import { IListFolders } from '@dashboard/utils/dashboardTypes';
import SearchFolder from '@dashboard/components/searchFolder/SearchFolder';

function HeaderFolderMonitoring(): JSX.Element {
  const location = useLocation();
  const { isLoading, tab, updateTab, updateIsModalDeleteFolder } = useContext(
    FolderMonitoringContext
  );
  const { folderActif, updateDeleteOperationOrFolder } = useContext(
    FolderMonitoringContext
  );
  const { radiusPx, isThemeDark } = useContext(InstallerContext);

  return (
    <>
      <div
        className="pt-[2.25rem] w-full dark:bg-dark-5 bg-white dark:text-white text-dark-1 px-[2rem] w-full h-home-header relative dark:border-none border-b border-b-borderLightMode border-b-[1px]"
        style={{ borderTopLeftRadius: radiusPx }}
      >
        <div className="flex items-center space-x-[1.5rem] w-full mb-[.875rem]">
          <Link to="/" state={{ prevPath: location.pathname }}>
            <img
              className="h-[1.5rem]"
              src={isThemeDark ? arrowLeft : arrowLeft_black}
              alt="arrow-left"
            />
          </Link>
          {isLoading ? (
            <div
              style={{ borderRadius: radiusPx === '16px' ? '8px' : '' }}
              className="h-[2.5rem] w-[12.5rem] dark:bg-dark-3 dark:opacity-79 bg-[#E9E9E9] animate__infinite  animate__flash animate__animated animate__slower"
            />
          ) : (
            <div className="xl:text-[1.875rem] text-[1.625rem] font-[500] animate__animated animate__fadeIn">
              {folderActif[0].nom_client} {folderActif[0].prénom_client}
            </div>
          )}
        </div>
        <div className="flex space-x-[1.5rem] text-[.75rem] absolute bottom-0">
          {Object.keys(FOLDER_MONITORING_TABS).map((tabKey) => (
            <NavButton
              key={v4()}
              title={FOLDER_MONITORING_TABS[tabKey]}
              elementActive={tab}
              updateElementActive={updateTab}
              isDashboard={false}
            />
          ))}
        </div>
        <div className="absolute right-[2rem] bottom-[2.5rem] w-5/12 z-[99999]">
          <SearchFolder />
        </div>
        {!folderActif.map((el: IListFolders) => el.deletable).includes(0) &&
          !isLoading && (
            <button
              onClick={() => {
                updateIsModalDeleteFolder(true);
                updateDeleteOperationOrFolder('folder');
              }}
              type="button"
              className="absolute right-[1.875rem] text-[.875rem] bottom-[.625rem] hover:opacity-80"
            >
              Supprimer le dossier
            </button>
          )}
      </div>
      {isLoading && <Loader />}
    </>
  );
}

export default HeaderFolderMonitoring;
