import { useContext, useEffect, useState } from 'react';
import {
  backgroundQuaternaryLightMode,
  dark1,
  dark4,
  dark5,
  darkVariant1,
  purple1,
  white,
} from '@assets/colors';
import { InstallerContext } from '@auth/utils/authContext';
import { FolderContext } from '@folder/utils/FoldersContext';
import { v4 } from 'uuid';
import {
  IDimensioningNoteType,
  IDimensioningNoteMaterial,
} from '@folder/utils/folderTypes';
import {
  getDimensioningNoteDatas,
  sendDimensioningNoteDatas,
} from '@folder/apiRequests/folderRequests';
import MaterialInfosFields from '@folder/components/forms/dimensioningNote/MaterialInfosFields';
import {
  Button,
  HeightSElect,
  SimpleSelect,
  CheckCard,
  FileMultiUpload,
} from 'storybook-design-system-drapo';
import Loader from '@components/loaders/Loader';
import { convertHexToRGBA } from '@utils/utils';
import { WarningIcon } from '@assets/svgComponents/svgComponents';

function DimensioningNote(): JSX.Element {
  const { radiusPx, isThemeDark, colorPrimary, colorTextButton, installer } =
    useContext(InstallerContext);
  const {
    folderReference,
    updateStepNumberFolder,
    roomsHeight,
    updateRoomsHeight,
    stopTempHeatPump,
    tempPowerHeatPump,
    heatingElementPower,
    otherHeatingSystems,
    updateDimensioningNote,
    showDimensioningNote,
    updateShowDimensioningNote,
    dimensioningNote,
    housingValue,
    updateHousingValue,
    hasDimensioningNotePro,
    updateHasDimensioningNotePro,
    dimensioningNotePro,
    updateDimensioningNotePro,
    thermalInsulationIsCheck,
    updateThermalInsulationIsCheck,
  } = useContext(FolderContext);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isOk, setIsOk] = useState<boolean>(false);
  const [heightSelectIsChanging, setHeightSelectIsChanging] =
    useState<boolean>(false);

  const [housingInfos, setHousingInfos] = useState<IDimensioningNoteType[]>([]);

  const [material, setMaterial] = useState<
    IDimensioningNoteMaterial | undefined
  >();
  const [isFileUploading, setIsFileUploading] = useState<boolean>(false);
  const [fileName, setFileName] = useState<string>('');
  const [fileToSend, setFileToSend] = useState<File>();
  const [filesUploaded, setFilesUploaded] = useState<any[]>([]);

  const onUploadFile = (file: File) => {
    if (showDimensioningNote && dimensioningNote !== '') {
      updateShowDimensioningNote(false);
      updateDimensioningNote('');
    }
    setIsFileUploading(false);
    setFileName(file.name);
    setFileToSend(file);
    setFilesUploaded([
      {
        name: file.name,
        path: URL.createObjectURL(file),
      },
    ]);
  };

  const onDeleteFileUpload = () => {
    setFilesUploaded([]);
  };

  const onSelectInList = (val: string, slug: string) => {
    const tab = housingValue;
    const alreadyExist = tab.findIndex((el) => el.title === slug);

    tab.splice(alreadyExist, 1);

    tab.push({ title: slug, value: val });
    updateHousingValue(tab);

    if (showDimensioningNote && dimensioningNote !== '') {
      updateShowDimensioningNote(false);
      updateDimensioningNote('');
    }
  };

  const handleClickCheckCard = () => {
    updateHasDimensioningNotePro(!hasDimensioningNotePro);
    if (showDimensioningNote && dimensioningNote !== '') {
      updateShowDimensioningNote(false);
      updateDimensioningNote('');
    }
  };

  const onClickNext = () => {
    setIsLoading(true);
    if (heightSelectIsChanging) {
      setHeightSelectIsChanging(false);
    }
    if (!showDimensioningNote) {
      sendDimensioningNoteDatas(
        folderReference,
        setIsLoading,
        housingInfos,
        roomsHeight,
        stopTempHeatPump,
        tempPowerHeatPump,
        heatingElementPower,
        otherHeatingSystems,
        housingValue,
        updateHousingValue,
        updateShowDimensioningNote,
        updateDimensioningNote,
        hasDimensioningNotePro,
        fileToSend,
        updateStepNumberFolder,
        updateDimensioningNotePro,
        installer.trust_nd
      );
    } else {
      setIsLoading(false);
      updateStepNumberFolder(4);
    }
  };

  useEffect(() => {
    getDimensioningNoteDatas(folderReference, setHousingInfos, setMaterial);
  }, []);

  useEffect(() => {
    const infosOk =
      stopTempHeatPump !== '' &&
      tempPowerHeatPump !== '' &&
      heatingElementPower !== '' &&
      otherHeatingSystems !== '' &&
      thermalInsulationIsCheck;

    setIsOk(
      (hasDimensioningNotePro &&
        fileToSend !== undefined &&
        installer.trust_nd) ||
        (infosOk && !hasDimensioningNotePro) ||
        (infosOk && hasDimensioningNotePro && fileToSend !== undefined)
    );
  }, [
    stopTempHeatPump,
    tempPowerHeatPump,
    heatingElementPower,
    otherHeatingSystems,
    hasDimensioningNotePro,
    fileToSend,
    thermalInsulationIsCheck,
  ]);

  useEffect(() => {
    if (
      showDimensioningNote &&
      dimensioningNote !== '' &&
      heightSelectIsChanging
    ) {
      updateShowDimensioningNote(false);
      updateDimensioningNote('');
    }
  }, [showDimensioningNote, dimensioningNote, heightSelectIsChanging]);

  useEffect(() => {
    if (hasDimensioningNotePro) {
      updateShowDimensioningNote(false);
    }
  }, [hasDimensioningNotePro]);

  useEffect(() => {
    if (dimensioningNotePro) {
      setFileToSend(dimensioningNotePro);
    }
  }, [dimensioningNotePro]);

  return (
    <>
      <div
        style={{ borderRadius: radiusPx }}
        className="dark:bg-dark-2 bg-white p-[1.5rem] mt-[2rem]"
      >
        <CheckCard
          borderRadius={radiusPx}
          colorHover={colorTextButton}
          colorChecked={colorTextButton}
          colorNotChecked={isThemeDark ? white : dark1}
          backgroundColorNotChecked={
            isThemeDark ? dark4 : backgroundQuaternaryLightMode
          }
          backgroundColorChecked={colorPrimary}
          label="J’atteste de faire les travaux de calorifugeage"
          subtitle="En cochant cette case, j’atteste calorifuger l’ensemble des réseaux hydrauliques (en volume non chauffé) et frigorifiques."
          onClick={() =>
            updateThermalInsulationIsCheck(!thermalInsulationIsCheck)
          }
          addClass="px-[2.5rem!important] py-[1.75rem!important] mb-[1rem]"
          checked={thermalInsulationIsCheck}
        />
        <CheckCard
          borderRadius={radiusPx}
          colorHover={colorTextButton}
          colorChecked={colorTextButton}
          colorNotChecked={isThemeDark ? white : dark1}
          backgroundColorNotChecked={
            isThemeDark ? dark4 : backgroundQuaternaryLightMode
          }
          backgroundColorChecked={colorPrimary}
          label="Je possède ma propre note de dimensionnement"
          subtitle="J'atteste être en possession de la note de dimensionnement du chantier. Celle-ci est conforme au logement des travaux et aux informations du matériel qui sera installé. En cochant cette case, je dois transmettre ma note de dimensionnement et la joindre à ce dossier."
          onClick={() => handleClickCheckCard()}
          addClass="px-[2.5rem!important] py-[1.75rem!important] mb-[1rem]"
          checked={hasDimensioningNotePro}
        />
        {hasDimensioningNotePro && (
          <>
            <div
              className={`rounded-default p-4 pt-6 border border-purple-1 mb-4 relative ${
                isThemeDark ? 'text-white' : 'text-black'
              }`}
            >
              Même si vous disposez de votre propre note de dimensionnement, il
              est nécessaire de compléter les formulaires ci-dessous,
              intitulés&nbsp;
              <b>Informations du logement</b> et <b>Informations du matériel</b>
              , afin de vérifier la concordance de ces informations avec votre
              note de dimensionnement.
              <div className="absolute top-2 right-4">
                <WarningIcon
                  width="2rem"
                  height="2rem"
                  fill={isThemeDark ? white : purple1}
                />
              </div>
            </div>
            <FileMultiUpload
              key={v4()}
              fileName="Note de dimensionnement"
              fileNameUpload={fileName}
              required
              isFileUploading={isFileUploading}
              borderRadiusContainer={radiusPx}
              borderRadius={radiusPx}
              backgroundColorFileLoaded={colorPrimary}
              backgroundUploadedColor={colorPrimary}
              containerBackgroundColor={
                isThemeDark ? dark1 : convertHexToRGBA(colorPrimary, 0.1)
              }
              color={isThemeDark ? white : dark1}
              backgroundColorAddFile={isThemeDark ? dark5 : white}
              backgroundColorComponent={
                isThemeDark ? dark1 : convertHexToRGBA(colorPrimary, 0.1)
              }
              onDelete={onDeleteFileUpload}
              onChangeFileUpload={(files) => onUploadFile(files)}
              filesUploaded={filesUploaded}
            />
          </>
        )}
      </div>
      <div
        style={{ borderRadius: radiusPx }}
        className="dark:bg-dark-2 bg-white p-[1.5rem] mt-[1.5rem]"
      >
        <div className="mb-[1.5rem] dark:text-white text-colorTextLightMode leading-[1.5rem] flex flex-col space-y-[.75rem]">
          <p className="font-[500]">Informations du logement</p>
          <p className="text-[.875rem]">
            Nous avons besoin d’informations complémentaires sur le logement{' '}
            {hasDimensioningNotePro
              ? 'afin de vérifier que votre note de dimensionnement est conforme'
              : 'pour la génération de la note de dimensionnement'}
            .
          </p>
        </div>
        <HeightSElect
          label="Hauteur des pièces (m)"
          borderRadius={radiusPx}
          color={isThemeDark ? white : dark1}
          backgroundColor={
            isThemeDark ? darkVariant1 : backgroundQuaternaryLightMode
          }
          backgroundColorIcon={isThemeDark ? dark4 : white}
          darkMode={isThemeDark}
          value={roomsHeight}
          setValue={updateRoomsHeight}
          setIsChange={setHeightSelectIsChanging}
        />
        <div className="flex flex-col items-center space-y-[.75rem] mt-[.75rem]">
          {housingInfos.map((el: IDimensioningNoteType) => (
            <div key={v4()} className="w-full">
              <SimpleSelect
                label={el.title}
                borderRadius={radiusPx}
                color={isThemeDark ? white : dark1}
                backgroundColor={
                  isThemeDark ? darkVariant1 : backgroundQuaternaryLightMode
                }
                backgroundColorIconSearch={isThemeDark ? dark4 : white}
                listToDisplay={el.values}
                onSelectInList={(val: string) => onSelectInList(val, el.slug)}
                valueToDisplay={
                  housingValue[
                    housingValue.findIndex(
                      (element) => element.title === el.slug
                    )
                  ].value
                    ? housingValue[
                        housingValue.findIndex(
                          (element) => element.title === el.slug
                        )
                      ].value
                    : el.values[0]
                }
              />
            </div>
          ))}
        </div>
      </div>
      {!(installer.trust_nd && hasDimensioningNotePro) && (
        <div
          style={{ borderRadius: radiusPx }}
          className="dark:bg-dark-2 bg-white p-[1.5rem] mt-[1.5rem]"
        >
          <div className="mb-[1.5rem] dark:text-white text-colorTextLightMode leading-[1.5rem] flex flex-col space-y-[.75rem]">
            <p className="font-[500]">Informations du matériel</p>
            <p className="text-[.875rem]">
              Nous avons besoin d’informations complémentaire sur votre matériel{' '}
              {`${material ? `${material.marque} ${material.référence}` : ''}`}.
            </p>
          </div>
          <MaterialInfosFields />
        </div>
      )}
      <div className="w-full pb-[3rem]">
        <Button
          disabled={!isOk}
          borderRadius={radiusPx}
          addClass="mt-[1.5rem] !w-full"
          buttonArrowSmall
          label={`${
            showDimensioningNote || hasDimensioningNotePro
              ? 'Suivant'
              : 'Valider et prévisualiser la note de dimensionnement'
          }`}
          color={colorTextButton}
          backgroundColor={colorPrimary}
          arrow
          onClick={() => onClickNext()}
        />
        {isLoading && (
          <div className="mt-[1.5rem]">
            <Loader />
          </div>
        )}
      </div>
    </>
  );
}

export default DimensioningNote;
