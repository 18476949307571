import { Button, Input } from 'storybook-design-system-drapo';
import React, {
  Dispatch,
  SetStateAction,
  useState,
  useEffect,
  useContext,
} from 'react';
import { InstallerContext } from '@auth/utils/authContext';
import {
  backgroundQuaternaryLightMode,
  colorTextLightMode,
  dark1,
  darkVariant1,
  white,
} from '@assets/colors';
import { CloseIcon } from '@assets/svgComponents/svgComponents';

interface ManualAdressProps {
  isWorkAddress?: boolean;
  setNumberStreet?: Dispatch<SetStateAction<string>>;
  street: string;
  setStreet: Dispatch<SetStateAction<string>>;
  zip: string;
  setZip: Dispatch<SetStateAction<string>>;
  city: string;
  setCity: Dispatch<SetStateAction<string>>;
  setManualAdress: Dispatch<SetStateAction<boolean>>;
  setValue: (val: string, shouldFetchData?: boolean | undefined) => void;
  setIsManual?: Dispatch<SetStateAction<boolean>>;
}

function ManualAddress({
  isWorkAddress,
  setNumberStreet,
  street,
  setStreet,
  zip,
  setZip,
  city,
  setCity,
  setManualAdress,
  setValue,
  setIsManual,
}: ManualAdressProps): JSX.Element {
  const [numberStreetManual, setNumberStreetManual] = useState<string>('');
  const [streetManual, setStreetManuel] = useState<string>('');
  const [streetZipManual, setStreetZipManuel] = useState<string>('');
  const [streetCityManual, setStreetCityManuel] = useState<string>('');
  const [errorZip, setErrorZip] = useState<boolean>(false);

  const [isNum, setIsNum] = useState(false);

  const { colorPrimary, radiusPx, isThemeDark, colorTextButton } =
    useContext(InstallerContext);

  useEffect(() => {
    if (isWorkAddress) {
      if (
        streetManual !== '' &&
        streetZipManual !== '' &&
        streetCityManual !== ''
      ) {
        if (streetZipManual.length !== 5) {
          setErrorZip(true);
        } else {
          setErrorZip(false);
          setValue(
            `${numberStreetManual} ${streetManual}, ${streetCityManual}, France`
          );
        }
      }
    }
  }, [
    isWorkAddress,
    numberStreetManual,
    streetManual,
    streetZipManual,
    streetCityManual,
  ]);

  const isNumeric = (e: any) => {
    const charCode = e.charCode;
    if (charCode === 43) {
      setIsNum(false);
    } else if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      setIsNum(true);
    } else {
      setIsNum(false);
    }
  };

  const confirmAdress = () => {
    if (zip.length === 5) {
      setStreet(`${numberStreetManual} ${streetManual}`);
      setManualAdress(false);
      setValue(`${numberStreetManual} ${streetManual} ${zip} ${city}, France.`);
      if (setIsManual) {
        setIsManual(false);
      }
    } else {
      setErrorZip(true);
    }
  };

  return (
    <div className="mt-[1.5rem] dark:bg-dark-1 bg-white p-[.75rem] rounded-[1.5rem]">
      <div className="mb-[.75rem] flex justify-end mr-[.5rem] mt-[.5rem]">
        <button
          type="button"
          className="opacity-80 hover:opacity-100"
          onClick={() => {
            setManualAdress(false);
            if (setIsManual) {
              setIsManual(false);
            }
          }}
        >
          <CloseIcon
            fill={isThemeDark ? white : colorTextLightMode}
            width="1rem"
            height="1rem"
          />
        </button>
      </div>
      <div className="flex">
        <Input
          addClassInput={!isThemeDark ? 'light-mode-input' : 'dark-mode-input'}
          borderRadius={radiusPx}
          addClass="w-1/5 "
          backgroundColor={
            isThemeDark ? darkVariant1 : backgroundQuaternaryLightMode
          }
          inputType="text"
          isNumber
          label="Numéro"
          value={numberStreetManual}
          onChange={(e) => {
            if (setNumberStreet) {
              setNumberStreet(e.target.value);
            }
            setNumberStreetManual(e.target.value);
          }}
          color={isThemeDark ? white : dark1}
        />
        <Input
          addClassInput={!isThemeDark ? 'light-mode-input' : 'dark-mode-input'}
          borderRadius={radiusPx}
          addClass="w-4/5 ml-[.75rem]"
          backgroundColor={
            isThemeDark ? darkVariant1 : backgroundQuaternaryLightMode
          }
          inputType="text"
          label="Nom de la rue ou Lieu-dit"
          value={streetManual}
          onChange={(e) => {
            setStreet(e.target.value);
            setStreetManuel(e.target.value);
          }}
          color={isThemeDark ? white : dark1}
        />
      </div>
      <Input
        addClassInput={!isThemeDark ? 'light-mode-input' : 'dark-mode-input'}
        borderRadius={radiusPx}
        addClass={`mt-[.75rem] ${errorZip ? 'border border-red-500' : ''}`}
        backgroundColor={
          isThemeDark ? darkVariant1 : backgroundQuaternaryLightMode
        }
        inputType="text"
        isNumber
        onKeyPress={(e) => isNumeric(e)}
        readOnly={isNum}
        label="Code postal"
        value={streetZipManual}
        onChange={(e) => {
          setZip(e.target.value);
          setStreetZipManuel(e.target.value);
          if (setErrorZip) {
            setErrorZip(false);
          }
        }}
        color={isThemeDark ? white : dark1}
      />
      {errorZip && (
        <p className="text-[.75rem] text-red-500 my-[1rem] ml-[.5rem]">
          Le code postal doit contenir 5 chiffres.
        </p>
      )}
      <Input
        addClassInput={!isThemeDark ? 'light-mode-input' : 'dark-mode-input'}
        borderRadius={radiusPx}
        addClass="mt-[.75rem]"
        color={isThemeDark ? white : dark1}
        backgroundColor={
          isThemeDark ? darkVariant1 : backgroundQuaternaryLightMode
        }
        inputType="text"
        label="Ville"
        value={streetCityManual}
        onChange={(e) => {
          setCity(e.target.value);
          setStreetCityManuel(e.target.value);
        }}
      />
      <div className="mt-[.75rem] w-full justify-end flex">
        <Button
          borderRadius={radiusPx}
          label="Confirmer"
          backgroundColor={colorPrimary}
          color={colorTextButton}
          onClick={confirmAdress}
          disabled={street === '' && zip === '' && city === ''}
        />
      </div>
    </div>
  );
}

export default ManualAddress;

ManualAddress.defaultProps = {
  isWorkAddress: undefined,
  setNumberStreet: undefined,
  setIsManual: undefined,
};
