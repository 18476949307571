import { Dispatch, SetStateAction, useContext } from 'react';
import { CheckCardSquare } from 'storybook-design-system-drapo';
import { InstallerContext } from '@auth/utils/authContext';
import { colorTextLightMode, dark1, white } from '@assets/colors';
import { convertHexToRGBA } from '@utils/utils';

interface AgreeCheckCardsProps {
  typeAgree: boolean;
  updateAgree: Dispatch<SetStateAction<boolean>>;
  trueLabel?: string;
  falseLabel?: string;
  isMpr: boolean;
}

function AgreeCheckCards({
  typeAgree,
  updateAgree,
  trueLabel = 'oui',
  falseLabel = 'non',
  isMpr,
}: AgreeCheckCardsProps): JSX.Element {
  const onCheck = (choice: boolean) => {
    updateAgree(choice);
  };
  const { colorPrimary, radiusPx, isThemeDark, colorTextButton } =
    useContext(InstallerContext);

  return (
    <div
      className={`flex  w-full ${
        isMpr ? 'space-x-[2.5rem]' : 'space-x-[1rem]'
      }`}
    >
      <div className="w-1/2 h-72 checkcards-folder-indivision">
        <CheckCardSquare
          borderRadius={radiusPx}
          textColorChecked={colorTextButton}
          textColorNotChecked={isThemeDark ? white : colorTextLightMode}
          backgroundColorNotChecked={
            isThemeDark ? dark1 : convertHexToRGBA(colorPrimary, 0.1)
          }
          backgroundColorChecked={colorPrimary}
          checked={typeAgree}
          label={trueLabel}
          onClick={() => onCheck(true)}
        />
      </div>
      <div className="w-1/2 h-72 ">
        <CheckCardSquare
          borderRadius={radiusPx}
          textColorChecked={colorTextButton}
          textColorNotChecked={isThemeDark ? white : colorTextLightMode}
          backgroundColorNotChecked={
            isThemeDark ? dark1 : convertHexToRGBA(colorPrimary, 0.1)
          }
          backgroundColorChecked={colorPrimary}
          checked={!typeAgree}
          label={falseLabel}
          onClick={() => onCheck(false)}
        />
      </div>
    </div>
  );
}

AgreeCheckCards.defaultProps = {
  trueLabel: 'oui',
  falseLabel: 'non',
};

export default AgreeCheckCards;
