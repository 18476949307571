/* eslint-disable no-nested-ternary */
import { useContext, useEffect, useState } from 'react';
import {
  Button,
  CheckCard,
  InputSearch,
  WarningNotification,
} from 'storybook-design-system-drapo';
import { v4 } from 'uuid';
import LoaderOperationList from '@components/loaders/LoaderOperationList';
import { getAllOperation } from '@simulator/apiRequests/SimulatorRequests';
import {
  IOperation,
  IOperationDatasType,
} from '@simulator/utils/simulatorTypes';
import {
  OperationsListContext,
  SimulatorContext,
} from '@simulator/utils/simulatorContext';
import { sansAccent } from '@simulator/utils/functions';
import { InstallerContext } from '@auth/utils/authContext';
import { operationDatasToPush } from '@simulator/utils/initialsState';
import {
  backgroundBodyLightMode,
  backgroundQuaternaryLightMode,
  dark1,
  dark4,
  darkVariant1,
  white,
} from '@assets/colors';
import { useMediaQuery } from 'react-responsive';
import { useLocation } from 'react-router-dom';
import ModalEligibilityVMC from '@simulator/components/ModalEligibilityVMC';
import { convertHexToRGBA } from '../../../utils/utils';

function ChooseMultipleOperation(): JSX.Element {
  const isMobile = useMediaQuery({
    query: '(max-width: 1024px)',
  });

  const location = useLocation();

  const [data, setData] = useState<IOperation[]>([]);
  const [filteredData, setFilteredData] = useState<IOperation[]>([]);
  const [incompatiblesIsActive, setIncompatiblesIsActive] =
    useState<boolean>(false);
  const [incompatibles2IsActive, setIncompatibles2IsActive] =
    useState<boolean>(false);

  const {
    updateMultipleOperations,
    updateIncompatibles,
    incompatibles,
    updateCurrentOperation,
    generalComplete,
    updateStepNumber,
    divLeftWidth,
    hasEcsIncompatibility,
  } = useContext(SimulatorContext);
  const { operationList } = useContext(OperationsListContext);
  const { installer, colorPrimary, radiusPx, isThemeDark, colorTextButton } =
    useContext(InstallerContext);

  const [operationsDatasToPush, setOperationsDatasToPush] = useState<
    IOperationDatasType[]
  >([]);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [buttonWidth, setButtonWidth] = useState<string>('');

  const getWidth = () => {
    if (divLeftWidth) {
      setButtonWidth(`${divLeftWidth + 1}px`);
    }
  };

  useEffect(() => {
    getAllOperation(
      setData,
      setFilteredData,
      updateIncompatibles,
      setIsLoading
    );
    for (let i = 0; i < incompatibles[0].length; i += 1) {
      if (
        operationList.filter((op) => op.id === incompatibles[0][i]).length > 0
      ) {
        setIncompatiblesIsActive(true);
      }
      if (
        operationList.filter((op) => op.id === incompatibles[1][i]).length > 0
      ) {
        setIncompatibles2IsActive(true);
      }
    }
  }, []);
  useEffect(() => {
    getWidth();
  }, [divLeftWidth]);

  const isIncompatible = (operation: IOperation) => {
    const allOperations = [...operationList, ...operationsDatasToPush];
    const multipleOperationsTransformed = allOperations;
    const operationTransformed = operation;
    // Si une opération est ajouté après un premier calcul de prime, on ne peut pas sélectionner bar-th-125 car cela implique une étape spécifique du graph général qui ne peut plus être demandé à ce stade
    if (operationList.length > 0 && operation.slug === 'bar-th-125') {
      return true;
    }
    if (
      (operation.slug === 'bar-th-148' || operation.slug === 'bar-th-101') &&
      incompatibles[1].includes(operation.slug) &&
      incompatibles2IsActive
    ) {
      return true;
    }
    // Si une opération BAR-TH-171 ou BAR-TH-172 est ajoutée après un premier calcul de prime, avec un usage = "ecs", bar-th-148 devient incompatible
    if (operation.slug === 'bar-th-148' && hasEcsIncompatibility) {
      return true;
    }
    if (
      operationTransformed.slug === 'bar-en-102' &&
      operationTransformed.name.includes('intérieur')
    ) {
      operationTransformed.slug = 'bar-en-102-int';
    }
    for (let i = 0; i < operationsDatasToPush.length; i += 1) {
      if (
        allOperations[i].id === 'bar-en-102' &&
        allOperations[i].name.includes('intérieur')
      ) {
        multipleOperationsTransformed[i].id = 'bar-en-102-int';
      }
    }
    return (
      (incompatiblesIsActive && incompatibles[0].includes(operation.slug)) ||
      multipleOperationsTransformed.filter(
        (op) => op.id === operationTransformed.slug
      ).length > 0
    );
  };

  const handleSearchChange = (searchText: string) => {
    if (searchText !== '') {
      const searchValue = sansAccent(searchText.toLowerCase());
      const operationsFiltered: IOperation[] = [];

      Object.values(data).forEach((e: any) => {
        if (
          sansAccent(e.name).match(new RegExp(searchValue, 'i')) ||
          sansAccent(e.slug).match(new RegExp(searchValue, 'i'))
        ) {
          operationsFiltered.push(e);
        }
      });

      setFilteredData(operationsFiltered);
    } else {
      setFilteredData(data);
    }
  };

  const filteredDataWithPrime =
    installer.id === ''
      ? filteredData
      : filteredData.filter(
          (el) =>
            el.eligibility.includes(installer.eligibility[0]) ||
            el.eligibility.includes(installer.eligibility[1])
        );

  const operationToAddInOperationDatas = (
    item: IOperation
  ): IOperationDatasType => {
    const selectedOperation: IOperationDatasType = operationDatasToPush(
      item.slug,
      item.name,
      item.eligibility
    );

    if (item.material !== null) {
      selectedOperation.material = item.material.value;
    }
    return selectedOperation;
  };

  const prepareOperationDatas = () => {
    const operationsReadyToPush = operationsDatasToPush;
    for (let i = 0; i < operationsDatasToPush.length; i += 1) {
      if (operationsDatasToPush[i].id === 'bar-en-102-int') {
        operationsReadyToPush[i].id = 'bar-en-102';
      }
    }
    updateMultipleOperations(operationsReadyToPush);
    updateCurrentOperation(operationsReadyToPush[0]);
  };

  const onClickNext = () => {
    if (generalComplete) {
      updateStepNumber(3);
    } else {
      updateStepNumber(2);
    }
  };

  const checkIfInArray = (item: IOperation) =>
    operationsDatasToPush.some((op) => op.name === item.name);

  const onClickChange = (item: IOperation) => {
    if (checkIfInArray(item)) {
      if (incompatibles[0].includes(item.slug)) {
        setIncompatiblesIsActive(false);
      }
      if (incompatibles[1].includes(item.slug)) {
        setIncompatibles2IsActive(false);
      }
      setOperationsDatasToPush((prevState) =>
        prevState.filter((op) => op.name !== item.name)
      );
    } else {
      if (incompatibles[0].includes(item.slug)) {
        setIncompatiblesIsActive(true);
      }
      if (incompatibles[1].includes(item.slug)) {
        setIncompatibles2IsActive(true);
      }
      const selectedOperation = operationToAddInOperationDatas(item);
      setOperationsDatasToPush((prev: IOperationDatasType[]) => [
        ...prev,
        selectedOperation,
      ]);
    }
  };

  /* const checkImg = (item: IOperation) => {
    try {
      if (item.material && item.material.value) {
        // eslint-disable-next-line import/no-dynamic-require, global-require
        require(`@assets/images/simulator/${item.slug.replace(/-/g, '')}-${
          item.material.value
        }.png`);
      }
      return true;
    } catch (error) {
      return false;
    }
  }; */

  const renderImg = (item: IOperation) => {
    let imgToRender;
    // eslint-disable-next-line @typescript-eslint/no-var-requires,global-require,import/no-dynamic-require
    try {
      if (item.material && item.material.value) {
        // eslint-disable-next-line import/no-dynamic-require, global-require
        imgToRender = require(`@assets/images/simulator/${item.slug.replace(
          /-/g,
          ''
        )}-${item.material.value}.png`);
      } else {
        // eslint-disable-next-line global-require,import/no-dynamic-require
        imgToRender = require(`@assets/images/simulator/${item.img}`);
      }
      return imgToRender;
    } catch (error) {
      try {
        // eslint-disable-next-line global-require,import/no-dynamic-require
        imgToRender = require(`@assets/images/simulator/${item.img}`);
        return imgToRender;
      } catch (e) {
        return undefined;
      }
    }
  };

  // Opérations devant être associées à bar-th-125 pour que celle-ci soit éligible
  const barTh125RelatedOperations = () => {
    const opeRelated = [
      'bar-en-101',
      'bar-en-102',
      'bar-en-102-int',
      'bar-en-104',
      'bar-en-105',
    ];

    return data
      .filter((o) => opeRelated.includes(o.slug))
      .map((o) => ({ code: o.slug, name: o.name }));
  };

  // Bar-th-125 est éligible seulement si elle est associée à certaines opérations d'isolation
  const barTh125IsEligible = (selectedOperations: IOperationDatasType[]) => {
    const isolationOperationsToCheck = new Set(
      barTh125RelatedOperations().map((op) => op.code.toLowerCase())
    );

    let hasBarTh125 = false;
    let hasIsolationOperation = false;

    selectedOperations.forEach((op) => {
      const opId = op.id.toLowerCase();
      if (opId === 'bar-th-125') {
        hasBarTh125 = true;
      }
      if (isolationOperationsToCheck.has(opId)) {
        hasIsolationOperation = true;
      }
    });

    // Retourne true si bar-th-125 n'a pas été trouvé ou si les deux conditions sont remplies
    return !hasBarTh125 || (hasBarTh125 && hasIsolationOperation);
  };

  useEffect(() => {
    prepareOperationDatas();
  }, [operationsDatasToPush]);
  return (
    <div style={{ borderRadius: radiusPx }} className="grow pb-[9.375rem]">
      {installer.eligibility.length > 0 ? (
        <InputSearch
          borderRadius={radiusPx}
          backgroundColor={
            isThemeDark
              ? dark1
              : location.search.includes('?integration=iframe')
              ? backgroundQuaternaryLightMode
              : 'white'
          }
          backgroundColorIconSearch={
            isThemeDark
              ? dark4
              : location.search.includes('?integration=iframe')
              ? white
              : backgroundQuaternaryLightMode
          }
          color={isThemeDark ? 'white' : dark1}
          addClass={` ${
            incompatiblesIsActive ? 'mt-[1.25rem]' : 'mt-[1.5rem]'
          }`}
          placeholder="Chercher une opération"
          onSearch={handleSearchChange}
          dynamic
          dataTestId="search-operation"
        />
      ) : (
        !isLoading && (
          <WarningNotification
            borderRadius={radiusPx}
            addClass="mt-[1.25rem]"
            color="white"
            backgroundColor="red"
            warningText="Merci de vérfier la validité de votre contrat auprès de Drapo."
          />
        )
      )}
      {isLoading ? (
        <LoaderOperationList />
      ) : (
        <div style={{ borderRadius: radiusPx }}>
          {filteredDataWithPrime.length > 0 ? (
            <div
              style={{
                borderRadius: radiusPx,
                border:
                  location.search.includes('?integration=iframe') &&
                  !isThemeDark
                    ? '1px solid  #D2D2D2'
                    : '',
              }}
              className="dark:bg-dark-2 bg-white p-[1.5rem] rounded-default mt-[1.5rem]"
            >
              {!barTh125IsEligible(operationsDatasToPush) && (
                <ModalEligibilityVMC
                  operationName={
                    data.find(
                      (item) => item.slug.toLowerCase() === 'bar-th-125'
                    )?.name ?? ''
                  }
                  operationCode="BAR-TH-125"
                  relatedOperations={barTh125RelatedOperations()}
                />
              )}

              {filteredDataWithPrime.map((item: IOperation, index) => {
                return (
                  <CheckCard
                    key={v4()}
                    borderRadius={radiusPx}
                    colorChecked={
                      isIncompatible(item) && !checkIfInArray(item)
                        ? white
                        : colorTextButton
                    }
                    colorNotChecked={
                      isThemeDark
                        ? white
                        : isIncompatible(item) && !checkIfInArray(item)
                        ? white
                        : dark1
                    }
                    addClass={`${
                      index === filteredDataWithPrime.length - 1
                        ? 'mt-0'
                        : 'mb-[1rem]'
                    }`}
                    label={item.name}
                    onClick={() => onClickChange(item)}
                    urlImg={renderImg(item)}
                    dataTestId={item.slug}
                    backgroundColorChecked={colorPrimary}
                    backgroundColorNotChecked={
                      isThemeDark ? dark1 : convertHexToRGBA(colorPrimary, 0.1)
                    }
                    colorHover={colorTextButton}
                    checked={checkIfInArray(item)}
                    disabled={isIncompatible(item) && !checkIfInArray(item)}
                    disabledText="Incompatible avec les opérations sélectionnées"
                    widthCheckbox={isMobile ? '1rem' : '1.5rem'}
                  />
                );
              })}
            </div>
          ) : (
            <div className="mt-[.75rem] text-white opacity-40">
              Désolé, aucune opération ne correspond à votre recherche.
            </div>
          )}
          <div
            style={{
              width: buttonWidth || '36rem',
              backgroundColor: isThemeDark
                ? darkVariant1
                : location.search.includes('?integration=iframe')
                ? white
                : backgroundBodyLightMode,
            }}
            className="lg:pb-[3.125rem] py-[1.25rem] fixed bottom-0 w-full"
          >
            <Button
              borderRadius={radiusPx}
              disabled={
                !(operationsDatasToPush.length > 0) ||
                !barTh125IsEligible(operationsDatasToPush)
              }
              buttonArrowSmall
              label="Suivant"
              color={colorTextButton}
              backgroundColor={colorPrimary}
              arrow
              onClick={onClickNext}
              dataTestId="next"
              addClass="!w-full !px-[1rem]"
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default ChooseMultipleOperation;
