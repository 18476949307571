/* eslint-disable react/jsx-props-no-spreading */
import Dropzone from 'react-dropzone';
import { add, add_black } from '@assets/images/images';
import { Dispatch, SetStateAction, useContext } from 'react';
import { updateFileMyProfile } from '@dashboard/apiRequests/dashboardRequests';
import { IMyProfilDatas } from '@dashboard/utils/dashboardTypes';
import { MyProfileContext } from '@dashboard/utils/context/myProfileContext';
import { InstallerContext } from '@auth/utils/authContext';
import { convertHexToRGBA } from '@utils/utils';

interface IProps {
  titleCard: string;
  setDatasMyProfile: Dispatch<SetStateAction<IMyProfilDatas>>;
}

function UploadNewDocument({
  titleCard,
  setDatasMyProfile,
}: IProps): JSX.Element {
  const { updateIsUpdateLoading } = useContext(MyProfileContext);
  const { radiusPx, isThemeDark } = useContext(InstallerContext);
  const { updateDocumentUrlActive } = useContext(MyProfileContext);

  const sendNewDocument = (file: File) => {
    const field =
      titleCard === 'Contrats sous-traitants'
        ? 'contrats_sous_traitance'
        : 'rge';
    updateFileMyProfile(
      field,
      file,
      setDatasMyProfile,
      updateIsUpdateLoading,
      updateDocumentUrlActive
    );
  };

  const radius = radiusPx === '0px' ? '0px' : '8px';
  return (
    <Dropzone
      accept={{
        'application/pdf': ['.pdf'],
      }}
      onDrop={(acceptedFiles) => {
        sendNewDocument(acceptedFiles[0]);
      }}
    >
      {({ getRootProps, getInputProps }) => (
        <section>
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            <button
              style={{
                borderRadius: radius,
                border: isThemeDark
                  ? '1px dashed'
                  : `1px dashed ${convertHexToRGBA('#202020', 0.5)}`,
              }}
              type="button"
              className="flex sb-text-rem-caption1 justify-between items-center w-full py-[.625rem] px-[1.25rem] dark:bg-transparent bg-white mb-[.625rem]"
            >
              <p className=" sb-text-rem-caption1 !font-medium">
                Ajouter un document
              </p>
              <img
                className="w-[1.5rem]"
                src={isThemeDark ? add : add_black}
                alt="ajouter un fichier"
              />
            </button>
          </div>
        </section>
      )}
    </Dropzone>
  );
}

export default UploadNewDocument;
