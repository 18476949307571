/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
import { useContext, useState, useEffect, useRef } from 'react';
import { v4 } from 'uuid';

import { InstallerContext } from '@auth/utils/authContext';
import { BulkImportContext } from '@bulkImport/utils/bulkImportContext';
import { IFolder } from '@bulkImport/utils/bulkImportTypes';
import {
  borderLightMode,
  colorTextLightMode,
  dark4,
  white,
} from '@assets/colors';

function ListFolders(): JSX.Element {
  const { colorPrimary, radiusPx, isThemeDark, colorTextButton } =
    useContext(InstallerContext);
  const {
    fileDetail,
    updateFolderActiveReference,
    folderActiveReference,
    updateFolderActive,
    updateListFilesImportedIsClicked,
    listFilesImportedIsClicked,
    folderList,
    updateDocumentsGlobals,
    updateOperations,
  } = useContext(BulkImportContext);

  // GERE LE BORDER AUTOURS DU BOUTON LORSQUE L'ON CLIQUE DESSUS
  const [borderActiv, setBorderActiv] = useState('1px solid white');

  const folderActiveRef = useRef(null);
  const listFoldersRef = useRef(null);

  const borderButton = (folder: string) => {
    let border = '';
    if (folder === folderActiveReference) {
      border = borderActiv;
    }
    if (folder !== folderActiveReference) {
      border = '';
    }
    return border;
  };

  const scrollBarColor = isThemeDark ? '' : 'scroll_light_mode';

  const onSelectFolder = (folder: IFolder) => {
    // MET A JOUR LA REFERENCE ACTIVE DU FOLDER DONT LA LISTE DES DOCUMENTS S'AFFICHERA SUR LA DROITE
    updateFolderActiveReference(folder.datas.folder);
    // MET A JOUR LE FOLDER ACTIF DONT LA LISTE DES DOCUMENTS S'AFFICHERA SUR LA DROITE
    updateFolderActive(folder);
    // MET A JOUR LES FICHIERS DU FOLDER ACTIF
    updateDocumentsGlobals(folder.datas.files);
    updateOperations(folder.datas.operations);
    // MET A JOUR LE BORDER DES FICHIER SUR LA GAUCHE
    updateListFilesImportedIsClicked(false);
    setBorderActiv('1px solid white');
  };

  const autoFocusActiveFolder = () => {
    for (let i = 0; i < folderList.length; i += 1) {
      if (folderList[i].datas.folder === folderActiveReference) {
        onSelectFolder(folderList[i]);
      }
    }
  };

  useEffect(() => {
    // LORSQUE L'ON CLIQUE SUR UN FICHIER ET NON UN FOLDER LE BORDER SE RETIRE
    if (listFilesImportedIsClicked) {
      setBorderActiv('');
    }
  }, [listFilesImportedIsClicked]);

  useEffect(() => {
    if (listFoldersRef.current && folderActiveRef.current) {
      autoFocusActiveFolder();
      const listFoldersCurrent: HTMLDivElement = listFoldersRef.current;
      const folderActiveCurrent: HTMLDivElement = folderActiveRef.current;
      listFoldersCurrent.scroll({
        top: folderActiveCurrent.offsetTop,
        left: 0,
        behavior: 'smooth',
      });
    }
  }, [folderList]);

  const radius = radiusPx === '0px' ? '0px' : '8px';

  const renderFolderButton = (folder: IFolder) => {
    return (
      <button
        onClick={() => {
          onSelectFolder(folder);
        }}
        type="button"
        style={{
          borderRadius: radius,
          border:
            folder.completed === false
              ? borderButton(folder.datas.folder)
              : `1px dashed ${borderLightMode}`,
          color:
            folder.completed === false
              ? colorTextButton
              : isThemeDark
              ? 'white'
              : colorTextLightMode,
          backgroundColor:
            folder.completed === false
              ? colorPrimary
              : isThemeDark
              ? dark4
              : white,
        }}
        className="w-full mt-[.5rem] px-[1.875rem] py-[.75rem] w-11/12 opacity-100 hover:opacity-80 dark:text-white text-colorTextLightMode"
      >
        <p className="sb-text-rem-body2">{folder.title}</p>
      </button>
    );
  };

  return (
    <div
      className={`lg:w-4/12 ${scrollBarColor} overflow-y-scroll max-h-full relative`}
      ref={listFoldersRef}
    >
      <div className="ml-[1rem] px-[1rem] py-[.75rem]">
        {/* STATUS !== 'Done' => N'A PAS ENCORE ETE ENVOYE A AIRTABLE */}
        {fileDetail?.status !== 'Done' && fileDetail !== undefined && (
          <>
            <div className="flex justify-between items-center">
              <p className="sb-text-rem-body2 dark:text-white text-colorTextLightMode">
                Dossier(s) avec fichiers manquants
              </p>
              <p className="sb-text-rem-body2">
                {folderList.filter((folder) => !folder.completed).length}
              </p>
            </div>
            <div className="my-[.75rem]">
              {folderList.map((folder: IFolder) => {
                if (!folder.completed) {
                  if (folder.datas.folder === folderActiveReference) {
                    return (
                      <div key={v4()} ref={folderActiveRef}>
                        {renderFolderButton(folder)}
                      </div>
                    );
                  }
                  return <div key={v4()}>{renderFolderButton(folder)}</div>;
                }
                return false;
              })}
            </div>
            <div className="flex justify-between items-center">
              <p className="sb-text-rem-body2 dark:text-white text-colorTextLightMode">
                Dossier(s) complet(s)
              </p>
              <p className="sb-text-rem-body2">
                {folderList.filter((folder) => folder.completed).length}
              </p>
            </div>
            <div className="mb-[.75rem]">
              {folderList.map((folder: IFolder) => {
                if (folder.completed) {
                  if (folder.datas.folder === folderActiveReference) {
                    return (
                      <div key={v4()} ref={folderActiveRef}>
                        {renderFolderButton(folder)}
                      </div>
                    );
                  }
                  return <div key={v4()}>{renderFolderButton(folder)}</div>;
                }
                return false;
              })}
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default ListFolders;
