import { InstallerContext } from '@auth/utils/authContext';
import { useContext } from 'react';

function LoaderCardlink(): JSX.Element {
  const { radiusPx } = useContext(InstallerContext);

  const array = [1, 2, 3];
  return (
    <div
      style={{ borderRadius: radiusPx }}
      className="w-full dark:bg-dark-1 bg-lightMode-dark-1 flex-row mt-[1.5rem]  p-[1.25rem]"
    >
      {array.map((arr) => {
        return (
          <div
            style={{ borderRadius: radiusPx }}
            key={arr}
            className={`flex items-center dark:bg-dark-1 bg-lightMode-dark-1 p-[.5rem] mb-[.75rem] w-full flex-row items-center `}
          >
            <div
              style={{ borderRadius: radiusPx }}
              className="w-[5.938rem] h-[5.938rem] bg-dark-light-1 animate__flash animate__animated animate__slower"
            />
            <div className="w-5/12 space-y-[.75rem] ml-[1.5rem] rounded-[6px] ">
              <div className="w-10/12 h-[1rem] bg-dark-light-1 h-[1.5rem] rounded-md animate__flash animate__animated animate__slower " />
              <div className="w-5/12 h-[1rem] bg-dark-light-1 h-[.75] rounded-md animate__flash animate__animated animate__slower" />
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default LoaderCardlink;
