import { IKeyValueStringType } from '@simulator/utils/simulatorTypes';

export const columnsKeys: IKeyValueStringType = {
  age_du_logement: 'Age du logement',
  civilite: 'Civilité',
  code_postal_des_travaux: 'Code Postal des travaux',
  code_postal_proprietaire_bailleur: 'Code Postal propriétaire bailleur',
  date_demande_subvention_mpr_ddmmaaaa: 'Date demande subvention MPR',
  deleguer_prime_mpr: 'Déléguer prime MPR',
  demandeur_cee: 'Demandeur CEE',
  depose: 'Dépose',
  efficacite_energetique: 'Éfficacité énergétique',
  email_du_client: 'Email du client',
  email_sous_traitant: 'Email Sous-Traitant',
  habitation: 'Habitation',
  identifiant_mpr: 'Identifiant MPR',
  indivision: 'indivision',
  menage: 'Ménage',
  montant_cee: 'Montant CEE',
  montant_des_travaux_eur: 'Montant des travaux',
  mot_de_passe_mpr: 'Mot de Passe MPR',
  nom_du_client: 'Nom du client',
  nom_prenom_2: 'Nom prénom 2',
  nom_prenom_3: 'Nom prénom 3',
  nom_sous_traitant: 'Nom Sous-Traitant',
  nombre_de_personnes: 'Nombre de personnes',
  numero_de_dossier_mpr: 'Numéro de dossier MPR',
  numero_fiscal_1: 'Numéro fiscal 1',
  numero_fiscal_2: 'Numéro fiscal 2',
  numero_fiscal_3: 'Numéro fiscal 3',
  operation: 'Opération',
  periode_sub_auto: 'Période sub',
  prenom_du_client: 'Prénom du client',
  prenom_sous_traitant: 'Prénom Sous-Traitant',
  prime: 'Prime',
  raison_sociale_sous_traitant: 'Raison sociale Sous-Traitant',
  reference_avis_1: 'Référence avis 1',
  reference_avis_2: 'Référence avis 2',
  reference_avis_3: 'Référence avis 3',
  remplacement: 'Remplacement',
  rue_des_travaux: 'Rue des travaux',
  rue_proprietaire_bailleur: 'Rue propriétaire bailleur',
  siret_sous_traitant: 'Siret Sous-Traitant',
  situation: 'Situation',
  surface_du_logement_m2: 'Surface du logement',
  systeme_de_chauffage: 'Système de chauffage',
  telephone_du_client: 'Téléphone du client',
  type: 'Type',
  ville_des_travaux: 'Ville des travaux',
  ville_proprietaire_bailleur: 'Ville propriétaire bailleur',
};

export const getObjectKeyByValue = (
  object: IKeyValueStringType,
  value: string
) => {
  return Object.keys(object).find((key) => object[key] === value) as string;
};
