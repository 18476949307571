import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import { v4 } from 'uuid';
import {
  Input,
  CheckCard,
  FileMultiUpload,
  ButtonSb,
} from 'storybook-design-system-drapo';
import Loader from '@components/loaders/Loader';
import { InstallerContext } from '@auth/utils/authContext';
import { addInfosCreateFolder } from '@folderMonitoring/apiRequest/folderMonitoringRequest';
import { toast } from 'react-toastify';
import {
  backgroundQuaternaryLightMode,
  colorTextLightMode,
  dark1,
  dark5,
  white,
} from '@assets/colors';
import { FolderMonitoringContext } from '../../utils/context/folderMonitoringContext';
import { convertHexToRGBA } from '../../../../utils/utils';

interface IMprCreateFolderProps {
  setDetailsActive: Dispatch<SetStateAction<boolean>>;
}

function MprCreateFolder({
  setDetailsActive,
}: IMprCreateFolderProps): JSX.Element {
  const { folderDatas, updateFolderDatas, updateStepIsUpdate } = useContext(
    FolderMonitoringContext
  );

  const { folderActif } = useContext(FolderMonitoringContext);

  const { radiusPx, colorPrimary, isThemeDark, colorTextButton, installer } =
    useContext(InstallerContext);

  const radius = radiusPx === '0px' ? '0px' : '8px';

  const [isCheckFile, setIsCheckFile] = useState<boolean>(false);
  const [isCheckRepresent, setIsCheckRepresent] = useState<boolean>(false);

  const [valueLogin, setValueLogin] = useState<string>('');
  const [valuePassword, setValuePassword] = useState<string>('');
  const [valueDate, setValueDate] = useState<string>('jj/mm/aaaa');

  const [isFileUploading, setIsFileUploading] = useState<boolean>(false);
  const [fileName, setFileName] = useState<string>('');
  const [fileToSend, setFileToSend] = useState<File>();
  const [filesUploaded, setFilesUploaded] = useState<any[]>([]);

  const [isOk, setIsOk] = useState<boolean>(false);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [errorDate, setErrorDate] = useState<string>('');
  const [errorDateStyle, setErrorDateStyle] = useState<string>('');
  const [isSend, setIsSend] = useState<boolean>(false);

  const [isSameFile, setIsSameFile] = useState<boolean>(false);

  const subtitle = `J’atteste que la prime figurant sur la notification d’octroi est la même que celle calculée par Drapo pour ce dossier (soit ${folderDatas.mpr_total} €)`;

  const checkDate = () => {
    const todayDate = new Date();
    const date = new Date(valueDate);

    const plus5years = () => {
      const result = new Date(todayDate);
      result.setFullYear(result.getFullYear() + 5);
      return result;
    };
    const minus5years = () => {
      const result = new Date(todayDate);
      result.setFullYear(result.getFullYear() - 5);
      return result;
    };

    if (date <= plus5years() && date >= minus5years()) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (
      (valueLogin && valuePassword && !isCheckFile && !isCheckRepresent) ||
      (isCheckRepresent && !isCheckFile)
    ) {
      setIsOk(true);
    } else if (
      (valueLogin &&
        valuePassword &&
        isCheckFile &&
        !isCheckRepresent &&
        valueDate !== 'jj/mm/aaaa' &&
        fileToSend) ||
      (isCheckFile &&
        isCheckRepresent &&
        valueDate !== 'jj/mm/aaaa' &&
        fileToSend)
    ) {
      if (checkDate()) {
        setIsOk(true);
      } else {
        setErrorDateStyle('border border-red-500');
        setErrorDate("La date de dépôt n'est pas valide");
        setIsOk(false);
      }
    } else {
      setIsOk(false);
    }
  }, [
    valueLogin,
    valuePassword,
    valueDate,
    fileToSend,
    isCheckFile,
    isCheckRepresent,
  ]);

  const onUploadFile = (file: File) => {
    setIsFileUploading(false);
    setFileName(file.name);
    setFileToSend(file);
    setFilesUploaded([
      {
        name: file.name,
        path: URL.createObjectURL(file),
      },
    ]);
  };

  const onDeleteFileUpload = () => {
    setFilesUploaded([]);
  };

  const handleClick = () => {
    setIsLoading(true);
    setIsSend(false);
    addInfosCreateFolder(
      isCheckRepresent,
      setDetailsActive,
      updateStepIsUpdate,
      folderDatas,
      updateFolderDatas,
      setIsLoading,
      setIsSend,
      setError,
      setErrorDateStyle,
      folderActif[0].référence_dossier,
      valueLogin,
      valuePassword,
      isCheckFile,
      valueDate,
      fileToSend
    );
  };

  useEffect(() => {
    if (isSameFile) {
      toast(
        '⛔️ Un fichier avec le même nom a déjà été renseigné pour ce document'
      );
    }
  }, [isSameFile]);

  return (
    <div className="relative pt-[.75rem] flex flex-col space-y-[.75rem] px-[1rem]">
      {!isCheckRepresent && (
        <div className="flex flex-col space-y-[.75rem] ">
          <div
            style={{ color: colorPrimary }}
            className="text-center font-[500] py-[.625rem]"
          >
            <a
              href="https://www.maprimerenov.gouv.fr/"
              target="_blank"
              rel="noreferrer"
            >
              Aller sur MaPrimeRénov&apos; pour créer le compte de votre client
            </a>
          </div>
          <Input
            backgroundColor={
              isThemeDark ? dark1 : backgroundQuaternaryLightMode
            }
            color={isThemeDark ? white : dark1}
            label="Identifiant MPR"
            onChange={(e) => {
              setValueLogin(e.target.value);
            }}
            value={valueLogin}
            inputType="text"
            dataTestId="login"
            addClass="text-[.75rem]"
            inputSmall
            borderRadius={radiusPx}
          />
          <Input
            backgroundColor={
              isThemeDark ? dark1 : backgroundQuaternaryLightMode
            }
            color={isThemeDark ? white : dark1}
            label="Mot de passe MPR"
            onChange={(e) => {
              setValuePassword(e.target.value);
            }}
            value={valuePassword}
            inputType="text"
            dataTestId="password"
            addClass="text-[.75rem]"
            inputSmall
            borderRadius={radiusPx}
          />
        </div>
      )}
      <CheckCard
        colorChecked={colorTextButton}
        colorHover={colorTextButton}
        colorNotChecked={isThemeDark ? white : colorTextLightMode}
        backgroundColorChecked={colorPrimary}
        backgroundColorNotChecked={
          isThemeDark ? dark1 : backgroundQuaternaryLightMode
        }
        dataTestId="checkcard"
        label="Le bénéficiaire a utilisé FranceConnect"
        borderRadius={radiusPx}
        onClick={() => setIsCheckRepresent(!isCheckRepresent)}
        addClass="!h-[5rem]"
      />
      {isCheckRepresent && (
        <>
          <div className="leading-[1.5rem] text-[.75rem] text-dark-1 dark:text-white">
            Dans ce cas, afin de poursuivre l&apos;instruction de votre dossier,
            le bénéficiaire doit se connecter sur son compte MaPrimeRénov&apos;
            et désigner Drapo comme mandataire avec le Numéro d’immatriculation
            mandataire :
          </div>
          <div className="text-center">
            <b>100DRAPO2_DLPF</b>
          </div>
        </>
      )}
      <CheckCard
        backgroundColorChecked={colorPrimary}
        backgroundColorNotChecked={
          isThemeDark ? dark1 : backgroundQuaternaryLightMode
        }
        colorChecked={colorTextButton}
        colorHover={colorTextButton}
        colorNotChecked={isThemeDark ? white : colorTextLightMode}
        dataTestId="checkcard"
        label="J’ai déjà déposé le dossier"
        subtitle={subtitle}
        borderRadius={radiusPx}
        onClick={() => setIsCheckFile(!isCheckFile)}
      />
      {isCheckFile && (
        <div className="flex flex-col space-y-[.75rem]">
          <p className="text-red-500">{errorDate}</p>
          <Input
            backgroundColor={
              isThemeDark ? dark1 : backgroundQuaternaryLightMode
            }
            color={isThemeDark ? white : colorTextLightMode}
            label="Date de dépôt"
            onChange={(e) => {
              setValueDate(e.target.value);
              setErrorDateStyle('');
              setErrorDate('');
            }}
            value={valueDate}
            inputType="date"
            dataTestId="depot"
            addClass={`text-[.75rem]/[.92] ${errorDateStyle}`}
            inputSmall
            borderRadius={radiusPx}
          />
          <FileMultiUpload
            key={v4()}
            fileName="Notification d’octroi"
            fileNameUpload={fileName}
            required
            isFileUploading={isFileUploading}
            borderRadiusContainer={radiusPx}
            borderRadius={radiusPx}
            backgroundColorFileLoaded={colorPrimary}
            backgroundUploadedColor={colorPrimary}
            containerBackgroundColor={
              isThemeDark ? dark1 : convertHexToRGBA(colorPrimary, 0.1)
            }
            color={isThemeDark ? white : dark1}
            backgroundColorAddFile={isThemeDark ? dark5 : white}
            backgroundColorComponent={
              isThemeDark ? dark1 : convertHexToRGBA(colorPrimary, 0.1)
            }
            onDelete={onDeleteFileUpload}
            onChangeFileUpload={(files) => onUploadFile(files)}
            filesUploaded={filesUploaded}
            setActiveToast={setIsSameFile}
          />
        </div>
      )}
      <ButtonSb
        disabled={!isOk || installer.desactiver_boutons}
        onClick={() => handleClick()}
        label="Soumettre"
        color={colorTextButton}
        backgroundColor={colorPrimary}
        dataTestId="soumettre"
        addClass="!h-[2.5rem]"
        borderRadius={radius}
      />
      {isLoading && (
        <div className="mt-[.875rem]">
          <Loader />
        </div>
      )}
      {!isLoading && isSend && error && (
        <div className="mt-[.875rem] text-red-1 text-[.875rem]">{error}</div>
      )}
    </div>
  );
}

export default MprCreateFolder;
