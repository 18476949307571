import { InstallerContext } from '@auth/utils/authContext';
import { useContext } from 'react';
import { dark2 } from '@assets/colors';
import { convertHexToRGBA } from '@utils/utils';

interface NextStepsContentProps {
  title: string;
  content: JSX.Element;
  isClick: boolean;
}

function NextStepsContent({
  title,
  content,
  isClick,
}: NextStepsContentProps): JSX.Element {
  const { radiusPx, isThemeDark, colorPrimary } = useContext(InstallerContext);

  const titleTransformed =
    title === 'Instruction par MPR' ? `${title} (si MPR uniquement)` : title;

  return (
    <div
      style={{
        borderRadius: radiusPx,
        backgroundColor: isThemeDark
          ? dark2
          : convertHexToRGBA(colorPrimary, 0.1),
      }}
      className={` dark:bg-dark-3 bg-lightMode-dark-3 px-[1.75rem] py-[2rem] animation-content-dropDown ${
        isClick && 'animation-content'
      }`}
    >
      <div className="font-medium dark:text-white text-colorTextLightMode">
        {titleTransformed}
      </div>
      <div className="pt-[1.375rem] dark:text-white text-colorTextLightMode">
        {content}
      </div>
    </div>
  );
}

export default NextStepsContent;
