import { useContext, useEffect, useState } from 'react';
import { v4 } from 'uuid';
import marker from '@assets/images/marker.svg';
import { MapView } from 'storybook-design-system-drapo';
import { IRecapGeneralType, ILatLng } from '@simulator/utils/simulatorTypes';
import { getParcelMap } from '@simulator/apiRequests/SimulatorRequests';
import { InstallerContext } from '@auth/utils/authContext';
import { latLngFrance } from '@utils/utils';
import { FolderMonitoringContext } from '../utils/context/folderMonitoringContext';

function RelativesInfos(): JSX.Element {
  const { folderDatas } = useContext(FolderMonitoringContext);
  const { radiusPx, colorPrimary } = useContext(InstallerContext);

  const radius = radiusPx === '0px' ? '0px' : '16px';

  const [depose, setDepose] = useState<string[]>([]);
  const [latLngData, setLatLngData] = useState<ILatLng>(latLngFrance);
  const [coordinates, setCoordinates] = useState<ILatLng[]>([latLngFrance]);
  const [markerMap, setMarkerMap] = useState<boolean>(true);

  const recapInfos: IRecapGeneralType[] = [
    {
      title: "Type d'habitation",
      content: folderDatas.operations[0].type_logement,
    },
    {
      title: 'Dépose',
      content: '',
      tabContent: depose,
    },
    {
      title: 'Âge du logement',
      content: folderDatas.operations[0].age_logement,
    },
    {
      title: 'Superficie',
      content: `${folderDatas.operations[0].surface} m2`,
    },
    {
      title: 'Personne(s) dans le ménage',
      content: `${
        folderDatas.operations[0].nombre_personnes_dans_ménage
      } Personne${
        parseFloat(folderDatas.operations[0].nombre_personnes_dans_ménage) > 1
          ? 's'
          : ''
      }`,
    },
    {
      title: 'Revenus du ménage',
      content: folderDatas.operations[0].catégorie_ménage,
    },
    {
      title: 'Typologie client',
      content: folderDatas.operations[0].type_propriétaire,
    },
    {
      title: 'Nom',
      content: folderDatas.operations[0].nom_client,
    },
    { title: 'Prénom', content: folderDatas.operations[0].prénom_client },
    {
      title: 'Email',
      content: folderDatas.operations[0].adresse_email_client,
    },
    {
      title: 'Téléphone',
      content: folderDatas.operations[0].téléphone_client,
    },
    {
      title: 'Adresse',
      content: folderDatas.operations[0].adresse_travaux,
    },
  ];

  useEffect(() => {
    // filter (): On filtre les opérations pour ne garder que celles qui ont au moins une dépose de matériel
    // map (): on créé un tableau des matériaux déposés
    const filteredMaterials = folderDatas.operations
      .filter(
        (operation) =>
          operation.matériel_déposé !== null && operation.matériel_déposé !== ''
      )
      .map((operation) => operation.matériel_déposé);
    setDepose(
      filteredMaterials.length > 0 ? filteredMaterials : ['Pas de dépose']
    );

    const latLngArray =
      folderDatas.operations[0].coordonnées_géographiques_nationales !== null
        ? folderDatas.operations[0].coordonnées_géographiques_nationales.split(
            ','
          )
        : [''];

    const latLngObject = {
      lat: parseFloat(latLngArray[0]),
      lng: parseFloat(latLngArray[1]),
    };

    getParcelMap(latLngObject, setCoordinates, setMarkerMap);
    setLatLngData(latLngObject);
  }, []);

  return (
    <div className="w-full h-full px-[1.375rem] flex items-start space-x-[2rem] text-dark-1 dark:text-white">
      <div className="lg:w-7/12 w-full flex flex-col space-y-[2rem]">
        <div
          className="dark:bg-dark-5 bg-lightMode-dark-5 px-[2rem] py-[1.5rem]"
          style={{ borderRadius: radius }}
        >
          <div className="font-[500] text-[1.125rem]">Informations</div>
          <div className="pt-[1rem]">
            {recapInfos.map((info: IRecapGeneralType, index: number) => (
              <div
                key={v4()}
                className={`text-[.875rem] dark:text-white/[.92] text-dark-1 flex items-center justify-between border-b-[2px] dark:border-b-dark-light-1/[.20] ${
                  index !== 0 ? 'py-[.625rem]' : 'pb-[.625rem]'
                }`}
              >
                <div className="font-[500]">{info.title}</div>
                {info.title === 'Dépose' && info.tabContent ? (
                  info.tabContent.map((dep: string) => (
                    <div key={v4()} className="text-right">
                      {dep}
                    </div>
                  ))
                ) : (
                  <div className="text-right">{info.content}</div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
      <div
        className="hidden lg:block w-5/12 h-full"
        style={{ borderRadius: radius }}
      >
        <MapView
          colorPolygon={colorPrimary}
          marker={markerMap}
          iconMarker={marker}
          zoomLevel={18}
          coordinates={coordinates}
          addressCenter={latLngData}
        />
      </div>
    </div>
  );
}

export default RelativesInfos;
