import { CloseIcon, SearchIcon } from '@assets/svgComponents/svgComponents';
import { InstallerContext } from '@auth/utils/authContext';
import { searchFolder } from '@dashboard/apiRequests/dashboardRequests';
import { IDataSearchFolder } from '@dashboard/utils/dashboardTypes';
import { useContext, Dispatch, SetStateAction, useState } from 'react';
import { dark5 } from '@assets/colors';
import { convertHexToRGBA } from '@utils/utils';

interface IProps {
  isLookingFor: boolean;
  setIsLookingFor: Dispatch<SetStateAction<boolean>>;
  searchValue: string;
  setSearchValue: Dispatch<SetStateAction<string>>;
  totalFoldersInList: number;
  setListFolders: Dispatch<SetStateAction<IDataSearchFolder[] | undefined>>;
  setTotalFoldersInList: Dispatch<SetStateAction<number>>;
  listFolders: IDataSearchFolder[] | undefined;
  setFoldersIsLoaded: Dispatch<SetStateAction<boolean>>;
  setNumberPage: Dispatch<SetStateAction<number>>;
}

function InputSearchFolder({
  isLookingFor,
  setIsLookingFor,
  searchValue,
  setSearchValue,
  totalFoldersInList,
  setListFolders,
  setTotalFoldersInList,
  listFolders,
  setFoldersIsLoaded,
  setNumberPage,
}: IProps): JSX.Element {
  const { isThemeDark, radiusPx, colorPrimary } = useContext(InstallerContext);

  const [timeOut, setTimeOut] = useState<NodeJS.Timeout | null>(null);

  const displayDefaultFolders = () => {
    if (searchValue.length === 0) {
      searchFolder(
        1,
        'default',
        setListFolders,
        setTotalFoldersInList,
        listFolders,
        setFoldersIsLoaded,
        setNumberPage
      );
    }
    setIsLookingFor(true);
  };

  const changeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    let timeout: any = null;
    if (timeOut) {
      clearTimeout(timeOut);
      setTimeOut(null);
    }
    if (e.target.value.length !== 0) {
      timeout = setTimeout(() => {
        searchFolder(
          1,
          e.target.value,
          setListFolders,
          setTotalFoldersInList,
          listFolders,
          setFoldersIsLoaded,
          setNumberPage
        );
      }, 350);
      setTimeOut(timeout);
    }
    setIsLookingFor(true);
    setSearchValue(e.target.value);
  };

  return (
    <div
      style={{
        borderTopLeftRadius: radiusPx,
        borderTopRightRadius: radiusPx,
        borderBottomLeftRadius:
          (radiusPx === '16px' && !isLookingFor) ||
          (radiusPx === '16px' && totalFoldersInList === 0)
            ? radiusPx
            : '0px',
        borderBottomRightRadius:
          (radiusPx === '16px' && !isLookingFor) ||
          (radiusPx === '16px' && totalFoldersInList === 0)
            ? radiusPx
            : '0px',
      }}
      className="dark:bg-dark-1 text-[.875rem] flex items-center justify-between w-full bg-white dark:text-white text-colorTextLightMode p-[.6rem] pl-[1rem] dark:border-none border border-borderLightMode border-[1px]"
    >
      <div className={`flex ${!isLookingFor ? 'w-full' : 'w-full'}`}>
        <input
          onClick={() => displayDefaultFolders()}
          value={searchValue}
          onChange={(e) => {
            changeValue(e);
          }}
          type="text"
          placeholder="Rechercher un dossier"
          className="dark:bg-dark-1 bg-lightMode-dark-1 w-full"
        />
      </div>
      <div className="flex items-center justify-between ">
        {searchValue !== '' && (
          <p className="mr-[.5rem] opacity-60 whitespace-nowrap">
            {totalFoldersInList} dossier{totalFoldersInList > 1 && 's'}
          </p>
        )}

        {isLookingFor ? (
          <button
            className="p-[1rem] hover:opacity-80"
            type="button"
            onClick={() => {
              setIsLookingFor(false);
              setSearchValue('');
            }}
          >
            <CloseIcon
              width="1rem"
              height="1rem"
              fill={isThemeDark ? 'white' : 'black'}
            />
          </button>
        ) : (
          <div
            style={{
              borderRadius: radiusPx,
              backgroundColor: isThemeDark
                ? dark5
                : convertHexToRGBA(colorPrimary, 0.1),
            }}
            className="p-[.8rem]"
          >
            <SearchIcon
              fill={isThemeDark ? 'white' : colorPrimary}
              width="1.5rem"
              height="1.5rem"
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default InputSearchFolder;
