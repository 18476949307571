import { InstallerContext } from '@auth/utils/authContext';
import { useContext } from 'react';

function LoaderFolderLeft(): JSX.Element {
  const { radiusPx } = useContext(InstallerContext);

  return (
    <div className="w-full h-screen ">
      <div
        style={{ borderRadius: radiusPx }}
        className="ml-[1.5rem] w-11/12 dark:bg-dark-4 bg-lightMode-dark-4 h-[8.125] opacity-60 animate__infinite  animate__flash animate__animated animate__slower"
      />
      <div
        style={{ borderRadius: radiusPx }}
        className="w-11/12 h-[37.5rem] dark:bg-dark-4 bg-lightMode-dark-4 mx-[1.5rem] opacity-60 mt-[1.875rem] animate__infinite  animate__flash animate__animated animate__slower"
      />
      <div
        style={{ borderRadius: radiusPx }}
        className="w-11/12 h-[18.75rem] dark:bg-dark-4 bg-lightMode-dark-4 mx-[1.5rem] opacity-60 mt-[1.875rem] animate__infinite  animate__flash animate__animated animate__slower"
      />
      <div
        style={{ borderRadius: radiusPx }}
        className="w-11/12 h-[6.25rem] dark:bg-dark-4 bg-lightMode-dark-4 mx-[1.5rem] opacity-60 mt-[1.875rem] animate__infinite  animate__flash animate__animated animate__slower"
      />
      <div
        style={{ borderRadius: radiusPx }}
        className="w-11/12 h-[18.75rem] dark:bg-dark-4 bg-lightMode-dark-4 mx-[1.5rem] opacity-60 mt-[1.875rem] animate__infinite  animate__flash animate__animated animate__slower"
      />
    </div>
  );
}
export default LoaderFolderLeft;
