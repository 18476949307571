import { FolderContext } from '@folder/utils/FoldersContext';
import { useContext, useEffect, useState } from 'react';
import {
  custominfo_dark_folder,
  custominfo_folder,
} from '@assets/images/images';
import EmailRecap from './EmailRecap';
import { RenderRightPart } from './RenderRightPart';
import { InstallerContext } from '../../../auth/utils/authContext';

interface RightPartCustomerInformationProps {
  divRightWidth: number | undefined;
  divActive: string | undefined;
}

function RightPartCustomerInformation({
  divRightWidth,
  divActive,
}: RightPartCustomerInformationProps): JSX.Element {
  const { housingOwner } = useContext(FolderContext);

  const { folderDatas, workAddress, workCoordinates } =
    useContext(FolderContext);

  const [emailRecapIsActive, setEmailRecapIsActive] = useState(false);
  const [infosRightPart, setInfosRightPart] = useState<JSX.Element[]>();
  const [hasCee, setHasCee] = useState<boolean>(false);

  const { radiusPx, isThemeDark } = useContext(InstallerContext);

  const helpCustomerInformation = () => {
    const client: JSX.Element = (
      <>
        Cette étape vous permet de valider les informations du client avant
        l&apos;envoi de son récapitulatif. Ce récapitulatif lui sera envoyé sous
        la forme d&apos;un email dont vous serez l&apos;expéditeur. <br />{' '}
        <br />
        Il listera le montant des aides auxquelles il peut prétendre, ainsi que
        le reste à charge qu&apos;il lui restera à vous régler. <br /> <br />
        Le cadre de contribution sera joint à l&apos;email, vous n&apos;aurez
        donc pas à le lui communiquer vous-même. <br /> <br />
        En validant ces informations, vous pourrez visualiser un aperçu de ce
        mail récapitulatif.
      </>
    );
    const housing: JSX.Element = (
      <>
        {' '}
        *Vous avez précédemment renseigné l&apos;adresse postale sur laquelle
        les travaux seront réalisé, nous avons également besoin de
        l&apos;adresse postale de vie courante de votre client pour compléter
        votre dossier.
      </>
    );

    let content: JSX.Element[] = [client];

    if (housingOwner !== '') {
      content = [client, housing];
    }

    return content;
  };

  const helpIndivision: JSX.Element[] = [
    <>
      {' '}
      Nous avons remarqué lors de la vérification des dossiers de nos
      partenaires installateurs qu’un grand nombre d’inconformités vient de
      l’absence d’une attestation d’indivision du logement.
      <br />
      <br />
      L’indivision est la situation juridique dans laquelle plusieurs personnes
      sont propriétaires d’un même bien immobilier.
      <br />
      <br />
      Pour savoir si le bien de vos clients est en indivision, il suffit de
      consulter leur taxe foncière sur lequel vous pourrez voir la mention
      PROP/INDIVIS dans le champ Débiteurs Légaux.
    </>,
  ];

  const helpTaxHousehold: JSX.Element[] = [
    <>
      {' '}
      {/* Nous allons vérifier que le ménage est bien éligible aux aides que vous
      avez précédemment calculées. <br /> <br />
      Pour cela, renseignez ci-contre, chaque foyer fiscal constituant le
      ménage. */}
      Pour chaque foyer fiscal, il y a un avis d&apos;imposition. Un couple
      marié est sur le même avis d&apos;imposition, et représente donc un seul
      foyer fiscal. <br /> <br />
      Un couple en concubinage aura deux avis d&apos;imposition, dans ce cas ce
      ménage sera constitué de deux foyers fiscaux.{' '}
      {/* Vous devez renseigner le
      numéro fiscal de l&apos;avis d’imposition de l’année n ou n-1 (c&apos;est
      à dire l&apos;avis des revenus n-1 ou n-2). Attention, pour un dossier
      MaPrimeRénov&apos;, seul l&apos;avis n sera accepté (c&apos;est à dire les
      revenus n-1). */}
    </>,
  ];

  const helpCadastreWorksAddress: JSX.Element[] = [
    <>
      Lors de la saisie de l&apos;adresse des travaux, nous n&apos;avons pas pu
      identifier exactement les références cadastrales du logement concernée par
      les travaux. <br /> <br />
      Afin de compléter votre dossier, nous avons besoin de ces informations.
      Vous pouvez utiliser la carte ci-contre afin de définir l&apos;adresse des
      travaux. Nous déduirons les références cadastrales à partir de votre
      sélection. <br /> <br />
      <a
        href={`https://cadastre.data.gouv.fr/map?style=ortho#15/${workCoordinates.latitude}/${workCoordinates.longitude}`}
        className="text-purple-1 no-underline"
        target="_blank"
        rel="noreferrer"
      >
        En cliquant sur ce lien{' '}
      </a>
      , vous pouvez identifier la parcelle correspondant à l&apos;adresse des
      travaux : <span className="text-purple-1">{workAddress}</span>
    </>,
  ];

  useEffect(() => {
    if (
      folderDatas.client &&
      folderDatas.client.lastname &&
      folderDatas.client.firstname &&
      folderDatas.client.email &&
      folderDatas.client.phone !== ''
    ) {
      setEmailRecapIsActive(true);
    }
  }, [folderDatas]);

  useEffect(() => {
    switch (divActive) {
      default:
      case 'FormCustomerInformation':
        setInfosRightPart(helpCustomerInformation());
        break;
      case 'Indivision':
        setInfosRightPart(helpIndivision);
        break;
      case 'TaxHouseholdCustomerInformation':
        setInfosRightPart(helpTaxHousehold);
        break;
      case 'CadastreWorksAddressInformation':
        setInfosRightPart(helpCadastreWorksAddress);
        break;
    }
  }, [divActive]);

  useEffect(() => {
    for (let i = 0; i < folderDatas.operations.length; i += 1) {
      if (folderDatas.operations[i].prime_cee_beneficiary) {
        setHasCee(true);
      }
    }
  }, [folderDatas.operations]);

  return (
    <div
      style={{
        marginTop: '-1.25rem',
        width: divRightWidth,
        borderRadius: radiusPx,
        backgroundImage: `${
          isThemeDark
            ? `url(${custominfo_dark_folder})`
            : `url(${custominfo_folder})`
        }`,
        backgroundSize: 'cover',
      }}
      className="max-h-[90vh] fixed h-[90vh] dark:bg-black bg-white z-[22222]"
    >
      {emailRecapIsActive &&
      divActive === 'FormCustomerInformation' &&
      hasCee ? (
        <EmailRecap />
      ) : (
        <RenderRightPart infosRightPart={infosRightPart} />
      )}
    </div>
  );
}

export default RightPartCustomerInformation;
