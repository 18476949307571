import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import LoaderFolderRight from '@components/loaders/LoaderFolderRight';
import { Document, Page, pdfjs, PDFPageProxy } from 'react-pdf';
import { LayoutDashboardContext } from '@dashboard/utils/context/LayoutDashboardContext';
import { InstallerContext } from '@auth/utils/authContext';
import { PdfNavigator } from '@components/PdfNavigator';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface IDocumentViewerProps {
  typeIsImage: boolean;
  nothingToShow: boolean;
  fileToShow: string;
  pdfIsLoaded: boolean;
  setPdfIsLoaded: Dispatch<SetStateAction<boolean>>;
}

function DocumentViewer({
  typeIsImage,
  nothingToShow,
  fileToShow,
  pdfIsLoaded,
  setPdfIsLoaded,
}: IDocumentViewerProps): JSX.Element {
  const { isRetractableDDC } = useContext(LayoutDashboardContext);

  const { radiusPx } = useContext(InstallerContext);

  const [numPages, setNumPages] = useState<number>();
  const [pageNumber, setPageNumber] = useState<number>(1);

  const [isRotate, setIsRotate] = useState<boolean>(false);

  const [pdfWidth, setPdfWidth] = useState<number>(0);
  const [widthToDisplay, setWidthToDisplay] = useState<number>(0);

  const [imageWidth, setImageWidth] = useState<string>('');
  const [imageHeight, setImageHeight] = useState<string>('');

  const [containerHeight, setContainerHeight] = useState<number>(0);
  const [containerWidth, setContainerWidth] = useState<number>(0);

  const container = useRef<HTMLDivElement>(null);

  const resizePdf = () => {
    if (container && container.current) {
      const divHeight = container.current.clientHeight;
      const divWidth = container.current.clientWidth;
      setContainerHeight(divHeight);
      setContainerWidth(divWidth);
    }
  };

  const sizeImage = () => {
    const width = containerWidth - containerWidth * 0.2;
    const height = containerHeight - containerHeight * 0.1;
    setImageWidth(`${width}px`);
    setImageHeight(`${height}px`);
  };

  const onDocumentLoadSuccess = (pageNum: number) => {
    setNumPages(pageNum);
    setPageNumber(1);
    if (isRotate) {
      setIsRotate(false);
    }
  };

  const handleLoadSuccess = (page: PDFPageProxy) => {
    if (page.width > page.height || page.width >= 842) {
      setIsRotate(true);
    }
    setPdfWidth(page.width);
  };

  const getSize = () => {
    if (fileToShow) {
      const img = new Image();
      img.src = fileToShow;
      img.onload = () => {
        if (img.width > img.height) {
          setIsRotate(true);
        } else {
          setIsRotate(false);
        }
      };
    }
  };

  const getWidth = () => {
    let width: number;
    if (isRetractableDDC) {
      width = containerWidth - containerWidth * 0.4;
    } else {
      width = containerWidth - containerWidth * 0.22;
    }
    setWidthToDisplay(width);
  };

  useEffect(() => {
    window.addEventListener('resize', resizePdf);

    resizePdf();
  }, []);
  useEffect(() => {
    getWidth();
  }, [pdfWidth, containerWidth]);

  useEffect(() => {
    sizeImage();
  }, [containerWidth, containerHeight]);
  return (
    <>
      {nothingToShow ? (
        <div className="text-dark-1 dark:text-white dark:bg-dark-5 bg-white rounded-default w-3/5 h-full justify-center flex items-center">
          <div className="text-[1.125rem] font-[500]">Aucun fichier.</div>
        </div>
      ) : (
        <div className="w-3/5 max-w-3/5 h-full max-h-full" ref={container}>
          {typeIsImage ? (
            <img
              src={fileToShow}
              alt="avant Travaux"
              onLoad={getSize}
              className={`${radiusPx === '16px' && 'rounded-[20px]'}`}
              style={
                isRotate
                  ? {
                      marginTop: '120px',
                      height: imageWidth,
                      maxHeight: imageWidth,
                      width: imageHeight,
                      maxWidth: imageHeight,
                      transform: 'rotate(90)',
                    }
                  : {
                      width: imageWidth,
                      maxWidth: imageWidth,
                      height: imageHeight,
                      maxHeight: imageHeight,
                      marginLeft: '80px',
                      marginTop: '10px',
                    }
              }
            />
          ) : (
            <>
              {!pdfIsLoaded && (
                <div className="w-full h-full">
                  <LoaderFolderRight />
                </div>
              )}
              <div
                style={{
                  width: `${containerWidth}px`,
                  maxWidth: `${containerWidth}px`,
                  height: `${containerHeight}px`,
                  maxHeight: `${containerHeight}px`,
                }}
                className="fixed z-[22222] flex flex-col "
              >
                <Document
                  file={fileToShow}
                  onLoadSuccess={(pdf) => {
                    onDocumentLoadSuccess(pdf.numPages);
                    setPdfIsLoaded(true);
                  }}
                  onLoadError={() => {
                    setNumPages(undefined);
                    setPageNumber(1);
                  }}
                  error={
                    <div className="w-full h-full justify-center flex items-center">
                      <div className="text-[.75rem] font-light">
                        Impossible d&apos;afficher le fichier.
                      </div>
                    </div>
                  }
                  loading={<></>}
                >
                  {isRotate ? (
                    <Page
                      renderAnnotationLayer={false}
                      pageNumber={pageNumber}
                      onRenderSuccess={(page) => handleLoadSuccess(page)}
                      rotate={pdfWidth < 842 ? 90 : 180}
                      className={`${pdfWidth >= 842 && 'overflow-auto'} ${
                        isRetractableDDC ? 'ml-[10.625rem]' : 'ml-[4.75rem]'
                      }`}
                      width={widthToDisplay}
                    />
                  ) : (
                    <Page
                      renderAnnotationLayer={false}
                      pageNumber={pageNumber}
                      onRenderSuccess={(page) => handleLoadSuccess(page)}
                      width={widthToDisplay}
                      className={`${
                        isRetractableDDC ? 'ml-[10.625rem]' : 'ml-[4.75rem]'
                      }`}
                    />
                  )}
                </Document>
                {pdfIsLoaded && (
                  <PdfNavigator
                    numberOfPages={numPages}
                    pageActive={pageNumber}
                    setPageActive={setPageNumber}
                  />
                )}
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
}

export { DocumentViewer };
