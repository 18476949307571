/* eslint-disable no-nested-ternary */
import { useContext, Dispatch, SetStateAction } from 'react';
import { numFormatSpace } from '@utils/utils';
import { InstallerContext } from '@auth/utils/authContext';
import { ChevronIcon } from '@assets/svgComponents/svgComponents';
import { useLocation } from 'react-router-dom';
import { dark1, backgroundQuaternaryLightMode } from '@assets/colors';

interface IDetailDropDownProps {
  TVA: number;
  detailDropdownOpen: boolean;
  setDetailDropdownOpen: Dispatch<SetStateAction<boolean>>;
  totalFolderFees: number;
}
function DetailDropdown({
  TVA,
  detailDropdownOpen,
  setDetailDropdownOpen,
  totalFolderFees,
}: IDetailDropDownProps): JSX.Element {
  const location = useLocation();

  const { radiusPx, isThemeDark } = useContext(InstallerContext);

  return (
    <div
      style={{
        borderRadius: radiusPx,
        backgroundColor: isThemeDark
          ? dark1
          : location.search.includes('?integration=iframe')
          ? backgroundQuaternaryLightMode
          : 'white',
      }}
      id="detail-dropdown"
      className="text-[.875rem] w-full mt-interblock dark:text-white text-colorTextLightMode animate__animated animate__fadeIn"
    >
      <div
        style={{
          borderRadius: radiusPx,
          backgroundColor: isThemeDark
            ? dark1
            : location.search.includes('?integration=iframe')
            ? backgroundQuaternaryLightMode
            : 'white',
        }}
        className="dark:bg-dark-1 bg-white w-full"
      >
        <button
          style={{
            borderRadius: radiusPx,
            backgroundColor: isThemeDark
              ? dark1
              : location.search.includes('?integration=iframe')
              ? backgroundQuaternaryLightMode
              : 'white',
          }}
          type="button"
          className="px-[2.5rem] items-center py-[2.5rem] justify-between flex dark:bg-dark-1 bg-white w-full"
          onClick={() => setDetailDropdownOpen(!detailDropdownOpen)}
        >
          <div className="font-medium">Détail des frais de dossier MPR</div>
          <div>
            <ChevronIcon
              width="1.5rem"
              height="1.5rem"
              fill={isThemeDark ? 'white' : '#191919'}
              className={`${
                detailDropdownOpen ? 'rotate-minus-90' : 'rotate-90'
              } transition-transform duration-300`}
            />
          </div>
        </button>
      </div>
      <div
        style={{
          transition: '1s linear',
          marginTop: '-.625rem',
          borderBottomLeftRadius: radiusPx,
          borderBottomRightRadius: radiusPx,
          backgroundColor: isThemeDark
            ? dark1
            : location.search.includes('?integration=iframe')
            ? backgroundQuaternaryLightMode
            : 'white',
        }}
        className={`px-[2.5rem] space-y-[1rem] pb-[2.5rem] flex dark:bg-dark-1 bg-white flex-col mt-0 overflow-hidden  ${
          detailDropdownOpen
            ? 'lg:max-h-[18.75rem] max-h-[31.25rem]'
            : `max-h-0 !py-0`
        } `}
      >
        <span>
          Les frais de dossier appliqués par Drapo sont soumis à une TVA de 20%.
        </span>
        <span>
          Ces frais s&apos;élèvent à {numFormatSpace(totalFolderFees)} € HT (
          {numFormatSpace(totalFolderFees + TVA)} € TTC).
        </span>
        <span>
          Une fois votre déclaration de TVA faite auprès de
          l&apos;administration, vous pourrez récupérer la TVA de{' '}
          {numFormatSpace(TVA)} €.
        </span>
      </div>
    </div>
  );
}

export default DetailDropdown;
