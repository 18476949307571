import { Dispatch, SetStateAction, useContext } from 'react';
import { v4 } from 'uuid';
import { transformDocumentUrl } from '@dashboard/utils/functions';
import { IContrat, IMyProfilDatas } from '../../../../utils/dashboardTypes';
import OneDocument from './OneDocument';
import { InstallerContext } from '../../../../../auth/utils/authContext';
import UploadNewDocument from './UploadNewDocument';

interface IProps {
  editable: boolean;
  documentsContrat: IContrat[] | null;
  documentsOther: string[] | null;
  titleCard: string;
  setDatasMyProfile: Dispatch<SetStateAction<IMyProfilDatas>>;
}
function OneCard({
  editable,
  documentsContrat,
  documentsOther,
  titleCard,
  setDatasMyProfile,
}: IProps): JSX.Element {
  const { radiusPx } = useContext(InstallerContext);
  return (
    <div
      style={{
        borderRadius: radiusPx,
      }}
      className="dark:bg-dark-5 bg-backgroundTertiaryLightMode px-[1rem] py-[1.25rem] mb-[1.5rem] dark:text-white text-dark-2 "
    >
      <p className="sb-text-rem-subtitle mb-[.375rem]">{titleCard}</p>
      {editable && (
        <UploadNewDocument
          titleCard={titleCard}
          setDatasMyProfile={setDatasMyProfile}
        />
      )}
      {documentsContrat
        ? documentsContrat.map((el) => {
            if (el.value.value !== '{}') {
              return (
                <div key={v4()}>
                  <OneDocument
                    title={el.label}
                    document={transformDocumentUrl(el)}
                    setDatasMyProfile={setDatasMyProfile}
                    titleCard={titleCard}
                    documentsOther={documentsOther}
                  />
                </div>
              );
            }
            return <></>;
          })
        : documentsOther?.map((el, index) => {
            return (
              <div key={v4()}>
                <OneDocument
                  title={`Fichier ${index + 1}`}
                  document={transformDocumentUrl(el)}
                  setDatasMyProfile={setDatasMyProfile}
                  titleCard={titleCard}
                  documentsOther={documentsOther}
                />
              </div>
            );
          })}
    </div>
  );
}

export default OneCard;
