import { Dispatch, SetStateAction } from 'react';
import { setSteps } from '../apiRequests/AdminRequests';
import { IStepOperationType, IStepType } from '../utils/AdminTypes';

type PropTypes = {
  //   step: IStepType;
  //   operations: IStepOperationType[];
  //   rows: unknown[][] | string[][];
  updateSteps: Dispatch<SetStateAction<IStepType[]>>;
  //   token: string | null;
  //   setShowPopup: Dispatch<SetStateAction<string>>;
  setOperations: Dispatch<SetStateAction<IStepOperationType[]>>;
  setOperationLoading: Dispatch<SetStateAction<boolean>>;
  //   stepFieldsSelect: IFieldType[];
  //   stepName: string;
  //   setStepName: Dispatch<SetStateAction<string>>;
  steps: IStepType[];
  selectedTab: number;
  setSelectedTab: Dispatch<SetStateAction<number>>;
  setSelectedStep: Dispatch<SetStateAction<IStepType>>;
};

export default function Tabs({
  setOperationLoading,
  updateSteps,
  setOperations,
  selectedTab,
  setSelectedTab,
  steps,
  setSelectedStep,
}: PropTypes): JSX.Element {
  const handleAddTable = () => {
    setOperationLoading(true);
    setSteps(updateSteps, setOperations, setOperationLoading);
  };

  const handleSelectTable = (id: number) => {
    if (selectedTab !== id) {
      setOperationLoading(true);
      setSelectedTab(id);
      const selectTable: IStepType = steps.find(
        (table) => table.id === id
      ) as IStepType;
      setSelectedStep(selectTable);
    }
  };
  return (
    <ul className="flex">
      {steps.map((table) => (
        <li key={`table-${table.id}`}>
          <button
            type="button"
            tabIndex={0}
            className={
              table.id !== selectedTab
                ? 'bg-black text-white p-3 min-w-[150px]'
                : 'bg-white text-black p-3 min-w-[150px]'
            }
            onClick={() => {
              handleSelectTable(table.id);
            }}
            onKeyPress={() => handleSelectTable(table.id)}
            style={{ cursor: 'pointer' }}
          >
            {table.name}
          </button>
        </li>
      ))}
      <button
        type="button"
        tabIndex={0}
        className="cursor-pointer mx-3 text-[40px] relative leading-3 "
        onClick={handleAddTable}
        onKeyPress={handleAddTable}
      >
        +
      </button>
    </ul>
  );
}
