import { IDataSearchFolder } from '@dashboard/utils/dashboardTypes';
import React, { useContext, useEffect, useState } from 'react';
import { LayoutDashboardContext } from '@dashboard/utils/context/LayoutDashboardContext';
import InputSearchFolder from './InputSearchFolder';
import SearchFolderList from './SearchFolderList';

function SearchFolder() {
  const { isLookingFor, updateIsLookingFor } = useContext(
    LayoutDashboardContext
  );
  const [searchValue, setSearchValue] = useState('');
  const [listFolders, setListFolders] = useState<
    IDataSearchFolder[] | undefined
  >();
  const [totalFoldersInList, setTotalFoldersInList] = useState<number>(0);
  const [numberPage, setNumberPage] = useState(1);
  const [foldersIsLoaded, setFoldersIsLoaded] = useState<boolean>(false);

  useEffect(() => {
    if (searchValue.length === 0) {
      updateIsLookingFor(false);
      setNumberPage(1);
    }
  }, [searchValue]);

  useEffect(() => {
    if (!isLookingFor) {
      setListFolders([]);
      setFoldersIsLoaded(false);
      setSearchValue('');
    }
  }, [isLookingFor]);

  return (
    <>
      <InputSearchFolder
        isLookingFor={isLookingFor}
        setIsLookingFor={updateIsLookingFor}
        setSearchValue={setSearchValue}
        searchValue={searchValue}
        totalFoldersInList={totalFoldersInList}
        setFoldersIsLoaded={setFoldersIsLoaded}
        setListFolders={setListFolders}
        setTotalFoldersInList={setTotalFoldersInList}
        listFolders={listFolders}
        setNumberPage={setNumberPage}
      />
      {totalFoldersInList > 0 && (
        <SearchFolderList
          isLookingFor={isLookingFor}
          datas={listFolders}
          totalFoldersInList={totalFoldersInList}
          setNumberPage={setNumberPage}
          numberPage={numberPage}
          setFoldersIsLoaded={setFoldersIsLoaded}
          foldersIsLoaded={foldersIsLoaded}
          searchValue={searchValue}
          setListFolders={setListFolders}
          setTotalFoldersInList={setTotalFoldersInList}
          listFolders={listFolders}
        />
      )}
    </>
  );
}

export default SearchFolder;
