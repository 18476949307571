import {
  Button,
  Input,
  WarningNotification,
} from 'storybook-design-system-drapo';
import { useContext, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { InstallerContext } from '@auth/utils/authContext';
import {
  OperationsListContext,
  SimulatorContext,
} from '@simulator/utils/simulatorContext';
import { calculatePrimes } from '@simulator/apiRequests/SimulatorRequests';
import { numFormatSpace } from '@utils/utils';
import { dark1, white } from '@assets/colors';
import { initialOperationDatas } from '@simulator/utils/initialsState';

function TotalCost() {
  const location = useLocation();
  const whiteLabel: string = useParams().marque || '';
  const [amount, setAmount] = useState<string>('0');
  const [errorMinAmount, setErrorMinAmount] = useState<boolean>(false);

  const {
    updateMultipleOperations,
    updateCurrentOperation,
    updateStepNumber,
    currentOperation,
    simulatorDatas,
    updateLoaded,
    updateTitle,
    updateSubtitle,
    surfaceIsolate,
    totalCostAmountMin,
    multipleOperations,
    updateErrorCalculationPrimes,
    operationInEdit,
    updateOperationActiveId,
  } = useContext(SimulatorContext);
  const {
    installer,
    radiusPx,
    colorPrimary,
    colorAlertWhiteLabel,
    isThemeDark,
    colorTextButton,
  } = useContext(InstallerContext);

  const { updateOperationList } = useContext(OperationsListContext);

  const isIsolationOperation = currentOperation.id.includes('-en-');

  useEffect(() => {
    if (currentOperation.id.includes('-se-')) {
      updateTitle('Coût total TTC');
    } else if (isIsolationOperation) {
      updateTitle('Indiquez le coût TTC matériel et posé au m²');
    } else {
      updateTitle('Coût total TTC matériel et pose');
    }
    updateSubtitle(currentOperation.name);
  }, []);

  const onClickNext = () => {
    const newCurrentOperation = currentOperation;
    const amountFormatted = amount.replace(',', '.');

    if (isIsolationOperation) {
      if (Number(amountFormatted) < 1) {
        setErrorMinAmount(true);
        return false;
      }
      const newTotalCost = Number(amountFormatted) * surfaceIsolate;
      newCurrentOperation.totalCost = newTotalCost.toString();
    } else {
      if (Number(amountFormatted) < totalCostAmountMin) {
        setErrorMinAmount(true);
        return false;
      }
      newCurrentOperation.totalCost = amountFormatted;
    }

    updateLoaded(false);

    const currentOperationIndexInList: number = multipleOperations.findIndex(
      (operation) => operation.id === currentOperation.id
    );
    const multipleOperationsWithDatas = multipleOperations;
    multipleOperationsWithDatas[currentOperationIndexInList] = currentOperation;
    updateMultipleOperations(multipleOperationsWithDatas);

    if (
      currentOperation.id ===
      multipleOperations[multipleOperations.length - 1].id
    ) {
      calculatePrimes(
        simulatorDatas,
        multipleOperationsWithDatas,
        updateOperationList,
        updateLoaded,
        installer.eligibility,
        location,
        whiteLabel,
        updateErrorCalculationPrimes,
        operationInEdit
      );
      updateCurrentOperation(initialOperationDatas);
      updateStepNumber(4);
      updateOperationActiveId('');
    } else {
      updateCurrentOperation(
        multipleOperations[currentOperationIndexInList + 1]
      );
    }

    // updateCurrentOperation(newCurrentOperation);
    return true;
  };

  const handleKeyPress = (e: React.KeyboardEvent<EventTarget>) => {
    if (e.key === 'Enter') {
      onClickNext();
    }
  };

  return (
    <div>
      <Input
        border={
          location.search.includes('?integration=iframe')
            ? '1px solid #d2d2d2'
            : ''
        }
        borderRadius={radiusPx}
        addClass="mt-[1.5rem]"
        inputType="text"
        isNumber
        label="Montant"
        value={amount.replace('.', ',')}
        onClick={() => {
          if (amount === '0') {
            setAmount('');
          }
        }}
        onChange={(e) => setAmount(e.target.value)}
        symbol={isIsolationOperation ? '€/m²' : '€'}
        color={isThemeDark ? white : dark1}
        backgroundColor={isThemeDark ? dark1 : 'white'}
        dataTestId="totalCost"
        onKeyPress={(e) =>
          handleKeyPress(e as React.KeyboardEvent<HTMLInputElement>)
        }
      />
      {errorMinAmount && (
        <WarningNotification
          addClass="mt-[1.5rem]"
          backgroundColor={colorAlertWhiteLabel}
          color="white"
          warningText={`Le montant du coût total doit être de ${numFormatSpace(
            totalCostAmountMin
          )} € minimum.`}
        />
      )}
      <div className="w-full mt-[1.5rem]">
        <Button
          borderRadius={radiusPx}
          disabled={!(Number(amount) !== 0 && amount !== '')}
          buttonArrowSmall
          label="Suivant"
          color={colorTextButton}
          backgroundColor={colorPrimary}
          arrow
          onClick={onClickNext}
          dataTestId="next"
          addClass="!w-full !px-[1rem]"
        />
      </div>
    </div>
  );
}

export default TotalCost;
