import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Button, InputSearch } from 'storybook-design-system-drapo';
import Loader from '@components/loaders/Loader';
import { purple1 } from '@assets/colors';
import { getSchemaFolders, updateStep } from '../../apiRequests/AdminRequests';
import { AdminContext } from '../../utils/AdminContext';
import Fields from './Fields';
import {
  IFieldType,
  IStepOperationType,
  IStepType,
} from '../../utils/AdminTypes';

type PropTypes = {
  setShowPopup: Dispatch<SetStateAction<string>>;
  stepFieldsSelect: IFieldType[];
  setStepFieldsSelect: Dispatch<SetStateAction<IFieldType[]>>;
  currentStep: IStepType;
  updateSteps: Dispatch<SetStateAction<IStepType[]>>;
  setOperations: Dispatch<SetStateAction<IStepOperationType[]>>;
  setOperationLoading: Dispatch<SetStateAction<boolean>>;
  stepName: string;
};

export default function SelectFields({
  stepFieldsSelect,
  setStepFieldsSelect,
  currentStep,
  setShowPopup,
  updateSteps,
  setOperations,
  setOperationLoading,
  stepName,
}: PropTypes): JSX.Element {
  const [schemas, setSchemas] = useState<IFieldType[]>([]);
  const [schemaLoading, setSchemaLoading] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const [showFields, setShowFields] = useState<string>('ALL');

  const { conditions } = useContext(AdminContext);

  useEffect(() => {
    if (!schemas[0]) {
      setSchemaLoading(true);
      getSchemaFolders(setSchemas, setSchemaLoading);
    }
  }, []);

  useEffect(() => {
    setSearchValue('');
  }, [showFields]);

  const stepFieldsId = stepFieldsSelect.map(
    (stepField) => stepField.provider_id
  );

  const handleCheckCard = (field: IFieldType) => {
    if (!stepFieldsId.includes(field.provider_id)) {
      setStepFieldsSelect([...stepFieldsSelect, field]);
    } else {
      const updatedFields = stepFieldsSelect.filter(
        (fieldValue) => field.provider_id !== fieldValue.provider_id
      );
      setStepFieldsSelect(updatedFields);
    }
  };

  const handleSubmitFields = () => {
    setOperationLoading(true);
    updateStep(
      currentStep,
      updateSteps,
      setShowPopup,
      setOperations,
      setOperationLoading,
      conditions,
      stepFieldsSelect,
      stepName
    );
  };

  const filterFields = (listFields: IFieldType[]) =>
    listFields.filter(
      (field) =>
        field.provider_name !== 'Référence opération' &&
        field.provider_name !== 'Référence dossier' &&
        field.provider_name !== 'Statut MPR' &&
        !field.provider_name.toLowerCase().includes('installateur')
    ) as IFieldType[];

  const fieldsToDisplay: IFieldType[] =
    showFields === 'ALL'
      ? filterFields(schemas)
      : filterFields(stepFieldsSelect);

  return (
    <div
      className="z-10 fixed top-0 left-0 w-full h-full bg-[#000000ab] cursor-pointer "
      onClick={() => setShowPopup('NONE')}
      onKeyPress={() => setShowPopup('NONE')}
      tabIndex={0}
      role="button"
    >
      {schemaLoading ? (
        <Loader />
      ) : (
        <div
          className="relative w-1/3 px-20 py-10 my-[2.5%] dark:bg-dark-3 bg-lightMode-dark-3 rounded-[8px] h-[90%] mx-auto"
          onClick={(e) => e.stopPropagation()}
          onKeyPress={(e) => e.stopPropagation()}
          tabIndex={0}
          role="button"
        >
          <button
            type="button"
            className="rotate-45 absolute top-0 right-4 text-[40px]"
            onClick={() => setShowPopup('NONE')}
          >
            +
          </button>
          <div className="flex justify-center  dark:bg-dark-4 bg-lightMode-dark-4 mb-5 text-sm h-[50px] rounded-md">
            <button
              type="button"
              className={
                showFields === 'ALL' ? 'w-1/2 bg-[#545454] rounded-md' : 'w-1/2'
              }
              onClick={() => setShowFields('ALL')}
            >
              Tous les champs
            </button>
            <button
              type="button"
              className={
                showFields === 'SELECTED'
                  ? 'w-1/2 bg-[#545454] rounded-md'
                  : 'w-1/2'
              }
              onClick={() => setShowFields('SELECTED')}
            >
              Ordonner les champs
            </button>
          </div>
          <div>
            <InputSearch
              backgroundColor="#191919"
              color="#fffff"
              backgroundColorIconSearch="#313030"
              dynamic
              placeholder="Rechercher un champ"
              onSearch={(e) => setSearchValue(e)}
            />
            <Fields
              fields={fieldsToDisplay}
              searchValue={searchValue}
              stepFieldsId={stepFieldsId}
              handleCheckCard={handleCheckCard}
              showFields={showFields}
            />

            <div className="flex gap-5">
              <Button
                label="Valider"
                backgroundColor={purple1}
                color="white"
                onClick={handleSubmitFields}
                addClass={showFields === 'ALL' ? 'w-1/2' : 'w-full'}
              />
              {showFields === 'ALL' && (
                <Button
                  label="Ordonner les champs"
                  backgroundColor={showFields === 'ALL' ? 'white' : purple1}
                  color={showFields !== 'ALL' ? 'white' : purple1}
                  onClick={() => setShowFields('SELECTED')}
                  addClass="w-1/2"
                />
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
