import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Button, Input } from 'storybook-design-system-drapo';
import Loader from '@components/loaders/Loader';
import { AuthContext, InstallerContext } from '@auth/utils/authContext';
import { getContrastYIQ } from '@utils/utils';
import {
  authRequests,
  getWhiteLabelLogin,
} from '@auth/apiRequests/AuthRequests';
import { TooltipsLogin } from '@components/TooltipsLogin';
import {
  backgroundQuaternaryLightMode,
  borderLightMode2,
} from '@assets/colors';
import { ErrorAutologin } from '@auth/components/ErrorAutologin';
import { FoldersAndSimulatorContext } from '@folder/utils/FoldersContext';
import LoaderDashboard from '@components/loaders/LoaderDashboard';
import { useMediaQuery } from 'react-responsive';

type LocationState = { prevPath: string };

function Login(): JSX.Element {
  const router = useNavigate();
  const location = useLocation();
  const isMobile = useMediaQuery({ maxWidth: 1024 });
  const whiteLabelLoginUrl = localStorage.getItem('whiteLabelLoginUrl');

  const { colorPrimary, radiusPx } = useContext(InstallerContext);
  const { whiteLabelCustom, updateWhiteLabelCustom } = useContext(
    FoldersAndSimulatorContext
  );

  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [errMessage, setErrMessage] = useState<string>('');
  const [errBorderMail, setErrBorderMail] = useState<string>('');
  const [errBorderPassword, setErrBorderPassword] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [subscribeLink, setSubscribeLink] = useState<string>(
    'https://www.drapo.com/inscription/'
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [fromAutologin, setFromAutologin] = useState<boolean>(false);

  const { updateToken, updateTokenConnected } = useContext(AuthContext);

  const isWhiteLabel = location.pathname.includes('wl');
  const whiteLabel: string = useParams().marque || '';

  const handleSubmit = () => {
    setLoading(true);
    if (isWhiteLabel) {
      localStorage.setItem('whiteLabelLoginUrl', location.pathname);
    } else {
      localStorage.removeItem('whiteLabelLoginUrl');
    }
    authRequests(
      email,
      password,
      updateToken,
      setLoading,
      setErrMessage,
      setErrBorderMail,
      setErrBorderPassword,
      router,
      isMobile,
      updateTokenConnected
    );
  };

  const cleanErrBorders = () => {
    setErrBorderMail('');
    setErrBorderPassword('');
    setErrMessage('');
  };

  const handleKeyPress = (e: React.KeyboardEvent<EventTarget>) => {
    if (e.key === 'Enter' && !loading) {
      handleSubmit();
    }
  };

  const onClickForgotten = () => {
    const path = isWhiteLabel
      ? `/wl/${whiteLabel}/password-forgotten`
      : `/password-forgotten`;
    router(path);
  };

  const imgStyle: {
    borderTopRightRadius: string;
    borderBottomRightRadius: string;
    backgroundImage?: string;
  } = {
    borderTopRightRadius: radiusPx,
    borderBottomRightRadius: radiusPx,
  };

  if (isWhiteLabel && whiteLabelCustom.illustration_page_connexion) {
    imgStyle.backgroundImage = `url(${whiteLabelCustom.illustration_page_connexion})`;
  }

  useEffect(() => {
    if (location.state !== null) {
      const { prevPath } = location.state as LocationState;
      if (prevPath !== undefined && prevPath.includes('autologin')) {
        setFromAutologin(true);
      }
    }
  }, [location]);

  useEffect(() => {
    if (isWhiteLabel) {
      setIsLoading(true);
      getWhiteLabelLogin(
        whiteLabel,
        updateWhiteLabelCustom,
        router,
        setIsLoading
      );
    }
    if (whiteLabelLoginUrl !== '' && whiteLabelLoginUrl) {
      localStorage.removeItem('whiteLabelLoginUrl');
      window.location.href = whiteLabelLoginUrl as string;
    }
  }, []);

  useEffect(() => {
    if (
      isWhiteLabel &&
      whiteLabelCustom.url_inscription !== '' &&
      whiteLabelCustom.url_inscription !== undefined
    ) {
      setSubscribeLink(whiteLabelCustom.url_inscription);
    }
  }, [whiteLabelCustom]);
  return (
    <div>
      {isLoading ? (
        <LoaderDashboard />
      ) : (
        <>
          {fromAutologin && <ErrorAutologin />}
          <div
            style={{
              transform: 'translate(-50%, -50%)',
              left: '50%',
              top: '50%',
              borderRadius: radiusPx,
            }}
            className="flex dark:bg-dark-2 bg-white absolute lg:w-8/12 w-11/12 lg:pt-0 pt-[1.25rem] dark:text-white text-colorTextLightMode"
          >
            <div className="flex w-full flex-col self-center justify-center p-[.5rem] lg:p-[3rem] xl:w-3/6">
              <h2 className="text-[2.25rem] lg:text-left text-center mb-[3.5rem] 3xl:text-center">
                Connexion
                <br /> à l’espace pro
              </h2>
              <Input
                borderRadius={radiusPx}
                addClassInput={
                  isWhiteLabel ? 'light-mode-input-white' : 'dark-mode-input'
                }
                backgroundColor={isWhiteLabel ? '#FFFFFF' : '#191919'}
                color={isWhiteLabel ? '#202020' : '#FFFFFF'}
                border={
                  isWhiteLabel ? `1px solid ${borderLightMode2}` : '#191919'
                }
                inputType="email"
                label="Email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  cleanErrBorders();
                }}
                addClass={`mb-[1rem] ${errBorderMail} `}
                dataTestId="email"
                onKeyPress={(e) =>
                  handleKeyPress(e as React.KeyboardEvent<HTMLInputElement>)
                }
              />
              <Input
                borderRadius={radiusPx}
                addClassInput={
                  isWhiteLabel ? 'light-mode-input-white' : 'dark-mode-input'
                }
                border={
                  isWhiteLabel ? `1px solid ${borderLightMode2}` : '#191919'
                }
                inputType="password"
                label="Mot de passe"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                  cleanErrBorders();
                }}
                backgroundColor={isWhiteLabel ? '#FFFFFF' : '#191919'}
                color={isWhiteLabel ? '#202020' : '#FFFFFF'}
                addClass={`${errBorderPassword}`}
                dataTestId="password"
                onKeyPress={(e) =>
                  handleKeyPress(e as React.KeyboardEvent<HTMLInputElement>)
                }
                forgotten
                onClickForgotten={onClickForgotten}
              />
              {errMessage !== '' && (
                <p
                  id="error-message"
                  className="text-red-500 text-[.75rem] mt-[.5rem] ml-[.5rem] opacity-60"
                >
                  {errMessage}
                </p>
              )}
              <div className="my-[1rem] w-full flex flex-col lg:flex-row mb-[1rem] 3xl:w-3/5">
                <Button
                  borderRadius={radiusPx}
                  disabled={!!loading || !email || !password}
                  label="Connexion"
                  color={getContrastYIQ(colorPrimary.replace('#', ''))}
                  backgroundColor={colorPrimary}
                  arrow
                  addClass="btn-login lg:mr-[.75rem]"
                  onClick={() => handleSubmit()}
                  dataTestId="connexion"
                />
                <a href={subscribeLink} className="h-full">
                  <Button
                    borderRadius={radiusPx}
                    label="Inscription"
                    color={isWhiteLabel ? '#202020' : '#FFFFFF'}
                    backgroundColor={
                      isWhiteLabel ? backgroundQuaternaryLightMode : '#212334'
                    }
                    arrow
                    addClass="btn-registration btn-sb-w-full mt-[.5rem] lg:mt-0"
                    dataTestId="inscription"
                  />
                </a>
              </div>
              {loading && <Loader />}
              <TooltipsLogin />
            </div>
            <div className="hidden xl:block xl:w-3/6">
              <div
                style={imgStyle}
                className={`h-full w-full ${
                  isWhiteLabel && whiteLabelCustom.illustration_page_connexion
                    ? ''
                    : 'bg-login-img'
                } bg-center bg-cover`}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default Login;
