import { DashboardContext } from '@dashboard/utils/context/dashboardContext';
import { useContext } from 'react';
import { InstallerContext } from '@auth/utils/authContext';
import QuotaCEE from './QuotaCEE';
import OneCardFinancial from './OneCardFinancial';

function Financials(): JSX.Element {
  const { financialsCee, financialsMpr, quotaCee } =
    useContext(DashboardContext);

  const { installer } = useContext(InstallerContext);

  const ceeActive = installer.eligibility.includes('CEE');
  const mprActive = installer.eligibility.includes('MPR');

  const checkIfQuotaDisplay = !!(
    ceeActive &&
    quotaCee &&
    quotaCee.quotas.filter((quota) => !quota.volume_max.includes('max. 0.00'))
      .length > 0
  );

  return (
    <div className="w-full max-h-full h-full">
      <p className="text-[.875rem] leading-[1.5rem] pb-[1rem] w-11/12 m-auto dark:text-white text-colorTextLightMode">
        Financiers
      </p>
      {(!mprActive || (mprActive && financialsMpr.nb_folders_total > 0)) && (
        <OneCardFinancial
          item={financialsMpr}
          isInactive={!mprActive}
          title="MPR"
        />
      )}
      {(!ceeActive || (ceeActive && financialsCee.nb_folders_total > 0)) && (
        <OneCardFinancial
          item={financialsCee}
          isInactive={!ceeActive}
          title="CEE"
        />
      )}
      {checkIfQuotaDisplay && <QuotaCEE />}
    </div>
  );
}

export default Financials;
