import {
  deleteListCustomViewById,
  createNewListCustomView,
} from '@dashboard/apiRequests/dashboardRequests';
import { DashboardContext } from '@dashboard/utils/context/dashboardContext';
import React, { useContext } from 'react';
import { v4 } from 'uuid';
import { InstallerContext } from '@auth/utils/authContext';
import {
  ContentCopyIcon,
  TrashIcon,
} from '@assets/svgComponents/svgComponents';

function DuplicateOrDeleteList(): JSX.Element {
  const {
    idCurrentListCustomView,
    updateElementActive,
    updateActiveCustomView,
    updateListSchemasCustomView,
    updateIsLoadingCustomView,
    listVisibleColumnsCustomView,
    nameCurrentListCustomView,
    updateCurrentListCustomView,
    updateIsCreateNewListCustomView,
    updateFiltersOrPersonalizationModalCustomView,
    updateIdCurrentListCustomView,
    numberPageList,
    newConditions,
    updateCountsCustomListView,
    updateRowsSelectedCustomView,
  } = useContext(DashboardContext);

  const { isThemeDark } = useContext(InstallerContext);

  const data = [
    {
      name: 'Dupliquer la liste',
      icon: (
        <ContentCopyIcon
          fill={isThemeDark ? '#E5E5E5' : '#191919'}
          width="1.25rem"
          height="1.25rem"
        />
      ),
    },
    {
      name: 'Supprimer la liste',
      icon: (
        <TrashIcon
          fill={isThemeDark ? '#E5E5E5' : '#191919'}
          width="1.25rem"
          height="1.25rem"
        />
      ),
    },
  ];

  const duplicateOrDeleteList = (name: string) => {
    if (name === 'Supprimer la liste') {
      deleteListCustomViewById(
        idCurrentListCustomView,
        updateElementActive,
        updateActiveCustomView,
        updateListSchemasCustomView,
        updateIsLoadingCustomView
      );
    } else {
      createNewListCustomView(
        listVisibleColumnsCustomView.fields,
        `${nameCurrentListCustomView} copie`,
        updateIsLoadingCustomView,
        updateCurrentListCustomView,
        updateIsCreateNewListCustomView,
        updateFiltersOrPersonalizationModalCustomView,
        updateIdCurrentListCustomView,
        numberPageList,
        updateCountsCustomListView,
        updateRowsSelectedCustomView,
        newConditions
      );
    }
  };

  return (
    <div className="dark:bg-[#1C1E20] bg-white px-[1.5rem] pt-[1.25rem]">
      {data.map((el) => {
        return (
          <div
            key={v4()}
            className="flex items-center space-x-[1rem] w-full pb-[.625rem]"
          >
            <button
              type="button"
              onClick={() => duplicateOrDeleteList(el.name)}
            >
              {el.icon}
            </button>

            <p className="text-[.875rem] dark:text-white text-colorTextLightMode">
              {el.name}
            </p>
          </div>
        );
      })}
    </div>
  );
}

export default DuplicateOrDeleteList;
