function AttenteDesign(): JSX.Element {
  return (
    <div className="w-full">
      <div className="sb-text-h3 w-[50%] text-center m-auto pt-[30px]">
        Attente Design
        <br />
        (We count on you Paul !)
      </div>
      <div className="m-auto w-[50%] h-[30%] text-center pt-[60px]">
        <iframe
          title="This is Paul"
          src="https://giphy.com/embed/HULqwwF5tWKznstIEE"
          width="480"
          height="270"
          frameBorder="0"
          className="giphy-embed w-full"
          allowFullScreen
        />
      </div>
    </div>
  );
}

export default AttenteDesign;
