import Logo from '@components/Logo';
import { AuthContext, InstallerContext } from '@auth/utils/authContext';
import { logout } from '@auth/utils/function';
import { useContext, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Divide as Hamburger } from 'hamburger-react';
import { v4 } from 'uuid';

function MenuMobile(): JSX.Element {
  const location = useLocation();
  const [openMenuBurger, setOpenMenuBurger] = useState<boolean>(false);

  const { updateToken } = useContext(AuthContext);
  const {
    installer,
    installerIsLoaded,
    updateInstallerIsLoaded,
    updateInstaller,
    isThemeDark,
    updateThemeChangedManually,
  } = useContext(InstallerContext);

  const openOrCloseMenuBurger = () => {
    setOpenMenuBurger(!openMenuBurger);
  };
  const darkModeLogoInstaller = isThemeDark
    ? installer.custom.logo_mb_light
    : installer.custom.logo_mb_dark;
  const marginLeftMenu = openMenuBurger ? 'ml-[0%]' : 'ml-[120%]';

  const nav = [
    {
      name: 'Tableau de bord',
      link: '/',
    },
    {
      name: 'Création de dossiers',
      link: '/demander-ma-prime',
    },
    {
      name: 'Import dossiers',
      link: '/import-dossiers',
    },
    {
      name: 'Boites à outils',
      link: '/boite-a-outils',
    },
    {
      name: 'FAQ',
      link: 'https://faq.drapo.com/',
    },
    {
      name: 'Mon profil',
      link: '/mon-profil',
    },
    {
      name: 'Déconnexion',
      link: '/',
    },
  ];
  return (
    <nav
      className={`${
        localStorage.getItem('isNewProfessionnalSpace') !== 'ok' ? 'hidden' : ''
      } navbar-device pl-[1.25rem] pr-[1rem] pt-[1.25rem]`}
    >
      <div className="flex justify-between items-center">
        <div style={{ zIndex: 60 }}>
          {installerIsLoaded && !location.pathname.includes('wl') && (
            <>
              {installer.nom_partenaire === 'DRAPO' ||
              installer.nom_partenaire === '' ? (
                <Logo />
              ) : (
                darkModeLogoInstaller.map((logo) => {
                  return (
                    <Link key={v4()} to="/">
                      <img
                        src={logo}
                        style={{ width: '8.125rem' }}
                        className="logo-drapo"
                        alt="logo"
                      />
                    </Link>
                  );
                })
              )}
            </>
          )}
        </div>
        <div style={{ zIndex: 60 }}>
          <Hamburger
            toggled={openMenuBurger}
            toggle={openOrCloseMenuBurger}
            color={isThemeDark ? 'white' : 'black'}
          />
        </div>
      </div>
      <div
        style={{ transition: 'all 0.5s' }}
        className={`w-screen pt-[5rem] fixed inset-0 z-50 h-full flex justify-center dark:bg-black bg-white text-center ${marginLeftMenu}`}
      >
        <ul className="w-full border-t mt-[1.25rem]">
          {nav.map((el) => {
            return (
              <li key={el.name} className="w-full">
                <Link to={el.link}>
                  <button
                    type="button"
                    className={`w-full mt-[1.25rem] border-b pb-[1.25rem] dark:text-white text-colorTextLightMode w-full ${
                      location.pathname === el.link
                        ? 'opacity-100'
                        : 'opacity-40'
                    }`}
                    onClick={() => {
                      if (el.name === 'Déconnexion') {
                        logout(
                          updateToken,
                          updateInstaller,
                          updateInstallerIsLoaded,
                          updateThemeChangedManually
                        );
                      }
                      openOrCloseMenuBurger();
                    }}
                  >
                    {el.name}
                  </button>
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
    </nav>
  );
}

export default MenuMobile;
