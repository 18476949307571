import { useContext } from 'react';
import { DashboardContext } from '@dashboard/utils/context/dashboardContext';
import { IQuotaCeeTab } from '@dashboard/utils/dashboardTypes';
import { v4 } from 'uuid';
import { InstallerContext } from '@auth/utils/authContext';
import { dark5 } from '@assets/colors';
import { convertHexToRGBA } from '@utils/utils';

function QuotaCEE(): JSX.Element {
  const { quotaCee } = useContext(DashboardContext);
  const { colorPrimary, radiusPx, isThemeDark } = useContext(InstallerContext);

  return (
    <>
      {quotaCee !== undefined && quotaCee.quotas !== undefined && (
        <>
          <div className="w-11/12  mb-[1.25rem] m-auto animate__animated animate__fadeIn dark:text-white text-colorTextLightMode ">
            <div
              style={{
                borderRadius: radiusPx,
              }}
              className="dark:bg-dark-4 bg-white p-[1rem] dark:border-none border border-borderLightModeborder-[1px]"
            >
              <div className="font-[500] text-[1rem] mb-[.5rem]">
                {quotaCee.title}
              </div>
              <>
                {quotaCee.quotas.map((quota: IQuotaCeeTab, index: number) => {
                  if (!quota.volume_max.includes('max. 0.00')) {
                    return (
                      <div
                        key={v4()}
                        className={`${
                          index !== quotaCee.quotas.length - 1 && 'mb-[1.25rem]'
                        }`}
                      >
                        <div className="flex items-center mb-[.375rem] justify-between text-[.875rem] opacity-[.92] leading-[1.75rem]">
                          <div>{quota.title}</div>
                          <div className="text-right">{quota.volume_max}</div>
                        </div>
                        <div
                          style={{
                            backgroundColor: isThemeDark
                              ? dark5
                              : convertHexToRGBA(colorPrimary, 0.1),
                          }}
                          className="w-full p-[.75rem] rounded-[8px] relative overflow-hidden"
                        >
                          <div
                            style={{
                              backgroundColor: convertHexToRGBA(
                                colorPrimary,
                                0.8
                              ),
                              zIndex: 0,
                              width: `${quota.percent}%`,
                            }}
                            className=" h-full absolute top-0 left-0"
                          />
                          <div className="flex items-center justify-between text-[.875rem]">
                            <div
                              style={{ zIndex: 2 }}
                              className="float-left  pl-[.75rem]"
                            >
                              {quota.volume}
                            </div>
                            <div
                              className="float-right pr-[.75rem]"
                              style={{ zIndex: 2 }}
                            >
                              {`${quota.percent}%`}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  }
                  return <></>;
                })}
              </>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default QuotaCEE;
