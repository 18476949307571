import axios from 'axios';
import { Dispatch, SetStateAction } from 'react';
import { Location, NavigateFunction } from 'react-router-dom';
import {
  arrayOfOperationsData,
  checkInitialChildrensConditions,
  constructIncomesContentArray,
  generalDatasFunction,
  initFilteredUnitsDatas,
  operationsDataFunction,
} from '@simulator/utils/functions';
import {
  IAdditionalInformationSendMailRecap,
  IBonusType,
  IGeneralDatasPostType,
  IGraphType,
  IIncomeType,
  IKeyValueStringType,
  ILatLng,
  IOperation,
  IOperationDatasType,
  IPrimesType,
  ISimulatorDatasType,
  IWhiteLabelCustomType,
} from '@simulator/utils/simulatorTypes';
import { toast } from 'react-toastify';
import { ICadastre } from '../utils/simulatorTypes';

const apiUrl = process.env.REACT_APP_BACK_PRO_API_URL;

// Recupère la liste des opérations
export const getAllOperation = (
  setData: Dispatch<SetStateAction<IOperation[]>>,
  setFilteredData: Dispatch<SetStateAction<IOperation[]>>,
  updateIncompatibles: Dispatch<SetStateAction<string[][]>>,
  setIsLoading: Dispatch<SetStateAction<boolean>>
) => {
  axios
    .get(`${apiUrl}/operation`)
    .then((response) => {
      const allOperations = response.data.operations;
      const incompatibles = response.data.incompatible;
      updateIncompatibles(incompatibles);
      setData(allOperations);
      setFilteredData(allOperations);
      setIsLoading(false);
    })
    .catch((error) => {
      console.log(error.response);
    });
};

// Récupère la structure du graph général
export const getGraphGeneral = (
  setGraphGeneral: Dispatch<SetStateAction<IGraphType>>,
  setGraphList: Dispatch<SetStateAction<IGraphType[]>>
) => {
  axios.get(`${apiUrl}/simulation/graph`).then((response) => {
    setGraphGeneral(response.data);
    setGraphList([response.data]);
  });
};

// Récupère la structure du graphOperation
export const getGraphOperation = (
  setLoaded: Dispatch<SetStateAction<boolean>>,
  setGraphOperation: Dispatch<SetStateAction<IGraphType>>,
  setGraphListOperation: Dispatch<SetStateAction<IGraphType[]>>,
  operation: IOperationDatasType,
  simulatorDatas: ISimulatorDatasType,
  setTotalCostAmountMin: Dispatch<SetStateAction<number>>
) => {
  const slugOperation =
    operation.id === 'bar-en-102-int' ? 'bar-en-102' : operation.id;

  axios.get(`${apiUrl}/operation/graph/${slugOperation}`).then((response) => {
    setLoaded(true);
    setTotalCostAmountMin(response.data.minAmount);
    const graphOperationFilter = checkInitialChildrensConditions(
      response.data.tree,
      simulatorDatas,
      operation.id,
      undefined,
      operation.material ? (operation.material as string) : undefined
    );
    if (graphOperationFilter) {
      setGraphOperation(graphOperationFilter);
      graphOperationFilter.operationId = operation.id;
      setGraphListOperation((oldList) => {
        if (oldList[0].id === '') {
          return [graphOperationFilter];
        }
        return [...oldList, graphOperationFilter];
      });
    }
  });
};

export const getIncomes = (
  datas: ISimulatorDatasType,
  setIncomesContent: Dispatch<SetStateAction<IIncomeType[] | null>>,
  updateLoaded: Dispatch<SetStateAction<boolean>>
) => {
  const datasPost = {
    works: {
      worksType: datas.works.worksType,
      worksStreet: datas.works.worksStreet,
      worksStreetNumber: datas.works.worksStreetNumber,
      worksZip: datas.works.worksZip,
      workCity: datas.works.worksCity,
    },
    persons: datas.persons,
  };

  axios.post(`${apiUrl}/simulation/incomes`, datasPost).then((response) => {
    const incomesContent = constructIncomesContentArray(response.data.incomes);
    setIncomesContent(incomesContent);
    updateLoaded(true);
  });
};

export const getParcelMap = (
  LatLng: ILatLng,
  setCoordinate: React.Dispatch<React.SetStateAction<ILatLng[]>>,
  updateMarkerMap: React.Dispatch<React.SetStateAction<boolean>>,
  updateCadastreDatas?: React.Dispatch<React.SetStateAction<ICadastre>>
) => {
  axios
    .get(
      `https://apicarto.ign.fr/api/cadastre/parcelle?geom=%7B%22type%22%3A%20%22Point%22%2C%22coordinates%22%3A%5B${LatLng.lng}%2C${LatLng.lat}%5D%7D`
    )
    .then((response) => {
      if (updateCadastreDatas) {
        if (response.data.features[0] !== undefined) {
          const data = `${response.data.features[0].properties.com_abs}-${response.data.features[0].properties.section}-${response.data.features[0].properties.numero}`;
          updateCadastreDatas({
            reference: data,
            latitude: LatLng.lat,
            longitude: LatLng.lng,
          });
        } else {
          updateCadastreDatas({
            reference: null,
            latitude: null,
            longitude: null,
          });
        }
      }
      const results = response.data.features[0].geometry.coordinates[0][0];
      const coordinatesCadastre = results.map((item: string) => {
        return { lat: item[0], lng: item[1] };
      });
      setCoordinate(coordinatesCadastre);
      updateMarkerMap(false);
    })
    .catch(() => {
      updateMarkerMap(true);
    });
};

// CALCUL DES PRIMES
export const calculatePrimes = (
  datas: ISimulatorDatasType,
  multipleOperations: IOperationDatasType[],
  updateOperationsList: Dispatch<SetStateAction<IOperationDatasType[]>>,
  updateLoaded: Dispatch<SetStateAction<boolean>>,
  eligibility: string[],
  location: Location,
  whiteLabel: string,
  updateErrorCalculationPrimes: Dispatch<SetStateAction<string | undefined>>,
  operationInEdit: boolean
) => {
  const operationsFormatted: IGeneralDatasPostType[][] = [];
  for (let i = 0; i < multipleOperations.length; i += 1) {
    const operation = multipleOperations[i];
    const operationFormatted = operationsDataFunction(operation);
    operationsFormatted.push(operationFormatted);
  }

  const datasPost = {
    general: generalDatasFunction(datas),
    operations: operationsFormatted,
  };

  const url =
    location.pathname === '/demander-ma-prime'
      ? '/simulation/primes'
      : `/wl/${whiteLabel}/simulation/primes`;
  axios
    .post(`${apiUrl}${url}`, datasPost)
    .then((response) => {
      // Ajout des primes à leurs opérations respectives
      const primesToPush = response.data;
      const operationsWithPrimes = [...multipleOperations];
      for (let i = 0; i < primesToPush.length; i += 1) {
        const bonusToPush: IBonusType | undefined =
          primesToPush[i].bonus &&
          (primesToPush[i].bonus.length > 0 ||
            Object.keys(primesToPush[i].bonus).length > 0)
            ? {}
            : undefined;
        if (primesToPush[i].bonus !== undefined) {
          if (bonusToPush) {
            Object.keys(primesToPush[i].bonus).forEach((key) => {
              bonusToPush[`calc-${key}`] = primesToPush[i].bonus[key];
            });
          }
        }
        primesToPush[i].calculated = {
          mpr: primesToPush[i].mpr,
          cdp: primesToPush[i].cdp,
          cee: primesToPush[i].cee,
          feesMpr: primesToPush[i].fees.MPR.fees || undefined,
          feesMprNoLimit: primesToPush[i].fees.MPR.feesNoLimit || undefined,
          bonus: bonusToPush,
        };
        if (
          !eligibility.includes('CEE') ||
          !operationsWithPrimes[i].eligibility.includes('CEE')
        ) {
          primesToPush[i].cee = 0;
          primesToPush[i].cdp = 0;
          if (primesToPush[i].cee_prime_beneficiary) {
            primesToPush[i].cee = primesToPush[i].cee_prime_beneficiary;
          }
          delete primesToPush[i].cee_prime_beneficiary;
        }
        if (
          !eligibility.includes('MPR') ||
          !operationsWithPrimes[i].eligibility.includes('MPR')
        ) {
          primesToPush[i].mpr = 0;
        }
        operationsWithPrimes[i].primes = primesToPush[i];
      }
      if (operationInEdit) {
        updateOperationsList((oldOperationsList) => {
          const newOperationsList = [...oldOperationsList];
          const indexIfAlreadyExist = newOperationsList.findIndex(
            (op) =>
              op.id === operationsWithPrimes[0].id &&
              op.name === operationsWithPrimes[0].name
          );
          if (indexIfAlreadyExist !== -1) {
            newOperationsList[indexIfAlreadyExist] = operationsWithPrimes[0];
          }
          return newOperationsList;
        });
      } else {
        updateOperationsList((oldOperationsList) => [
          ...oldOperationsList,
          ...operationsWithPrimes,
        ]);
      }
      updateLoaded(true);
    })
    .catch((error) => {
      console.log(error);
      updateLoaded(true);
      updateErrorCalculationPrimes(
        'Une erreur est survenue, merci de vous rapprocher de votre Responsable Technique.'
      );
    });
};

// CALCUL DES PRIMES SANS ECRETEMENT
export const calculatePrimesUncapped = (
  datas: ISimulatorDatasType,
  operationList: IOperationDatasType[],
  updateOperationsList: Dispatch<SetStateAction<IOperationDatasType[]>>,
  updateLoaded: Dispatch<SetStateAction<boolean>>,
  location: Location,
  whiteLabel: string,
  updateErrorCalculationPrimes: Dispatch<SetStateAction<string | undefined>>
) => {
  const operationsFormatted: IGeneralDatasPostType[][] = [];
  for (let i = 0; i < operationList.length; i += 1) {
    const operation = operationList[i];
    const operationFormatted = operationsDataFunction(operation);
    operationsFormatted.push(operationFormatted);
  }

  const datasPost = {
    general: generalDatasFunction(datas),
    operations: operationsFormatted,
    no_ecretement: 1,
  };

  const url =
    location.pathname === '/demander-ma-prime'
      ? '/simulation/primes'
      : `/wl/${whiteLabel}/simulation/primes`;
  axios
    .post(`${apiUrl}${url}`, datasPost)
    .then((response) => {
      // Ajout des primes à leurs opérations respectives
      const primesToPush = response.data;
      const operationsWithPrimes = [...operationList];
      for (let i = 0; i < primesToPush.length; i += 1) {
        const bonusToPush: IBonusType | undefined =
          primesToPush[i].bonus &&
          (primesToPush[i].bonus.length > 0 ||
            Object.keys(primesToPush[i].bonus).length > 0)
            ? {}
            : undefined;
        if (primesToPush[i].bonus !== undefined) {
          if (bonusToPush) {
            Object.keys(primesToPush[i].bonus).forEach((key) => {
              bonusToPush[`uncapped-${key}`] = primesToPush[i].bonus[key];
            });
          }
        }
        operationsWithPrimes[i].primes.mpr_uncapped = primesToPush[i].mpr;
        operationsWithPrimes[i].primes.bonus_uncapped = bonusToPush;
        operationsWithPrimes[i].primes.feesMprNoLimit_uncapped =
          primesToPush[i].fees.MPR.feesNoLimit;
        operationsWithPrimes[i].primes.feesMpr_uncapped =
          primesToPush[i].fees.MPR.fees;
      }

      updateOperationsList(operationsWithPrimes);
      updateLoaded(true);
    })
    .catch((error) => {
      console.log(error);
      updateLoaded(true);
      updateErrorCalculationPrimes(
        'Une erreur est survenue, merci de vous rapprocher de votre Responsable Technique.'
      );
    });
};

// RECUPERE LA LISTE DU MATERIEL
export const getMaterialsScopeUnit = (
  setMaterialsList: Dispatch<SetStateAction<IKeyValueStringType[]>>,
  operationSlug: string
) => {
  axios.get(`${apiUrl}/material/search/${operationSlug}`).then((response) => {
    setMaterialsList(initFilteredUnitsDatas(response.data));
  });
};

// RECUPERE LES INFOS DE CUSTOMISATIONS POUR LES URL WHITELABEL
export const getWhiteLabel = (
  whiteLabel: string,
  setWhiteLabelCustom: Dispatch<SetStateAction<IWhiteLabelCustomType>>,
  router: NavigateFunction,
  setLoadedWhiteLabel: Dispatch<SetStateAction<boolean>>,
  setGraphGeneral: Dispatch<SetStateAction<IGraphType>>,
  setGraphList: Dispatch<SetStateAction<IGraphType[]>>
) => {
  axios.get(`${apiUrl}/wl/${whiteLabel}`).then((response) => {
    if (response.data === undefined) {
      router('/demander-ma-prime', { replace: true });
      setLoadedWhiteLabel(true);
      getGraphGeneral(setGraphGeneral, setGraphList);
    } else {
      setWhiteLabelCustom(response.data);
      setLoadedWhiteLabel(true);
    }
  });
};

// Récupère la structure du graph général pour les urls whiteLabel
export const getGraphGeneralWhiteLabel = (
  setGraphGeneral: Dispatch<SetStateAction<IGraphType>>,
  setGraphList: Dispatch<SetStateAction<IGraphType[]>>,
  whiteLabel: string
) => {
  axios.get(`${apiUrl}/wl/${whiteLabel}/simulation/graph`).then((response) => {
    setGraphGeneral(response.data);
    setGraphList([response.data]);
  });
};

// ENVOI MAIL RECAP SUITE SIMULATION
export const sendSummaryByMail = (
  datas: ISimulatorDatasType,
  operations: IOperationDatasType[],
  ceeIsSelect: boolean,
  mprIsSelect: boolean,
  cadastresData: ICadastre,
  arrayMails: string[],
  additionalInformation: boolean,
  additionalInformationData: IAdditionalInformationSendMailRecap,
  whiteLabel: string,
  setIsLoading: Dispatch<SetStateAction<boolean>>,
  setisSendEmailModalOpen: Dispatch<SetStateAction<boolean>>,
  router: NavigateFunction,
  setErrorForm: Dispatch<SetStateAction<string>>
) => {
  setIsLoading(true);
  const primesToPush: IPrimesType[] = [];
  const operationsToPush: IOperationDatasType[] = [];
  for (let i = 0; i < operations.length; i += 1) {
    const primeToPush = operations[i].primes;
    if (!ceeIsSelect) {
      primeToPush.cee = 0;
      primeToPush.cdp = 0;
    }
    if (!mprIsSelect) {
      primeToPush.mpr = 0;
    }
    if (
      primeToPush.cee !== 0 ||
      primeToPush.cdp !== 0 ||
      primeToPush.mpr !== 0
    ) {
      primesToPush.push(primeToPush);
      operationsToPush.push(operations[i]);
    }
  }

  const noCadastre =
    Object.values(cadastresData).includes(null) ||
    Object.values(cadastresData).includes(0) ||
    Object.values(cadastresData).includes('');

  let dataPost = {};

  if (additionalInformation) {
    if (!noCadastre) {
      dataPost = {
        general: generalDatasFunction(datas),
        operations: arrayOfOperationsData(operationsToPush),
        primes: primesToPush,
        cadastre: cadastresData,
        mpr: mprIsSelect ? 1 : 0,
        cee: ceeIsSelect ? 1 : 0,
        to: arrayMails,
        contact: additionalInformationData,
      };
    } else {
      dataPost = {
        general: generalDatasFunction(datas),
        operations: arrayOfOperationsData(operationsToPush),
        primes: primesToPush,
        mpr: mprIsSelect ? 1 : 0,
        cee: ceeIsSelect ? 1 : 0,
        to: arrayMails,
        contact: additionalInformationData,
      };
    }
  } else if (!noCadastre) {
    dataPost = {
      general: generalDatasFunction(datas),
      operations: arrayOfOperationsData(operationsToPush),
      primes: primesToPush,
      cadastre: cadastresData,
      mpr: mprIsSelect ? 1 : 0,
      cee: ceeIsSelect ? 1 : 0,
      to: arrayMails,
    };
  } else {
    dataPost = {
      general: generalDatasFunction(datas),
      operations: arrayOfOperationsData(operationsToPush),
      primes: primesToPush,
      mpr: mprIsSelect ? 1 : 0,
      cee: ceeIsSelect ? 1 : 0,
      to: arrayMails,
    };
  }

  axios
    .post(`${apiUrl}/wl/${whiteLabel}/email`, dataPost)
    .then(() => {
      setErrorForm('');
      setIsLoading(false);
      setisSendEmailModalOpen(false);
      toast('Email envoyé avec succès');
      router(`/wl/${whiteLabel}/demander-ma-prime`, { replace: true });
    })
    .catch((err) => {
      setIsLoading(false);
      setErrorForm('Une erreur est survenue');
      console.log(err.response);
    });
};
