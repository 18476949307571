import { InstallerContext } from '@auth/utils/authContext';
import { useContext } from 'react';

function LoaderInputNumber(): JSX.Element {
  const { radiusPx } = useContext(InstallerContext);

  return (
    <div className="w-full">
      <div
        style={{ borderRadius: radiusPx }}
        className="w-full px-[4rem] flex items-center justify-between animate__flash animate__animated animate__slower h-[5.625rem] relative dark:bg-dark-1 bg-lightMode-dark-1 flex-row mt-[1.5rem] p-[1.25rem]"
      >
        <div className="w-[1.25rem] h-[1.25rem] bg-dark-light-1 rounded-full animate__flash animate__animated animate__slower" />
        <div className="w-[1.25rem] h-[1.25rem] bg-dark-light-1 rounded-full animate__flash animate__animated animate__slower" />
      </div>
    </div>
  );
}

export default LoaderInputNumber;
