import { Dispatch, SetStateAction, useContext } from 'react';
import { InstallerContext } from '@auth/utils/authContext';
import { LayoutDashboardContext } from '@dashboard/utils/context/LayoutDashboardContext';
import {
  ChevronIcon,
  NotificationsIcon,
} from '@assets/svgComponents/svgComponents';
import { convertHexToRGBA } from '@utils/utils';
import { darkLight1 } from '@assets/colors';

interface IButtonDDCProps {
  isRetractable: boolean;
  setIsRetractable: Dispatch<SetStateAction<boolean>>;
}

function ButtonDDC({
  isRetractable,
  setIsRetractable,
}: IButtonDDCProps): JSX.Element {
  const { radiusPx, isThemeDark, colorPrimary } = useContext(InstallerContext);
  const { updateIsRetractableDDC } = useContext(LayoutDashboardContext);

  const radius = radiusPx === '0px' ? '0px' : '8px';

  return (
    <button
      className="flex items-start opacity-80 hover:opacity-100 space-x-[1rem]"
      type="button"
      onClick={() => {
        setIsRetractable(!isRetractable);
        updateIsRetractableDDC(!isRetractable);
      }}
    >
      <ChevronIcon
        width="1.5rem"
        height="1.5rem"
        fill={isThemeDark ? 'white' : colorPrimary}
        style={{
          backgroundColor: convertHexToRGBA(
            isThemeDark ? darkLight1 : colorPrimary,
            0.1
          ),
          borderRadius: radius,
        }}
        className="transition-transform duration-[750ms]"
        transform={isRetractable ? 'rotate(180)' : 'rotate(0)'}
      />
      <NotificationsIcon
        width="1.5rem"
        height="1.5rem"
        fill={isThemeDark ? 'white' : colorPrimary}
      />
    </button>
  );
}

export default ButtonDDC;
