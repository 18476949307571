/* TOUJOURS S'ASSURER DE LA CORRESPONDANCE AVEC tailwind.config.js */
const white = '#FFFFFF';
const purple1 = '#5D6AD2';
const purple1Opaque = '#5D6AD21C';
const red1 = '#A60028';
const red2 = '#8E1322';
const red3 = '#991B2C';
const green1 = '#2ECC71';

// ** LIGHT MODE ** //
const backgroundBodyLightMode = '#EEEEF1';
const borderLightMode = '#E1E1E1';
const borderLightMode2 = '#D2D2D2';
const backgroundSecondaryLightMode = '#FBFBFB';
const backgroundTertiaryLightMode = '#F8F8F8';
const colorTextLightMode = '#202020';
const backgroundQuaternaryLightMode = '#F1F2F5';
const lightModeDarkVariant5 = '#8F8F8F';

// ** DARK MODE ** //
const dark1 = '#191919';
const dark2 = '#131313';
const dark3 = '#1D1D1D';
const dark4 = '#313030';
const dark4Opaque = 'rgba(49; 48; 48; 0.7)';
const dark5 = '#272727';
const dark6 = '#707070';
const darkVariant1 = '#202020';
const darkVariant2 = '#2D2C3D';
const darkLight1 = '#C6C6C6';
const borderDarkMode = '#313030';

export {
  dark1,
  dark2,
  dark3,
  dark4,
  dark4Opaque,
  dark5,
  dark6,
  darkVariant1,
  darkVariant2,
  darkLight1,
  purple1,
  purple1Opaque,
  red1,
  red2,
  red3,
  lightModeDarkVariant5,
  white,
  green1,
  backgroundBodyLightMode,
  borderLightMode,
  borderLightMode2,
  backgroundSecondaryLightMode,
  backgroundTertiaryLightMode,
  backgroundQuaternaryLightMode,
  colorTextLightMode,
  borderDarkMode,
};
