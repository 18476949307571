import { InstallerContext } from '@auth/utils/authContext';
import { useContext } from 'react';

function LoaderSimulator(): JSX.Element {
  const { radiusPx } = useContext(InstallerContext);

  const array = [1, 2, 3];
  return (
    <>
      <div
        style={{ borderRadius: radiusPx }}
        className="dark:bg-dark-4 bg-lightMode-dark-4 animate__flash animate__animated animate__slower h-[6.25rem] pt-[1.5rem] pl-[1.5rem]  w-full"
      >
        <div className=" bg-dark-light-1 h-[1.5rem] opacity-80 w-6/12 rounded-[6px] " />
      </div>
      <div
        style={{ borderRadius: radiusPx }}
        className="w-full dark:bg-dark-4 bg-lightMode-dark-4 flex-row mt-[1.5rem]  px-[1.25rem] py-[1.25rem]"
      >
        {array.map((arr) => {
          return (
            <div
              style={{ borderRadius: radiusPx }}
              key={arr}
              className={`flex animate__flash animate__animated animate__slower items-center dark:bg-dark-1 bg-lightMode-dark-1 px-[.5rem] mb-[.75rem] py-[.5rem] w-full flex-row items-center `}
            >
              <div
                style={{ borderRadius: radiusPx }}
                className={`w-[5.938rem] h-[5.938rem] bg-dark-light-1 `}
              />
              <div className="w-5/12 space-y-[.75rem] ml-[1.5rem] rounded-[6px] ">
                <div className="w-10/12 h-[1rem] bg-dark-light-1 opacity-60 h-[1.5rem] rounded-[6px] " />
                <div className="w-5/12 h-[1rem] bg-dark-light-1 h-[1.5rem] opacity-60 rounded-[6px] " />
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}

export default LoaderSimulator;
