/* eslint-disable no-nested-ternary */
import { colorTextLightMode, white } from '@assets/colors';
import { DashboardContext } from '@dashboard/utils/context/dashboardContext';
import { convertHexToRGBA } from '@utils/utils';
import React, { useContext, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { InstallerContext } from '../../../../auth/utils/authContext';

interface IProps {
  data: string;
}
function ListLinksDropdown({ data }: IProps): JSX.Element {
  const location = useLocation();
  const page = location.pathname;

  const [isShown, setIsShown] = useState(false);

  const {
    elementActive,
    updateElementActive,
    updateActiveCustomView,
    updateCurrentListCustomView,
    updateNewConditions,
    updateCeeStatusFilter,
    updateMprStatusFilter,
    updateRowsSelectedFolders,
  } = useContext(DashboardContext);
  const { isThemeDark, colorPrimary, colorTextButton } =
    useContext(InstallerContext);

  const gotToDashboard = () => {
    updateCeeStatusFilter(['']);
    updateMprStatusFilter(['']);
    updateRowsSelectedFolders([]);
    updateActiveCustomView(false);
    updateCurrentListCustomView(undefined);
    updateNewConditions([]);
  };

  const colorText = isThemeDark
    ? white
    : colorTextButton === 'white'
    ? colorPrimary
    : colorTextLightMode;
  return (
    <Link to="/" state={{ prevPath: `${data}-home` }}>
      <button
        onMouseEnter={() => setIsShown(true)}
        onMouseLeave={() => setIsShown(false)}
        style={{
          color:
            (elementActive === data || isShown) && !isThemeDark
              ? colorText
              : (elementActive !== data || !isShown) && !isThemeDark
              ? convertHexToRGBA(colorTextLightMode, 0.5)
              : 'white',
        }}
        type="button"
        className={`dark:hover:opacity-100 w-full pl-[3.25rem] text-left text-[.75rem] leading-[1.5rem] font-normal whitespace-nowrap ${
          elementActive === data ? 'dark:opacity-100' : 'dark:opacity-40'
        }`}
        onClick={() => {
          if (page === '/') {
            updateElementActive(data);
            gotToDashboard();
          }
        }}
      >
        {data}
      </button>
    </Link>
  );
}

export default ListLinksDropdown;
