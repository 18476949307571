import { useNavigate, useParams } from 'react-router-dom';
import { useContext, useEffect } from 'react';
import { autoLoginRequest } from '@auth/apiRequests/AuthRequests';
import { AuthContext, InstallerContext } from '@auth/utils/authContext';
import LoaderDashboard from '@components/loaders/LoaderDashboard';
import { logout } from '@auth/utils/function';

function Autologin() {
  const urlToken: string = useParams().url_token || '';
  const router = useNavigate();
  const { updateToken, updateTokenConnected, token } = useContext(AuthContext);
  const {
    updateInstaller,
    updateInstallerIsLoaded,
    updateThemeChangedManually,
  } = useContext(InstallerContext);

  useEffect(() => {
    if (urlToken !== '') {
      if (token !== '') {
        logout(
          updateToken,
          updateInstaller,
          updateInstallerIsLoaded,
          updateThemeChangedManually,
          true
        );
      } else {
        autoLoginRequest(urlToken, updateToken, router, updateTokenConnected);
      }
    } else {
      router('/');
    }
  }, []);

  return (
    <div className="absolute h-[100vh] w-[100vw] top-0 left-0 flex items-center justify-center overflow-hidden">
      <LoaderDashboard />
    </div>
  );
}

export { Autologin };
