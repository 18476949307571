import { alert } from '@assets/images/images';
import { deleteFolderAirtable } from '@dashboard/apiRequests/dashboardRequests';
import { useContext } from 'react';
import { v4 } from 'uuid';
import { InstallerContext } from '@auth/utils/authContext';
import { useNavigate } from 'react-router-dom';
import { deleteOperationFolder } from '@folderMonitoring/apiRequest/folderMonitoringRequest';
import { CloseIcon } from '@assets/svgComponents/svgComponents';
import { FolderMonitoringContext } from '../utils/context/folderMonitoringContext';

function DeleteFolder(): JSX.Element {
  const router = useNavigate();
  const {
    folderActif,
    updateIsModalDeleteFolder,
    isLoading,
    updateIsLoading,
    folderDatas,
    deleteOperationOrFolder,
    operationActive,
    updateFolderDatas,
  } = useContext(FolderMonitoringContext);

  const { radiusPx, installer } = useContext(InstallerContext);

  const deleteFolderOrOperation = () => {
    if (
      deleteOperationOrFolder === 'folder' ||
      folderDatas.nb_operations === 1
    ) {
      deleteFolderAirtable(
        folderActif[0].référence_dossier,
        updateIsLoading,
        updateIsModalDeleteFolder,
        router
      );
    } else {
      deleteOperationFolder(
        installer.eligibility,
        folderActif[0].référence_dossier,
        operationActive.référence_opération,
        updateFolderDatas,
        router,
        updateIsLoading,
        updateIsModalDeleteFolder
      );
    }
  };

  return (
    <div
      style={{ borderRadius: radiusPx }}
      className="bg-[#A60028] absolute bottom-0 left-[1.875rem] p-[1.875rem] text-[.75rem] pb-[1.25rem]"
    >
      <div className="flex justify-between items-center">
        <div className="flex">
          <img className="!w-[1.25rem] mr-[1rem]" src={alert} alt="attention" />
          {deleteOperationOrFolder === 'folder' ? (
            <p>Suppression du dossier</p>
          ) : (
            <p>Suppression d&apos;une opération</p>
          )}
        </div>
        <button type="button" onClick={() => updateIsModalDeleteFolder(false)}>
          <CloseIcon width="1rem" height="1rem" fill="white" />
        </button>
      </div>
      {deleteOperationOrFolder === 'folder' ? (
        <div>
          <p className="mt-[.625rem]">
            Vous êtes sur le point de supprimer le dossier contenant les
            opérations :
          </p>
          {folderDatas.operations.map((op: any) => {
            return (
              <div key={v4()} className="flex items-center">
                <div className="w-[.25rem] h-[.25rem] bg-white rounded-[100%] mr-[.5rem] mt-[.625rem]" />
                <p className="mt-[.625rem]">
                  {op.libellé_opération
                    .replace('{', '')
                    .replace('}', '')
                    .replace('"', '')
                    .replace('"', '')}
                </p>
              </div>
            );
          })}
        </div>
      ) : (
        <div>
          <p className="mt-[.625rem]">
            Vous êtes sur le point de supprimer l&apos;opération :
          </p>
          <p className="mt-[.625rem]">
            {operationActive.libellé_opération
              .replace('{', '')
              .replace('}', '')
              .replace('"', '')
              .replace('"', '')}
          </p>
        </div>
      )}
      <button
        style={{
          borderRadius: radiusPx === '16px' ? '8px' : '0px',
          opacity: isLoading ? 0.5 : 1,
        }}
        disabled={isLoading}
        type="button"
        className="bg-[white] text-[#A60028] mt-[.625rem] p-[.625rem] w-full hover:bg-[#A60028] hover:text-[white] hover:border"
        onClick={() => {
          deleteFolderOrOperation();
        }}
      >
        Confirmer la suppression
      </button>
    </div>
  );
}

export default DeleteFolder;
