import { useContext, useState } from 'react';
import { Button, Input } from 'storybook-design-system-drapo';
import LoaderInputNumber from '@components/loaders/LoaderInputNumber';
import { InstallerContext } from '@auth/utils/authContext';
import { nextStepGeneral, nextStepOperation } from '@simulator/utils/functions';
import { SimulatorContext } from '@simulator/utils/simulatorContext';
import { useLocation } from 'react-router-dom';

function InputThermalResistance(): JSX.Element {
  const location = useLocation();
  const {
    simulatorDatas,
    updateSimulatorDatas,
    graphGeneral,
    updateGraphGeneral,
    updateGraphList,
    updateStepNumber,
    updateGeneralComplete,
    generalComplete,
    updateGraphOperation,
    updateGraphListOperation,
    graphOperation,
    updateCurrentOperation,
    currentOperation,
    loaded,
    multipleOperations,
  } = useContext(SimulatorContext);
  const { colorPrimary, radiusPx, isThemeDark, colorTextButton } =
    useContext(InstallerContext);

  // const graphActive = generalComplete ? graphOperation : graphGeneral;

  const [amount, setAmount] = useState<string>('7');

  const onClickNext = () => {
    if (generalComplete) {
      nextStepOperation(
        simulatorDatas,
        currentOperation,
        updateStepNumber,
        updateCurrentOperation,
        updateGraphOperation,
        updateGraphListOperation,
        graphOperation,
        graphOperation.id,
        amount.toString()
      );
    } else {
      nextStepGeneral(
        simulatorDatas,
        multipleOperations,
        updateGeneralComplete,
        updateStepNumber,
        updateSimulatorDatas,
        updateGraphGeneral,
        updateGraphList,
        graphGeneral,
        graphGeneral.id,
        amount.toString()
      );
    }
  };

  return (
    <div>
      {!loaded ? (
        <LoaderInputNumber />
      ) : (
        <div className="animate__animated animate__fadeIn">
          <div
            className="mt-[1.5rem]"
            style={{
              borderRadius: radiusPx,
              border:
                location.search.includes('?integration=iframe') && !isThemeDark
                  ? '1px solid #d2d2d2'
                  : '',
            }}
          >
            <Input
              backgroundColor="#191919"
              color="#FFFFFF"
              label="Résistance thermique (m².K/W)"
              onClick={() => {
                if (amount === '0') {
                  setAmount('');
                }
              }}
              onChange={(e) => setAmount(e.target.value)}
              value={amount}
              inputType="text"
              dataTestId="inputNumber"
            />
          </div>
          <div className="w-full mt-[1.5rem]">
            <Button
              borderRadius={radiusPx}
              disabled={!(Number(amount) > 0)}
              buttonArrowSmall
              label="Suivant"
              color={colorTextButton}
              backgroundColor={colorPrimary}
              arrow
              addClass="!w-full !px-[1rem]"
              onClick={onClickNext}
              dataTestId="next"
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default InputThermalResistance;
