import { InstallerContext } from '@auth/utils/authContext';
import { IMyProfilDatas } from '@dashboard/utils/dashboardTypes';
import { Dispatch, SetStateAction, useContext } from 'react';
import { MyProfileContext } from '@dashboard/utils/context/myProfileContext';
import { colorTextLightMode, dark2, purple1, white } from '@assets/colors';
import { DownloadIcon, TrashIcon } from '@assets/svgComponents/svgComponents';
import { deleteFileMyProfile } from '@dashboard/apiRequests/dashboardRequests';

interface IProps {
  document: any;
  title: string;
  setDatasMyProfile: Dispatch<SetStateAction<IMyProfilDatas>>;
  titleCard: string;
  documentsOther: string[] | null;
}

function OneDocument({
  document,
  title,
  titleCard,
  setDatasMyProfile,
  documentsOther,
}: IProps): JSX.Element {
  const { installer, radiusPx, isThemeDark, colorTextButton } =
    useContext(InstallerContext);
  const {
    documentUrlActive,
    updateDocumentUrlActive,
    updateIsUpdateLoading,
    updatePageNumberViewDocument,
  } = useContext(MyProfileContext);

  const background =
    installer.nom_partenaire === 'DRAPO' || installer.nom_partenaire === ''
      ? purple1
      : installer.custom.couleur_boutons;

  const backgroundActive = () => {
    let color = '';
    if (document === documentUrlActive) {
      color = background;
    } else if (isThemeDark) {
      color = dark2;
    } else {
      color = white;
    }
    return color;
  };

  const colorActive = () => {
    let color = '';
    if (document === documentUrlActive) {
      color = colorTextButton;
    } else if (isThemeDark) {
      color = white;
    } else {
      color = colorTextLightMode;
    }
    return color;
  };

  const updateRightPart = () => {
    updateDocumentUrlActive(document);
  };

  const deleteDocument = () => {
    const fieldName = titleCard === 'RGE' ? 'rge' : 'contrats_sous_traitance';
    deleteFileMyProfile(
      fieldName,
      document,
      setDatasMyProfile,
      updateIsUpdateLoading,
      updateDocumentUrlActive
    );
  };
  const radius = radiusPx === '0px' ? '0px' : '8px';

  return (
    <div
      style={{
        backgroundColor: backgroundActive(),
        borderRadius: radius,
        color: colorActive(),
      }}
      className="px-[1.25rem] py-[.625rem]  flex items-center w-full justify-between mb-[.625rem] dark:border-none border"
    >
      <button
        type="button"
        onClick={() => {
          updateRightPart();
          updatePageNumberViewDocument(1);
        }}
        className="sb-text-rem-caption1 !font-medium w-full text-left"
      >
        {title}
      </button>
      <div className="flex">
        {document !== '' && (
          <>
            {documentsOther !== null && documentsOther.length > 1 && (
              <button
                type="button"
                className="mr-2"
                onClick={() => deleteDocument()}
              >
                <TrashIcon
                  fill={colorActive()}
                  width="1.5rem"
                  height="1.5rem"
                />
              </button>
            )}
            <a target="_blank" href={document} rel="noreferrer">
              <DownloadIcon
                fill={colorActive()}
                width="1.5rem"
                height="1.5rem"
              />
            </a>
          </>
        )}
      </div>
    </div>
  );
}

export default OneDocument;
