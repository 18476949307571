import { Navigate, Route, Routes } from 'react-router-dom';
import { useContext } from 'react';
import Admin from '@pages/Admin';
import MyProfile from '@pages/MyProfile';
import Login from '@pages/Login';
import Simulator from '@pages/Simulator';
import Dashboard from '@pages/Dashboard';
import Folder from '@pages/Folder';
import AttenteDesign from '@pages/AttenteDesign';
import BulkImport from '@pages/BulkImport';
import FolderMonitoring from '@pages/FolderMonitoring';
import { Autologin } from '@auth/components/Autologin';
import { AuthContext } from '@auth/utils/authContext';
import TestIframe from './TestIframe';
import PasswordForgotten from './pages/PasswordForgotten';
import ResetPassword from './pages/ResetPassword';

function Router(): JSX.Element {
  const { token } = useContext(AuthContext);
  return (
    <Routes>
      <Route
        path="/connexion"
        element={!token ? <Login /> : <Navigate to="/" />}
      />
      <Route path="/autologin/:url_token" element={<Autologin />} />
      <Route path="/password-forgotten" element={<PasswordForgotten />} />
      <Route
        path="/wl/:marque/password-forgotten"
        element={!token ? <PasswordForgotten /> : <Navigate to="/" />}
      />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route path="/wl/:marque/demander-ma-prime" element={<Simulator />} />
      <Route path="/wl/:marque" element={<Simulator />} />
      <Route
        path="/wl/:marque/connexion"
        element={!token ? <Login /> : <Navigate to="/" />}
      />
      <Route
        path="/demander-ma-prime"
        element={token ? <Simulator /> : <Navigate to="/connexion" />}
      />
      <Route
        path="/informations-complementaires/:folderReference"
        element={token ? <Folder /> : <Navigate to="/connexion" />}
      />
      <Route
        path="/"
        element={token ? <Dashboard /> : <Navigate to="/connexion" />}
      />
      <Route
        path="/suivi-de-dossier/:folderReference"
        element={token ? <FolderMonitoring /> : <Navigate to="/connexion" />}
      />
      <Route
        path="/admin"
        element={token ? <Admin /> : <Navigate to="/connexion" />}
      />
      <Route
        path="/import-dossiers"
        element={token ? <BulkImport /> : <Navigate to="/connexion" />}
      />
      <Route
        path="/mon-profil"
        element={token ? <MyProfile /> : <Navigate to="/connexion" />}
      />
      <Route path="*" element={<Navigate to="/" />} />
      <Route path="/attente-design" element={<AttenteDesign />} />
      <Route path="/test-iframe/:wl" element={<TestIframe />} />
    </Routes>
  );
}

export default Router;
