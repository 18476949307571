import { getInstallerNotifications } from '@dashboard/apiRequests/dashboardRequests';
import { useContext, useEffect, useState } from 'react';

import { AuthContext, InstallerContext } from '@auth/utils/authContext';
import { DashboardContext } from '@dashboard/utils/context/dashboardContext';
import {
  INbNotifs,
  IInstallerNotificationCeeMprOrAll,
} from '@dashboard/utils/dashboardTypes';
import { initialNbNotifs } from '@simulator/utils/initialsState';
import { LayoutDashboardContext } from '@dashboard/utils/context/LayoutDashboardContext';
import { HeaderRequestForSupplements } from './HeaderRequestForSupplements';
import { NavRequestForSupplements } from './NavRequestForSupplements';
import ListCardsFolders from './ListCardsFolders';

function RequestForSupplements(): JSX.Element {
  const { installerNotification, updateInstallerNotification, radiusPx } =
    useContext(InstallerContext);
  const { elementActive } = useContext(DashboardContext);
  const { token } = useContext(AuthContext);
  const { isRetractableDDC, updateIsRetractableDDC } = useContext(
    LayoutDashboardContext
  );

  const [isRetractable, setIsRetractable] = useState<boolean>(false);
  const [activeElement, setActiveElement] = useState<string>('Toutes');
  const [nbNotifs, setNbNotifs] = useState<INbNotifs>(initialNbNotifs);

  useEffect(() => {
    if (installerNotification === null) {
      getInstallerNotifications(
        updateInstallerNotification,
        setNbNotifs,
        updateIsRetractableDDC,
        setIsRetractable
      );
    } else {
      setNbNotifs({
        cee:
          installerNotification.ddc.cee.length +
            installerNotification.ddc.cee_mpr.length || 0,
        mpr:
          installerNotification.ddc.mpr.length +
            installerNotification.ddc.cee_mpr.length || 0,
        toutes:
          installerNotification.ddc.cee_mpr.length +
            installerNotification.ddc.mpr.length +
            installerNotification.ddc.cee.length || 0,
      });
    }
  }, [installerNotification]);

  useEffect(() => {
    updateInstallerNotification(null);
    setNbNotifs(initialNbNotifs);
  }, [token]);

  const tabAll = (ddcType?: string) => {
    if (installerNotification) {
      const tab = Object.entries(installerNotification.ddc);
      const result: IInstallerNotificationCeeMprOrAll[] = [];

      tab.forEach(([key, value]) => {
        for (let i = 0; i < value.length; i += 1) {
          if (
            (ddcType && key.includes('cee_mpr')) ||
            (ddcType && key.includes(ddcType)) ||
            !ddcType
          ) {
            result.push(value[i]);
          }
        }
      });
      return result;
    }
    return undefined;
  };

  const propsSendToListCardsFolders = () => {
    switch (activeElement) {
      case 'Toutes':
        return tabAll();
      case 'CEE':
        return tabAll('cee');
      case 'MPR':
        return tabAll('mpr');
      default:
        return [];
    }
  };

  return (
    <div
      style={{ transition: 'width 1s' }}
      className={`${isRetractableDDC ? 'lg:w-1/12' : 'lg:w-3/12'}
       hidden overflow-hidden ${
         elementActive === 'Documents' ? 'xl:block' : 'lg:block'
       }`}
    >
      <HeaderRequestForSupplements
        elementActive={activeElement}
        setElementActive={setActiveElement}
        nbNotifs={nbNotifs}
        isRetractable={isRetractable}
        setIsRetractable={setIsRetractable}
      />
      {isRetractable ? (
        <div
          style={{ borderBottomRightRadius: radiusPx }}
          className="w-full pt-[1.25rem] dark:bg-dark-1 h-[82vh] bg-backgroundSecondaryLightMode"
        >
          <NavRequestForSupplements
            elementActive={activeElement}
            setElementActive={setActiveElement}
            nbNotifs={nbNotifs}
            isRetractable={isRetractable}
            setIsRetractable={setIsRetractable}
          />
        </div>
      ) : (
        <ListCardsFolders data={propsSendToListCardsFolders()} />
      )}
    </div>
  );
}

export default RequestForSupplements;
