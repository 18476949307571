function ErrorAutologin(): JSX.Element {
  return (
    <div
      style={{ zIndex: 22223333 }}
      className="absolute left-0 top-0 text-white lg:text-[1rem] text-center bg-red-1 w-full py-[.25rem] z-[33333]"
    >
      <p>
        La connexion automatique a échoué, merci de saisir les identifiants du
        compte à connecter.
      </p>
    </div>
  );
}

export { ErrorAutologin };
