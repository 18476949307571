/* eslint-disable no-nested-ternary */
import { useContext } from 'react';
import { DashboardContext } from '@dashboard/utils/context/dashboardContext';
import {
  arrowLeft_black,
  arrowLeft,
  editPencilBlack,
  editPencilWhite,
} from '@assets/images/images';

import { InstallerContext } from '@auth/utils/authContext';
import { DASHBOARD_TABS } from '@dashboard/utils/tabs';
import { purple1 } from '@assets/colors';
import Loader from '@components/loaders/Loader';
import SearchFolder from '../searchFolder/SearchFolder';

function FoldersHeader(): JSX.Element {
  const {
    updateElementActive,
    updateActiveCustomView,
    elementActive,
    isModalPersonalizationFolders,
    updateIsModalPersonalizationFolders,
    isDeleteFolderLoading,
    countsFolders,
    isLoadedFolders,
  } = useContext(DashboardContext);

  const { radiusPx, installer, isThemeDark } = useContext(InstallerContext);

  const handleClick = () => {
    updateElementActive(DASHBOARD_TABS.SUMMARY);
    updateActiveCustomView(false);
  };

  const radiusTop = radiusPx === '0px' ? `0px` : '16px';
  const radius = radiusPx === '0px' ? `0px` : '8px';
  return (
    <div
      style={{
        borderTopLeftRadius: radiusTop,
      }}
      className={`pt-[2.25rem] w-full dark:bg-dark-5  bg-white dark:text-white text-dark-2 px-[2rem] w-full h-home-header dark:border-none border-b border-b-borderLightMode border-b-[1px] `}
    >
      <div className="flex items-center space-x-[.875rem] w-full mb-[.375rem] relative">
        <div
          className="cursor-pointer "
          onClick={handleClick}
          onKeyDown={undefined}
          role="button"
          tabIndex={0}
        >
          <img
            className="w-[1rem]"
            src={isThemeDark ? arrowLeft : arrowLeft_black}
            alt="arrow-left"
          />
        </div>

        <p className="text-[1.875rem]">
          {elementActive === DASHBOARD_TABS.FOLDERS_INSTRUCTION
            ? DASHBOARD_TABS.FOLDERS_INSTRUCTION
            : DASHBOARD_TABS.FOLDERS_SIMULATION}
        </p>
        <div className="absolute right-0 top-[-1rem] w-5/12 z-[99999]">
          <SearchFolder />
        </div>
      </div>

      <div className="flex items-center justify-between w-full">
        <button
          onClick={() => {
            updateIsModalPersonalizationFolders(!isModalPersonalizationFolders);
          }}
          className="border px-[1rem] py-[.5rem]"
          type="button"
          style={{
            borderRadius: radius,
            borderColor: isModalPersonalizationFolders
              ? installer.nom_partenaire === 'DRAPO' ||
                installer.nom_partenaire === ''
                ? purple1
                : installer.custom.couleur_boutons
              : isThemeDark
              ? 'rgba(255,255,255,0.15)'
              : 'rgba(0,0,0,0.15)',
          }}
        >
          <div className="flex space-x-[.625rem] items-center">
            <div className="text-[.75rem] dark:text-white text-dark-2">
              Personnaliser
            </div>
            <img
              className="ml-[1.25rem] w-[1rem]"
              src={isThemeDark ? editPencilWhite : editPencilBlack}
              alt="Personnaliser"
            />
          </div>
        </button>
        {!isLoadedFolders && (
          <p className="text-[.75rem]">
            {`Il y a ${countsFolders.nb_folders} opération${
              countsFolders.nb_folders > 1 ? 's' : ''
            } dans la vue`}
          </p>
        )}
      </div>
      {isDeleteFolderLoading && <Loader />}
    </div>
  );
}

export default FoldersHeader;
