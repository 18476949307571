import Financials from '@dashboard/components/summary/Financials/Financials';
import { useContext, useEffect, useState } from 'react';
import { DashboardContext } from '@dashboard/utils/context/dashboardContext';
import LoaderSummaryDashboard from '@components/loaders/LoaderSummaryDashboard';

import {
  getAllFinancialsCee,
  getAllFinancialsMpr,
  getAllStatusCee,
  getAllStatusMpr,
  getQuotaCee,
} from '@dashboard/apiRequests/dashboardRequests';
import { InstallerContext } from '@auth/utils/authContext';
import {
  initialFinancials,
  initialStatus,
} from '@dashboard/utils/initialState';
import NewOperationsStatus from './operationsStatus/NewOperationsStatus';

function Summary(): JSX.Element {
  const {
    financialsIsLoaded,
    updateFinancialsIsLoaded,
    updateFinancialsCee,
    updateFinancialsMpr,
    statusIsLoaded,
    updateStatusIsLoaded,
    updateStatusCee,
    updateStatusMpr,
    statusCee,
    statusMpr,
    updateCeeStatusFilter,
    updateMprStatusFilter,
    updateQuotaCee,
  } = useContext(DashboardContext);

  const { eligibilityInstallerDemo, installer, installerIsLoaded } =
    useContext(InstallerContext);

  const [financialsMprLoaded, setFinancialsMprLoaded] =
    useState<boolean>(false);
  const [financialsCeeLoaded, setFinancialsCeeLoaded] =
    useState<boolean>(false);
  const [statutsMprLoaded, setStatutsMprLoaded] = useState<boolean>(false);
  const [statutsCeeLoaded, setStatutsCeeLoaded] = useState<boolean>(false);
  const [quotaCeeLoaded, setQuotaCeeLoaded] = useState<boolean>(false);

  const demoActive =
    eligibilityInstallerDemo.includes('CEE') ||
    eligibilityInstallerDemo.includes('MPR');

  const getAllSummaryDatas = (eligibility: string[]) => {
    if (eligibility.includes('MPR')) {
      getAllFinancialsMpr(updateFinancialsMpr, setFinancialsMprLoaded);
      getAllStatusMpr(updateStatusMpr, setStatutsMprLoaded, installer);
    } else {
      setFinancialsMprLoaded(true);
      setStatutsMprLoaded(true);
    }
    if (eligibility.includes('CEE')) {
      getAllFinancialsCee(updateFinancialsCee, setFinancialsCeeLoaded);
      getAllStatusCee(updateStatusCee, setStatutsCeeLoaded, installer);
      getQuotaCee(updateQuotaCee, setQuotaCeeLoaded);
    } else {
      setFinancialsCeeLoaded(true);
      setStatutsCeeLoaded(true);
      setQuotaCeeLoaded(true);
    }
  };

  const resetLoaders = () => {
    updateFinancialsIsLoaded(false);
    updateStatusIsLoaded(false);
    setFinancialsMprLoaded(false);
    setFinancialsCeeLoaded(false);
    setStatutsMprLoaded(false);
    setStatutsCeeLoaded(false);
    setQuotaCeeLoaded(false);
  };

  useEffect(() => {
    if (financialsMprLoaded && financialsCeeLoaded && quotaCeeLoaded) {
      updateFinancialsIsLoaded(true);
    }
  }, [financialsMprLoaded, financialsCeeLoaded, quotaCeeLoaded]);

  useEffect(() => {
    if (statutsMprLoaded && statutsCeeLoaded) {
      updateStatusIsLoaded(true);
    }
  }, [statutsMprLoaded, statutsCeeLoaded]);

  useEffect(() => {
    updateCeeStatusFilter(['']);
    updateMprStatusFilter(['']);
  }, []);

  useEffect(() => {
    if (installerIsLoaded && !demoActive) {
      getAllSummaryDatas(installer.eligibility);
    }
  }, [installer]);

  useEffect(() => {
    updateFinancialsIsLoaded(false);
    updateStatusIsLoaded(false);
  }, []);

  useEffect(() => {
    if (demoActive) {
      resetLoaders();
      if (!eligibilityInstallerDemo.includes('CEE')) {
        updateFinancialsCee(initialFinancials);
        updateStatusCee(initialStatus);
        updateQuotaCee(undefined);
      }
      if (!eligibilityInstallerDemo.includes('MPR')) {
        updateFinancialsMpr(initialFinancials);
        updateStatusMpr(initialStatus);
      }
      getAllSummaryDatas(eligibilityInstallerDemo);
    }
  }, [eligibilityInstallerDemo]);

  return (
    <div className="lg:w-full px-[1rem] py-[2rem] lg:flex">
      {financialsIsLoaded && statusIsLoaded ? (
        <>
          <div className="lg:w-1/3 h-full">
            <Financials />
          </div>
          <div className="lg:w-2/3 h-full flex flex-col space-y-[1rem] pb-[1rem]">
            <NewOperationsStatus
              title="Statuts des opérations MPR"
              datas={statusMpr}
              updateStatus={updateMprStatusFilter}
            />
            <NewOperationsStatus
              title="Statuts des opérations CEE"
              datas={statusCee}
              updateStatus={updateCeeStatusFilter}
            />
          </div>
        </>
      ) : (
        <LoaderSummaryDashboard />
      )}
    </div>
  );
}

export default Summary;
