/* eslint-disable no-nested-ternary */
import { useState, useEffect, useRef, useContext } from 'react';
import { Title } from 'storybook-design-system-drapo';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Header from '@components/menu/Header';
import rectangle1 from '@assets/images/simulator/rectangle_black.svg';
import {
  SimulatorContext,
  OperationsListContext,
} from '@simulator/utils/simulatorContext';
import {
  getGraphGeneral,
  getGraphGeneralWhiteLabel,
  getGraphOperation,
  getWhiteLabel,
} from '@simulator/apiRequests/SimulatorRequests';
import {
  IGraphType,
  ISimulatorDatasType,
  ILatLng,
  IOperationDatasType,
  ICadastre,
} from '@simulator/utils/simulatorTypes';
import {
  initialGraph,
  initialOperationDatas,
  initialSimulatorDatas,
} from '@simulator/utils/initialsState';
import { StepsNames } from '@simulator/utils/steps';
import LoaderSimulator from '@components/loaders/LoaderSimulator';
import 'animate.css';
import BackButton from '@simulator/components/BackButton';
import Summary from '@simulator/components/summary/Summary';
import RenderStep from '@simulator/components/RenderStep';
import Map from '@simulator/components/Map';
import { FoldersAndSimulatorContext } from '@folder/utils/FoldersContext';
import { AuthContext, InstallerContext } from '@auth/utils/authContext';
import { colorTextLightMode, dark4, white } from '@assets/colors';
import { useMediaQuery } from 'react-responsive';
import { latLngFrance } from '@utils/utils';

function Simulator(): JSX.Element {
  const whiteLabel: string = useParams().marque || '';
  const { updateWhiteLabelCustom } = useContext(FoldersAndSimulatorContext);
  const {
    installerIsLoaded,
    radiusPx,
    imgTemplate,
    isThemeDark,
    displayModalContratMixteMpr,
  } = useContext(InstallerContext);
  const { token } = useContext(AuthContext);
  const offLine = token === '';
  const router = useNavigate();
  const location = useLocation();

  const [graphGeneral, setGraphGeneral] = useState<IGraphType>(initialGraph);
  const [generalComplete, setGeneralComplete] = useState<boolean>(false);
  const [graphList, setGraphList] = useState<IGraphType[]>([initialGraph]);
  const [graphOperation, setGraphOperation] =
    useState<IGraphType>(initialGraph);
  const [graphListOperation, setGraphListOperation] = useState<IGraphType[]>([
    initialGraph,
  ]);
  const [simulatorDatas, setSimulatorDatas] = useState<ISimulatorDatasType>(
    initialSimulatorDatas
  );
  const [currentOperation, setCurrentOperation] = useState<IOperationDatasType>(
    initialOperationDatas
  );
  const [multipleOperations, setMultipleOperations] = useState<
    IOperationDatasType[]
  >([]);
  const [operationActiveId, setOperationActiveId] = useState<string>('');
  const [stepNumber, setStepNumber] = useState<number>(1);
  const [stepName, setStepName] = useState<string>(StepsNames[1]);
  const [title, setTitle] = useState<string>('');
  const [subtitle, setSubtitle] = useState<string>('');
  const [loaded, setLoaded] = useState<boolean>(true);
  const [loadedWhiteLabel, setLoadedWhiteLabel] = useState<boolean>(false);

  const [urlImg, setUrlImg] = useState<string>(rectangle1);

  const [latLngData, setLatLngData] = useState<ILatLng>(latLngFrance);

  const [cadastreDatas, setCadastreDatas] = useState<ICadastre>({
    reference: '',
    latitude: 0,
    longitude: 0,
  });
  const [coordinates, setCoordinates] = useState<ILatLng[]>([
    { lat: 0, lng: 0 },
  ]);
  const [markerMap, setMarkerMap] = useState<boolean>(false);

  const [heightMap, setHeightMap] = useState<string>('h-[90vh]');
  const [incompatibles, setIncompatibles] = useState<string[][]>([[''], ['']]);

  const [surfaceIsolate, setSurfaceIsolate] = useState<number>(40);
  const [totalCostAmountMin, setTotalCostAmountMin] = useState<number>(0);

  const [isBack, setIsBack] = useState<boolean>(false);
  const [operationsIdRecoveredGraph, setOperationsIdRecoveredGraph] = useState<
    string[]
  >([]);
  const [auditFromSummary, setAuditFromSummary] = useState<boolean>(false);
  const [operationInEdit, setOperationInEdit] = useState<boolean>(false);
  const [divLeftWidth, setDivLeftWidth] = useState<number>();
  const [errorCalculationPrimes, setErrorCalculationPrimes] = useState<
    string | undefined
  >();
  const [hasEcsIncompatibility, setHasEcsIncompatibility] =
    useState<boolean>(false);

  const simulatorContextValue = {
    title,
    updateTitle: setTitle,
    subtitle,
    updateSubtitle: setSubtitle,
    stepNumber,
    updateStepNumber: setStepNumber,
    stepName,
    updateStepName: setStepName,
    urlImg,
    updateUrlImg: setUrlImg,
    simulatorDatas,
    updateSimulatorDatas: setSimulatorDatas,
    graphGeneral,
    updateGraphGeneral: setGraphGeneral,
    graphList,
    updateGraphList: setGraphList,
    graphOperation,
    updateGraphOperation: setGraphOperation,
    graphListOperation,
    updateGraphListOperation: setGraphListOperation,
    updateOperationsIdRecoveredGraph: setOperationsIdRecoveredGraph,
    latLngData,
    updateLatLngData: setLatLngData,
    coordinates,
    updateCoordinates: setCoordinates,
    cadastreDatas,
    updateCadastreDatas: setCadastreDatas,
    markerMap,
    updateMarkerMap: setMarkerMap,
    currentOperation,
    updateCurrentOperation: setCurrentOperation,
    multipleOperations,
    updateMultipleOperations: setMultipleOperations,
    generalComplete,
    updateGeneralComplete: setGeneralComplete,
    loaded,
    updateLoaded: setLoaded,
    heightMap,
    updateHeigthMap: setHeightMap,
    incompatibles,
    updateIncompatibles: setIncompatibles,
    updateSurfaceIsolate: setSurfaceIsolate,
    surfaceIsolate,
    totalCostAmountMin,
    updateTotalCostAmountMin: setTotalCostAmountMin,
    isBack,
    updateIsBack: setIsBack,
    operationActiveId,
    updateOperationActiveId: setOperationActiveId,
    auditFromSummary,
    updateAuditFromSummary: setAuditFromSummary,
    divLeftWidth,
    errorCalculationPrimes,
    updateErrorCalculationPrimes: setErrorCalculationPrimes,
    operationInEdit,
    updateOperationInEdit: setOperationInEdit,
    hasEcsIncompatibility,
    updateHasEcsIncompatibility: setHasEcsIncompatibility,
  };

  const [operations, setOperations] = useState<IOperationDatasType[]>([]);

  const operationsListContextValue = {
    operationList: operations,
    updateOperationList: setOperations,
  };

  const [backDisplay, setBackDisplay] = useState<JSX.Element>(<></>);

  const leftContainer = useRef<HTMLInputElement>(null);
  const rightContainer = useRef<HTMLInputElement>(null);

  const [divRightWidth, setDivRightWidth] = useState<number>();
  const [divRightHeight, setDivRightHeight] = useState<number>();
  const [headerContainerHeight, setHeaderContainerHeight] = useState<number>(0);

  const littlePaddingAppContainer = useMediaQuery({ maxWidth: 1280 });

  const getSize = () => {
    if (leftContainer && leftContainer.current) {
      const newWidth = leftContainer.current.clientWidth;
      setDivLeftWidth(newWidth);
    }
    if (rightContainer && rightContainer.current) {
      const newWidth = rightContainer.current.clientWidth;
      const newHeight = rightContainer.current.clientHeight;

      setDivRightWidth(newWidth);
      setDivRightHeight(newHeight);
    }
  };

  useEffect(() => {
    const html = document.getElementsByTagName('html')[0];

    if (!location.pathname.includes('demander-ma-prime')) {
      router(`${location.pathname}/demander-ma-prime`);
    }
    getSize();
    html.classList.remove('not-scroll');
  }, []);

  useEffect(() => {
    window.addEventListener('resize', getSize);
  }, []);

  useEffect(() => {
    if (
      (stepNumber !== 1 && operations.length === 0) ||
      operations.length !== 0
    ) {
      setBackDisplay(<BackButton />);
    } else {
      setBackDisplay(<></>);
    }
  }, [stepNumber, operations]);

  useEffect(() => {
    if (location.pathname === '/demander-ma-prime') {
      getGraphGeneral(setGraphGeneral, setGraphList);
      setLoadedWhiteLabel(true);
    } else {
      getWhiteLabel(
        whiteLabel,
        updateWhiteLabelCustom,
        router,
        setLoadedWhiteLabel,
        setGraphGeneral,
        setGraphList
      );
      getGraphGeneralWhiteLabel(setGraphGeneral, setGraphList, whiteLabel);
    }
  }, []);

  useEffect(() => {
    switch (stepNumber) {
      default:
      case 1:
        setStepName(StepsNames['1']);
        break;
      case 2:
        setStepName(StepsNames['2']);
        break;
      case 3:
        setStepName(StepsNames['3']);
        if (
          currentOperation.id !== '' &&
          currentOperation.id !== operationActiveId &&
          !operationsIdRecoveredGraph.includes(currentOperation.id)
        ) {
          setLoaded(false);
          setOperationsIdRecoveredGraph((oldList) => [
            ...oldList,
            currentOperation.id,
          ]);
          getGraphOperation(
            setLoaded,
            setGraphOperation,
            setGraphListOperation,
            currentOperation,
            simulatorDatas,
            setTotalCostAmountMin
          );
          setOperationActiveId(currentOperation.id);
        }
        break;
      case 4:
        setStepName(StepsNames['4']);
        setGraphListOperation([initialGraph]);
        setGraphOperation(initialGraph);
        setCurrentOperation(initialOperationDatas);
        setOperationsIdRecoveredGraph([]);
        break;
    }
  }, [stepNumber, currentOperation]);

  return (
    <SimulatorContext.Provider value={simulatorContextValue}>
      <OperationsListContext.Provider value={operationsListContextValue}>
        {stepNumber === 4 ? (
          <Summary
            latLng={latLngData}
            coordinates={coordinates}
            markerMap={markerMap}
          />
        ) : !displayModalContratMixteMpr ? (
          <div className="lg:flex justify-between lg:space-x-[5rem] lg:h-screen">
            <div className="lg:w-6/12 mx-auto max-h-full h-90-vh">
              <Header
                leftContainer={divLeftWidth}
                setHeaderContainerHeight={setHeaderContainerHeight}
              />
              <div
                ref={leftContainer}
                style={{
                  marginTop: `calc(${headerContainerHeight}px - ${
                    littlePaddingAppContainer ? '2vh' : '5vh'
                  })`,
                }}
              >
                {backDisplay}
                {(generalComplete && !loaded) ||
                !loadedWhiteLabel ||
                (!installerIsLoaded && !offLine) ? (
                  <LoaderSimulator />
                ) : (
                  <>
                    <div
                      style={{ borderRadius: radiusPx }}
                      className="dark:border-none border-borderLightMode2 border"
                    >
                      <Title
                        colorSubtitle={isThemeDark ? white : colorTextLightMode}
                        title={title}
                        width="70%"
                        subtitle={subtitle}
                        labelButton=""
                        color={isThemeDark ? white : colorTextLightMode}
                        backgroundColor={isThemeDark ? dark4 : white}
                        borderRadius={radiusPx}
                      />
                    </div>
                    <RenderStep />
                  </>
                )}
              </div>
            </div>
            <div
              ref={rightContainer}
              className="lg:block mx-auto hidden lg:w-6/12 static h-90-vh z-[22222] rounded-[16px]"
            >
              {stepNumber === 1 ? (
                <img
                  style={{
                    width: divRightWidth,
                    height: divRightHeight,
                    borderRadius: radiusPx,
                  }}
                  className="fixed z-[22222]  object-cover"
                  src={imgTemplate}
                  alt="illustration drapo"
                />
              ) : (
                <Map
                  coordinates={coordinates}
                  latLngData={latLngData}
                  markerMap={markerMap}
                  divRightWidth={divRightWidth}
                />
              )}
            </div>
          </div>
        ) : (
          <div />
        )}
      </OperationsListContext.Provider>
    </SimulatorContext.Provider>
  );
}

export default Simulator;
