import { InstallerContext } from '@auth/utils/authContext';
import { v4 } from 'uuid';
import { Dispatch, SetStateAction, useContext, useRef } from 'react';
import { Link } from 'react-router-dom';
import { white } from '@assets/colors';
import { searchFolder } from '@dashboard/apiRequests/dashboardRequests';
import { IDataSearchFolder } from '../../utils/dashboardTypes';
import { LayoutDashboardContext } from '../../utils/context/LayoutDashboardContext';
import { numFormatSpace } from '../../../../utils/utils';

interface IProps {
  isLookingFor: boolean;
  datas: IDataSearchFolder[] | undefined;
  totalFoldersInList: number;
  setNumberPage: Dispatch<SetStateAction<number>>;
  numberPage: number;
  setFoldersIsLoaded: Dispatch<SetStateAction<boolean>>;
  foldersIsLoaded: boolean;
  searchValue: string;
  setListFolders: Dispatch<SetStateAction<IDataSearchFolder[] | undefined>>;
  setTotalFoldersInList: Dispatch<SetStateAction<number>>;
  listFolders: IDataSearchFolder[] | undefined;
}
function SearchFolderList({
  isLookingFor,
  datas,
  totalFoldersInList,
  setNumberPage,
  numberPage,
  setFoldersIsLoaded,
  foldersIsLoaded,
  searchValue,
  setListFolders,
  setTotalFoldersInList,
  listFolders,
}: IProps) {
  const listInnerRef = useRef<any>(null);

  const { radiusPx, colorPrimary } = useContext(InstallerContext);

  const { isRetractableDDC } = useContext(LayoutDashboardContext);

  const lengthSubstringFolderReference = (
    firstnameLenght: number,
    lastnameLength: number
  ) => {
    if (firstnameLenght + lastnameLength > 30 && isRetractableDDC) {
      return 18;
    }
    if (firstnameLenght + lastnameLength > 30 && !isRetractableDDC) {
      return 5;
    }
    return 100000;
  };

  const infiniteScroll = () => {
    if (listInnerRef.current) {
      // eslint-disable-next-line no-shadow
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (
        (scrollTop + clientHeight === scrollHeight ||
          scrollTop + clientHeight === scrollHeight - 0.5 ||
          scrollTop + clientHeight === scrollHeight + 0.5) &&
        !foldersIsLoaded
      ) {
        setFoldersIsLoaded(true);
        searchFolder(
          numberPage + 1,
          searchValue,
          setListFolders,
          setTotalFoldersInList,
          listFolders,
          setFoldersIsLoaded,
          setNumberPage
        );
        listInnerRef.current.scrollTop = scrollTop - 2.5;
      }
    }
  };

  const colorSearchInString = (
    characterIndex: number,
    positionSearchIndex: number
  ) => {
    if (
      characterIndex >= positionSearchIndex &&
      characterIndex < positionSearchIndex + searchValue.length
    ) {
      return colorPrimary;
    }
    return '';
  };

  return (
    <div className="relative w-full text-[.875rem] dark:text-white text-colorTextLightMode">
      <div
        style={{
          borderBottomRightRadius: radiusPx,
          borderBottomLeftRadius: radiusPx,
        }}
        onScroll={() => infiniteScroll()}
        ref={listInnerRef}
        className="absolute w-full overflow-auto scrollWidthMini max-h-[31rem] dark:bg-[#262626] bg-white"
      >
        <div
          style={{
            maxHeight: isLookingFor ? '' : '0px',
            borderBottomRightRadius: radiusPx,
            borderBottomLeftRadius: radiusPx,
          }}
          className={`dark:bg-[#262626] bg-white ${
            isLookingFor ? 'p-[.8rem]' : ''
          }`}
        >
          {datas !== undefined &&
            Object.values(datas).map((data: IDataSearchFolder) => {
              const searchIndexInFirstname = data.firstname
                ? data.firstname
                    .toLowerCase()
                    .indexOf(searchValue.toLowerCase())
                : -1;
              const searchIndexInLastname = data.lastname
                ? data.lastname.toLowerCase().indexOf(searchValue.toLowerCase())
                : -1;
              const searchIndexInWorkStreet = data.work_street
                ? data.work_street
                    .toLowerCase()
                    .indexOf(searchValue.toLowerCase())
                : -1;
              const searchIndexInWorkCity = data.work_city
                ? data.work_city
                    .toLowerCase()
                    .indexOf(searchValue.toLowerCase())
                : -1;
              const searchIndexInWorkZipCode = data.work_zipcode
                ? data.work_zipcode
                    .toLowerCase()
                    .indexOf(searchValue.toLowerCase())
                : -1;
              const searchIndexInFolderReference = data.folder_reference
                ? data.folder_reference
                    .toLowerCase()
                    .indexOf(searchValue.toLowerCase())
                : -1;

              const cutSubstring =
                lengthSubstringFolderReference(
                  data?.firstname?.length,
                  data?.lastname?.length
                ) === 5 ||
                lengthSubstringFolderReference(
                  data?.firstname?.length,
                  data?.lastname?.length
                ) === 18;
              return (
                <Link
                  to={
                    data.in_airtable
                      ? `/suivi-de-dossier/${data.folder_reference}`
                      : `/informations-complementaires/${data.folder_reference}`
                  }
                  key={v4()}
                  style={{
                    borderRadius: radiusPx === '16px' ? '8px' : '0px',
                  }}
                  className="flex justify-between items-center dark:bg-[#202020] bg-[#FBFBFB] mb-[.6rem] p-[.4rem] hover:scale-[1.02] dark:border-none border border-borberLightMode"
                >
                  <div className="flex items-center space-x-[.3rem] w-full">
                    <div className="px-[.5rem] w-full">
                      <div className="flex items-center justify-between">
                        <div className="flex items-center font-medium">
                          {data.firstname
                            ? data.firstname.split('').map((el, index) => {
                                return (
                                  <span
                                    key={v4()}
                                    className="!m-[0px] !p-0 font-medium"
                                    style={{
                                      color:
                                        searchIndexInFirstname !== -1
                                          ? colorSearchInString(
                                              index,
                                              searchIndexInFirstname
                                            )
                                          : '',
                                    }}
                                  >
                                    {el}
                                  </span>
                                );
                              })
                            : undefined}
                          {data.lastname && <span>&nbsp;</span>}
                          {data.lastname
                            ? data.lastname.split('').map((el, index) => {
                                return (
                                  <span
                                    key={v4()}
                                    className="!m-[0px] !p-0 whitespace-nowrap	font-medium"
                                    style={{
                                      color:
                                        searchIndexInLastname !== -1
                                          ? colorSearchInString(
                                              index,
                                              searchIndexInLastname
                                            )
                                          : '',
                                    }}
                                  >
                                    {el}
                                  </span>
                                );
                              })
                            : '-'}
                        </div>

                        <p className="opacity-70">
                          {data.folder_reference
                            ? data.folder_reference
                                .substring(
                                  0,
                                  lengthSubstringFolderReference(
                                    data?.firstname?.length,
                                    data?.lastname?.length
                                  )
                                )
                                .split('')
                                .map((el, index) => {
                                  return (
                                    <span
                                      key={v4()}
                                      className="!m-[0px] !p-0 whitespace-nowrap"
                                      style={{
                                        color:
                                          searchIndexInFolderReference !== -1
                                            ? colorSearchInString(
                                                index,
                                                searchIndexInFolderReference
                                              )
                                            : '',
                                      }}
                                    >
                                      {el}
                                    </span>
                                  );
                                })
                            : undefined}
                          {cutSubstring && '...'}
                        </p>
                      </div>
                      <div className="flex items-center justify-between">
                        <div className="whitespace-nowrap">
                          <p>
                            {' '}
                            {data.work_street
                              ? data.work_street.split('').map((el, index) => {
                                  return (
                                    <span
                                      key={v4()}
                                      className="!m-[0px] !p-0 whitespace-nowrap	"
                                      style={{
                                        color:
                                          searchIndexInWorkStreet !== -1
                                            ? colorSearchInString(
                                                index,
                                                searchIndexInWorkStreet
                                              )
                                            : '',
                                      }}
                                    >
                                      {el}
                                    </span>
                                  );
                                })
                              : undefined}
                          </p>
                          <div className="flex items-center space-x-[.3rem]">
                            <p>
                              {data.work_zipcode
                                ? data.work_zipcode
                                    .split('')
                                    .map((el, index) => {
                                      return (
                                        <span
                                          key={v4()}
                                          className="!m-[0px] !p-0 whitespace-nowrap	"
                                          style={{
                                            color:
                                              searchIndexInWorkZipCode !== -1
                                                ? colorSearchInString(
                                                    index,
                                                    searchIndexInWorkZipCode
                                                  )
                                                : '',
                                          }}
                                        >
                                          {el}
                                        </span>
                                      );
                                    })
                                : undefined}
                            </p>
                            <p>
                              {data.work_city
                                ? data.work_city.split('').map((el, index) => {
                                    return (
                                      <span
                                        key={v4()}
                                        className="!m-[0px] !p-0 whitespace-nowrap	"
                                        style={{
                                          color:
                                            searchIndexInWorkCity !== -1
                                              ? colorSearchInString(
                                                  index,
                                                  searchIndexInWorkCity
                                                )
                                              : '',
                                        }}
                                      >
                                        {el}
                                      </span>
                                    );
                                  })
                                : undefined}
                            </p>
                          </div>
                        </div>
                        <div className="font-medium text-right">
                          <p>MPR {numFormatSpace(data.mpr_amount, false)} €</p>
                          <p>
                            CEE{' '}
                            {numFormatSpace(data.prime_cee_beneficiary, false)}{' '}
                            €
                          </p>
                        </div>
                      </div>
                    </div>
                    <div />
                  </div>
                </Link>
              );
            })}
          {datas !== undefined &&
            totalFoldersInList > Object.values(datas).length &&
            searchValue.length > 0 && (
              <div className="pb-[1rem]">
                <div
                  style={{
                    borderRadius: radiusPx === '16px' ? '8px' : '0px',
                  }}
                  className="dark:bg-dark-4 bg-[#F1F2F5] text-center my-[.2rem] p-[.7rem]"
                >
                  {foldersIsLoaded ? (
                    <div className="loader opacity-40 h-2">
                      <div
                        style={{
                          backgroundColor: white,
                        }}
                        className="loaderBar"
                      />
                    </div>
                  ) : (
                    'Faire défiler pour voir plus de résultats'
                  )}
                </div>
              </div>
            )}
        </div>
      </div>
    </div>
  );
}

export default SearchFolderList;
