import { MyProfileContext } from '@dashboard/utils/context/myProfileContext';
import { useContext, useState } from 'react';
import { Document, Page } from 'react-pdf';
// import { useMediaQuery } from 'react-responsive';
import { InstallerContext } from '@auth/utils/authContext';
import { PdfNavigator } from '@components/PdfNavigator';

function RightPartDocuments(): JSX.Element {
  const [numPages, setNumPages] = useState<number | undefined>(1);
  const {
    documentUrlActive,
    updatePageNumberViewDocument,
    pageNumberViewDocument,
  } = useContext(MyProfileContext);
  const { radiusPx } = useContext(InstallerContext);

  const radius = radiusPx === '0px' ? '0px' : '20px';

  return (
    <div
      style={{
        borderRadius: radius,
      }}
      className="xl:w-7/12 relative mt-[1.875rem] flex flex-col items-center m-auto xl:ml-[1rem] dark:text-white text-dark-2 dark:bg-dark-5 bg-backgroundTertiaryLightMode  overflow-hidden xl:h-[74.5vh] h-[76vh]"
    >
      {documentUrlActive !== '' ? (
        <div>
          <Document
            className="flex flex-col items-center"
            onLoadSuccess={(pdf) => {
              setNumPages(pdf.numPages);
            }}
            onLoadError={() => {
              setNumPages(undefined);
              updatePageNumberViewDocument(1);
            }}
            file={
              documentUrlActive !== undefined
                ? documentUrlActive.replace('{', '').replace('}', '')
                : ['']
            }
          >
            <Page
              renderAnnotationLayer={false}
              pageNumber={pageNumberViewDocument}
              scale={0.8}
              className="tall:w-[35rem] w-[34rem] px-[2.5rem] py-[2.5rem] pdfPage"
            />
          </Document>
          <div
            style={{ transform: 'translateX(-50%)' }}
            className="absolute bottom-0 tall:bottom-3 left-[50%] w-full px-8"
          >
            <PdfNavigator
              numberOfPages={numPages}
              pageActive={pageNumberViewDocument}
              setPageActive={updatePageNumberViewDocument}
            />
          </div>
        </div>
      ) : (
        <p>Pas de document</p>
      )}
    </div>
  );
}

export default RightPartDocuments;
