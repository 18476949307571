import { Dispatch, SetStateAction, useContext } from 'react';
import infos from '@assets/images/infos.svg';
import { infos_black } from '@assets/images/images';
import { InstallerContext } from '@auth/utils/authContext';

type IInfosButtonProps = {
  forcedDivActive: string;
  setDivActive: Dispatch<SetStateAction<string | undefined>>;
};

function InfosButton({
  forcedDivActive,
  setDivActive,
}: IInfosButtonProps): JSX.Element {
  const { isThemeDark } = useContext(InstallerContext);
  const handleClick = () => {
    setDivActive(forcedDivActive);
  };

  return (
    <button type="button" onClick={handleClick}>
      {isThemeDark ? (
        <img
          src={infos}
          alt="icone infos"
          className="w-[1.25rem] h-[1.25rem] opacity-40 hover:opacity-100"
        />
      ) : (
        <img
          src={infos_black}
          alt="icone infos"
          className="w-[1.25rem] h-[1.25rem] opacity-40 hover:opacity-100"
        />
      )}
    </button>
  );
}

export default InfosButton;
