import LoaderDashboard from '@components/loaders/LoaderDashboard';
import {
  // deleteListCustomViewById,
  getSchemaFolders,
} from '@dashboard/apiRequests/dashboardRequests';
import { DashboardContext } from '@dashboard/utils/context/dashboardContext';
import React, { useContext, useEffect } from 'react';
import ListViewArray from './ListViewArray';
import ModalPersonalization from './modalPersonalization/ModalPersonalization';
import { getOneSchemaCustomViewById } from '../../apiRequests/dashboardRequests';
import ModalFilters from './modalFilters/ModalFilters';

function CustomView(): JSX.Element {
  const {
    filtersOrPersonalizationModalCustomView,
    isLoadingCustomView,
    updateCurrentSchemaFolderCustomView,
    currentSchemaFolderCustomView,
    idCurrentListCustomView,
    currentListCustomView,
    isCreateNewListCustomView,
    updateSchemaFoldersCustomView,
    updateIsLoadingCustomView,
    updateIsCreateNewListCustomView,
    updateFiltersOrPersonalizationModalCustomView,
    // updateActiveCustomView,
    // updateListSchemasCustomView,
    // updateElementActive,
  } = useContext(DashboardContext);

  useEffect(() => {
    getSchemaFolders(updateSchemaFoldersCustomView);
    // deleteListCustomViewById(
    //   idCurrentListCustomView,
    //   updateElementActive,
    //   updateActiveCustomView,
    //   updateListSchemasCustomView,
    //   updateIsLoadingCustomView
    // );
  }, []);

  useEffect(() => {
    if (idCurrentListCustomView !== '') {
      updateIsCreateNewListCustomView(false);
      getOneSchemaCustomViewById(
        idCurrentListCustomView,
        updateCurrentSchemaFolderCustomView,
        updateIsLoadingCustomView
      );
    }
  }, [idCurrentListCustomView, currentListCustomView]);

  const displayLoader = () => {
    if (isLoadingCustomView) {
      return true;
    }
    if (isCreateNewListCustomView && currentListCustomView === undefined) {
      return false;
    }
    if (
      currentListCustomView !== undefined &&
      currentListCustomView.data !== undefined
    ) {
      return false;
    }
    return true;
  };

  return (
    <div>
      {displayLoader() ? (
        <LoaderDashboard />
      ) : (
        <div>
          {filtersOrPersonalizationModalCustomView === 'Personnaliser' && (
            <ModalPersonalization />
          )}
          {filtersOrPersonalizationModalCustomView === 'Filtrer' &&
            !isCreateNewListCustomView && <ModalFilters />}
          {currentSchemaFolderCustomView.schema !== undefined &&
          currentSchemaFolderCustomView.schema.fields !== undefined &&
          currentSchemaFolderCustomView.schema.fields.length === 0 ? (
            <div className="text-[.875rem] px-[1.5rem] pt-[.625rem]">
              <p>Il n&apos;y a aucune colonne visible dans cette vue.</p>
              <p>
                Vous pouvez modifier la visibilité des colonnes{' '}
                <button
                  type="button"
                  onClick={() =>
                    updateFiltersOrPersonalizationModalCustomView(
                      'Personnaliser'
                    )
                  }
                  className="underline"
                >
                  ici
                </button>
                .
              </p>
            </div>
          ) : (
            !isCreateNewListCustomView && <ListViewArray />
          )}
        </div>
      )}
    </div>
  );
}

export default CustomView;
