import { InstallerContext } from '@auth/utils/authContext';
import { updateMail } from '@dashboard/apiRequests/dashboardRequests';
import { MyProfileContext } from '@dashboard/utils/context/myProfileContext';
import { Dispatch, SetStateAction, useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { purple1 } from '@assets/colors';
import InputUpdate from '../InputUpdate';

interface IProps {
  dataMail: string;
  setShowEmailUpdate: Dispatch<SetStateAction<boolean>>;
}

function UpdateEmail({ dataMail, setShowEmailUpdate }: IProps): JSX.Element {
  const [error, setError] = useState('');
  const { handleSubmit, register } = useForm();
  const { installer, radiusPx } = useContext(InstallerContext);
  const { updateIsUpdateLoading, isUpdateLoading } =
    useContext(MyProfileContext);

  const onSubmit = (data: any) => {
    updateMail(data.mail, updateIsUpdateLoading, setError, setShowEmailUpdate);
  };

  const radius = radiusPx === '0px' ? '0px' : '8px';

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      action="update Email"
      className="pb-[1.25rem] w-full"
    >
      <InputUpdate
        placeholder={dataMail}
        register={register}
        name="mail"
        required
        id="mail"
        type="mail"
      />

      {error}
      <button
        disabled={isUpdateLoading}
        style={{
          borderRadius: radius,
          opacity: isUpdateLoading ? 0.5 : 1,
          backgroundColor:
            installer.nom_partenaire === 'DRAPO' ||
            installer.nom_partenaire === ''
              ? purple1
              : installer.custom.couleur_boutons,
        }}
        type="submit"
        className="mt-[1rem] w-auto text-white py-[.625rem] px-[2.25rem] sb-text-rem-subtitle2"
      >
        Valider
      </button>
    </form>
  );
}

export default UpdateEmail;
