import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import { InputSearchInList } from 'storybook-design-system-drapo';
import {
  IMaterialDataType,
  IOperationFolderType,
} from '@folder/utils/folderTypes';
import { getMaterials } from '@folder/apiRequests/folderRequests';
import { IKeyValueStringType } from '@simulator/utils/simulatorTypes';
import { initFilteredDatas } from '@simulator/utils/functions';
import { InstallerContext } from '@auth/utils/authContext';
import {
  backgroundQuaternaryLightMode,
  colorTextLightMode,
  dark1,
  dark4,
  white,
} from '@assets/colors';

interface MaterialOperationProps {
  operation: IOperationFolderType;
  setMaterialsData: Dispatch<SetStateAction<IMaterialDataType[]>>;
  materialsData: IMaterialDataType[];
  operationIndex: number;
  setMaterialNotCorrect: Dispatch<SetStateAction<boolean>>;
  setNoMaterialSelected: Dispatch<SetStateAction<boolean>>;
  setInputIsActive: Dispatch<SetStateAction<boolean>>;
}

function MaterialOperation({
  operation,
  setMaterialsData,
  materialsData,
  operationIndex,
  setMaterialNotCorrect,
  setNoMaterialSelected,
  setInputIsActive,
}: MaterialOperationProps): JSX.Element {
  const [materials, setMaterials] = useState<IKeyValueStringType[]>(
    initFilteredDatas(
      [
        {
          id: '',
          title: '',
        },
      ],
      'id',
      'title'
    )
  );
  const [filteredMaterials, setFilteredMaterials] =
    useState<IKeyValueStringType[]>(materials);

  const [materialNotFound, setMaterialNotFound] = useState<boolean>(false);

  const { radiusPx, isThemeDark } = useContext(InstallerContext);

  useEffect(() => {
    getMaterials(setMaterials, operation.slug);
  }, [setFilteredMaterials, operation.slug]);

  useEffect(() => {
    setFilteredMaterials(materials);
  }, [materials, setFilteredMaterials]);

  const handleSearch = (
    searchText: string | IKeyValueStringType,
    setFilteredArray: Dispatch<SetStateAction<IKeyValueStringType[]>>,
    datas: IKeyValueStringType[]
  ) => {
    if (typeof searchText === 'string') {
      if (searchText !== '') {
        const filteredBlocks = datas.filter((data) => {
          return data.label.toLowerCase().includes(searchText.toLowerCase());
        });
        setFilteredArray(filteredBlocks);
        if (filteredBlocks.length === 0) {
          setMaterialNotCorrect(true);
        } else {
          setMaterialNotCorrect(false);
        }
      } else {
        setFilteredArray(datas);
        setMaterialNotCorrect(false);
      }
    } else {
      const filteredBlocks = datas.filter((data) => {
        return data.label
          .toLowerCase()
          .includes(searchText.label.toLowerCase());
      });
      setFilteredArray(filteredBlocks);
      if (filteredBlocks.length === 0) {
        setMaterialNotCorrect(true);
      } else {
        setMaterialNotCorrect(false);
      }
    }
  };

  const handleSelectMaterial = (
    material: IKeyValueStringType,
    index: number
  ) => {
    setNoMaterialSelected(false);
    setMaterialsData((prevState) => {
      const newMaterialsData = [...prevState];
      const newMaterial = prevState[index];
      newMaterial.referenceMark = material.value;
      newMaterial.label = material.label;
      newMaterialsData[index] = newMaterial;
      return newMaterialsData;
    });
    setFilteredMaterials(materials);
    if (materialNotFound) {
      setMaterialNotFound(false);
    }
  };

  const handleMaterialNotFound = () => {
    setMaterialNotFound(true);
  };

  const handleClear = () => {
    let newMaterial: IMaterialDataType;
    let index: number;
    for (let i = 0; i < materialsData.length; i += 1) {
      if (materialsData[i].referenceMark !== '') {
        newMaterial = materialsData[i];
        newMaterial.referenceMark = '';
        newMaterial.label = '';
        index = i;
      }
    }
    setMaterialsData((prevState) => {
      const newMaterialsData = [...prevState];
      newMaterialsData[index] = newMaterial;
      return newMaterialsData;
    });
    setFilteredMaterials(materials);
  };
  return (
    <>
      <InputSearchInList
        colorNotFoundText={isThemeDark ? dark1 : colorTextLightMode}
        backgroundColorIconSearch={isThemeDark ? dark4 : white}
        backgroundColor={isThemeDark ? dark1 : backgroundQuaternaryLightMode}
        addClassScroll={isThemeDark ? '' : 'scroll_light_mode_search_in_list'}
        borderRadius={radiusPx}
        color={isThemeDark ? white : dark1}
        onSearch={(searchValue) =>
          handleSearch(searchValue, setFilteredMaterials, materials)
        }
        filteredList={filteredMaterials}
        onSelectInList={(material) =>
          handleSelectMaterial(material, operationIndex)
        }
        placeholder="Marque et référence matériel"
        addClass={`mt-[.875rem] ${
          materialNotFound && 'border border-red-500 rounded-default'
        }`}
        defaultSelectedItem={
          operation.material && materialsData[operationIndex]
            ? {
                value: materialsData[operationIndex].referenceMark,
                label: materialsData[operationIndex].label,
              }
            : null
        }
        notFoundText="La référence de mon matériel n'est pas dans la liste."
        notFoundAction={handleMaterialNotFound}
        onClear={() => handleClear()}
        setIsActive={setInputIsActive}
      />
      {materialNotFound && (
        <p className="text-[.875rem] text-red-500 mb-[1rem] ml-[.5rem] mt-[.5rem]">
          Merci de nous envoyer la fiche technique du matériel afin que nous
          l&apos;ajoutions à notre simulateur.
          <br />
          Vous pouvez nous contacter à l&apos;adresse&nbsp;
          <a
            href="mailto:contact@drapo.com"
            target="_blank"
            className="cursor-pointer text-purple-1"
            rel="noreferrer"
          >
            contact@drapo.com
          </a>
        </p>
      )}
    </>
  );
}

export default MaterialOperation;
