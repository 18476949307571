export const initialFolderData = {
  cee_steps: '',
  cee_total: 0,
  mpr_steps: '',
  mpr_total: 0,
  nb_operations: 0,
  operations: [],
  mpr_amount_quote: 0,
};

export const initialAllDocuments = {
  common: {
    attestation_indivisaire: [''],
    attestation_propriétaire_bailleur_mpr: [''],
    avis_imposition: [''],
    facture_travaux: [''],
    justificatif_propriété: [''],
    mandat_mixte_mpr_généré: [''],
    mandat_mixte_mpr_signé: [''],
    no_mpr: [''],
    attestation_consentement_mpr: [''],
  },
  specific: [],
};

export const initialOperationMonitoringData = {
  montant_facture_ttc_attestation_consentement_dev: 0,
  attestation_consentement_mpr: '',
  demandeur_cee: '',
  adresse_email_client: '',
  adresse_travaux: '',
  bonus: false,
  ah_originale: '',
  civilité_client: '',
  commentaires_référents_dossier: '',
  date_estimée_virement_cee: '',
  date_estimée_virement_mpr: '',
  ddc: null,
  dernier_rt: '',
  facture_travaux: '',
  frais_dossier_mpr_ht: '',
  frais_dossier_mpr_ttc: '',
  last_cee: '',
  last_mpr: '',
  libellé_opération: '',
  montant_mpr: '',
  no_mpr: '',
  nom_client: '',
  photos_après_travaux: '',
  photos_avant_travaux: '',
  prime_cee_bénéficiaire: '',
  prénom_client: '',
  référence_opération: '',
  rc: '',
  statut_cee: '',
  statut_mpr: '',
  step_cee: '',
  step_mpr: '',
  text_cee: '',
  text_mpr: '',
  type_dossier: '',
  téléphone_client: '',
  téléphone_référent: '',
  virement_mpr_pro: '',
  catégorie_ménage: '',
  nombre_personnes_dans_ménage: '',
  surface: '',
  type_logement: '',
  type_propriétaire: '',
  age_logement: '',
  matériel_déposé: '',
  coordonnées_géographiques_nationales: '',
  devis_signé: '',
  mandat_mixte_mpr_généré: '',
  mandat_mixte_mpr_signé: '',
  status_payment_cee: '',
  status_payment_mpr: '',
  statut_signature_ah: '',
  text_cofrac: '',
  cofrac: false,
  frais_dossier_cee_ht: '',
  step_cofrac: '',
  note_dimensionnement: '',
  montant_ttc_devis: '',
  nombre_démetteur: '',
};
