import { useContext, useEffect, useState } from 'react';
import {
  Button,
  InputCount,
  WarningNotification,
} from 'storybook-design-system-drapo';
import { InstallerContext } from '@auth/utils/authContext';
import { checkChildrensConditions } from '@simulator/utils/functions';
import { SimulatorContext } from '@simulator/utils/simulatorContext';
import {
  IGraphType,
  IKeyValueStringType,
} from '@simulator/utils/simulatorTypes';

import { getMaterialsScopeUnit } from '@simulator/apiRequests/SimulatorRequests';
import { dark1, dark2, white } from '@assets/colors';
import ScopUnitCard from './ScopUnitCard';

function ScopUnits(): JSX.Element {
  const {
    colorPrimary,
    radiusPx,
    colorAlertWhiteLabel,
    isThemeDark,
    colorTextButton,
  } = useContext(InstallerContext);
  const [materialsList, setMaterialsList] = useState<IKeyValueStringType[]>([]);
  const [errorSurfaceTooMuch, setErrorSurfaceTooMuch] =
    useState<boolean>(false);
  const [errorSurfaceNotEnough, setErrorSurfaceNotEnough] =
    useState<boolean>(false);
  const [unitsData, setUnitsData] = useState<IKeyValueStringType[]>([
    {
      id: '1',
      value: '',
      label: '',
      scop: '',
      power: '',
      name: '',
      surface: '',
    },
  ]);

  const {
    simulatorDatas,
    updateGraphOperation,
    updateGraphListOperation,
    graphOperation,
    updateCurrentOperation,
    currentOperation,
  } = useContext(SimulatorContext);

  useEffect(() => {
    getMaterialsScopeUnit(setMaterialsList, currentOperation.id);
  }, []);

  const minusClick = () => {
    const min = 1;
    if (unitsData.length > min) {
      setUnitsData(unitsData.slice(0, -1));
    }
  };

  const plusClick = () => {
    const max = 5;
    if (unitsData.length < max) {
      setUnitsData((prevState) => [
        ...prevState,
        {
          id: (unitsData.length + 1).toString(2),
          value: '',
          label: '',
          scop: '',
          power: '',
          name: '',
          surface: '',
        },
      ]);
    }
  };

  const onClickNext = () => {
    let surfaceTotal = 0;
    for (let i = 0; i < unitsData.length; i += 1) {
      surfaceTotal += Number(unitsData[i].surface);
    }
    if (Number(simulatorDatas.surface) < surfaceTotal) {
      setErrorSurfaceTooMuch(true);
      setErrorSurfaceNotEnough(false);
      return false;
    }
    if (
      Number(simulatorDatas.surface) > surfaceTotal &&
      !errorSurfaceNotEnough
    ) {
      setErrorSurfaceTooMuch(false);
      setErrorSurfaceNotEnough(true);
      return false;
    }
    setErrorSurfaceTooMuch(false);
    setErrorSurfaceNotEnough(false);
    updateCurrentOperation((oldCurrentOperation) => {
      const newCurrentOperation = { ...oldCurrentOperation };
      const variations = [];
      const marks = [];
      for (let i = 0; i < unitsData.length; i += 1) {
        const unit = unitsData[i];
        variations.push({
          scop: unit.scop,
          name: unit.name,
          surface: unit.surface,
          power: unit.power,
        });
        marks.push({
          id: unit.value,
        });
      }
      newCurrentOperation.variation = variations;
      newCurrentOperation.marks = marks;
      return newCurrentOperation;
    });

    updateGraphListOperation((oldGraphList) => {
      graphOperation.operationId = currentOperation.id;
      const newGraphList: IGraphType[] = [...oldGraphList, graphOperation];
      return newGraphList;
    });

    updateGraphOperation((oldGraphOperation) => {
      let newGraphOperation: IGraphType = { ...oldGraphOperation };
      newGraphOperation = checkChildrensConditions(
        newGraphOperation.childrens,
        simulatorDatas,
        currentOperation.id,
        undefined,
        currentOperation.material
          ? (currentOperation.material as string)
          : undefined
      );
      return newGraphOperation;
    });
    return true;
  };

  const checkIfDisabled = () => {
    for (let i = 0; i < unitsData.length; i += 1) {
      const unit = unitsData[i];
      if (
        unit.value === '' ||
        unit.label === '' ||
        unit.scop === '' ||
        unit.power === '' ||
        unit.name === '' ||
        unit.surface === ''
      ) {
        return true;
      }
    }
    return false;
  };

  return (
    <>
      <div>
        <InputCount
          color={isThemeDark ? white : dark1}
          backgroundColor={isThemeDark ? dark2 : 'white'}
          borderRadius={radiusPx}
          addClass="mt-[1.5rem]"
          label="unité"
          valueInputCount={unitsData.length}
          onClickMinus={minusClick}
          onClickPlus={plusClick}
        />
        {unitsData.map((unit, index) => (
          <ScopUnitCard
            key={`scop-${unit.id}`}
            materialsList={materialsList}
            unitsData={unitsData}
            setUnitsData={setUnitsData}
            cardIndex={index}
          />
        ))}
        {errorSurfaceTooMuch && (
          <WarningNotification
            borderRadius={radiusPx}
            addClass="mt-[1.25rem]"
            backgroundColor={colorAlertWhiteLabel}
            color={isThemeDark ? white : dark1}
            warningText="La surface totale des unités est supérieure à celle de la surface du bien déclarée précédemment."
          />
        )}
        {errorSurfaceNotEnough && (
          <WarningNotification
            borderRadius={radiusPx}
            addClass="mt-[1.25rem]"
            backgroundColor={colorAlertWhiteLabel}
            color={isThemeDark ? white : dark1}
            warningText="La surface totale des unités est inférieure à celle de la surface du bien déclarée précédemment. Cliquez à nouveau sur Valider si vous voulez continuer quand même."
          />
        )}
      </div>
      <div className="w-full mt-[1.5rem]">
        <Button
          borderRadius={radiusPx}
          onClick={onClickNext}
          label="Suivant"
          color={colorTextButton}
          backgroundColor={colorPrimary}
          buttonArrowSmall
          disabled={checkIfDisabled()}
          dataTestId="next"
          addClass="!w-full"
        />
      </div>
    </>
  );
}

export default ScopUnits;
