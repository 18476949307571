import { useContext } from 'react';
import {
  backgroundQuaternaryLightMode,
  dark1,
  darkVariant1,
  white,
} from '@assets/colors';
import { InstallerContext } from '@auth/utils/authContext';
import { FolderContext } from '@folder/utils/FoldersContext';
import { Input } from 'storybook-design-system-drapo';

function MaterialInfosFields(): JSX.Element {
  const { radiusPx, isThemeDark } = useContext(InstallerContext);
  const {
    folderDatas,
    stopTempHeatPump,
    updateStopTempHeatPump,
    tempPowerHeatPump,
    updateTempPowerHeatPump,
    heatingElementPower,
    updateHeatingElementPower,
    otherHeatingSystems,
    updateOtherHeatingSystems,
    showDimensioningNote,
    updateShowDimensioningNote,
    dimensioningNote,
    updateDimensioningNote,
  } = useContext(FolderContext);

  const inputColor = isThemeDark ? white : dark1;
  const inputBgColor = isThemeDark
    ? darkVariant1
    : backgroundQuaternaryLightMode;
  const addClassInput = `${
    !isThemeDark ? 'light-mode-input' : 'dark-mode-input-folder'
  }`;
  const addClass = '!w-full !leading-[1.5rem]';

  const valueNotNull = (value: string) => {
    if (value !== '') {
      return '!pt-[.8rem]';
    }
    return '';
  };

  const handleChange = (value: string, type: string) => {
    switch (type) {
      case 'stopTempHeatPump':
        updateStopTempHeatPump(value);
        break;
      case 'tempPowerHeatPump':
        updateTempPowerHeatPump(value);
        break;
      case 'heatingElementPower':
        updateHeatingElementPower(value);
        break;
      case 'otherHeatingSystems':
        updateOtherHeatingSystems(value);
        break;
      default:
        break;
    }

    if (showDimensioningNote && dimensioningNote !== '') {
      updateShowDimensioningNote(false);
      updateDimensioningNote('');
    }
  };

  return (
    <div className="flex flex-col items-center space-y-[.75rem]">
      <Input
        borderRadius={radiusPx}
        color={inputColor}
        backgroundColor={inputBgColor}
        label={`T° arrêt de la ${
          folderDatas.operations.filter((el: { slug: string | string[] }) =>
            el.slug.includes('113')
          ).length > 0
            ? 'chaudière biomasse'
            : 'pompe à chaleur'
        } (°C)`}
        inputType="number"
        onChange={(e) => {
          handleChange(e.target.value, 'stopTempHeatPump');
        }}
        value={stopTempHeatPump}
        addClassInput={`${addClassInput} ${valueNotNull(stopTempHeatPump)}`}
        addClass={addClass}
        symbol="°C"
      />
      <Input
        borderRadius={radiusPx}
        color={inputColor}
        backgroundColor={inputBgColor}
        label="Puissance T° base ou T° arrêt PAC (kW)"
        inputType="number"
        onChange={(e) => {
          handleChange(e.target.value, 'tempPowerHeatPump');
        }}
        value={tempPowerHeatPump}
        addClassInput={`${addClassInput} ${valueNotNull(tempPowerHeatPump)}`}
        addClass={addClass}
        symbol="kW"
      />
      <Input
        borderRadius={radiusPx}
        color={inputColor}
        backgroundColor={inputBgColor}
        label="Puissance de la résistance électrique de la PAC (kW)"
        inputType="number"
        onChange={(e) => {
          handleChange(e.target.value, 'heatingElementPower');
        }}
        value={heatingElementPower}
        addClassInput={`${addClassInput} ${valueNotNull(heatingElementPower)}`}
        addClass={addClass}
        symbol="kW"
      />
      <Input
        borderRadius={radiusPx}
        color={inputColor}
        backgroundColor={inputBgColor}
        label="Autres appoints de chauffage (bois, électrique, …) (kW)"
        inputType="number"
        onChange={(e) => {
          handleChange(e.target.value, 'otherHeatingSystems');
        }}
        value={otherHeatingSystems}
        addClassInput={`${addClassInput} ${valueNotNull(otherHeatingSystems)}`}
        addClass={addClass}
        symbol="kW"
      />
    </div>
  );
}

export default MaterialInfosFields;
