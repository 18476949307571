import { infos_black, infoWhite } from '@assets/images/images';
import { InstallerContext } from '@auth/utils/authContext';
import { useContext } from 'react';
import { FoldersAndSimulatorContext } from '@folder/utils/FoldersContext';
import { useLocation } from 'react-router-dom';
import parse from 'html-react-parser';

function TooltipsLogin() {
  const { isThemeDark, radiusPx } = useContext(InstallerContext);
  const { whiteLabelCustom } = useContext(FoldersAndSimulatorContext);
  const location = useLocation();
  const isWhiteLabel = location.pathname.includes('wl');
  return (
    <div
      style={{ borderRadius: radiusPx }}
      className="flex items-center px-[1.5rem] py-[1.25rem]  justify-between sb-text-typo sb-text-rem-caption1 text-white dark:bg-dark-1 bg-white dark:border-none border border-borderLightMode2 dark:text-white text-[#202020]"
    >
      <p className="sb-tooltips-text">
        {isWhiteLabel &&
        whiteLabelCustom.message_connexion &&
        whiteLabelCustom.message_connexion !== '' ? (
          parse(whiteLabelCustom.message_connexion)
        ) : (
          <>
            Vous êtes intéressé par les services de Drapo ? <br />
            Contactez nous{' '}
            <a href="mailto:contact@drapo.com">contact@drapo.com</a> ou{' '}
            <a className="whitespace-nowrap" href="tel:01 76 40 37 75">
              01 76 40 37 75
            </a>
          </>
        )}
      </p>

      <img
        className="w-[2rem]"
        src={isThemeDark ? infoWhite : infos_black}
        alt="info icon"
      />
    </div>
  );
}

export { TooltipsLogin };
