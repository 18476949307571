import { useContext } from 'react';
import { getTotalCee, getTotalMpr } from '@utils/utils';
import { InstallerContext } from '@auth/utils/authContext';
import { FolderContext } from '../../utils/FoldersContext';

function EmailRecap(): JSX.Element {
  const { folderDatas, genderClient, workAddress } = useContext(FolderContext);
  const { installer, radiusPx } = useContext(InstallerContext);

  const operationListConstructor = () => {
    let list = '';
    if (folderDatas.operations.length === 1) {
      list = folderDatas.operations[0].operation_name;
    } else {
      for (let i = 0; i < folderDatas.operations.length; i += 1) {
        if (i === folderDatas.operations.length - 1) {
          list += `et ${folderDatas.operations[i].operation_name}`;
        } else if (i === folderDatas.operations.length - 2) {
          list += `${folderDatas.operations[i].operation_name} `;
        } else {
          list += `${folderDatas.operations[i].operation_name}, `;
        }
      }
    }
    return list;
  };
  const operationList = operationListConstructor();
  const primeCee = getTotalCee(folderDatas.operations);
  const primeMpr = getTotalMpr(folderDatas.operations);
  const dateFormatted =
    folderDatas.created_at !== ''
      ? new Date(folderDatas.created_at).toLocaleDateString('fr')
      : 'Date création simulation';

  return (
    <div className="p-[1.25rem] text-[.75rem] leading-[1.125rem]">
      <p>Email Récapitulatif de votre client</p>
      <div
        style={{ borderRadius: radiusPx }}
        className="h-[75vh] bg-white mt-[1.5rem]  p-[1.875rem] text-black"
      >
        <div className="border-b border-black/50 pb-[.875rem]">
          <>
            💶 {folderDatas.client.lastname} {folderDatas.client.firstname},
            voici le récapitulatif de vos primes pour{' '}
            {folderDatas.operations.length > 1
              ? 'les opérations'
              : "l'opération"}{' '}
            {operationList}.
          </>
        </div>
        <div className="pt-[1.875rem] flex justify-between items-start">
          <div className="flex flex-col pb-[.875rem]">
            <span>
              De:{' '}
              <span className="font-bold">
                {installer.raison_sociale_de_lentreprise}
              </span>
            </span>
            <span>
              A: <span className="font-bold">{folderDatas.client.email}</span>
            </span>
            <span>
              CC:{' '}
              <span className="font-bold">
                {installer.adresse_email_de_lentreprise}
              </span>
            </span>
          </div>
          <div>{dateFormatted}</div>
        </div>
        <div className="pt-[.875rem]">
          <>
            Bonjour {genderClient} {folderDatas.client.lastname}, <br /> <br />
            L&apos;entreprise {installer.raison_sociale_de_lentreprise} est
            heureuse de vous adresser le récapitulatif des primes auxquelles
            vous pouvez prétendre dans le cadre des travaux de {operationList}{' '}
            au {workAddress}
            . <br /> <br />
            {primeCee && (
              <>
                - Prime CEE : {primeCee} € <br />
              </>
            )}
            {primeMpr && (
              <>
                - MaPrimeRénov&apos; : {primeMpr} € <br />
              </>
            )}
            <br />❗ Vous trouverez en pièce jointe de cet email le cadre de
            contribution signé par {installer.raison_sociale_de_lentreprise}. Il
            pourrait vous être demandé en cas de contrôle. Conservez le
            précieusement, même après vos travaux. <br /> <br />
            Toute l&apos;équipe {installer.raison_sociale_de_lentreprise} vous
            remercie pour votre confiance et vous souhaite une bonne journée.{' '}
            <br /> <br /> -- <br /> <br /> Vous avez une questions sur ces
            primes ? <br /> Contactez {installer.raison_sociale_de_lentreprise}
            <br /> 📧&nbsp;&nbsp;par email :{' '}
            {installer.adresse_email_de_lentreprise} ou <br />{' '}
            ☎&nbsp;&nbsp;&nbsp;par téléphone au{' '}
            {installer.téléphone_de_lentreprise}.
          </>
        </div>
      </div>
    </div>
  );
}

export default EmailRecap;
