import {
  Button,
  FileMultiUpload,
  WarningNotification,
} from 'storybook-design-system-drapo';
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import Loader from '@components/loaders/Loader';
import { convertHexToRGBA, scrollSmoothToPosition } from '@utils/utils';
import { FolderContext } from '@folder/utils/FoldersContext';
import {
  addUploadFile,
  deleteUploadedFile,
  sendFolderToAirtable,
} from '@folder/apiRequests/folderRequests';
import {
  IFileFolderType,
  IOperationFolderType,
} from '@folder/utils/folderTypes';
import { IKeyValueStringType } from '@simulator/utils/simulatorTypes';
import { InstallerContext } from '@auth/utils/authContext';
import { toast } from 'react-toastify';
import {
  colorTextLightMode,
  dark1,
  dark5,
  lightModeDarkVariant5,
  red3,
  white,
} from '@assets/colors';
import ModalThermalInsulation from './ModalThermalInsulation';

type UploadFilesFolderTypes = {
  setFileToShow: Dispatch<SetStateAction<string | undefined>>;
  setTypeImage: Dispatch<SetStateAction<boolean>>;
  setRightPartActive: Dispatch<SetStateAction<boolean>>;
};

function UploadFilesFolder({
  setFileToShow,
  setTypeImage,
  setRightPartActive,
}: UploadFilesFolderTypes): JSX.Element {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [isAwait, setIsAwait] = useState<boolean>(false);
  const [err, setErr] = useState<boolean>(false);
  const [errMessage, setErrMessage] = useState<string>('');
  const [fileUploadName, setFileUploadName] = useState<string>('');
  const [categoryToDelete, setCategoryToDelete] = useState<string>('');
  const [webSocketConnected, setWebSocketConnected] = useState<boolean>(false);
  const [hasThermalInsulation, setHasThermalInsulation] =
    useState<boolean>(false);

  const hasModal = (slug: string) => {
    if (
      slug.includes('th-104') ||
      slug.includes('th-113') ||
      slug.includes('th-159')
    ) {
      return true;
    }
    return false;
  };
  const [showModal, setShowModal] = useState<boolean>(false);

  const {
    folderDatas,
    updateFolderDatas,
    globalFiles,
    updateGlobalFiles,
    folderReference,
    folderInCreation,
    updateFolderInCreation,
    modalThermalInsulationAlreadyClosed,
    updateModalThermalInsulationAlreadyClosed,
  } = useContext(FolderContext);

  const {
    installer,
    colorPrimary,
    radiusPx,
    pusher,
    isThemeDark,
    colorTextButton,
  } = useContext(InstallerContext);

  const [isSameFile, setIsSameFile] = useState<boolean>(false);

  useEffect(() => {
    if (err && errMessage !== '') {
      scrollSmoothToPosition(0);
    }
  }, [errMessage]);

  const getFileType = (type: string) => {
    return type.indexOf('photo') !== -1 ? ['image'] : ['pdf'];
  };

  const typeIsImg = (type: string) => {
    return type.indexOf('photo') !== -1;
  };

  const transformFilesList = (fileName: string, list: string[] | null) => {
    const transformedList: IKeyValueStringType[] = [];
    if (list) {
      for (let i = 0; i < list.length; i += 1) {
        transformedList.push({
          name: `${fileName}_${i + 1}`,
          path: list[i],
        });
      }
    }
    return transformedList;
  };

  const checkIfAllMandatoriesOk = () => {
    if (hasThermalInsulation) {
      if (!modalThermalInsulationAlreadyClosed) {
        return true;
      }
    }
    if (isLoading) {
      return true;
    }
    if (isAwait) {
      return true;
    }
    for (let o = 0; o < folderDatas.operations.length; o += 1) {
      const operationFiles = folderDatas.operations[o].files;
      for (let i = 0; i < operationFiles.length; i += 1) {
        const file = operationFiles[i];
        if (file.mandatory) {
          if (typeof file.path === 'object') {
            if (file.path?.length === 0) {
              return true;
            }
          } else if (file.path === null) {
            return true;
          }
        }
      }
    }
    for (let i = 0; i < globalFiles.length; i += 1) {
      const file = globalFiles[i];
      if (file.mandatory) {
        if (typeof file.path === 'object') {
          if (file.path?.length === 0) {
            return true;
          }
        } else if (file.path === null) {
          return true;
        }
      }
    }
    return false;
  };

  const onClickNext = () => {
    setIsLoading(true);
    sendFolderToAirtable(
      folderReference,
      setIsLoading,
      updateFolderInCreation,
      setErr,
      setErrMessage
    );
  };

  const onDeleteFile = (
    pathIndex: number,
    fileExpected: IFileFolderType,
    operationRef?: string
  ) => {
    setIsAwait(true);
    const fileName = fileExpected.path ? fileExpected.path[pathIndex] : '';
    deleteUploadedFile(
      setIsAwait,
      fileExpected,
      folderReference,
      fileName,
      updateGlobalFiles,
      updateFolderDatas,
      folderDatas,
      operationRef
    );
  };

  const handleClick = (file: IFileFolderType, filePath: string) => {
    if (typeIsImg(file.type)) {
      setTypeImage(true);
    } else {
      setTypeImage(false);
    }
    setFileToShow(filePath);
  };

  const onUploadFile = (
    file: File,
    fileExpected: IFileFolderType,
    operationRerence?: string
  ) => {
    setFileToShow('');
    setErr(false);
    setRightPartActive(false);
    addUploadFile(
      folderReference,
      file,
      fileExpected,
      updateGlobalFiles,
      updateFolderDatas,
      folderDatas,
      setErr,
      setErrMessage,
      setRightPartActive,
      setCategoryToDelete,
      setFileUploadName,
      setFileToShow,
      operationRerence
    );
    if (file.type !== 'application/pdf') {
      setTypeImage(true);
    } else {
      setTypeImage(false);
    }
    if (isAwait) {
      setIsAwait(false);
    }
  };

  const backgroundUploadedColor =
    installer.nom_partenaire === 'DRAPO' || installer.nom_partenaire === ''
      ? '#2f3354'
      : lightModeDarkVariant5;

  const radiusPxUpload = installer.nom_partenaire === 'EASYCEE' ? '0px' : '8px';

  const webSocketHandler = () => {
    const channel = pusher?.subscribe(
      folderDatas.from_bulk_import
        ? `bulkimport.${installer.id}`
        : `dossier.${installer.id}`
    );
    const eventName = folderDatas.from_bulk_import
      ? 'pusher:checking'
      : 'dossier:airtable';
    // eslint-disable-next-line no-unused-expressions
    channel?.bind(eventName, (data: any) => {
      if (
        Object.keys(data).includes('folder') &&
        data.folder === folderReference
      ) {
        setIsLoading(false);
        updateFolderInCreation(false);
        // eslint-disable-next-line no-unused-expressions
        pusher?.unsubscribe(
          folderDatas.from_bulk_import
            ? `bulkimport.${installer.id}`
            : `dossier.${installer.id}`
        );
        if (data.pushed === false) {
          setErrMessage(
            'Un problème est survenu lors de la création de votre dossier.'
          );
          setErr(true);
        } else {
          navigate(`/suivi-de-dossier/${folderReference}`);
        }
        toast('Dossier créé avec succès.', { type: 'success' });
      }
    });
    setWebSocketConnected(true);
  };

  useEffect(() => {
    if (folderInCreation && !webSocketConnected) {
      webSocketHandler();
    }
  }, [folderInCreation]);

  useEffect(() => {
    checkIfAllMandatoriesOk();
  }, [isAwait]);

  useEffect(() => {
    if (isSameFile) {
      toast(
        '⛔️ Un fichier avec le même nom a déjà été renseigné pour ce document'
      );
    }
  }, [isSameFile]);

  useEffect(() => {
    const hasThermal = folderDatas.operations.filter((el) => hasModal(el.slug));
    setHasThermalInsulation(
      folderDatas.completed ? false : hasThermal.length > 0
    );
  }, [folderDatas]);

  return (
    <>
      {err && (
        <WarningNotification
          borderRadius={radiusPx}
          warningText={errMessage}
          backgroundColor={
            installer.nom_partenaire === 'DRAPO' ||
            installer.nom_partenaire === ''
              ? red3
              : installer.custom.couleur_alerte
          }
          color="#ffffff"
          addClass="mt-[1.25rem]"
        />
      )}
      {globalFiles !== undefined && globalFiles.length > 0 && (
        <div
          style={{ borderRadius: radiusPx }}
          className="dark:bg-dark-2 bg-white p-[1.5rem] mt-[2rem] dark:text-white text-colorTextLightMode"
        >
          <p className="mb-[1rem]">Documents globaux</p>
          {globalFiles.map((globalFile: IFileFolderType) => (
            <FileMultiUpload
              setActiveToast={setIsSameFile}
              containerBackgroundColor={
                isThemeDark ? dark1 : convertHexToRGBA(colorPrimary, 0.1)
              }
              colorIcon={colorTextButton}
              color={isThemeDark ? white : colorTextLightMode}
              backgroundColorAddFile={isThemeDark ? dark5 : white}
              borderRadiusContainer={radiusPx}
              borderRadius={radiusPxUpload}
              key={`${globalFile.title}-${globalFile.type}`}
              fileName={globalFile.title}
              onClick={(file: IKeyValueStringType) => {
                handleClick(globalFile, file.path);
                setErr(false);
                setRightPartActive(false);
              }}
              onChangeFileUpload={(file: File) => {
                onUploadFile(file, globalFile);
              }}
              onDelete={(pathIndex: number) => {
                setIsAwait(true);
                setRightPartActive(true);
                onDeleteFile(pathIndex, globalFile);
              }}
              filesUploaded={transformFilesList(
                globalFile.type,
                globalFile.path
              )}
              required={globalFile.mandatory}
              fileTypes={getFileType(globalFile.type)}
              notOpenInNewTab
              errDetected={err}
              fileNameUpload={fileUploadName}
              categoryToDelete={categoryToDelete}
              fillLoaderColor={colorPrimary}
              backgroundColorFileLoaded={colorPrimary}
              backgroundUploadedColor={backgroundUploadedColor}
              backgroundColorComponent={
                isThemeDark ? dark1 : convertHexToRGBA(colorPrimary, 0.1)
              }
              generatedPath={
                globalFile.generated ? globalFile.generated : undefined
              }
              isFileUploading={false}
              addClass="mb-[.75rem]"
            />
          ))}
        </div>
      )}
      {folderDatas.operations.map((operation: IOperationFolderType) => (
        <div
          style={{ borderRadius: radiusPx }}
          key={operation.operation_reference}
          className="dark:bg-dark-2 bg-white  p-[1.5rem] mt-[2rem] dark:text-white text-colorTextLightMode"
        >
          <p className="mb-[1rem]">
            Documents de l&apos;opération {operation.operation_name}{' '}
          </p>
          {operation.files.map((operationFile: IFileFolderType) => (
            <div key={`${operation.operation_reference}-${operationFile.type}`}>
              <FileMultiUpload
                colorIcon={colorTextButton}
                containerBackgroundColor={
                  isThemeDark ? dark1 : convertHexToRGBA(colorPrimary, 0.1)
                }
                color={isThemeDark ? white : dark1}
                backgroundColorAddFile={isThemeDark ? dark5 : white}
                borderRadiusContainer={radiusPx}
                borderRadius={radiusPxUpload}
                fileName={operationFile.title}
                onClick={(file: IKeyValueStringType) => {
                  handleClick(operationFile, file.path);
                  setErr(false);
                  setRightPartActive(false);
                }}
                onChangeFileUpload={(file: File) => {
                  setShowModal(!modalThermalInsulationAlreadyClosed);
                  onUploadFile(
                    file,
                    operationFile,
                    operation.operation_reference
                  );
                }}
                onDelete={(pathIndex: number) => {
                  setIsAwait(true);
                  setRightPartActive(true);
                  onDeleteFile(
                    pathIndex,
                    operationFile,
                    operation.operation_reference
                  );
                }}
                filesUploaded={transformFilesList(
                  operationFile.type,
                  operationFile.path
                )}
                required={operationFile.mandatory}
                fileTypes={getFileType(operationFile.type)}
                notOpenInNewTab
                errDetected={err}
                fileNameUpload={fileUploadName}
                categoryToDelete={categoryToDelete}
                fillLoaderColor={colorPrimary}
                backgroundColorFileLoaded={colorPrimary}
                backgroundUploadedColor={backgroundUploadedColor}
                backgroundColorComponent={
                  isThemeDark ? dark1 : convertHexToRGBA(colorPrimary, 0.1)
                }
                generatedPath={
                  operationFile.generated ? operationFile.generated : undefined
                }
                isFileUploading={false}
                addClass="mb-[.75rem]"
                setActiveToast={setIsSameFile}
              />
            </div>
          ))}
          {showModal && hasModal(operation.slug) && (
            <ModalThermalInsulation
              setModalThermalInsulationAlreadyClosed={
                updateModalThermalInsulationAlreadyClosed
              }
              setShowModal={setShowModal}
            />
          )}
        </div>
      ))}
      <div className="w-full pb-[3rem]">
        <Button
          disabled={checkIfAllMandatoriesOk()}
          addClass="mt-[1.5rem] !w-full !px-[1rem]"
          buttonArrowSmall
          label="Valider et créer le dossier"
          color="white"
          backgroundColor={colorPrimary}
          arrow
          onClick={() => onClickNext()}
        />
        {isLoading && (
          <div className="mt-[1.5rem]">
            <Loader />
          </div>
        )}
      </div>
    </>
  );
}

export default UploadFilesFolder;
