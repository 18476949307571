import React, { Dispatch, SetStateAction } from 'react';
import { IInstallerNotification } from '@dashboard/utils/dashboardTypes';
import Pusher from 'pusher-js';
import { IInstaller } from './authType';

interface AuthContextState {
  token: string | null;
  updateToken: Dispatch<SetStateAction<string>>;
  updateTokenConnected: Dispatch<SetStateAction<boolean>>;
}

export const AuthContext = React.createContext({} as AuthContextState);

interface InstallerContextState {
  installer: IInstaller;
  updateInstaller: Dispatch<SetStateAction<IInstaller>>;
  installerIsLoaded: boolean;
  updateInstallerIsLoaded: Dispatch<SetStateAction<boolean>>;
  eligibilityInstallerDemo: string[];
  updateEligibilityInstallerDemo: Dispatch<SetStateAction<string[]>>;
  installerNotification: IInstallerNotification | null;
  updateInstallerNotification: Dispatch<
    SetStateAction<IInstallerNotification | null>
  >;
  radiusPx: string;
  colorPrimary: string;
  colorAlertWhiteLabel: string;
  imgTemplate: string;
  pusher: Pusher | undefined;
  isThemeDark: boolean;
  updateIsThemeDark: Dispatch<SetStateAction<boolean>>;
  updateThemeChangedManually: Dispatch<SetStateAction<boolean>>;
  colorTextButton: string;
  displayModalContratMixteMpr: boolean;
  updateDisplayModalContratMixteMpr: Dispatch<SetStateAction<boolean>>;
}

export const InstallerContext = React.createContext(
  {} as InstallerContextState
);
