import { InstallerContext } from '@auth/utils/authContext';
import { IDdcNavElement, INbNotifs } from '@dashboard/utils/dashboardTypes';
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import { v4 } from 'uuid';
import NavButtonDDC from './NavButtonDDC';

interface INavRequestForSupplementProps {
  elementActive: string;
  setElementActive: Dispatch<SetStateAction<string>>;
  nbNotifs: INbNotifs;
  isRetractable?: boolean;
  setIsRetractable?: Dispatch<SetStateAction<boolean>>;
}

function NavRequestForSupplements({
  elementActive,
  setElementActive,
  nbNotifs,
  isRetractable,
  setIsRetractable,
}: INavRequestForSupplementProps): JSX.Element {
  const { installer, eligibilityInstallerDemo } = useContext(InstallerContext);

  const demoActive =
    eligibilityInstallerDemo.includes('CEE') ||
    eligibilityInstallerDemo.includes('MPR');

  const [oneTypeContract, setOneTypeContract] = useState<boolean>(false);

  const renderNavElements = (eligibility: string[]): IDdcNavElement[] => {
    const arrayNavElements: IDdcNavElement[] = [
      {
        title: 'Toutes',
        nb_notif: nbNotifs.toutes,
      },
    ];
    if (eligibility.includes('MPR')) {
      arrayNavElements.push({
        title: 'MPR',
        nb_notif: nbNotifs.mpr,
      });
    }
    if (eligibility.includes('CEE')) {
      arrayNavElements.push({
        title: 'CEE',
        nb_notif: nbNotifs.cee,
      });
    }
    return arrayNavElements;
  };

  const [navElements, setNavElements] = useState<IDdcNavElement[]>(
    renderNavElements(installer.eligibility)
  );

  useEffect(() => {
    if (demoActive) {
      setNavElements(renderNavElements(eligibilityInstallerDemo));
    }
  }, [demoActive]);

  useEffect(() => {
    setNavElements(renderNavElements(installer.eligibility));
    setOneTypeContract(
      !!(
        !installer.eligibility.includes('CEE') ||
        !installer.eligibility.includes('MPR')
      )
    );
  }, [installer.eligibility]);

  useEffect(() => {
    setNavElements(renderNavElements(installer.eligibility));
  }, [nbNotifs]);

  useEffect(() => {
    if (oneTypeContract) {
      setElementActive(
        !eligibilityInstallerDemo.includes('CEE') ? 'MPR' : 'CEE'
      );
    }
  }, [oneTypeContract]);

  return (
    <div
      className={`${
        isRetractable
          ? 'flex flex-col space-y-[1rem] items-center mx-[1.375rem]'
          : 'flex space-x-[1rem] absolute bottom-[.75rem]'
      }  text-[.75rem]`}
    >
      {navElements
        .filter(
          (el) =>
            el.title !== 'Toutes' || (el.title === 'Toutes' && !oneTypeContract)
        )
        .map((el) => (
          <div key={v4()} className={isRetractable ? 'w-[5.5rem]' : 'w-full'}>
            <NavButtonDDC
              title={el.title}
              elementActive={elementActive}
              setElementActive={setElementActive}
              isRetractable={isRetractable}
              setIsRetractable={setIsRetractable}
              nbNotifs={el.nb_notif}
            />
          </div>
        ))}
    </div>
  );
}

export { NavRequestForSupplements };

NavRequestForSupplements.defaultProps = {
  isRetractable: false,
  setIsRetractable: undefined,
};
