export const initialFinancials = {
  amount_total: 0,
  categories: [
    {
      title: '',
      amount: '',
      nb_folders: 0,
      color: '',
    },
  ],
  nb_folders_total: 0,
  title: '',
};

export const initialStatus = {
  total: 0,
  datas: [
    {
      amount: 0,
      nb_folders: 0,
      step: '',
      step_number: 0,
      title: '',
      filter: {
        mpr_status: [''],
        cee_status: [''],
      },
    },
  ],
};

export const initialOperationMonitoringData = {
  adresse_email_client: '',
  adresse_travaux: '',
  bonus: false,
  ah_originale: '',
  civilité_client: '',
  commentaire_référent_dossier: '',
  date_estimée_virement_cee: '',
  date_estimée_virement_mpr: '',
  ddc: null,
  dernier_rt: '',
  facture_travaux: '',
  frais_dossier_mpr_ht: '',
  last_cee: '',
  last_mpr: '',
  libellé_opération: '',
  montant_mpr: '',
  no_mpr: '',
  nom_client: '',
  photos_après_travaux: '',
  photos_avant_travaux: '',
  prime_cee_bénéficiaire: '',
  prénom_client: '',
  référence_opération: '',
  rc: '',
  statut_cee: '',
  statut_mpr: '',
  step_cee: '',
  step_mpr: '',
  text_cee: '',
  text_mpr: '',
  type_dossier: '',
  téléphone_client: '',
  téléphone_référent: '',
  virement_mpr_pro: '',
  catégorie_ménage: '',
  nombre_personnes_dans_ménage: '',
  surface: '',
  type_logement: '',
  type_propriétaire: '',
  age_logement: '',
  matériel_déposé: '',
  coordonnées_géographiques_nationales: '',
  devis_signé: '',
  mandat_mixte_mpr_généré: '',
  mandat_mixte_mpr_signé: '',
  status_payment_cee: '',
  status_payment_mpr: '',
  statut_signature_ah: '',
};
export const initialListFolders = {
  folder_reference: '',
  operation_reference: '',
  frais_dossier_cee_ttc: '',
  cee_folder_fees: '',
  référence_opération: '',
  deletable: 0,
  created_at: '',
  status: '',
  bonus: false,
  operation: '',
  menage_type: '',
  lastname: '',
  firstname: '',
  work_street: '',
  work_zipcode: '',
  work_city: '',
  mpr_amount: '',
  prime_cee_beneficiary: '',
  mpr_management: true,
  phone: '',
  email: '',
  mpr_folder_fees: '',
  adresse_email_client: '',
  adresse_travaux: '',
  catégorie_ménage: '',
  commentaires_référents_dossier: '',
  frais_dossier_mpr_ttc: '',
  gestion_mpr_drapo: '',
  installateur: '',
  montant_mpr: '',
  prime_cee_bénéficiaire: '',
  nom_client: '',
  opération: '',
  prénom_client: '',
  référence_dossier: '',
  statut_cee: '',
  statut_dossier: '',
  statut_mpr: '',
  statut_paiement_bonus: '',
  statut_paiement_mpr: '',
  téléphone_client: '',
  virement_mpr_pro: '',
  sous_opération: '',
};

export const initialCurrentSchemaCustomView = {
  id: '',
  name: '',
  order: '',
  schema: {
    fields: [{ provider_id: '', provider_name: '', provider_type: '' }],
    groupBy: [{ provider_id: '', provider_name: '' }],
    whereClauses: {
      type: 'AND',
      conditions: [
        {
          condition: '',
          provider_id: '',
          provider_name: '',
          value: '',
        },
      ],
    },

    provider_id: '',
    provider_name: '',
  },
};

export const initialSchemaCustomView = {
  id: '',
  name: '',
  fields: [],
  provider_id: '',
  provider_name: '',
};

export const initialDatasMyProfil = {
  login: {
    field: 'login',
    value: '',
    editable: false,
  },
  password: {
    field: 'password',
    value: '',
    editable: false,
  },
  date_dinscription: {
    field: 'date_dinscription',
    value: '',
    editable: false,
  },
  site_web_de_lentreprise: {
    field: 'site_web_de_lentreprise',
    value: '',
    editable: false,
  },
  téléphone_de_lentreprise: {
    field: 'téléphone_de_lentreprise',
    value: '',
    editable: false,
  },
  nombre_de_salariés: {
    field: 'nombre_de_salariés',
    value: '',
    editable: false,
  },
  chiffre_daffaire: {
    field: 'chiffre_daffaire',
    value: '',
    editable: false,
  },
  logo: {
    field: 'logo',
    value: [''],
    editable: false,
  },
  iban: {
    field: 'iban',
    value: '',
    editable: false,
  },
  bic: {
    field: 'bic',
    value: '',
    editable: false,
  },
  contrats_sous_traitance: {
    field: 'contrats_sous_traitance',
    value: [''],
    editable: false,
  },
  rge: {
    field: 'rge',
    value: [''],
    editable: false,
  },
  siren: {
    field: 'siren',
    value: '',
    editable: false,
  },
  siret: {
    field: 'siret',
    value: '',
    editable: false,
  },
  ville_du_rcs: {
    field: 'ville_du_rcs',
    value: '',
    editable: false,
  },
  capital_social: {
    field: 'capital_social',
    value: '',
    editable: false,
  },
  année_de_création_de_lentreprise: {
    field: 'année_de_création_de_lentreprise',
    value: null,
    editable: false,
  },
  rue_du_siège_social: {
    field: 'rue_du_siège_social',
    value: '',
    editable: false,
  },
  prénom_du_signataire: {
    field: 'prénom_du_signataire',
    value: '',
    editable: false,
  },
  adresse_email_du_service_comptabilité: {
    field: 'adresse_email_du_service_comptabilité',
    value: '',
    editable: false,
  },
  contrat_mpr: {
    field: 'contrat_mpr',
    value: '',
    editable: false,
  },
  date_signature_contrat_mpr: {
    field: 'date_signature_contrat_mpr',
    value: null,
    editable: false,
  },
  contrat_cee: {
    field: 'contrat_cee',
    value: '',
    editable: false,
  },
  date_signature_contrat_cee: {
    field: 'date_signature_contrat_cee',
    value: null,
    editable: false,
  },
};

export const initialCountsCustomView = {
  nb_folders: 0,
  'Montant MPR géré par Drapo': 0,
  'Montant TTC devis': 0,
  'Prime CEE bénéficiaire': 0,
  'Prime CEE installateur': 0,
  'Virement MPR pro': 0,
};
