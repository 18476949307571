import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { v4 } from 'uuid';
import {
  FileMultiUpload,
  ButtonSb,
  CheckCard,
} from 'storybook-design-system-drapo';
import { InstallerContext } from '@auth/utils/authContext';
import Loader from '@components/loaders/Loader';
import {
  editPencilWhite,
  editPencilBlack,
  validate,
} from '@assets/images/images';
import { addCEEFiles } from '@folderMonitoring/apiRequest/folderMonitoringRequest';
import {
  ICeeDocuments,
  IOperationsMonitoringDatas,
} from '@folderMonitoring/utils/folderMonitoringTypes';
import {
  dark1,
  dark5,
  white,
  colorTextLightMode,
  backgroundQuaternaryLightMode,
} from '@assets/colors';
import { convertHexToRGBA } from '@utils/utils';
import { CloseIcon } from '@assets/svgComponents/svgComponents';
import { toast } from 'react-toastify';
/*
import InputInvoiceAmount from '@folderMonitoring/components/recapFolder/InputInvoiceAmount';
*/
import { isVolumeExceed } from '@folderMonitoring/utils/functions';
import { FolderMonitoringContext } from '../../utils/context/folderMonitoringContext';

interface ICeeDocumentsProps {
  reference_operation: string;
  email: string;
  tel: string;
  setDetailsActive: Dispatch<SetStateAction<boolean>>;
  operation: IOperationsMonitoringDatas;
  hasInvoice: boolean;
  /*
  montant_facture_ttc_attestation_consentement_dev: number;
  mprAmountQuote: number;
  */
}

function CeeDocuments({
  reference_operation,
  email,
  tel,
  setDetailsActive,
  operation,
  hasInvoice,
}: /*
  montant_facture_ttc_attestation_consentement_dev,
  mprAmountQuote,
  */
ICeeDocumentsProps): JSX.Element {
  const { radiusPx, colorPrimary, isThemeDark, colorTextButton, installer } =
    useContext(InstallerContext);
  const {
    folderDatas,
    folderActif,
    updateFolderDatas,
    updateStepIsUpdate /* hasAttestation */,
  } = useContext(FolderMonitoringContext);

  const radius = radiusPx === '0px' ? '0px' : '8px';

  /*
  const [invoiceAmountToCompare, setInvoiceAmountToCompare] = useState<string>(
    montant_facture_ttc_attestation_consentement_dev !== null
      ? montant_facture_ttc_attestation_consentement_dev.toString()
      : '0'
  );
  const [isMatchingAmount, setIsMatchingAmount] = useState<boolean>(
    montant_facture_ttc_attestation_consentement_dev !== null
      ? invoiceAmountToCompare === mprAmountQuote.toString()
      : false
  );
  const [isCompare, setIsCompare] = useState<boolean>(
    montant_facture_ttc_attestation_consentement_dev !== null || hasAttestation
  );
  */

  const [isFileUploading, setIsFileUploading] = useState<boolean>(false);
  const [filesToSend, setFilesToSend] = useState<ICeeDocuments[]>([]);
  const [fileName, setFileName] = useState<string>('');

  const [isOk, setIsOk] = useState<boolean>(false);
  const [isEditable, setIsEditable] = useState<boolean>(false);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [errorCSS, setErrorCSS] = useState<string>('');
  const [isSend, setIsSend] = useState<boolean>(false);

  const [requiredFilesNameUploaded, setRequiredFilesNameUploaded] = useState<
    string[]
  >([]);
  const [allRequiredUploaded, setAllRequiredUploaded] =
    useState<boolean>(false);

  const [emailClient, setEmailClient] = useState<string>(email);
  const [telClient, setTelClient] = useState<string>(tel);
  const [endOfWorksiteDate, setEndOfWorksiteDate] = useState<string>('');

  const [changeValue, setChangeValue] = useState<string>('');
  const [isChange, setIsChange] = useState<string>('');

  const [isSameFile, setIsSameFile] = useState<boolean>(false);

  const [interiorUnitIsHeated, setInteriorUnitIsHeated] =
    useState<boolean>(false);

  const hasPhotoEquilibrage = installer.has_photo_equilibrage;

  const libelle = useMemo(() => {
    return operation.libellé_opération !== null
      ? operation.libellé_opération.replace(/["{}]/g, '').split('- BAR')
      : [''];
  }, [operation]);

  const calorifugeage = useMemo(() => {
    return (
      libelle[1].includes('TH-104') ||
      libelle[1].includes('TH-171') ||
      libelle[1].includes('TH-172') ||
      libelle[1].includes('TH-159')
    );
  }, [operation]);

  const isBarTh173 = useMemo(() => {
    return libelle[1].includes('TH-173');
  }, [operation]);

  const isBarEn101Combles = useMemo(() => {
    return (
      libelle[1].includes('EN-101') &&
      folderActif[0].sous_opération === 'Combles perdus'
    );
  }, [operation]);

  const isBarEn101Rampants = useMemo(() => {
    return (
      libelle[1].includes('EN-101') &&
      folderActif[0].sous_opération === 'Rampants de toitures'
    );
  }, [operation]);

  const isBarEn102ITE = useMemo(() => {
    return (
      libelle[1].includes('EN-102') && folderActif[0].sous_opération === 'ITE'
    );
  }, [operation]);

  const isBarEn102ITI = useMemo(() => {
    return (
      libelle[1].includes('EN-102') && folderActif[0].sous_opération === 'ITI'
    );
  }, [operation]);

  const isBarEn103 = useMemo(() => {
    return libelle[1].includes('EN-103');
  }, [operation]);

  const displayDimensioningNote = () => {
    if (calorifugeage) {
      if (
        operation.note_dimensionnement === '{}' ||
        operation.note_dimensionnement === null
      ) {
        return true;
      }
    }
    return false;
  };

  const listFilteredWithTitles = (
    list: { title: string; required: boolean }[]
  ) => list.filter((item) => item.title !== '');

  const listFilteredWithTitlesAndRequired = (
    list: { title: string; required: boolean }[]
  ) => list.filter((item) => item.title !== '' && item.required);

  const filesList = [
    { title: `${!hasInvoice ? 'Facture' : ''}`, required: true },
    { title: 'Devis signé', required: true },
    {
      title: `Photos avant chantier${calorifugeage ? '' : ' (recommandé)'}`,
      required: calorifugeage,
    },
    {
      title: `${displayDimensioningNote() ? 'Note de dimensionnement' : ''}`,
      required: true,
    },
    {
      title: `${!calorifugeage ? 'Photos après chantier (recommandé)' : ''}`,
      required: false,
    },
  ];

  const exteriorUnitPostWorksiteFiles = [
    {
      title: 'Photo d’ensemble (montrant la fixation)',
      required: true,
      scope: 'file_photo_after_work_outside[]',
    },
    {
      title: 'Photo plaque signalétique',
      required: true,
      scope: 'file_photo_after_work_outside[]',
    },
    {
      title: 'Photo écart unité/mur',
      required: true,
      scope: 'file_photo_after_work_outside[]',
    },
  ];

  const interiorUnitPostWorksiteFiles = [
    {
      title: 'Photo d’ensemble',
      required: true,
      scope: 'file_photo_after_work_inside[]',
    },
    {
      title: `${hasPhotoEquilibrage ? "Photo dispositif d'équilibrage" : ''}`,
      required: hasPhotoEquilibrage,
      scope: 'file_photo_after_work_inside[]',
    },
  ];

  const unheatedVolumeFiles = [
    {
      title: 'Photo calorifugeage du réseau hydraulique nouveau',
      required: true,
      scope: 'file_photo_after_work_inside[]',
    },
    {
      title: 'Photo calorifugeage du réseau hydraulique existant',
      required: true,
      scope: 'file_photo_after_work_inside[]',
    },
    {
      title: 'Photo calorifugeage des flexibles',
      required: true,
      scope: 'file_photo_after_work_inside[]',
    },
  ];

  const heatedVolumeFiles = [
    {
      title:
        'Photo de la pièce comprenant l’unité intérieure et un émetteur de chauffage fixe',
      required: true,
      scope: 'file_photo_after_work_inside[]',
    },
  ];

  const barTh173Files = [
    {
      title:
        'PDFs certifiés de l’interface de pilotage, de l’application lancée et fonctionnelle, et du dispositif régulant chaque émetteur',
      required: true,
      scope: 'file_photo_regulation_command[]',
    },
    /* {
      title: 'Photo (pdf certifié) de l’application lancée et fonctionnelle',
      required: true,
      scope: 'file_photo_regulation_software[]',
    },
    {
      title: `Photos (pdf certifiés) du dispositif régulant l’émission des émetteurs (1 photo par émetteur)`,
      required: true,
      scope: 'file_photo_regulation_transmitters[]',
    }, */
  ];

  const barEn101ComblesFiles = [
    {
      title: 'Photo du Pare vapeur (si nécessaire)',
      required: false,
      scope: 'file_photo_during_work[]',
    },
    {
      title:
        'Photos d’ensembles avec les éléments suivants : Trappe d’accès, écart au feu, capots de protection, déflecteurs',
      required: true,
      scope: 'file_photo_after_work[]',
    },
  ];

  const barEn101RampantsFiles = [
    {
      title: 'Photo du pare vapeur',
      required: true,
      scope: 'file_photo_during_work[]',
    },
    {
      title: 'Photo du rail',
      required: true,
      scope: 'file_photo_during_work[]',
    },
    {
      title: 'Photo de l’épaisseur de l’isolant',
      required: true,
      scope: 'file_photo_during_work[]',
    },
    {
      title: 'Photo de l’écart au feu (si présence)',
      required: false,
      scope: 'file_photo_during_work[]',
    },
    {
      title: 'Photo d’ensemble',
      required: true,
      scope: 'file_photo_after_work[]',
    },
  ];

  const barEn102ITEFiles = [
    {
      title: 'Photos d’ensembles avant les éléments de finition',
      required: true,
      scope: 'file_photo_during_work[]',
    },
    {
      title: 'Photos du profilé de départ',
      required: true,
      scope: 'file_photo_during_work[]',
    },
    {
      title: 'Photos de l’épaisseur de l’isolant',
      required: true,
      scope: 'file_photo_during_work[]',
    },
    {
      title:
        'Photos de toutes les façades isolées avec les éléments suivants : bavettes, écart au sol, couvertine (partie haute et basse)',
      required: true,
      scope: 'file_photo_after_work[]',
    },
  ];

  const barEn102ITIFiles = [
    {
      title: 'Photo du pare vapeur',
      required: true,
      scope: 'file_photo_during_work[]',
    },
    {
      title: 'Photo du rail',
      required: true,
      scope: 'file_photo_during_work[]',
    },
    {
      title: 'Photo de l’épaisseur de l’isolant',
      required: true,
      scope: 'file_photo_during_work[]',
    },
    {
      title:
        'Photos d’ensembles avec les éléments suivants : éléments électrique, radiateurs et canalisations',
      required: true,
      scope: 'file_photo_after_work[]',
    },
  ];

  const barEn103Files = [
    {
      title: 'Photo du pare vapeur (si nécessaire)',
      required: false,
      scope: 'file_photo_during_work[]',
    },
    {
      title: 'Photo de l’épaisseur de l’isolant',
      required: true,
      scope: 'file_photo_during_work[]',
    },
    {
      title:
        'Photo d’ensembles avec les éléments suivants : écart au feu, poutre montrant les 3 faces, points lumineux',
      required: true,
      scope: 'file_photo_after_work[]',
    },
  ];

  const numberFilesRequired =
    listFilteredWithTitlesAndRequired(filesList).length +
    (!calorifugeage
      ? 0
      : exteriorUnitPostWorksiteFiles.length +
        listFilteredWithTitlesAndRequired(interiorUnitPostWorksiteFiles)
          .length +
        (interiorUnitIsHeated
          ? heatedVolumeFiles.length
          : unheatedVolumeFiles.length)) +
    (!isBarTh173 ? 0 : barTh173Files.length) +
    (!isBarEn101Combles
      ? 0
      : barEn101ComblesFiles.filter((file) => file.required).length) +
    (!isBarEn101Rampants
      ? 0
      : barEn101RampantsFiles.filter((file) => file.required).length) +
    (!isBarEn102ITE
      ? 0
      : barEn102ITEFiles.filter((file) => file.required).length) +
    (!isBarEn102ITI
      ? 0
      : barEn102ITIFiles.filter((file) => file.required).length) +
    (!isBarEn103 ? 0 : barEn103Files.filter((file) => file.required).length);

  const infosClient = [
    { title: 'Email Client', value: emailClient, type: 'email' },
    { title: 'Téléphone Client', value: telClient, type: 'tel' },
  ];

  const checkEmail = (mail: string) => {
    const reg =
      /^(([^<>()[\]\\.,;:\s@\\"]+(\.[^<>()[\]\\.,;:\s@\\"]+)*)|(\\".+\\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return reg.test(mail);
  };

  const checkTel = (phone: string) => {
    const reg = /^\d{10}$/;
    return reg.test(phone);
  };

  const validateChange = (type: string) => {
    if (type === 'email') {
      if (checkEmail(changeValue)) {
        setEmailClient(changeValue);
        setIsEditable(false);
        setChangeValue('');
        setIsChange('');
      } else {
        setError("L'email n'est pas valide");
        setErrorCSS('border-red-500');
      }
    }
    if (type === 'tel') {
      if (checkTel(changeValue)) {
        setTelClient(changeValue);
        setIsEditable(false);
        setChangeValue('');
        setIsChange('');
      } else {
        setError("Le numéro de téléphone n'est pas valide");
        setErrorCSS('border-red-500');
      }
    }
  };

  const closeChange = () => {
    setIsEditable(false);
    setChangeValue('');
    setIsChange('');
  };

  const whichFile = (index: number) => {
    const tab = filesToSend;
    const file: ICeeDocuments[] = [];

    for (let i = 0; i < tab.length; i += 1) {
      if (tab[i].index === index) {
        file.push(tab[i]);
      }
    }
    return file;
  };

  const onUploadFile = (
    file: File,
    index: number,
    name: string,
    required: boolean,
    scope: string
  ) => {
    setIsFileUploading(false);
    setFileName(file.name);
    setFilesToSend((prev: ICeeDocuments[]) => [
      ...prev,
      {
        name: file.name,
        path: URL.createObjectURL(file),
        index,
        file,
        slug: name,
        scope,
      },
    ]);
    if (required && requiredFilesNameUploaded.indexOf(name) === -1) {
      setRequiredFilesNameUploaded((prev) => [...prev, name]);
    }
  };

  const filterFilesByIndexPrefix = (indexPrefix: number): ICeeDocuments[] => {
    return filesToSend.filter((file) => {
      const firstDigit = Math.floor(file.index / 10); // Récupère le premier chiffre de l'index
      return firstDigit === indexPrefix; // Compare avec le préfixe donné
    });
  };

  const onDeleteFileUpload = (
    index: number,
    required: boolean,
    name: string
  ) => {
    // Renvoies un tableau avec tous les fichiers sauf celui qui a l'index passé en paramètre
    const filesToSendWithDeletedFile = filesToSend.filter(
      (file) => file.index !== index
    );

    // Si il reste un fichier avec le nom passé en paramètre, on ne supprime pas le nom du tableau des fichiers requis
    const requiredFilesNeedUpdate =
      filesToSendWithDeletedFile.filter((file) => file.slug === name).length ===
      0;

    // Si le fichier est requis et qu'il n'y a plus de fichier avec le même nom, on supprime le nom du tableau des fichiers requis
    if (required && requiredFilesNeedUpdate) {
      setRequiredFilesNameUploaded((prev) =>
        prev.filter((item) => item !== name)
      );
    }

    setFilesToSend(filesToSendWithDeletedFile);
  };

  const differentMail = () => {
    if (email !== emailClient) {
      return emailClient;
    }
    return '';
  };

  const differentTel = () => {
    if (tel !== telClient) {
      return telClient;
    }
    return '';
  };

  const handleClick = () => {
    setIsLoading(true);
    setIsSend(false);
    setError('');
    if (isVolumeExceed(filesToSend)) {
      setError('Le volume des fichiers dépasse la limite de 30 Mo maximum');
      setIsLoading(false);
      setIsSend(true);
    } else {
      addCEEFiles(
        setDetailsActive,
        endOfWorksiteDate,
        updateStepIsUpdate,
        setIsLoading,
        setIsSend,
        setError,
        folderActif[0].référence_dossier,
        filesToSend,
        reference_operation,
        folderDatas,
        updateFolderDatas,
        differentMail(),
        differentTel()
      );
    }
  };

  const defineFileType = (fileTitle: string) => {
    if (fileTitle.toLowerCase().includes('photo')) {
      return ['image'];
    }
    return ['pdf'];
  };

  useEffect(() => {
    console.log(requiredFilesNameUploaded.length);
    console.log(numberFilesRequired);
    setAllRequiredUploaded(
      requiredFilesNameUploaded.length === numberFilesRequired
    );
  }, [requiredFilesNameUploaded]);

  useEffect(() => {
    setIsOk(allRequiredUploaded && endOfWorksiteDate !== '');
  }, [allRequiredUploaded, endOfWorksiteDate]);

  useEffect(() => {
    if (isSameFile) {
      toast(
        '⛔️ Un fichier avec le même nom a déjà été renseigné pour ce document'
      );
    }
  }, [isSameFile]);

  useEffect(() => {
    let filesToDelete: ICeeDocuments[] = [];
    if (interiorUnitIsHeated) {
      filesToDelete = filterFilesByIndexPrefix(3);
    } else {
      filesToDelete = filterFilesByIndexPrefix(4);
    }
    filesToDelete.forEach((file) => {
      onDeleteFileUpload(file.index, true, file.slug as string);
    });
  }, [interiorUnitIsHeated]);

  const renderFile = (file: any, index: number) => {
    return (
      <div key={v4()}>
        <FileMultiUpload
          setActiveToast={setIsSameFile}
          key={v4()}
          fileName={file.title}
          fileNameUpload={fileName}
          fileTypes={defineFileType(file.title)}
          required={file.required}
          isFileUploading={isFileUploading}
          borderRadiusContainer={radiusPx}
          borderRadius={radiusPx}
          colorIcon={colorTextButton}
          backgroundColorFileLoaded={colorPrimary}
          backgroundUploadedColor={colorPrimary}
          containerBackgroundColor={
            isThemeDark ? dark1 : convertHexToRGBA(colorPrimary, 0.1)
          }
          color={isThemeDark ? white : dark1}
          backgroundColorAddFile={isThemeDark ? dark5 : white}
          backgroundColorComponent={
            isThemeDark ? dark1 : convertHexToRGBA(colorPrimary, 0.1)
          }
          onDelete={() => onDeleteFileUpload(index, file.required, file.title)}
          onChangeFileUpload={(fileUpload) =>
            onUploadFile(
              fileUpload,
              index,
              file.title,
              file.required,
              file.scope
            )
          }
          filesUploaded={whichFile(index)}
          onlyJpeg={file.title.includes('Photo')}
        />
      </div>
    );
  };

  return (
    <div className="relative pt-[.75rem] flex flex-col space-y-[.75rem] px-[1rem]">
      {/*
      {!hasAttestation && (
        <InputInvoiceAmount
          reference_operation={reference_operation}
          mprAmountQuote={mprAmountQuote}
          invoiceAmountToCompare={invoiceAmountToCompare}
          isMatchingAmount={isMatchingAmount}
          setIsMatchingAmount={setIsMatchingAmount}
          isCompare={isCompare}
          setIsCompare={setIsCompare}
          setInvoiceAmountToCompare={setInvoiceAmountToCompare}
        />
      )}
      {isCompare || hasAttestation ? (
      */}
      <>
        {listFilteredWithTitles(filesList).map((file, index: number) => {
          return renderFile(file, index);
        })}

        {isBarTh173 && (
          <>
            {barTh173Files.map((file, index: number) =>
              renderFile(file, index + 20)
            )}
          </>
        )}

        {isBarEn101Combles && (
          <>
            {barEn101ComblesFiles.map((file, index: number) =>
              renderFile(file, index + 20)
            )}
          </>
        )}

        {isBarEn101Rampants && (
          <>
            {barEn101RampantsFiles.map((file, index: number) =>
              renderFile(file, index + 20)
            )}
          </>
        )}

        {isBarEn102ITE && (
          <>
            {barEn102ITEFiles.map((file, index: number) =>
              renderFile(file, index + 20)
            )}
          </>
        )}

        {isBarEn102ITI && (
          <>
            {barEn102ITIFiles.map((file, index: number) =>
              renderFile(file, index + 20)
            )}
          </>
        )}

        {isBarEn103 && (
          <>
            {barEn103Files.map((file, index: number) =>
              renderFile(file, index + 20)
            )}
          </>
        )}

        {calorifugeage && (
          <>
            <p className="text-sm font-medium !mt-8">
              Photos après chantier – unité extérieure
            </p>
            {exteriorUnitPostWorksiteFiles.map((file, index: number) =>
              renderFile(file, index + 10)
            )}

            <p className="text-sm font-medium !mt-8">
              Photos après chantier – unité intérieure
            </p>

            {listFilteredWithTitles(interiorUnitPostWorksiteFiles).map(
              (file, index: number) => renderFile(file, index + 20)
            )}

            <div className="!mt-8 flex flex-col space-y-2">
              <CheckCard
                label="Installation de l’unité intérieure sur un volume non chauffé"
                onClick={() => setInteriorUnitIsHeated(false)}
                checked={!interiorUnitIsHeated}
                borderRadius={radiusPx}
                colorHover={colorTextButton}
                colorChecked={colorTextButton}
                colorNotChecked={isThemeDark ? white : dark1}
                backgroundColorNotChecked={
                  isThemeDark ? dark1 : backgroundQuaternaryLightMode
                }
                backgroundColorChecked={colorPrimary}
              />
              <CheckCard
                label="Installation de l’unité intérieure sur un volume chauffé"
                onClick={() => setInteriorUnitIsHeated(true)}
                checked={interiorUnitIsHeated}
                borderRadius={radiusPx}
                colorHover={colorTextButton}
                colorChecked={colorTextButton}
                colorNotChecked={isThemeDark ? white : dark1}
                backgroundColorNotChecked={
                  isThemeDark ? dark1 : backgroundQuaternaryLightMode
                }
                backgroundColorChecked={colorPrimary}
              />
            </div>

            {interiorUnitIsHeated &&
              heatedVolumeFiles.map((file, index: number) =>
                renderFile(file, index + 30)
              )}

            {!interiorUnitIsHeated &&
              unheatedVolumeFiles.map((file, index: number) =>
                renderFile(file, index + 40)
              )}
          </>
        )}

        <div className="leading-[1.5rem] text-dark-1 dark:text-white">
          Veuillez saisir la date prévisionnelle de fin de chantier.
        </div>
        <div className="p-[.625rem] border-b-[2px] border-b-dark-light-1/[.20] flex items-center justify-between mb-[.75rem]">
          <div className="font-[500]">Date prévisionnelle fin de chantier</div>
          <input
            type="date"
            className={`dark:bg-dark-2 bg-lightMode-dark-2 text-dark-1 dark:text-white text-right ${
              isThemeDark ? '' : 'datepicker-input-whitemode'
            }`}
            value={endOfWorksiteDate}
            onChange={(e) => setEndOfWorksiteDate(e.target.value)}
          />
        </div>
        <div className="flex flex-col space-y-[.75rem] py-[.75rem]">
          <div className="leading-[1.5rem] text-dark-1 dark:text-white">
            Veuillez vérifier les informations à propos de votre client. Ils
            sont nécessaires à la demande de signature électronique de
            l&apos;Attestation sur l&apos;honneur qui sera envoyée.
          </div>
          {infosClient.map((infos) => (
            <div>
              {isEditable && isChange === infos.type ? (
                <>
                  <p className="text-red-500">{error}</p>
                  <div className="w-full flex justify-between items-center relative">
                    <button
                      type="button"
                      className="absolute h-3 right-[4.375rem] bottom-4 hover:opacity-80"
                      onClick={closeChange}
                    >
                      <CloseIcon
                        width="1rem"
                        height="1rem"
                        fill={isThemeDark ? white : colorTextLightMode}
                      />
                    </button>
                    <div className="w-full">
                      <label
                        htmlFor="email"
                        className="flex w-full text-sm mt-[.5rem]"
                      >
                        <input
                          style={{
                            borderRadius: radius,
                          }}
                          id="email"
                          type={infos.type}
                          placeholder={infos.value}
                          className={`${
                            error !== '' ? errorCSS : 'border-[#878787] '
                          } bg-transparent w-full focus:outline-none border py-[.625rem] px-[1rem]`}
                          onChange={(e) => {
                            setChangeValue(e.target.value);
                            setError('');
                            setErrorCSS('');
                          }}
                        />
                      </label>
                    </div>
                    <button
                      style={{
                        borderRadius: radius,
                        backgroundColor: colorPrimary,
                      }}
                      className="p-[1rem] ml-[.625rem] mt-[.375rem]"
                      type="button"
                      onClick={() => validateChange(infos.type)}
                    >
                      <img src={validate} alt="valider" />
                    </button>
                  </div>
                </>
              ) : (
                <div className="p-[.625rem] border-b-[2px] border-b-dark-light-1/[.20] flex items-center justify-between text-dark-1 dark:text-white">
                  <div className="font-[500]">{infos.title}</div>
                  <div className="flex items-center">
                    <div className="dark:bg-dark-2 bg-lightMode-dark-2 text-right">
                      {infos.value}
                    </div>
                    <button
                      onClick={() => {
                        setIsEditable(true);
                        setIsChange(infos.type);
                      }}
                      type="button"
                    >
                      <img
                        className="ml-[1.25rem]"
                        src={isThemeDark ? editPencilWhite : editPencilBlack}
                        alt="modifier"
                      />
                    </button>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
        <ButtonSb
          disabled={!isOk || installer.desactiver_boutons}
          onClick={() => handleClick()}
          label="Soumettre"
          color={colorTextButton}
          backgroundColor={colorPrimary}
          dataTestId="soumettre"
          addClass="!h-[2.5rem]"
          borderRadius={radius}
        />
        {isLoading && (
          <div className="mt-[.875rem]">
            <Loader />
          </div>
        )}
        {!isLoading && isSend && error !== '' && (
          <div className="mt-[.875rem] text-red-1 text-[.875rem]">{error}</div>
        )}
      </>
      {/*
      ) : (
        <></>
      )}
      */}
    </div>
  );
}

export default CeeDocuments;
