import {
  useContext,
  useEffect,
  useState,
  Dispatch,
  SetStateAction,
} from 'react';
import { scrollSmoothToPosition } from '@utils/utils';
import { FolderContext } from '@folder/utils/FoldersContext';
import InformationsAboutFolder from './InformationsAboutFolder';
import CustomerInformation from './CustomerInformation';
import UploadFilesFolder from './UploadFilesFolder';
import DimensioningNote from './DimensioningNote';

type RenderStepFolderTypes = {
  setFileToShow: Dispatch<SetStateAction<string | undefined>>;
  setDivActive: Dispatch<SetStateAction<string | undefined>>;
  setTypeImage: Dispatch<SetStateAction<boolean>>;
  setRightPartActive: Dispatch<SetStateAction<boolean>>;
  setHasInfosMaterials: Dispatch<SetStateAction<boolean>>;
};

function RenderStepFolder({
  setFileToShow,
  setDivActive,
  setTypeImage,
  setRightPartActive,
  setHasInfosMaterials,
}: RenderStepFolderTypes): JSX.Element {
  const { updateTitle, updateSubtitle, stepNumberFolder, hasDimensioningNote } =
    useContext(FolderContext);

  const [componentActive, setComponentActive] = useState<JSX.Element>(<></>);

  const renderComponent = () => {
    scrollSmoothToPosition(0);
    if (stepNumberFolder === 1) {
      setComponentActive(<CustomerInformation setDivActive={setDivActive} />);
      updateTitle('Informations à propos du client');
      updateSubtitle('');
    } else if (stepNumberFolder === 2) {
      setComponentActive(
        <InformationsAboutFolder
          setDivActive={setDivActive}
          setHasInfosMaterials={setHasInfosMaterials}
        />
      );
      updateTitle('Informations à propos du dossier');
      updateSubtitle('');
    } else if (stepNumberFolder === 3 && hasDimensioningNote) {
      setComponentActive(<DimensioningNote />);
      updateTitle('Note de dimensionnement');
      updateSubtitle('');
    } else {
      setComponentActive(
        <UploadFilesFolder
          setFileToShow={setFileToShow}
          setTypeImage={setTypeImage}
          setRightPartActive={setRightPartActive}
        />
      );
      updateTitle('Dépose de documents');
      updateSubtitle(
        'Veuillez déposer les documents obligatoires, les documents recommandés permettent d’accélérer la demande et diminuer les DDC'
      );
    }
  };

  useEffect(() => {
    renderComponent();
  }, [stepNumberFolder]);

  return componentActive;
}

export default RenderStepFolder;
