/* eslint-disable no-nested-ternary */
import { colorTextLightMode, dark5, purple1 } from '@assets/colors';
import { backgroundCheckbox } from '@assets/images/images';
import { InstallerContext } from '@auth/utils/authContext';
import { DashboardContext } from '@dashboard/utils/context/dashboardContext';
import { IDataCurrentCustomView } from '@dashboard/utils/dashboardTypes';
import { convertHexToRGBA, numFormatSpace } from '@utils/utils';
import { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { v4 } from 'uuid';

interface IProps {
  data: IDataCurrentCustomView;
}

function RowsInListViewArray({ data }: IProps): JSX.Element {
  const [isShown, setIsShown] = useState(false);

  const {
    currentSchemaFolderCustomView,
    rowsSelectedCustomView,
    updateRowsSelectedCustomView,
  } = useContext(DashboardContext);

  const { isThemeDark, colorPrimary, installer } = useContext(InstallerContext);

  const backgroundColorLightMode = isThemeDark
    ? dark5
    : convertHexToRGBA(colorPrimary, 0.1);

  const colorCheckBox =
    installer.nom_partenaire === '' || installer.nom_partenaire === 'DRAPO'
      ? purple1
      : installer.custom.couleur_boutons;

  const addRowsSelected = (folder: IDataCurrentCustomView, e: any) => {
    if (e.target.checked) {
      if (rowsSelectedCustomView.length === 0) {
        updateRowsSelectedCustomView([folder]);
      }
      updateRowsSelectedCustomView([...rowsSelectedCustomView, folder]);
    } else {
      updateRowsSelectedCustomView(
        [...rowsSelectedCustomView].filter((row) => row !== folder)
      );
    }
  };

  const rowsToDisplay = (folder: any) => {
    const newArray = [];
    for (
      let i = 0;
      i < currentSchemaFolderCustomView.schema.fields.length;
      i += 1
    ) {
      newArray.push({
        provider_name:
          folder[
            currentSchemaFolderCustomView.schema.fields.map(
              (op) => op.provider_name
            )[i]
          ],
        provider_id: currentSchemaFolderCustomView.schema.fields.map(
          (op) => op.provider_id
        )[i],
        provider_type: currentSchemaFolderCustomView.schema.fields.map(
          (op) => op.provider_type
        )[i],
      });
    }
    return newArray;
  };

  const widthRowsAndColumns = (content: any) => {
    if (
      content.provider_id === 'fldn5YY8AGTC2wtkT' ||
      content.provider_id === 'fldIpwfMhyimarFHj' ||
      content.provider_id === 'fldpRfFD5izm60E1Y'
    ) {
      return '9.375rem';
    }
    if (
      content.provider_id === 'fldwKj7lXvUpeS28m' ||
      content.provider_id === 'fldOrj98UzXywrEAE' ||
      content.provider_id === 'fldDkH4qoF0bJG8rd'
    ) {
      return '18.75rem';
    }
    if (
      content.provider_id === 'fld1Bh2wK1RlbIRwF' ||
      content.provider_id === 'fldfk4ePNV7Ca4kkL' ||
      content.provider_id === 'fldKpAqrEqIWyIjyE'
    ) {
      return '25rem';
    }
    return '12.5rem';
  };

  const conditionDisplayRow = (content: any) => {
    if (content.provider_name === null) {
      return (
        <p
          style={{ width: widthRowsAndColumns(content) }}
          className=" py-[.375rem]  h-[2.5rem] flex justify-start h-[1.5rem] overflow-hidden"
        >
          -
        </p>
      );
    }
    if (content.provider_type === 'multipleSelect') {
      const newArray =
        content.provider_name !== null
          ? content.provider_name
              .replace('{', '')
              .replace(',', '')
              .replace(',', '')
              .replace('}', '')
              .split('"')
          : '';
      return newArray.length > 1 ? (
        <>
          <div className="py-[.375rem]">
            {newArray.map((op: any) => op !== '' && <p>{op}</p>)}
          </div>
        </>
      ) : (
        <p
          style={{ width: widthRowsAndColumns(content) }}
          className=" py-[.375rem]  h-[2.5rem] flex justify-start h-[1.5rem] overflow-hidden"
        >
          -
        </p>
      );
    }
    if (content.provider_id === 'fld7ZPzGlR1qmYjOF') {
      return (
        <p
          style={{ width: widthRowsAndColumns(content) }}
          className=" py-[.375rem] h-[2.5rem] flex justify-start overflow-hidden"
        >
          {new Date(content.provider_name).toLocaleDateString()}
        </p>
      );
    }
    if (
      content.provider_id === 'fldn5YY8AGTC2wtkT' ||
      content.provider_id === 'fldi9TF214XEW0ldb' ||
      content.provider_id === 'fldwhz8HEp4xbhdUK' ||
      content.provider_id === 'fldopk7iq8tghmm6w' ||
      content.provider_id === 'fldFepotIhzVcoL0v'
    ) {
      return (
        <p
          style={{ width: widthRowsAndColumns(content) }}
          className=" py-[.375rem] h-[2.5rem] flex justify-start overflow-hidden"
        >
          {numFormatSpace(content.provider_name, true)} €
        </p>
      );
    }
    if (content.provider_id === 'fldfk4ePNV7Ca4kkL') {
      return (
        <p
          style={{ width: widthRowsAndColumns(content) }}
          className="w-[25rem] py-[.375rem] flex justify-start overflow-hidden"
        >
          {content.provider_name.toString().split('-')[0]}
        </p>
      );
    }
    return (
      <p
        style={{ width: widthRowsAndColumns(content) }}
        className={`py-[.375rem] flex justify-start overflow-hidden ${
          content.provider_id === 'fldIpwfMhyimarFHj' ||
          content.provider_id === 'fldpRfFD5izm60E1Y'
            ? 'font-[600]'
            : ''
        }`}
      >
        {content.provider_id !== 'fld5IDVsivNzWLr0M' && content.provider_name}
      </p>
    );
  };

  const renderTabRow = (content: any, folder: IDataCurrentCustomView) =>
    content.provider_id !== 'fld5IDVsivNzWLr0M' && (
      <td key={v4()} className="sb-text-rem-caption1 ">
        <Link to={`/suivi-de-dossier/${folder['Référence du dossier']}`}>
          <div className="flex justify-start overflow-hidden text-left">
            {conditionDisplayRow(content)}
          </div>
        </Link>
      </td>
    );
  const changeHoverAtTrue = () => {
    setIsShown(true);
  };

  const changeHoverAtFalse = () => {
    setIsShown(false);
  };
  return (
    <tr
      onMouseEnter={() => setTimeout(changeHoverAtTrue, 200)}
      onMouseLeave={() => setTimeout(changeHoverAtFalse, 200)}
      key={v4()}
      className="border-t dark:border-t-[#272727]  dark:hover:border-t-2 dark:hover:border-b-2 dark:hover:border-b-[#272727]"
      style={{
        backgroundColor:
          (rowsSelectedCustomView.includes(data) || isShown) && !isThemeDark
            ? backgroundColorLightMode
            : (rowsSelectedCustomView.includes(data) || isShown) && isThemeDark
            ? colorTextLightMode
            : '',
      }}
    >
      {currentSchemaFolderCustomView.schema.fields !== undefined &&
        currentSchemaFolderCustomView.schema.fields.length > 0 && (
          <td className="sb-text-rem-caption1 text-left px-[1.5rem]">
            <div
              style={{
                backgroundImage: rowsSelectedCustomView.includes(data)
                  ? `url(${backgroundCheckbox})`
                  : '',
                backgroundSize: 'center',
                backgroundPositionX: '60%',
                backgroundPositionY: 'center',
                backgroundRepeat: 'no-repeat',
                border: rowsSelectedCustomView.includes(data)
                  ? ''
                  : '1px solid #C6C6C6',
                backgroundColor: rowsSelectedCustomView.includes(data)
                  ? colorCheckBox
                  : '',
              }}
              className="w-[1.125rem] h-[1.125rem]"
            >
              <input
                className="input-checked"
                checked={rowsSelectedCustomView.includes(data)}
                onChange={(e) => addRowsSelected(data, e)}
                type="checkbox"
              />
            </div>
          </td>
        )}
      {rowsToDisplay(data).map((row) => renderTabRow(row, data))}
    </tr>
  );
}

export default RowsInListViewArray;
