import axios from 'axios';
import { Dispatch, SetStateAction } from 'react';
import { NavigateFunction } from 'react-router-dom';
import { toast } from 'react-toastify';
import { DASHBOARD_TABS } from '@dashboard/utils/tabs';
import { IInstaller } from '@auth/utils/authType';
import {
  IFilteredMaterialToolbox,
  IRepGlobalePercent,
  IInstallerNotification,
  IOperationToolBox,
  IFinancial,
  IStatus,
  IMyProfilDatas,
  INbNotifs,
  ISchemaFolder,
  IProviderWithChoices,
  IProvider,
  ICurrentCustomView,
  ICurrentSchemaCustomView,
  IConditions,
  IQuotaCee,
  ICountsCurrentCustomView,
  IListFolders,
  ICountsFolders,
  IFilterRequest,
  IDataCurrentCustomView,
  IDataSearchFolder,
} from '../utils/dashboardTypes';
import { initFilteredDatasToolBox, numberOfDemands } from '../utils/functions';

const apiUrl = process.env.REACT_APP_BACK_PRO_API_URL;

// ENVOI UN MAIL SI L'INSTALATEUR  REFUSE DE POURSUIVRE LE PARTENARIAT AVEC DRAPO pour la gestion administrative de MaPrimeRénov’.
export const sendMailIfNoMprWithDrapo = async (
  setIsModalOpen: Dispatch<SetStateAction<boolean>>,
  setIsLoading: Dispatch<SetStateAction<boolean>>,
  choice: number
) => {
  setIsLoading(true);
  axios
    .get(`${apiUrl}/installer/no_more?continue_partnership=${choice}`)
    .then(() => {
      setIsModalOpen(false);
      setIsLoading(false);
    })
    .catch((error) => {
      console.log(error.response);
    });
};

// RECUPERE LES INFOS POUR LE DASHBOARD
export const getDashboardDatas = (
  setRepartitionGlobalePercent: Dispatch<SetStateAction<IRepGlobalePercent[]>>,
  setIsFolder: Dispatch<SetStateAction<boolean>>
) => {
  axios
    .get(`${apiUrl}/installer/dashboard`)
    .then((response) => {
      setRepartitionGlobalePercent(response.data);
      for (let i = 0; i < response.data.length; i += 1) {
        if (response.data[i].nb_folders > 0) {
          setIsFolder(true);
        }
      }
    })
    .catch((error) => {
      console.log(error);
    });
};

// RECUPERE LA LISTE DES DOSSIERS POUR LE DASHBOARD
export const getFoldersDashboard = (
  setFoldersDataNoSendToAirtable: Dispatch<SetStateAction<IListFolders[]>>,
  numberPage: number,
  setIsLoaded: Dispatch<SetStateAction<boolean>>,
  foldersData: IListFolders[],
  updateCountsFolders: Dispatch<SetStateAction<ICountsFolders>>,
  updateRowsSelected: Dispatch<SetStateAction<IListFolders[]>>,
  rowsSelected: IListFolders[],
  setIsEndFolders?: Dispatch<SetStateAction<boolean>>
) => {
  const data = {
    nb_per_page: 20,
    page: numberPage,
  };

  axios
    .post(`${apiUrl}/installer/dossiers`, data)
    .then((response) => {
      if (numberPage === 1) {
        updateRowsSelected(rowsSelected);
        setFoldersDataNoSendToAirtable(response.data.dossiers.data);
        setIsLoaded(false);
        updateCountsFolders(response.data.counts);
      } else {
        updateRowsSelected(rowsSelected);
        if (response.data.dossiers.to !== null) {
          const newData = response.data.dossiers.data;
          const newArray = foldersData.concat(newData);
          setFoldersDataNoSendToAirtable(newArray);
        } else if (setIsEndFolders) {
          setIsEndFolders(true);
        }
        setIsLoaded(false);
      }
    })
    .catch((error) => {
      console.log(error);
    });
};

// RECUPERE LA LISTE DES DOSSIERS ENVOYES A AIRTABLE
export const getFoldersInAirtableDashboard = (
  setFoldersDataSendToAirtable: Dispatch<SetStateAction<IListFolders[]>>,
  numberPage: number,
  setIsLoaded: Dispatch<SetStateAction<boolean>>,
  foldersData: IListFolders[],
  ceeStatus: string[],
  mprStatus: string[],
  updateCountsFolders: Dispatch<SetStateAction<ICountsFolders>>,
  updateRowsSelected: Dispatch<SetStateAction<IListFolders[]>>,
  rowsSelected: IListFolders[],
  setIsEndFolders?: Dispatch<SetStateAction<boolean>>
) => {
  setIsLoaded(true);
  const filter: IFilterRequest = {
    mpr_status: mprStatus,
    cee_status: ceeStatus,
    keyword: '',
  };
  if (mprStatus[0] === '') {
    if (filter.mpr_status !== undefined) {
      delete filter.mpr_status;
    }
  }
  if (ceeStatus[0] === '') {
    delete filter.cee_status;
  }
  const data = {
    nb_per_page: 15,
    page: numberPage,
    filter,
  };

  axios
    .post(`${apiUrl}/installer/dossiersAirtable`, data)
    .then((response) => {
      if (numberPage === 1) {
        updateRowsSelected(rowsSelected);
        setFoldersDataSendToAirtable(response.data.dossiers.data);
        setIsLoaded(false);
        updateCountsFolders(response.data.counts);
      } else {
        updateRowsSelected(rowsSelected);
        if (response.data.dossiers.to !== null) {
          const newData = response.data.dossiers.data;
          const newArray = foldersData.concat(newData);
          setFoldersDataSendToAirtable(newArray);
        } else if (setIsEndFolders) {
          setIsEndFolders(true);
        }

        setIsLoaded(false);
      }
    })
    .catch((error) => {
      console.log(error);
    });
};

// SUPPRIME UN DOSSIER (EN COURS DE SIMULATION)
export const deleteFolderSimulation = (
  folder: string,
  operation: string,
  setFoldersDataNoSendToAirtable: Dispatch<SetStateAction<IListFolders[]>>,
  numberPageFolderNoSendToAirtable: number,
  setIsLoaded: Dispatch<SetStateAction<boolean>>,
  foldersDataNoSendToAirtable: IListFolders[],
  updateCountsFolders: Dispatch<SetStateAction<ICountsFolders>>,
  setIsDeleteLoading: Dispatch<SetStateAction<boolean>>,
  setIsDeleteModal: Dispatch<SetStateAction<boolean>>,
  setRowsSelected: Dispatch<SetStateAction<IListFolders[]>>
) => {
  const config = {
    headers: {
      ContentType: 'multipart/form-data',
    },
  };
  return axios
    .delete(`${apiUrl}/dossier/${folder}/operation/${operation}`, config)
    .then(() => {
      setIsDeleteLoading(false);
      setIsDeleteModal(false);
      toast(` 🎉 L'opération ${operation} a bien été supprimée.`);
      setRowsSelected([]);
      getFoldersDashboard(
        setFoldersDataNoSendToAirtable,
        numberPageFolderNoSendToAirtable,
        setIsLoaded,
        foldersDataNoSendToAirtable,
        updateCountsFolders,
        setRowsSelected,
        []
      );
      return true;
    })
    .catch(() => {
      toast(
        ` ⛔️ Une erreur est survenue lors de la suppression du dossier ${folder}.`
      );
      setIsDeleteLoading(false);
      return false;
    });
};

// SUPPRIME UNE OPERATION (EN COURS D'INSTRUCTION) => DANS LE DETAIL DU DOSSIER

// SUPPRIME UNE OPERATION (EN COURS D'INSTRUCTION)
export const deleteFolderOperationAirtable = (
  folder: string,
  operation: string,
  setIsLoaded: Dispatch<SetStateAction<boolean>>,
  setFoldersDataSendToAirtable: Dispatch<SetStateAction<IListFolders[]>>,
  updateCountsFolders: Dispatch<SetStateAction<ICountsFolders>>,
  ceeStatusFilter: string[],
  mprStatusFilter: string[],
  foldersDataSendToAirtable: IListFolders[],
  setIsDeleteLoading: Dispatch<SetStateAction<boolean>>,
  setIsDeleteModal: Dispatch<SetStateAction<boolean>>,
  setRowsSelected: Dispatch<SetStateAction<IListFolders[]>>
) => {
  const config = {
    headers: {
      ContentType: 'multipart/form-data',
    },
  };
  return axios
    .delete(`${apiUrl}/dossier/${folder}/${operation}/delete`, config)
    .then(() => {
      setIsDeleteLoading(false);
      setIsDeleteModal(false);
      setRowsSelected([]);
      toast(` 🎉 L'operation ${operation} a bien été supprimée.`);
      getFoldersInAirtableDashboard(
        setFoldersDataSendToAirtable,
        1,
        setIsLoaded,
        foldersDataSendToAirtable,
        ceeStatusFilter,
        mprStatusFilter,
        updateCountsFolders,
        setRowsSelected,
        []
      );
      return true;
    })
    .catch(() => {
      toast(
        `⛔️ Une erreur est survenue lors de la suppression de l'opération ${operation}.`
      );
      setIsDeleteLoading(false);
      return false;
    });
};

// SUPPRIMER UN DOSSIER EN COURS DE SIMULATION
export const deleteFolder = (folder: string, router: NavigateFunction) => {
  const config = {
    headers: {
      ContentType: 'multipart/form-data',
    },
  };
  axios
    .delete(`${apiUrl}/dossier/${folder}`, config)
    .then(() => {
      toast(` 🎉 Le dossier ${folder} a bien été supprimé.`);
      router('/');
    })
    .catch((error) => {
      console.log(error.response);
      toast(
        `⛔️ Une erreur est survenue lors de la suppression du dossier ${folder}.`
      );
    });
};
// SUPPRIMER UN DOSSIER DANS AIRTABLE
export const deleteFolderAirtable = (
  folder: string,
  updateIsDeleteFolderLoading: Dispatch<SetStateAction<boolean>>,
  updateIsModalDeleteFolder: Dispatch<SetStateAction<boolean>>,
  router: NavigateFunction
) => {
  const config = {
    headers: {
      ContentType: 'multipart/form-data',
    },
  };
  updateIsDeleteFolderLoading(true);
  axios
    .delete(`${apiUrl}/dossier/${folder}/delete`, config)
    .then(() => {
      toast(` 🎉 Le dossier ${folder} a bien été supprimé.`);
      updateIsDeleteFolderLoading(false);
      updateIsModalDeleteFolder(false);
      router('/');
    })
    .catch((error) => {
      console.log(error.response);
      toast(
        `⛔️ Une erreur est survenue lors de la suppression du dossier ${folder}.`
      );
      if (updateIsDeleteFolderLoading) updateIsDeleteFolderLoading(false);
    });
};

// RECUPERE LA LISTE DES OPERATIONS
export const getAllOperationsToolBox = (
  setAllOperations: Dispatch<SetStateAction<IOperationToolBox[] | undefined>>
) => {
  axios.get(`${apiUrl}/operation?material=true`).then((response) => {
    const allOperations: IOperationToolBox[] = [];
    for (let i = 0; i < response.data.operations.length; i += 1) {
      if (response.data.operations[i].hasMaterial) {
        allOperations.push(response.data.operations[i]);
      }
    }
    setAllOperations(allOperations);
  });
};

// RECUPERE LA LISTE DU MATERIEL
export const getListMaterials = (
  setMaterials: Dispatch<SetStateAction<IFilteredMaterialToolbox[]>>,
  setIsLoading: Dispatch<SetStateAction<boolean>>,
  setError: Dispatch<SetStateAction<boolean>>
) => {
  axios
    .get(`${apiUrl}/material/search/?link=true`)
    .then((response) => {
      setMaterials(initFilteredDatasToolBox(response.data));
      setIsLoading(false);
    })
    .catch((err) => {
      setError(true);
      setIsLoading(false);
      console.log(err);
    });
};

// RECUPERE LA LISTE DU MATERIEL PAR OPERATION
export const getListMaterialsByOperation = (
  setMaterials: Dispatch<SetStateAction<IFilteredMaterialToolbox[]>>,
  setIsLoading: Dispatch<SetStateAction<boolean>>,
  slug: string | undefined,
  setError: Dispatch<SetStateAction<boolean>>
) => {
  axios
    .get(`${apiUrl}/material/search/${slug}?link=true`)
    .then((response) => {
      setError(false);
      setMaterials(initFilteredDatasToolBox(response.data));
      setIsLoading(false);
    })
    .catch((err) => {
      setError(true);
      setIsLoading(false);
      console.log(err);
    });
};

// RECUPERE LES NOTIFICATIONS POUR LES DEMANDES DE COMPLEMENTS
export const getInstallerNotifications = (
  setInstallerNotification: Dispatch<
    SetStateAction<IInstallerNotification | null>
  >,
  setNbNotifs: Dispatch<SetStateAction<INbNotifs>>,
  updateIsRetractableDDC: Dispatch<SetStateAction<boolean>>,
  setIsRetractable: Dispatch<SetStateAction<boolean>>
) => {
  axios
    .get(`${apiUrl}/installer/notifications`)
    .then((response) => {
      setInstallerNotification(response.data);
      setNbNotifs({
        cee:
          numberOfDemands(response.data.ddc.cee) +
            numberOfDemands(response.data.ddc.cee_mpr) || 0,
        mpr:
          numberOfDemands(response.data.ddc.mpr) +
            numberOfDemands(response.data.ddc.cee_mpr) || 0,
        toutes:
          numberOfDemands(response.data.ddc.cee_mpr) +
            numberOfDemands(response.data.ddc.mpr) +
            numberOfDemands(response.data.ddc.cee) || 0,
      });
      if (
        response.data.ddc.cee_mpr.length +
          response.data.ddc.cee.length +
          response.data.ddc.mpr.length ===
        0
      ) {
        updateIsRetractableDDC(true);
        setIsRetractable(true);
      }
    })
    .catch((error) => {
      console.log(error);
    });
};

// RECUPERE LES INFOS FINANCIERES DES DOSSIERS CEE
export const getAllFinancialsCee = (
  setFinancialsCee: Dispatch<SetStateAction<IFinancial>>,
  updateFinancialsIsLoaded: Dispatch<SetStateAction<boolean>>
) => {
  updateFinancialsIsLoaded(true);

  axios
    .get(`${apiUrl}/dashboard/financial/cee`)
    .then((response) => {
      setFinancialsCee(response.data);
      updateFinancialsIsLoaded(true);
    })
    .catch((error) => {
      console.log(error);
    });
};

// RECUPERE LES INFOS FINANCIERES DES DOSSIERS MPR
export const getAllFinancialsMpr = (
  setFinancialsMpr: Dispatch<SetStateAction<IFinancial>>,
  updateFinancialsIsLoaded: Dispatch<SetStateAction<boolean>>
) => {
  axios
    .get(`${apiUrl}/dashboard/financial/mpr`)
    .then((response) => {
      setFinancialsMpr(response.data);
      updateFinancialsIsLoaded(true);
    })
    .catch((error) => {
      console.log(error);
    });
};

// RECUPERE LES INFOS DES STATUS CEE
export const getAllStatusCee = (
  updateStatusCee: Dispatch<SetStateAction<IStatus>>,
  updateStatusIsLoaded: Dispatch<SetStateAction<boolean>>,
  installer: IInstaller
) => {
  axios
    .get(`${apiUrl}/dashboard/summary/cee`)
    .then((response) => {
      const tab = response.data;
      if (installer.raison_sociale_de_lentreprise === 'DÉMO') {
        tab.datas.push({
          amount: 0,
          nb_folders: 0,
          step: '6. Contrôle contact',
          step_number: 6,
          title: '',
          filter: [],
        });
      }
      updateStatusCee(tab);
      updateStatusIsLoaded(true);
    })
    .catch((error) => {
      console.log(error);
    });
};
// RECUPERE LES INFOS DES STATUS MPR
export const getAllStatusMpr = (
  updateStatusMpr: Dispatch<SetStateAction<IStatus>>,
  updateStatusIsLoaded: Dispatch<SetStateAction<boolean>>,
  installer: IInstaller
) => {
  axios
    .get(`${apiUrl}/dashboard/summary/mpr`)
    .then((response) => {
      const tab = response.data;
      if (installer.raison_sociale_de_lentreprise === 'DÉMO') {
        tab.datas.push({
          amount: 0,
          nb_folders: 0,
          step: '9. Contrôle contact',
          step_number: 9,
          title: '',
          filter: [],
        });
      }
      updateStatusMpr(tab);
      updateStatusIsLoaded(true);
    })
    .catch((error) => {
      console.log(error);
    });
};

// RECUPERE QUOTA CEE
export const getQuotaCee = (
  updateQuotaCee: Dispatch<SetStateAction<IQuotaCee | undefined>>,
  updateStatusIsLoaded: Dispatch<SetStateAction<boolean>>
) => {
  axios
    .get(`${apiUrl}/dashboard/financial/quota`)
    .then((response) => {
      updateQuotaCee(response.data);
      updateStatusIsLoaded(true);
    })
    .catch((error) => {
      console.log(error);
    });
};

// RECUPERE LES INFOS DES STATUS MPR
export const getAllFilters = () => {
  axios
    .get(`${apiUrl}/utils/folderSearchFilters`)
    .then((response) => {
      console.log(response.data);
    })
    .catch((error) => {
      console.log(error);
    });
};

// ** MON PROFIL **
// RECUPERE LES INFOS DU PROFIL
export const getAllParameters = (
  setDatasMyProfil: Dispatch<SetStateAction<IMyProfilDatas>>,
  fromUploadCategoryFile?: string,
  updateDocumentUrlActive?: Dispatch<SetStateAction<string>>,
  setIsLoading?: Dispatch<SetStateAction<boolean>>
) => {
  axios
    .get(`${apiUrl}/installer/parameters`)
    .then((response) => {
      setDatasMyProfil(response.data.parameters);
      if (fromUploadCategoryFile && updateDocumentUrlActive) {
        updateDocumentUrlActive(
          response.data.parameters[fromUploadCategoryFile].value[0]
        );
      }
      if (setIsLoading) {
        setIsLoading(false);
      }
    })
    .catch((error) => {
      console.log(error);
    });
};

// UPDATE LES INFOS GENERALES DU PROFIL
export const updateInformationMyProfile = (
  field: string,
  value: string,
  setDatasMyProfil: Dispatch<SetStateAction<IMyProfilDatas>>,
  updateIsUpdateLoading: Dispatch<SetStateAction<boolean>>,
  setIsEditable: Dispatch<SetStateAction<string[]>>,
  isEditable: string[]
) => {
  const formData = new FormData();

  formData.append(field, value);

  const config = {
    headers: {
      ContentType: 'multipart/form-data',
    },
  };

  const responseSuccess = () => {
    updateIsUpdateLoading(false);
    setIsEditable(isEditable.filter((el: string) => el !== value));
  };

  axios
    .post(`${apiUrl}/installer/parameters`, formData, config)
    .then(() => {
      getAllParameters(setDatasMyProfil);
      setTimeout(responseSuccess, 2000);
    })
    .catch((error) => {
      console.log(error);
    });
};

// UPDATE PASSWORD
export const updatePassword = (
  password: string,
  updateIsUpdateLoading: Dispatch<SetStateAction<boolean>>,
  setError: Dispatch<SetStateAction<string>>,
  setShowPasswordUpdate: Dispatch<SetStateAction<boolean>>
) => {
  updateIsUpdateLoading(true);
  const formData = new FormData();

  formData.append('password', password);

  const config = {
    headers: {
      ContentType: 'multipart/form-data',
    },
  };

  axios
    .post(`${apiUrl}/installer/parameters`, formData, config)
    .then(() => {
      updateIsUpdateLoading(false);
      setShowPasswordUpdate(false);
    })
    .catch(() => {
      setError('Nous rencontrons un problème veuillez réessayer');
    });
};

// UPDATE EMAIL
export const updateMail = (
  email: string,
  updateIsUpdateLoading: Dispatch<SetStateAction<boolean>>,
  setError: Dispatch<SetStateAction<string>>,
  setShowEmailUpdate: Dispatch<SetStateAction<boolean>>
) => {
  const formData = new FormData();
  updateIsUpdateLoading(true);

  formData.append('email', email);

  const config = {
    headers: {
      ContentType: 'multipart/form-data',
    },
  };

  axios
    .post(`${apiUrl}/installer/parameters`, formData, config)
    .then(() => {
      updateIsUpdateLoading(false);
      setShowEmailUpdate(false);
    })
    .catch(() => {
      setError('Nous rencontrons un problème veuillez réessayer');
    });
};

// UPDATE LES FICHIERS DU PROFIL
export const updateFileMyProfile = (
  field: string,
  file: File,
  setDatasMyProfil: Dispatch<SetStateAction<IMyProfilDatas>>,
  updateIsUpdateLoading: Dispatch<SetStateAction<boolean>>,
  updateDocumentUrlActive?: Dispatch<SetStateAction<string>>
) => {
  updateIsUpdateLoading(true);

  const formData = new FormData();
  formData.append(field, file);

  const responseSuccess = () => {
    updateIsUpdateLoading(false);
  };

  const config = {
    headers: {
      ContentType: 'multipart/form-data',
    },
  };

  axios
    .post(`${apiUrl}/installer/parameters`, formData, config)
    .then(() => {
      getAllParameters(setDatasMyProfil, field, updateDocumentUrlActive);
      setTimeout(responseSuccess, 2000);
    })
    .catch((error) => {
      console.log(error);
    });
};

// DELETE UN DOCUMENT DU PROFIL
export const deleteFileMyProfile = (
  field: string,
  file: string,
  setDatasMyProfil: Dispatch<SetStateAction<IMyProfilDatas>>,
  updateIsUpdateLoading: Dispatch<SetStateAction<boolean>>,
  updateDocumentUrlActive: Dispatch<SetStateAction<string>>
) => {
  updateIsUpdateLoading(true);
  const formData = new FormData();
  formData.append(field, file);

  const responseSuccess = () => {
    updateIsUpdateLoading(false);
  };

  const config = {
    headers: {
      ContentType: 'multipart/form-data',
    },
  };
  axios
    .post(`${apiUrl}/installer/document/delete`, formData, config)
    .then(() => {
      updateDocumentUrlActive('');
      getAllParameters(setDatasMyProfil);
      setTimeout(responseSuccess, 2000);
    })
    .catch((error) => {
      console.log(error);
    });
};

// ** CUSTOM VIEW **
// RECUPERE LE SCHEMA GLOBAL
export const getSchemaFolders = (
  updateSchemaFoldersCustomView: Dispatch<SetStateAction<ISchemaFolder>>
) => {
  axios
    .get(`${apiUrl}/schema/folders`)
    .then((response) => {
      updateSchemaFoldersCustomView(response.data);
    })
    .catch((error) => {
      console.log(error);
    });
};

// RECUPERE LA TOTALITE DES SCHEMAS DES LISTES CREES
export const getListSchemasCustomView = (
  updateListSchemasCustomView: Dispatch<
    SetStateAction<ICurrentSchemaCustomView[]>
  >,
  updateIsLoadingCustomView: Dispatch<SetStateAction<boolean>>
) => {
  updateIsLoadingCustomView(true);
  axios
    .get(`${apiUrl}/customviews`)
    .then((response) => {
      updateListSchemasCustomView(response.data);
      updateIsLoadingCustomView(false);
    })
    .catch((error) => {
      console.log(error);
    });
};

// RECUPERE LE SCHEMA D'UNE LISTE PAR SON ID
export const getOneSchemaCustomViewById = (
  id: string,
  updateCurrentSchema: Dispatch<SetStateAction<ICurrentSchemaCustomView>>,
  updateIsLoadingCustomView: Dispatch<SetStateAction<boolean>>
) => {
  updateIsLoadingCustomView(true);
  axios
    .get(`${apiUrl}/customviews/${id}`)
    .then((response) => {
      updateCurrentSchema(response.data);
      updateIsLoadingCustomView(false);
    })
    .catch((error) => {
      console.log(error);
    });
};

// RECUPERE LES DATAS D'UNE LISTE PAR SON ID
export const getListCustomViewById = (
  idView: string,
  updateIsLoadingCustomView: Dispatch<SetStateAction<boolean>>,
  updateCurrentListCustomView: Dispatch<
    SetStateAction<ICurrentCustomView | undefined>
  >,
  numberPage: number,
  updateCountsCustomListView: Dispatch<
    SetStateAction<ICountsCurrentCustomView>
  >,
  setRowsSelected: Dispatch<SetStateAction<IDataCurrentCustomView[]>>,
  setFolderIsLoaded?: Dispatch<SetStateAction<boolean>>,
  currentListCustomView?: ICurrentCustomView | undefined,
  setIsEndFolders?: Dispatch<SetStateAction<boolean>>,
  rowSelected?: IDataCurrentCustomView[]
) => {
  axios
    .get(
      `${apiUrl}/customviews/${idView}/listoperationb2c?nb_per_page=25&page=${numberPage}`
    )
    .then((response) => {
      if (numberPage === 1) {
        updateCurrentListCustomView(response.data);
        updateCountsCustomListView(response.data.counts);
        updateIsLoadingCustomView(false);
        setRowsSelected([]);
      } else {
        setRowsSelected(rowSelected || []);
        if (response.data.size !== 0) {
          const newData = response.data;
          const newArray = currentListCustomView?.data.concat(newData.data);
          updateCurrentListCustomView({
            counts: {
              nb_folders: 0,
              'Montant MPR géré par Drapo': 0,
              'Montant TTC devis': 0,
              'Prime CEE bénéficiaire': 0,
              'Prime CEE installateur': 0,
              'Virement MPR pro': 0,
            },
            data: newArray !== undefined ? newArray : [],
            size: response.data.size,
          });
        } else if (setIsEndFolders) {
          setIsEndFolders(true);
        }
        updateIsLoadingCustomView(false);
        if (setFolderIsLoaded) {
          setFolderIsLoaded(false);
        }
      }
    })
    .catch((error) => {
      console.log(error.response);
    });
};

// CREE UNE NOUVELLE LISTE
export const createNewListCustomView = (
  listVisibleColumnsCustomView: IProvider[] | IProviderWithChoices[],
  nameCustomView: string,
  updateIsLoadingCustomView: Dispatch<SetStateAction<boolean>>,
  updateCurrentListCustomView: Dispatch<
    SetStateAction<ICurrentCustomView | undefined>
  >,
  updateIsCreateNewListCustomView: Dispatch<SetStateAction<boolean>>,
  updateFiltersOrPersonalizationModalCustomView: Dispatch<
    SetStateAction<string>
  >,
  updateIdCurrentListCustomView: Dispatch<SetStateAction<string>>,
  numberPageList: number,
  updateCountsCustomListView: Dispatch<
    SetStateAction<ICountsCurrentCustomView>
  >,
  updateRowsSelectedCustomView: Dispatch<
    SetStateAction<IDataCurrentCustomView[]>
  >,
  newConditions?: IConditions[]
) => {
  updateIsLoadingCustomView(true);
  const data = {
    name: nameCustomView,
    order: '6',
    schema: JSON.stringify({
      whereClauses: {
        type: 'AND',
        conditions: newConditions || [],
      },
      groupBy: [
        {
          provider_id: 'fld8hMf9x1jhPTwft',
          provider_name: 'R\u00e9f\u00e9rent technique',
        },
        {
          provider_name: 'R\u00e9f\u00e9rence op\u00e9ration',
          provider_id: 'fldFD1JlTjvfEcfQm',
        },
        { provider_name: 'Statut MPR', provider_id: 'fldDkH4qoF0bJG8rd' },
      ],
      provider_name: '\ud83d\udcc2 Dossiers B2C',
      provider_id: 'tbldz93hhSHX5vYZH',
      fields: listVisibleColumnsCustomView,
    }),
  };

  axios
    .post(`${apiUrl}/customviews`, data)
    .then((response) => {
      updateIsCreateNewListCustomView(false);
      updateFiltersOrPersonalizationModalCustomView('');
      updateIdCurrentListCustomView(response.data.id);
      getListCustomViewById(
        response.data.id,
        updateIsLoadingCustomView,
        updateCurrentListCustomView,
        numberPageList,
        updateCountsCustomListView,
        updateRowsSelectedCustomView
      );
    })
    .catch((error) => {
      console.log(error);
    });
};

// MODIFIE LE NOM D'UNE LISTE EXISTANTE
export const updateNameOneCustomViewById = (
  nameCustomView: string,
  id: string,
  schemaList: ICurrentSchemaCustomView
) => {
  const data = {
    order: schemaList.order,
    name: nameCustomView,
    schema: JSON.stringify(schemaList.schema),
  };
  axios
    .post(`${apiUrl}/customviews/${id}`, data)

    .catch((error) => {
      console.log(error);
    });
};

// MODIFIE UNE LISTE EXISTANTE
export const updateOneCustomViewById = (
  nameCustomView: string,
  id: string,
  listVisibleColumnsCustomView: IProvider[] | IProviderWithChoices[],
  updateIsLoadingCustomView: Dispatch<SetStateAction<boolean>>,
  updateCurrentListCustomView: Dispatch<
    SetStateAction<ICurrentCustomView | undefined>
  >,
  updateFiltersOrPersonalizationModalCustomView: Dispatch<
    SetStateAction<string>
  >,
  schemaList: ICurrentSchemaCustomView,
  numberPageList: number,
  updateCountsCustomListView: Dispatch<
    SetStateAction<ICountsCurrentCustomView>
  >,
  setRowsSelected: Dispatch<SetStateAction<IDataCurrentCustomView[]>>,
  typeConditions?: string,
  newConditions?: IConditions[]
) => {
  updateIsLoadingCustomView(true);
  const data = {
    order: schemaList.order,
    name: nameCustomView,
    schema: JSON.stringify({
      whereClauses: {
        type: typeConditions || 'AND',
        conditions: newConditions || schemaList.schema.whereClauses.conditions,
      },
      groupBy: schemaList.schema.groupBy,
      provider_name: schemaList.schema.provider_name,
      provider_id: schemaList.schema.provider_id,
      fields: listVisibleColumnsCustomView,
    }),
  };
  axios
    .post(`${apiUrl}/customviews/${id}`, data)
    .then((response) => {
      updateFiltersOrPersonalizationModalCustomView('');
      updateCurrentListCustomView(response.data);
      updateIsLoadingCustomView(false);
      getListCustomViewById(
        response.data.id,
        updateIsLoadingCustomView,
        updateCurrentListCustomView,
        numberPageList,
        updateCountsCustomListView,
        setRowsSelected
      );
    })
    .catch((error) => {
      // getListCustomViewById(
      //   id,
      //   updateIsLoadingCustomView,
      //   updateCurrentListCustomView,
      //   numberPageList,
      //   updateCountsCustomListView,
      //   setRowsSelected
      // );

      console.log(error.response);
    });
};

// SUPPRIME UNE LISTE
export const deleteListCustomViewById = (
  id: string,
  updateElementActive: Dispatch<SetStateAction<string>>,
  updateActiveCustomView: Dispatch<SetStateAction<boolean>>,
  updateListSchemasCustomView: Dispatch<
    SetStateAction<ICurrentSchemaCustomView[]>
  >,
  updateIsLoadingCustomView: Dispatch<SetStateAction<boolean>>
) => {
  axios
    .delete(`${apiUrl}/customviews/${id}`)
    .then(() => {
      updateElementActive(DASHBOARD_TABS.SUMMARY);
      updateActiveCustomView(false);
      getListSchemasCustomView(
        updateListSchemasCustomView,
        updateIsLoadingCustomView
      );
    })
    .catch((error) => {
      console.log(error);
    });
};

// TELECHARGE LA LISTE PERSONNALISEE
export const downloadCustomList = (id: string) => {
  axios.get(`${apiUrl}/customviews/${id}/export`).catch((error) => {
    console.log("Erreur lors de l'importation", error);
  });
};

// RECHERCHE D'UN DOSSIER
export const searchFolder = (
  numberPage: number,
  searchValue: string,
  setListFolders: Dispatch<SetStateAction<IDataSearchFolder[] | undefined>>,
  setTotalFoldersInList: Dispatch<SetStateAction<number>>,
  listFolders: IDataSearchFolder[] | undefined,
  setFoldersIsLoaded: Dispatch<SetStateAction<boolean>>,
  setNumberPageFolders: Dispatch<SetStateAction<number>>
) => {
  const data = {
    nb_per_page: 5,
    page: numberPage,
    search: searchValue,
  };
  const loadedTimeOut = () => {
    setFoldersIsLoaded(false);
  };
  axios
    .post(`${apiUrl}/installer/dossiers/search`, data)
    .then((response) => {
      setTotalFoldersInList(response.data.total);
      if (numberPage === 1) {
        setListFolders(response.data.data);
      } else {
        setNumberPageFolders(numberPage);
        if (response.data.data !== null) {
          const newData = response.data.data;
          const newDataInArray: IDataSearchFolder[] = Object.values(newData);
          const listFoldersInArray: IDataSearchFolder[] = Object.values(
            listFolders !== undefined ? listFolders : {}
          );
          const newArray = listFoldersInArray.concat(newDataInArray);

          setListFolders(newArray);
        }
        setTimeout(loadedTimeOut, 1000);
      }
    })
    .catch((error) => {
      console.log(error.response);
      setFoldersIsLoaded(false);
    });
};
