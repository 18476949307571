/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import { alert } from '@assets/images/images';
import {
  deleteFolderOperationAirtable,
  deleteFolderSimulation,
} from '@dashboard/apiRequests/dashboardRequests';
import { v4 } from 'uuid';
import { DashboardContext } from '@dashboard/utils/context/dashboardContext';
import { Dispatch, SetStateAction, useContext } from 'react';
import { InstallerContext } from '@auth/utils/authContext';
import { IListFolders } from '@dashboard/utils/dashboardTypes';
import { DASHBOARD_TABS } from '@dashboard/utils/tabs';
import { toast } from 'react-toastify';
import { CloseIcon } from '@assets/svgComponents/svgComponents';

interface IProps {
  setFoldersDatas: Dispatch<SetStateAction<IListFolders[]>>;
  folderDatas: IListFolders[];
  isAddOperation: boolean;
}

function DeleteOperationsInList({
  setFoldersDatas,
  folderDatas,
  isAddOperation,
}: IProps): JSX.Element {
  const {
    updateIsDeleteFolderLoading,
    isDeleteFolderLoading,
    updateIsModalDeleteFolder,
    elementActive,
    updateCountsFolders,
    ceeStatusFilter,
    mprStatusFilter,
    numberPageList,
    updateRowsSelectedFolders,
    rowsSelectedFolders,
    updateIsLoadedFolders,
  } = useContext(DashboardContext);

  const { radiusPx } = useContext(InstallerContext);

  const deleteFolders = async () => {
    updateIsDeleteFolderLoading(true);
    for (let i = 0; i < rowsSelectedFolders.length; i += 1) {
      if (elementActive === DASHBOARD_TABS.FOLDERS_INSTRUCTION) {
        if (rowsSelectedFolders[i].deletable !== 0) {
          await deleteFolderOperationAirtable(
            rowsSelectedFolders[i].référence_dossier,
            rowsSelectedFolders[i].référence_opération,
            updateIsLoadedFolders,
            setFoldersDatas,
            updateCountsFolders,
            ceeStatusFilter,
            mprStatusFilter,
            folderDatas,
            updateIsDeleteFolderLoading,
            updateIsModalDeleteFolder,
            updateRowsSelectedFolders
          );
          updateIsDeleteFolderLoading(false);
        } else {
          toast(
            `⛔️ Vous ne pouvez pas supprimer l'opération avec la référence ${rowsSelectedFolders[i].référence_opération}. Elle est en cours de traitement.`
          );
          updateIsDeleteFolderLoading(false);
        }
      } else {
        await deleteFolderSimulation(
          rowsSelectedFolders[i].folder_reference,
          rowsSelectedFolders[i].operation_reference,
          setFoldersDatas,
          numberPageList,
          updateIsLoadedFolders,
          folderDatas,
          updateCountsFolders,
          updateIsDeleteFolderLoading,
          updateIsModalDeleteFolder,
          updateRowsSelectedFolders
        );
      }
    }
  };

  return (
    <div
      style={{ borderRadius: radiusPx }}
      className="bg-[#A60028] absolute bottom-[10rem] left-[1.875rem] p-[1.875rem] text-[.75rem] pb-[1.25rem]"
    >
      <div className="flex justify-between items-center">
        <div className="flex items-center">
          <img className="!w-[1.5rem] mr-[1rem]" src={alert} alt="attention" />

          <p className="mt-[.25rem] font-medium text-[.865rem]">
            {isAddOperation
              ? 'Attention'
              : `Suppression ${
                  rowsSelectedFolders.length > 1
                    ? 'des opérations'
                    : "d'une opération"
                }`}
          </p>
        </div>

        <button type="button" onClick={() => updateIsModalDeleteFolder(false)}>
          <CloseIcon fill="white" width="1rem" height="1rem" />
        </button>
      </div>
      {isAddOperation && (
        <div>
          <p className="mt-2 font-medium text-[.875rem]">
            Certaines opérations vont être supprimées en plus de celles
            sélectionnées.
          </p>
          <p className="mt-2 font-medium text-[.875rem]">
            En effet, celles-ci appartiennent à un même dossier contenant des
            opérations bonus.
          </p>
        </div>
      )}
      <div>
        <>
          <p className="mt-[.625rem]">
            Vous êtes sur le point de supprimer{' '}
            {rowsSelectedFolders.length > 1 ? 'les opérations' : "l'opération"}{' '}
            :
          </p>
          {rowsSelectedFolders.map((op) => {
            return (
              <p key={v4()} className="mt-[.625rem]">
                -{' '}
                {elementActive === DASHBOARD_TABS.FOLDERS_INSTRUCTION
                  ? op.opération
                  : op.operation}
              </p>
            );
          })}
        </>
      </div>
      <button
        style={{
          borderRadius: radiusPx === '16px' ? '8px' : '0px',
          opacity: isDeleteFolderLoading ? 0.5 : 1,
        }}
        disabled={isDeleteFolderLoading}
        type="button"
        className="bg-[white] text-[#A60028] mt-[.625rem] p-[.625rem] w-full hover:bg-[#A60028] hover:text-[white] hover:border"
        onClick={() => deleteFolders()}
      >
        Confirmer la suppression
      </button>
    </div>
  );
}

export default DeleteOperationsInList;
