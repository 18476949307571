import { useContext, useEffect, useRef, useState } from 'react';
import { FolderContext } from '@folder/utils/FoldersContext';
import { Document, Page, pdfjs } from 'react-pdf';
import { PdfNavigator } from '@components/PdfNavigator';
import { InstallerContext } from '@auth/utils/authContext';
import { dimensioning_note } from '@assets/images/images';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface RightPartMprInstructionProps {
  divRightWidth: number | undefined;
}

function RightPartDimensioningNote({
  divRightWidth,
}: RightPartMprInstructionProps): JSX.Element {
  const { radiusPx } = useContext(InstallerContext);
  const { showDimensioningNote, dimensioningNote } = useContext(FolderContext);

  const [numPages, setNumPages] = useState<number>();
  const [pageNumber, setPageNumber] = useState<number>(1);

  const [widthToDisplay, setWidthToDisplay] = useState<number>(0);

  const [containerWidth, setContainerWidth] = useState<number>(0);

  const container = useRef<HTMLDivElement>(null);

  const resizePdf = () => {
    if (container && container.current) {
      const divWidth = container.current.clientWidth;
      setContainerWidth(divWidth);
    }
  };

  const onDocumentLoadSuccess = (pageNum: number) => {
    setNumPages(pageNum);
    setPageNumber(1);
  };

  const getWidth = () => {
    const width = containerWidth;
    setWidthToDisplay(width);
  };

  useEffect(() => {
    window.addEventListener('resize', resizePdf);

    resizePdf();
  }, []);

  useEffect(() => {
    getWidth();
  }, [containerWidth]);

  return (
    <>
      {showDimensioningNote && dimensioningNote !== '' ? (
        <div
          className="max-h-[90vh] fixed h-[90vh] z-[22222] flex flex-col "
          style={{
            width: divRightWidth,
            maxWidth: divRightWidth,
          }}
          ref={container}
        >
          <Document
            file={dimensioningNote}
            onLoadSuccess={(pdf) => {
              onDocumentLoadSuccess(pdf.numPages);
            }}
            onLoadError={() => {
              setNumPages(undefined);
              setPageNumber(1);
            }}
            error={
              <div className="w-full h-full justify-center flex items-center">
                <div className="text-[.75rem] font-light">
                  Impossible d&apos;afficher le fichier.
                </div>
              </div>
            }
            loading={<></>}
          >
            <Page
              renderAnnotationLayer={false}
              pageNumber={pageNumber}
              width={widthToDisplay}
            />
          </Document>
          <PdfNavigator
            numberOfPages={numPages}
            pageActive={pageNumber}
            setPageActive={setPageNumber}
          />
        </div>
      ) : (
        <div
          style={{
            marginTop: '-1.25rem',
            width: divRightWidth,
            borderRadius: radiusPx,
            backgroundImage: `url(${dimensioning_note})`,
            backgroundSize: 'cover',
          }}
          className="max-h-[90vh] fixed h-[90vh] z-[22222] dark:bg-black bg-white"
        />
      )}
    </>
  );
}

export default RightPartDimensioningNote;
