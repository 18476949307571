import { useState, Dispatch, SetStateAction } from 'react';
import { ILatLng } from '../../models/simulator/utils/simulatorTypes';
import AddressInputSearch from './AddressInputSearch';
import ManualAddress from './ManualAddress';

interface AdressFormProps {
  data: any;
  value: string;
  setValue: (val: string, shouldFetchData?: boolean | undefined) => void;
  city: string;
  setCity: Dispatch<SetStateAction<string>>;
  street: string;
  setStreet: Dispatch<SetStateAction<string>>;
  setNumberStreet?: Dispatch<SetStateAction<string>>;
  zip: string;
  setZip: Dispatch<SetStateAction<string>>;
  updateLatLngData?: Dispatch<SetStateAction<ILatLng>>;
  updateCoordinates?: any;
  updateMarkerMap?: Dispatch<SetStateAction<boolean>>;
  updateIsBack?: Dispatch<SetStateAction<boolean>>;
  choiceOk: boolean;
  setChoiceOk: Dispatch<SetStateAction<boolean>>;
  isWorkAddress?: boolean;
  setWorksStreetNumber?: Dispatch<SetStateAction<string>>;
  setIsManual?: Dispatch<SetStateAction<boolean>>;
  errorOwnerAddress?: string;
  setErrorOwnerAddress?: Dispatch<SetStateAction<string>>;
}

function AddressForm({
  data,
  value,
  setValue,
  city,
  setCity,
  street,
  setStreet,
  setNumberStreet,
  zip,
  setZip,
  updateLatLngData,
  updateCoordinates,
  updateMarkerMap,
  updateIsBack,
  choiceOk,
  setChoiceOk,
  isWorkAddress,
  setWorksStreetNumber,
  setIsManual,
  errorOwnerAddress,
  setErrorOwnerAddress,
}: AdressFormProps): JSX.Element {
  const [manualAddress, setManualAddress] = useState<boolean>(false);

  return (
    <>
      {!manualAddress ? (
        <AddressInputSearch
          data={data}
          value={value}
          setManualAdress={setManualAddress}
          setValue={setValue}
          setCity={setCity}
          setStreet={setStreet}
          setZip={setZip}
          choiceOk={choiceOk}
          setChoiceOk={setChoiceOk}
          updateIsBack={updateIsBack}
          updateCoordinates={updateCoordinates}
          updateLatLngData={updateLatLngData}
          updateMarkerMap={updateMarkerMap}
          isWorkAddress={isWorkAddress}
          setWorksStreetNumber={setWorksStreetNumber}
          setIsManual={setIsManual}
          errorOwnerAddress={errorOwnerAddress}
          setErrorOwnerAddress={setErrorOwnerAddress}
        />
      ) : (
        <ManualAddress
          street={street}
          setStreet={setStreet}
          zip={zip}
          setZip={setZip}
          city={city}
          setCity={setCity}
          setManualAdress={setManualAddress}
          setValue={setValue}
          isWorkAddress={isWorkAddress}
          setNumberStreet={setNumberStreet}
          setIsManual={setIsManual}
        />
      )}
    </>
  );
}

export default AddressForm;

AddressForm.defaultProps = {
  setWorksStreetNumber: undefined,
  updateLatLngData: undefined,
  updateCoordinates: undefined,
  updateMarkerMap: undefined,
  updateIsBack: undefined,
  isWorkAddress: false,
  setNumberStreet: undefined,
  setIsManual: undefined,
  errorOwnerAddress: undefined,
  setErrorOwnerAddress: undefined,
};
