/* eslint-disable no-nested-ternary */
import { backgroundCheckbox } from '@assets/images/images';
import { colorTextLightMode, dark5, purple1 } from '@assets/colors';
import { v4 } from 'uuid';
import { InstallerContext } from '@auth/utils/authContext';
import { convertHexToRGBA, numFormatSpace } from '@utils/utils';
import React, { useContext, useState } from 'react';
import { DashboardContext } from '@dashboard/utils/context/dashboardContext';
import { Link } from 'react-router-dom';
import { DASHBOARD_TABS } from '@dashboard/utils/tabs';
import { IListFolders } from '@dashboard/utils/dashboardTypes';

interface IProps {
  data: IListFolders;
  columnsArrays: string[];
}
interface IRowsArray {
  row: string;
  width: string;
}

function RowsInFolderListArray({ data, columnsArrays }: IProps) {
  const [isShown, setIsShown] = useState(false);

  const { isThemeDark, colorPrimary, installer } = useContext(InstallerContext);

  const {
    elementActive,
    isDeleteFolderLoading,
    isModalDeleteFolder,
    rowsSelectedFolders,
    updateRowsSelectedFolders,
  } = useContext(DashboardContext);

  const addRowsSelected = (folder: IListFolders, e: any) => {
    if (e.target.checked) {
      if (rowsSelectedFolders.length === 0) {
        updateRowsSelectedFolders([folder]);
      }
      updateRowsSelectedFolders([...rowsSelectedFolders, folder]);
    } else {
      updateRowsSelectedFolders(
        [...rowsSelectedFolders].filter((row) => row !== folder)
      );
    }
  };

  const rowsToDisplay = (folder: IListFolders) => {
    const rowArrays: IRowsArray[] = [];
    if (elementActive === DASHBOARD_TABS.FOLDERS_INSTRUCTION) {
      for (let i = 0; i < columnsArrays.length; i += 1) {
        switch (columnsArrays[i]) {
          default:
          case 'Référence du dossier':
            rowArrays[i] = { row: folder.référence_dossier, width: '25rem' };
            break;

          case 'Adresse des travaux':
            rowArrays[i] = { row: folder.adresse_travaux, width: '25rem' };
            break;
          case 'Montant MaPrimeRénov':
            rowArrays[i] = {
              row: `${`${numFormatSpace(folder.montant_mpr)} €`}`,
              width: '12.5rem',
            };
            break;
          case 'Montant prime CEE':
            rowArrays[i] = {
              row: `${`${numFormatSpace(folder.prime_cee_bénéficiaire)} €`}`,
              width: '12.5rem',
            };
            break;
          case "Gestion MaPrimeRénov' par Drapo":
            rowArrays[i] = { row: folder.gestion_mpr_drapo, width: '12.5rem' };
            break;
          case 'Frais dossier MPR TTC':
            rowArrays[i] = {
              row: `${`${numFormatSpace(folder.frais_dossier_mpr_ttc)} €`}`,
              width: '12.5rem',
            };
            break;
          case 'Opération':
            rowArrays[i] = { row: folder.opération, width: '25rem' };
            break;
          case 'Nom bénéficiaire':
            rowArrays[i] = { row: folder.nom_client, width: '12.5rem' };
            break;
          case 'Prénom bénéficiaire':
            rowArrays[i] = { row: folder.prénom_client, width: '12.5rem' };
            break;
          case 'Frais dossier CEE TTC':
            rowArrays[i] = {
              row: `${`${numFormatSpace(folder.frais_dossier_cee_ttc)} €`}`,
              width: '12.5rem',
            };
            break;
        }
      }
    } else {
      for (let i = 0; i < columnsArrays.length; i += 1) {
        switch (columnsArrays[i]) {
          default:
          case 'Référence':
            rowArrays[i] = { row: folder.folder_reference, width: '25rem' };
            break;
          case 'Date de simulation':
            rowArrays[i] = {
              row: new Date(folder.created_at).toLocaleDateString('fr'),
              width: '12.5rem',
            };
            break;
          case 'Opération':
            rowArrays[i] = { row: folder.operation, width: '25rem' };
            break;
          case 'Adresse des travaux':
            rowArrays[i] = {
              row: `${folder.work_street} ${folder.work_zipcode} ${folder.work_city}`,
              width: '25rem',
            };
            break;
          case 'Nom du client':
            rowArrays[i] = { row: folder.lastname, width: '12.5rem' };
            break;
          case 'Prénom du client':
            rowArrays[i] = { row: folder.firstname, width: '12.5rem' };
            break;
          case 'Statut Dossier':
            rowArrays[i] = { row: folder.status, width: '25rem' };
            break;
          case 'Type de ménage':
            rowArrays[i] = { row: folder.menage_type, width: '12.5rem' };
            break;
          case 'Téléphone du client':
            rowArrays[i] = { row: folder.phone, width: '12.5rem' };
            break;
          case 'Adresse mail du client':
            rowArrays[i] = { row: folder.email, width: '12.5rem' };
            break;
          case 'Montant MaPrimeRénov':
            rowArrays[i] = {
              row: `${`${numFormatSpace(folder.mpr_amount)} €`}`,
              width: '12.5rem',
            };
            break;
          case 'Montant prime CEE':
            rowArrays[i] = {
              row: `${numFormatSpace(Number(folder.prime_cee_beneficiary))} €`,
              width: '12.5rem',
            };
            break;
          case "Gestion MaPrimeRénov' par Drapo":
            rowArrays[i] = {
              row: `${folder.mpr_management === true ? 'Oui' : 'Non'}`,
              width: '12.5rem',
            };
            break;
          case 'Frais dossier MPR TTC':
            rowArrays[i] = {
              row: `${`${numFormatSpace(folder.mpr_folder_fees)} €`}`,
              width: '12.5rem',
            };
            break;
          case 'Frais dossier CEE TTC':
            rowArrays[i] = {
              row: `${`${numFormatSpace(folder.cee_folder_fees)} €`}`,
              width: '12.5rem',
            };
            break;
        }
      }
    }
    return rowArrays;
  };

  const backgroundColorLightMode = isThemeDark
    ? dark5
    : convertHexToRGBA(colorPrimary, 0.1);

  const colorCheckBox =
    installer.nom_partenaire === '' || installer.nom_partenaire === 'DRAPO'
      ? purple1
      : installer.custom.couleur_boutons;

  const changeHoverAtTrue = () => {
    setIsShown(true);
  };

  const changeHoverAtFalse = () => {
    setIsShown(false);
  };

  return (
    <tr
      key={v4()}
      onMouseEnter={() => setTimeout(changeHoverAtTrue, 200)}
      onMouseLeave={() => setTimeout(changeHoverAtFalse, 200)}
      className="border-t dark:border-t-[#272727] text-colorTextLightMode dark:text-white"
      style={{
        backgroundColor:
          (rowsSelectedFolders.includes(data) || isShown) && !isThemeDark
            ? backgroundColorLightMode
            : (rowsSelectedFolders.includes(data) || isShown) && isThemeDark
            ? colorTextLightMode
            : '',
      }}
    >
      <td
        style={{ opacity: isDeleteFolderLoading ? 0.1 : 1 }}
        className="sb-text-rem-caption1 text-left w-auto px-[1.5rem]"
      >
        <div
          style={{
            backgroundImage: rowsSelectedFolders.includes(data)
              ? `url(${backgroundCheckbox})`
              : '',
            backgroundSize: 'center',
            backgroundPositionX: '60%',
            backgroundPositionY: 'center',
            backgroundRepeat: 'no-repeat',
            border: rowsSelectedFolders.includes(data)
              ? ''
              : '1px solid #C6C6C6',
            // eslint-disable-next-line no-nested-ternary
            backgroundColor: rowsSelectedFolders.includes(data)
              ? installer.nom_partenaire === 'DRAPO' ||
                installer.nom_partenaire === ''
                ? colorCheckBox
                : installer.custom.couleur_boutons
              : '',
          }}
          className="w-[1.125rem] h-[1.125rem]"
        >
          <input
            disabled={isDeleteFolderLoading || isModalDeleteFolder}
            className="input-checked w-full h-full"
            checked={rowsSelectedFolders.includes(data)}
            onChange={(e) => addRowsSelected(data, e)}
            type="checkbox"
          />
        </div>
      </td>

      {rowsToDisplay(data).map((row) => {
        return (
          <td key={v4()} className="sb-text-rem-caption1">
            {elementActive === DASHBOARD_TABS.FOLDERS_INSTRUCTION ? (
              <Link
                style={{ width: row.width }}
                className={`py-[1rem] flex justify-start overflow-hidden text-left ${
                  data.nom_client === row.row || data.prénom_client === row.row
                    ? 'font-[600]'
                    : ''
                }`}
                to={`/suivi-de-dossier/${data.référence_dossier}`}
              >
                {row.row === '' ||
                row.row === '0 €' ||
                row.row === undefined ||
                row.row === null
                  ? '-'
                  : row.row}
              </Link>
            ) : (
              <Link
                style={{ width: row.width }}
                to={`/informations-complementaires/${data.folder_reference}`}
                rel="noreferrer"
                className={`flex justify-start py-[1rem] ${
                  data.firstname === row.row || data.lastname === row.row
                    ? 'font-[600]'
                    : ''
                }`}
              >
                {row.row === '' ||
                row.row === '0 €' ||
                row.row === undefined ||
                row.row === null
                  ? '-'
                  : row.row}
              </Link>
            )}
          </td>
        );
      })}
    </tr>
  );
}

export default RowsInFolderListArray;
