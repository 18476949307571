import { InstallerContext } from '@auth/utils/authContext';
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  backgroundBodyLightMode,
  backgroundQuaternaryLightMode,
  colorTextLightMode,
  dark1,
  white,
} from '@assets/colors';
import {
  Button,
  CheckCard,
  WarningNotification,
} from 'storybook-design-system-drapo';
import { CloseIcon } from '@assets/svgComponents/svgComponents';
import { useNavigate, useParams } from 'react-router-dom';
import {
  IAdditionalInformationSendMailRecap,
  IOperationDatasType,
} from '@simulator/utils/simulatorTypes';
import { sendSummaryByMail } from '@simulator/apiRequests/SimulatorRequests';
import Loader from '@components/loaders/Loader';
import { useMediaQuery } from 'react-responsive';
import { AddMailButton } from './AddMailButton';
import { OneNewMail } from './OneNewMail';
import { AddAdditionalInformation } from './AdditionalInformationForm';
import { SimulatorContext } from '../../../utils/simulatorContext';

interface IProps {
  setisSendEmailModal: Dispatch<SetStateAction<boolean>>;
  operationsSelected: IOperationDatasType[];
  ceeIsSelect: boolean;
  mprIsSelect: boolean;
}

function ModalWhiteLabel({
  setisSendEmailModal,
  operationsSelected,
  ceeIsSelect,
  mprIsSelect,
}: IProps): JSX.Element {
  const router = useNavigate();

  const [isAdditionalInformation, setIsAdditionalInformation] = useState(false);
  const [arrayMail, setArrayMail] = useState<string[]>(['']);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errForm, setErrorForm] = useState<string>('');

  const [additionalInformation, setAdditionalInformation] =
    useState<IAdditionalInformationSendMailRecap>({
      firstname: '',
      lastname: '',
      phone: '',
    });

  const {
    colorPrimary,
    radiusPx,
    colorTextButton,
    isThemeDark,
    colorAlertWhiteLabel,
  } = useContext(InstallerContext);
  const { simulatorDatas, cadastreDatas } = useContext(SimulatorContext);

  const isMobile = useMediaQuery({ maxWidth: 1024 });
  const html = document.getElementsByTagName('html')[0];

  const whiteLabelName: string = useParams().marque || '';
  const whiteLabelNameWithFirstLetterUppercase = () => {
    const words = whiteLabelName.split(' ');
    const wordsWithFirstLetterUppercase = words.map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    });
    return wordsWithFirstLetterUppercase.join(' ');
  };

  useEffect(() => {
    if (!isAdditionalInformation) {
      setAdditionalInformation({
        firstname: '',
        lastname: '',
        phone: '',
      });
    }
  }, [isAdditionalInformation]);

  const sendRecap = () => {
    html.classList.remove('not-scroll');
    if (!arrayMail.includes('')) {
      sendSummaryByMail(
        simulatorDatas,
        operationsSelected,
        ceeIsSelect,
        mprIsSelect,
        cadastreDatas,
        arrayMail,
        isAdditionalInformation,
        additionalInformation,
        whiteLabelName,
        setIsLoading,
        setisSendEmailModal,
        router,
        setErrorForm
      );
    } else {
      setErrorForm('Veuillez remplir tous les champs');
    }
  };

  return (
    <div
      style={{ zIndex: 61 }}
      className="w-screen h-screen bg-[#000000] fixed inset-0 z-50 h-full flex items-center justify-center bg-opacity-30 mt-[-5rem]  mt-0 ml-0"
    >
      <div
        style={{
          borderRadius: isMobile ? '' : radiusPx,
          backgroundColor: isThemeDark ? '#202020' : backgroundBodyLightMode,
        }}
        className="p-[2rem] relative bg-black shadow-2xl overflow-hidden lg:h-auto h-screen lg:w-5/12 w-screen"
      >
        <button
          type="button"
          onClick={() => {
            setisSendEmailModal(false);
            html.classList.remove('not-scroll');
          }}
        >
          <CloseIcon
            className="absolute top-[2rem] right-[2rem] cursor-pointer"
            width="1.5rem"
            height="1.5rem"
            fill={isThemeDark ? 'white' : '#191919'}
          />
        </button>
        {errForm !== '' && (
          <WarningNotification
            warningText={errForm}
            backgroundColor={colorAlertWhiteLabel}
            color={white}
            borderRadius={radiusPx}
          />
        )}

        <ul className="flex flex-col space-y-[.5rem] lg:mt-[2rem]">
          <p style={{ color: isThemeDark ? white : dark1 }}>
            Merci de renseigner au moins une adresse email pour recevoir votre
            récapitulatif
          </p>
          {arrayMail.map((mail, index) => (
            <OneNewMail
              mail={mail}
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              index={index}
              arrayMail={arrayMail}
              setArrayMail={setArrayMail}
            />
          ))}
          {arrayMail.length < 6 && (
            <AddMailButton arrayMail={arrayMail} setArrayMail={setArrayMail} />
          )}
        </ul>

        <CheckCard
          backgroundColorChecked={colorPrimary}
          backgroundColorNotChecked={
            isThemeDark ? dark1 : backgroundQuaternaryLightMode
          }
          colorChecked={colorTextButton}
          colorHover={colorTextButton}
          colorNotChecked={isThemeDark ? white : colorTextLightMode}
          dataTestId="checkcard"
          addClass="!py-[1rem]  px-[2rem] mt-[1rem] dark:border-none border border-borderLightMode"
          label={`Je souhaite être contacté par ${whiteLabelNameWithFirstLetterUppercase()}`}
          borderRadius={radiusPx}
          onClick={() => setIsAdditionalInformation(!isAdditionalInformation)}
        />
        {isAdditionalInformation && (
          <AddAdditionalInformation
            setAdditionalInformation={setAdditionalInformation}
            additionalInformation={additionalInformation}
          />
        )}
        {isLoading && <Loader />}
        <div className="h-[2rem] w-full mt-[1rem]">
          <Button
            borderRadius={radiusPx}
            label="Envoyer"
            arrow
            disabled={
              isAdditionalInformation &&
              (additionalInformation.firstname === '' ||
                additionalInformation.lastname === '' ||
                additionalInformation.phone === '')
            }
            addClass="w-4/12 float-right"
            backgroundColor={colorPrimary}
            color={colorTextButton}
            onClick={() => sendRecap()}
          />
        </div>
      </div>
    </div>
  );
}

export { ModalWhiteLabel };
