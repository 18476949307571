import { useContext, useEffect, useState } from 'react';
import { Button, CheckCardSquare } from 'storybook-design-system-drapo';
import { InstallerContext } from '@auth/utils/authContext';
import { nextStepAudit } from '@simulator/utils/functions';
import { SimulatorContext } from '@simulator/utils/simulatorContext';

import { IOptionType } from '@simulator/utils/simulatorTypes';
import { EnergyConsumptionLabelHouse } from '@simulator/components/forms/audit/EnergyConsumptionLabelHouse';
import { dark1, white } from '@assets/colors';
import { convertHexToRGBA } from '@utils/utils';

function AuditForm(): JSX.Element {
  const {
    updateStepNumber,
    updateGraphListOperation,
    updateGraphOperation,
    graphOperation,
    updateCurrentOperation,
    currentOperation,
    auditFromSummary,
  } = useContext(SimulatorContext);
  const { colorPrimary, radiusPx, isThemeDark, colorTextButton } =
    useContext(InstallerContext);

  const [dataOption, setDataOption] = useState('');
  const [noChecked, setNoChecked] = useState(false);
  const [yesChecked, setYesChecked] = useState(false);
  const [currentEnergyConsumption, setCurrentEnergyConsumption] = useState('');
  const [currentEnergyLabel, setCurrentEnergyLabel] = useState('');
  const [projectedEnergyConsumption, setProjectedEnergyConsumption] =
    useState('');
  const [projectedEnergyLabel, setProjectedEnergyLabel] = useState('');

  const onClickChange = (option: string) => {
    setDataOption(option);
    setNoChecked(option !== 'oui');
    setYesChecked(option !== 'non');
  };

  const nextButton = () => {
    nextStepAudit(
      updateStepNumber,
      updateCurrentOperation,
      updateGraphListOperation,
      updateGraphOperation,
      graphOperation,
      currentEnergyLabel,
      currentEnergyConsumption,
      projectedEnergyLabel,
      projectedEnergyConsumption,
      dataOption,
      currentOperation.id
    );
  };

  const checkIfDisabled =
    dataOption === '' ||
    (dataOption === 'oui' &&
      (currentEnergyConsumption === '' ||
        currentEnergyLabel === '' ||
        projectedEnergyConsumption === '' ||
        projectedEnergyLabel === ''));

  useEffect(() => {
    if (auditFromSummary) {
      setDataOption('oui');
      setNoChecked(false);
      setYesChecked(true);
    }
  }, []);

  return (
    <div className="pb-[15.625rem]">
      {!auditFromSummary && (
        <div
          style={{ borderRadius: radiusPx }}
          className="dark:bg-dark-2 bg-white animate__animated animate__fadeIn p-[1.5rem] mt-[1.5rem] leading-[2rem]"
        >
          <p className="mb-[.625rem] text-[.875rem] dark:text-white text-dark-1">
            Éligibilité au bonus
          </p>
          <p className="mb-[.938rem] text-[.75rem] font-light dark:text-white text-dark-1">
            Voulez vous tester votre éligibilité à un bonus pour votre audit
            énergétique ?
          </p>
          <div className="flex space-x-[2.5rem]">
            {graphOperation.options.map((item: IOptionType) => {
              const checkedState =
                item.value === 'oui' ? yesChecked : noChecked;
              return (
                <div key={item.value} className="w-1/2 h-[18rem]">
                  <CheckCardSquare
                    borderRadius={radiusPx}
                    textColorChecked={colorTextButton}
                    textColorNotChecked={isThemeDark ? white : dark1}
                    backgroundColorNotChecked={
                      isThemeDark ? dark1 : convertHexToRGBA(colorPrimary, 0.1)
                    }
                    backgroundColorChecked={colorPrimary}
                    checked={checkedState}
                    label={item.label}
                    onClick={() => onClickChange(item.value.toString())}
                    dataTestId={`audit-card--${graphOperation.id}`}
                    addClass={
                      !isThemeDark
                        ? 'border border-borderLightMode border-solid'
                        : ''
                    }
                  />
                </div>
              );
            })}
          </div>
        </div>
      )}

      {yesChecked &&
        graphOperation.childrens.length > 0 &&
        graphOperation.childrens[0].childrens.length > 0 && (
          <>
            <EnergyConsumptionLabelHouse
              energyConsumptionValue={currentEnergyConsumption}
              setEnergyConsumptionValue={setCurrentEnergyConsumption}
              energyLabel={currentEnergyLabel}
              setEnergyLabel={setCurrentEnergyLabel}
              isCurrent
            />

            {/*------------------------------------------------------------*/}

            <EnergyConsumptionLabelHouse
              energyConsumptionValue={projectedEnergyConsumption}
              setEnergyConsumptionValue={setProjectedEnergyConsumption}
              energyLabel={projectedEnergyLabel}
              setEnergyLabel={setProjectedEnergyLabel}
              isCurrent={false}
            />
          </>
        )}

      <Button
        disabled={checkIfDisabled}
        onClick={() => nextButton()}
        addClass="mt-[1.5rem] !w-full"
        label="Suivant"
        color="white"
        backgroundColor={colorPrimary}
        buttonArrowSmall
        dataTestId="next"
      />
    </div>
  );
}

export { AuditForm };
