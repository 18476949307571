/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
import { useLocation } from 'react-router-dom';
import { InstallerContext } from '@auth/utils/authContext';
import { useContext, useEffect, useState } from 'react';
import { DASHBOARD_TABS } from '@dashboard/utils/tabs';
import { colorTextLightMode, dark5, white } from '@assets/colors';
import { convertHexToRGBA } from '@utils/utils';
import { ChevronIcon, GridViewIcon } from '@assets/svgComponents/svgComponents';
import { v4 } from 'uuid';
import ListLinksDropdown from './ListLinksDropdown';

function DashboardButton(): JSX.Element {
  const location = useLocation();
  const page = location.pathname;

  const [iconDashboard, setIconDashboard] = useState<JSX.Element>(
    <GridViewIcon fill="white" width="2rem" height="2rem" />
  );
  const [isOpen, setIsOpen] = useState(page === '/');
  const [isShown, setIsShown] = useState(false);

  const { isThemeDark, colorPrimary, colorTextButton, radiusPx } =
    useContext(InstallerContext);

  // const session = {
  //   title: 'Session Active',
  //   name: installer.raison_sociale_de_lentreprise,
  // };

  useEffect(() => {
    if (!isThemeDark) {
      setIconDashboard(
        <GridViewIcon
          fill={page === '/' || isShown ? colorPrimary : '#BEBFBE'}
          width="2rem"
          height="2rem"
        />
      );
    } else {
      setIconDashboard(
        <GridViewIcon
          fill={
            isShown
              ? white
              : convertHexToRGBA('#ffffff', page === '/' ? 1 : 0.3)
          }
          width="2rem"
          height="2rem"
        />
      );
    }
  }, [isThemeDark, isShown]);

  const colorText = isThemeDark
    ? white
    : colorTextButton === 'white'
    ? colorPrimary
    : colorTextLightMode;
  return (
    <div className="px-[1rem]">
      <div
        onMouseEnter={() => setIsShown(true)}
        onMouseLeave={() => setIsShown(false)}
        style={{
          borderRadius: radiusPx === '0px' ? '0px' : '8px',
          backgroundColor:
            (isOpen || page === '/' || isShown) && !isThemeDark
              ? convertHexToRGBA(colorPrimary, 0.22)
              : (isOpen || page === '/') && isThemeDark
              ? dark5
              : '',
        }}
        className={`cursor-pointer pl-[2.625rem] pr-[1.225rem] py-[.875rem] w-full dark:text-white text-[#5D5D5F] dark:hover:bg-dark-5 flex flex-col space-y-[.5rem] `}
      >
        <button
          type="button"
          className="flex items-center w-full"
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        >
          {iconDashboard}
          <div
            style={{
              color: isThemeDark
                ? 'white'
                : convertHexToRGBA(
                    page === '/' || isShown ? colorText : colorTextLightMode,
                    1
                  ),
            }}
            className="sb-text-rem-body2 whitespace-nowrap font-medium ml-[1rem]"
          >
            Tableau de bord
          </div>
          <ChevronIcon
            width="1.5rem"
            height="1.5rem"
            fill={
              !isThemeDark && (page === '/' || isShown)
                ? colorText
                : !isThemeDark && !isShown
                ? colorTextLightMode
                : 'white'
            }
            className="ml-auto transition-transform duration-300"
            transform={isOpen ? 'rotate(-90)' : 'rotate(90)'}
          />
        </button>
        {isOpen &&
          Object.keys(DASHBOARD_TABS).map((dashboardTab) => (
            <div key={v4()}>
              <ListLinksDropdown data={DASHBOARD_TABS[dashboardTab]} />
            </div>
          ))}
      </div>
    </div>
  );
}

export default DashboardButton;
