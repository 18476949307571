import LoaderMyProfileGeneral from '@components/loaders/LoaderMyProfileGeneral';
import {
  ICardInformation,
  IMyProfilDatas,
} from '@dashboard/utils/dashboardTypes';
import { Dispatch, SetStateAction } from 'react';
import LoginInformation from './leftPart/LoginInformation';
import LogoCompany from './leftPart/LogoCompany';
import CardInformation from './rightPart/CardInformations';

interface IProps {
  datas: IMyProfilDatas;
  setDatasMyProfil: Dispatch<SetStateAction<IMyProfilDatas>>;
  isLoading: boolean;
}
function GeneralContainer({
  datas,
  setDatasMyProfil,
  isLoading,
}: IProps): JSX.Element {
  const arrayDatasFiancial: ICardInformation[] = [
    { label: 'IBAN', value: datas.iban },
    { label: 'BIC', value: datas.bic },
    { label: 'SIREN', value: datas.siren },
    { label: 'SIRET', value: datas.siret },
    { label: 'Ville du RCS', value: datas.ville_du_rcs },
    { label: 'Capital Social', value: datas.capital_social },
  ];
  const arrayDatasCompany: ICardInformation[] = [
    { label: 'Site web de l’entreprise', value: datas.site_web_de_lentreprise },
    {
      label: 'Téléphone de l’entreprise',
      value: datas.téléphone_de_lentreprise,
    },
    { label: 'Nombre de salariés', value: datas.nombre_de_salariés },
    { label: 'Chiffre d’affaires', value: datas.chiffre_daffaire },
    {
      label: 'Année de création de l’entreprise',
      value: datas.année_de_création_de_lentreprise,
    },
    { label: 'Adresse du siège social', value: datas.rue_du_siège_social },
    { label: 'Prénom et nom du signataire', value: datas.prénom_du_signataire },
    {
      label: 'Adresse email de la comptabilité',
      value: datas.adresse_email_du_service_comptabilité,
    },
  ];

  return (
    <div className="w-full pl-[1.875rem] pr-[1.5rem] lg:flex justify-between">
      {!isLoading ? (
        <>
          <div className="lg:w-5/12 pr-[1.875rem] lg:border-r dark:lg:border-r-dark-5 lg:border-r-borderLightMode pt-[1.875rem]">
            <LogoCompany
              logo={datas.logo.value}
              setDatasMyProfil={setDatasMyProfil}
            />

            <LoginInformation
              registrationDate={datas.date_dinscription.value}
              mail={datas.login.value}
            />
          </div>
          <div className="w-1 lg:h-[82vh]" />
          <div className="lg:w-7/12 pl-[1.875rem] pt-[1.875rem]">
            <CardInformation
              dataArray={arrayDatasFiancial}
              title="Informations financières"
              setDatasMyProfil={setDatasMyProfil}
            />
            <div className="desktop:mt-[3.125rem] mt-[1.875rem]">
              <CardInformation
                dataArray={arrayDatasCompany}
                title="Informations de l’entreprise"
                setDatasMyProfil={setDatasMyProfil}
              />
            </div>
          </div>
        </>
      ) : (
        <LoaderMyProfileGeneral />
      )}
    </div>
  );
}

export default GeneralContainer;
