import React, { Dispatch, SetStateAction } from 'react';
import {
  IKeyValueStringType,
  IWhiteLabelCustomType,
} from '@simulator/utils/simulatorTypes';
import {
  IFileFolderType,
  IFolderDatasType,
  ITaxHousehold,
} from './folderTypes';

interface Folders {
  folderDatas: IFolderDatasType;
  updateFolderDatas: Dispatch<SetStateAction<IFolderDatasType>>;
  folderReference: string;
  housingOwner: string;
  stepNumberFolder: number;
  updateStepNumberFolder: Dispatch<SetStateAction<number>>;
  title: string;
  subtitle: string;
  updateTitle: Dispatch<SetStateAction<string>>;
  updateSubtitle: Dispatch<SetStateAction<string>>;
  parcelPrefix: string;
  updateParcelPrefix: Dispatch<SetStateAction<string>>;
  parcelSection: string;
  updateParcelSection: Dispatch<SetStateAction<string>>;
  parcelNumber: string;
  updateParcelNumber: Dispatch<SetStateAction<string>>;
  genderClient: string;
  updateGenderClient: Dispatch<SetStateAction<string>>;
  householdsDatas: ITaxHousehold[];
  updateHouseholdsDatas: Dispatch<SetStateAction<ITaxHousehold[]>>;
  mprAgree: boolean;
  updateMprAgree: Dispatch<SetStateAction<boolean>>;
  subcontractorAgree: boolean;
  updateSubcontractorAgree: Dispatch<SetStateAction<boolean>>;
  globalFiles: IFileFolderType[] | [];
  updateGlobalFiles: Dispatch<SetStateAction<IFileFolderType[] | []>>;
  updateHousingOwner: Dispatch<SetStateAction<string>>;
  focusCustomerInfo: boolean;
  updateFocusCustomerInfo: Dispatch<SetStateAction<boolean>>;
  focusCadastre: boolean;
  updateFocusCadastre: Dispatch<SetStateAction<boolean>>;
  focusTax: boolean;
  updateFocusTax: Dispatch<SetStateAction<boolean>>;
  menageIndivision: boolean;
  updateMenageIndivision: Dispatch<SetStateAction<boolean>>;
  hasCadastre: boolean;
  updateHasCadastre: Dispatch<SetStateAction<boolean>>;
  hasIndivision: boolean;
  updateHasIndivision: Dispatch<SetStateAction<boolean>>;
  workAddress: string;
  workCoordinates: IKeyValueStringType;
  isBack: boolean;
  updateIsBack: Dispatch<SetStateAction<boolean>>;
  numberHousehold: number;
  updateNumberHousehold: Dispatch<SetStateAction<number>>;
  clientInfosAlreadySet: boolean;
  updateClientInfosAlreadySet: Dispatch<SetStateAction<boolean>>;
  folderInCreation: boolean;
  updateFolderInCreation: Dispatch<SetStateAction<boolean>>;
  hasDimensioningNote: boolean;
  updateHasDimensioningNote: Dispatch<SetStateAction<boolean>>;
  roomsHeight: string;
  updateRoomsHeight: Dispatch<SetStateAction<string>>;
  stopTempHeatPump: string;
  updateStopTempHeatPump: Dispatch<SetStateAction<string>>;
  tempPowerHeatPump: string;
  updateTempPowerHeatPump: Dispatch<SetStateAction<string>>;
  heatingElementPower: string;
  updateHeatingElementPower: Dispatch<SetStateAction<string>>;
  otherHeatingSystems: string;
  updateOtherHeatingSystems: Dispatch<SetStateAction<string>>;
  dimensioningNote: string;
  updateDimensioningNote: Dispatch<SetStateAction<string>>;
  showDimensioningNote: boolean;
  updateShowDimensioningNote: Dispatch<SetStateAction<boolean>>;
  housingValue: IKeyValueStringType[];
  updateHousingValue: Dispatch<SetStateAction<IKeyValueStringType[]>>;
  hasDimensioningNotePro: boolean;
  updateHasDimensioningNotePro: Dispatch<SetStateAction<boolean>>;
  dimensioningNotePro: File | undefined;
  updateDimensioningNotePro: Dispatch<SetStateAction<File | undefined>>;
  thermalInsulationIsCheck: boolean;
  updateThermalInsulationIsCheck: Dispatch<SetStateAction<boolean>>;
  modalThermalInsulationAlreadyClosed: boolean;
  updateModalThermalInsulationAlreadyClosed: Dispatch<SetStateAction<boolean>>;
}

export const FolderContext = React.createContext({} as Folders);

interface FoldersAndSimulator {
  folderReference: string;
  updateFolderReference: Dispatch<SetStateAction<string>>;
  ownerOrLandlord: string;
  updateOwnerOrLandlord: Dispatch<SetStateAction<string>>;
  whiteLabelCustom: IWhiteLabelCustomType;
  updateWhiteLabelCustom: Dispatch<SetStateAction<IWhiteLabelCustomType>>;
  isPrefilled: boolean;
  updateIsPrefilled: Dispatch<SetStateAction<boolean>>;
}

export const FoldersAndSimulatorContext = React.createContext(
  {} as FoldersAndSimulator
);
