import {
  backgroundQuaternaryLightMode,
  colorTextLightMode,
  dark1,
  dark5,
  white,
} from '@assets/colors';
import { InstallerContext } from '@auth/utils/authContext';
import { IAccountMPR } from '@folder/utils/folderTypes';
import { convertHexToRGBA } from '@utils/utils';
import React, { Dispatch, SetStateAction, useContext, useState } from 'react';
import {
  CheckCard,
  DatePicker,
  FileMultiUpload,
  Input,
} from 'storybook-design-system-drapo';
import { v4 } from 'uuid';

interface IProps {
  dateDeposite: string;
  setDateDeposite: Dispatch<SetStateAction<string>>;
  filesUploaded: any[];
  setFilesUploaded: Dispatch<SetStateAction<any[]>>;
  dataAccountMpr: IAccountMPR;
  setDataAccountMpr: Dispatch<SetStateAction<IAccountMPR>>;
  setFileToSendDeposite: Dispatch<SetStateAction<File | undefined>>;
  isCheckDeclareIdMpr: boolean;
  setIsCheckDeclareIdMpr: Dispatch<SetStateAction<boolean>>;
  isCheckFolderDeposite: boolean;
  setIsCheckFolderDeposite: Dispatch<SetStateAction<boolean>>;
  errorDateDeposite: boolean;
  setErrorDateDeposite: Dispatch<SetStateAction<boolean>>;
}

export default function FormAccountMpr({
  dateDeposite,
  setDateDeposite,
  filesUploaded,
  setFilesUploaded,
  dataAccountMpr,
  setDataAccountMpr,
  setFileToSendDeposite,
  isCheckDeclareIdMpr,
  setIsCheckDeclareIdMpr,
  isCheckFolderDeposite,
  setIsCheckFolderDeposite,
  errorDateDeposite,
  setErrorDateDeposite,
}: IProps): JSX.Element {
  const [isFileDepositeLoading, setIsFileDepositeLoading] =
    useState<boolean>(false);
  const [fileNameDeposite, setFileNameDeposite] = useState<string>('');

  const [isCheckFranceConnect, setIsCheckFranceConnect] = useState(false);
  const { radiusPx, colorTextButton, isThemeDark, colorPrimary } =
    useContext(InstallerContext);

  const arrayCheckboxMpr = [
    'Je préfère le faire plus tard',
    'Je souhaite déclarer les identifiants MPR',
  ];
  const arrayInputsDataAccountMpr = ['Identifiant MPR', 'Mot de passe MPR'];
  const subtitle = `J’atteste que la prime figurant sur la notification d’octroi est la même que celle calculée par Drapo pour ce dossier.`;

  const onUploadFile = (file: File) => {
    setIsFileDepositeLoading(false);
    setFileNameDeposite(file.name);
    setFileToSendDeposite(file);
    setFilesUploaded([
      {
        name: file.name,
        path: URL.createObjectURL(file),
      },
    ]);
  };

  const onDeleteFileUpload = () => {
    setFilesUploaded([]);
  };
  return (
    <div
      style={{ borderRadius: radiusPx }}
      className="dark:bg-dark-2 bg-white p-[1.5rem] mt-[2rem] dark:text-white text-colorTextLightMode"
    >
      <div className="sb-text-rem-body2">Déclaration du compte MPR</div>
      <div className="flex flex-col space-y-[.875rem]  mt-[1.5rem]">
        {arrayCheckboxMpr.map((el) => {
          return (
            <CheckCard
              key={el}
              backgroundColorChecked={colorPrimary}
              backgroundColorNotChecked={
                isThemeDark ? dark1 : backgroundQuaternaryLightMode
              }
              addClass="!py-[1.875rem]"
              colorChecked={colorTextButton}
              colorHover={colorTextButton}
              colorNotChecked={isThemeDark ? white : colorTextLightMode}
              dataTestId="checkcard"
              label={el}
              checked={
                el.includes('plus tard')
                  ? !isCheckDeclareIdMpr
                  : isCheckDeclareIdMpr
              }
              borderRadius={radiusPx}
              onClick={() => {
                setIsCheckDeclareIdMpr(!isCheckDeclareIdMpr);
                setErrorDateDeposite(false);
              }}
            />
          );
        })}
      </div>
      {isCheckDeclareIdMpr && (
        <div className="flex flex-col space-y-[.875rem]  mt-[.875rem]">
          {arrayInputsDataAccountMpr.map((el) => (
            <div key={el}>
              <Input
                backgroundColor={
                  isThemeDark ? dark1 : backgroundQuaternaryLightMode
                }
                color={isThemeDark ? white : dark1}
                label={el}
                onChange={(e) => {
                  setDataAccountMpr(
                    el.includes('Identifiant')
                      ? { ...dataAccountMpr, id: e.target.value }
                      : {
                          ...dataAccountMpr,
                          password: e.target.value,
                        }
                  );
                }}
                value={
                  el.includes('Identifiant')
                    ? dataAccountMpr.id
                    : dataAccountMpr.password
                }
                inputType="text"
                dataTestId="id"
                borderRadius={radiusPx}
              />
            </div>
          ))}
          <CheckCard
            colorChecked={colorTextButton}
            colorHover={colorTextButton}
            colorNotChecked={isThemeDark ? white : colorTextLightMode}
            backgroundColorChecked={colorPrimary}
            backgroundColorNotChecked={
              isThemeDark ? dark1 : backgroundQuaternaryLightMode
            }
            dataTestId="checkcard"
            label="Le bénéficiaire a utilisé FranceConnect"
            borderRadius={radiusPx}
            onClick={() => setIsCheckFranceConnect(!isCheckFranceConnect)}
            addClass="!h-[5rem]"
          />
          {isCheckFranceConnect && (
            <>
              <div className="leading-[1.5rem] text-[.75rem] text-dark-1 dark:text-white">
                Dans ce cas, afin de poursuivre l&apos;instruction de votre
                dossier, le bénéficiaire doit se connecter sur son compte
                MaPrimeRénov&apos; et désigner Drapo comme mandataire avec le
                Numéro d’immatriculation mandataire :
              </div>
              <div className="text-center">
                <b>100DRAPO2_DLPF</b>
              </div>
            </>
          )}
          <CheckCard
            backgroundColorChecked={colorPrimary}
            backgroundColorNotChecked={
              isThemeDark ? dark1 : backgroundQuaternaryLightMode
            }
            checked={isCheckFolderDeposite}
            addClass="!py-[1.875rem]"
            colorChecked={colorTextButton}
            colorHover={colorTextButton}
            subtitle={subtitle}
            colorNotChecked={isThemeDark ? white : colorTextLightMode}
            dataTestId="checkcard"
            label="J'ai déja déposé le dossier"
            borderRadius={radiusPx}
            onClick={() => setIsCheckFolderDeposite(!isCheckFolderDeposite)}
          />
          {isCheckFolderDeposite && (
            <div className="flex flex-col space-y-[.75rem]">
              <DatePicker
                backgroundColorCalendar={
                  isThemeDark ? dark1 : backgroundQuaternaryLightMode
                }
                backgroundColorHeaderCalendar={
                  isThemeDark ? dark1 : backgroundQuaternaryLightMode
                }
                borderInput={errorDateDeposite ? '1px solid red' : 'none'}
                backgroundColorCellCalendar={colorPrimary}
                colorCalendar={isThemeDark ? white : colorTextLightMode}
                colorCellCalendarActive={colorTextButton}
                label="Date de dépôt"
                setDate={setDateDeposite}
                date={dateDeposite}
                dataTestId="depot"
                addClass="text-[.75rem]/[.92]"
                borderRadiusCalendar={radiusPx}
                required
                borderRadiusCellCalendar={radiusPx !== '0px' ? '8px' : '0px'}
              />
              {errorDateDeposite && (
                <p className="sb-text-rem-body2 text-red-500 ">
                  La date de dépôt n&apos;est pas valide.
                </p>
              )}
              <FileMultiUpload
                key={v4()}
                fileName="Notification d’octroi"
                fileNameUpload={fileNameDeposite}
                required
                isFileUploading={isFileDepositeLoading}
                borderRadiusContainer={radiusPx}
                borderRadius={radiusPx !== '0px' ? '8px' : '0px'}
                backgroundColorFileLoaded={colorPrimary}
                backgroundUploadedColor={colorPrimary}
                containerBackgroundColor={
                  isThemeDark ? dark1 : convertHexToRGBA(colorPrimary, 0.1)
                }
                color={isThemeDark ? white : dark1}
                backgroundColorAddFile={isThemeDark ? dark5 : white}
                backgroundColorComponent={
                  isThemeDark ? dark1 : convertHexToRGBA(colorPrimary, 0.1)
                }
                onDelete={onDeleteFileUpload}
                onChangeFileUpload={(files) => onUploadFile(files)}
                filesUploaded={filesUploaded}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export { FormAccountMpr };
