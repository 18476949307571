import { AddCircleIcon } from '@assets/svgComponents/svgComponents';
import { InstallerContext } from '@auth/utils/authContext';
import { Dispatch, SetStateAction, useContext } from 'react';

interface IProps {
  setArrayMail: Dispatch<SetStateAction<string[]>>;
  arrayMail: string[];
}

function AddMailButton({ arrayMail, setArrayMail }: IProps): JSX.Element {
  const { colorPrimary, radiusPx, colorTextButton } =
    useContext(InstallerContext);
  return (
    <button
      type="button"
      onClick={() => setArrayMail(arrayMail.concat(''))}
      className="flex items-center justify-between px-[1.5rem] py-[.5rem] cursor-pointer"
      style={{
        borderRadius: radiusPx,
        color: colorTextButton,
        backgroundColor: colorPrimary,
      }}
    >
      <div>Ajouter une adresse</div>
      <AddCircleIcon width="1.5rem" height="1.5rem" fill={colorTextButton} />
    </button>
  );
}

export { AddMailButton };
