import { useContext, useEffect, useMemo, useState } from 'react';
import LoaderCardlink from '@components/loaders/LoaderCardLink';
import { scrollSmoothToPosition } from '@utils/utils';
import {
  IGraphType,
  IOperationDatasType,
} from '@simulator/utils/simulatorTypes';
import { SimulatorContext } from '@simulator/utils/simulatorContext';
import { AuditForm } from '@simulator/components/forms/audit/AuditForm';
import { removeStep } from '@simulator/utils/functions';
import InputThermalResistance from '@simulator/components/forms/InputThermalResistance';
import WorksAddress from './forms/WorksAddress';
import CardForm from './forms/CardForm';
import ScopUnits from './scopUnits/ScopUnits';
import InputSurface from './forms/InputSurface';
import InputNumber from './forms/InputNumber';
import IncomesForm from './forms/IncomesForm';
import CardSquareForm from './forms/CardSquareForm';
import NoAid from './forms/NoAid';
import ChooseMultipleOperation from './ChooseMultipleOperation';
import TotalCost from './forms/TotalCost';

function RenderStep(): JSX.Element {
  const {
    graphGeneral,
    graphOperation,
    updateTitle,
    updateSubtitle,
    stepNumber,
    generalComplete,
    currentOperation,
    multipleOperations,
    updateGraphGeneral,
  } = useContext(SimulatorContext);

  const [graphActive, setGraphActive] = useState<IGraphType>(graphGeneral);
  const [componentActive, setComponentActive] = useState<JSX.Element>(<></>);

  // vérifie qu'on ait que des opérations d'isolation 'bar-en-101', 'bar-en-102', 'bar-en-102-int', 'bar-en-103'
  const areOnlyIsolationOperationsSelected = useMemo(() => {
    if (multipleOperations && multipleOperations.length > 0) {
      return multipleOperations.every((operationData: IOperationDatasType) =>
        ['bar-en-101', 'bar-en-102', 'bar-en-102-int', 'bar-en-103'].includes(
          operationData.id.toLowerCase()
        )
      );
    }
    return false;
  }, [multipleOperations]);

  // on modifie le graphe general en retirant les étapes 'surface' et 'energy'
  useEffect(() => {
    if (areOnlyIsolationOperationsSelected) {
      const newGraph = removeStep(graphGeneral, ['surface', 'energy']);
      // on update le graphe general seulement si le nouveau graphe est différent
      if (JSON.stringify(newGraph) !== JSON.stringify(graphGeneral)) {
        updateGraphGeneral(newGraph);
      }
    }
  }, [areOnlyIsolationOperationsSelected, graphGeneral]);

  const checkGraph = () => {
    switch (stepNumber) {
      default:
      case 2:
        setGraphActive(graphGeneral);
        break;
      case 3:
        setGraphActive(graphOperation);
        break;
    }
  };

  const renderComponent = () => {
    scrollSmoothToPosition(0);
    if (stepNumber === 1) {
      setComponentActive(<ChooseMultipleOperation />);
      updateTitle('Quels travaux allez-vous réaliser ?');
      updateSubtitle('Vous pouvez sélectionner une ou plusieurs opération(s).');
    } else if (graphActive.id === 'totalCost') {
      setComponentActive(<TotalCost />);
    } else if (graphActive.type === 'scopForm') {
      setComponentActive(<ScopUnits />);
    } else {
      switch (graphActive.type) {
        case 'worksForm':
          setComponentActive(<WorksAddress />);
          break;
        case 'card':
          if (graphActive.id === 'habitation') {
            setComponentActive(<CardSquareForm />);
          } else if (graphActive.id === 'bonus') {
            setComponentActive(<AuditForm />);
          } else {
            setComponentActive(<CardForm />);
          }
          break;
        case 'input-surface':
          setComponentActive(<InputSurface />);
          break;
        case 'select':
        case 'input-number':
          setComponentActive(<InputNumber />);
          break;
        case 'inputThermalResistance':
          setComponentActive(<InputThermalResistance />);
          break;
        case 'incomesForm':
          setComponentActive(<IncomesForm />);
          break;
        case 'noaid':
          setComponentActive(<NoAid />);
          break;
        case '':
          setComponentActive(<LoaderCardlink />);
          break;
        default:
          setComponentActive(<div />);
          break;
      }
    }
  };

  useEffect(() => {
    renderComponent();
    if (graphActive && stepNumber !== 1) {
      if (
        graphActive.name ===
        'Les revenus annuels du ménage de votre client sont-ils?'
      ) {
        updateTitle(graphActive.name.replace('?', ' :'));
      } else {
        updateTitle(graphActive.name);
      }

      if (graphActive.description === 'noaid') {
        updateSubtitle('');
      } else {
        let subtitle = graphActive.description;
        if (stepNumber === 3) {
          if (subtitle !== '') {
            subtitle = [subtitle, currentOperation.name].join(' - ');
          } else {
            subtitle = currentOperation.name;
          }
        }
        updateSubtitle(subtitle);
      }
    }
  }, [graphActive, stepNumber]);

  useEffect(() => {
    checkGraph();
  }, [graphGeneral, graphOperation, generalComplete]);

  return componentActive;
}

export default RenderStep;
