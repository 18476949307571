import { useEffect, useState, useContext } from 'react';
import { IFilesList, IDocsByOp } from '@dashboard/utils/dashboardTypes';
import { v4 } from 'uuid';
import LoaderFolderMonitoring from '@components/loaders/LoaderFolderMonitoring';
import { InstallerContext } from '@auth/utils/authContext';
import { getAllDocuments } from '@folderMonitoring/apiRequest/folderMonitoringRequest';
import { DocumentViewer } from '@folderMonitoring/components/documentsFolder/DocumentViewer';
import { DocumentsList } from '@folderMonitoring/components/documentsFolder/DocumentsList';
import { FolderMonitoringContext } from '../../utils/context/folderMonitoringContext';

function DocumentsFolder(): JSX.Element {
  const { updateAllDocuments, allDocuments } = useContext(
    FolderMonitoringContext
  );
  const { folderActif } = useContext(FolderMonitoringContext);

  const { isThemeDark } = useContext(InstallerContext);

  const globalDocuments: IFilesList[] = [
    {
      name: 'Attestation indivisaire',
      link: allDocuments.common.attestation_indivisaire,
      isImage: false,
    },
    {
      name: 'Attestation propriétaire bailleur',
      link: allDocuments.common.attestation_propriétaire_bailleur_mpr,
      isImage: false,
    },
    {
      name: "Avis d'imposition",
      link: allDocuments.common.avis_imposition,
      isImage: false,
    },
    {
      name: 'Facture travaux',
      link: allDocuments.common.facture_travaux,
      isImage: false,
    },
    {
      name: 'Justificatif propriété',
      link: allDocuments.common.justificatif_propriété,
      isImage: false,
    },
    {
      name: 'Mandat mixte généré',
      link: allDocuments.common.mandat_mixte_mpr_généré,
      isImage: false,
    },
    {
      name: 'Mandate mixte signé',
      link: allDocuments.common.mandat_mixte_mpr_signé,
      isImage: false,
    },
    {
      name: "Notification d'octroi",
      link: allDocuments.common.no_mpr,
      isImage: false,
    },
    {
      name: 'Attestation consentement MPR',
      link: allDocuments.common.attestation_consentement_mpr,
      isImage: false,
    },
  ];

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [typeIsImage, setIsTypeImage] = useState<boolean>(true);

  const [docsByOp, setDocsByOp] = useState<IDocsByOp[]>([]);

  const [nothingToShow, setNothingToShow] = useState<boolean>(false);

  const [isGlobalFilesToShow, setIsGlobalFilesToShow] =
    useState<boolean>(false);
  const [isAnyDocsByOp, setIsAnyDocsByOp] = useState<string[]>([]);

  const [fileToShow, setFileToShow] = useState<string>('');

  const [pdfIsLoaded, setPdfIsLoaded] = useState<boolean>(false);

  const isSomethingToShow = (tab: any) => {
    for (let i = 0; i < tab.length; i += 1) {
      if (tab[i].link.length !== 0) {
        return true;
      }
    }
    return false;
  };

  const isAnyDocsByOpToShow = () => {
    const tab: string[] = [];
    for (let i = 0; i < docsByOp.length; i += 1) {
      const result = isSomethingToShow(docsByOp[i].filesList);
      if (result) {
        tab.push(docsByOp[i].operationName);
      }
    }
    setIsAnyDocsByOp(tab);
  };
  const setDocuments = () => {
    const docOp: IDocsByOp[] = [];
    for (let i = 0; i < allDocuments.specific.length; i += 1) {
      docOp.push({
        operationName: allDocuments.specific[i].libellé_opération,
        filesList: [
          {
            name: "Attestation sur l'honneur",
            link: allDocuments.specific[i].ah_générée,
            isImage: false,
          },
          {
            name: 'Cadre de contribution',
            link: allDocuments.specific[i].cc,
            isImage: false,
          },
          {
            name: 'Devis signé',
            link: allDocuments.specific[i].devis_signé,
            isImage: false,
          },
          {
            name: 'Devis travaux',
            link: allDocuments.specific[i].devis_travaux,
            isImage: false,
          },
          {
            name: 'Rapport audit énergétique',
            link: allDocuments.specific[i].rapport_audit_énergétique,
            isImage: false,
          },
          {
            name: 'Note de Dimensionnement',
            link: allDocuments.specific[i].note_dimensionnement,
            isImage: false,
          },
          {
            name: 'Rapport cofrac',
            link: allDocuments.specific[i].rapport_cofrac,
            isImage: false,
          },
          {
            name: 'Photo avant travaux',
            link: allDocuments.specific[i].photos_avant_chantier,
            isImage: true,
          },
          {
            name: 'Photo après travaux',
            link: allDocuments.specific[i].photos_après_chantier,
            isImage: true,
          },
        ],
      });
    }
    setDocsByOp(docOp);
  };
  useEffect(() => {
    setIsLoading(true);
    getAllDocuments(
      folderActif[0].référence_dossier,
      updateAllDocuments,
      setIsLoading
    );
    setDocuments();
    isAnyDocsByOpToShow();
  }, [folderActif]);

  useEffect(() => {
    setDocuments();
    if (globalDocuments) {
      for (let i = 0; i < globalDocuments.length; i += 1) {
        if (globalDocuments[i].link.length !== 0) {
          setFileToShow(globalDocuments[i].link[0]);
          setIsTypeImage(false);
          break;
        }
      }
      setIsGlobalFilesToShow(isSomethingToShow(globalDocuments));
    }
  }, [allDocuments]);

  useEffect(() => {
    isAnyDocsByOpToShow();
  }, [docsByOp]);

  useEffect(() => {
    if (!isGlobalFilesToShow && isAnyDocsByOp.length === 0) {
      setNothingToShow(true);
    } else {
      setNothingToShow(false);
    }
  }, [isGlobalFilesToShow, isAnyDocsByOp]);

  const scrollbarColor = isThemeDark ? '' : 'scroll_light_mode';

  return (
    <>
      {isLoading ? (
        <div className="px-[1.375rem] h-full">
          <LoaderFolderMonitoring />
        </div>
      ) : (
        <div
          className={`${scrollbarColor} w-full h-full px-[1.375rem] flex items-start space-x-[2rem] overflow-y-scroll text-dark-1 dark:text-white`}
        >
          <div className="w-2/5 flex flex-col space-y-[1.5rem]">
            <DocumentsList
              title="Documents Globaux"
              filesList={globalDocuments}
              fileToShow={fileToShow}
              setPdfIsLoaded={setPdfIsLoaded}
              setIsTypeImage={setIsTypeImage}
              setFileToShow={setFileToShow}
            />
            {docsByOp.map((op: IDocsByOp) => (
              <DocumentsList
                key={v4()}
                title={op.operationName.replace(/["{}]/g, '')}
                filesList={op.filesList}
                fileToShow={fileToShow}
                setPdfIsLoaded={setPdfIsLoaded}
                setIsTypeImage={setIsTypeImage}
                setFileToShow={setFileToShow}
              />
            ))}
          </div>
          <DocumentViewer
            typeIsImage={typeIsImage}
            nothingToShow={nothingToShow}
            fileToShow={fileToShow}
            pdfIsLoaded={pdfIsLoaded}
            setPdfIsLoaded={setPdfIsLoaded}
          />
        </div>
      )}
    </>
  );
}

export default DocumentsFolder;
