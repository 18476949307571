import { useContext, useEffect, useState } from 'react';
import Parser from 'html-react-parser';
import { v4 } from 'uuid';
import {
  IDDCConvert,
  IDDCProps,
} from '@folderMonitoring/utils/folderMonitoringTypes';
import { InstallerContext } from '@auth/utils/authContext';
import { ChevronIcon } from '@assets/svgComponents/svgComponents';

interface IErrorGeneralProps {
  convertDDC: IDDCConvert[];
  isMpr: boolean;
  commentaries: string;
}

function ErrorGeneral({
  convertDDC,
  isMpr,
  commentaries,
}: IErrorGeneralProps): JSX.Element {
  const [ddc, setDdc] = useState<IDDCProps[]>([]);

  const [solutionActive, setSolutionActive] = useState<boolean>(false);
  const [problemActive, setProblemActive] = useState<number>(-1);

  const { isThemeDark } = useContext(InstallerContext);

  const getAllDdc = (tab: IDDCProps[], result: IDDCProps[]) => {
    for (let i = 0; i < tab.length; i += 1) {
      result.push({
        ddc: tab[i].ddc,
        résolution: tab[i].résolution,
      });
    }
  };

  useEffect(() => {
    const mpr: IDDCProps[] = [];
    const cee: IDDCProps[] = [];

    for (let i = 0; i < convertDDC.length; i += 1) {
      if (convertDDC[i].name === 'ah' && convertDDC[i].tab.length !== 0) {
        getAllDdc(convertDDC[i].tab, cee);
      } else if (
        convertDDC[i].name === 'mandat' &&
        convertDDC[i].tab.length !== 0
      ) {
        getAllDdc(convertDDC[i].tab, mpr);
      } else if (convertDDC[i].tab.length !== 0) {
        getAllDdc(convertDDC[i].tab, cee);
        getAllDdc(convertDDC[i].tab, mpr);
      }
    }

    setDdc(isMpr ? mpr : cee);
  }, []);

  return (
    <div className="pt-[.75rem] text-dark-1 dark:text-white">
      <div className="text[.75rem] font-[500] px-[1rem]">
        Problèmes constatés
      </div>
      {ddc.map((err: IDDCProps, index: number) => {
        const errDisplay = err.ddc
          .replace(
            /([\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2694-\u2697]|\uD83E[\uDD10-\uDD5D])/g,
            ''
          )
          .replace(/' {2}'/g, ' ');

        const solDisplay = err.résolution
          .replace(
            /([\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2694-\u2697]|\uD83E[\uDD10-\uDD5D])/g,
            ''
          )
          .replace(/' {2}'/g, ' ')
          .replace(/\\/g, '');

        return (
          <div key={v4()}>
            <div
              role="button"
              tabIndex={0}
              onKeyDown={undefined}
              onClick={() => {
                if (index === problemActive) {
                  setProblemActive(-1);
                  setSolutionActive(false);
                } else {
                  setSolutionActive(true);
                  setProblemActive(index);
                }
              }}
              className={`flex items-center justify-between ${
                !solutionActive && ddc.length > 1 && 'border-b border-b-dark-5'
              } py-[.75rem] px-[1rem] text-[.75rem] leading-[1.5rem]`}
            >
              <div>
                {`${index + 1}. ${Parser(errDisplay.replace(/\n/g, '<br />'))}`}
              </div>
              <div className="pl-[.625rem]">
                <ChevronIcon
                  width="1.75rem"
                  height="1.75rem"
                  fill={isThemeDark ? 'white' : '#191919'}
                  transform={
                    solutionActive && index === problemActive
                      ? 'rotate(-90)'
                      : 'rotate(90)'
                  }
                  className="transition-transform duration-300"
                />
              </div>
            </div>
            <div>
              {solutionActive && index === problemActive && (
                <div
                  className={`pb-[.75rem] ${
                    ddc.length > 1 && 'border-b border-b-dark-5 '
                  } px-[1rem] leading-[1.5rem]`}
                >
                  <div>Résolution :</div>
                  <div>{Parser(solDisplay.replace(/\n/g, '<br />'))}</div>
                </div>
              )}
            </div>
          </div>
        );
      })}
      {commentaries && (
        <div className="pt-[.75rem] px-[1rem] text[.75rem]">
          <div className="font-[500] pb-[.75rem]">Commentaires référents</div>
          <div className="leading-[1.5rem]">
            {Parser(commentaries.replace(/\n/g, '<br />'))}
          </div>
        </div>
      )}
    </div>
  );
}

export default ErrorGeneral;
