import { useContext } from 'react';
import { InstallerContext } from '@auth/utils/authContext';
import { darkVariant1, backgroundBodyLightMode } from '@assets/colors';

function LoaderFolderMonitoring(): JSX.Element {
  const { radiusPx, isThemeDark } = useContext(InstallerContext);
  const backgroundColor = isThemeDark ? darkVariant1 : backgroundBodyLightMode;

  const radius = radiusPx === '0px' ? '0px' : '16px';
  return (
    <div
      style={{ borderRadius: radius, backgroundColor }}
      className="load w-full h-full load-folder"
    >
      <div className="load-one" />
      <div className="load-two" />
      <div className="load-three" />
    </div>
  );
}

export default LoaderFolderMonitoring;
