import { useContext, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import HeaderBulkImport from '@bulkImport/components/HeaderBulkImport';
import LeftPartBulkImport from '@bulkImport/components/leftPart/LeftPartBulkImport';
import RightPartBulkImport from '@bulkImport/components/rightPart/RightPartBulkImport';
import { BulkImportContext } from '@bulkImport/utils/bulkImportContext';
import { getListFilesImported } from '@bulkImport/apiRequests/bulkImportRequest';
import {
  ICoordinates,
  IOperation,
  Idocuments,
  IFileCheckBeforeSend,
  IFileImported,
  IListFilesImported,
  IFolder,
  IFileSentToAirtable,
} from '@bulkImport/utils/bulkImportTypes';
import { InstallerContext } from '@auth/utils/authContext';
import { PERMISSIONS } from '@utils/utils';
import LayoutDashboard from './LayoutDashboard';

function BulkImport(): JSX.Element {
  const { installer, pusher } = useContext(InstallerContext);
  // Redirection quand l'utilisateur n'a pas la permission de réaliser des imports en masse
  if (!localStorage.getItem('permission')?.includes(PERMISSIONS.BULK_IMPORT)) {
    return <Navigate to="/dashboard" replace />;
  }

  const [fileNameUploaded, setFileNameUploaded] = useState<string>('');
  const [fileUploaded, setFileUploaded] = useState<IFileImported>();

  const [isUploaded, setIsUploaded] = useState<boolean>(false);
  const [fileSentToAirtable, setFileSentToAirtable] =
    useState<IFileSentToAirtable>();
  const [wasSentToAirtable, setWasSentToAirtable] = useState<boolean>(false);
  const [fileIsLoading, setFileIsLoading] = useState<boolean>(false);

  const [listFilesImportedIsClicked, setListFilesImportedIsClicked] =
    useState<boolean>(false);

  const [listFilesImported, setListFilesImported] = useState<
    IListFilesImported[]
  >([]);

  const [fileDetail, setFileDetail] = useState<IFileCheckBeforeSend>();

  const [documentsGlobals, setdocumentsGlobals] = useState<Idocuments[]>();
  const [operations, setOperations] = useState<IOperation[]>();

  const [isErrorXLS, setIsErrorXLS] = useState<boolean>(false);

  const [foldersIsCompleted, setFoldersIsCompleted] = useState<boolean>(false);
  const [fileIsPushingToAirtable, setFileIsPushingToAirtable] =
    useState<boolean>(false);

  const [folderActiveReference, setFolderActiveReference] =
    useState<string>('');
  const [folderActive, setFolderActive] = useState<IFolder | undefined>(
    undefined
  );
  const [folderList, setFolderList] = useState<IFolder[]>([]);

  const [coordinatesArrayForMapLoading, setCoordinatesArrayForMapLoading] =
    useState<ICoordinates[]>([{ lat: 0, lng: 0 }]);
  const [centerMap, setCenterMap] = useState<ICoordinates>({
    lat: 46.227638,
    lng: 2.213749,
  });
  const [zoomMap, setZoomMap] = useState<number>(5);

  const [errorWindow, setErrorWindow] = useState<boolean>(false);
  const [errorWindowHeader, setErrorWindowHeader] = useState<string>('');
  const [errorWindowContent, setErrorWindowContent] = useState<
    JSX.Element | string
  >(<></>);

  const [webSocketConnected, setWebSocketConnected] = useState<boolean>(false);
  const [importLoading, setImportLoading] = useState<number>();
  const [uploadLoading, setUploadLoading] = useState<number>(0);
  const [importSuccess, setImportSuccess] = useState<boolean>(false);

  const bulkImportContextValue = {
    listFilesImported,
    updateListFilesImported: setListFilesImported,
    listFilesImportedIsClicked,
    updateListFilesImportedIsClicked: setListFilesImportedIsClicked,
    fileNameUploaded,
    updateFileNameUploaded: setFileNameUploaded,
    fileUploaded,
    updateFileUploaded: setFileUploaded,
    fileIsLoading,
    updateFileIsLoading: setFileIsLoading,
    isErrorXLS,
    updateIsErrorXLS: setIsErrorXLS,
    isUploaded,
    updateIsUploaded: setIsUploaded,
    fileDetail,
    updateFileDetail: setFileDetail,
    folderList,
    updateFolderList: setFolderList,
    operations,
    updateOperations: setOperations,
    documentsGlobals,
    updateDocumentsGlobals: setdocumentsGlobals,
    folderActiveReference,
    updateFolderActiveReference: setFolderActiveReference,
    foldersIsCompleted,
    updateFolderIsCompleted: setFoldersIsCompleted,
    folderActive,
    updateFolderActive: setFolderActive,
    fileIsPushingToAirtable,
    updateFileIsPushingToAirtable: setFileIsPushingToAirtable,
    coordinatesArrayForMapLoading,
    updateCoordinatesArrayForMapLoading: setCoordinatesArrayForMapLoading,
    centerMap,
    updateCenterMap: setCenterMap,
    zoomMap,
    updateZoomMap: setZoomMap,
    errorWindow,
    updateErrorWindow: setErrorWindow,
    errorWindowHeader,
    updateErrorWindowHeader: setErrorWindowHeader,
    errorWindowContent,
    updateErrorWindowContent: setErrorWindowContent,
    importLoading,
    updateImportLoading: setImportLoading,
    uploadLoading,
    updateUploadLoading: setUploadLoading,
    importSuccess,
    updateImportSuccess: setImportSuccess,
    updateWebSocketConnected: setWebSocketConnected,
    wasSentToAirtable,
    updateWasSentToAirtable: setWasSentToAirtable,
    fileSentToAirtable,
    updateFileSentToAirtable: setFileSentToAirtable,
  };

  const webSocketHandler = () => {
    const changeZoomAndCoordinatesMap = (data: any) => {
      setCoordinatesArrayForMapLoading((old) => {
        const newArray = [...old];
        newArray.push(data.coordinates);
        return newArray;
      });
    };

    const channel = pusher?.subscribe(`bulkimport.${installer.id}`);
    // eslint-disable-next-line no-unused-expressions
    channel?.bind('pusher:checking', (data: any) => {
      if (data.import === fileUploaded?.id) {
        if (Object.keys(data).includes('coordinates')) {
          changeZoomAndCoordinatesMap(data);
        } else if (Object.keys(data).includes('folder_created')) {
          setImportLoading(data.folder_created);
        } else if (Object.keys(data).includes('step')) {
          setImportLoading(undefined);
          setImportSuccess(true);
          toast.success('Le fichier a été importé avec succès');
        } else if (Object.keys(data).includes('error')) {
          setErrorWindowHeader("Erreur lors de l'import");
          if (data.error.includes('Quota')) {
            setErrorWindowContent(
              'Vous avez atteint le quota autorisé pour valoriser des CEE. Merci de contacter votre Représentant commercial.'
            );
          } else {
            setErrorWindowContent(
              "Le fichier n'a pas pu être importé, veuillez vérifier les informations entrées dans le tableau puis réessayer."
            );
          }
          setErrorWindow(true);
          setFileNameUploaded('');
          setFileUploaded(undefined);
        } else {
          if (Object.keys(data).includes('xls_row')) {
            setUploadLoading(data.xls_row + 1);
          }
          setFileUploaded((old) => {
            let newFileUploaded = old;
            if (newFileUploaded) {
              if (Object.keys(data).includes('scan')) {
                setTimeout(() => {
                  if (newFileUploaded) {
                    if (newFileUploaded.id) {
                      setFileIsLoading(false);
                    }
                    if (data.scan.check) {
                      getListFilesImported(setListFilesImported);
                    }
                    setIsUploaded(true);
                    setIsErrorXLS(data.scan.nb_errors !== 0);
                    newFileUploaded.check = data.scan.check;
                    newFileUploaded.nb_errors = data.scan.nb_errors;
                    newFileUploaded.nb_folders = data.scan.nb_folders;
                    newFileUploaded.nb_operations = data.scan.nb_operations;
                    setUploadLoading(0);
                  }
                }, 1500);
              } else if (Object.keys(data).includes('xls_row')) {
                if (newFileUploaded.rows) {
                  newFileUploaded.rows[data.xls_row].xls_row = data.xls_row;
                  newFileUploaded.rows[data.xls_row].valid = data.row_validity;
                }
                if (newFileUploaded.errors) {
                  newFileUploaded.errors.push(data.errors);
                } else {
                  newFileUploaded.errors = [data.errors];
                }
              } else if (Object.keys(data).includes('rows')) {
                if (!newFileUploaded.nb_folders) {
                  newFileUploaded.nb_folders = data.rows.length;
                }
                newFileUploaded.rows = [];
                for (let i = 0; i < data.rows.length; i += 1) {
                  newFileUploaded.rows.push({ row: data.rows[i] });
                }
              } else {
                for (let i = 0; i < Object.keys(data).length; i += 1) {
                  newFileUploaded[Object.keys(data)[i]] =
                    data[Object.keys(data)[i]];
                }
              }
            } else {
              newFileUploaded = data;
            }
            return newFileUploaded;
          });
        }
      }
    });
    setWebSocketConnected(true);
  };
  useEffect(() => {
    if (!webSocketConnected && installer.id !== '' && fileUploaded?.id) {
      webSocketHandler();
    }
  }, [installer.id, webSocketConnected, fileUploaded]);

  useEffect(() => {
    if (fileDetail) {
      setFolderList(fileDetail.folders);
    } else {
      setFolderList([]);
    }
  }, [fileDetail]);

  useEffect(() => {
    if (
      !webSocketConnected &&
      pusher?.channel(`bulkimport.${installer.id}`) === undefined
    ) {
      // eslint-disable-next-line no-unused-expressions
      pusher?.unsubscribe(`bulkimport.${installer.id}`);
    }
  }, [webSocketConnected]);
  return (
    <BulkImportContext.Provider value={bulkImportContextValue}>
      <LayoutDashboard withoutRequestForSupplements>
        <div className="h-[82vh] w-full ">
          <HeaderBulkImport />
          <div className="w-full lg:flex dark:bg-dark-1 bg-backgroundSecondaryLightMode px-[2rem] h-full rounded-b-[16px] ">
            <LeftPartBulkImport />
            <RightPartBulkImport />
          </div>
        </div>
      </LayoutDashboard>
    </BulkImportContext.Provider>
  );
}

export default BulkImport;
