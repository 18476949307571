import { useContext, useEffect, useState } from 'react';
import Loader from '@components/loaders/Loader';
import { BulkImportContext } from '@bulkImport/utils/bulkImportContext';
import { borderLightMode } from '@assets/colors';
import HeaderRightPart from '../HeaderRightPart';
import ListDocumentsUpload from './ListDocumentsUpload';
import ListFolders from './ListFolders';
import { InstallerContext } from '../../../../auth/utils/authContext';

function UploadDocumentsFolderRightPart(): JSX.Element {
  const { fileDetail, updateFolderIsCompleted, listFilesImportedIsClicked } =
    useContext(BulkImportContext);
  const { isThemeDark, radiusPx } = useContext(InstallerContext);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // VERIFIE SI LES DOSSIERS D'UN FICHIER SONT TOUS COMPLETS AVANT DE DISABLED LE BUTTON VALIDER ET D'E?VOYER A AIRTABLE
    if (fileDetail?.status !== 'Done') {
      const numberCompleted = fileDetail?.folders?.filter(
        (folder) => folder.completed === false
      ).length;
      if (numberCompleted === 0) {
        updateFolderIsCompleted(true);
      } else {
        updateFolderIsCompleted(false);
      }
    }
  }, [fileDetail]);

  return (
    <>
      {fileDetail !== undefined && (
        <div
          style={{
            borderRadius: radiusPx,
            border: isThemeDark ? '' : `1px solid ${borderLightMode}`,
          }}
          className="w-full mx-[1rem] dark:bg-dark-3 bg-white h-full pb-[.7rem]"
        >
          <HeaderRightPart isLoading={isLoading} setLoading={setIsLoading} />
          {isLoading && <Loader />}
          <div className="lg:flex justify-between w-full h-[calc(100%-6.25rem)]">
            {fileDetail?.status !== 'Done' && (
              <>
                <ListFolders />
                <div className="lg:w-8/12">
                  {!listFilesImportedIsClicked && (
                    <ListDocumentsUpload setIsLoading={setIsLoading} />
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default UploadDocumentsFolderRightPart;
