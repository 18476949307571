/* eslint-disable no-param-reassign */
import { useState, useEffect, useMemo, Dispatch, SetStateAction } from 'react';
import { IOperationFolderType } from '@folder/utils/folderTypes';
import packageJson from '../../package.json';

export const roundedValue = (value: number) => {
  return (Math.round(value * 100) / 100)
    .toFixed(2)
    .toLocaleString()
    .replace('.', ',');
};

export const roundFormat = (value: number | string) => {
  const valueFormat = typeof value === 'string' ? parseFloat(value) : value;
  return (Math.round(valueFormat * 100) / 100).toFixed(2).toLocaleString();
};

// Séparation des milliers par un espace
export const numFormatSpace = (
  a: string | number,
  withoutDecimal?: boolean
) => {
  let str = roundFormat(a);
  if (withoutDecimal) {
    str = Math.round(parseFloat(str)).toString();
  }
  const decimal = str !== null ? str.split('.', str.length - 1) : [''];
  if (decimal[1] === '00') {
    return decimal[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ').replace('.', ',');
  }
  return str.replace(/\B(?=(\d{3})+(?!\d))/g, ' ').replace('.', ',');
};

export function useIsInViewport(
  ref: any,
  div: string,
  setDivActive: Dispatch<SetStateAction<string | undefined>>,
  mpr?: boolean
) {
  const isMpr = mpr;
  const [isIntersecting, setIsIntersecting] = useState(false);

  const observer = useMemo(
    () =>
      new IntersectionObserver(
        ([entries]) => {
          if (entries.isIntersecting) {
            setDivActive(div);
          }
          if (!entries.isIntersecting && isMpr) {
            setDivActive('Partenariat');
          }
          setIsIntersecting(entries.isIntersecting);
        },
        { threshold: [1] }
      ),
    []
  );

  useEffect(() => {
    observer.observe(ref.current);
    return () => {
      observer.disconnect();
    };
  }, [ref, observer]);

  return isIntersecting;
}

export const transformLongString = (longString: string) => {
  let newString = longString;
  if (longString.length > 23) {
    newString = `${newString.slice(0, 12)}...${newString.slice(-11)}`;
  }
  return newString;
};

export const pagesWithSidebarLeft = [
  '/',
  '/import-dossiers',
  '/admin',
  '/boite-a-outils',
  '/mon-profil',
];

export const getFeesMpr = (operations: IOperationFolderType[]) => {
  let totalFees = 0;
  for (let i = 0; i < operations.length; i += 1) {
    const operationFees: number | undefined = operations[i].mpr_folder_fees;
    if (operationFees !== undefined) {
      totalFees += operationFees;
    }
  }
  if (totalFees === 0) {
    return undefined;
  }
  return numFormatSpace(totalFees);
};

export const getTotalMpr = (operations: IOperationFolderType[]) => {
  let totalMpr = 0;
  for (let i = 0; i < operations.length; i += 1) {
    const operationMpr: number | undefined = operations[i].mpr_amount;
    if (operationMpr !== undefined) {
      totalMpr += operationMpr;
    }
  }

  if (totalMpr === 0) {
    return undefined;
  }
  return numFormatSpace(totalMpr);
};

export const getTotalCee = (operations: IOperationFolderType[]) => {
  let totalCee = 0;
  for (let i = 0; i < operations.length; i += 1) {
    const operationCee: number | undefined =
      operations[i].prime_cee_beneficiary;
    if (operationCee !== undefined) {
      totalCee += operationCee;
    }
  }
  if (totalCee === 0) {
    return undefined;
  }
  return numFormatSpace(totalCee);
};

export const scrollSmoothToPosition = (topPositionOfElement: number) => {
  window.scrollTo({
    top: topPositionOfElement,
    behavior: 'smooth',
    left: 0,
  });
};

export const firstToUpperCase = (a: string) => {
  return `${a}`.charAt(0).toUpperCase() + a.substr(1);
};

export const PERMISSIONS = {
  ALL: 'all',
  BULK_IMPORT: 'bulk-import',
};

export const convertHexToRGBA = (hexCode: string, opacity: any) => {
  let hex = hexCode ? hexCode.replace('#', '') : '#5D6AD2'.replace('#', '');

  if (hex.length === 3) {
    hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
  }

  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  if (opacity > 1 && opacity <= 100) {
    opacity /= 100;
  }

  return `rgba(${r},${g},${b},${opacity})`;
};

export const getContrastYIQ = (hexcolor: string) => {
  const r = parseInt(hexcolor.substr(0, 2), 16);
  const g = parseInt(hexcolor.substr(2, 2), 16);
  const b = parseInt(hexcolor.substr(4, 2), 16);
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 130 ? '#202020' : 'white';
};

export const eligibilityDemoFromLocalStorage = () => {
  const eligibilityDemoCee = localStorage.getItem('eligibilityDemoCee');
  const eligibilityDemoMpr = localStorage.getItem('eligibilityDemoMpr');
  if (eligibilityDemoCee && eligibilityDemoMpr) {
    const eligibilityDemo = [];
    if (eligibilityDemoCee === 'true') {
      eligibilityDemo.push('CEE');
    }
    if (eligibilityDemoMpr === 'true') {
      eligibilityDemo.push('MPR');
    }
    return eligibilityDemo;
  }
  return [''];
};

export const rollbarConfig = {
  accessToken: process.env.REACT_APP_ROLLBAR_TOKEN || '',
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    client: {
      javascript: {
        code_version: '1.0.0',
        source_map_enabled: true,
      },
    },
  },
  environment: process.env.REACT_APP_ROLLBAR_ENV || 'not_set',
};

const body = document.getElementsByTagName('body')[0];
const html = document.getElementsByTagName('html')[0];

export const changeThemeToLight = (path: string, search: string) => {
  localStorage.setItem('theme', 'light');
  if (html.classList.contains('dark') || path.includes('wl')) {
    html.classList.remove('dark');
    if (search.includes('?integration=iframe')) {
      body.classList.add('theme-iframe-background-color');
    } else {
      body.classList.add('theme-light-background-color');
    }
    body.classList.remove('theme-dark-background-color');
  }
};

export const changeThemeToDark = () => {
  localStorage.setItem('theme', 'dark');
  if (!html.classList.contains('dark')) {
    html.classList.add('dark');
    body.classList.add('theme-dark-background-color');
    body.classList.remove('theme-light-background-color');
  }
};

export const changeMobileScreen = (displayMobileScreen: boolean) => {
  if (displayMobileScreen) {
    html.classList.add('not-scroll');
  } else {
    html.classList.remove('not-scroll');
  }
};

// export const fontSizeHtml = (path: string) => {
// fixe la taille de référence des parties simulation et infos dossier à 16px
// if (
// path.includes('wl') ||
// path.includes('demander-ma-prime') ||
// path.includes('informations')
// ) {
//   if (!html.classList.contains('font-size-16')) {
//     html.classList.add('font-size-16');
//   }
// } else
//   if (html.classList.contains('font-size-16')) {
//     html.classList.remove('font-size-16');
//   }
// };

export const checkCaching = () => {
  const version = localStorage.getItem('version');
  if (version !== packageJson.version) {
    if ('caches' in window) {
      caches.keys().then((names) => {
        // Delete all the cache files
        names.forEach((name) => {
          caches.delete(name);
        });
      });
      // Makes sure the page reloads. Changes are only visible after you refresh.
      window.location.reload();
    }
    localStorage.removeItem('version');
    localStorage.setItem('version', packageJson.version);
  }
};

export const latLngFrance = {
  lat: 47.8588897,
  lng: 2.320041,
};

export const convertToYYYYMMDD = (dateString: string): string => {
  const [day, month, year] = dateString.split('/');
  return `${year}-${month}-${day}`;
};
