/* eslint-disable no-nested-ternary */
import { InstallerContext } from '@auth/utils/authContext';
import { useContext, useState } from 'react';
import { borderDarkMode, borderLightMode, white } from '@assets/colors';
import { convertHexToRGBA } from '@utils/utils';

interface HomeLeftMenuCardProps {
  name: string;
  title?: string;
  email?: string;
  tel?: string;
  photo?: string;
}

function HomeLeftMenuCard({
  title,
  name,
  email,
  tel,
  photo,
}: HomeLeftMenuCardProps): JSX.Element {
  const [mouseActive, setMouseActive] = useState<boolean>(false);
  const { radiusPx, isThemeDark, colorPrimary } = useContext(InstallerContext);
  const radius = radiusPx === '0px' ? '0px' : '8px';

  if (!name) {
    return (
      <div
        style={{
          borderRadius: radius,
        }}
        className="dark:bg-dark-5 bg-white h-[4rem] mb-[.5rem] mx-[1rem] rounded-md animate__infinite animate__flash animate__animated animate__slower"
      />
    );
  }
  return (
    <div
      style={{
        borderRadius: radius,
        backgroundColor:
          !isThemeDark && title !== 'Session Active'
            ? convertHexToRGBA(colorPrimary, 0.1)
            : !isThemeDark && title === 'Session Active'
            ? white
            : '',
        border:
          !isThemeDark && title === 'Session Active'
            ? ` 1px solid ${borderLightMode}`
            : isThemeDark && title === 'Session Active'
            ? ` 1px solid ${borderDarkMode}`
            : '',
      }}
      onMouseEnter={() => {
        if (email !== '') {
          setMouseActive(true);
        }
      }}
      onMouseLeave={() => {
        if (email !== '') {
          setMouseActive(false);
        }
      }}
      className={`${
        mouseActive ? 'dark:bg-dark-3' : 'dark:bg-dark-1'
      } flex items-center justify-between py-[.5rem] pl-[.5rem] pr-[.75rem] mb-[.5rem] mx-[1rem] dark:text-white text-colorTextLightMode h-[4rem]`}
    >
      <div className="flex items-center space-x-[.75rem] w-full truncate">
        <div style={{ borderRadius: radius }}>
          {photo !== '' && (
            <img
              style={{ borderRadius: radius, width: '3rem', height: '3rem' }}
              src={photo}
              alt={title}
            />
          )}
        </div>
        {!mouseActive ? (
          <div>
            <p className="sb-text-rem-caption1">{title}</p>
            <p className="sb-text-rem-body2">{name}</p>
          </div>
        ) : (
          <div>
            <div>
              <a
                className="sb-text-rem-caption1 whitespace-nowrap"
                href={`mailto:${email}`}
              >
                {email}
              </a>
            </div>
            <div>
              {tel !== null && (
                <a
                  className="sb-text-rem-body2 whitespace-nowrap pt-[.25rem]"
                  href={`tel:${tel}`}
                >
                  {tel}
                </a>
              )}
            </div>
          </div>
        )}
      </div>
      {title !== 'Session Active' && (
        <div>
          <svg
            width="1.5rem"
            height="1.5rem"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={`${mouseActive ? 'mouseActive' : 'mouseNotActive'}`}
          >
            <path
              d="M9.99997 6L8.58997 7.41L13.17 12L8.58997 16.59L9.99997 18L16 12L9.99997 6Z"
              fill={isThemeDark ? white : '#5D5D5F'}
            />
          </svg>
        </div>
      )}
    </div>
  );
}

export default HomeLeftMenuCard;

HomeLeftMenuCard.defaultProps = {
  title: '',
  email: '',
  tel: '',
  photo: '',
};
