import { InstallerContext } from '@auth/utils/authContext';
import { IListFolders } from '@dashboard/utils/dashboardTypes';
import React, { Dispatch, SetStateAction, useContext } from 'react';
import { v4 } from 'uuid';
import { numFormatSpace } from '@utils/utils';

import { DASHBOARD_TABS } from '@dashboard/utils/tabs';
import { DashboardContext } from '../../utils/context/dashboardContext';
import DeleteOperationsInList from './DeleteOperationsInList';

interface IProps {
  setFoldersDatas: Dispatch<SetStateAction<IListFolders[]>>;
  folderDatas: IListFolders[];
}

function TotalAmountsAndDelete({
  setFoldersDatas,
  folderDatas,
}: IProps): JSX.Element {
  const [isAddOperation, setIsAddOperation] = React.useState(false);
  const { installer, radiusPx, colorTextButton } = useContext(InstallerContext);
  const {
    countsFolders,
    isDeleteFolderLoading,
    updateIsModalDeleteFolder,
    isModalDeleteFolder,
    elementActive,
    rowsSelectedFolders,
    updateRowsSelectedFolders,
  } = useContext(DashboardContext);

  const radius = radiusPx === '0px' ? '0px' : '8px';

  const totalMpr = () => {
    if (rowsSelectedFolders.length === 0) {
      return Number(countsFolders.mpr);
    }
    return rowsSelectedFolders.reduce((acc, curr) => {
      return (
        acc + Number(curr.mpr_management === true ? curr?.mpr_amount : '0') ||
        acc + Number(curr.gestion_mpr_drapo === 'Oui' ? curr?.montant_mpr : '0')
      );
    }, 0);
  };
  const totalCee = () => {
    if (rowsSelectedFolders.length === 0) {
      return Number(countsFolders.cee);
    }
    if (elementActive === DASHBOARD_TABS.FOLDERS_INSTRUCTION) {
      return rowsSelectedFolders.reduce((acc, curr) => {
        return acc + Number(curr?.prime_cee_bénéficiaire);
      }, 0);
    }
    return rowsSelectedFolders.reduce((acc, curr) => {
      return acc + Number(curr?.prime_cee_beneficiary);
    }, 0);
  };

  const totaux = [
    {
      label: 'Total MPR',
      value: totalMpr(),
    },
    {
      label: 'Total CEE',
      value: totalCee(),
    },
    {
      label: 'Total global',
      value: totalMpr() + totalCee(),
    },
  ];

  const addOperationBonus = () => {
    const otherFolders: any = [];
    for (let i = 0; i < folderDatas.length; i += 1) {
      const operation = folderDatas[i];
      const refFolder =
        elementActive === DASHBOARD_TABS.FOLDERS_INSTRUCTION
          ? operation.référence_dossier
          : operation.folder_reference;
      if (rowsSelectedFolders.indexOf(operation) === -1) {
        for (let j = 0; j < rowsSelectedFolders.length; j += 1) {
          const rowSelected = rowsSelectedFolders[j];
          const refFolderRowSelected =
            elementActive === DASHBOARD_TABS.FOLDERS_INSTRUCTION
              ? rowSelected.référence_dossier
              : rowSelected.folder_reference;
          if (
            refFolder === refFolderRowSelected &&
            otherFolders.indexOf(operation) === -1
          ) {
            otherFolders.push(operation);
          }
        }
      }
    }

    if (
      otherFolders.findIndex((folder: any) => folder.bonus) !== -1 ||
      rowsSelectedFolders.findIndex((folder: IListFolders) => folder.bonus) !==
        -1
    ) {
      setIsAddOperation(true);
      updateRowsSelectedFolders([...rowsSelectedFolders, ...otherFolders]);
    } else {
      setIsAddOperation(false);
    }
  };
  return (
    <div
      style={{
        borderBottomLeftRadius: radius,
        color: colorTextButton,
        backgroundColor:
          installer.nom_partenaire === 'DRAPO' ||
          installer.nom_partenaire === ''
            ? '#5d6ad2'
            : installer.custom.couleur_boutons,
      }}
      className="flex text-white pt-[.625rem] px-[1.5rem] w-full pb-[5.825rem]"
    >
      <p className=" w-2/6 px-[1.5rem] text-[1rem] font-normal">
        {rowsSelectedFolders.length} Sélectionné
        {rowsSelectedFolders.length > 1 ? 's' : ''}
      </p>

      <div className="w-full">
        <div className="w-4/6 flex space-x-[3.125rem]">
          {totaux.map((el) => {
            return (
              <div key={v4()}>
                <p>{el.label}</p>
                <p>{numFormatSpace(el.value, false)} €</p>
              </div>
            );
          })}
        </div>
      </div>
      {rowsSelectedFolders.length > 0 && (
        <button
          disabled={isDeleteFolderLoading || isModalDeleteFolder}
          style={{
            borderRadius: radius,
            opacity: isDeleteFolderLoading || isModalDeleteFolder ? 0.7 : 1,
          }}
          onClick={() => {
            updateIsModalDeleteFolder(true);
            addOperationBonus();
          }}
          className="bg-white text-colorTextLightMode px-[1.25rem] text-[1rem] hover:bg-transparent hover:text-white hover:border-white hover:border"
          type="button"
        >
          Supprimer
        </button>
      )}
      {isModalDeleteFolder && (
        <DeleteOperationsInList
          setFoldersDatas={setFoldersDatas}
          folderDatas={folderDatas}
          isAddOperation={isAddOperation}
        />
      )}
    </div>
  );
}

export default TotalAmountsAndDelete;
