import React, { Dispatch, SetStateAction } from 'react';
import { IFieldType, IStepConditionType, IStepType } from './AdminTypes';

interface AdminContextState {
  // steps
  stepName: string;
  updateStepName: Dispatch<SetStateAction<string>>;
  stepFieldsSelect: IFieldType[];
  updateStepFieldsSelect: Dispatch<SetStateAction<IFieldType[]>>;
  conditions: IStepConditionType[];
  updateConditions: Dispatch<SetStateAction<IStepConditionType[]>>;
  stepLoading: boolean;
  updateStepLoading: Dispatch<SetStateAction<boolean>>;
  steps: IStepType[];
  updateSteps: Dispatch<SetStateAction<IStepType[]>>;
}

export const AdminContext = React.createContext({} as AdminContextState);
