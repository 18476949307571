import { Dispatch, SetStateAction } from 'react';
import { Button } from 'storybook-design-system-drapo';
import { deleteStep } from '../../apiRequests/AdminRequests';
import { IStepType } from '../../utils/AdminTypes';

type PropTypes = {
  step: IStepType;
  updateSteps: Dispatch<SetStateAction<IStepType[]>>;
  setShowPopup: Dispatch<SetStateAction<string>>;
  setOperationLoading: Dispatch<SetStateAction<boolean>>;
};

export default function Delete({
  step,
  updateSteps,
  setShowPopup,
  setOperationLoading,
}: PropTypes): JSX.Element {
  const handleDeleteStep = () => {
    deleteStep(step.id, updateSteps, setShowPopup, setOperationLoading);
  };

  return (
    <div className="fixed top-0 left-0 w-full h-full bg-[#000000ab]">
      <div
        className="dark:bg-dark-3 bg-lightMode-dark-3 relative left-1/2 top-1/2 w-1/4 h-1/4 cursor-default rounded-md p-5 flex flex-col justify-between"
        style={{ transform: 'translate(-50%, -50%)' }}
      >
        <div className="text-xl">Supprimer {step.name} ?</div>
        <p className="text-sm">
          Le tableau sera supprimé de manière définitive
        </p>
        <div className="flex justify-between">
          <Button
            backgroundColor="#2ECC71"
            color="white"
            label="Annuler"
            onClick={() => setShowPopup('NONE')}
            addClass="w-[130px]"
          />
          <Button
            backgroundColor="#EC1A37"
            color="white"
            label="Supprimer"
            onClick={handleDeleteStep}
            addClass="w-[130px]"
          />
        </div>
      </div>
    </div>
  );
}
