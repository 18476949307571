import axios from 'axios';
import { Dispatch, SetStateAction } from 'react';
import { IListFolders } from '@dashboard/utils/dashboardTypes';
import { toast } from 'react-toastify';
import { NavigateFunction } from 'react-router-dom';
import {
  IAllDocumentType,
  ICeeDocuments,
  IFolderMonitoringDatas,
} from '../utils/folderMonitoringTypes';

const apiUrl = process.env.REACT_APP_BACK_PRO_API_URL;

// RECUPERE LES INFOS DU DOSSIER POUR FOLDER MONITORING MPR & CEE
export const getFolderMonitoringDatasMPRCEE = (
  refFolder: string,
  updateFolderDatas: Dispatch<SetStateAction<IFolderMonitoringDatas>>,
  setIsLoading: Dispatch<SetStateAction<boolean>>,
  router: NavigateFunction
) => {
  setIsLoading(true);
  axios
    .get(`${apiUrl}/dossier/${refFolder}/cee-mpr`)
    .then((response) => {
      updateFolderDatas(response.data);
      setIsLoading(false);
    })
    .catch(() => {
      toast('⛔️ Dossier inexistant');
      router('/', { state: { prevPath: 'suivi-de-dossier/' }, replace: true });
    });
};

// RECUPERE LE DETAIL DU DOSSIER
export const getFolder = (
  refFolder: string,
  updateFolder: Dispatch<SetStateAction<IListFolders[]>>
) => {
  const data = {
    nb_per_page: 20,
    page: 1,
    filter: { keyword: refFolder },
  };
  axios
    .post(`${apiUrl}/installer/dossiersAirtable`, data)
    .then((response) => {
      updateFolder(response.data.dossiers.data);
    })
    .catch((error) => {
      console.log(error.response);
    });
};

// SUPPRIME UNE OPERATION DU DOSSIER
export const deleteOperationFolder = (
  eligibility: string[],
  folder: string,
  operation: string,
  setFolderDatas: Dispatch<SetStateAction<IFolderMonitoringDatas>>,
  router: NavigateFunction,
  setIsLoading: Dispatch<SetStateAction<boolean>>,
  setIsDeleteLoading: Dispatch<SetStateAction<boolean>>
) => {
  setIsLoading(true);
  const config = {
    headers: {
      ContentType: 'multipart/form-data',
    },
  };
  axios
    .delete(`${apiUrl}/dossier/${folder}/${operation}/delete`, config)
    .then(() => {
      setIsLoading(false);
      setIsDeleteLoading(false);
      toast(` 🎉 L'operation ${operation} a bien été supprimée.`);
      getFolderMonitoringDatasMPRCEE(
        folder,
        setFolderDatas,
        setIsLoading,
        router
      );
    })
    .catch(() => {
      toast(
        `⛔️ Une erreur est survenue lors de la suppression du dossier ${folder}.`
      );
      setIsDeleteLoading(false);
    });
};

// RECUPERE LES DOCUMENT POUR FOLDER MONITORING
export const getAllDocuments = (
  refFolder: string,
  updateAllDocuments: Dispatch<SetStateAction<IAllDocumentType>>,
  setIsLoading: Dispatch<SetStateAction<boolean>>
) => {
  axios
    .get(`${apiUrl}/dossier/${refFolder}/documents`)
    .then((response) => {
      updateAllDocuments(response.data);
      setIsLoading(false);
    })
    .catch((error) => {
      console.log(error.response);
    });
};

// AJOUTE LES INFOS CREATION DE DOSSIER MPR
export const addInfosCreateFolder = (
  isCheckRepresent: boolean,
  setDetailsActive: Dispatch<SetStateAction<boolean>>,
  updateStepIsUpdate: Dispatch<SetStateAction<boolean>>,
  foldersDatas: IFolderMonitoringDatas,
  updateFolderDatas: Dispatch<SetStateAction<IFolderMonitoringDatas>>,
  setIsLoading: Dispatch<SetStateAction<boolean>>,
  setIsSend: Dispatch<SetStateAction<boolean>>,
  setError: Dispatch<SetStateAction<string>>,
  setErrorDateStyle: Dispatch<SetStateAction<string>>,
  refFolder: string,
  login: string,
  password: string,
  isCheck: boolean,
  date?: string,
  file?: File
) => {
  const config = {
    headers: {
      ContentType: 'multipart/form-data',
    },
  };

  const formData = new FormData();
  formData.append('mpr_override', `${isCheckRepresent ? '0' : '1'}`);

  if (!isCheckRepresent) {
    formData.append('mpr_id', login);
    formData.append('mpr_password', password);
  }

  formData.append('mpr_attest', `${isCheck ? '1' : '0'}`);

  if (date && file && isCheck) {
    if (date !== 'jj/mm/aaaa') {
      const dateToSend = new Date(date);
      formData.append('mpr_date', dateToSend.toLocaleDateString('fr'));
    }
    formData.append('file_notification_octroi', file);
  }

  axios
    .post(`${apiUrl}/dossier/${refFolder}/mpr-infos`, formData, config)
    .then((response) => {
      const tab = foldersDatas;
      for (let i = 0; i < tab.operations.length; i += 1) {
        tab.operations[i].step_mpr = response.data.operations[0].step_mpr;
        tab.operations[i].text_mpr = response.data.operations[0].text_mpr;
        tab.operations[i].statut_mpr = response.data.operations[0].statut_mpr;
      }
      updateFolderDatas(tab);
      setIsLoading(false);
      setError('');
      setIsSend(true);
      setDetailsActive(false);
      toast('👌 Les informations ont bien été envoyées');
      updateStepIsUpdate(true);
    })
    .catch((error) => {
      if (error.response.data.mpr_date) {
        setError(
          "La date n'est pas valide. Merci de saisir une date au format jj/mm/yyyy"
        );
        setErrorDateStyle('border border-red-500');
      } else {
        setError('Une erreur est survenue. Veuillez réessayer');
      }
      setIsLoading(false);
      setIsSend(true);
      console.log(error);
    });
};

// AJOUTE LE MANDAT MPR
export const addMandateMPR = (
  updateStepIsUpdate: Dispatch<SetStateAction<boolean>>,
  setIsLoading: Dispatch<SetStateAction<boolean>>,
  setIsSend: Dispatch<SetStateAction<boolean>>,
  setError: Dispatch<SetStateAction<string>>,
  refFolder: string,
  file: ICeeDocuments[],
  operation_reference: string,
  foldersDatas: IFolderMonitoringDatas,
  updateFolderDatas: Dispatch<SetStateAction<IFolderMonitoringDatas>>
) => {
  const formData = new FormData();
  formData.append('operation_reference', operation_reference);

  for (let i = 0; i < file.length; i += 1) {
    formData.append('file_mandate_signed[]', file[i].file);
  }

  axios
    .post(`${apiUrl}/dossier/${refFolder}/mpr-files`, formData)
    .then((response) => {
      setIsLoading(false);
      setError('');
      setIsSend(true);
      const tab = foldersDatas;
      for (let i = 0; i < tab.operations.length; i += 1) {
        tab.operations[i].step_mpr = response.data.operations[i].step_mpr;
        tab.operations[i].step_cee = response.data.operations[i].step_cee;
        tab.operations[i].text_mpr = response.data.operations[i].text_mpr;
        tab.operations[i].text_cee = response.data.operations[i].text_cee;
      }
      updateFolderDatas(tab);
      toast('👌 Le fichier a bien été envoyé');
      updateStepIsUpdate(true);
    })
    .catch((error) => {
      setError('Une erreur est survenue. Veuillez réessayer');
      setIsLoading(false);
      setIsSend(true);
      console.log(error);
    });
};

/*
// ENVOI LE MONTANT DE LA FACTURE MPR
export const sendInvoiceAmountMPR = (
  refFolder: string,
  operation_reference: string,
  matchingAmount: string,
  setIsCompare: Dispatch<SetStateAction<boolean>>,
  setErrorSendAmount: Dispatch<SetStateAction<boolean>>,
  setIsLoadingSendAmount: Dispatch<SetStateAction<boolean>>
) => {
  const data = {
    amount_invoice: matchingAmount,
    operation_reference,
  };

  axios
    .post(`${apiUrl}/dossier/${refFolder}/mpr-certificate `, data)
    .then(() => {
      setIsCompare(true);
      setIsLoadingSendAmount(false);
    })
    .catch((error) => {
      setErrorSendAmount(true);
      setIsLoadingSendAmount(false);
      console.log(error);
    });
};
*/

// AJOUTE FACTURE ET ATTESTATION CONSENTEMENT MPR
export const addInvoiceMPR = (
  updateStepIsUpdate: Dispatch<SetStateAction<boolean>>,
  setIsLoading: Dispatch<SetStateAction<boolean>>,
  setIsSend: Dispatch<SetStateAction<boolean>>,
  setError: Dispatch<SetStateAction<string>>,
  refFolder: string,
  file: ICeeDocuments[],
  operation_reference: string,
  foldersDatas: IFolderMonitoringDatas,
  updateFolderDatas: Dispatch<SetStateAction<IFolderMonitoringDatas>>,
  hasInvoice: boolean
) => {
  const formData = new FormData();
  formData.append('operation_reference', operation_reference);
  formData.append('is_invoice', hasInvoice ? '0' : '1');

  for (let i = 0; i < file.length; i += 1) {
    if (file[i].name === 'Facture') {
      formData.append('file_work_invoice[]', file[i].file);
    } /* else {
      formData.append('file_certificate_consent[]', file[i].file);
    }
    */
  }

  axios
    .post(`${apiUrl}/dossier/${refFolder}/mpr-invoice`, formData)
    .then((response) => {
      setIsLoading(false);
      setError('');
      setIsSend(true);
      const tab = foldersDatas;
      for (let i = 0; i < tab.operations.length; i += 1) {
        tab.operations[i].step_mpr = response.data.operations[i].step_mpr;
        tab.operations[i].step_cee = response.data.operations[i].step_cee;
        tab.operations[i].text_mpr = response.data.operations[i].text_mpr;
        tab.operations[i].text_cee = response.data.operations[i].text_cee;
      }
      updateFolderDatas(tab);
      toast('👌 Le fichier a bien été envoyé');
      updateStepIsUpdate(true);
    })
    .catch((error) => {
      setError('Une erreur est survenue. Veuillez réessayer');
      setIsLoading(false);
      setIsSend(true);
      console.log(error);
    });
};

// RELANCE EMAIL SIGNATURE ELECTRONIQUE MPR
export const sendEmailMPR = (
  setMailIsSend: Dispatch<SetStateAction<boolean>>,
  setErrorMail: Dispatch<SetStateAction<string>>,
  refOperation: string
) => {
  axios
    .get(`${apiUrl}/signature/remind/mandat/${refOperation}`)
    .then(() => {
      setErrorMail('');
      setMailIsSend(false);
      toast("📫 Un email de relance vient d'être adressé à votre client");
    })
    .catch((error) => {
      if (
        error.response.data.error.includes(
          'no procedure in progress for this folder'
        )
      ) {
        setErrorMail('Aucune procédure en cours.');
      } else {
        setErrorMail('Une erreur est survenue. Veuillez réessayer');
      }
      setMailIsSend(false);
      console.log(error);
    });
};

/*
// ENVOI LE MONTANT DE LA FACTURE CEE
export const sendInvoiceAmountCEE = (
  refFolder: string,
  operation_reference: string,
  matchingAmount: string,
  setIsCompare: Dispatch<SetStateAction<boolean>>,
  setErrorSendAmount: Dispatch<SetStateAction<boolean>>,
  setIsLoadingSendAmount: Dispatch<SetStateAction<boolean>>
) => {
  const data = {
    amount_invoice: matchingAmount,
    operation_reference,
  };

  axios
    .post(`${apiUrl}/dossier/${refFolder}/cee-certificate `, data)
    .then(() => {
      setIsCompare(true);
      setIsLoadingSendAmount(false);
    })
    .catch((error) => {
      setErrorSendAmount(true);
      setIsLoadingSendAmount(false);
      console.log(error);
    });
};
*/

// AJOUTE LES FICHIERS MANQUANT CEE FOLDERMONITORING
export const addCEEFiles = (
  setDetailsActive: Dispatch<SetStateAction<boolean>>,
  dateConstruction: string,
  updateStepIsUpdate: Dispatch<SetStateAction<boolean>>,
  setIsLoading: Dispatch<SetStateAction<boolean>>,
  setIsSend: Dispatch<SetStateAction<boolean>>,
  setError: Dispatch<SetStateAction<string>>,
  refFolder: string,
  filesToSend: ICeeDocuments[],
  operation_reference: string,
  foldersDatas: IFolderMonitoringDatas,
  updateFolderDatas: Dispatch<SetStateAction<IFolderMonitoringDatas>>,
  emailClient?: string,
  telClient?: string
) => {
  const formData = new FormData();

  formData.append('operation_reference', operation_reference);
  formData.append('date_construction', dateConstruction);

  const config = {
    headers: {
      ContentType: 'multipart/form-data',
    },
  };

  for (let i = 0; i < filesToSend.length; i += 1) {
    if (filesToSend[i].scope !== undefined) {
      formData.append(filesToSend[i].scope ?? '', filesToSend[i].file);
    } else if (filesToSend[i].slug?.includes('Devis signé')) {
      formData.append('file_devis_signe[]', filesToSend[i].file);
    } else if (filesToSend[i].slug?.includes('Facture')) {
      formData.append('file_work_invoice[]', filesToSend[i].file);
    } else if (filesToSend[i].slug?.includes('Photos avant chantier')) {
      formData.append('file_photo_before_work[]', filesToSend[i].file);
    } else if (
      filesToSend[i].slug?.includes('Photos après chantier') ||
      filesToSend[i].slug?.includes('Photos du calorifugeage')
    ) {
      formData.append('file_photo_after_work[]', filesToSend[i].file);
    } else if (filesToSend[i].slug?.includes('Note de dimensionnement')) {
      formData.append('file_dimension_note[]', filesToSend[i].file);
    } else if (
      filesToSend[i].slug?.includes("Photo de la vanne d'équilibrage")
    ) {
      formData.append('file_photo_valve[]', filesToSend[i].file);
    }
  }

  if (emailClient) {
    formData.append('client_email', emailClient);
  }

  if (telClient) {
    formData.append('client_phone', telClient);
  }

  axios
    .post(`${apiUrl}/dossier/${refFolder}/cee-files`, formData, config)
    .then((response) => {
      setIsLoading(false);
      setError('');
      setIsSend(true);
      const tab = foldersDatas;
      for (let i = 0; i < tab.operations.length; i += 1) {
        tab.operations[i].step_cee = response.data.operations[i].step_cee;
        tab.operations[i].text_cee = response.data.operations[i].text_cee;
        tab.operations[i].statut_cee = response.data.operations[i].statut_cee;
      }
      setDetailsActive(false);
      updateFolderDatas(tab);
      toast('👌 Les fichiers ont bien été envoyés');
      updateStepIsUpdate(true);
    })
    .catch((error) => {
      setError(
        error.response.data.error ??
          'Une erreur est survenue. Veuillez réessayer'
      );
      setIsLoading(false);
      setIsSend(true);
    });
};

// RELANCE EMAIL CEE
export const sendEmailCEE = (
  setMailIsSend: Dispatch<SetStateAction<boolean>>,
  setErrorMail: Dispatch<SetStateAction<string>>,
  refOperation: string
) => {
  axios
    .get(`${apiUrl}/signature/remind/ah/${refOperation}`)
    .then(() => {
      setErrorMail('');
      setMailIsSend(false);
      toast('👌 La relance de mail a bien été effectuée');
    })
    .catch((error) => {
      setErrorMail('Une erreur est survenue. Veuillez réessayer');
      setMailIsSend(false);
      console.log(error);
    });
};

// GET STATUS SIGNATURE AH
export const getStatusSignatureAH = (
  setCanceledByRT: Dispatch<SetStateAction<boolean>>,
  setIsLoading: Dispatch<SetStateAction<boolean>>,
  setDateAH: Dispatch<SetStateAction<string>>,
  /*
  setLinkSignature: Dispatch<SetStateAction<string>>,
  */
  setStatusSignaturePro: Dispatch<SetStateAction<string>>,
  setStatusSignatureClient: Dispatch<SetStateAction<string>>,
  refOperation: string
) => {
  axios
    .get(`${apiUrl}/signature/status/ah/${refOperation}`)
    .then((response) => {
      if (response.data.date) {
        const date = new Date(response.data.date);
        setDateAH(date.toLocaleDateString('fr'));
      }
      /*
      setLinkSignature(response.data.pro.url);
      */
      setStatusSignaturePro(response.data.pro.status);
      setStatusSignatureClient(response.data.client.status);
      setIsLoading(false);
    })
    .catch((error) => {
      setIsLoading(false);
      if (
        error.response.data.error.includes(
          'Attempt to read property "members" on null'
        )
      ) {
        setCanceledByRT(true);
      }
      console.log(error);
    });
};
