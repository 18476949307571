import { InstallerContext } from '@auth/utils/authContext';
import { useContext } from 'react';

function LoaderBulkimport(): JSX.Element {
  const { radiusPx } = useContext(InstallerContext);
  return (
    <div style={{ borderRadius: radiusPx }} className={`load load-import `}>
      <div className="load-one" />
      <div className="load-two" />
      <div className="load-three" />
    </div>
  );
}

export default LoaderBulkimport;
