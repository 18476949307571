/* eslint-disable react/require-default-props */
import { numFormatSpace } from '@utils/utils';
import { useContext } from 'react';
import { InstallerContext } from '@auth/utils/authContext';
import { Link } from 'react-router-dom';

interface IProps {
  nameClient: string;
  firstNameClient: string;
  addressClient: string;
  amountCee?: string;
  amountMpr?: string;
  refFolder: string;
  error: number;
}

function OneCardFolder({
  nameClient,
  firstNameClient,
  addressClient,
  amountCee,
  amountMpr,
  refFolder,
  error,
}: IProps): JSX.Element {
  const { radiusPx } = useContext(InstallerContext);
  const addressSplit =
    addressClient !== null ? addressClient.split(', ') : [''];

  const radius = radiusPx === '0px' ? '0px' : '8px';
  return (
    <Link to={`/suivi-de-dossier/${refFolder}`}>
      <div
        style={{ borderRadius: radius }}
        className="w-full p-[.75rem] flex justify-between dark:bg-dark-5 bg-white dark:text-white text-dark-2 mb-[.75rem] cursor-pointer dark:border-none border border-borderLightMode "
      >
        <div>
          <p className="text-[1rem] font-medium mb-[.5rem]">
            {firstNameClient} {nameClient}
          </p>
          <p className="font-normal text-[.875rem]">
            {addressSplit[0]}
            <br />
            {addressSplit[1]}
          </p>
        </div>
        <div className="flex flex-col justify-between items-end text-[.75rem] text-medium">
          <p className="opacity-[.40] whitespace-nowrap">{`${error} demande${
            error > 1 ? 's' : ''
          }`}</p>
          <div className="text-right">
            {amountCee !== undefined && (
              <p>CEE {numFormatSpace(amountCee)} €</p>
            )}
            {amountMpr !== undefined && (
              <p>MPR {numFormatSpace(amountMpr)} €</p>
            )}
          </div>
        </div>
      </div>
    </Link>
  );
}

export default OneCardFolder;
