import ListImportedXls from './ListImportedXls';
import UploadXls from './UploadXls';

function LeftPartBulkImport(): JSX.Element {
  return (
    <div className="border-r dark:border-r-dark-5 border-r-borderLightMode lg:w-3/12 pt-[1rem] flex flex-col pb-[1.5rem]">
      <div className="overflow-y-auto h-[100%!important] dark:text-white text-dark-1">
        <ListImportedXls />
      </div>
      <div className="flex items-end">
        <UploadXls />
      </div>
    </div>
  );
}

export default LeftPartBulkImport;
