import { Dispatch, SetStateAction } from 'react';
import { Button } from 'storybook-design-system-drapo';

type PropTypes = {
  setShowPopup: Dispatch<SetStateAction<string>>;
};

export default function Save({ setShowPopup }: PropTypes): JSX.Element {
  return (
    <div className="fixed top-0 left-0 w-full h-full bg-[#000000ab] z-10">
      <div
        className="dark:bg-dark-3 bg-lightMode-dark-3 relative left-1/2 top-1/2  xl:w-1/2  h-1/3 cursor-default rounded-md p-5 flex flex-col justify-between"
        style={{ transform: 'translate(-50%, -50%)' }}
      >
        <div className="text-xl">Quitter ?</div>
        <p className="text-sm">
          Voulez vous quitter sans enregistrer les modification?
        </p>
        <div className="flex justify-between">
          <Button
            backgroundColor="#2ECC71"
            color="white"
            label="Annuler"
            onClick={() => setShowPopup('SELECT_CONDITIONS')}
            addClass="w-[130px]"
          />
          <Button
            backgroundColor="#EC1A37"
            color="white"
            label="Ne pas enregistrer"
            onClick={() => setShowPopup('NONE')}
            addClass="w-[200px]"
          />
        </div>
      </div>
    </div>
  );
}
