import React from 'react';

interface IProps {
  isRetractableDDC: boolean;
  updateIsRetractableDDC: React.Dispatch<React.SetStateAction<boolean>>;
  isLookingFor: boolean;
  updateIsLookingFor: React.Dispatch<React.SetStateAction<boolean>>;
}

export const LayoutDashboardContext = React.createContext({} as IProps);
