interface IProps {
  info: string;
}
function InfosBanner({ info }: IProps): JSX.Element {
  return (
    <div className="w-full text-center bg-purple-1  py-0 px-[1vh] fixed z-[33333]">
      {info}
    </div>
  );
}

export { InfosBanner };
