import { useContext } from 'react';
import { RefreshIcon } from '@assets/svgComponents/svgComponents';
import { SimulatorContext } from '@simulator/utils/simulatorContext';

function ErrorCalculationPrimes(): JSX.Element {
  const { errorCalculationPrimes } = useContext(SimulatorContext);

  return (
    <div className="w-full p-[2rem] flex justify-between rounded-default bg-red-1 items-center">
      <div className="leading-[1.5rem] text-[.875rem] text-white">
        <p>{errorCalculationPrimes}</p>
      </div>
      <div className="flex items-end pl-[2rem]">
        <button
          type="button"
          className="cursor-pointer bg-white rounded-default px-[1.5rem] py-[1rem] text-[.75rem] font-light whitespace-nowrap h-min flex items-center text-red-1"
          onClick={() => window.location.reload()}
        >
          Recommencer une simulation{' '}
          <RefreshIcon className="ml-[.75rem] w-[1.5rem]" fill="#A60028" />
        </button>
      </div>
    </div>
  );
}

export { ErrorCalculationPrimes };
