import { Dispatch, SetStateAction, useContext } from 'react';
import { InputCount } from 'storybook-design-system-drapo';
import { FolderContext } from '@folder/utils/FoldersContext';
import { ITaxHousehold } from '@folder/utils/folderTypes';
import { InstallerContext } from '@auth/utils/authContext';
import { dark1, dark2, white } from '@assets/colors';
import HouseholdCard from './HouseholdCard';

interface TaxHouseholdCustomerInformationProps {
  /* setErrorsTaxReference: Dispatch<SetStateAction<string[]>>;
  setErrorsTaxNumber: Dispatch<SetStateAction<string[]>>;
  errorsTaxReference: string[];
  errorsTaxNumber: string[];
  isErrorTax: boolean;
  setIsErrorTax: Dispatch<SetStateAction<boolean>>;
  setTaxReferenceYearNMinus2: Dispatch<SetStateAction<boolean[]>>;
  taxReferenceYearNMinus2: boolean[]; */
  setDivActive: Dispatch<SetStateAction<string | undefined>>;
  setCheckGrantNotification: Dispatch<SetStateAction<boolean>>;
  checkGrantNotification: boolean;
}

function TaxHouseholdCustomerInformation({
  /* setErrorsTaxReference,
  setErrorsTaxNumber,
  errorsTaxReference,
  errorsTaxNumber,
  isErrorTax,
  setIsErrorTax,
  setTaxReferenceYearNMinus2,
  taxReferenceYearNMinus2, */
  setDivActive,
  setCheckGrantNotification,
  checkGrantNotification,
}: TaxHouseholdCustomerInformationProps): JSX.Element {
  // const [displayNMinus2, setDisplayNMinus2] = useState(false);

  const {
    householdsDatas,
    updateHouseholdsDatas,
    folderDatas,
    numberHousehold,
    updateNumberHousehold,
  } = useContext(FolderContext);

  const { radiusPx, isThemeDark } = useContext(InstallerContext);

  const minusClick = () => {
    const min = 1;
    if (numberHousehold > min) {
      updateHouseholdsDatas(householdsDatas.slice(0, -1));
      updateNumberHousehold(numberHousehold - 1);
      return updateNumberHousehold(numberHousehold - 1);
    }
    return updateNumberHousehold(numberHousehold);
  };

  const plusClick = () => {
    const max = 5;
    if (householdsDatas.length < max) {
      updateHouseholdsDatas((oldHouseholdDatas) => {
        const newHouseholdDatas: ITaxHousehold[] = [
          ...oldHouseholdDatas,
          {
            /* numero: '',
            reference: '', */
            declarant: '',
          },
        ];
        return newHouseholdDatas;
      });
      /* setErrorsTaxNumber((oldErrorsTaxNumber) => {
        return [...oldErrorsTaxNumber, ''];
      });
      setErrorsTaxReference((oldErrorsTaxReference) => {
        return [...oldErrorsTaxReference, ''];
      }); */
      return updateNumberHousehold(numberHousehold + 1);
    }
    return updateNumberHousehold(numberHousehold);
  };

  /* useEffect(() => {
    if (taxReferenceYearNMinus2.includes(true)) {
      setDisplayNMinus2(true);
    } else {
      setDisplayNMinus2(false);
    }
  }, [taxReferenceYearNMinus2]);

  useEffect(() => {
    if (displayNMinus2) {
      setDisplayNMinus2(false);
      setTaxReferenceYearNMinus2([false]);
    }
  }, [householdsDatas]); */

  return (
    <div className="mt-interblock">
      <InputCount
        borderRadius={radiusPx}
        color={isThemeDark ? white : dark1}
        backgroundColor={isThemeDark ? dark2 : white}
        addClass="mt-[2rem]"
        label={numberHousehold === 1 ? 'Foyer fiscal' : 'Foyers fiscaux'}
        valueInputCount={numberHousehold}
        onClickMinus={minusClick}
        onClickPlus={plusClick}
        readOnly
      />
      {/* isErrorTax && (
        <WarningNotification
          borderRadius={radiusPx}
          addClass="mt-[1.25rem]"
          backgroundColor={
            installer.nom_partenaire === 'DRAPO' ||
            installer.nom_partenaire === ''
              ? red2
              : installer.custom.couleur_alerte
          }
          color="white"
          warningText="Certaines réponses ne sont pas valides"
        />
      ) */}
      <ul>
        {householdsDatas.map((item, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <li key={index}>
            <HouseholdCard
              item={item}
              setHouseholdsDatas={updateHouseholdsDatas}
              index={index}
              firstNameClient={folderDatas.client.firstname}
              lastNameClient={folderDatas.client.lastname}
              /* setErrorsTaxReference={setErrorsTaxReference}
              setErrorsTaxNumber={setErrorsTaxNumber}
              errorsTaxReference={errorsTaxReference}
              errorsTaxNumber={errorsTaxNumber}
              setIsErrorTax={setIsErrorTax}
              setTaxReferenceYearNMinus2={setTaxReferenceYearNMinus2} */
              setDivActive={setDivActive}
              setCheckGrantNotification={setCheckGrantNotification}
              checkGrantNotification={checkGrantNotification}
            />
          </li>
        ))}
      </ul>
      {/* displayNMinus2 && (
        <AlertTaxReferenceNMinus2
          taxReferenceYearNMinus2={taxReferenceYearNMinus2}
          setCheckGrantNotification={setCheckGrantNotification}
          checkGrantNotification={checkGrantNotification}
        />
      ) */}
    </div>
  );
}

export default TaxHouseholdCustomerInformation;
