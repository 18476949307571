import { Input, InputSearchInList } from 'storybook-design-system-drapo';
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import { IKeyValueStringType } from '@simulator/utils/simulatorTypes';
import { InstallerContext } from '@auth/utils/authContext';
import {
  backgroundQuaternaryLightMode,
  dark1,
  dark4,
  darkVariant1,
  white,
} from '@assets/colors';

interface ScopUnitCardProps {
  materialsList: IKeyValueStringType[];
  unitsData: IKeyValueStringType[];
  setUnitsData: Dispatch<SetStateAction<IKeyValueStringType[]>>;
  cardIndex: number;
}

function ScopUnitCard({
  materialsList,
  unitsData,
  setUnitsData,
  cardIndex,
}: ScopUnitCardProps): JSX.Element {
  const [nameUnit, setNameUnit] = useState('');
  const [surfaceUnit, setSurfaceUnit] = useState('');
  const [filteredMaterials, setFilteredMaterials] = useState<
    IKeyValueStringType[]
  >([]);
  const [materialNotFound, setMaterialNotFound] = useState<boolean>(false);

  const { radiusPx, colorPrimary, isThemeDark } = useContext(InstallerContext);

  useEffect(() => {
    setUnitsData((prevState) => {
      const newUnitsData = [...prevState];
      newUnitsData[cardIndex].name = nameUnit;
      newUnitsData[cardIndex].surface = surfaceUnit;
      return newUnitsData;
    });
  }, [nameUnit, surfaceUnit]);

  useEffect(() => {
    if (filteredMaterials.length === 0 && materialsList.length > 0) {
      setFilteredMaterials(materialsList);
    }
  }, [materialsList]);

  const handleSearch = (
    searchText: string | IKeyValueStringType,
    setFilteredArray: Dispatch<SetStateAction<IKeyValueStringType[]>>,
    datas: IKeyValueStringType[]
  ) => {
    if (materialNotFound) {
      setMaterialNotFound(false);
    }
    if (typeof searchText === 'string') {
      if (searchText !== '') {
        const filteredBlocks = datas.filter((data) => {
          return data.label.toLowerCase().includes(searchText.toLowerCase());
        });
        setFilteredArray(filteredBlocks);
      } else {
        setFilteredArray(datas);
      }
    } else {
      const filteredBlocks = datas.filter((data) => {
        return data.label
          .toLowerCase()
          .includes(searchText.label.toLowerCase());
      });
      setFilteredArray(filteredBlocks);
    }
  };

  const handleSelectMaterial = (material: IKeyValueStringType) => {
    setUnitsData((prevState) => {
      const newMaterialsData = [...prevState];
      newMaterialsData[cardIndex].value = material.value;
      newMaterialsData[cardIndex].label = material.label;
      newMaterialsData[cardIndex].scop = material.scop;
      newMaterialsData[cardIndex].power = material.power;
      return newMaterialsData;
    });
    setFilteredMaterials(materialsList);
    if (materialNotFound) {
      setMaterialNotFound(false);
    }
  };

  const handleChangeSurface = (value: string) => {
    setSurfaceUnit(value);
  };

  const handleClear = () => {
    setUnitsData((prevState) => {
      const newMaterialsData = [...prevState];
      newMaterialsData[cardIndex].value = '';
      newMaterialsData[cardIndex].label = '';
      newMaterialsData[cardIndex].scop = '';
      newMaterialsData[cardIndex].power = '';
      return newMaterialsData;
    });
  };

  const handleMaterialNotFound = () => {
    setMaterialNotFound(true);
  };

  return (
    <div
      style={{ borderRadius: radiusPx }}
      className="dark:bg-dark-2 bg-white p-[1.5rem] mt-[1.5rem] dark:text-white text-dark-1"
    >
      <Input
        borderRadius={radiusPx}
        backgroundColor={
          isThemeDark ? darkVariant1 : backgroundQuaternaryLightMode
        }
        color={isThemeDark ? white : dark1}
        addClass="mb-[1rem]"
        inputType="text"
        label="Nom de la pièce"
        value={nameUnit}
        onChange={(e) => setNameUnit(e.target.value)}
      />
      <Input
        borderRadius={radiusPx}
        backgroundColor={
          isThemeDark ? darkVariant1 : backgroundQuaternaryLightMode
        }
        color={isThemeDark ? white : dark1}
        inputType="text"
        isNumber
        label="Surface (m2)"
        value={surfaceUnit}
        onChange={(e) => handleChangeSurface(e.target.value)}
      />
      <div className="flex justify-between mt-[1rem]">
        <p>Scop</p>
        <p>{unitsData[cardIndex]?.scop}</p>
      </div>
      <div className="flex justify-between mt-[1rem]">
        <p>Puissance Nominale</p>
        <p>{unitsData[cardIndex]?.power}</p>
      </div>
      <div className="flex justify-between mt-[1rem]">
        <p>Marque et référence</p>
      </div>
      <InputSearchInList
        borderRadius={radiusPx}
        color={isThemeDark ? white : dark1}
        backgroundColorIconSearch={isThemeDark ? dark4 : 'white'}
        backgroundColor={isThemeDark ? dark1 : backgroundQuaternaryLightMode}
        onSearch={(searchValue) =>
          handleSearch(searchValue, setFilteredMaterials, materialsList)
        }
        filteredList={filteredMaterials}
        onSelectInList={(material) => handleSelectMaterial(material)}
        placeholder="Marque et référence matériel"
        addClass={`mt-[1rem] ${
          materialNotFound &&
          'border border-red-500 xl:!rounded-default sm:!rounded-[10px]'
        }`}
        onClear={handleClear}
        notFoundText="La référence de mon matériel n'est pas dans la liste."
        notFoundAction={handleMaterialNotFound}
      />
      {materialNotFound && (
        <p className="text-sm text-red-500 ml-[.5rem] mt-[.5rem]">
          Merci de nous envoyer la fiche technique du matériel afin que nous
          l&apos;ajoutions à notre simulateur.
          <br />
          Vous pouvez nous contacter à l&apos;adresse&nbsp;
          <a
            href="mailto:contact@drapo.com"
            target="_blank"
            className="cursor-pointer"
            style={{ color: colorPrimary }}
            rel="noreferrer"
          >
            contact@drapo.com
          </a>
        </p>
      )}
    </div>
  );
}

export default ScopUnitCard;
