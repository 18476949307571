/* eslint-disable no-nested-ternary */
import React, { useContext } from 'react';
import {
  editPencilWhite,
  editPencilBlack,
  filters,
  filters_black,
} from '@assets/images/images';
import { v4 } from 'uuid';
import { DashboardContext } from '@dashboard/utils/context/dashboardContext';
import { InstallerContext } from '@auth/utils/authContext';
import { purple1 } from '@assets/colors';

function ButtonsHeader(): JSX.Element {
  const {
    filtersOrPersonalizationModalCustomView,
    updateFiltersOrPersonalizationModalCustomView,
  } = useContext(DashboardContext);

  const { radiusPx, installer, isThemeDark } = useContext(InstallerContext);
  const radius = radiusPx === '0px' ? `0px` : '8px';

  const buttonsHeader = [
    {
      name: 'Filtrer',
      iconBlack: filters_black,
      iconWhite: filters,
    },
    {
      name: 'Personnaliser',
      iconBlack: editPencilBlack,
      iconWhite: editPencilWhite,
    },
  ];
  return (
    <div className="flex space-x-[1rem] items-center">
      {buttonsHeader.map((el) => {
        return (
          <button
            key={v4()}
            onClick={() => {
              updateFiltersOrPersonalizationModalCustomView(el.name);
            }}
            className="border px-[1rem] py-[.5rem]"
            type="button"
            style={{
              borderRadius: radius,
              borderColor:
                filtersOrPersonalizationModalCustomView === el.name
                  ? installer.nom_partenaire === 'DRAPO' ||
                    installer.nom_partenaire === ''
                    ? purple1
                    : installer.custom.couleur_boutons
                  : isThemeDark
                  ? 'rgba(255,255,255,0.15)'
                  : 'rgba(0,0,0,0.15)',
            }}
          >
            <div className="flex space-x-[.625rem] items-center">
              <div className="text-[.75rem] dark:text-white text-dark-2">
                {el.name}
              </div>
              <img
                className="ml-[1.25rem] w-[1rem]"
                src={isThemeDark ? el.iconWhite : el.iconBlack}
                alt={el.name === 'Filtrer' ? 'Filtrer' : 'Personnaliser'}
              />
            </div>
          </button>
        );
      })}
    </div>
  );
}

export default ButtonsHeader;
