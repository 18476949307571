import HeaderRightPart from '@bulkImport/components/rightPart/HeaderRightPart';
import { ArrayFileSent } from '@bulkImport/components/rightPart/wasSentToAirtable/ArrayFileSent';
import { useContext, useEffect } from 'react';
import { BulkImportContext } from '@bulkImport/utils/bulkImportContext';

function WasSentToAirtable(): JSX.Element {
  const { updateListFilesImported, fileSentToAirtable } =
    useContext(BulkImportContext);
  useEffect(() => {
    updateListFilesImported((oldListFilesImported) =>
      oldListFilesImported.filter((file) => file.id !== fileSentToAirtable?.id)
    );
  }, []);

  return (
    <>
      <div className="w-full mx-[1rem] dark:bg-dark-3 bg-lightMode-dark-3 rounded-default h-full">
        <HeaderRightPart />
        <div className="overflow-x-scroll max-h-[calc(100%-6.25rem)] w-full">
          <ArrayFileSent />
        </div>
      </div>
    </>
  );
}

export { WasSentToAirtable };
