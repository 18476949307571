import { useContext } from 'react';
import { CardLink } from 'storybook-design-system-drapo';
import { v4 } from 'uuid';
import LoaderCardlink from '@components/loaders/LoaderCardLink';
import { InstallerContext } from '@auth/utils/authContext';
import {
  OperationsListContext,
  SimulatorContext,
} from '@simulator/utils/simulatorContext';
import { IOptionType } from '@simulator/utils/simulatorTypes';
import { nextStepGeneral, nextStepOperation } from '@simulator/utils/functions';
import { colorTextLightMode, dark1, white } from '@assets/colors';
import { convertHexToRGBA } from '@utils/utils';
import { useLocation } from 'react-router-dom';

function CardForm(): JSX.Element {
  const location = useLocation();
  const {
    simulatorDatas,
    updateSimulatorDatas,
    graphGeneral,
    updateGraphGeneral,
    updateGraphList,
    updateStepNumber,
    updateGeneralComplete,
    generalComplete,
    updateGraphOperation,
    updateGraphListOperation,
    graphOperation,
    updateCurrentOperation,
    currentOperation,
    updateHeigthMap,
    loaded,
    multipleOperations,
    updateHasEcsIncompatibility,
  } = useContext(SimulatorContext);
  const { colorPrimary, radiusPx, isThemeDark, colorTextButton } =
    useContext(InstallerContext);
  const { operationList } = useContext(OperationsListContext);
  const onClickChange = (option: IOptionType) => {
    if (graphGeneral.name === 'Age du logement') {
      updateHeigthMap('h-[45vh]');
    }
    if (generalComplete) {
      nextStepOperation(
        simulatorDatas,
        currentOperation,
        updateStepNumber,
        updateCurrentOperation,
        updateGraphOperation,
        updateGraphListOperation,
        graphOperation,
        graphOperation.id,
        option.value
      );
    } else {
      nextStepGeneral(
        simulatorDatas,
        multipleOperations,
        updateGeneralComplete,
        updateStepNumber,
        updateSimulatorDatas,
        updateGraphGeneral,
        updateGraphList,
        graphGeneral,
        graphGeneral.id,
        option.value.toString()
      );
    }

    if (option.value === 'ecs') updateHasEcsIncompatibility(true);
    if (option.value === 'heating') updateHasEcsIncompatibility(false);
  };

  const renderImg = (img: string | undefined) => {
    if (img === undefined) return undefined;
    try {
      // eslint-disable-next-line global-require,import/no-dynamic-require
      return require(`@assets/images/simulator/${img}`);
    } catch (error) {
      return undefined;
    }
  };

  const graphActive = generalComplete ? graphOperation : graphGeneral;

  // BAR-TH-148 est incompatible avec BAR-TH-171 et BAR-TH-172 si l'usage = "eau chaude sanitaire".
  const hasBarTh148 = multipleOperations.some(
    (mo) =>
      mo.id === 'bar-th-148' ||
      operationList.some((ol) => ol.id === 'bar-th-148')
  );

  return (
    <div className="grow animate__animated animate__fadeIn">
      {!loaded ? (
        <LoaderCardlink />
      ) : (
        <div
          style={{
            borderRadius: radiusPx,
            border:
              location.search.includes('?integration=iframe') && !isThemeDark
                ? '1px solid #d2d2d2'
                : '',
          }}
          className="dark:bg-dark-2 bg-white p-[1.5rem] mt-[1.5rem]"
        >
          {graphActive.options.map((option: IOptionType, index: number) => {
            return (
              <CardLink
                key={v4()}
                borderRadius={radiusPx}
                color={isThemeDark ? white : colorTextLightMode}
                backgroundColor={
                  isThemeDark ? dark1 : convertHexToRGBA(colorPrimary, 0.1)
                }
                colorHover={colorTextButton}
                backgroundColorHover={colorPrimary}
                addClass={`${
                  index === graphActive.options.length - 1
                    ? 'mt-0'
                    : 'mb-[1rem]'
                }`}
                title={option.label}
                subtitle={option.postlabel}
                onClick={() => onClickChange(option)}
                urlImg={renderImg(option.img)}
                icon="arrow"
                dataTestId={`card-${graphActive.id}`}
                disabled={option.value === 'ecs' && hasBarTh148}
              />
            );
          })}
        </div>
      )}
    </div>
  );
}

export default CardForm;
