/* eslint-disable jsx-a11y/iframe-has-title */
import React from 'react';
import { useParams } from 'react-router-dom';

function TestIframe() {
  const { wl } = useParams();
  return (
    <div className="flex justify-center items-center">
      <iframe
        src={`https://pro.drapo.com/wl/${wl}/demander-ma-prime?integration=iframe`}
        width="390"
        height="800"
      />
    </div>
  );
}

export default TestIframe;
