import { ITabType } from '@dashboard/utils/dashboardTypes';

export const FOLDER_MONITORING_TABS: ITabType = {
  RECAP: 'Suivi du dossier',
  INFOS: 'Bénéficiaire',
  DOCS: 'Documents',
};

export const DASHBOARD_TABS: ITabType = {
  SUMMARY: 'Récapitulatif',
  FOLDERS_SIMULATION: 'Dossiers en cours de création',
  FOLDERS_INSTRUCTION: "Dossiers en cours d'instruction",
};
