import { InstallerContext } from '@auth/utils/authContext';
import { deleteFolder } from '@dashboard/apiRequests/dashboardRequests';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 } from 'uuid';
import { FolderContext } from '../../utils/FoldersContext';

type RenderRightPartProps = {
  infosRightPart: JSX.Element[] | undefined;
};

function RenderRightPart({
  infosRightPart,
}: RenderRightPartProps): JSX.Element {
  const router = useNavigate();
  const { radiusPx } = useContext(InstallerContext);
  const { folderReference } = useContext(FolderContext);

  return (
    <>
      {infosRightPart && (
        <div className="w-full h-full flex flex-col justify-end items-center text-[.75rem] leading-[1.5rem] p-[1.5rem] space-y-[1.5rem] relative">
          <button
            type="button"
            onClick={() => deleteFolder(folderReference, router)}
            className="absolute bottom-[95%] right-[1.875rem]"
          >
            Supprimer le dossier
          </button>
          {infosRightPart.map((info: JSX.Element) => (
            <div
              style={{ borderRadius: radiusPx }}
              className="dark:bg-dark-1 bg-white text-[.75rem] leading-[1.5rem] p-[1.875rem] dark:text-white text-colorTextLightMode"
              key={v4()}
            >
              {info}
            </div>
          ))}
        </div>
      )}
    </>
  );
}

export { RenderRightPart };
