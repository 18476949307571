import { InstallerContext } from '@auth/utils/authContext';
import { useContext } from 'react';

function HeaderBulkImport(): JSX.Element {
  const { radiusPx } = useContext(InstallerContext);
  return (
    <div
      style={{ borderTopLeftRadius: radiusPx, borderTopRightRadius: radiusPx }}
      className="w-full invisible lg:visible flex dark:bg-dark-5 bg-white px-[2rem] items-center  h-home-header dark:text-white text-dark-1 dark:border-none border-b border-b-borderLightMode border-b-[1px]"
    >
      <div className="text-[1.875rem] border-r dark:border-r-dark-5 border-r-borderLightMode w-3/12 h-full flex items-center">
        Imports XLS
      </div>
      <div className="text-[1.875rem] pl-[1.5rem] h-full flex items-center">
        Détails de l’import
      </div>
    </div>
  );
}

export default HeaderBulkImport;
