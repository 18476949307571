import { Dispatch, SetStateAction } from 'react';
import { initialInstaller } from './initialState';
import { IInstaller } from './authType';

export const logout = (
  updateToken: Dispatch<SetStateAction<string>>,
  updateInstaller: Dispatch<SetStateAction<IInstaller>>,
  updateInstallerIsLoaded: Dispatch<SetStateAction<boolean>>,
  updateThemeChangedManually: Dispatch<SetStateAction<boolean>>,
  fromAutoLogin?: boolean
) => {
  updateToken('');
  updateInstaller(initialInstaller);
  updateInstallerIsLoaded(false);
  updateThemeChangedManually(false);
  const body = document.getElementsByTagName('body')[0];
  body.classList.add('theme-dark-background-color');
  body.classList.remove('theme-light-background-color');
  if (fromAutoLogin) {
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  }
};
