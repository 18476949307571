import { InstallerContext } from '@auth/utils/authContext';
import { Dispatch, useContext, SetStateAction, useEffect } from 'react';
import Draggable from 'react-draggable';
import { purple1 } from '@assets/colors';
import { DashboardContext } from '@dashboard/utils/context/dashboardContext';
import { CloseIcon } from '@assets/svgComponents/svgComponents';
import ListColumns from './ListColumns';

interface IProps {
  updateNumberPageList: Dispatch<SetStateAction<number>>;
  columnsArray: string[];
  setColumnArray: Dispatch<SetStateAction<string[]>>;
}

function ModalPersonalization({
  updateNumberPageList,
  columnsArray,
  setColumnArray,
}: IProps) {
  const { updateIsModalPersonalizationFolders, isModalPersonalizationFolders } =
    useContext(DashboardContext);

  const { radiusPx, installer, isThemeDark, colorTextButton } =
    useContext(InstallerContext);

  const radius = radiusPx === '0px' ? '0px' : '8px';

  const scrollBarColor = isThemeDark ? '' : 'scroll_light_mode';

  useEffect(() => {
    updateNumberPageList(1);
  });

  return (
    <>
      <Draggable handle=".modal" bounds="main">
        <div
          style={{ borderRadius: radius, zIndex: 99999999 }}
          className="absolute overflow-hidden max-w-[18.125rem] text-colorTextLightMode dark:text-white"
        >
          <div
            style={{
              color: colorTextButton,
              backgroundColor:
                installer.nom_partenaire === 'DRAPO' ||
                installer.nom_partenaire === ''
                  ? purple1
                  : installer.custom.couleur_boutons,
            }}
            className="w-full text-[.75rem] flex justify-between items-center px-[1.5rem] py-[.625rem] modal"
          >
            <p className="font-medium"> Personnaliser</p>
            <button
              type="button"
              onClick={() =>
                updateIsModalPersonalizationFolders(
                  !isModalPersonalizationFolders
                )
              }
            >
              <CloseIcon fill={colorTextButton} width="1rem" height="1rem" />
            </button>
          </div>
          <div
            className={`flex flex-col ${scrollBarColor} border border-borderLightMode border dark:border-[#2D2D2D] space-y-[1.25rem] pt-[1.25rem] max-h-[37.5rem] overflow-y-scroll dark:bg-[#1C1E1F] bg-white pb-[1.25rem]`}
          >
            <ListColumns datas={columnsArray} setColumnArray={setColumnArray} />
          </div>
        </div>
      </Draggable>
    </>
  );
}

export default ModalPersonalization;
