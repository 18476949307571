import { InstallerContext } from '@auth/utils/authContext';
import {
  addUploadFile,
  deleteDocument,
} from '@bulkImport/apiRequests/bulkImportRequest';
import { BulkImportContext } from '@bulkImport/utils/bulkImportContext';
import { IKeyValueStringType } from '@simulator/utils/simulatorTypes';
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  FileMultiUpload,
  WarningNotification,
} from 'storybook-design-system-drapo';
import { v4 } from 'uuid';
import { toast } from 'react-toastify';
import {
  dark1,
  dark5,
  lightModeDarkVariant5,
  red3,
  white,
} from '@assets/colors';
import { convertHexToRGBA } from '@utils/utils';

interface IProps {
  setIsLoading: Dispatch<SetStateAction<boolean>>;
}

function ListDocumentsUpload({ setIsLoading }: IProps): JSX.Element {
  const {
    updateDocumentsGlobals,
    updateOperations,
    folderActiveReference,
    fileDetail,
    folderActive,
    updateFileDetail,
    operations,
    documentsGlobals,
  } = useContext(BulkImportContext);

  const { installer, radiusPx, colorPrimary, isThemeDark, colorTextButton } =
    useContext(InstallerContext);

  const [err, setErr] = useState<boolean>(false);
  const [errMessage, setErrMessage] = useState<string>('');
  const [isSameFile, setIsSameFile] = useState<boolean>(false);

  const transformFilesList = (fileName: string, list: string[] | null) => {
    const transformedList: IKeyValueStringType[] = [];
    if (list) {
      for (let i = 0; i < list.length; i += 1) {
        transformedList.push({
          name: `${fileName}_${i + 1}`,
          path: `${list[i]}`,
        });
      }
    }
    return transformedList;
  };

  const onUploadFile = (
    file: File,
    fileType: string,
    operationRerence?: string
  ) => {
    if (fileDetail !== undefined) {
      addUploadFile(
        folderActiveReference,
        file,
        fileType,
        fileDetail.id,
        updateFileDetail,
        setIsLoading,
        updateOperations,
        updateDocumentsGlobals,
        setErr,
        setErrMessage,
        operationRerence
      );
    }
  };

  const onDeleteFileUpload = (
    pathIndex: number,
    path: string[] | null,
    fileType: string,
    operationRerence?: string
  ) => {
    const fileName = path ? path[pathIndex] : '';

    if (fileDetail !== undefined) {
      deleteDocument(
        folderActiveReference,
        fileType,
        fileName,
        fileDetail.id,
        updateFileDetail,
        updateDocumentsGlobals,
        updateOperations,
        setIsLoading,
        operationRerence
      );
    }
  };

  const getFileType = (type: string) => {
    return type.indexOf('photo') !== -1 ? ['image'] : ['pdf'];
  };
  const backgroundUploadedColor =
    installer.nom_partenaire === 'DRAPO' || installer.nom_partenaire === ''
      ? '#2f3354'
      : lightModeDarkVariant5;

  const scrollBarColor = isThemeDark ? '' : 'scroll_light_mode';

  useEffect(() => {
    if (isSameFile) {
      toast(
        '⛔️ Un fichier avec le même nom a déjà été renseigné pour ce document'
      );
    }
  }, [isSameFile]);

  return (
    <div className={`${scrollBarColor} overflow-y-scroll max-h-full`}>
      <div>
        {err && (
          <WarningNotification
            warningText={errMessage}
            backgroundColor={
              installer.nom_partenaire === 'DRAPO' ||
              installer.nom_partenaire === ''
                ? red3
                : installer.custom.couleur_alerte
            }
            color="#ffffff"
            addClass="mt-[1.25rem] mx-4"
          />
        )}
        {folderActive && folderActive?.datas.files?.length > 0 && (
          <div className="mt-[1.5rem] px-[1rem] text-center text-[1.25rem] mb-[1rem] dark:text-white text-colorTextLightMode">
            Documents globaux
          </div>
        )}
        {folderActive &&
          documentsGlobals?.map((document) => {
            return (
              <div
                style={{
                  borderRadius: radiusPx,
                  backgroundColor: isThemeDark
                    ? dark1
                    : convertHexToRGBA(colorPrimary, 0.1),
                }}
                className="mx-5 mb-[.75rem]"
              >
                <FileMultiUpload
                  setActiveToast={setIsSameFile}
                  isFileUploading={false}
                  borderRadiusContainer={radiusPx}
                  containerBackgroundColor={
                    isThemeDark ? dark1 : convertHexToRGBA(colorPrimary, 0.1)
                  }
                  colorIcon={colorTextButton}
                  color={isThemeDark ? white : dark1}
                  backgroundColorAddFile={isThemeDark ? dark5 : 'white'}
                  key={v4()}
                  filesUploaded={transformFilesList(
                    document.type,
                    document.path
                  )}
                  addClass="w-full"
                  fillLoaderColor={colorPrimary}
                  backgroundColorFileLoaded={colorPrimary}
                  backgroundUploadedColor={backgroundUploadedColor}
                  backgroundColorComponent="transparent"
                  fileName={document.title}
                  fileTypes={getFileType(document.type)}
                  required={document.mandatory}
                  onDelete={(pathIndex: number) =>
                    onDeleteFileUpload(pathIndex, document.path, document.type)
                  }
                  onChangeFileUpload={(files) =>
                    onUploadFile(files, document.type)
                  }
                  generatedPath={
                    document.generated ? document.generated : undefined
                  }
                />
              </div>
            );
          })}
      </div>
      <div>
        {operations?.map((op) => {
          return (
            <div key={v4()}>
              <div className="mt-[2.5rem] px-[1rem] text-center text-[1.25rem] mb-[1rem] dark:text-white text-colorTextLightMode">
                {op.name ? op.name : op.operation_name}
              </div>
              {op.files.map((file) => {
                return (
                  <div
                    style={{
                      borderRadius: radiusPx,
                      backgroundColor: isThemeDark
                        ? dark1
                        : convertHexToRGBA(colorPrimary, 0.1),
                    }}
                    className="mx-5 mb-[.75rem]"
                  >
                    <FileMultiUpload
                      setActiveToast={setIsSameFile}
                      isFileUploading={false}
                      borderRadiusContainer={radiusPx}
                      containerBackgroundColor={
                        isThemeDark
                          ? dark1
                          : convertHexToRGBA(colorPrimary, 0.1)
                      }
                      color={isThemeDark ? white : dark1}
                      colorIcon={colorTextButton}
                      backgroundColorAddFile={isThemeDark ? dark5 : 'white'}
                      key={v4()}
                      filesUploaded={transformFilesList(file.type, file.path)}
                      addClass="w-full"
                      fileName={file.title}
                      fileTypes={getFileType(file.type)}
                      required={file.mandatory}
                      onDelete={(pathIndex: number) =>
                        onDeleteFileUpload(
                          pathIndex,
                          file.path,
                          file.type,
                          op.operation ? op.operation : op.operation_reference
                        )
                      }
                      onChangeFileUpload={(files) =>
                        onUploadFile(
                          files,
                          file.type,
                          op.operation ? op.operation : op.operation_reference
                        )
                      }
                      generatedPath={
                        file.generated ? file.generated : undefined
                      }
                      fillLoaderColor={colorPrimary}
                      backgroundColorFileLoaded={colorPrimary}
                      backgroundUploadedColor={backgroundUploadedColor}
                      backgroundColorComponent="transparent"
                    />
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default ListDocumentsUpload;
