import axios from 'axios';
import { Dispatch, SetStateAction } from 'react';
import {
  IFieldType,
  ISchemaChoiceType,
  IStepConditionType,
  IStepOperationType,
  IStepType,
} from '../utils/AdminTypes';

const apiUrl = process.env.REACT_APP_BACK_PRO_API_URL;
const newView = {
  name: 'Mon tableau',
  order: '1',
  schema: {
    whereClauses: {
      type: 'AND',
      conditions: [],
      groupBy: [],
    },
    provider_name: '📂 Dossiers B2C',
    provider_id: 'tbldz93hhSHX5vYZH',
    fields: [
      {
        provider_id: 'fldFD1JlTjvfEcfQm',
        provider_name: 'Référence opération',
        provider_type: 'singleLineText',
      },
      {
        provider_id: 'fldDkH4qoF0bJG8rd',
        provider_name: 'Statut MPR',
        provider_type: 'singleSelect',
      },
      {
        provider_id: 'fldlvUshxdkEhDu6Q',
        provider_name: 'Référence dossier',
        provider_type: 'singleLineText',
      },
    ],
  },
};
export const getOperations = async (
  id: number,
  updateOperation: Dispatch<SetStateAction<IStepOperationType[]>>,
  setOperationLoading: Dispatch<SetStateAction<boolean>>
) => {
  await axios
    .get(`${apiUrl}/installerviews/${id}/listoperationb2c`)
    .then((response) => {
      const { data } = response;
      updateOperation(data.data);
      setOperationLoading(false);
    })
    .catch((error) => {
      updateOperation([]);
      setOperationLoading(false);
      console.log(error);
    });
};

export const setSteps = async (
  updateSteps: Dispatch<SetStateAction<IStepType[]>>,
  setOperations: Dispatch<SetStateAction<IStepOperationType[]>>,
  setOperationLoading: Dispatch<SetStateAction<boolean>>
) => {
  await axios
    .post(`${apiUrl}/installerviews`, newView)
    .then((response) => {
      const { data } = response;
      getOperations(data.id, setOperations, setOperationLoading);
    })
    .catch((error) => console.log(error));

  axios
    .get(`${apiUrl}/installerviews`)
    .then((response) => {
      const { data } = response;
      updateSteps(data);
    })
    .catch((error) => console.log(error));
};

export const getSteps = (
  updateSteps: Dispatch<SetStateAction<IStepType[]>>
) => {
  axios
    .get(`${apiUrl}/installerviews`)
    .then((response) => {
      const { data } = response;
      updateSteps(data);
    })
    .catch((error) => console.log(error));
};

export const updateStep = async (
  step: IStepType,
  updateSteps: Dispatch<SetStateAction<IStepType[]>>,
  setShowPopUp: Dispatch<SetStateAction<string>>,
  setOperations: Dispatch<SetStateAction<IStepOperationType[]>>,
  setOperationLoading: Dispatch<SetStateAction<boolean>>,
  conditions: IStepConditionType[],
  stepFieldsSelect: IFieldType[],
  stepName: string
) => {
  const body = {
    id: step.id,
    installer_id: step.installer_id,
    name: stepName,
    order: step.order,
    schema: {
      fields: stepFieldsSelect,
      provider_id: step.schema.provider_id,
      provider_name: step.schema.provider_name,
      whereClauses: { ...step.schema.whereClauses, conditions },
    },
  };

  await axios
    .put(`${apiUrl}/installerviews/${step.id}`, body)
    .then(() => {
      setShowPopUp('NONE');
    })
    .catch((error) => console.log(error));

  await getOperations(step.id, setOperations, setOperationLoading);
  await getSteps(updateSteps);
};

export const deleteStep = async (
  id: number,
  updateSteps: Dispatch<SetStateAction<IStepType[]>>,
  setShowPopUp: Dispatch<SetStateAction<string>>,
  setOperationLoading: Dispatch<SetStateAction<boolean>>
) => {
  await axios
    .delete(`${apiUrl}/installerviews/${id}`)
    .then(() => {
      setShowPopUp('NONE');
      setOperationLoading(true);
    })
    .catch((error) => console.log(error));

  await getSteps(updateSteps);
};

export const getSchemaFolders = (
  setSchema: Dispatch<SetStateAction<IFieldType[]>>,
  setSchemaLoading: Dispatch<SetStateAction<boolean>>
) => {
  axios
    .get(`${apiUrl}/schema/folders`)
    .then((response) => {
      const { data } = response;
      setSchema(data.fields.slice(0, data.fields.length / 2));
      setSchemaLoading(false);
    })
    .catch((error) => console.log(error));
};

export const getSchemaChoices = (
  id: string,
  setSchemaChoices: Dispatch<SetStateAction<ISchemaChoiceType[]>>,
  setConditionLoading: Dispatch<SetStateAction<boolean>>
) => {
  axios
    .get(`${apiUrl}/schema/choices/${id}`)
    .then((response) => {
      const { data } = response;
      setSchemaChoices(data);
      setConditionLoading(false);
    })
    .catch((error) => {
      console.log(error);
      setSchemaChoices([]);
    });
};

export const sendEmailPasswordForgotten = (
  email: string,
  setIsSend: Dispatch<SetStateAction<boolean>>,
  setLoading: Dispatch<SetStateAction<boolean>>,
  setErrMessage: Dispatch<SetStateAction<string>>
) => {
  axios
    .post(`${apiUrl}/installer/ask-reset-password?email=${email}`)
    .then(() => {
      setLoading(false);
      setIsSend(true);
    })
    .catch((error) => {
      console.log(error);
      setIsSend(true);
      setLoading(false);
      setErrMessage(
        'Une erreur est survenue, merci de vous rapprocher de votre référent de compte Drapo.'
      );
    });
};

export const resetPassword = (
  email: string,
  token: string | null,
  password: string,
  setIsSend: Dispatch<SetStateAction<boolean>>,
  setLoading: Dispatch<SetStateAction<boolean>>,
  setErrMessage: Dispatch<SetStateAction<string>>,
  setErrBorderMail: Dispatch<SetStateAction<string>>,
  setErrBorderPassword: Dispatch<SetStateAction<string>>
) => {
  axios
    .post(
      `${apiUrl}/installer/reset-password?email=${email}&token=${token}&password=${password}&password_confirmation=${password}`
    )
    .then(() => {
      setLoading(false);
      setIsSend(true);
    })
    .catch((error) => {
      console.log('erreur', error.response);
      setLoading(false);
      if (
        error.response.data.error === 'No installer found' ||
        error.response.data.error === 'Email not sent from spring'
      ) {
        setErrMessage("L'adresse mail n'est pas reconnue.");
        setErrBorderMail('border border-red-500');
      } else if (
        error.response.data.password[0] ===
        'The password must be at least 8 characters.'
      ) {
        setErrMessage('Le mot de passe doit contenir au minimum 8 caractères');
        setErrBorderPassword('border border-red-500');
      } else {
        setErrMessage('Une erreur est survenue.');
      }
    });
};
