/* eslint-disable jsx-a11y/label-has-associated-control */
import { InstallerContext } from '@auth/utils/authContext';
import React, {
  Dispatch,
  useContext,
  SetStateAction,
  useState,
  useEffect,
} from 'react';
import { v4 } from 'uuid';
import { IChoices, IConditions } from '@dashboard/utils/dashboardTypes';
import { arrayChoicesMultiple } from '@dashboard/utils/functions';
import { ChevronIcon } from '@assets/svgComponents/svgComponents';
import { DashboardContext } from '../../../utils/context/dashboardContext';

interface IProps {
  valueValue: string | number;
  setValueValue: Dispatch<SetStateAction<string | number>>;
  datas: IConditions;
}

function InputSingleSelect({
  valueValue,
  setValueValue,
  datas,
}: IProps): JSX.Element {
  const [isSelectValueMultipleSelect, setIsSelectValueMultipleSelect] =
    useState(false);
  const { schemaFoldersCustomView, updateIsDisabledFiltersCustomView } =
    useContext(DashboardContext);

  const [valueArray, setValueArray] = useState<string[]>(
    typeof valueValue === 'string' && valueValue !== null
      ? valueValue.split('||')
      : ['']
  );
  const { radiusPx, isThemeDark } = useContext(InstallerContext);

  const radius = radiusPx === '0px' ? '0px' : '8px';

  const changeValue = (el: IChoices) => {
    if (valueArray.includes(el.provider_id)) {
      setValueArray(valueArray.filter((e) => e !== el.provider_id));
    } else {
      setValueArray([...valueArray, el.provider_id]);
    }
    setIsSelectValueMultipleSelect(true);
  };
  const valueVisible = () => {
    const valueVisbibleArray =
      typeof valueValue === 'string' && valueValue !== null
        ? valueValue.split('||')
        : '';
    const valueVisibleTransformName = arrayChoicesMultiple(
      datas,
      schemaFoldersCustomView
    )
      ?.filter((el: any) => {
        return valueVisbibleArray.includes(el.provider_id);
      })
      .map((el: any) => el.provider_name);
    return valueVisibleTransformName?.join(', ');
  };
  useEffect(() => {
    if (valueVisible() === '') {
      setValueValue('');
      setValueArray(['']);
    }
  }, [valueVisible()]);

  useEffect(() => {
    setValueValue(
      valueArray.join('||').substring(0, 2) === '||'
        ? valueArray.join('||').substring(2)
        : valueArray.join('||')
    );
  }, [valueArray]);

  const scrollBarColor = isThemeDark ? '' : 'scroll_light_mode';
  const closeChoices = () => {
    if (isSelectValueMultipleSelect) {
      if (valueValue !== '') {
        updateIsDisabledFiltersCustomView(false);
      } else {
        updateIsDisabledFiltersCustomView(true);
      }
    }
    setIsSelectValueMultipleSelect(!isSelectValueMultipleSelect);
  };

  return (
    <div className="relative text-[.75rem] font-medium ">
      <button
        style={{ borderRadius: radius }}
        className=" text-[.75rem] font-medium border  dark:border-white dark:border-opacity-[15%] flex items-center justify-between  py-[.375rem] px-[.625rem]  w-[30rem]"
        type="button"
        onClick={() => {
          closeChoices();
        }}
      >
        <input
          className="bg-transparent w-[95%] overflow-x-scroll cursor-pointer"
          type="text"
          readOnly
          value={valueVisible() || 'Choisir une ou plusieurs valeurs'}
        />
        <ChevronIcon
          width="1.5rem"
          height="1.5rem"
          fill={isThemeDark ? 'white' : '#191919'}
          transform={isSelectValueMultipleSelect ? 'rotate(-90)' : 'rotate(90)'}
          className="transition-transform duration-300"
        />
      </button>
      {isSelectValueMultipleSelect && (
        <div
          style={{
            zIndex: 9999999999,
            borderBottomLeftRadius: radius,
            borderBottomRightRadius: radius,
          }}
          className={`text-[.75rem] font-medium absolute w-[30rem] mt-[-.375rem] p-[.625rem] border dark:border-white dark:border-opacity-[15%] dark:border-t-transparent border-t-transparent dark:bg-[#1C1E1F] bg-white overflow-y-scroll max-h-[30rem] ${scrollBarColor}`}
        >
          {arrayChoicesMultiple(datas, schemaFoldersCustomView)?.map(
            (el: IChoices) => {
              return (
                <div key={v4()} className="flex items-center mt-2">
                  <label className="check-box flex sb-text-rem-body2  mr-[.625rem] font-medium gap-x-[.3rem] items-center">
                    <input
                      checked={
                        typeof valueValue === 'string'
                          ? valueArray.includes(el.provider_id)
                          : false
                      }
                      onChange={() => changeValue(el)}
                      type="checkbox"
                      className="check-box__switcher sb-text-rem-body2 appearance-none h-[.625rem] cursor-pointer	w-[1.25rem] rounded-[1em]"
                    />
                  </label>
                  <p className="text-[.75rem] font-medium hover:opacity-90 hover:scale-105 hover:ml-[.375rem]">
                    {el.provider_name}
                  </p>
                </div>
              );
            }
          )}
        </div>
      )}
    </div>
  );
}

export default InputSingleSelect;
