/* eslint-disable no-nested-ternary */
import React, { Dispatch, SetStateAction, useContext, useState } from 'react';
import { DatePicker, Input } from 'storybook-design-system-drapo';
import { InstallerContext } from '@auth/utils/authContext';
import { FolderContext } from '@folder/utils/FoldersContext';
import {
  backgroundQuaternaryLightMode,
  colorTextLightMode,
  dark1,
  dark5,
  darkVariant1,
  white,
} from '@assets/colors';
import { convertHexToRGBA } from '@utils/utils';
import InfosButton from '../../InfosButton';
import AdressCustomerInformation from './AdressCustomerInformation';

interface IFormCustomerInformationProps {
  errMail: string;
  errPhone: string;
  setErrMail: Dispatch<SetStateAction<string>>;
  setDivActive: Dispatch<SetStateAction<string | undefined>>;
  errorOwnerAddress: string;
  setErrorOwnerAddress: Dispatch<SetStateAction<string>>;
  birthDate: string;
  setBirthDate: Dispatch<SetStateAction<string>>;
  errBirthDate: string;
}

function FormCustomerInformation({
  errorOwnerAddress,
  setErrorOwnerAddress,
  errMail,
  errPhone,
  setErrMail,
  setDivActive,
  birthDate,
  setBirthDate,
  errBirthDate,
}: IFormCustomerInformationProps): JSX.Element {
  const {
    updateFolderDatas,
    folderDatas,
    updateFocusCustomerInfo,
    updateFocusTax,
    updateFocusCadastre,
    housingOwner,
    clientInfosAlreadySet,
  } = useContext(FolderContext);
  const { colorPrimary, radiusPx, isThemeDark, colorTextButton } =
    useContext(InstallerContext);

  const choices = ['Monsieur', 'Madame'];

  const [choiceSelected, setChoiceSelected] = useState('Monsieur');
  const radioWidth = `${100 / choices.length}%`;

  const onChangeChoiceSelected = (choice: string, index: number) => {
    setChoiceSelected(choices[index]);
    updateFolderDatas({
      ...folderDatas,
      client: { ...folderDatas.client, gender: choice },
    });
  };

  const updateInfoRightPart = () => {
    updateFocusTax(false);
    updateFocusCustomerInfo(true);
    updateFocusCadastre(false);
  };

  return (
    <div
      style={{ borderRadius: radiusPx }}
      className="dark:bg-dark-2 bg-white p-[1.5rem] mt-[2rem]"
    >
      <div className="mb-[1.5rem] flex items-center justify-between">
        <p className="sb-text-rem-body2 dark:text-white text-colorTextLightMode">
          Informations personnelles
        </p>
        <InfosButton
          forcedDivActive="FormCustomerInformation"
          setDivActive={setDivActive}
        />
      </div>
      <div
        style={{
          borderRadius: radiusPx,
          backgroundColor: isThemeDark
            ? dark5
            : convertHexToRGBA(colorPrimary, 0.1),
        }}
        className="sb-text-typo text-white sb-text-rem-subtitle2 flex w-full mb-[1rem]"
      >
        {choices.map((choice, index) => {
          const radiusStyle =
            choice === choiceSelected
              ? radiusPx
              : index === 0
              ? `16px 0 0 16px`
              : index === choices.length - 1
              ? `0 16px 16px 0`
              : '0';
          return (
            <div
              tabIndex={index}
              onKeyDown={undefined}
              role="button"
              key={choice}
              style={{
                width: radioWidth,
                backgroundColor: choice === choiceSelected ? colorPrimary : '',
                borderRadius: radiusStyle,
                color: isThemeDark
                  ? white
                  : choice === choiceSelected && !isThemeDark
                  ? colorTextButton
                  : choice !== choiceSelected && !isThemeDark
                  ? colorTextLightMode
                  : '',
              }}
              className={`dark:text-white text-colorTextLightMode sb-text-rem-subtitle2 sb-choice flex justify-center items-center choice-${
                choice === choiceSelected ? 'sb-selected' : 'sb-not-selected'
              }`}
              onClick={() => {
                if (!clientInfosAlreadySet) {
                  onChangeChoiceSelected(choice, index);
                }
              }}
            >
              {choice}
            </div>
          );
        })}
      </div>
      <Input
        borderRadius={radiusPx}
        color={isThemeDark ? white : dark1}
        backgroundColor={
          isThemeDark ? darkVariant1 : backgroundQuaternaryLightMode
        }
        onClick={() => updateInfoRightPart()}
        label="Nom du client"
        inputType="text"
        onChange={(e) => {
          updateFolderDatas({
            ...folderDatas,
            client: { ...folderDatas.client, lastname: e.target.value },
          });
        }}
        value={folderDatas.client.lastname}
        addClassInput={
          !isThemeDark ? 'light-mode-input' : 'dark-mode-input-folder'
        }
        addClass="mb-[.875rem]"
        disabled={clientInfosAlreadySet}
      />
      <Input
        addClassInput={
          !isThemeDark ? 'light-mode-input' : 'dark-mode-input-folder'
        }
        borderRadius={radiusPx}
        onClick={() => updateInfoRightPart()}
        label="Prénom du client"
        color={isThemeDark ? white : dark1}
        backgroundColor={
          isThemeDark ? darkVariant1 : backgroundQuaternaryLightMode
        }
        inputType="text"
        onChange={(e) => {
          updateFolderDatas({
            ...folderDatas,
            client: { ...folderDatas.client, firstname: e.target.value },
          });
        }}
        value={folderDatas.client.firstname}
        addClass="mb-[.875rem]"
        disabled={clientInfosAlreadySet}
      />
      {folderDatas.has_mpr_step && !clientInfosAlreadySet && (
        <div>
          <DatePicker
            backgroundColorCalendar={
              isThemeDark ? darkVariant1 : backgroundQuaternaryLightMode
            }
            backgroundColorHeaderCalendar={
              isThemeDark ? darkVariant1 : backgroundQuaternaryLightMode
            }
            borderInput={errBirthDate !== '' ? '1px solid red' : 'none'}
            backgroundColorCellCalendar={colorPrimary}
            colorCalendar={isThemeDark ? white : colorTextLightMode}
            colorCellCalendarActive={colorTextButton}
            label="Date de naissance"
            setDate={setBirthDate}
            date={birthDate}
            dataTestId="depot"
            addClass={`text-[.75rem]/[.92] ${
              errBirthDate !== '' ? 'mb-[.75rem]' : 'mb-[.875rem]'
            }`}
            borderRadiusCalendar={radiusPx}
            required
            borderRadiusCellCalendar={radiusPx !== '0px' ? '8px' : '0px'}
          />
          {errBirthDate !== '' && (
            <p className="sb-text-rem-body2 text-red-500 mb-[.75rem]">
              {errBirthDate}
            </p>
          )}
        </div>
      )}

      <Input
        addClassInput={
          !isThemeDark ? 'light-mode-input' : 'dark-mode-input-folder'
        }
        borderRadius={radiusPx}
        onClick={() => updateInfoRightPart()}
        label="Email"
        color={isThemeDark ? white : dark1}
        backgroundColor={
          isThemeDark ? darkVariant1 : backgroundQuaternaryLightMode
        }
        inputType="email"
        onChange={(e) => {
          updateFolderDatas({
            ...folderDatas,
            client: { ...folderDatas.client, email: e.target.value },
          });
          if (errMail !== '') {
            setErrMail('');
          }
        }}
        value={folderDatas.client.email}
        addClass={`${
          errMail !== '' ? 'border border-red-500' : 'mb-[.875rem]'
        }`}
        disabled={clientInfosAlreadySet}
      />
      {errMail !== '' && (
        <p className="text-xs text-red-500 mt-[.25rem] mb-[.875rem] ml-[.5rem]">
          {errMail}
        </p>
      )}
      <Input
        addClassInput={
          !isThemeDark ? 'light-mode-input' : 'dark-mode-input-folder'
        }
        borderRadius={radiusPx}
        onClick={() => updateInfoRightPart()}
        label="Téléphone"
        color={isThemeDark ? white : dark1}
        backgroundColor={
          isThemeDark ? darkVariant1 : backgroundQuaternaryLightMode
        }
        inputType="tel"
        onChange={(e) => {
          updateFolderDatas({
            ...folderDatas,
            client: { ...folderDatas.client, phone: e.target.value },
          });
        }}
        value={folderDatas.client.phone}
        addClass={`${errPhone !== '' && 'border border-red-500'} ${
          housingOwner !== '' && 'mb-[.875rem]'
        }`}
        disabled={clientInfosAlreadySet}
      />
      {errPhone !== '' && (
        <p className="text-[.75rem] text-red-500 mt-[.25rem] ml-[.5rem]">
          {errPhone}
        </p>
      )}
      {housingOwner !== '' && (
        <AdressCustomerInformation
          setErrorOwnerAddress={setErrorOwnerAddress}
          errorOwnerAddress={errorOwnerAddress}
        />
      )}
    </div>
  );
}

export default FormCustomerInformation;
