import { useContext, useEffect, useState } from 'react';
/*
import { relance } from '@assets/images/images';
*/
import LoadersCeeFolderMonitoring from '@components/loaders/LoadersCeeFolderMonitoring';
import { InstallerContext } from '@auth/utils/authContext';
import {
  getStatusSignatureAH,
  /*
  sendEmailCEE,
  */
} from '@folderMonitoring/apiRequest/folderMonitoringRequest';
import { CheckCircleIcon } from '@assets/svgComponents/svgComponents';

interface ICeeAHProps {
  reference_operation: string;
  canceledInAirtable?: boolean;
}

function CeeAH({
  reference_operation,
  canceledInAirtable,
}: ICeeAHProps): JSX.Element {
  const { radiusPx, colorPrimary, pusher, installer, colorTextButton } =
    useContext(InstallerContext);

  const radius = radiusPx === '0px' ? '0px' : '16px';

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [statusSignaturePro, setStatusSignaturePro] = useState<string>('');
  const [statusSignatureClient, setStatusSignatureClient] =
    useState<string>('');
  /*
  const [linkSignature, setLinkSignature] = useState<string>('');
  */
  const [dateAH, setDateAh] = useState<string>('');
  /*
  const [mailIsSend, setMailIsSend] = useState<boolean>(false);
  const [errorMail, setErrorMail] = useState<string>('');
  */
  const [webSocketConnected, setWebSocketConnected] = useState<boolean>(false);

  const [canceledByRT, setCanceledByRT] = useState<boolean>(
    canceledInAirtable || false
  );
  const [statutNotCreated, setStatutNotcreated] = useState<boolean>(false);

  const webSocketHandler = () => {
    if (!webSocketConnected) {
      let channel = pusher?.channel(`webhook.signature.${installer.id}`);
      if (channel === undefined) {
        channel = pusher?.subscribe(`webhook.signature.${installer.id}`);
      }
      // eslint-disable-next-line no-unused-expressions
      channel?.bind('webhook:ah', (data: any) => {
        if (
          Object.keys(data).includes('status_updated') &&
          data.reference_operation === reference_operation
        ) {
          getStatusSignatureAH(
            setCanceledByRT,
            setIsLoading,
            setDateAh,
            /*
            setLinkSignature,
            */
            setStatusSignaturePro,
            setStatusSignatureClient,
            reference_operation
          );
        }
      });
      setWebSocketConnected(true);
    }
  };

  /*
  const sendEmail = () => {
    setErrorMail('');
    setMailIsSend(true);
    sendEmailCEE(setMailIsSend, setErrorMail, reference_operation);
  };
  */

  const pending = (type: string) => {
    return (
      <div
        style={{ borderRadius: radius }}
        className="dark:bg-dark-5 bg-lightMode-dark-5 pt-[.875rem] pb-[1.125rem] px-[1rem] flex flex-col space-y-[.875rem] text-dark-1 dark:text-white"
      >
        <div className="font-[500] text-[.875rem] pl-[.625rem]">
          {type === 'pro' ? 'Votre signature' : 'Signature de votre client'}
        </div>
        <div className="text-[.875rem] pl-[.625rem]">
          {type === 'pro'
            ? "Vous n'avez pas encore signé."
            : "Votre client n'a pas encore signé."}
        </div>
      </div>
    );
  };

  const done = (type: string) => {
    return (
      <div
        style={{
          backgroundColor: colorPrimary,
          borderRadius: radius,
        }}
        className="px-[1.5rem] py-[1.75rem] flex items-center justify-between"
      >
        <div className="text-[.875rem] font-[500] text-dark-1 dark:text-white">{`${
          type === 'pro' ? 'Vous avez signé' : 'Votre client a signé'
        }`}</div>
        <CheckCircleIcon
          width="1.5rem"
          height="1.5rem"
          fill={colorTextButton}
        />
      </div>
    );
  };

  const canceled = (type: string) => {
    return (
      <div className="leading-[1.5rem] font-[500] text-[.875rem] text-red-1">
        {`${
          type === 'client'
            ? 'Votre client à annulé sa signature'
            : 'Vous avez annulé votre signature'
        }`}
        . Merci de contacter votre Référent Technique.
      </div>
    );
  };

  const expired = (type: string) => {
    return (
      <div className="leading-[1.5rem] font-[500] text-[.875rem] text-red-1">
        {`${
          type === 'client'
            ? 'La signature de votre client a expiré'
            : 'Votre demande de signature a expiré'
        }`}
        . Merci de contacter votre Référent Technique.
      </div>
    );
  };

  const renderPro = () => {
    switch (statusSignaturePro) {
      case 'pending':
      case 'notified':
        return pending('pro');
      case 'done':
      case 'signed':
        return done('pro');
      case 'refused':
        return canceled('pro');
      case 'expired':
        return expired('pro');
      default:
        return <></>;
    }
  };

  const renderClient = () => {
    switch (statusSignatureClient) {
      case 'pending':
      case 'notified':
        return pending('client');
      case 'done':
      case 'signed':
        return done('client');
      case 'refused':
        return canceled('client');
      case 'expired':
        return expired('client');
      default:
        return <></>;
    }
  };

  useEffect(() => {
    webSocketHandler();
    getStatusSignatureAH(
      setCanceledByRT,
      setIsLoading,
      setDateAh,
      /*
      setLinkSignature,
      */
      setStatusSignaturePro,
      setStatusSignatureClient,
      reference_operation
    );
  }, []);

  useEffect(() => {
    setStatutNotcreated(
      statusSignatureClient === 'not_created' &&
        statusSignaturePro === 'not_created'
    );
  }, [statusSignatureClient, statusSignaturePro]);

  return (
    <>
      {!isLoading ? (
        <>
          {!statutNotCreated ? (
            <div className="pt-[.75rem] px-[1rem] flex flex-col space-y-[1.5rem]">
              <div>
                <div className="leading-[1.5rem] text-dark-1 dark:text-white">
                  Nous vous avons adressé par mail, ainsi qu&apos;à votre
                  client, une demande de signature électronique de ce document
                  le {`${dateAH !== '' ? dateAH : 'N/C'}`}.
                </div>
                {/*
                {statusSignaturePro === 'pending' && (
                  <div className="leading-[1.5rem] text-dark-1 dark:text-white">
                    Vous pouvez également signer directement votre document en
                    suivant le lien ci-dessous.
                  </div>
                )}
                */}
              </div>
              {statusSignaturePro !== '' && renderPro()}
              {statusSignatureClient !== '' && renderClient()}
            </div>
          ) : (
            <>
              {canceledByRT && (
                <div className="pt-[.75rem] px-[1rem] flex flex-col space-y-[1.5rem]">
                  {canceled('pro')}
                </div>
              )}
            </>
          )}
        </>
      ) : (
        <div className="pt-[.75rem] px-[1rem]">
          <LoadersCeeFolderMonitoring />
        </div>
      )}
    </>
  );
}

export default CeeAH;

CeeAH.defaultProps = {
  canceledInAirtable: false,
};
