import { useContext } from 'react';
import { MapView } from 'storybook-design-system-drapo';

import marker from '@assets/images/marker.svg';
import { InstallerContext } from '@auth/utils/authContext';
import { LabelsByValue } from '@simulator/utils/labelsByValue';
import { SimulatorContext } from '@simulator/utils/simulatorContext';
import { ILatLng, IRecapGeneralType } from '@simulator/utils/simulatorTypes';
import { useLocation } from 'react-router-dom';

interface RecapGeneralProps {
  latLng: ILatLng;
  coordinates: ILatLng[];
  markerMap: boolean;
}
function GeneralSummary({
  latLng,
  coordinates,
  markerMap,
}: RecapGeneralProps): JSX.Element {
  const { simulatorDatas } = useContext(SimulatorContext);
  const { colorPrimary, radiusPx, isThemeDark } = useContext(InstallerContext);

  const recapGeneralDatas: Array<IRecapGeneralType> = [
    {
      title: 'Âge du logement',
      content: LabelsByValue[simulatorDatas.age],
    },
    {
      title: "Type d'habitation",
      content: LabelsByValue[simulatorDatas.habitation],
    },
    {
      title: 'Chauffage central',
      content: LabelsByValue[simulatorDatas.energy],
    },
    { title: 'Superficie', content: `${simulatorDatas.surface}m2` },
    {
      title: 'Personne(s) dans le ménage',
      content: `${simulatorDatas.persons} Personne${
        simulatorDatas.persons !== '1' ? 's' : ''
      }`,
    },
    {
      title: 'Revenus du ménage',
      content: `${simulatorDatas.income.legend} (${simulatorDatas.income.menage})`,
    },
    { title: 'Typologie client', content: LabelsByValue[simulatorDatas.owner] },
  ];
  const location = useLocation();
  return (
    <div
      style={{
        borderRadius: radiusPx,
        border:
          location.search.includes('?integration=iframe') && !isThemeDark
            ? '1px solid #d2d2d2'
            : '',
      }}
      className="w-full lg:flex dark:bg-dark-5 bg-white mb-[2rem] p-[2rem] lg:space-x-[2rem] dark:text-white text-colorTextLightMode"
    >
      <div
        style={{ borderRadius: radiusPx }}
        className="lg:w-1/2 lg:block hidden dark:border-none border border-borderLightMode2"
      >
        <MapView
          colorPolygon={colorPrimary}
          marker={markerMap}
          iconMarker={marker}
          zoomLevel={coordinates.length > 1 ? 18 : 5}
          addressCenter={
            coordinates.length > 1 ? latLng : { lat: 48.8588897, lng: 2.320041 }
          }
          coordinates={coordinates}
          whiteMode={!isThemeDark}
        />
      </div>
      <div className="lg:w-1/2">
        <ul className="w-full sb-text-rem-body2">
          {recapGeneralDatas.map((data: IRecapGeneralType, index: number) => (
            <li
              key={data.title}
              className={`flex border-opacity-20 border-b-[2px] border-solid border-[#707070] ${
                index === 0 ? 'pb-[.625rem]' : 'py-[.625rem]'
              }`}
              data-test-id={data.title}
            >
              <div className="w-7/12 text-left">{data.title}</div>
              <div className="w-5/12 text-right">{data.content}</div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default GeneralSummary;
