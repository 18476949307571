function InfoHeader(): JSX.Element {
  return (
    <div
      style={{ zIndex: 22223333 }}
      className=" fixed lg:text-[1rem] text-[.75rem] text-center bg-purple-1 w-full py-[.25rem] z-[33333]"
    >
      <p>
        Les fonctionnalités disponibles sont limitées car vous n&apos;êtes pas
        connecté.
      </p>
    </div>
  );
}

export default InfoHeader;
