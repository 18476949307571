import { useState, useContext, useEffect } from 'react';
import { v4 } from 'uuid';
import { FileMultiUpload, ButtonSb } from 'storybook-design-system-drapo';
import Loader from '@components/loaders/Loader';
import { InstallerContext } from '@auth/utils/authContext';
import { FolderMonitoringContext } from '@folderMonitoring/utils/context/folderMonitoringContext';
import {
  addMandateMPR,
  /*
  sendEmailMPR,
  */
} from '@folderMonitoring/apiRequest/folderMonitoringRequest';
import { ICeeDocuments } from '@folderMonitoring/utils/folderMonitoringTypes';
import { toast } from 'react-toastify';
import { dark1, dark5, white } from '@assets/colors';
import { convertHexToRGBA } from '@utils/utils';

interface IMprElectronicSignatureProps {
  email: string;
  tel: string;
  reference_operation: string;
}

function MprElectronicSignature({
  email,
  tel,
  reference_operation,
}: IMprElectronicSignatureProps): JSX.Element {
  const { folderDatas, updateFolderDatas, updateStepIsUpdate } = useContext(
    FolderMonitoringContext
  );

  const { folderActif } = useContext(FolderMonitoringContext);

  const { radiusPx, colorPrimary, isThemeDark, colorTextButton, installer } =
    useContext(InstallerContext);

  const radius = radiusPx === '0px' ? '0px' : '8px';

  const [filesUploaded, setFilesUploaded] = useState<any[]>([]);
  const [fileToSend, setFileToSend] = useState<ICeeDocuments[]>([]);

  const [isFileUploading, setIsFileUploading] = useState<boolean>(false);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isFile, setIsFile] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [isSend, setIsSend] = useState<boolean>(false);
  /*
  const [errorMail, setErrorMail] = useState<string>('');
  const [mailIsSend, setMailIsSend] = useState<boolean>(false);
  */

  const [isSameFile, setIsSameFile] = useState<boolean>(false);

  const onUploadFile = (file: File) => {
    setFilesUploaded((prev) => [
      ...prev,
      {
        name: file.name,
        path: URL.createObjectURL(file),
      },
    ]);
    setFileToSend((prev: ICeeDocuments[]) => [
      ...prev,
      {
        name: file.name,
        path: URL.createObjectURL(file),
        index: fileToSend.length,
        file,
      },
    ]);
    setIsFile(true);
  };

  const onDeleteFileUpload = (index: number) => {
    const tab = filesUploaded;
    const tab2 = fileToSend;

    for (let i = 0; i < filesUploaded.length; i += 1) {
      if (i === index) {
        tab.splice(i, 1);
        tab2.splice(i, 1);
      }
    }
    setFilesUploaded(tab);
    setFileToSend(tab2);

    setIsFile(tab2.length !== 0);
  };

  /*
  const handleClickMail = () => {
    setMailIsSend(true);
    if (errorMail !== '') {
      setErrorMail('');
    }
    sendEmailMPR(setMailIsSend, setErrorMail, reference_operation);
  };
  */

  const handleClick = () => {
    setIsFileUploading(true);
    setIsLoading(true);
    setError('');
    setIsSend(false);
    addMandateMPR(
      updateStepIsUpdate,
      setIsLoading,
      setIsSend,
      setError,
      folderActif[0].référence_dossier,
      fileToSend,
      reference_operation,
      folderDatas,
      updateFolderDatas
    );
  };

  useEffect(() => {
    if (isSameFile) {
      toast(
        '⛔️ Un fichier avec le même nom a déjà été renseigné pour ce document'
      );
    }
  }, [isSameFile]);

  return (
    <>
      <div className="relative pt-[.75rem] flex flex-col space-y-[1.25rem] px-[1rem]">
        <div className="text-[.875rem]">
          <div className="p-[.625rem] border-b-[2px] border-b-dark-light-1/[.20] flex items-center justify-between">
            <div className="font-[500]">Email</div>
            <input
              type="email"
              className="dark:bg-dark-2 bg-lightMode-dark-2 dark:text-white text-dark-1 text-right w-full"
              value={email}
            />
          </div>
          <div className="p-[.625rem] border-b-[2px] border-b-dark-light-1/[.20] flex items-center justify-between">
            <div className="font-[500]">Télephone</div>
            <input
              type="tel"
              className="dark:bg-dark-2 bg-lightMode-dark-2 dark:text-white text-dark-1 text-right w-full"
              value={tel}
            />
          </div>
          {/*
          <ButtonSb
            onClick={() => handleClickMail()}
            label="Envoyer une relance par mail au client"
            color={colorTextButton}
            backgroundColor={colorPrimary}
            dataTestId="soumettre"
            addClass="mt-[.625rem] !h-[2.5rem]"
            borderRadius={radius}
            disabled={installer.desactiver_boutons}
          />
          {mailIsSend && (
            <div className="mt-[.875rem]">
              <Loader />
            </div>
          )}
          {!mailIsSend && errorMail !== '' && (
            <div className="mt-[.875rem] text-red-1 text-[.875rem]">
              {errorMail}
            </div>
          )}
          */}
        </div>
        <FileMultiUpload
          setActiveToast={setIsSameFile}
          key={v4()}
          fileName="Si vous possédez le Mandat MPR signé"
          fileNameUpload="mandat MPR"
          required
          colorIcon={colorTextButton}
          isFileUploading={isFileUploading}
          borderRadiusContainer={radiusPx}
          borderRadius={radiusPx}
          backgroundColorFileLoaded={colorPrimary}
          backgroundUploadedColor={colorPrimary}
          containerBackgroundColor={
            isThemeDark ? dark1 : convertHexToRGBA(colorPrimary, 0.1)
          }
          color={isThemeDark ? white : dark1}
          backgroundColorAddFile={isThemeDark ? dark5 : white}
          backgroundColorComponent={
            isThemeDark ? dark1 : convertHexToRGBA(colorPrimary, 0.1)
          }
          filesUploaded={filesUploaded}
          onDelete={(index) => onDeleteFileUpload(index)}
          onChangeFileUpload={(file: File) => onUploadFile(file)}
        />
        <ButtonSb
          disabled={!isFile || installer.desactiver_boutons}
          onClick={() => handleClick()}
          label="Envoyer le fichier"
          color={isThemeDark ? white : dark1}
          backgroundColor={colorPrimary}
          dataTestId="soumettre"
          addClass="!h-[2.5rem]"
          borderRadius={radius}
        />
        {isLoading && (
          <div className="mt-[.875rem]">
            <Loader />
          </div>
        )}
        {!isLoading && isSend && error && (
          <div className="mt-[.875rem] text-red-1 text-[.875rem]">{error}</div>
        )}
      </div>
    </>
  );
}

export default MprElectronicSignature;
