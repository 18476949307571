import {
  IFinancial,
  ICategoryFinancial,
} from '@dashboard/utils/dashboardTypes';
import { v4 } from 'uuid';
import { numFormatSpace } from '@utils/utils';
import { useEffect, useState, useContext } from 'react';
import { InstallerContext } from '@auth/utils/authContext';
import OneCardCategoryFinancial from './OneCardCategoryFinancial';

interface IProps {
  item: IFinancial;
  title: string;
  isInactive: boolean;
}

function OneCardFinancial({ item, isInactive, title }: IProps): JSX.Element {
  const [list, setList] = useState<ICategoryFinancial[]>();

  useEffect(() => {
    const tab = {
      payé: { title: '', nb_folders: 0, amount: '', color: '' },
      a_payer: { title: '', nb_folders: 0, amount: '', color: '' },
      en_cours: { title: '', nb_folders: 0, amount: '', color: '' },
    };

    for (let i = 0; i < item.categories.length; i += 1) {
      const categoryTitle = item.categories[i].title
        .replace(' ', '_')
        .toLowerCase();
      const obj = tab[categoryTitle as keyof typeof tab] ?? null;
      if (obj !== null) {
        obj.title = item.categories[i].title;
        obj.nb_folders = item.categories[i].nb_folders;
        obj.amount = item.categories[i].amount;
        obj.color = item.categories[i].color;
      }
    }
    const result = Object.keys(tab).map((index) => {
      return tab[index as keyof typeof tab];
    });

    setList(result);
  }, [item.categories]);

  const { radiusPx } = useContext(InstallerContext);
  const financialContent = () => {
    if (isInactive) {
      return (
        <p className="text-[.875rem] dark:text-white text-dark-1">
          Vous ne bénéficiez pas de ce dispositif. Si vous voulez en disposer,
          veuillez contacter votre référent de compte.
        </p>
      );
    }
    if (item.categories.length > 0 && list) {
      return (
        <>
          {list.map((category) => {
            if (category.title) {
              return (
                <div key={v4()}>
                  <OneCardCategoryFinancial item={category} />
                </div>
              );
            }
            return false;
          })}
        </>
      );
    }
    return (
      <div className="pb-[.625rem] dark:text-white text-dark-1">
        Aucune donnée
      </div>
    );
  };

  return (
    <div
      style={{
        borderRadius: radiusPx,
      }}
      className="w-11/12 overflow-hidden mb-[1.25rem] m-auto animate__animated dark:bg-transparent bg-white animate__fadeIn leading-[1.5rem] dark:border-borderDarkMode border border-borderLightMode border-[1px] "
    >
      <div className="dark:bg-dark-4 bg-lightMode-dark-4 p-[1rem] dark:border-b-borderDarkMode border-b border-b-borderLightMode border-b-[1px]  ">
        <div className="flex items-center justify-between mb-[.375rem]">
          <div className="!font-medium text-[1rem] dark:text-white text-dark-1">
            {title}
          </div>
          {!isInactive && (
            <div className="font-regular text-[.75rem] opacity-80 dark:text-white text-dark-1">
              {`${numFormatSpace(item.nb_folders_total, true)} opération${
                item.nb_folders_total > 1 ? 's' : ''
              }`}
            </div>
          )}
        </div>
        <div className="text-[1.5rem] dark:text-white text-dark-1">
          {!isInactive ? `${numFormatSpace(item.amount_total)} €` : '-'}
        </div>
      </div>
      <div
        style={{
          borderBottomLeftRadius: radiusPx,
          borderBottomRightRadius: radiusPx,
        }}
        className="dark:bg-dark-3 bg-lightMode-dark-3 p-[1rem]"
      >
        {financialContent()}
      </div>
    </div>
  );
}

export default OneCardFinancial;
