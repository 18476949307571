import { InstallerContext } from '@auth/utils/authContext';
import { useContext } from 'react';
import { white } from '@assets/colors';
import { BugReportIcon } from '@assets/svgComponents/svgComponents';

function HomeLeftMenuBugCard(): JSX.Element {
  const { radiusPx, isThemeDark, colorPrimary } = useContext(InstallerContext);
  const radius = radiusPx === '0px' ? '0px' : '8px';

  return (
    <a
      className="cursor-pointer"
      href="https://airtable.com/shrgj4ZTbl9IgKopQ"
      target="_blank"
      rel="noreferrer"
    >
      <div
        style={{
          borderRadius: radius,
          backgroundColor: isThemeDark ? '#5D6AD233' : 'white',
        }}
        className="dark:bg-dark-1 bg-[#EFEFEF] dark:border-none border border-borderLightMode flex items-center justify-between py-[.25rem] pl-[.5rem] pr-[.75rem] mb-[.5rem] mx-[1rem] dark:text-white text-dark-5 h-[3rem]"
      >
        <div className="flex items-center space-x-[.75rem] w-full truncate">
          <div
            style={{ borderRadius: radius }}
            className="w-[3rem] flex justify-center"
          >
            <BugReportIcon
              width="2rem"
              height="2rem"
              fill={isThemeDark ? 'white' : colorPrimary}
            />
          </div>
          <p className="sb-text-rem-body2">Un bug ?</p>
        </div>
        <div>
          <svg
            width="1.5rem"
            height="1.5rem"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="mouseNotActive"
          >
            <path
              d="M9.99997 6L8.58997 7.41L13.17 12L8.58997 16.59L9.99997 18L16 12L9.99997 6Z"
              fill={isThemeDark ? white : '#5D5D5F'}
            />
          </svg>
        </div>
      </div>
    </a>
  );
}

export { HomeLeftMenuBugCard };
